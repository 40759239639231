import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { ACC_PREFERENCES } from "./faApi";

export const AccountPreferencesService = {
  saveAccountPreferences: (accPreferences, callback, errorCallback) => {
    let reqBody = {
      ...accPreferences,
    };

    let req = requests.getSimplePostRequest(
      ACC_PREFERENCES.saveAccountPreferences,
      reqBody
    );
    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  saveFeedbacks: (feedbacks, callback, errorCallback) => {
    let reqBody = {
      ...feedbacks,
    };

    let req = requests.getSimplePostRequest(
      ACC_PREFERENCES.saveFeedbacks,
      reqBody
    );
    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  savePeriodicPreferences: (periodic, callback, errorCallback) => {
    let reqBody = {
      ...periodic,
    };

    let req = requests.getSimplePostRequest(
      ACC_PREFERENCES.savePeriodicPreferences,
      reqBody
    );
    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  saveInteractionPagePreferences: (
    interactionPageOptions,
    callback,
    errorCallback
  ) => {
    let reqBody = {
      ...interactionPageOptions,
    };

    let req = requests.getSimplePostRequest(
      ACC_PREFERENCES.saveInteractionPagePreferences,
      reqBody
    );
    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
