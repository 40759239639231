import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { USER_CONTENT } from "./faApi";

export const UserContentService = {
  save: (userContent, callback, errorCallback) => {
    let reqBody = {
      ...userContent,
    };

    let req = requests.getSimplePostRequest(USER_CONTENT.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(USER_CONTENT.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  downloadThumbnails: (userContentId, callback, errorCallback) => {
    const req = requests.getSimpleGetRequest(USER_CONTENT.downloadThumbnails, {
      userContentId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  downloadImage: (userContentId, objectId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      USER_CONTENT.downloadImage +
        "?userContentId=" +
        userContentId +
        "&objectId=" +
        encodeURI(objectId)
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
