//import { useStyles } from "./QrLabelTemplateItemStyle";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    minHeight: 280,
  },
}));

const QrLabelTemplateItem = (props) => {
  const { value, clickAction } = props;
  const classes = useStyles();

  const buttonClicked = () => {
    clickAction(value);
  };

  return (
    <Card
      className={classes.root}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CardActionArea style={{ display: "flex" }} onClick={buttonClicked}>
        {value.src && <img
          className={classes.imageSrc}
          src={value.src}
          style={{ width: 200 }}
        ></img>}
        {value.thumbnail && <img
          className={classes.imageSrc}
          src={`data:image/png;base64, ${value.thumbnail}`}
          style={{ width: 200 }}
        ></img>}
      </CardActionArea>
    </Card>

    // <ButtonBase
    //       focusRipple
    //       key={props.desc}
    //       onClick={buttonClicked}
    //       className={classes.image}
    //       variant="outlined"
    //       color="primary"
    //       >

    //   <img className={classes.imageSrc} src={props.src} style={{width: 200}}></img>
    // </ButtonBase>

    //

    // <ButtonBase
    //       focusRipple
    //       key={props.desc}
    //
    //       focusVisibleClassName={classes.focusVisible}
    //       style={{
    //         width: 200//image.width,
    //       }}
    //       onClick={buttonClicked}
    //     >
    //       <span
    //         className={classes.imageSrc}
    //         style={{
    //           backgroundImage: `url(${props.src})`,
    //         }}
    //       />
    //       <span className={classes.imageBackdrop} />
    //       <span className={classes.imageButton}>

    //       </span>
    //     </ButtonBase>
  );
};

export default QrLabelTemplateItem;
