import { Grid } from "@material-ui/core";
import ThingSelector from "components/selector/ThingSelector";
import React, { forwardRef, useEffect, useState } from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { IssueService } from "services/issue/issueService";
import i18n from "../../i18n";

const ThingAssign = forwardRef((props, ref) => {
  const { onChange, onClose, open, issue } = props;
  const { t } = useTranslation("issue", { i18n });
  const dispatch = useDispatch();
  const accountId = localStorage.accountId;

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [loading, setLoading] = useState(false);
  const [thing, setThing] = useState(null);

  useEffect(() => {
    setThing(null);
  }, [open]);

  const saveSuccess = (data) => {
    showAlert(t("THING_ASSIGNED"), "success");
    setLoading(false);
    onChange(data);
    onClose();
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const assignThing = () => {
    setLoading(true);

    IssueService.assignThing(issue.id, thing.id, saveSuccess, saveError);
  };

  const newFormRender = () => {
    return (
      <>
        <FaDialog
          title={t("ASSIGN_THING")}
          faOpen={open}
          faOnSubmit={assignThing}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            onClose();
          }}
          faMaxWidth="lg"
          saveButtonTitle={t("ASSIGN_THING")}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <ThingSelector
                required={true}
                value={thing}
                accountId={accountId}
                onChange={(data) => {
                  setThing(data);
                }}
              />
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  return newFormRender();
});

export default ThingAssign;
