import AccountDetails from "features/accountDetails";
import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import i18n from "../../i18n";
import { useStyles } from "./QuickStartTutorialStyle";
import ThingsSteps from "./ThingsSteps";
import TutorialElement from "./TutorialElement";

export default function ShareSocialMediaTutorial() {
  const { code } = useParams();
  const classes = useStyles();

  const stepsRef = useRef();
  const accountPreferencesRef = useRef(null);
  let history = useHistory();

  const { t } = useTranslation("tutorialShareSocialMediaLink", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        element: "#menuAccountManagement",
        intro: (
          <TutorialElement
            icon="click"
            text={
              "Sosyal Medya hesaplarınızı eklemek için hesap yönetimi menüsünü açın."
            }
          />
        ),
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "#menuAccountDetails", // child menu
        intro: (
          <TutorialElement icon="click">
            {t("Hesap sayfanızı açmak için tıklayın.")}
          </TutorialElement>
        ),
        position: "right",
        onBeforeChange: (index) => {
          var element = document.querySelector("#menuAccountManagement");
          if (element.childElementCount > 1 == false)
            $("#menuAccountManagement")[0].firstElementChild.click();
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "[data-fa-button='SOCIAL_MEDIA_ADD']", // todo: gosterilecek elementi en uste kaydiriyor, digerleriyle cakisiyor.
        intro: (
          <TutorialElement
            icon="click"
            text={"Sosyal Medya hesaplarınızı eklemek için tıklayın."}
          />
        ),
        comp: "AccountInteraction",
        onBeforeChange: (index) => {
          // document.querySelector("#root > div > main").scrollTo(0, 0);
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "[data-fa-section='SOCIAL_MEDIA_EDIT']",
        intro: (
          <TutorialElement icon="click">
            {t(
              "Dilediğiniz Sosyal Medya Hesabınıza ait URL’yi girin ve Kaydet’e basın."
            )}
          </TutorialElement>
        ),
        comp: "AccountInteraction",
        onBeforeChange: (index) => {
          $("[data-fa-button='SOCIAL_MEDIA_ADD']").click();
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "[data-fa-section='SOCIAL_MEDIA_LIST']",
        intro: (
          <TutorialElement icon="click">
            {t("Sosyal medya hesaplarınızı bu alanda görebilirsiniz.")}
            <br />
            {
              "Artık müşterilerinizin beğenilerini Sosyal Medya hesaplarınıza yönlendirmeye hazırsınız."
            }
            .
          </TutorialElement>
        ),
        comp: "AccountInteraction",
        onBeforeChange: (index) => {
          $("[aria-label='close']")[0].click();
          stepsRef.current.updateStepElement(index);
        },
      },
    ]);
  }, []);

  return (
    <div>
      {steps &&
        steps.length > stepIndex &&
        steps[stepIndex].comp === "AccountInteraction" && <AccountDetails />}

      <ThingsSteps
        code={code}
        steps={steps}
        ref={stepsRef}
        onStepChange={setStepIndex}
        afterComplete={() => window.history.back()}
      />
    </div>
  );
}
