import {
  faCubes,
  faFolder,
  faGlobe,
  faHashtag,
  faSitemap,
  faTrash,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardHeader, Chip } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { Edit } from "@material-ui/icons";
import { default as React, Suspense, useEffect, useState } from "react";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { SurveyService } from "services/SurveyService";
import { ThingDesc, isTNF } from "services/utils";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import SurveyTargetEdit from "./SurveyTargetEdit";
import { useStyles } from "./style";

export default function SurveyTarget(props) {
  const { survey } = props;

  const { t } = useTranslation("survey", { i18n });
  const { id } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [targets, setTargets] = useState();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    refresh();
  }, [survey]);

  const refresh = () => {
    setLoading(true);
    SurveyService.findTargets(id, findTargetsOnSuccess, findTargetsOnError);
  };
  const columns = [
    {
      field: "user",
      label: t("USER"),
      align: "center",
      width: "90%",
      valueFormatter: (value, row) => {
        return row.user ? row.user.fullname : row.userGroup?.name;
      },
    },
    {
      field: "id",
      label: t("İşlem"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        return (
          <IconButton
            //onClick={() => deleteUser(row)}
            size="small"
            color="primary"
            aria-label={t("Sil")}
            //disabled={message.status !== "DRAFT"}
          >
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        );
      },
    },
  ];
  const findTargetsOnSuccess = (data) => {
    setTargets(data);
    setLoading(false);
  };

  const findTargetsOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };

  const renderTargets = () => {
    if (survey == null || targets == null || targets.length === 0) {
      return <div>Kayıt bulunamadı.</div>;
    } else
      return (
        <ul className={classes.chipRoot}>
          {targets?.map((target, index) => {
            let icon;
            let label;

            if (target.thingClass) {
              icon = <FontAwesomeIcon icon={faSitemap} />;
              label = target.thingClass.className;
            } else if (target.thing) {
              icon = <FontAwesomeIcon icon={faCubes} />;
              label = ThingDesc(target.thing);
            } else if (target.hashtag) {
              icon = <FontAwesomeIcon icon={faHashtag} />;
              label = target.hashtag;
            } else if (target.organization) {
              icon = <FontAwesomeIcon icon={faFolder} />;
              label = target.organization.name;
            } else if (target.location) {
              icon = <FontAwesomeIcon icon={faGlobe} />;
              label = target.location.name;
            } else if (target.allThings) {
              icon = <FontAwesomeIcon icon={faUser} />;
              label = isTNF(t("ALL_THINGS"), t("ALL_THINGS_T2C"));
            }

            return (
              <li key={index}>
                <Chip icon={icon} label={label} className={classes.chip} />
              </li>
            );
          })}
        </ul>
      );
  };
  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <SurveyTargetEdit
          surveyId={survey.id}
          targets={targets}
          onSave={() => {
            refresh();
            setIsEditFormOpen(false);
          }}
          onClose={() => {
            setIsEditFormOpen(false);
          }}
          onDelete={() => {
            refresh();
            setIsEditFormOpen(false);
          }}
          open={isEditFormOpen}
        />
        <Card style={{ minHeight: 204 }}>
          <CardHeader
            action={
              <IconButton
                aria-label="settings"
                disabled={survey.status !== "PASSIVE"}
                onClick={() => {
                  setIsEditFormOpen(true);
                }}
              >
                <Edit />
              </IconButton>
            }
            title={t("SURVEY_SCOPE")}
            data-fa-section="SURVEY_SCOPE"
          />
          <CardContent>
            <Grid item container spacing={1}>
              {renderTargets()}
            </Grid>
          </CardContent>
        </Card>
      </Suspense>
    </>
  );
}
