import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    padding10: {
        padding: "14.5px 14px",
    },
    w100: {
        width: "100%",
    }
}));