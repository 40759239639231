import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { ACCESS_LOG } from "./faApi";

const populateVisitorHeaders = (req) => {
  if (localStorage.getItem("visitorId")) {
    req.header = req.header
      ? { ...req.header, T_VisitorId: localStorage.getItem("visitorId") }
      : { T_VisitorId: localStorage.getItem("visitorId") };
  }
  if (localStorage.getItem("location1")) {
    req.header = req.header
      ? { ...req.header, T_Location1: localStorage.getItem("location1") }
      : { T_Location1: localStorage.getItem("location1") };
  }
  if (localStorage.getItem("location2")) {
    req.header = req.header
      ? { ...req.header, T_Location2: localStorage.getItem("location2") }
      : { T_Location2: localStorage.getItem("location2") };
  }
};

export const AccessLogService = {
  save: (accessLog, callback, errorCallback) => {
    let reqBody = {
      ...accessLog,
    };

    let req = requests.getSimplePostRequest(ACCESS_LOG.save, reqBody);
    populateVisitorHeaders(req);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(ACCESS_LOG.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
