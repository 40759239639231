import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { default as React, Suspense, useEffect, useState } from "react";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { MessagesService } from "services/MessagesService";
import { getDateTimeWFormat } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";

export default function InboxDetail() {
  const labelClasses = labelStyles();
  const { t } = useTranslation("messages", { i18n });
  const { id } = useParams();
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});

  useEffect(() => {
    setLoading(true);
    MessagesService.findById(
      id,
      (data) => {
        setMessage(data);
        setLoading(false);
      },
      (error) => {
        showAlert(error.message, "error");
        setLoading(false);
      }
    );
  }, [id]);

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <div>
          <Grid container spacing={1}>
            <Grid item lg={12}>
              <Grid container spacing={1}>
                <Grid item lg={12}>
                  <Card>
                    <CardHeader title="Mesaj Detay" />
                    <CardContent>
                      <Grid item container spacing={0}>
                        <Grid item xs={12}>
                          <Grid
                            item
                            container
                            spacing={1}
                            style={{ marginLeft: 10, marginRight: 10 }}
                          >
                            <Grid
                              item
                              xs={12}
                              className={labelClasses.LabelRow}
                            >
                              <Typography className={labelClasses.LabelHeader}>
                                {t("Tarih")}
                              </Typography>
                              <Typography className={labelClasses.LabelValue}>
                                {getDateTimeWFormat(message.messageDate)}
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              className={labelClasses.LabelRow}
                            >
                              <Typography className={labelClasses.LabelHeader}>
                                {t("Konu")}
                              </Typography>
                              <Typography className={labelClasses.LabelValue}>
                                {message.subject}
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              className={labelClasses.LabelLastRow}
                            >
                              <Typography className={labelClasses.LabelHeader}>
                                {t("İçerik")}
                              </Typography>
                              <Typography className={labelClasses.LabelValue}>
                                {message.text}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Suspense>
    </>
  );
}
