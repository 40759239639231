import React, { Suspense, useEffect } from "react";
import { getUserInfo, setRole } from "react-base-fa/dist/services/sessionSlice";
import { useDispatch, useSelector } from "react-redux";
import { AccUserService } from "services/AccUserService";
import LoadingBar from "../../components/loadingBar";

export default function IyzicoCallback() {
  const dispatch = useDispatch();

  const userInfo = useSelector(getUserInfo);

  const accountId = localStorage.accountId;

  useEffect(() => {
    AccUserService.findAccUserRole(
      accountId,
      userInfo.id,
      findAccUserRoleOnSuccess,
      () => {}
    );
  }, []);

  const findAccUserRoleOnSuccess = (data) => {
    dispatch(setRole(data));
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <div
          style={{
            backgroundColor: "green",
            padding: "16px",
            border: "1px solid #cfdae0",
            borderRadius: "4px",
          }}
        >
          Ödemeniz başarıyla alınmıştır.
        </div>
      </Suspense>
    </>
  );
}
