import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import { isTNF } from "services/utils";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function HomepageItemTypeSelect(props) {
  const { value, onChange, required, label } = props;
  const classes = useStyles();
  const { t } = useTranslation("components", { i18n });

  return (
    <FormControl variant="outlined" className={classes.w100} size="small">
      <InputLabel>{label ?? t("Birim")}</InputLabel>
      <Select
        // classes={{
        //   outlined: classes.padding10,
        // }}
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        required={required}
        label={label ?? t("Birim")}
      >
        <MenuItem value={null}>...</MenuItem>
        <MenuItem value={"THINGS"}>
          {isTNF(t("HOMEPAGE_ITEM.THINGS"), t("HOMEPAGE_ITEM.THINGS_T2C"))}
        </MenuItem>
        <MenuItem value={"THINGS_12_MONTH_CHART"}>
          {isTNF(
            t("HOMEPAGE_ITEM.THINGS_12_MONTH_CHART"),
            t("HOMEPAGE_ITEM.THINGS_12_MONTH_CHART_T2C")
          )}
        </MenuItem>
        <MenuItem value={"ISSUES_CHART"}>
          {t("HOMEPAGE_ITEM.ISSUES_CHART")}
        </MenuItem>
        <MenuItem value={"MY_THINGS"}>
          {isTNF(
            t("HOMEPAGE_ITEM.MY_THINGS"),
            t("HOMEPAGE_ITEM.MY_THINGS_T2C")
          )}
        </MenuItem>
        <MenuItem value={"CALENDAR"}>{t("HOMEPAGE_ITEM.CALENDAR")}</MenuItem>
        <MenuItem value={"TODO"}>{t("HOMEPAGE_ITEM.TODO")}</MenuItem>

        <MenuItem value={"ISSUE_LIST"}>
          {t("HOMEPAGE_ITEM.ISSUE_LIST")}
        </MenuItem>
        <MenuItem value={"LIKE_LIST"}>{t("HOMEPAGE_ITEM.LIKE_LIST")}</MenuItem>
        <MenuItem value={"SUGGESTION_LIST"}>
          {t("HOMEPAGE_ITEM.SUGGESTION_LIST")}
        </MenuItem>
        <MenuItem value={"SURVEY_JOIN_LIST"}>
          {t("HOMEPAGE_ITEM.SURVEY_JOIN_LIST")}
        </MenuItem>
        <MenuItem value={"CAMPAIGN_JOIN_LIST"}>
          {t("HOMEPAGE_ITEM.CAMPAIGN_JOIN_LIST")}
        </MenuItem>
        <MenuItem value={"ISSUE_REPORT_BUTTON"}>
          {t("HOMEPAGE_ITEM.ISSUE_REPORT_BUTTON")}
        </MenuItem>
        <MenuItem value={"ISSUE_REPORT_FORM"}>
          {t("HOMEPAGE_ITEM.ISSUE_REPORT_FORM")}
        </MenuItem>
        <MenuItem value={"ANNOUNCEMENTS"}>
          {t("HOMEPAGE_ITEM.ANNOUNCEMENTS")}
        </MenuItem>
        <MenuItem value={"USER_LOCATION"}>
          {t("HOMEPAGE_ITEM.USER_LOCATION")}
        </MenuItem>
      </Select>
    </FormControl>
  );
}
