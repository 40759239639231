import { Box, Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";
import "react-puzzle-confirm/react-puzzle-confirm.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function BeneficiaryLoginLayout(props) {
  const { appLogo, children } = props;

  return (
    <Box display="flex" alignItems="center">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        component={Paper}
        elevation={6}
        style={{
          minHeight: "50vh",
          width: "70vw",
          margin: "10vw",
          padding: "5px",
        }}
      >
        <div>
          <Grid
            item
            container
            spacing={3}
            lg={8}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
              <img
                src={appLogo}
                width="250"
                height="92"
                alt="logo"
                //className={classes.appLogo}
              />
            </Grid>
          </Grid>
          <Grid item lg={10} xs={12}>
            {children}
          </Grid>
        </div>
      </Box>
    </Box>
  );
}

BeneficiaryLoginLayout.propTypes = {
  appLogo: PropTypes.any,
};
