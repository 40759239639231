import { Box, Paper, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import i18n from "i18n";
import PropTypes from "prop-types";
import React, { createRef, useEffect, useState } from "react";
import useStyles from "react-base-fa/dist/fa/faUnAuthPages/style";
import { useTranslation } from "react-i18next";
import "react-puzzle-confirm/react-puzzle-confirm.css";
import { useDispatch } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getDateTimeWFormat } from "services/utils";
import { ReportService } from "../../../services/report/reportService";

export default function PublicIssueStatus(props) {
  const { appLogo } = props;
  const { id, thingId, accountId } = useParams();
  const classes = useStyles();
  const { t } = useTranslation("report", { i18n });
  const history = useHistory();
  const formRef = createRef();

  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [publicThing, setPublicThing] = useState(location?.state?.publicThing);

  const [visitorId, setVisitorId] = useState(null);

  const [issue, setIssue] = useState(null);

  useEffect(() => {
    ReportService.getIssue(
      id,
      thingId,
      accountId,
      getIssueOnSuccess,
      (error) => {}
    );
  }, []);

  const getIssueOnSuccess = (data) => {
    setLoading(true);
    setIssue(data);
  };

  const dispatch = useDispatch();

  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ minHeight: "100vh", width: "100vw" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        component={Paper}
        elevation={6}
        style={{
          minHeight: "100vh",
          width: "100vw",
          margin: "10px",
          paddingLeft: "5px",
          paddingRight: "5px",
        }}
      >
        <div>
          <Grid
            item
            container
            spacing={3}
            lg={8}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
              <img
                src={appLogo}
                width="250"
                height="92"
                alt="logo"
                className={classes.appLogo}
              />
            </Grid>
          </Grid>
          <Grid item lg={10} xs={12} style={{ textAlign: "center" }}></Grid>
        </div>
        {!loading && !issue ? (
          <Grid
            item
            container
            spacing={0}
            lg={8}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
              :( Üzgünüz, aradığınız bildirim bulunamadı.
            </Grid>
          </Grid>
        ) : null}
        {loading && issue && (
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
              <Typography variant="h5">{t("Bildirim Durumu")}</Typography>
            </Grid>
            <Grid
              item
              lg={10}
              xs={12}
              style={{ textAlign: "left", paddingLeft: 20 }}
            >
              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  {"Bildirim Tarihi: " +
                    getDateTimeWFormat(issue.notificationDate)}
                </Grid>
                <Grid item xs={12}>
                  {"Bildirim Durumu: " + t("ISSUE_STATUS_" + issue.status)}
                </Grid>
                <Grid item xs={12}>
                  {"Kapatılma Tarihi: " +
                    (issue.closeDate
                      ? getDateTimeWFormat(issue.closeDate)
                      : "")}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}

PublicIssueStatus.propTypes = {
  loginPath: PropTypes.string,
  appLogo: PropTypes.any,
};
