import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ThingClassAutoComplete from "components/autocomplete/ThingClassAutoComplete";
import VendorAutoComplete from "components/autocomplete/VendorAutoComplete";
import ThingHashtag from "components/hashtag/ThingHashtag";
import ThingProductTypeSelect from "components/select/ThingProductTypeSelect";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaDatePicker, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { AccPreferencesService } from "services/accPreferences/accPreferencesService";
import { getThingsPackage, ThingDesc } from "services/utils";
import i18n from "../../i18n";
import { ThingService } from "../../services/thing/thingService";
import { useStyles } from "./ThingDetailCardStyle";

const ThingEdit = forwardRef((props, ref) => {
  const { thing, onUpdate } = props;
  const { t } = useTranslation(["thingDetail", "errorMessages"], { i18n });
  const classes = useStyles();
  const THINGS_PACKAGE = getThingsPackage();
  const faDialog = useDialog();
  const dispatch = useDispatch();
  let history = useHistory();
  const [uniqueThingIdentifier, setUniqueThingIdentifier] = useState(null);
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsThingUpdateFormOpen(true);
    },
  }));

  const [thingDTO, setThingDTO] = useState({ ...thing });

  useEffect(() => {
    setThingDTO({ ...thing });

    if (thing && thing.account && thing.account.id) {
      AccPreferencesService.findByAccount(thing.account.id, (data) => {
        setUniqueThingIdentifier(data?.uniqueThingIdentifier);
      });
    }
  }, [thing]);

  const handleChangeV = (prop, val) => {
    setThingDTO({ ...thingDTO, [prop]: val });
  };

  const [isThingUpdateFormOpen, setIsThingUpdateFormOpen] = useState(false);

  const updateThing = () => {
    if (uniqueThingIdentifier === "FORCE" || uniqueThingIdentifier === "WARN") {
      ThingService.checkThingDesc(
        thingDTO.account.id,
        thingDTO.id ?? -1,
        ThingDesc(thingDTO),
        checkThingDescSuccess,
        (error) => {
          showAlert(error, "error");
        }
      );
    } else saveForm();
  };

  const saveForm = () => {
    ThingService.update({ ...thingDTO }, updateSuccessful, updateFail);
  };

  const checkThingDescSuccess = (data) => {
    if (data) saveForm();
    else if (uniqueThingIdentifier === "WARN") {
      faDialog({
        description: t(t("UNIQUE_THING_IDENTIFIER_WARNING")),
        type: "confirm",
      })
        .then((confirmation) => {
          saveForm();
        })
        .catch((err) => console.log("error:", err));
    } else if (uniqueThingIdentifier === "FORCE")
      showAlert(t("UNIQUE_THING_IDENTIFIER_ERROR"), "error");
  };

  const updateSuccessful = (newData) => {
    onUpdate(newData);
    setIsThingUpdateFormOpen(false);
  };

  const updateFail = (error) => {
    console.log("error", error);
    showAlert(
      t("errorMessages:" + error?.message ?? "UNEXPECTED_ERROR"),
      "error"
    );
  };

  const onDelete = (data) => {
    faDialog({
      description: t(t("DELETE_CONFIRMATION")),
      type: "confirm",
    })
      .then((confirmation) => {
        ThingService.delete(
          thingDTO,
          (data) => {
            showAlert(t("DELETE_SUCCESS"), "success");
            const tmpTo = {
              pathname: `/things`,
              breadCrumbKey: `/things`,
              search: "?page=0&size=10&isAsc=false&sort=createDate",
            };
            history.push(tmpTo);
          },
          (error) => {}
        );
      })
      .catch((err) => console.log("error:", err));
  };

  return (
    <FaDialog
      title={t("Temel Bilgiler Güncelle Başlık")}
      faOpen={isThingUpdateFormOpen}
      faOnSubmit={updateThing}
      showSaveButton
      faHandleClose={(data) => {
        setIsThingUpdateFormOpen(data);
      }}
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={0}
        data-fa-section="THING_EDIT_DIALOG"
      >
        {thingDTO.productType && (
          <Grid item xs={12}>
            <ThingProductTypeSelect
              required={true}
              value={thingDTO.productType}
              onChange={(data) => {
                setThingDTO({
                  ...thingDTO,
                  ["productType"]: data,
                });
              }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <ThingClassAutoComplete
            required={true}
            value={thingDTO.thingClass}
            accountId={thingDTO?.account?.id}
            onChange={(data) => {
              setThingDTO({
                ...thingDTO,
                ["thingClass"]: data,
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FaInput
            label={t("DESCRIPTION")}
            onChange={(event) =>
              handleChangeV("description", event.target.value)
            }
            value={thingDTO.description || ""}
            inputProps={{
              maxLength: 255,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FaInput
            label={t("PUBLIC_IDENTIFIER")}
            onChange={(event) =>
              handleChangeV("publicIdentifier", event.target.value)
            }
            inputProps={{
              maxLength: 255,
            }}
            value={thingDTO.publicIdentifier || ""}
          />
        </Grid>
        <Grid item xs={12}>
          <FaInput
            label={t("THING_CODE")}
            onChange={(event) => handleChangeV("thingCode", event.target.value)}
            value={thingDTO.thingCode || ""}
            inputProps={{
              maxLength: 255,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <ThingHashtag
            label={t("HASHTAG")}
            accountId={thingDTO?.account?.id}
            value={thingDTO.hashtag}
            onChange={(data) => {
              setThingDTO({
                ...thingDTO,
                hashtag: data,
              });
            }}
          />
        </Grid>
        {!!!thingDTO.productType && THINGS_PACKAGE === "things2care" && (
          <Grid item xs={12}>
            <VendorAutoComplete
              value={thingDTO.vendor}
              accountId={thingDTO?.account?.id}
              onChange={(data) => {
                handleChangeV("vendor", data);
              }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FaDatePicker
            label={t("SERVICE_START_DATE")}
            faType="date"
            name="serviceStartDate"
            value={thingDTO.serviceStartDate}
            faOnChange={(val) => {
              handleChangeV("serviceStartDate", val);
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button onClick={onDelete} color="secondary">
            {t("Sil")}
          </Button>
        </Grid>
      </Grid>
    </FaDialog>
  );
});

export default ThingEdit;
