import { CardHeader } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AddIcon from "@material-ui/icons/Add";
import NoImage from "components/ui/NoImage";
import React, { useEffect, useRef, useState } from "react";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { SolutionService } from "services/SolutionService";
import i18n from "../../i18n";
import SolutionPhotosCarousel from "./SolutionPhotosCarousel";
import { useStyles } from "./style";

export default function SolutionPhotos(props) {
  const { solution } = props;
  const classes = useStyles();
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const { t } = useTranslation("solutions", { i18n });
  const fileInputRef = useRef();
  const [selectedHdImageIndex, setSelectedHdImageIndex] = useState(0);
  const [solutionHdImages, setSolutionHdImages] = useState([]);
  const [images, setImages] = useState([]);
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    SolutionService.downloadThumbnails(
      solution.id,
      downloadThumbnailsOnSuccess,
      downloadThumbnailsOnError
    );
  };

  const downloadThumbnailsOnSuccess = (data) => {
    setImages(data);
  };

  const downloadThumbnailsOnError = (error) => {
    console.log(error);
  };

  const handleChange = (event) => {
    event.preventDefault();
    if (event?.target?.files?.length > 0) {
      SolutionService.uploadFile(
        solution.id,
        event.target.files,
        uploadFileOnSuccess,
        uploadFileOnError
      );
    }
  };

  const uploadFileOnSuccess = () => {
    refresh();
  };

  const uploadFileOnError = () => {
    showAlert(t("Disk full"), "error");
  };

  const [isCarouselOpen, setCarouselOpen] = useState(false);

  const openCarousel = (index) => {
    getsolutionHdImages();
    setSelectedHdImageIndex(index);
  };

  const closeCarousel = () => {
    setCarouselOpen(false);
  };

  const getsolutionHdImages = () => {
    SolutionService.downloadFiles(
      solution.id,
      downloadFilesOnSuccess,
      downloadFilesOnError
    );
  };

  const downloadFilesOnSuccess = (data) => {
    setSolutionHdImages(data);
    setCarouselOpen(true);
  };

  const downloadFilesOnError = (error) => {
    console.log(error);
  };

  return (
    <>
      <Dialog
        open={isCarouselOpen}
        aria-labelledby="form-dialog-title"
        onClose={closeCarousel}
      >
        <DialogContent>
          <SolutionPhotosCarousel
            images={solutionHdImages}
            selectedItem={selectedHdImageIndex}
          />
        </DialogContent>
      </Dialog>

      <Card style={{ height: 330, overflowY: "auto" }}>
        <CardHeader
          action={
            <IconButton aria-label="settings">
              <AddIcon onClick={() => fileInputRef.current.click()} />
            </IconButton>
          }
          title={t("PHOTOS")}
          data-fa-section="PHOTOS"
        />
        <CardContent>
          <Grid item container spacing={1}>
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <input
                onChange={handleChange}
                multiple
                ref={fileInputRef}
                type="file"
                hidden
              />
            </div>
            {(!images || images.length === 0) && <NoImage />}
            <ImageList
              rowHeight={140}
              className={classes.imageList}
              cols={lg ? 4 : 2}
            >
              {images.map((item, index) => (
                <ImageListItem key={index} cols={images.length === 1 ? 2 : 1}>
                  <img
                    src={`data:image/png;base64, ${item.content}`}
                    alt={item.object}
                    onClick={() => {
                      openCarousel(index);
                    }}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
