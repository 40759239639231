import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { TeamRolesService } from "services/TeamRolesService";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import TeamRolesEdit from "./TeamRolesEdit";

export default function TeamRolesDetail() {
  const tableRef = useRef();
  const labelClasses = labelStyles();
  const { t } = useTranslation(["teamRoles", "components"], { i18n });
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    setLoading(true);
    TeamRolesService.findById(
      id,
      (data) => {
        setTeamRole(data);
        setLoading(false);
      },
      (error) => {
        showAlert(error.message, "error");
        setLoading(false);
      }
    );
  };

  const [teamRole, setTeamRole] = useState(null);

  const editButtonClicked = () => {
    setIsEditFormOpen(true);
  };

  const teamRoleOnChange = () => {
    setIsEditFormOpen(false);
    refresh();
  };

  const teamRoleOnDelete = () => {
    history.goBack();
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <div>
          <TeamRolesEdit
            onChange={teamRoleOnChange}
            onDelete={teamRoleOnDelete}
            onClose={() => {
              setIsEditFormOpen(false);
            }}
            value={teamRole}
            open={isEditFormOpen}
          />
          <Grid container spacing={1}>
            <Grid item lg={12}>
              <Card>
                <CardHeader
                  action={
                    <IconButton
                      aria-label="settings"
                      onClick={editButtonClicked}
                    >
                      <Edit />
                    </IconButton>
                  }
                  title="Takım Rolü Bilgileri"
                />
                <CardContent>
                  <Grid item container spacing={1}>
                    <Grid item xs={12} className={labelClasses.LabelRow}>
                      <Typography className={labelClasses.LabelHeader}>
                        {t("Takım Rolü")}
                      </Typography>
                      <Typography className={labelClasses.LabelValue}>
                        {teamRole?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Suspense>
    </>
  );
}
