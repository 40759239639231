import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { IssueService } from "services/issue/issueService";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";

export default function IssueConfirmReject(props) {
  const { issueId, onClose, onChange } = props;
  const { t } = useTranslation("issueDetail", { i18n });
  const labelClasses = labelStyles();
  const faDialog = useDialog();

  const [rejectDescription, setRejectDescription] = useState(null);

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const userReject = () => {
    IssueService.rejectConfirmation(
      issueId,
      rejectDescription,
      () => {
        showAlert("İtirazınız gönderilmiştir.", "success");
        onChange();
      },
      () => {
        showAlert("Bir hata ile karşılaşıldı.", "error");
      }
    );
  };

  return (
    <FaDialog
      title={t("REJECT_CONFIRMATION")}
      faOpen={true}
      faOnSubmit={userReject}
      showSaveButton
      saveButtonTitle={"Geri Gönder"}
      faHandleClose={(data) => {
        onClose();
      }}
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item xs={12}>
          <FaInput
            label={t("DESCRIPTION")}
            faType="description"
            inputProps={{ maxLength: 255 }}
            onChange={(event) => setRejectDescription(event.target.value)}
            value={rejectDescription || ""}
          />
        </Grid>
      </Grid>
    </FaDialog>
  );
}
