import { Help } from "@material-ui/icons";
import CustomerInteractions from "features/customerInteraction";
import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import i18n from "../../i18n";
import { useStyles } from "./QuickStartTutorialStyle";
import ThingsSteps from "./ThingsSteps";
import TutorialElement from "./TutorialElement";

export default function FollowInteractionsTutorial() {
  const { code } = useParams();
  const classes = useStyles();

  const stepsRef = useRef();
  let history = useHistory();

  const { t } = useTranslation("tutorialFollowInteractions", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        element: "#menuCustomerInteraction", // parent menu
        intro: (
          <TutorialElement
            icon="click"
            text={"Etkileşimleri izlemek için  tıklayın."}
          />
        ),
        position: "right",
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "#menuCustomerInteractions", // child menu
        intro: (
          <TutorialElement header="Menu" icon="click">
            {t("Tüm etkileşimleri Liste halinde izlemek için tıklayın")}.
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          var element = document.querySelector("#menuCustomerInteraction");
          if (element.childElementCount > 1 == false)
            $("#menuCustomerInteraction")[0].firstElementChild.click();
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element:
          "#root > div > header > div > div > div:nth-child(3) > button:nth-child(5)",
        intro: (
          <TutorialElement icon="info">
            {
              "Tüm etkileşimleri Tarih ve İşlemlere göre sıralamak, etkileşim detaylarını görmek için "
            }{" "}
            <b>Tümü</b> sayfasındaki {"yardıma"} <Help />{" "}
            {" tıklayın ve yönergeleri takip edin."}
          </TutorialElement>
        ),
        comp: "CustomerInteractions",
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
    ]);
  }, []);

  return (
    <div>
      {steps &&
        steps.length > stepIndex &&
        steps[stepIndex].comp === "CustomerInteractions" && (
          <CustomerInteractions />
        )}

      <ThingsSteps
        code={code}
        steps={steps}
        ref={stepsRef}
        onStepChange={setStepIndex}
        afterComplete={() => {
          window.history.back();
          $("#menuCustomerInteraction")[0].firstElementChild.click();
        }}
      />
    </div>
  );
}
