import Grid from "@material-ui/core/Grid";
import Adsense from "components/adsense/Adsense";
import React, { Suspense, useEffect, useState } from "react";
import { AccPreferencesService } from "services/accPreferences/accPreferencesService";
import LoadingBar from "../../components/loadingBar";
import AccountPreferencesFeedback from "./AccountPreferencesFeedback";

export default function AccountNotifications() {
  const accountId = localStorage.accountId;

  const [accountPreferences, setAccountPreferences] = useState({});

  useEffect(() => {
    getAccPreferences();
  }, [accountId]);

  const getAccPreferences = () => {
    AccPreferencesService.findByAccount(
      accountId,
      (data) => {
        setAccountPreferences(data);
      },
      (error) => {}
    );
  };

  const accPreferencesUpdated = () => {
    getAccPreferences();
  };

  return (
    <Suspense fallback={<LoadingBar />}>
      <Grid container spacing={1}>
        <Adsense value="RklmUst" />
        <Grid item lg={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <AccountPreferencesFeedback
                accountPreferences={accountPreferences}
                onSave={accPreferencesUpdated}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Suspense>
  );
}
