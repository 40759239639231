import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { INVITATION } from "./faApi";

export const InvitationService = {
  invitation: (location, callback, errorCallback) => {
    let reqBody = {
      ...location,
    };

    let req = requests.getSimplePostRequest(INVITATION.invitation, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  acception: (invitation, callback, errorCallback) => {
    let reqBody = {
      ...invitation,
    };

    let req = requests.getSimplePostRequest(INVITATION.acception, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  rejection: (invitation, callback, errorCallback) => {
    let reqBody = {
      ...invitation,
    };

    let req = requests.getSimplePostRequest(INVITATION.rejection, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (invitation, callback, errorCallback) => {
    let reqBody = {
      ...invitation,
    };

    let req = requests.getSimplePostRequest(INVITATION.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  checkAddNewUser: (accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      INVITATION.checkAddNewUser + "?accountId=" + accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
