import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { BENEFICIARY_PORTAL } from "./faApi";

export const BeneficiaryPortalService = {
  getLoginType: (callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(BENEFICIARY_PORTAL.getLoginType);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response);
      });
    return () => {
      //setLoading(false);
    };
  },

  login: (form, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(BENEFICIARY_PORTAL.login);

    FaAxios(req)
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        errorCallback(error?.response);
      });
    return () => {
      //setLoading(false);
    };
  },

  getCampaign: (id, accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      BENEFICIARY_PORTAL.getCampaign + "/" + id + "/" + accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  checkCampaignAccess: (
    id,
    campaignId,
    accountId,
    email,
    phone,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(
      BENEFICIARY_PORTAL.checkCampaignAccess +
        "/" +
        id +
        "/" +
        campaignId +
        "/" +
        accountId,
      { email, phone }
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getCampaignCustomerStatus: (
    id,
    campaignId,
    accountId,
    email,
    phone,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(
      BENEFICIARY_PORTAL.getCampaignCustomerStatus +
        "/" +
        id +
        "/" +
        campaignId +
        "/" +
        accountId,
      { email, phone }
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getCustomerInfo: (accId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      BENEFICIARY_PORTAL.getCustomerInfo + "?accId=" + accId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  getCampaignCustomer: (id, accId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      BENEFICIARY_PORTAL.getCampaignCustomer + "/" + id + "/" + accId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  createCustomerThing: (accId, thingId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      BENEFICIARY_PORTAL.createCustomerThing +
        "?accId=" +
        accId +
        "&thingId=" +
        thingId
    );

    FaAxios(req)
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        errorCallback(error?.response);
      });
    return () => {
      //setLoading(false);
    };
  },
};
