import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { PATROL_DEF_USER } from "./faApi";

export const PatrolDefUserService = {
  save: (patrolDefUser, callback, errorCallback) => {
    let reqBody = {
      ...patrolDefUser,
    };

    let req = requests.getSimplePostRequest(PATROL_DEF_USER.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findByPatrolDef: (patrolDefId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(PATROL_DEF_USER.findByPatrolDef, {
      patrolDefId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (patrolDefUser, callback, errorCallback) => {
    let reqBody = {
      ...patrolDefUser,
    };

    let req = requests.getSimplePostRequest(PATROL_DEF_USER.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
