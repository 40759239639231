import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import ThingsDataTable from "components/thingsDataTable";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PeriodicReportService } from "services/PeriodicReportService";
import i18n from "../../i18n";

export default function PeriodicReportLog(props) {
  const { periodicReport } = props;
  const { t } = useTranslation("periodicReport", { i18n });
  const tableRef = useRef();

  const [logs, setLogs] = useState([]);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    PeriodicReportService.findLogs(
      periodicReport.id,
      findLogsOnSuccess,
      findLogsOnError
    );
  };

  const findLogsOnSuccess = (data) => {
    setLogs(data.list);
  };
  const findLogsOnError = () => {};

  const columns = [
    {
      field: "createDate",
      label: t("CREATE_DATE"),
      align: "center",
      width: "100%",
      type: "dateTime",
    },
  ];

  return (
    <>
      <Grid item container spacing={1}>
        <Grid item lg={12}>
          <Card>
            <CardHeader title={t("LOG")} data-fa-section="LOG" />

            <ThingsDataTable
              data={logs}
              columns={columns}
              ref={tableRef}
              showHeaderText={false}
              queryParams={{
                page: 0,
                size: 50,
                sort: "id",
                isAsc: true,
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
