import { CardHeader } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { default as React, useRef } from "react";
import { getDateTimeWFormat } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./ThingDetailCardStyle";
import ThingEdit from "./ThingEdit";

export default function ThingDetailCard(props) {
  const { thing, onUpdate } = props;
  const { t } = useTranslation("thingDetail", { i18n });
  const classes = useStyles();
  const dialogRef = useRef();

  const onThingInfoUpdate = (newData) => {
    onUpdate(newData);
  };

  const thingEditDialogRender = () => {
    return (
      <ThingEdit
        thing={thing}
        ref={dialogRef}
        onUpdate={onThingInfoUpdate}
      ></ThingEdit>
    );
  };

  const openEditDialog = () => {
    dialogRef.current.openDialog();
  };

  return (
    <>
      <Card style={{ minHeight: 250 }}>
        <CardHeader
          action={
            <IconButton aria-label="settings">
              <EditIcon
                onClick={() => {
                  openEditDialog();
                }}
              />
            </IconButton>
          }
          title="Temel Bilgiler"
        />
        <CardContent>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <Grid item container spacing={1}>
                {/* Description */}
                <Grid item xs={3} className={classes.ThingDetailLabelHeader}>
                    {t("DESCRIPTION")}
                </Grid>
                <Grid item xs={9} className={classes.ThingDetailLabelHeader}>
                  {thing?.description}
                </Grid>

                {/* Class Name */}
                <Grid item xs={3} className={classes.ThingDetailLabelHeader}>
                  {t("CLASS_NAME")}
                </Grid>
                <Grid item xs={9} className={classes.ThingDetailLabelHeader}>
                  {thing?.thingClass?.className}
                </Grid>
                
                <Grid item xs={3} className={classes.ThingDetailLabelHeader} style={{ borderBottom: "none"}}>
                  
                    Sipariş Tarihi
                  
                </Grid>
                <Grid item xs={9} className={classes.ThingDetailLabelHeader} style={{ borderBottom: "none"}}>
                  
                    {getDateTimeWFormat(thing?.createDate)}
                  
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {thingEditDialogRender()}
    </>
  );
}
