import {
  default as React,
  forwardRef,
  Suspense,
  useImperativeHandle,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import LoadingBar from "../../../components/loadingBar";
import i18n from "../../../i18n";
import { ThingStatisticService } from "../../../services/statistic/statisticService";
import GenericBarChart from "../ui/GenericBarChart";

const ThingScanCountStat = forwardRef((props, ref) => {
  const { settings, thingId } = props;
  const barChartRef = useRef();
  const { t } = useTranslation("statistic", { i18n });
  const accountId = localStorage.accountId;

  var today = new Date();
  const statSettings = {
    start: new Date(today.setDate(today.getDate() - 14)),
    end: null,
    period: "DAY",
    cumulative: false,
    dateRange: 4,
    dateRangeType: 2,
    thingClassId: null,
    ...settings,
  };

  const countLabel = t("SAYI");

  const getData = (settings, getDataOnSuccess, getDataOnError) => {
    if (accountId && thingId) {
      ThingStatisticService.thingScanCountByDate(
        accountId,
        thingId,
        settings,
        countLabel,
        getDataOnSuccess,
        getDataOnError
      );
    }
  };
  const renderChart = () => {
    return (
      <div data-fa-section="THING_INTERACTION_COUNT">
        <GenericBarChart
          ref={barChartRef}
          title={t("THING_INTERACTION_STATISTICS")}
          getData={getData}
          bAxisLabel={t("Gün")}
          lAxisLabel={t("QR Okuma")}
          menuDate
          menuPeriod
          settings={statSettings}
          menuList
          helpCode="thingDetail.thingScanCountStat"
        />
      </div>
    );
  };

  useImperativeHandle(ref, () => ({
    refreshChart(newSettings) {
      console.log("refreshChart", newSettings);
      barChartRef.current.refreshChart(newSettings);
    },
  }));

  return (
    <>
      <Suspense fallback={<LoadingBar />}>{renderChart()}</Suspense>
    </>
  );
});
export default ThingScanCountStat;
