import { default as React, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { isTNF } from "services/utils";
import LoadingBar from "../../../components/loadingBar";
import i18n from "../../../i18n";
import { ThingStatisticService } from "../../../services/statistic/statisticService";
import GenericPieChart from "../ui/GenericPieChart";

export default function ThingCountByVendor() {
  const { t } = useTranslation("statistic", { i18n });
  const accountId = localStorage.accountId;

  const statSettings = {
    limit: 10,
  };

  const getData = (settings, getDataOnSuccess, getDataOnError) => {
    if (accountId) {
      ThingStatisticService.activeThingCountByVendor(
        accountId,
        settings.limit,
        getDataOnSuccess,
        getDataOnError
      );
    }
  };
  const renderChart = () => {
    return (
      <GenericPieChart
        title={isTNF(
          t("Tedarikçiye Göre Etkileşim Noktası Sayıları"),
          t("Tedarikçiye Göre Varlık Sayıları")
        )}
        getData={getData}
        menuLimit
        settings={statSettings}
      />
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>{renderChart()}</Suspense>
    </>
  );
}
