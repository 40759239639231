import { makeStyles } from "@material-ui/core/styles";

export const authStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
  },

  input: {
    width: 40,
    height: 40,
    padding: 0,
    fontSize: 24,
    textAlign: "center",
    marginRight: 8,
    color: "#494949",
    border: "1px solid #d6d6d6",
    borderRadius: 4,
    background: "#fff",
    backgroundClip: "paddingBox",
  },

  inputDisabled: {
    width: 40,
    height: 40,
    padding: 0,
    fontSize: 24,
    textAlign: "center",
    marginRight: 8,
    color: "#494949",
    border: "1px solid #d6d6d6",
    borderRadius: 4,
    background: "rgba(0, 0, 0, 0.12)",
    backgroundClip: "paddingBox",
  },
}));
