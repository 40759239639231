import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, CardHeader } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import JournalTypeAutoComplete from "components/autocomplete/JournalTypeAutoComplete";
import UserDetail from "components/detail/UserDetail";
import DynamicFieldsEdit from "components/dynamicFieldsEdit";
import ThingsHelpIcon from "components/icons/ThingsHelpIcon";
import ThingsDataTable from "components/thingsDataTable";
import React, { useRef, useState } from "react";
import { FaDatePicker, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DynamicFormService } from "services/dynamicForm/dynamicFormService";
import { JOURNAL } from "services/faApi";
import { JournalService } from "services/JournalService";
import { ThingDynamicFormService } from "services/ThingDynamicFormService";
import { gridStyles } from "styles/ThingsGridStyle";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function ThingJournals(props) {
  const faDialog = useDialog();
  const { thing, isEditable } = props;
  const classes = useStyles();
  const accountId = localStorage.accountId;
  const { t } = useTranslation("thingDetail", { i18n });

  const [loading, setLoading] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [dynamicFormList, setDynamicFormList] = useState([]);
  const [dynamicValues, setDynamicValues] = useState([]);
  const [journal, setJournal] = useState({});

  const userInfo = useSelector(getUserInfo);
  const tableRef = useRef();
  const gridClasses = gridStyles();

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const newJournal = () => {
    return {
      thing: { id: thing.id },
      name: null,
      description: null,
      account: { id: thing?.account?.id },
      journalType: null,
      journalDate: new Date(),
      user: { id: userInfo?.id },
    };
  };

  const saveJournal = () => {
    setLoading(true);
    let data = {
      ...journal,
      dynamicValues: dynamicValues,
    };
    JournalService.save(data, saveJournalOnSuccess, saveJournalOnError);
  };

  const saveJournalOnSuccess = () => {
    tableRef.current.refreshTable();
    setIsEditDialogOpen(false);
    setDynamicFormList([]);
    setDynamicValues([]);
    setLoading(false);
  };

  const saveJournalOnError = (data) => {
    showAlert("Günce kaydedilirken bir sorunla karşılaşıldı", "error");
    setLoading(false);
  };

  const handleChangeV = (prop, val) => {
    setJournal({ ...journal, [prop]: val });
  };

  const deleteJournal = (item) => {
    faDialog({
      description: t("Silmek istediğinize emin misiniz?"),
      type: "confirm",
    }).then((confirmation) => {
      JournalService.delete(item, deleteJournalOnSuccess, deleteJournalOnError);
    });
  };

  const deleteJournalOnSuccess = (data) => {
    tableRef.current.refreshTable();
    setIsEditDialogOpen(false);
  };

  const deleteJournalOnError = (data) => {};

  const findDynamicFields = (item) => {
    // Editleniyorsa item 'journal' olur. Yeni ek alan giriliyorsa item 'journalType' olur.
    if (item?.journalType && item?.journalType?.id) {
      ThingDynamicFormService.findFormAndData(
        accountId,
        "JOURNAL_CLASS",
        item.journalType.id,
        item.id,
        (data) => {
          if (data) {
            setDynamicFormList(data.fieldList);
            setDynamicValues(data.data.detailData ? data.data.detailData : []);
          }
        },
        (error) => {}
      );
    } else if (item?.id) {
      DynamicFormService.findFormByName(
        "JOURNAL_CLASS",
        accountId,
        item.id,
        (data) => setDynamicFormList(data),
        (error) => {}
      );
    } else {
      setDynamicFormList(null);
    }
  };

  const onDynamicValuesChange = (values) => {
    setDynamicValues(values);
  };

  const renderEditDialog = () => {
    return (
      <FaDialog
        title={"Günce Ekle"}
        faOpen={isEditDialogOpen}
        faOnSubmit={saveJournal}
        showSaveButton
        faHandleClose={() => {
          setIsEditDialogOpen(false);
          setDynamicFormList([]);
          setDynamicValues([]);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={0}
        >
          <Grid item xs={12}>
            <JournalTypeAutoComplete
              value={journal.journalType}
              accountId={thing?.account?.id}
              onChange={(data) => {
                handleChangeV("journalType", data);
                findDynamicFields(data);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FaDatePicker
              faType="dateTime"
              label={t("JOURNAL_DATE")}
              style={{ minHeight: 65 }}
              value={journal.journalDate}
              faOnChange={(val) => {
                setJournal({
                  ...journal,
                  journalDate: val,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              value={journal.description}
              label={t("DESCRIPTION")}
              onChange={(event) =>
                handleChangeV("description", event.target.value)
              }
            />
          </Grid>
          {dynamicFormList && dynamicFormList.length > 0 && (
            <Grid item xs={12}>
              <DynamicFieldsEdit
                selectedDetails={dynamicFormList}
                details={dynamicFormList}
                data={dynamicValues}
                onValuesChange={onDynamicValuesChange}
              />
            </Grid>
          )}
          {journal && journal.id > 0 && (
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                onClick={() => {
                  deleteJournal(journal);
                }}
                color="secondary"
              >
                Sil
              </Button>
            </Grid>
          )}
        </Grid>
      </FaDialog>
    );
  };

  const columns = [
    {
      field: "journalDate",
      label: t("JOURNAL_DATE"),
      align: "center",
      width: "15%",
      type: "dateTime",
    },
    {
      field: "journalType.name",
      label: t("JOURNAL_TYPE"),
      align: "center",
      width: "25%",
      valueFormatter: (value) => {
        return t("" + value);
      },
    },

    {
      field: "description",
      label: t("DESCRIPTION"),
      align: "center",
      width: "25%",
    },
    {
      field: "user.fullname",
      label: t("USER"),
      align: "center",
      width: "25%",
      valueFormatter: (value, row) => {
        return <UserDetail value={row?.user} type="USER" />;
      },
    },
    {
      field: "id",
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        return (
          <IconButton
            onClick={() => {
              onEdit(row);
            }}
            size="small"
            color="primary"
            title={t("Düzenle")}
          >
            <FontAwesomeIcon icon={faEdit} />
          </IconButton>
        );
      },
    },
  ];

  const onEdit = (row) => {
    setJournal({ ...row });
    findDynamicFields(row);
    setIsEditDialogOpen(true);
  };

  return (
    <>
      {renderEditDialog()}
      <Card style={{ minHeight: 100 }} className="fai-thingLinks">
        <CardHeader
          action={
            <>
              <IconButton aria-label="help" onClick={() => {}}>
                <ThingsHelpIcon />
              </IconButton>
              {isEditable && (
                <IconButton
                  aria-label="settings"
                  onClick={() => {
                    setJournal(newJournal());
                    setIsEditDialogOpen(true);
                  }}
                >
                  <AddIcon />
                </IconButton>
              )}
            </>
          }
          title={t("JOURNAL")}
          data-fa-section="JOURNAL"
        />
        {thing && thing.id > 0 && (
          <ThingsDataTable
            dataUrl={JOURNAL.findThingJournals + "?thingId=" + thing.id}
            queryParams={{
              page: 0,
              size: 10,
              sort: "journalDate",
              isAsc: false,
            }}
            columns={columns}
            ref={tableRef}
            showDeleteButton={false}
            showHeaderText={false}
            headerText={t("JOURNAL")}
            showHeader={false}
            columnClassName={gridClasses.GenericColumn}
          />
        )}
      </Card>
    </>
  );
}
