import { Button, Card, CardHeader, Chip, Grid } from "@material-ui/core";
import ThingsDataTable from "components/thingsDataTable";
import InvitationHelp from "features/help/InvitationHelp";
import { default as React, Suspense, useRef } from "react";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { getDateTimeWFormat } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { InvitationService } from "services/InvitationService";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { INVITATION } from "../../services/faApi";
import { useStyles } from "./style";

export default function Invitations() {
  const tableRef = useRef();
  const { t } = useTranslation("accountUserInvitation", { i18n });
  const classes = useStyles();
  const faDialog = useDialog();

  const acceptInvitation = (row) => {
    faDialog({
      description: t("Davetiyeyi onaylamak istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        InvitationService.acception(row, acceptOnSuccess, acceptOnError);
      })
      .catch((err) => console.log("error:", err));
  };

  const acceptOnSuccess = () => {
    tableRef.current.refreshTable();
  };

  const acceptOnError = () => {};

  const rejectInvitation = (row) => {
    faDialog({
      description: t("Davetiyeyi reddetmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        InvitationService.rejection(row, acceptOnSuccess, acceptOnError);
      })
      .catch((err) => console.log("error:", err));
  };

  const columns = [
    {
      field: "invitationDate",
      label: t("INVITATION_DATE"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        return value ? getDateTimeWFormat(value) : null;
      },
    },
    {
      field: "account",
      label: t("ACCOUNT"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        return value.fullname;
      },
    },
    {
      field: "invitationMsg",
      label: t("INVITATION_MESSAGE"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        return value;
      },
    },
    {
      field: "statusDate",
      label: t("ACCEPT_REJECT_DATE"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        return value ? getDateTimeWFormat(value) : null;
      },
    },
    {
      field: "status",
      label: t("STATUS"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        //console.log("my value", value);
        let text = row.status
          ? t("STATUS_" + row.status)
          : t("STATUS_NOT_SENDED");
        let className = row.statusDate ? classes.chipOpen : classes.chipClose;
        return <Chip className={className} label={text} />;
      },
    },
    {
      field: "id",
      label: t("OPERATION"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        if (row.status === "WAITING") {
          return (
            <>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => acceptInvitation(row)}
                style={{ marginRight: 5 }}
              >
                {t("ACCEPT")}
              </Button>

              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => rejectInvitation(row)}
              >
                {t("REJECT")}
              </Button>
            </>
          );
        }
      },
    },
  ];

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Card>
          <CardHeader
            title={t("INVITATIONS")}
            action={<InvitationHelp value="profile.invitations" />}
          />
          <Grid container data-fa-section="INVITATIONS">
            <ThingsDataTable
              dataUrl={INVITATION.findByUser}
              columns={columns}
              ref={tableRef}
              showDeleteButton={false}
              showHeaderText={false}
              queryParams={{
                page: 0,
                size: 50,
                sort: "invitationDate",
                isAsc: false,
              }}
            />
          </Grid>
        </Card>
      </Suspense>
    </>
  );
}
