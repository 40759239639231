import { faGlobe, faLowVision } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  CardHeader,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Delete } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import ThingsImageHelp from "features/help/ThingsImageHelp";
import QRCode from "qrcode.react";

import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ThingService } from "services/thing/thingService";
import i18n from "../../i18n";
import ThingPhotosCarousel from "./ThingPhotosCarousel";
import { useStyles } from "./style";

const ThingPhotos = forwardRef((props, ref) => {
  const {
    images,
    setImages,
    refresh,
    setDefaultImage,
    thingId,
    thing,
    isEditable,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [openWebLink, setOpenWebLink] = useState(false);
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const { t } = useTranslation("thingDetail", { i18n });
  const fileInputRef = useRef();
  const faDialog = useDialog();

  const [selectedHdImageIndex, setSelectedHdImageIndex] = useState(0);

  const history = useHistory();
  const svgRef = useRef();
  function downloadBlob(blob, filename) {
    const objectUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = objectUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
  }

  const downloadSVG = useCallback(() => {
    const content = svgRef.current.children[0].innerHTML;
    const contentWithSvg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" height="128" width="128" viewBox="0 0 29 29">
 ${content}
 </svg>`;
    const blob = new Blob([contentWithSvg], { type: "image/svg+xml" });
    downloadBlob(blob, `qrcode.svg`);
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const navigatePrintQR = () => {
    const tmpTo = {
      pathname: `/print-qr`,
      state: {
        selectedQr: thing.thidUrl,
        selectedThid: thing.thid,
      },
    };
    history.push(tmpTo);
  };

  const webLink = () => {
    setOpenWebLink(true);
  };

  const handleChange = (event) => {
    event.preventDefault();
    if (event?.target?.files?.length > 0) {
      uploadImages(event.target.files);
    }
  };

  const [isCarouselOpen, setCarouselOpen] = useState(false);

  const openCarousel = (index) => {
    setSelectedHdImageIndex(index);
    setCarouselOpen(true);
  };

  const closeCarousel = () => {
    setCarouselOpen(false);
  };

  useImperativeHandle(ref, () => ({
    openPhotoMenu() {
      setAnchorEl(svgRef.current);
    },
  }));

  const uploadImages = (files) => {
    ThingService.uploadImage(files, thing.id, onSuccess, onError);
  };

  const onSuccess = () => {
    refresh();
  };
  const onError = (e) => {
    console.log(e);
  };

  const deleteImage = (objectId) => {
    faDialog({
      description: t("Kaydı silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        ThingService.deleteImage(
          thing.id,
          objectId,
          (data) => {
            refresh();
          },
          (error) => {}
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const onPublicImage = (objectId) => {
    faDialog({
      description: t("Görseli herkese açık yapmak istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        ThingService.makeImagePublic(
          objectId,
          thing.account.id,
          (data) => {
            refresh();
          },
          (error) => {}
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const onPrivateImage = (objectId) => {
    faDialog({
      description: t(
        "Görseli herkese kapalı yapmak istediğinize emin misiniz?"
      ),
      type: "confirm",
    })
      .then((confirmation) => {
        ThingService.makeImagePrivate(
          objectId,
          thing.account.id,
          (data) => {
            refresh();
          },
          (error) => {}
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const renderWebLinkDialog = () => {
    return (
      <Dialog
        open={openWebLink}
        onClose={() => {
          setOpenWebLink(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t("Web Link")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{thing.thidUrl}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(thing.thidUrl);
            }}
            color="primary"
          >
            Kopyala
          </Button>
          <Button
            onClick={() => {
              window.open(thing.thidUrl, "_blank").focus();
            }}
            color="primary"
          >
            Git
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {renderWebLinkDialog()}

      <Dialog
        open={isCarouselOpen}
        aria-labelledby="form-dialog-title"
        onClose={closeCarousel}
      >
        <DialogContent>
          <ThingPhotosCarousel
            thingId={thingId}
            setImages={setImages}
            images={images}
            setDefaultImage={setDefaultImage}
            selectedItem={selectedHdImageIndex}
          />
        </DialogContent>
      </Dialog>

      <Card
        style={{ minHeight: 150, overflowY: "auto" }}
        className="fai-photos"
      >
        <CardHeader
          action={
            <>
              <ThingsImageHelp value="thingDetail.images" />

              {isEditable && (
                <IconButton
                  data-fa-button="PHOTOS_ADD"
                  aria-label="settings"
                  onClick={() => fileInputRef.current.click()}
                >
                  <AddIcon />
                </IconButton>
              )}
            </>
          }
          title={t("PHOTOS")}
          data-fa-section="PHOTOS"
        />
        <CardContent>
          <Grid item container spacing={1}>
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <input
                onChange={handleChange}
                multiple
                ref={fileInputRef}
                type="file"
                hidden
              />
            </div>
            <div
              ref={svgRef}
              className={classes.thingImageContainer}
              data-fa-input="QR_MENU"
            >
              <QRCode
                data-fa-input="QR"
                id="thingQr"
                className="fai-qr "
                value={thing.thidUrl ? thing.thidUrl : ""}
                renderAs="svg"
                size={100}
                onClick={(event) => {
                  console.log("event.currentTarget:" + event.currentTarget);
                  setAnchorEl(event.currentTarget);
                }}
                style={{
                  cursor: "pointer",
                  padding: 5,
                  border: "1px solid black",
                  borderRadius: "15%",
                }}
              />
              <Menu
                id="qr-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => {
                  setAnchorEl(null);
                }}
              >
                <MenuItem onClick={downloadSVG}>QR İndir</MenuItem>
                <MenuItem onClick={navigatePrintQR}>QR Etiket Bas</MenuItem>
                <MenuItem onClick={webLink}>Web Link</MenuItem>
              </Menu>
            </div>
            <div
              data-fa-section="imageArea"
              style={{ minWidth: 100, display: "flex" }}
            >
              {images &&
                images.map((item, index) => (
                  <div key={index} className={classes.thingImageContainer}>
                    <img
                      src={`data:image/png;base64, ${item.content}`}
                      className={classes.thingImage}
                      alt={item.object}
                      onClick={() => {
                        openCarousel(index);
                      }}
                    />
                    {isEditable && (
                      <IconButton
                        className={classes.thingImageDeleteButtom}
                        onClick={() => {
                          deleteImage(item.object);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    )}
                    {!(item.accessType === "PUBLIC") && (
                      <IconButton
                        className={classes.thingImageAccessButtom}
                        title="Herkese kapalı"
                        onClick={() => {
                          if (isEditable) onPublicImage(item.object);
                        }}
                      >
                        <FontAwesomeIcon icon={faLowVision} />
                      </IconButton>
                    )}
                    {item.accessType === "PUBLIC" && (
                      <IconButton
                        className={classes.thingImageAccessButtom}
                        title="Herkese açık"
                        onClick={() => {
                          if (isEditable) onPrivateImage(item.object);
                        }}
                      >
                        <FontAwesomeIcon icon={faGlobe} />
                      </IconButton>
                    )}
                    {/* <ImageListItemBar
                  title={item.object}
                  actionIcon={
                    <IconButton
                      aria-label={`info about ${item.object}`}
                      className={classes.icon}
                    >
                      <InfoIcon />
                    </IconButton>
                  }
                /> */}
                  </div>
                ))}
            </div>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
});
export default ThingPhotos;
