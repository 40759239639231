import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Slider,
  Typography,
  withStyles,
} from "@material-ui/core";
import ThingsSwitch from "components/ui/ThingsSwitch";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDateWFormat } from "services/utils";
import i18n from "../../i18n";

function dateDiffInDays(a, b) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  var date1 = new Date(a);
  var date2 = new Date(b);

  const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

  return Math.floor((utc1 - utc2) / _MS_PER_DAY);
}

const CustomSlider = withStyles({
  root: {
    color: "#532363",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 10,
    width: 2,
    marginTop: -4,
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor",
  },
})(Slider);

function ControlPanel(props) {
  const {
    startTime,
    endTime,
    onChangeTime,
    allDays,
    onChangeAllDays,
    selectedTime,
    unique,
    onChangeUnique,
    mapType,
    onChangeMapType,
    marks,
  } = props;

  const { t } = useTranslation("customerInteraction", { i18n });

  const [selectedDays, setSelectedDays] = useState(
    dateDiffInDays(selectedTime, startTime)
  );
  const [totalDays, setTotalDays] = useState(
    dateDiffInDays(endTime - startTime)
  );

  useEffect(() => {
    setSelectedDays([
      dateDiffInDays(selectedTime[0], startTime),
      dateDiffInDays(selectedTime[1], startTime),
    ]);
  }, [selectedTime]);

  useEffect(() => {
    if (endTime && startTime) {
      setTotalDays(dateDiffInDays(endTime, startTime));
    }
  }, [endTime, startTime]);

  const onSelectDay = (evt, data) => {
    setSelectedDays(data);

    let selectedStart = data[0];
    let selectedEnd = data[1];

    const daysToAddStart = parseInt(selectedStart);
    var date = new Date(startTime);
    let x = date.setDate(date.getDate() + daysToAddStart);
    let result = date.toISOString();

    const daysToAddEnd = parseInt(selectedEnd);
    var date2 = new Date(startTime);
    let x2 = date2.setDate(date2.getDate() + daysToAddEnd);
    let result2 = date2.toISOString();

    onChangeTime([result, result2]);
  };

  function valueLabelFormat(value) {
    const daysToAdd = parseInt(value);
    var date = new Date(startTime);
    let x = date.setDate(date.getDate() + daysToAdd);

    let month = date.getMonth();
    let year = date.getFullYear();

    return month + 1 + "/" + (year % 100);
  }

  return (
    <Grid item container>
      <Grid item xs={12} lg={3}>
        {/* <Typography style={{ marginTop: 7 }}>
          {t("TODAY")} {getDateWFormat(startTime)} - {getDateWFormat(endTime)}
        </Typography> */}
      </Grid>
      <Grid data-fa-section="ALL_DAYS" item xs={12} lg={3}>
        <ThingsSwitch
          label={t("Bütün günlerin toplamı")}
          value={allDays}
          onChange={(evt) => onChangeAllDays(evt)}
        />
      </Grid>
      <Grid item xs={12} lg={3}>
        <ThingsSwitch
          label={t("Biricik kullanıcı etkileşimi")}
          value={unique}
          onChange={(evt) => onChangeUnique(evt)}
          data-fa-label="BIRICIK_KULLANICI"
        />
      </Grid>
      <Grid item xs={12} lg={3}>
        <RadioGroup
          row
          value={mapType}
          onChange={(event) => {
            onChangeMapType(event.target.value);
          }}
          data-fa-label="mapType"
        >
          <FormControlLabel
            value="numericMap"
            data-fa-label="NUMERIC_MAP"
            control={<Radio color="primary" />}
            label="Sayısal"
          />
          <FormControlLabel
            value="heatMap"
            data-fa-label="HEAT_MAP"
            control={<Radio color="primary" />}
            label="Isı haritası"
          />
        </RadioGroup>
      </Grid>
      {!allDays && marks && (
        <Grid data-fa-section="RANGE" item xs={12} lg={12}>
          <Typography style={{ fontWeight: "bold" }}>
            {getDateWFormat(selectedTime[0])} -{" "}
            {getDateWFormat(selectedTime[1])}
          </Typography>
          <CustomSlider
            value={selectedDays}
            onChange={onSelectDay}
            valueLabelDisplay="auto"
            aria-labelledby="rande-slider"
            step={null}
            marks={marks}
            min={0}
            max={totalDays}
            getAriaValueText={valueLabelFormat}
            valueLabelFormat={valueLabelFormat}
          />
        </Grid>
      )}
      {!allDays && <Grid item xs={12} lg={8}></Grid>}
    </Grid>
  );
}

export default React.memo(ControlPanel);
