import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { THING_TEAM } from "./faApi";

export const ThingTeamService = {
  save: (thingTeam, callback, errorCallback) => {
    console.log("BURADAYIM");
    let reqBody = {
      ...thingTeam,
    };

    let req = requests.getSimplePostRequest(THING_TEAM.save, reqBody);

    FaAxios(req)
      .then((response) => {
        console.log("response => ", response);
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },

  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(THING_TEAM.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (thingTeam, callback, errorCallback) => {
    let reqBody = {
      ...thingTeam,
    };

    let req = requests.getSimplePostRequest(THING_TEAM.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },

  findThingTeams: (accountId, thingId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(THING_TEAM.findThingTeams, {
      accountId,
      thingId,
    });

    FaAxios(req)
      .then((response) => {
        console.log("response ;=> ", response);
        console.log(response.data);
        //showAlert("RESPONSE DATA =>", response.data);
        callback(response.data);
      })
      .catch((error) => {
        console.log("Error ;=> ", error);
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
};
