import { CardHeader } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { default as React, Suspense, useEffect, useState } from "react";
import { FaPieChart } from "react-base-fa/dist/fa/facharts";
import { CHART_COLORS } from "services/utils/ChartConstants";
import LoadingBar from "../../../components/loadingBar";
import { default as MenuLimit } from "./MenuLimit";

export default function GenericPieChart(props) {
  const { title, getData, menuLimit, settings, chartClick } = props;

  const [statSettings, setStatSettings] = useState({ ...settings });
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    getData(statSettings, getDataOnSuccess, getDataOnError);
  }, [statSettings]);

  const getDataOnSuccess = (data) => {
    setChartData(data);
  };

  const getDataOnError = (error) => {};

  const changeLimit = (newLimit) => {
    setStatSettings({ ...statSettings, limit: newLimit });
  };

  const chartClicked = (p1, p2) => {
    if (chartClick) chartClick(p1);
  };

  const renderChart = () => {
    return (
      <div>
        <Card>
          <CardHeader
            action={
              <>
                {menuLimit && (
                  <MenuLimit
                    value={statSettings.limit}
                    onChange={changeLimit}
                  />
                )}
              </>
            }
            title={title}
          />
          <CardContent>
            <Grid container spacing={1}>
              <Grid item lg={12} xs={12}>
                <Grid item>
                  <FaPieChart
                    data={chartData}
                    legends={[]}
                    height="300px"
                    colors={CHART_COLORS}
                    onClick={chartClicked}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>{renderChart()}</Suspense>
    </>
  );
}
