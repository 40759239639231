import {
  default as React,
  forwardRef,
  Suspense,
  useImperativeHandle,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import LoadingBar from "../../../components/loadingBar";
import i18n from "../../../i18n";
import { ThingStatisticService } from "../../../services/statistic/statisticService";
import GenericBarChart from "../ui/GenericBarChart";

const IssueCountStat = forwardRef((props, ref) => {
  const { settings } = props;
  const barChartRef = useRef();
  const { t } = useTranslation("statistic", { i18n });
  const accountId = localStorage.accountId;

  var today = new Date();
  const statSettings = {
    start: new Date(today.setMonth(today.getMonth() - 1)),
    end: null,
    period: "DAY",
    cumulative: false,
    dateRange: 4,
    dateRangeType: 3,
    thingClassId: null,
    ...settings,
  };

  const countLabel = t("SAYI");

  const getData = (settings, getDataOnSuccess, getDataOnError) => {
    if (accountId) {
      ThingStatisticService.issueCountByDate(
        accountId,
        settings,
        countLabel,
        getDataOnSuccess,
        getDataOnError
      );
    }
  };
  const renderChart = () => {
    return (
      <GenericBarChart
        ref={barChartRef}
        title={t("Bildirilen Sorun Sayıları")}
        getData={getData}
        bAxisLabel={t("Gün")}
        lAxisLabel={t("Sorun Sayısı")}
        menuDate
        menuPeriod
        menuCumulative
        settings={statSettings}
      />
    );
  };

  useImperativeHandle(ref, () => ({
    refreshChart(newSettings) {
      console.log("refreshChart", newSettings);
      barChartRef.current.refreshChart(newSettings);
    },
  }));

  return (
    <>
      <Suspense fallback={<LoadingBar />}>{renderChart()}</Suspense>
    </>
  );
});
export default IssueCountStat;
