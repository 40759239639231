import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Add, Edit } from "@material-ui/icons";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getDateTimeWFormat } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { CustomerListDetailService } from "services/CustomerListDetailService";
import { CustomerListService } from "services/CustomerListService";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import CustomerListDetailEdit from "./CustomerListDetailEdit";
import CustomerListEdit from "./CustomerListEdit";

export default function CustomerListDetail() {
  const tableRef = useRef();
  const labelClasses = labelStyles();
  const { t } = useTranslation(["customer", "components"], { i18n });
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [
    isCustomerListDetailEditFormOpen,
    setIsCustomerListDetailEditFormOpen,
  ] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  const columns = [
    {
      field: "name",
      label: t("NAME"),
      align: "center",
      width: "20%",
      valueFormatter: (value, row) => {
        return row.customer?.name;
      },
    },
    {
      field: "surname",
      label: t("SURNAME"),
      align: "center",
      width: "20%",
      valueFormatter: (value, row) => {
        return row.customer?.surname;
      },
    },
    {
      field: "membershipDate",
      label: t("MEMBERSHIP_DATE"),
      align: "center",
      width: "20%",
      valueFormatter: (value, row) => {
        return getDateTimeWFormat(row.customer?.membershipDate);
      },
    },
    {
      field: "email",
      label: t("E-MAIL"),
      align: "center",
      width: "20%",
      valueFormatter: (value, row) => {
        return row.customer?.email;
      },
    },
    {
      field: "phone",
      label: t("PHONE"),
      align: "center",
      width: "20%",
      valueFormatter: (value, row) => {
        return row.customer?.phone;
      },
    },
    {
      field: "id",
      label: t("İşlem"),
      align: "center",
      width: "20%",
      valueFormatter: (value, row) => {
        return (
          <IconButton
            onClick={() => deleteCustomerListDetail(row)}
            size="small"
            color="primary"
            aria-label={t("Sil")}
          >
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        );
      },
    },
  ];

  useEffect(() => {
    refresh();
  }, [id]);

  const [customerList, setCustomerList] = useState(null);
  const [customerListDetail, setCustomerListDetail] = useState(null);
  const [customers, setCustomers] = useState(null);

  const refresh = () => {
    setLoading(true);
    CustomerListService.findById(
      id,
      (data) => {
        setCustomerList(data);
        setLoading(false);
      },
      (error) => {
        showAlert(error.message, "error");
        setLoading(false);
      }
    );
    CustomerListDetailService.findByCustomerListId(id, getSuccess, getError);
  };

  useEffect(() => {
    setCustomerListDetail(customerListDetail);
  }, [customerListDetail]);

  const deleteCustomerListDetail = (customerListDetail) => {
    faDialog({
      description: t(t("Kaydı silmek istediğinize emin misiniz?")),
      type: "confirm",
    })
      .then((confirmation) => {
        CustomerListDetailService.delete(
          customerListDetail,
          (data) => {
            showAlert(t("Başarıyla silindi"), "success");
            refresh();
          },
          (error) => {
            showAlert(t("Kayıt silinemedi, bağlı kayıtlar mevcut."), "error");
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };
  const getSuccess = (data) => {
    showAlert(data.message, "save");
    console.log("data", data.list);
    setCustomerListDetail(data.list);
    setCustomers(data.list.filter((i) => i?.costumer));
    setLoading(false);
  };

  const getError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };
  const editButtonClicked = () => {
    setIsEditFormOpen(true);
  };

  const customerListOnChange = () => {
    setIsEditFormOpen(false);
    setIsCustomerListDetailEditFormOpen(false);
    refresh();
  };

  const customerListOnDelete = () => {
    history.goBack();
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <div>
          <CustomerListEdit
            onChange={customerListOnChange}
            onDelete={customerListOnDelete}
            onClose={() => {
              setIsEditFormOpen(false);
            }}
            value={customerList}
            open={isEditFormOpen}
          />
          <CustomerListDetailEdit
            onChange={customerListOnChange}
            onDelete={customerListOnDelete}
            onClose={() => {
              setIsCustomerListDetailEditFormOpen(false);
            }}
            customerList={customerList}
            open={isCustomerListDetailEditFormOpen}
          />
          <Grid container spacing={1}>
            <Grid item lg={12}>
              <Card>
                <CardHeader
                  action={
                    <IconButton
                      aria-label="settings"
                      onClick={editButtonClicked}
                    >
                      <Edit />
                    </IconButton>
                  }
                  title="Müşteri Listesi Bilgileri"
                />
                <CardContent>
                  <Grid item container spacing={1}>
                    <Grid item xs={6} className={labelClasses.LabelRow}>
                      <Typography className={labelClasses.LabelHeader}>
                        {t("Liste Adı")}
                      </Typography>
                      <Typography className={labelClasses.LabelValue}>
                        {customerList?.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} className={labelClasses.LabelRow}>
                      <Typography className={labelClasses.LabelHeader}>
                        {t("Liste Açıklaması")}
                      </Typography>
                      <Typography className={labelClasses.LabelValue}>
                        {customerList?.description}
                      </Typography>
                    </Grid>

                    <Grid item xs={6} className={labelClasses.LabelLastRow}>
                      <Typography className={labelClasses.LabelHeader}>
                        {t("Oluşturulma Tarihi")}
                      </Typography>
                      <Typography className={labelClasses.LabelValue}>
                        {getDateTimeWFormat(customerList?.createDate)}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  action={
                    <IconButton
                      aria-label="settings"
                      onClick={() => {
                        setIsCustomerListDetailEditFormOpen(true);
                      }}
                    >
                      <Add />
                    </IconButton>
                  }
                  title="Müşteri Listesi"
                />

                <ThingsDataTable
                  data={customerListDetail}
                  columns={columns}
                  ref={tableRef}
                  showDeleteButton={false}
                  showHeaderText={false}
                  headerText={t("AWARDS")}
                  showPaging={false}
                />
              </Card>
            </Grid>
          </Grid>
        </div>
      </Suspense>
    </>
  );
}
