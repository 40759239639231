import { Button, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import ThingSelector from "components/selector/ThingSelector";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { forwardRef, useEffect, useState } from "react";
import { FaDatePicker, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { AnnouncementService } from "services/AnnouncementService";
import { isTNF } from "services/utils";
import i18n from "../../i18n";
import { useStyles } from "./style";

const AnnouncementEdit = forwardRef((props, ref) => {
  const { onChange, onDelete, onClose, value, open } = props;
  const { t } = useTranslation("announcement", { i18n });

  const dispatch = useDispatch();
  const faDialog = useDialog();
  const classes = useStyles();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const accountId = localStorage.accountId;
  const [thing, setThing] = useState(null);

  const newAnnouncement = () => {
    return {
      id: null,
      content: null, // mobile
      contentRow: null, // web
      subject: null,
      startDate: new Date(),
      endDate: new Date(),
      thing: null,
      noThing: null,
      account: { id: accountId },
    };
  };
  const [announcement, setAnnouncement] = useState(newAnnouncement());

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (open) {
      if (value) {
        setAnnouncement({
          ...value,
        });
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(value.contentRow))
          )
        );
      } else {
        setAnnouncement(newAnnouncement());
        setEditorState(EditorState.createEmpty());
      }
    }
  }, [open]);

  const handleChange = (prop) => (event) => {
    setAnnouncement({ ...announcement, [prop]: event.target.value });
  };
  const handleChangeV = (prop, val) => {
    setAnnouncement({ ...announcement, [prop]: val });
  };

  const saveSuccess = (data) => {
    showAlert(!announcement.id ? t("SAVED") : t("EDITED"), "success");
    setAnnouncement(newAnnouncement());
    setLoading(false);
    onChange(data);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const saveAnnouncement = () => {
    setLoading(true);
    AnnouncementService.save(announcement, saveSuccess, saveError);
  };

  const announcementDelete = () => {
    faDialog({
      description: t("Silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        AnnouncementService.delete(
          announcement,
          (data) => {
            showAlert(t("Başarıyla silindi"), "success");
            onDelete();
          },
          (error) => {
            showAlert(t("Kayıt silinemedi, bağlı kayıtlar mevcut."), "error");
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const noThingOnCheck = (checked) => {
    if (checked) {
      setAnnouncement((prev) => {
        return { ...prev, thing: null };
      });
    }
  };

  const onEditorStateChange = (data) => {
    setEditorState(data);
    let row = convertToRaw(data.getCurrentContent());
    setAnnouncement({
      ...announcement,
      content: draftToHtml(row),
      contentRow: JSON.stringify(row),
    });
  };

  const newFormRender = () => {
    return (
      <>
        <FaDialog
          title={"Duyuru Tanımlama"}
          faOpen={open}
          faOnSubmit={saveAnnouncement}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            onClose();
            setAnnouncement(newAnnouncement());
          }}
          faMaxWidth="lg"
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            {!announcement.noThing && (
              <Grid item xs={12}>
                <ThingSelector
                  value={announcement.thing}
                  accountId={accountId}
                  multiple={false}
                  onChange={(data) => {
                    setAnnouncement({
                      ...announcement,
                      thing: data,
                    });
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={announcement.noThing}
                    onChange={(event) => {
                      setAnnouncement((prev) => {
                        return { ...prev, noThing: event.target.checked };
                      });
                      noThingOnCheck(event.target.checked);
                    }}
                    color="primary"
                  />
                }
                label={isTNF(
                  t("NO_THING_ANNOUNCEMENT"),
                  t("NO_THING_ANNOUNCEMENT_T2C")
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                required
                faType="subject"
                label={t("Konu")}
                value={announcement.subject}
                inputProps={{ maxLengt: 100 }}
                onChange={handleChange("subject")}
              />
            </Grid>
            <Grid item xs={6}>
              <FaDatePicker
                label={t("START_DATE")}
                faType="dateTime"
                required
                name="startDate"
                value={announcement?.startDate || null}
                faOnChange={(val) => {
                  handleChangeV("startDate", val);
                }}
                data-fa-input="START_DATE"
              />
            </Grid>
            <Grid item xs={6}>
              <FaDatePicker
                label={t("END_DATE")}
                faType="dateTime"
                required
                name="endDate"
                value={announcement?.endDate || null}
                faOnChange={(val) => {
                  handleChangeV("endDate", val);
                }}
                data-fa-input="END_DATE"
              />
            </Grid>
            <Grid item xs={12}>
              <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                wrapperClassName={classes.wrapperClass}
                editorClassName={classes.editorClass}
                toolbarClassName={classes.toolbarClass}
              />
            </Grid>

            {announcement.id > 0 && (
              <Grid item xs={12} style={{ textAlign: "right" }}>
                <Button onClick={announcementDelete} color="secondary">
                  Sil
                </Button>
              </Grid>
            )}
          </Grid>
        </FaDialog>
      </>
    );
  };

  return newFormRender();
});

export default AnnouncementEdit;
