import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  CardHeader,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import AccessTypeSelect from "components/select/AccessTypeSelect";
import ThingsWebLinkHelp from "features/help/ThingsWebLinkHelp";
import React, { useEffect, useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import i18n from "../../i18n";
import { ThingLinkService } from "../../services/thingLink/thingLinkService";
import { useStyles } from "./ThingDetailCardStyle";

export default function ThingLinks(props) {
  const faDialog = useDialog();
  const { thing, isEditable } = props;
  const classes = useStyles();

  const { t } = useTranslation("thingDetail", { i18n });

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  // const [isDetailDialogOpen, setIsDetailDialogOpen] = useState(false);
  const [thingLinks, setThingLinks] = useState([]);
  const [thingLink, setThingLink] = useState({});

  const newLink = () => {
    return {
      url: "",
      description: "",
      accessType: "PUBLIC",
      thing: { id: thing.id },
      account: { id: thing.account.id },
    };
  };

  useEffect(() => {
    if (thing && thing.id) {
      refresh();
      setThingLink(newLink());
    }
  }, [thing]);

  const refresh = () => {
    ThingLinkService.findThingLinks(
      thing.id,
      thing.account.id,
      findThingLinksOnSuccess,
      () => {}
    );
  };

  const findThingLinksOnSuccess = (data) => {
    setThingLinks(data);
  };

  const saveLink = () => {
    ThingLinkService.save(thingLink, saveLinkOnSuccess, () => {});
  };

  const saveLinkOnSuccess = () => {
    refresh();
    setIsEditDialogOpen(false);
  };

  const handleChangeV = (prop, val) => {
    setThingLink({ ...thingLink, [prop]: val });
  };

  const deleteLink = (item) => {
    faDialog({
      description: t("Silmek istediğinize emin misiniz?"),
      type: "confirm",
    }).then((confirmation) => {
      ThingLinkService.delete(item, deleteLinkOnSuccess, deleteLinkonError);
    });
  };

  const deleteLinkOnSuccess = (data) => {
    refresh();
    setIsEditDialogOpen(false);
  };

  const deleteLinkonError = (e) => {
    showAlert("Bağlı kayıtlar mevcut", "error");
    setIsEditDialogOpen(false);
  };

  const makePublic = (item) => {
    ThingLinkService.makePublic(
      item.id,
      thing.account.id,
      makePublicOnSuccess,
      () => {}
    );
  };

  const makePublicOnSuccess = (data) => {
    refresh();
    setIsEditDialogOpen(false);
  };

  const makePrivate = (item) => {
    ThingLinkService.makePrivate(
      item.id,
      thing.account.id,
      makePrivateOnSuccess,
      () => {}
    );
  };

  const makePrivateOnSuccess = (data) => {
    refresh();
    setIsEditDialogOpen(false);
  };

  const moveUp = (item) => {
    ThingLinkService.moveUp(
      item,
      () => {
        refresh();
        setIsEditDialogOpen(false);
      },
      () => {}
    );
  };

  const moveDown = (item) => {
    ThingLinkService.moveDown(
      item,
      () => {
        refresh();
        setIsEditDialogOpen(false);
      },
      () => {}
    );
  };

  const renderEditDialog = () => {
    return (
      <FaDialog
        title={"Bağlantı Ekle"}
        faOpen={isEditDialogOpen}
        faOnSubmit={saveLink}
        showSaveButton
        faHandleClose={() => {
          setIsEditDialogOpen(false);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
          data-fa-section="WEB_LINK_DIALOG"
        >
          <Grid item xs={12}>
            <FaInput
              label={t("DESCRIPTION")}
              onChange={(event) =>
                handleChangeV("description", event.target.value)
              }
              value={thingLink.description || ""}
              inputProps={{
                maxLength: 255,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              label={t("URL_ADDRESS")}
              onChange={(event) => handleChangeV("url", event.target.value)}
              value={thingLink.url || ""}
              required={true}
              inputProps={{
                maxLength: 255,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <AccessTypeSelect
              value={thingLink.accessType}
              onChange={(data) => handleChangeV("accessType", data)}
            />
          </Grid>
          {thingLink?.id && (
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                onClick={() => {
                  deleteLink(thingLink);
                }}
                color="secondary"
              >
                {t("Sil")}
              </Button>
            </Grid>
          )}
        </Grid>
      </FaDialog>
    );
  };

  const createFallbackUrl = (item) => {
    return (
      <div className={classes.fallbackUrlBox}>
        <a
          href={item.url}
          target="blank_"
          style={{ position: "relative", top: "50%" }}
        >
          {item.url}
        </a>
      </div>
    );
  };

  return (
    <>
      {renderEditDialog()}
      {/* {renderDetailDialog()} */}
      <Card
        style={{ minHeight: 100 }}
        data-fa-section="WEB_LINKS_CARD"
        className="fai-thingLinks"
      >
        <CardHeader
          action={
            <>
              <ThingsWebLinkHelp value="thingDetail.thingsWebLink" />
              {isEditable && (
                <IconButton
                  aria-label="settings"
                  data-fa-button="WEB_LINKS_ADD"
                  onClick={() => {
                    setThingLink(newLink());
                    setIsEditDialogOpen(true);
                  }}
                >
                  <AddIcon />
                </IconButton>
              )}
            </>
          }
          title={t("WEB_LINKS")}
          data-fa-section="WEB_LINKS"
        />
        <CardContent>
          <Grid item container spacing={1} data-fa-section="WEB_LINK_LIST">
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableBody>
                  {thingLinks &&
                    thingLinks.length > 0 &&
                    thingLinks?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Link
                            onClick={() => {
                              window.open(item.url, "_blank").focus();
                            }}
                            style={{
                              textDecoration: "underline",
                              fontSize: "1rem",
                              cursor: "pointer",
                            }}
                          >
                            <Typography className={classes.LabelValue}>
                              {item.description == null ||
                              item.description === ""
                                ? item.url
                                : item.description + " (" + item.url + ")"}
                            </Typography>
                          </Link>
                        </TableCell>
                        <TableCell className={classes.LabelValue}>
                          {t(item.accessType)}
                        </TableCell>
                        <TableCell align="right" className={classes.LabelValue}>
                          {isEditable ? (
                            <>
                              {!item.thingClass?.id ? (
                                <>
                                  <IconButton
                                    onClick={() => {
                                      moveUp(item);
                                    }}
                                    size="small"
                                    color="primary"
                                    aria-label={t("MOVE_UP")}
                                    className={classes.ActionButton}
                                  >
                                    <KeyboardArrowUpIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => {
                                      moveDown(item);
                                    }}
                                    size="small"
                                    color="primary"
                                    aria-label={t("MOVE_DOWN")}
                                    className={classes.ActionButton}
                                  >
                                    <KeyboardArrowDownIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => {
                                      setThingLink(item);
                                      setIsEditDialogOpen(true);
                                    }}
                                    size="small"
                                    color="primary"
                                    aria-label={t("EDIT")}
                                    className={classes.ActionButton}
                                    style={{ marginLeft: 2 }}
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                  </IconButton>
                                </>
                              ) : (
                                "Nokta tipine ait bağlantı"
                              )}
                            </>
                          ) : (
                            <div className={classes.ActionButtonPlaceHolder} />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
