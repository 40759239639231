import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { useEffect } from "react";
import { useControl } from "react-map-gl";

import type { ControlPosition, MapRef } from "react-map-gl";

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition;

  onCreate?: (evt: { features: object[] }) => void;
  onUpdate?: (evt: { features: object[]; action: string }) => void;
  onDelete?: (evt: { features: object[] }) => void;
};

export default function DrawControl(props: DrawControlProps) {
  const draw = useControl<MapboxDraw>(
    () => new MapboxDraw(props),
    ({ map }: { map: MapRef }) => {
      if (map) {
        if (props.onCreate) map.on("draw.create", props.onCreate);
        if (props.onUpdate) map.on("draw.update", props.onUpdate);
        if (props.onDelete) map.on("draw.delete", props.onDelete);
      }
    },
    ({ map }: { map: MapRef }) => {
      if (map) {
        if (props.onCreate) map.off("draw.create", props.onCreate);
        if (props.onUpdate) map.off("draw.update", props.onUpdate);
        if (props.onDelete) map.off("draw.delete", props.onDelete);
      }
    },
    {
      position: props.position,
    }
  );

  useEffect(() => {
    const polygonButton = document.querySelector(".mapbox-gl-draw_polygon");

    if (polygonButton) {
      polygonButton.setAttribute("title", "Poligon çiz");
    }

    const trashButton = document.querySelector(".mapbox-gl-draw_trash");

    if (trashButton) {
      trashButton.setAttribute("title", "Seçilen poligonu sil"); // Buraya istediğiniz tooltip metnini ekleyin
    }
  }, [draw]);

  return null;
}

DrawControl.defaultProps = {
  onCreate: () => {},
  onUpdate: () => {},
  onDelete: () => {},
};
