import { Card, CardHeader } from "@material-ui/core";
import { ResponsiveCalendar } from "@nivo/calendar";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomerInteractionService } from "services/CustomerInteractionService";
import { getDateWFormatEn } from "services/utils";
import i18n from "../../i18n";

const CustomerInteractionDailyHeatmap = forwardRef((props, ref) => {
  const { onClick, title } = props;
  const { t } = useTranslation("customer", { i18n });

  const [dailyCounts, setDailyCounts] = useState(null);

  const [masterSearchForm, setMasterSearchForm] = useState(null);
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [isIndoorMapOpen, setIsIndoorMapOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    refreshTable(searchForm) {
      if (searchForm) {
        setMasterSearchForm(searchForm);
        CustomerInteractionService.getDailyCounts(
          searchForm,
          setDailyCounts,
          (error) => {}
        );
      }
    },
  }));

  return (
    <>
      {dailyCounts && (
        <Card
          data-fa-section="CUSTOMER_INTERACTION_DAILY_HEAT_MAP"
          style={{ overflowY: "auto" }}
        >
          {title && (
            <CardHeader
              title="Etkileşim Takvimi"
              style={{ height: 40 }}
            ></CardHeader>
          )}

          <div style={{ width: "100%", height: "10rem" }}>
            <ResponsiveCalendar
              data={dailyCounts.list.map((item) => {
                return {
                  value: item.value,
                  day: getDateWFormatEn(item.date),
                };
              })}
              from={getDateWFormatEn(dailyCounts.from)}
              to={getDateWFormatEn(dailyCounts.to)}
              emptyColor="#eeeeee"
              colors={["#61cdbb", "#97e3d5", "#e8c1a0", "#f47560"]}
              margin={{ top: 0, right: 10, bottom: 0, left: 20 }}
              yearSpacing={40}
              monthBorderColor="#ffffff"
              dayBorderWidth={2}
              dayBorderColor="#ffffff"
              legends={[
                {
                  anchor: "bottom-right",
                  direction: "row",
                  translateY: 36,
                  itemCount: 4,
                  itemWidth: 42,
                  itemHeight: 36,
                  itemsSpacing: 14,
                  itemDirection: "right-to-left",
                },
              ]}
              onClick={(day, event) => {
                if (onClick) onClick(day.day);
              }}
            />
          </div>
        </Card>
      )}
    </>
  );
});
export default CustomerInteractionDailyHeatmap;
