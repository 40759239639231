import { Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import PatrolDefTypeSelect from "components/select/PatrolDefTypeSelect";
import ThingsDataTable from "components/thingsDataTable";
import ActivePassiveDecorator from "components/ui/ActivePassiveDecorator";
import React, { Suspense, useRef, useState } from "react";
import { FaButton, FaDialog, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { PatrolDefService } from "services/PatrolDefService";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { PATROL_DEF } from "../../services/faApi";

export default function PatrolDef() {
  const tableRef = useRef();
  const { t } = useTranslation("patrolDef", { i18n });
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const accountId = localStorage.accountId;

  const [loading, setLoading] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  const columns = [
    { field: "name", label: t("NAME"), align: "center", width: "30%" },
    {
      field: "description",
      label: t("DESCRIPTION"),
      align: "center",
      width: "50%",
    },
    {
      field: "status",
      label: t("STATUS"),
      align: "center",
      width: "20%",
      valueFormatter: (val) => <ActivePassiveDecorator value={val} />,
    },
  ];
  const newPatrolDef = () => {
    return {
      id: "",
      name: "",
      description: "",
      totalDurationMin: null,
      closeDurationMin: null,
      status: "PASSIVE",
      type: "ORDERED",

      frequency: "hour",
      hourlyInterval: 8,

      dailyMod: "INTERVAL", //WEEKDAY
      dailyInterval: 1,

      weeklyInterval: 1,
      weeklyDays: [false, false, false, false, false, false, false],

      monthlyDay: 1,
      monthlyInterval: 1,

      yearlyInterval: 1,
      yearlyDay: 1,
      yearlyMonth: 0,

      startTime: new Date(2021, 1, 1, 12, 0, 0),
      startDate: new Date(),
      endDate: null,
    };
  };
  const [patrolDef, setPatrolDef] = useState(newPatrolDef());

  const handleChange = (prop) => (event) => {
    setPatrolDef({ ...patrolDef, [prop]: event.target.value });
  };

  const saveSuccess = (data) => {
    showAlert(!patrolDef.id ? t("SAVED") : t("EDITED"), "success");
    setIsEditFormOpen(false);
    setPatrolDef(newPatrolDef());

    setLoading(false);

    const tmpTo = {
      pathname: `/patrolDef/detail/${data.id}`,
      breadCrumbKey: `/patrolDef/detail/:id`,
    };
    history.push(tmpTo);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const savePatrolDef = () => {
    setLoading(true);
    PatrolDefService.save(
      {
        ...patrolDef,
        account: { id: accountId },
      },
      saveSuccess,
      saveError
    );
  };

  const newSearchForm = () => {
    let result = {
      name: null,
      description: null,
      accountId: accountId,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `/patrolDef/detail/${row.id}`,
      breadCrumbKey: `/patrolDef/detail/:id`,
    };
    history.push(tmpTo);
  };

  const newFormRender = () => {
    return (
      <>
        <FaDialog
          title={t("PATROL_DEFINITION")}
          faOpen={isEditFormOpen}
          faOnSubmit={savePatrolDef}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            setIsEditFormOpen(data);
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <FaInput
                required
                maxLength={100}
                label={t("NAME")}
                onChange={handleChange("name")}
                value={patrolDef.name || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                maxLength={100}
                label={t("DESCRIPTION")}
                onChange={handleChange("description")}
                value={patrolDef.description || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                faType="number"
                label={t("TOTAL_DURATION_MIN")}
                onChange={handleChange("totalDurationMin")}
                value={patrolDef.totalDurationMin || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                faType="number"
                label={t("CLOSE_DURATION_MIN")}
                onChange={handleChange("closeDurationMin")}
                value={patrolDef.closeDurationMin || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <PatrolDefTypeSelect
                required
                value={patrolDef.type}
                onChange={(data) => {
                  handleChangeGeneric(setPatrolDef, patrolDef, "type", data);
                }}
              />
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {newFormRender()}
        <ThingsDataTable
          searchUrl={PATROL_DEF.search + "?accountId=" + localStorage.accountId}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={1} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                setPatrolDef(newPatrolDef());
                setIsEditFormOpen(true);
              }}
              startIcon={<AddIcon />}
              data-fa-button="ADD"
            >
              {t("ADD")}
            </FaButton>
          </Grid>
          <Grid item xs={5}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <FaInput
                label={t("NAME")}
                value={searchForm.name}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "name",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("DESCRIPTION")}
                value={searchForm.description}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "description",
                    event.target.value
                  );
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
