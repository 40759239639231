import { Button, Grid } from "@material-ui/core";
import IssueClassByThingClassAutoComplete from "components/autocomplete/IssueClassByThingClassAutoComplete";
import OrganizationAutoComplete from "components/autocomplete/OrganizationAutoComplete";
import ThingClassAutoComplete from "components/autocomplete/ThingClassAutoComplete";
import UserAutoComplete from "components/autocomplete/UserAutoComplete";
import ActionTypeSelect from "components/select/ActionTypeSelect";
import PolicyActionEnum from "components/select/PolicyActionEnum";
import SchedulePlanSelect from "components/select/SchedulePlanSelect";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { PolicyService } from "services/PolicyService";
import i18n from "../../i18n";

const PolicyEdit = forwardRef((props, ref) => {
  const { onSave, policy } = props;
  const { t } = useTranslation("policy", { i18n });
  const accountId = localStorage.accountId;
  let history = useHistory();
  const faDialog = useDialog();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const newPolicy = () => {
    return {
      account: { id: accountId },
      name: null,
      description: null,
      sourceAction: null,
      targetAction: null,
      schedulePlan: { id: 0 },
      targetUser: null,
      messageTitle: null,
      messageContent: null,
      taskDescription: null,
      newIssueClass: null,
      newIssueDescription: null,
      thingClass: null,
      issueClass: null,
      status: "ACTIVE",
      ipRangeStart: null,
      ipRangeEnd: null,
      organization: null,
    };
  };
  const [policyDTO, setPolicyDTO] = useState(newPolicy());

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsDialogOpen(true);
    },
  }));

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    if (policy) {
      setPolicyDTO(policy == null ? newPolicy() : { ...policy });
    }
  }, [policy]);

  const savePolicy = () => {
    setLoading(true);

    if (policyDTO.schedulePlan && policyDTO.schedulePlan.id === 0) {
      PolicyService.save(
        { ...policyDTO, schedulePlan: null },
        saveOnSuccess,
        saveOnError
      );
    } else PolicyService.save(policyDTO, saveOnSuccess, saveOnError);
  };

  const saveOnSuccess = (data) => {
    showAlert(!policyDTO.id ? t("SAVED") : t("EDITED"), "success");
    setIsDialogOpen(false);
    setLoading(false);
    setPolicyDTO(policy ? policy : newPolicy());
    onSave(data);
  };

  const saveOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };

  const handleChange = (prop) => (event) => {
    setPolicyDTO({ ...policyDTO, [prop]: event.target.value });
  };

  const handleChangeV = (prop, val) => {
    setPolicyDTO({ ...policyDTO, [prop]: val });
  };
  const onDelete = (data) => {
    faDialog({
      description: t("Kuralı silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        PolicyService.delete(
          policyDTO,
          (data) => {
            showAlert(t("Kural Silindi"), "success");
            const tmpTo = {
              pathname: `/policy`,
              breadCrumbKey: `/policy`,
              search: "?page=0&size=10&isAsc=false&sort=name",
            };
            history.push(tmpTo);
          },
          (error) => {}
        );
      })
      .catch((err) => console.log("error:", err));
  };
  return (
    <FaDialog
      title={t("EDIT_POLICY")}
      faOpen={isDialogOpen}
      faOnSubmit={savePolicy}
      loading={loading}
      showSaveButton
      faHandleClose={() => {
        setIsDialogOpen(false);
        setPolicyDTO(policy ? policy : newPolicy());
      }}
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item xs={12}>
          <FaInput
            label={t("NAME")}
            required
            onChange={handleChange("name")}
            value={policyDTO.name || ""}
          />
        </Grid>

        <Grid item xs={12}>
          <FaInput
            label={t("DESCRIPTION")}
            faType="description"
            inputProps={{ maxLength: 255 }}
            onChange={handleChange("description")}
            value={policyDTO.description || ""}
          />
        </Grid>

        <Grid item xs={12}>
          <SchedulePlanSelect
            value={policyDTO.schedulePlan ? policyDTO.schedulePlan.id : 0}
            onChange={(data) => {
              handleChangeV("schedulePlan", data);
            }}
            label={t("SCHEDULE_PLAN")}
          />
        </Grid>

        <Grid item xs={12}>
          <ActionTypeSelect
            required
            label={t("SOURCE_ACTION")}
            value={policyDTO.sourceAction}
            onChange={(data) => {
              handleChangeV("sourceAction", data);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <ThingClassAutoComplete
            accountId={accountId}
            required
            value={policyDTO.thingClass}
            onChange={(data) => {
              handleChangeV("thingClass", data);
            }}
          />
        </Grid>
        {(policyDTO.sourceAction === "ISSUE_ADD" ||
          policyDTO.sourceAction === "ISSUE_CLOSED") && (
          <Grid item xs={12}>
            <IssueClassByThingClassAutoComplete
              accountId={accountId}
              label="Kaynak Sorun Tipi"
              thingClassId={policyDTO.thingClass?.id}
              onChange={(data) => {
                handleChangeV("issueClass", data);
              }}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <PolicyActionEnum
            required
            label={t("TARGET_ACTION")}
            value={policyDTO.targetAction}
            onChange={(data) => {
              handleChangeV("targetAction", data);
            }}
          />
        </Grid>

        {(policyDTO.targetAction === "NEW_TASK" ||
          policyDTO.targetAction === "EMAIL_NOTIFICATION" ||
          policyDTO.targetAction === "SMS_NOTIFICATION" ||
          policyDTO.targetAction === "PUSH_NOTIFICATION") && (
          <Grid item xs={12}>
            <UserAutoComplete
              value={policyDTO?.targetUser}
              accountId={accountId}
              onChange={(data) => {
                handleChangeV("targetUser", data);
              }}
              label={t("TARGET_USER")}
            />
          </Grid>
        )}

        {(policyDTO.targetAction === "EMAIL_NOTIFICATION" ||
          policyDTO.targetAction === "PUSH_NOTIFICATION") && (
          <Grid item xs={12}>
            <FaInput
              label={t("MESSAGE_TITLE")}
              required
              faType="description"
              onChange={handleChange("messageTitle")}
              value={policyDTO.messageTitle || ""}
            />
          </Grid>
        )}

        {(policyDTO.targetAction === "EMAIL_NOTIFICATION" ||
          policyDTO.targetAction === "PUSH_NOTIFICATION" ||
          policyDTO.targetAction === "SMS_NOTIFICATION") && (
          <Grid item xs={12}>
            <FaInput
              label={t("MESSAGE_CONTENT")}
              required
              faType="description"
              inputProps={{
                maxLength:
                  policyDTO.targetAction === "SMS_NOTIFICATION" ? 160 : null,
              }}
              onChange={handleChange("messageContent")}
              value={policyDTO.messageContent || ""}
            />
          </Grid>
        )}

        {policyDTO.targetAction === "NEW_TASK" && (
          <Grid item xs={12}>
            <FaInput
              label={t("TASK_DESCRIPTION")}
              required
              faType="description"
              onChange={handleChange("taskDescription")}
              value={policyDTO.taskDescription || ""}
            />
          </Grid>
        )}

        {policyDTO.targetAction === "NEW_ISSUE" && (
          <Grid item xs={12}>
            <IssueClassByThingClassAutoComplete
              required
              accountId={accountId}
              value={policyDTO.newIssueClass}
              thingClassId={policyDTO.thingClass?.id}
              onChange={(data) => {
                handleChangeV("newIssueClass", data);
              }}
              label={t("NEW_ISSUE_CLASS")}
            />
          </Grid>
        )}

        {policyDTO.targetAction === "NEW_ISSUE" && (
          <Grid item xs={12}>
            <FaInput
              label={t("NEW_ISSUE_DESCRIPTION")}
              required
              faType="description"
              onChange={handleChange("newIssueDescription")}
              value={policyDTO.newIssueDescription || ""}
            />
          </Grid>
        )}

        {policyDTO.targetAction === "DISPATCH_ORGANIZATION" && (
          <Grid item xs={12}>
            <FaInput
              label={t("IP_RANGE_START")}
              inputProps={{ maxLength: 255 }}
              onChange={handleChange("ipRangeStart")}
              value={policyDTO.ipRangeStart || ""}
            />
          </Grid>
        )}

        {policyDTO.targetAction === "DISPATCH_ORGANIZATION" && (
          <Grid item xs={12}>
            <FaInput
              label={t("IP_RANGE_END")}
              inputProps={{ maxLength: 255 }}
              onChange={handleChange("ipRangeEnd")}
              value={policyDTO.ipRangeEnd || ""}
            />
          </Grid>
        )}

        {policyDTO.targetAction === "DISPATCH_ORGANIZATION" && (
          <Grid item xs={12}>
            <OrganizationAutoComplete
              required
              accountId={accountId}
              value={policyDTO.organization}
              onChange={(data) => {
                handleChangeV("organization", data);
              }}
              label={t("ORGANIZATION")}
            />
          </Grid>
        )}
      </Grid>

      {policyDTO.id && (
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button onClick={onDelete} color="secondary">
            {t("Sil")}
          </Button>
        </Grid>
      )}
    </FaDialog>
  );
});

export default PolicyEdit;
