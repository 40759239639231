import { CardHeader } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { FaPieChart } from "react-base-fa/dist/fa/facharts";
import { useTranslation } from "react-i18next";
import { ThingStatisticService } from "services/statistic/statisticService";
import { isTNF } from "services/utils";
import { CHART_COLORS } from "services/utils/ChartConstants";
import i18n from "../../../i18n";

export default function HomepageThings(props) {
  const { size, refreshCount } = props;
  const { t } = useTranslation("components", { i18n });

  const accountId = localStorage.accountId;

  const [chartThingClassNumber, setChartThingClassNumber] = useState([]);

  useEffect(() => {
    ThingStatisticService.activeThingCountByThingClass(
      accountId,
      5,
      setChartThingClassNumber
    );
  }, [refreshCount]);

  return (
    <Grid item lg={size} xs={12}>
      <Card>
        <CardHeader
          title={isTNF(
            t("HOMEPAGE_ITEM.THINGS"),
            t("HOMEPAGE_ITEM.THINGS_T2C")
          )}
        />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item lg={12} xs={12}>
              <Grid item>
                <FaPieChart
                  data={chartThingClassNumber}
                  legends={[]}
                  height="300px"
                  colors={CHART_COLORS}
                  padAngle={1}

                  //colorBy="index"
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
