import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { Add, Remove } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import Rating from "@material-ui/lab/Rating";
import ServiceOperationClassAutoComplete from "components/autocomplete/ServiceOperationClassAutoComplete";
import StockAutoComplete from "components/autocomplete/StockAutoComplete";
import UserAutoComplete from "components/autocomplete/UserAutoComplete";
import ThingDetail from "components/detail/ThingDetail";
import UserDetail from "components/detail/UserDetail";
import ThingSelector from "components/selector/ThingSelector";
import ThingsDataTable from "components/thingsDataTable";
import update from "immutability-helper";
import React, { Suspense, useEffect, useRef, useState } from "react";
import {
  FaButton,
  FaDatePicker,
  FaDialog,
  FaInput,
  FaSearch,
} from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SolutionService } from "services/SolutionService";
import { isTNF } from "services/utils";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { AccPreferencesService } from "../../services/accPreferences/accPreferencesService";
import { SOLUTION } from "../../services/faApi";
import OpenIssues from "./OpenIssues";
import SolutionCreate from "./SolutionCreate";
import { useStyles } from "./style";

export default function Solutions() {
  const tableRef = useRef();
  const { t } = useTranslation("solutions", { i18n });
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  let queryParams = qsParse(history.location.search);
  const accountId = localStorage.accountId;
  queryParams["accountId"] = localStorage.getItem("accountId");
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const userInfo = useSelector(getUserInfo);

  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  const [selectedServiceOperationClass, setSelectedServiceOperationClass] =
    useState(null);
  const [selectedStock, setSelectedStock] = useState(null);
  const [selectedStockAmount, setSelectedStockAmount] = useState(1);
  const [isSolutionRegisterFormOpen, setIsSolutionRegisterFormOpen] =
    useState(false);
  const [selectedIssues, setSelectedIssues] = useState([]);
  const [solutionControlItems, setSolutionControlItems] = useState([]);
  const [solutionStocks, setSolutionStocks] = useState([]);

  const [accPreferences, setAccPreferences] = useState(null);
  const [isServiceOperationClassFormOpen, setIsServiceOperationClassFormOpen] =
    useState(false);

  const [isStockFormOpen, setIsStockFormOpen] = useState(false);

  useEffect(() => {
    if (accountId)
      AccPreferencesService.findByAccount(accountId, (data) => {
        setAccPreferences(data);
      });
  }, [accountId]);

  const columns = [
    {
      field: "notificationDate",
      label: t("NOTIFICATION_DATE"),
      align: "center",
      width: "10%",
      inner: "notificationDate",
      type: "smartDateTime",
    },
    {
      field: "thing",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      width: "40%",
      valueFormatter: (val) => {
        return val == null ? null : <ThingDetail value={val} noLink={true} />;
      },
    },
    {
      field: "user",
      label: t("USER"),
      align: "center",
      width: "20%",
      inner: "user",
      valueFormatter: (val) => <UserDetail value={val} type="USER" />,
    },
    {
      field: "notifier",
      label: t("DESCRIPTION"),
      align: "center",
      width: "30%",
      valueFormatter: (val, row) => {
        return (
          t(row.solutionType) + (row.description && " - ") + row.description
        );
      },
    },
  ];

  const newSolution = () => {
    return {
      id: "",
      notificationDate: new Date(),
      thing: null,
      user: { ...userInfo },
      description: "",
      solutionType: 1,
      serviceOperationClasses: [],
      solutionStocks: [],
      formSection: false,
    };
  };

  const [solutionDTO, setSolutionDTO] = useState(newSolution());

  useEffect(() => {
    if (solutionDTO?.thing) {
      let issueClassIdList = selectedIssues.map((item) => item.issueClass.id);
      let serviceOperationClassIdList = solutionDTO.serviceOperationClasses.map(
        (item) => item.id
      );
      SolutionService.createSolutionControlItems(
        accountId,
        solutionDTO.thing.id,
        issueClassIdList,
        serviceOperationClassIdList,
        populateControlItemsOnSuccess,
        populateControlItemsOnError
      );
    }
  }, [
    selectedIssues,
    solutionDTO?.serviceOperationClasses,
    solutionDTO?.thing,
  ]);

  const handleChange = (prop) => (event) => {
    setSolutionDTO({ ...solutionDTO, [prop]: event.target.value });
  };

  const newSearchForm = () => {
    let result = {
      thing: null,
      thingId: null,
      user: null,
      userId: null,
      description: null,
      solutionType: null,
      accountId: accountId,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm({
      thing: null,
      thingId: null,
      user: null,
      userId: null,
      description: null,
      solutionType: null,
      accountId: accountId,
    });
  };

  const saveSolution = () => {
    if (
      solutionDTO.formSection &&
      solutionControlItems &&
      solutionControlItems.length > 0
    ) {
      setSolutionDTO({ ...solutionDTO, formSection: false });
      return;
    }

    if (
      solutionControlItems &&
      solutionControlItems.length > 0 &&
      accPreferences.emptySolutionControlList === "NO"
    ) {
      let empty = false;
      solutionControlItems.map((item) => {
        if (item.controlItem.itemType === "YES_NO" && item.yesNoValue == null)
          empty = true;
        else if (
          item.controlItem.itemType === "RATING_SCALE" &&
          item.ratingValue == null
        )
          empty = true;
      });

      if (empty) {
        showAlert("Lütfen kontrol listesinin tamamını cevaplayın", "error");
        return;
      }
    }

    setLoading(true);
    var req = requests.getSimplePostRequest(SOLUTION.save, {
      ...solutionDTO,
      solutionControlItems: [...solutionControlItems],
      solutionStocks: [...solutionStocks],
      account: { id: accountId },
      issues: selectedIssues,
    });
    FaAxios(req)
      .then((response) => {
        showAlert(!solutionDTO.id ? t("SAVED") : t("EDITED"), "success");
        setTimeout(() => {
          setIsSolutionRegisterFormOpen(false);
        }, 1000);
        setTimeout(() => {
          setSolutionDTO(newSolution());
        }, 1200);
        tableRef.current.refreshTable();
      })
      .catch((error) => {
        showAlert(error.message, "error");
        setLoading(false);
      });
    setLoading(false);
  };

  const thingChanged = (thing) => {};

  const populateControlItemsOnSuccess = (data) => {
    setSolutionControlItems(data);
  };
  const populateControlItemsOnError = () => {};

  const handleChipDelete = (index) => {
    var temp = [...solutionDTO.serviceOperationClasses];
    temp.splice(index, 1);
    setSolutionDTO({ ...solutionDTO, serviceOperationClasses: temp });
  };

  const stockDelete = (index) => {
    var temp = [...solutionStocks];
    temp.splice(index, 1);
    setSolutionStocks([...temp]);
  };

  const addServiceOperationClass = () => {
    setIsServiceOperationClassFormOpen(false);
    if (selectedServiceOperationClass == null) return;
    if (
      solutionDTO.serviceOperationClasses.findIndex(
        (i) => i.id === selectedServiceOperationClass.id
      ) === -1
    ) {
      setSolutionDTO({
        ...solutionDTO,
        serviceOperationClasses: [
          ...solutionDTO.serviceOperationClasses,
          selectedServiceOperationClass,
        ],
      });
    }

    setSelectedServiceOperationClass({});
  };

  const addStock = () => {
    const newValue = {
      stock: selectedStock,
      amount: selectedStockAmount,
    };

    setSolutionStocks([...solutionStocks, newValue]);
    setSelectedStock({});
    setSelectedStockAmount("");

    setIsStockFormOpen(false);
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const handleDetailChangeYesNo = (index, val) => {
    var updatedItem = update(solutionControlItems[index], {
      yesNoValue: { $set: val },
    });
    var newData = update(solutionControlItems, {
      $splice: [[index, 1, updatedItem]],
    });
    setSolutionControlItems(newData);
  };

  const handleDetailChangeRating = (index, val) => {
    var updatedItem = update(solutionControlItems[index], {
      ratingValue: { $set: val },
    });
    var newData = update(solutionControlItems, {
      $splice: [[index, 1, updatedItem]],
    });
    setSolutionControlItems(newData);
  };

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `solutions/detail/${row.id}`,
      breadCrumbKey: `/solutions/detail/:id`,
    };
    history.push(tmpTo);
  };

  const createControlItem = (item, index) => {
    return (
      <div>
        <div>
          <b>{item.controlItem.orderNo}.</b> {item.controlItem.question}
        </div>
        <div>
          {item.controlItem.itemType === "YES_NO" && (
            <RadioGroup
              row
              aria-label="position"
              value={"" + item.yesNoValue}
              onChange={(event) => {
                handleDetailChangeYesNo(index, event.target.value);
              }}
            >
              <FormControlLabel
                value={"YES"}
                control={<Radio color="primary" />}
                label={item.controlItem.yesLabel}
              />
              <FormControlLabel
                value={"NO"}
                control={<Radio color="primary" />}
                label={item.controlItem.noLabel}
              />
            </RadioGroup>
          )}
          {item.controlItem.itemType === "RATING_SCALE" && (
            <Rating
              max={item.maxRating}
              defaultValue={item.ratingValue}
              onChange={(event) => {
                handleDetailChangeRating(index, event.target.value);
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const renderFormInputs = () => {
    return (
      <>
        <Grid item lg={6} xs={12}>
          <ThingSelector
            required={true}
            value={solutionDTO.thing}
            accountId={accountId}
            onChange={(data) => {
              setSolutionDTO({
                ...solutionDTO,
                thing: data,
              });
              thingChanged(data);
            }}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <FaDatePicker
            faType="dateTime"
            label={t("NOTIFICATION_DATE")}
            style={{ minHeight: 65 }}
            value={solutionDTO.notificationDate}
            faOnChange={(value) => {
              setSolutionDTO({
                ...solutionDTO,
                notificationDate: value,
              });
            }}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <UserAutoComplete
            value={solutionDTO.user}
            required={true}
            accountId={accountId}
            onChange={(data) => {
              setSolutionDTO({
                ...solutionDTO,
                user: data,
              });
            }}
            label={t("USER")}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <FaInput
            label={t("DESCRIPTION")}
            onChange={handleChange("description")}
            value={solutionDTO.description || ""}
          />
        </Grid>

        <Grid item xs={12}>
          <OpenIssues
            thing={solutionDTO.thing}
            setIssues={setSelectedIssues}
            accPreferences={accPreferences}
          />
        </Grid>

        <Grid item lg={12} xs={12}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("SOLUTION_DETAILS")}</TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    <IconButton
                      onClick={() => {
                        setSelectedServiceOperationClass(null);
                        setIsServiceOperationClassFormOpen(true);
                      }}
                    >
                      <Add />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {solutionDTO?.serviceOperationClasses.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell>{data.className}</TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      <IconButton
                        onClick={() => {
                          handleChipDelete(index);
                        }}
                      >
                        <Remove />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {solutionDTO.serviceOperationClasses.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={2}>Kayıt bulunmamaktadır.</TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item lg={12} xs={12}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={3}>{t("USED_MATERIALS")}</TableCell>
                  <TableCell width="5%" style={{ textAlign: "right" }}>
                    <IconButton
                      onClick={() => {
                        setIsStockFormOpen(true);
                      }}
                    >
                      <Add />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="50%">Malzeme</TableCell>
                  <TableCell width="30%">Kod</TableCell>
                  <TableCell width="15%">Miktar</TableCell>
                  <TableCell width="5%"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {solutionStocks?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.stock.name}</TableCell>
                    <TableCell>{item.stock.code}</TableCell>
                    <TableCell>
                      {item.amount} {item.stock.unit.name}
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      <IconButton
                        onClick={() => {
                          stockDelete(index);
                        }}
                      >
                        <Remove />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {solutionStocks == null || solutionStocks.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4}>
                      Malzeme kaydı bulunmamaktadır.
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item lg={6} xs={12}>
          <FaInput
            label={t("LABOR_TIME_MINUTES")}
            faType="number"
            onChange={handleChange("laborTime")}
            value={solutionDTO.laborTime || ""}
          />
        </Grid>
        <Grid item lg={6} xs={12}></Grid>
      </>
    );
  };

  const newServiceOperationClassRender = () => {
    return (
      <>
        <Dialog
          open={isServiceOperationClassFormOpen}
          onClose={(data) => {
            setIsServiceOperationClassFormOpen(false);
          }}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle id="form-dialog-title">Yapılan İşlem Ekle</DialogTitle>
          <DialogContent>
            <Grid item container justifyContent="center" spacing={1}>
              <Grid item xs={12}>
                <ServiceOperationClassAutoComplete
                  accountId={accountId}
                  value={selectedServiceOperationClass}
                  onChange={(data) => {
                    setSelectedServiceOperationClass(data);
                  }}
                  label="Bakım görevi eklemek için seçiniz"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={addServiceOperationClass} color="primary">
              Ekle
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const newStockRender = () => {
    return (
      <>
        <Dialog
          open={isStockFormOpen}
          onClose={(data) => {
            setIsStockFormOpen(false);
          }}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle id="form-dialog-title">
            Kullanılan Malzeme Ekle
          </DialogTitle>
          <DialogContent>
            <Grid item container justifyContent="center" spacing={1}>
              <Grid item xs={12}>
                <StockAutoComplete
                  accountId={accountId}
                  value={selectedStock}
                  label="Kullanılan malzeme eklemek için seçiniz"
                  onChange={(data) => {
                    setSelectedStock(data);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FaInput
                  label={
                    t("Miktar") +
                    (selectedStock == null
                      ? ""
                      : " (" + selectedStock?.unit?.name + ")")
                  }
                  faType="number"
                  value={selectedStockAmount}
                  onChange={(event) => {
                    setSelectedStockAmount(event.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={addStock} color="primary">
              Ekle
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const newIssueInspectionRegisterRender = () => {
    return (
      <>
        <FaDialog
          title={"Yeni Servis Operasyon"}
          faOpen={isSolutionRegisterFormOpen}
          faOnSubmit={saveSolution}
          loading={loading}
          faMaxWidth="lg"
          showSaveButton
          faHandleClose={(data) => {
            setIsSolutionRegisterFormOpen(false);
            setSolutionDTO(newSolution());
          }}
        >
          <Grid item container justifyContent="center" spacing={1}>
            {solutionDTO.formSection && renderFormInputs()}
            {!solutionDTO.formSection &&
              solutionControlItems &&
              solutionControlItems.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="caption">Kontrol Listesi</Typography>
                  {solutionControlItems.map((item, index) =>
                    createControlItem(item, index)
                  )}
                </Grid>
              )}
          </Grid>
        </FaDialog>
      </>
    );
  };

  const solutionOnChange = (data) => {
    const tmpTo = {
      pathname: `solutions/detail/${data.id}`,
      breadCrumbKey: `/solutions/detail/:id`,
    };
    history.push(tmpTo);
  };

  // xs, sm, md, lg, and xl.
  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {newIssueInspectionRegisterRender()}
        {newServiceOperationClassRender()}
        {newStockRender()}

        <SolutionCreate
          onSave={solutionOnChange}
          open={isEditFormOpen}
          onClose={() => {
            setIsEditFormOpen(false);
          }}
        />

        <ThingsDataTable
          searchUrl={SOLUTION.search + "?accountId=" + localStorage.accountId}
          exportUrl={SOLUTION.export + "?accountId=" + localStorage.accountId}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
        />

        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={1} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                //setIsSolutionRegisterFormOpen(true);
                setIsEditFormOpen(true);
              }}
              startIcon={<AddIcon />}
              data-fa-button="NEW_SERVICE_OPERATION"
            >
              {t("NEW_SERVICE_OPERATION")}
            </FaButton>
          </Grid>
          <Grid item xs={5}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <UserAutoComplete
                accountId={accountId}
                value={searchForm.user}
                label={t("USER")}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "user",
                    data,
                    "userId",
                    data?.id
                  );
                }}
              />
              <ThingSelector
                accountId={accountId}
                value={searchForm.thing}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "thing",
                    data,
                    "thingId",
                    data?.id
                  );
                }}
              />
              <FaInput
                label={t("DESCRIPTION")}
                value={searchForm.description}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "description",
                    event.target.value
                  );
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
