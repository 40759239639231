import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { THING } from "../../services/faApi";

export const ThingService = {
  changeGpsLocation: (
    thingId,
    gpsLocation,
    location,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimplePostRequest(
      THING.changeGpsLocation +
        "?thingId=" +
        thingId +
        "&gpsLocation=" +
        encodeURI(gpsLocation) +
        "&location=" +
        encodeURI(location)
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  changeIndoorLocation: (
    thingId,
    indoorLocation,
    locationDetailId,
    location,
    floor,
    roomNumber,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimplePostRequest(
      THING.changeIndoorLocation +
        "?thingId=" +
        thingId +
        "&locationDetailId=" +
        locationDetailId +
        "&indoorLocation=" +
        encodeURI(indoorLocation) +
        "&location=" +
        encodeURI(location) +
        "&floor=" +
        encodeURI(floor ?? "") +
        "&roomNumber=" +
        encodeURI(roomNumber ?? "")
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  update: (thing, callback, errorCallback) => {
    let reqBody = {
      ...thing,
    };

    let req = requests.getSimplePostRequest(THING.update, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  setDefaultImage: (thingId, objectId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      THING.setDefaultImage +
        "?thingId=" +
        thingId +
        "&objectId=" +
        encodeURI(objectId)
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  uploadDocument: (
    id,
    document,
    name,
    description,
    accessType,
    callback,
    errorCallback
  ) => {
    let formData = new FormData();
    formData.append("multipartFile", document.file, document.file.name);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("accessType", accessType);

    let req = requests.getSimplePostRequest(
      THING.uploadDocument + "/" + id,
      formData
    );
    req["header"] = { "Content-Type": "multipart/form-data" };

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findDocuments: (thingId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      THING.findDocuments + "?thingId=" + thingId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(THING.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  downloadDocument: (thingId, objectId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      THING.downloadDocument +
        "?thingId=" +
        thingId +
        "&objectId=" +
        encodeURI(objectId)
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  downloadImage: (thingId, objectId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      THING.downloadImage +
        "?thingId=" +
        thingId +
        "&objectId=" +
        encodeURI(objectId)
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  deleteDocument: (thingId, objectId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      THING.deleteDocument +
        "?thingId=" +
        thingId +
        "&objectId=" +
        encodeURI(objectId)
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  changeOrganization: (thingId, organizationId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      THING.changeOrganization +
        "?thingId=" +
        thingId +
        "&organizationId=" +
        organizationId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  changeOwner: (thingChange, callback, errorCallback) => {
    let formData = new FormData();
    if (thingChange.document)
      formData.append(
        "multipartFile",
        thingChange.document.file,
        thingChange.document.file.name
      );
    formData.append("thingId", thingChange.thingId);
    if (thingChange.ownerUser)
      formData.append("ownerUserId", thingChange.ownerUser.id);
    if (thingChange.ownerUserGroup)
      formData.append("ownerUserGroupId", thingChange.ownerUserGroup.id);
    if (thingChange.user) formData.append("userId", thingChange.user.id);
    if (thingChange.userGroup)
      formData.append("userGroupId", thingChange.userGroup.id);
    if (thingChange.mtncUser)
      formData.append("mtncUserId", thingChange.mtncUser.id);
    if (thingChange.mtncUserGroup)
      formData.append("mtncUserGroupId", thingChange.mtncUserGroup.id);
    if (thingChange.organization)
      formData.append("organizationId", thingChange.organization.id);
    formData.append("description", thingChange.description);
    formData.append("date", thingChange.date);
    if (thingChange.ownerUserText)
      formData.append("ownerUserText", thingChange.ownerUserText);
    if (thingChange.userText) formData.append("userText", thingChange.userText);
    if (thingChange.mtncUserText)
      formData.append("mtncUserText", thingChange.mtncUserText);
    let req = requests.getSimplePostRequest(THING.changeOwner, formData);
    req["header"] = { "Content-Type": "multipart/form-data" };

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  makeDocumentPublic: (objectId, accountId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      THING.makeDocumentPublic +
        "?objectId=" +
        encodeURI(objectId) +
        "&accountId=" +
        accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  makeDocumentPrivate: (objectId, accountId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      THING.makeDocumentPrivate +
        "?objectId=" +
        encodeURI(objectId) +
        "&accountId=" +
        accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  makeDocumentAttendant: (objectId, accountId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      THING.makeDocumentAttendant +
        "?objectId=" +
        encodeURI(objectId) +
        "&accountId=" +
        accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  makeImagePublic: (objectId, accountId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      THING.makeImagePublic +
        "?objectId=" +
        encodeURI(objectId) +
        "&accountId=" +
        accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  makeImagePrivate: (objectId, accountId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      THING.makeImagePrivate +
        "?objectId=" +
        encodeURI(objectId) +
        "&accountId=" +
        accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findMissingDataList: (
    accountId,
    dynamicFormIdList,
    callback,
    errorCallback
  ) => {
    let params = "?accountId=" + accountId;
    dynamicFormIdList.map((item) => {
      params = params + "&dynamicFormIdList=" + item;
      return null;
    });

    let req = requests.getSimpleGetRequest(THING.findMissingDataList + params);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  search: (searchForm, paging, callback, errorCallback) => {
    let s = {
      ...searchForm,
      paging: {
        ...paging,
      },
    };
    var req = requests.getSimplePostRequest(THING.search, s);

    FaAxios(req)
      .then((response) => {
        callback(response.data.list);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  searchMissingData: (searchForm, paging, callback, errorCallback) => {
    let s = {
      ...searchForm,
      paging: {
        ...paging,
      },
    };
    var req = requests.getSimplePostRequest(THING.searchMissingData, s);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  check_tid: (cipher, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(THING.check_tid, {
      cipher,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  deleteImage: (thingId, objectId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      THING.deleteImage +
        "?thingId=" +
        thingId +
        "&objectId=" +
        encodeURI(objectId)
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  downloadThumbnails: (thingId, callback, errorCallback) => {
    const req = requests.getSimpleGetRequest(THING.downloadThumbnails, {
      thingId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (form, callback, errorCallback) => {
    let reqBody = {
      ...form,
    };

    let req = requests.getSimplePostRequest(THING.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  checkThingDesc: (accountId, id, thingDesc, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(THING.checkThingDesc, {
      accountId,
      id,
      thingDesc,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getSystemThing: (accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(THING.getSystemThing, {
      accountId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  archive: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(THING.archive + "?id=" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  unarchive: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(THING.unarchive + "?id=" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  convertToProduct: (
    id,
    thingClassId,
    productType,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimplePostRequest(
      THING.convertToProduct +
        "?id=" +
        id +
        "&thingClassId=" +
        thingClassId +
        "&productType=" +
        productType
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  convertToInteractionPoint: (id, thingClassId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      THING.convertToInteractionPoint +
        "?id=" +
        id +
        "&thingClassId=" +
        thingClassId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  updatePublicReportPageTemplate: (
    id,
    publicReportPageTemplate,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimplePostRequest(
      THING.updatePublicReportPageTemplate +
        "?id=" +
        id +
        "&publicReportPageTemplate=" +
        publicReportPageTemplate
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        console.log("error", error?.response?.data);
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getHashtagList: (accountId, limit, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(THING.getHashtagList, {
      accountId,
      limit,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  checkAddNewThing: (accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      THING.checkAddNewThing + "?accountId=" + accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  uploadImage: async (newImages, thingId, callback, errorCallback) => {
    for await (const fileObj of newImages) {
      let fd = new FormData();
      fd.append("multipartFile", fileObj, fileObj.name);
      let req = requests.getSimplePostRequest(
        THING.uploadFile + "/" + thingId,
        fd
      );
      req["header"] = { "Content-Type": "multipart/form-data" };
      await FaAxios(req)
        .then((response) => {
          callback();
        })
        .catch((error) => {
          errorCallback();
        });
    }
  },
};
