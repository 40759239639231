import { IconButton } from "@material-ui/core";
import ThingsHelpIcon from "components/icons/ThingsHelpIcon";
import ThingsSteps from "features/tutorial/ThingsSteps";
import TutorialElement from "features/tutorial/TutorialElement";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function InvitationHelp(props) {
  const { value } = props;
  const { t } = useTranslation("help", { i18n });

  const classes = useStyles();
  const stepsRef = useRef();
  const [open, setOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            <b>Kullanıcı Davet*</b> bilgilerinin yer aldığı alandır. <br />
            <br />
            <b>*</b>: Birden fazla kullanıcı davet edilmiş olmalıdır.
          </TutorialElement>
        ),
      },
      {
        element:
          "[data-fa-section='INVITATIONS'] > div > div > div > div > div > div.MuiTableContainer-root > table > thead",
        intro: (
          <TutorialElement icon="click">
            Davetleri{" "}
            <b>Davet Tarihi, Hesap, Davet Mesajı, Kabul/Ret Tarihi, Durum</b> ve{" "}
            <b>İşlem’e</b> göre listelemek için kolon başlıklarına tıklayın.
          </TutorialElement>
        ),
      },
    ]);
  }, []);

  return (
    <>
      {open && (
        <ThingsSteps
          code={value}
          steps={steps}
          ref={stepsRef}
          onStepChange={setStepIndex}
          afterComplete={() => {
            setOpen(true);
          }}
        />
      )}
      <IconButton
        aria-label="help"
        onClick={() => setOpen(true)}
        title={t("HELP")}
      >
        <ThingsHelpIcon />
      </IconButton>
    </>
  );
}

InvitationHelp.propTypes = {
  value: PropTypes.string,
};
