import { IconButton } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { List } from "@material-ui/icons";
import LoadingBar from "components/loadingBar";
import i18n from "i18n";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { SurveyService } from "services/SurveyService";
import { labelStyles } from "styles/ThingsLabelStyle";
import SurveyAnalysisBool from "./SurveyAnalysisBool";
import SurveyAnalysisGeneric from "./SurveyAnalysisGeneric";
import SurveyAnalysisMatrix from "./SurveyAnalysisMatrix";
import SurveyAnalysisMultipleChoice from "./SurveyAnalysisMultipleChoice";
import SurveyAnalysisNumeric from "./SurveyAnalysisNumeric";
import SurveyAnalysisText from "./SurveyAnalysisText";

export default function SurveyAnalysis() {
  const labelClasses = labelStyles();
  const { t } = useTranslation("survey", { i18n });
  const { id } = useParams();
  const accountId = localStorage.accountId;
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const dialogRef = useRef();
  let history = useHistory();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [loading, setLoading] = useState(false);
  const [analysis, setAnalysis] = useState(null);

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    setLoading(true);
    SurveyService.analysis(id, findByIdOnSuccess, findByIdOnError);
  };

  const findByIdOnSuccess = (data) => {
    setAnalysis({
      ...data,
    });
    setLoading(false);
  };

  const findByIdOnError = (error) => {
    console.log("findByIdOnError", error);
    showAlert(error, "error");
    setLoading(false);
  };

  const allAnswers = () => {
    const tmpTo = {
      pathname: `/survey/surveyAllAnswers/${id}`,
      breadCrumbKey: `/survey/surveyAllAnswers/:id`,
    };
    history.push(tmpTo);
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid container spacing={1}>
          <Grid item lg={12}>
            <Grid item container spacing={1}>
              <Grid item lg={12}>
                <Card>
                  <CardHeader
                    title={t("Analiz")}
                    data-fa-section="Analiz"
                    action={
                      <IconButton
                        onClick={allAnswers}
                        size="small"
                        title={"Toplu Cevaplar"}
                      >
                        <List />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <Grid item container spacing={1}>
                      <Grid item xs={12}>
                        <Grid item container spacing={1}>
                          <Grid
                            item
                            xs={12}
                            className={labelClasses.LabelLastRow}
                          >
                            <Typography className={labelClasses.LabelHeader}>
                              {t("Anket")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {analysis?.survey.multiLangName !== null
                                ? analysis?.survey.multiLangName["tr"]
                                : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={12}>
            <Grid item container spacing={1}>
              <Grid item xs={12}>
                {analysis &&
                  analysis.surveyQuestions &&
                  analysis.surveyQuestions.map((item, index) => {
                    if (
                      item.questionType === "MULTIPLE_CHOICE" ||
                      item.questionType === "DROPDOWN" ||
                      item.questionType === "CHECKBOX"
                    ) {
                      return (
                        <SurveyAnalysisMultipleChoice
                          question={item}
                          questionAnalysis={analysis.analysisList.find(
                            (x) => x.surveyQuestionId === item.id
                          )}
                          currentLang="tr"
                        />
                      );
                    } else if (
                      item.questionType === "STAR_RATING" ||
                      item.questionType === "INTEGER" ||
                      item.questionType === "DECIMAL" ||
                      item.questionType === "SLIDER" ||
                      item.questionType === "NET_PROMOTER_SCORE"
                    ) {
                      return (
                        <SurveyAnalysisNumeric
                          question={item}
                          questionAnalysis={analysis.analysisList.find(
                            (x) => x.surveyQuestionId === item.id
                          )}
                        />
                      );
                    } else if (
                      item.questionType === "TEXT" ||
                      item.questionType === "COMMENT"
                    ) {
                      return (
                        <SurveyAnalysisText
                          question={item}
                          questionAnalysis={analysis.analysisList.find(
                            (x) => x.surveyQuestionId === item.id
                          )}
                        />
                      );
                    } else if (item.questionType === "MATRIX_TEMP") {
                      return (
                        <SurveyAnalysisMatrix
                          question={item}
                          questionAnalysis={analysis.analysisList.find(
                            (x) => x.surveyQuestionId === item.id
                          )}
                        />
                      );
                    } else if (item.questionType === "YES_NO") {
                      return (
                        <SurveyAnalysisBool
                          question={item}
                          questionAnalysis={analysis.analysisList.find(
                            (x) => x.surveyQuestionId === item.id
                          )}
                        />
                      );
                    } else
                      return (
                        <SurveyAnalysisGeneric
                          question={item}
                          questionAnalysis={analysis.analysisList.find(
                            (x) => x.surveyQuestionId === item.id
                          )}
                        />
                      );
                  })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
