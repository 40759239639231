import { Card, CardHeader, Grid } from "@material-ui/core";
import CustomerInteractionDailyHeatmap from "features/customerInteraction/CustomerInteractionDailyHeatmap";
import FirstAndLastInteractions from "features/customerInteraction/FirstAndLastInteractions";
import InteractionCountsByOperationChart from "features/customerInteraction/InteractionCountsByOperationChart";
import TopInteractionsList from "features/customerInteraction/TopInteractionsList";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

export default function ThingInteractions(props) {
  const { thingId } = props;

  const accountId = localStorage.accountId;

  const chartRef = useRef();
  const pieChartRef = useRef();
  const topInteractionsRef = useRef();
  const firstAndLastInteractionsRef = useRef();

  const { t } = useTranslation("thingDetail", { i18n });

  const [dailyCounts, setDailyCounts] = useState({ list: [] });

  const searchForm = { accountId: accountId, thingId: thingId, limit: 20 };

  useEffect(() => {
    if (thingId) {
      chartRef.current.refreshTable(searchForm);
      pieChartRef.current.refreshTable(searchForm);
      topInteractionsRef.current.refreshTable(searchForm);
      firstAndLastInteractionsRef.current.refreshTable(searchForm);
    }
  }, [thingId]);

  return (
    <Card className="fai-thingInteractions">
      <CardHeader
        title={t("INTERACTION_STATISTICS")}
        data-fa-section="INTERACTION_STATISTICS"
      />

      <Grid container spacing={0}>
        <Grid item xs={7}>
          <CustomerInteractionDailyHeatmap
            ref={chartRef}
            searchForm={searchForm}
            showNumbers={false}
          />
        </Grid>
        <Grid item xs={5}>
          <InteractionCountsByOperationChart
            ref={pieChartRef}
            searchForm={searchForm}
            faType="small"
          />
        </Grid>
        <Grid item xs={12}>
          <TopInteractionsList
            ref={topInteractionsRef}
            searchForm={searchForm}
            showThings={false}
          />
        </Grid>
        <Grid item xs={12}>
          <FirstAndLastInteractions ref={firstAndLastInteractionsRef} />
        </Grid>
      </Grid>
    </Card>
  );
}

ThingInteractions.propTypes = {
  thingId: PropTypes.any,
};
