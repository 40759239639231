import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import i18n from "../../../i18n";
import ThingsSteps from "../ThingsSteps";
import TutorialElement from "../TutorialElement";

export default function SuggestionDetailTutorial() {
  const { code } = useParams();

  const stepsRef = useRef();

  const { t } = useTranslation("tutorialQuickStart", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="info">
            <div>
              <p>
                Müşterilerinizin bildirdiği <b>Önerilere</b> ait detay
                bilgilerin gösterildiği sayfadır.
              </p>
              <p>
                Bu sayfada;
                <ul>
                  <li>
                    <b>Öneri Bilgileri</b>’ni, öneriye ait{" "}
                    <b>Görsel(ler)i, Ek Alanları</b> ve{" "}
                    <b>Mesajlaşma Geçmişi</b>’ni izleyebilirsiniz
                  </li>
                  <li>
                    Öneri bildirimi yapan müşterinize <b>mesaj</b>{" "}
                    gönderebilirsiniz.
                  </li>
                </ul>
              </p>
            </div>
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='ADD_BENEFICIARY_NOTES']",
        intro: (
          <TutorialElement icon="click">
            Öneri bildiren müşterinize <b>mesaj göndermek</b> için tıklayın.
          </TutorialElement>
        ),
      },
      {
        //2
        element: "[data-fa-section='CHANNEL_INPUT']",
        intro: (
          <TutorialElement icon="info">
            <b>ÖNEMLİ NOT :</b>
            <br />
            Öneri bildiriminde bulunan müşteriniz <b>İletişim Bilgisi</b>{" "}
            olarak;
            <p>
              a{")"} <b>E-Posta</b> adresini vermiş ise İletişim Kanalı{" "}
              <b>E-Posta</b> olarak gelir ve <b>Konu</b> girmeniz istenir.
            </p>
            <p>
              b{")"} <b>Cep Telefonu</b> numarası vermiş ise İletişim Kanalı{" "}
              <b>SMS</b> olarak gelir ve sadece <b>mesaj</b> girmeniz istenir.
            </p>
          </TutorialElement>
        ),
        position: "right",
        onBeforeChange: (index) => {
          $("[data-fa-button='ADD_BENEFICIARY_NOTES']")[0].click();
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "[data-fa-section='EMAIL_INPUT']",
        intro: (
          <TutorialElement icon="click">
            İletişim kanalı <b>E-Posta</b> ise <b>Konu</b> ve <b>Mesajınızı</b>{" "}
            girin. <b>Mesaj Gönder</b>’e tıklayın.
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "[data-fa-section='MESSAGE_CONTENT_INPUT']",
        intro: (
          <TutorialElement icon="click">
            İletişim kanalı <b>SMS </b> ise <b>Mesajınızı</b> yazın ve{" "}
            <b>Mesaj Gönder</b>’e tıklayın.
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={"suggestion_detail"}
        steps={steps}
        ref={stepsRef}
        onStepChange={setStepIndex}
      />
    </div>
  );
}
