import { CardHeader } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from "@material-ui/core/Grid";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useRef, useState } from "react";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { ISSUE } from "../../services/faApi";
import IssuePhotosCarousel from "./IssuePhotosCarousel";
import { useStyles } from "./style";

export default function IssuePhotos(props) {
  const { images, uploadImages, deleteImage, issueId } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const { t } = useTranslation("issueDetail", { i18n });
  const fileInputRef = useRef();
  const [selectedHdImageIndex, setSelectedHdImageIndex] = useState(0);
  const [issueHdImages, setIssueHdImages] = useState([]);

  const handleChange = (event) => {
    event.preventDefault();
    if (event?.target?.files?.length > 0) {
      uploadImages(event.target.files);
    }

  }

  const [isCarouselOpen, setCarouselOpen] = useState(false);

  const openCarousel = (index) => {

    getIssueHdImages();
    setSelectedHdImageIndex(index);
  }

  const closeCarousel = () => {

    setCarouselOpen(false);
  }

  const getIssueHdImages = () => {
    
    const req = requests.getSimplePostRequest(ISSUE.downloadFiles + "/" + issueId);
    FaAxios(req)
      .then((response) => {
        setIssueHdImages(response.data);
        setCarouselOpen(true);
      })
      .catch((error) => {
        console.log("refreshIssueImages error", issueId);
      });
  };


  return (
    <>
         <Dialog open={isCarouselOpen} aria-labelledby="form-dialog-title" onClose={closeCarousel}>
            <DialogContent>
              <IssuePhotosCarousel images={issueHdImages} selectedItem={selectedHdImageIndex} />
            </DialogContent>
          </Dialog>



    <Card style={{ height: 250, overflowY: "auto" }}>
      <CardHeader
        title={t("PHOTOS")}
      />
      <CardContent>
        <Grid item container spacing={1}>
          <div style={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-between" }}>
            <input onChange={handleChange} multiple ref={fileInputRef} type='file' hidden />
          </div>
          <ImageList
            rowHeight={140}
            className={classes.imageList}
            cols={lg ? 4 : 2}
          >
            {images.map((item, index) => (
              <ImageListItem key={index} cols={images.length == 1 ? 2 : 1}>
                <img
                  src={`data:image/png;base64, ${item.content}`}
                  alt={item.object}
                  onClick={() => {openCarousel(index);}}
                />
                {/* <IconButton
                  style={{ position: "absolute", top: -7, right: -7 }}
                  onClick={() => { deleteImage(item.object) }}
                >
                  <DeleteIcon color="secondary" />
                </IconButton> */}
                {/* <ImageListItemBar
                  title={item.object}
                  actionIcon={
                    <IconButton
                      aria-label={`info about ${item.object}`}
                      className={classes.icon}
                    >
                      <InfoIcon />
                    </IconButton>
                  }
                /> */}
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>
      </CardContent>
    </Card>
    </>
  );
}
