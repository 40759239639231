import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { USER_TUTORIAL } from "./faApi";

export const UserTutorialService = {
  start: (tutorialCode, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      USER_TUTORIAL.start + "/" + tutorialCode
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },

  complete: (tutorialCode, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      USER_TUTORIAL.complete + "/" + tutorialCode
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },

  findTutorialList: (callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(USER_TUTORIAL.findTutorialList);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
