import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { AccPreferencesService } from "services/accPreferences/accPreferencesService";
import i18n from "../../i18n";
import { useStyles } from "../accountDetails/AccountDetailsCardStyle";

export default function AccountPreferencesLabel(props) {
  const { isOpen, onClose } = props;
  const { t } = useTranslation(["accountDetails", "components"], {
    i18n,
  });
  const classes = useStyles();
  const [accountPreferences, setAccountPreferences] = useState({});
  const accountId = localStorage.accountId;

  const handleChange = (prop) => (event) => {
    setAccountPreferences({
      ...accountPreferences,
      [prop]: event.target.value,
    });
  };

  useEffect(() => {
    if (isOpen) {
      AccPreferencesService.findByAccount(
        accountId,
        (data) => {
          setAccountPreferences(data);
        },
        (error) => {}
      );
    }
  }, [isOpen]);

  const saveForm = () => {
    AccPreferencesService.save(
      accountPreferences,
      (data) => {
        onClose();
      },
      (error) => {}
    );
  };

  const renderDialog = () => {
    return (
      <FaDialog
        title={"Etiket Seçenekleri"}
        faOpen={isOpen}
        faOnSubmit={() => {
          saveForm();
        }}
        showSaveButton
        faHandleClose={(data) => {
          onClose();
        }}
        faMaxWidth="md"
      >
        <Grid container spacing={1}>
          <Grid item lg={6} xs={12}>
            <FaInput
              id="labelAccountTitle"
              label={t("labelTitle")}
              value={accountPreferences.labelAccountTitle}
              onChange={handleChange("labelAccountTitle")}
              name="labelAccountTitle"
              autoComplete="labelAccountTitle"
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <FaInput
              id="labelMessage"
              label={t("labelMessage")}
              value={accountPreferences.labelMessage}
              onChange={handleChange("labelMessage")}
              name="labelMessage"
              autoComplete="labelMessage"
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <FaInput
              id="labelFormat"
              autoComplete="off"
              label={t("labelFormat")}
              value={accountPreferences.phone}
              onChange={handleChange("labelFormat")}
              name="labelFormat"
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <FaInput
              id="labelDPI"
              autoComplete="off"
              label={t("labelDPI")}
              value={accountPreferences.labelDPI}
              onChange={handleChange("labelDPI")}
              name="labelDPI"
            />
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  return renderDialog();
}
