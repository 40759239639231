import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  Link,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { Alert } from "@material-ui/lab";
import CountryAutoComplete from "components/autocomplete/CountryAutoComplete";
import DataCenterSelect from "components/select/DataCenterSelect";
import LanguageSelect from "components/select/LanguageSelect";
import React, { useEffect, useState } from "react";
import { FaButton, FaInput } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import {
  getUserInfo,
  setAccountId,
  setAccountName,
  setAccountTimezone,
  setRole,
} from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LegalTextService } from "services/LegalTextService";
import { SubscriptionService } from "services/SubscriptionService";
import { APP } from "services/utils/Constants";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import { ACCOUNT, ACC_USER } from "../../services/faApi";
import { useStyles } from "./style";

export default function AccountIncentiveScreen() {
  const { t } = useTranslation("accountIncentiveScreen", { i18n });
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const userInfo = useSelector(getUserInfo);
  const dispatch = useDispatch();
  const labelClasses = labelStyles();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  const [uyelikSozlesme, setUyelikSozlesme] = useState(false);
  const [legalText, setLegalText] = useState(null);
  const [openLegalText, setOpenLegalText] = useState(null);
  const [defaultSalesPackage, setDefaultSalesPackage] = useState(null);

  const [newAccount, setNewAccount] = useState({
    fullname: "",
    country: null,
    dataCenter: "TR",
    language: "TR",
    things2Care: localStorage.app === APP.T2C ? true : false,
  });

  const handleChange = (prop) => (event) => {
    setNewAccount({ ...newAccount, [prop]: event.target.value });
  };

  const handleChangeV = (prop, value) => {
    setNewAccount({ ...newAccount, [prop]: value });
  };

  useEffect(() => {
    console.log("useEffect", userInfo);
    setNewAccount({
      ...newAccount,
      fullname: userInfo.fullname,
      country: userInfo.country,
    });

    SubscriptionService.findDefaultSalesPackage(
      (data) => {
        setDefaultSalesPackage(data);
      },
      (error) => {}
    );
  }, [userInfo]);

  const createAccount = (e) => {
    e.preventDefault();
    setLoading(true);
    let req = requests.getSimplePostRequest(ACCOUNT.createAccount, {
      ...newAccount,
    });
    FaAxios(req)
      .then((response) => {
        dispatch(setAccountId(response.data.id));
        dispatch(setAccountName(response.data.fullname));
        dispatch(setAccountTimezone(response.data.timeZone));
        showAlert(t("ACCOUNT_CREATED"), "success");
        req = requests.getSimpleGetRequest(ACC_USER.findAccUserRole, {
          accountId: response.data.id,
          userId: userInfo.id,
        });
        FaAxios(req)
          .then((response) => {
            dispatch(setRole(response.data));
            var toObj = {
              pathname: "/homepage",
            };
            history.push(toObj);
          })
          .catch((e) => {
            console.error("find account error_>", e);
            setLoading(false);
          });
      })
      .catch((e) => {
        showAlert(e.message, "error");
        setLoading(false);
      });
  };

  const showLegalText = (type) => {
    LegalTextService.getLegalTextByType(
      type,
      showLegalTextOnSuccess,
      (error) => {
        setLegalText(null);
      }
    );
  };

  const showLegalTextOnSuccess = (data) => {
    setLegalText(data);
    setOpenLegalText(true);
  };

  const renderLegalTextDialog = () => {
    return (
      <Dialog
        open={openLegalText}
        onClose={() => {
          setOpenLegalText(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{legalText?.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div dangerouslySetInnerHTML={{ __html: legalText?.content }} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div className={classes.root}>
      {renderLegalTextDialog()}
      <Grid container spacing={1}>
        <Grid item lg={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1} justify="center">
                <Grid item container lg={12} xs={12} justify="center">
                  <Grid item lg={12} xs={12}>
                    <Alert severity="info">
                      Yeni oluşturmak istediğiniz hesap için lütfen bilgileri
                      tamamlayın.
                    </Alert>
                  </Grid>
                  <Grid item lg={12} xs={12} style={{ paddingTop: 10 }}>
                    <FaInput
                      label={t("HESAP_ADI")}
                      onChange={handleChange("fullname")}
                      value={newAccount.fullname}
                    />
                    <FormHelperText>
                      Kullanmak istediğiniz hesabınızın adını girin, dilerseniz
                      sonra değiştirebilirsiniz.
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <CountryAutoComplete
                      required
                      value={newAccount.country}
                      onChange={(data) => {
                        handleChangeV("country", data);
                      }}
                    />
                    <FormHelperText>Bulunduğunuz ülkeyi seçin.</FormHelperText>
                  </Grid>
                  <Grid item lg={12} xs={12} style={{ paddingTop: 10 }}>
                    <DataCenterSelect
                      required
                      value={newAccount.dataCenter}
                      onChange={(data) => {
                        handleChangeV("dataCenter", data);
                      }}
                    />
                    <FormHelperText>
                      Hizmet aldığınız yazılımın ve verilerinizin
                      barındırılacağı ülkedir. Bu seçimdeki ülkeye göre tabi
                      olacağınız mevzuat farklılık gösterebilir. Her hizmet
                      merkezinin ayrı abonelik tarifesi ve kullanım koşulları
                      olabilir. Detaylar üyelik sözleşmesinde yer almaktadır.
                      Burada yapılan seçim daha sonra değiştirilemeyecektir.
                      Tabi olacağınız mevzuat ve fiyat veri merkezine göre
                      farklılık gösterebilir.
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} xs={12} style={{ paddingTop: 10 }}></Grid>
                  <Grid item lg={12} xs={12}>
                    <LanguageSelect
                      required
                      value={newAccount.language}
                      onChange={(data) => {
                        handleChangeV("language", data);
                      }}
                    />
                  </Grid>
                  {defaultSalesPackage && (
                    <Grid item lg={12} xs={12}>
                      {defaultSalesPackage.monthlySubscribeDuration === -1 &&
                        t(
                          "Hesabınız oluşturulduğunda zaman sınırı olmadan " +
                            defaultSalesPackage.name +
                            " paketinizi kullanabileceksiniz."
                        )}
                      {defaultSalesPackage.monthlySubscribeDuration !== -1 &&
                        t(
                          "Hesabınız oluşturulduğunda " +
                            defaultSalesPackage.monthlySubscribeDuration +
                            " "
                        ) +
                          t(
                            "" + defaultSalesPackage.monthlySubscribeTimePeriod
                          ) +
                          " boyunca " +
                          defaultSalesPackage.name +
                          " paketinizi kullanabileceksiniz."}
                    </Grid>
                  )}
                  <Grid item lg={12} xs={12}>
                    <Checkbox
                      checked={uyelikSozlesme}
                      onChange={(event) => {
                        setUyelikSozlesme(event.target.checked);
                      }}
                      name="uyelikSozlesme"
                      color="primary"
                    />
                    <Link
                      className={labelClasses.SimpleLink}
                      onClick={() => {
                        showLegalText("LISANS_SOZLESMESI");
                      }}
                    >
                      {t("Lisans sözleşmesini okudum ve kabul ediyorum.")}
                    </Link>
                  </Grid>

                  <Grid item lg={11} xs={11}></Grid>
                  <Grid item lg={4} xs={12}>
                    <FaButton
                      async
                      label={t("HESAP_OLUSTUR")}
                      faClick={createAccount}
                      loading={loading}
                      disabled={!uyelikSozlesme}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
