import { CardHeader, IconButton, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import ThingsEditIcon from "components/icons/ThingsEditIcon";
import { THINGS_ENVIRONMENT } from "environments/Environments";
import React, { useEffect, useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { UserService } from "services/UserService";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../../i18n";

export default function HomepageUserLocation(props) {
  const { size } = props;
  const { t } = useTranslation(["homepage", "components"], { i18n });

  const accountId = localStorage.accountId;

  const [userLocation, setUserLocation] = useState({});
  const [updateData, setUpdateData] = useState({});
  const [open, setOpen] = useState(false);
  const labelClasses = labelStyles();

  const getLocation = () => {
    UserService.getLocation(
      (data) => {
        setUserLocation(data);

        if (
          THINGS_ENVIRONMENT.forceUserLocation &&
          (!!!data.phone || !!!data.floor || !!!data.room)
        ) {
          setOpen(true);
        }
      },
      (error) => {}
    );
  };

  useEffect(() => {
    getLocation();
  }, []);

  const saveLocation = () => {
    UserService.updateLocation(
      updateData,
      (data) => {
        getLocation();
        setOpen(false);
      },
      (error) => {}
    );
  };

  const handleChange = (prop) => (event) => {
    setUpdateData({ ...updateData, [prop]: event.target.value });
  };

  const newFormRender = () => {
    return (
      <>
        <FaDialog
          title={"Konum Bilgilerimi Güncelle"}
          faOpen={open}
          faOnSubmit={saveLocation}
          showSaveButton
          faHandleClose={(data) => {
            if (
              THINGS_ENVIRONMENT.forceUserLocation &&
              (!!!data.phone || !!!data.floor || !!!data.room)
            ) {
              setOpen(true);
            } else {
              setOpen(false);
            }
          }}
          faMaxWidth="lg"
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              Lütfen konum bilgilerinizi girin.
            </Grid>
            <Grid item xs={12}>
              <FaInput
                required
                label={t("Dahili Telefon No")}
                value={updateData.phone}
                inputProps={{ maxLengt: 100 }}
                onChange={handleChange("phone")}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                required
                label={t("Kat Numarası")}
                value={updateData.floor}
                inputProps={{ maxLengt: 100 }}
                onChange={handleChange("floor")}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                required
                label={t("Oda No")}
                value={updateData.room}
                inputProps={{ maxLengt: 100 }}
                onChange={handleChange("room")}
              />
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  return (
    <Grid item lg={size} xs={12}>
      {open && newFormRender()}
      <Card>
        <CardHeader
          title={t("Benim Konumum")}
          action={
            <>
              <IconButton
                aria-label="settings"
                onClick={() => {
                  setUpdateData({ ...userLocation });
                  setOpen(true);
                }}
              >
                <ThingsEditIcon />
              </IconButton>
            </>
          }
        />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item lg={12} xs={12}>
              <Grid item container spacing={1}>
                <Grid item xs={12} lg={6} className={labelClasses.LabelLastRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("Dahili Tel. No")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {userLocation.phone}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6} className={labelClasses.LabelLastRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("Kat ve Oda No")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {userLocation.floor}-{userLocation.room}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
