import { SvgIcon } from "@material-ui/core";
import { default as React } from "react";

export default function InfoIcon(props) {
  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <path
        d="M80.969,56.194H19.031V28.322H50v-6.194H19.031c-3.414,0-6.194,2.778-6.194,6.194v40.26c0,3.414,2.779,6.194,6.194,6.194
		h21.678v6.194h-3.097v6.194h24.775v-6.194h-3.097v-6.194h21.678c3.414,0,6.194-2.779,6.194-6.194V50h-6.194V56.194z M53.097,80.969
		h-6.194v-6.194h6.194V80.969z M19.031,68.581v-6.194h15.484v0.002h24.775v-0.002h21.678v6.194H19.031z"
      />
      <path
        d="M77.107,41.227l4.77,4.768l4.379-4.377l-4.769-4.77c1.624-2.448,2.58-5.374,2.58-8.525c0-8.537-6.947-15.484-15.484-15.484
		s-15.484,6.947-15.484,15.484s6.947,15.484,15.484,15.484C71.733,43.806,74.66,42.849,77.107,41.227z M59.291,28.322
		c0-5.123,4.167-9.291,9.291-9.291s9.291,4.167,9.291,9.291s-4.167,9.291-9.291,9.291S59.291,33.445,59.291,28.322z"
      />
      <rect x="25.225" y="34.516" width="24.775" height="6.194" />
      <rect x="25.225" y="43.806" width="34.066" height="6.194" />
    </SvgIcon>
  );
}
