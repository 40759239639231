import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { Check, Remove } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import {
  FaButton,
  FaDialog,
  FaFileUpload,
  FaInput,
} from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AccPreferencesService } from "services/accPreferences/accPreferencesService";
import { CustomerService } from "services/CustomerService";
import { getDateTimeWFormat } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import BeneficiaryNote from "../../components/beneficiaryNote";
import LoadingBar from "../../components/loadingBar";
import Note from "../../components/noteCard";
import i18n from "../../i18n";
import CustomerDevice from "./CustomerDevice";
import CustomerDocuments from "./CustomerDocuments";
import CustomerEdit from "./CustomerEdit";
import CustomerHistory from "./CustomerHistory";
import CustomerInteractions from "./CustomerInteractions";
import CustomerLists from "./CustomerLists";
import CustomerThings from "./CustomerThings";
import { useStyles } from "./style";

export default function CustomerDetail() {
  const classes = useStyles();
  const labelClasses = labelStyles();
  const { t } = useTranslation("customer", { i18n });
  const { id } = useParams();
  const accountId = localStorage.accountId;
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const dialogRef = useRef();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState({});
  const [customerPermissionUpdate, setCustomerPermissionUpdate] =
    useState(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  //const [editButtonClicked, setEditButtonClicked] = useState(true);

  const [theDocument, setTheDocument] = useState({
    name: "",
    description: "",
  });
  const [files, setFiles] = useState([]);
  const [allowType, setAllowType] = useState(null);
  const [customerRefresh, setCustomerRefresh] = useState(0);

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    setLoading(true);
    CustomerService.findById(id, findByIdOnSuccess, findByIdOnError);
  };

  useEffect(() => {
    AccPreferencesService.findByAccount(accountId, (data) => {
      setCustomerPermissionUpdate(data?.customerPermissionUpdate);
    });
  }, []);

  const findByIdOnSuccess = (data) => {
    setCustomer({
      ...data,
    });
    setLoading(false);
  };

  const findByIdOnError = (error) => {
    showAlert(error, "error");
    setLoading(false);
  };

  const onSave = () => {
    //refresh(); //FIXME
    window.location.reload();
  };
  const editDialogRender = () => {
    return (
      <CustomerEdit
        customer={customer}
        ref={dialogRef}
        onSave={onSave}
      ></CustomerEdit>
    );
  };
  const openEditDialog = () => {
    dialogRef.current.openDialog();
  };

  const activation = () => {
    if (customer.status == "ACTIVE") {
      CustomerService.deactivate(
        customer.id,
        activationOnSuccess,
        activationOnError
      );
    } else {
      CustomerService.activate(
        customer.id,
        activationOnSuccess,
        activationOnError
      );
    }
  };

  const anonymize = (type) => {
    CustomerService.anonymize(
      customer.id,
      activationOnSuccess,
      activationOnError
    );
  };

  const removePermission = (type) => {
    CustomerService.remove(
      customer.id,
      type,
      activationOnSuccess,
      activationOnError
    );
  };

  const allowPermission = (type) => {
    CustomerService.allow(
      customer.id,
      type,
      activationOnSuccess,
      activationOnError
    );
  };

  const activationOnSuccess = (data) => {
    setCustomer({
      ...data,
    });
    setLoading(false);
    setCustomerRefresh((prev) => prev + 1);
  };

  const activationOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };

  const saveDocument = () => {
    CustomerService.allowWithDocument(
      customer.id,
      allowType,
      theDocument.name,
      theDocument.description,
      files[0],
      uploadDocumentOnSuccess,
      uploadDocumentOnError
    );
  };

  const uploadDocumentOnSuccess = (data) => {
    setCustomer({
      ...data,
    });
    setIsEditDialogOpen(false);
    setFiles([]);
    setLoading(false);
    setCustomerRefresh((prev) => prev + 1);
  };

  const uploadDocumentOnError = () => {};

  const handleChangeV = (prop, val) => {
    setTheDocument({ ...theDocument, [prop]: val });
  };

  const renderDocumentEditDialog = () => {
    return (
      <FaDialog
        title={t("Müşteri Yazılı Beyan Ekle")}
        loading={loading}
        faOpen={isEditDialogOpen}
        faOnSubmit={() => {
          setLoading(true);
          saveDocument();
        }}
        faHandleClose={() => {
          setIsEditDialogOpen(false);
        }}
        showSaveButton
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <FaInput
              label={t("NAME")}
              onChange={(event) => handleChangeV("name", event.target.value)}
              value={theDocument.name || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              faType="description"
              label={t("DESCRIPTION")}
              onChange={(event) =>
                handleChangeV("description", event.target.value)
              }
              value={theDocument.description || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <FaFileUpload
              maxFileSize={20000000}
              onChange={(data) => {
                if (data != null && data.length > 0) {
                  setFiles(data);
                } else setFiles(null);
              }}
              initialFiles={files}
              filesLimit={1}
            />
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {editDialogRender()}
        {renderDocumentEditDialog()}
        <Grid container spacing={1}>
          <Grid item lg={6} xs={12}>
            <Grid item container spacing={1}>
              <Grid item lg={12}>
                <Card>
                  <CardHeader
                    title={t("CUSTOMER_INFO")}
                    data-fa-section="CUSTOMER_INFO"
                    action={
                      <IconButton
                        aria-label="settings"
                        onClick={() => openEditDialog()}
                      >
                        <EditIcon />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <Grid item container spacing={0}>
                      <Grid item xs={12}>
                        <Grid item container spacing={1}>
                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("NAME")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {customer.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("SURNAME")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {customer.surname}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("MEMBERSHIP_DATE")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {getDateTimeWFormat(customer.membershipDate)}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("PHONE")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {customer.phone}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("E-MAIL")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {customer.email}
                            </Typography>
                          </Grid>

                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("DESCRIPTION")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {customer.description}
                            </Typography>
                          </Grid>

                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("HASHTAG")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {customer.hashtag}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("RECORD_TYPE")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {t(customer.recordType)}
                            </Typography>
                          </Grid>

                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("CONTACT_PERMISSION")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {customer.contactPermission && <Check />}
                              {!!!customer.contactPermission && <Remove />}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("GPDR_PERMISSION")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {customer.gdprPermission && <Check />}
                              {!!!customer.gdprPermission && <Remove />}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            className={labelClasses.LabelLastRow}
                          >
                            <Typography className={labelClasses.LabelHeader}>
                              {t("STATUS")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {t(customer.status)}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <FaButton
                              style={{
                                width: "20%",
                                marginRight: 5,
                                marginBottom: 5,
                              }}
                              size="small"
                              color={
                                customer.status === "ACTIVE"
                                  ? "secondary"
                                  : "primary"
                              }
                              faClick={() => activation()}
                              data-fa-button="ACTIVATE"
                            >
                              {customer.status === "ACTIVE"
                                ? t("DEACTIVATE")
                                : t("ACTIVATE")}
                            </FaButton>

                            {customer.gdprPermission && (
                              <FaButton
                                style={{
                                  width: "25%",
                                  marginRight: 5,
                                  marginBottom: 5,
                                }}
                                size="small"
                                color="secondary"
                                faClick={() => removePermission("gdpr")}
                                data-fa-button="REMOVE_GDPR_PERMISSION"
                              >
                                {t("HISTORY_TYPE_REMOVE_GDPR_PERMISSION")}
                              </FaButton>
                            )}

                            {customer.contactPermission && (
                              <FaButton
                                style={{
                                  width: "25%",
                                  marginRight: 5,
                                  marginBottom: 5,
                                }}
                                size="small"
                                color="secondary"
                                faClick={() => removePermission("contact")}
                                data-fa-button="REMOVE_CONTACT_PERMISSION"
                              >
                                {t("HISTORY_TYPE_REMOVE_CONTACT_PERMISSION")}
                              </FaButton>
                            )}

                            {!!!customer.contactPermission &&
                              (!customerPermissionUpdate ||
                                customerPermissionUpdate === "ALLOWED") && (
                                <FaButton
                                  style={{
                                    width: "25%",
                                    marginRight: 5,
                                    marginBottom: 5,
                                  }}
                                  size="small"
                                  color="primary"
                                  faClick={() => allowPermission("contact")}
                                  data-fa-button="ALLOW_CONTACT_PERMISSION"
                                >
                                  {t("HISTORY_TYPE_ALLOW_CONTACT_PERMISSION")}
                                </FaButton>
                              )}

                            {!!!customer.contactPermission &&
                              customerPermissionUpdate ===
                                "ALLOWED_WITH_DOCUMENT" && (
                                <FaButton
                                  style={{
                                    width: "25%",
                                    marginRight: 5,
                                    marginBottom: 5,
                                  }}
                                  size="small"
                                  color="primary"
                                  faClick={() => {
                                    setAllowType("contact");
                                    setTheDocument({
                                      name: "İletişim izin beyanı",
                                      description: "",
                                      fileName: "",
                                    });
                                    setIsEditDialogOpen(true);
                                  }}
                                  data-fa-button="ALLOW_CONTACT_PERMISSION"
                                >
                                  {t("HISTORY_TYPE_ALLOW_CONTACT_PERMISSION")}
                                </FaButton>
                              )}

                            {!!!customer.gdprPermission &&
                              (!customerPermissionUpdate ||
                                customerPermissionUpdate === "ALLOWED") && (
                                <FaButton
                                  style={{
                                    width: "25%",
                                    marginRight: 5,
                                    marginBottom: 5,
                                  }}
                                  size="small"
                                  color="primary"
                                  faClick={() => allowPermission("gdpr")}
                                  data-fa-button="ALLOW_GDPR_PERMISSION"
                                >
                                  {t("HISTORY_TYPE_ALLOW_GDPR_PERMISSION")}
                                </FaButton>
                              )}

                            {!!!customer.gdprPermission &&
                              customerPermissionUpdate ===
                                "ALLOWED_WITH_DOCUMENT" && (
                                <FaButton
                                  style={{
                                    width: "25%",
                                    marginRight: 5,
                                    marginBottom: 5,
                                  }}
                                  size="small"
                                  color="primary"
                                  faClick={() => {
                                    setAllowType("gdpr");
                                    setTheDocument({
                                      name: "KVKK izin beyanı",
                                      description: "",
                                      fileName: "",
                                    });
                                    setIsEditDialogOpen(true);
                                  }}
                                  data-fa-button="ALLOW_GDPR_PERMISSION"
                                >
                                  {t("HISTORY_TYPE_ALLOW_GDPR_PERMISSION")}
                                </FaButton>
                              )}

                            {!!!customer.anonym && (
                              <FaButton
                                style={{
                                  width: "20%",
                                  marginRight: 5,
                                  marginBottom: 5,
                                }}
                                size="small"
                                color="secondary"
                                faClick={() => anonymize()}
                                data-fa-button="ANONYMIZE"
                              >
                                {t("ANONYMIZE")}
                              </FaButton>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                {customer && customer.id > 0 && (
                  <CustomerLists customer={customer} />
                )}
              </Grid>

              <Grid item xs={12}>
                {customer && customer.id > 0 && (
                  <CustomerThings customer={customer} />
                )}
              </Grid>

              <Grid item xs={12}>
                {customer && customer.id > 0 && (
                  <CustomerDevice customer={customer} />
                )}
              </Grid>

              <Grid item xs={12}>
                {customer && customer.id > 0 && (
                  <CustomerHistory
                    customer={customer}
                    customerRefresh={customerRefresh}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Grid item container spacing={1}>
              <Grid item xs={12}>
                {customer && customer.id > 0 && (
                  <CustomerInteractions customer={customer} />
                )}
              </Grid>
              <Grid item xs={12}>
                {customer && customer.id > 0 && (
                  <CustomerDocuments
                    customer={customer}
                    customerRefresh={customerRefresh}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                {customer && customer.id > 0 && <Note customer={customer} />}
              </Grid>

              <Grid item xs={12}>
                {customer && customer.id > 0 && (
                  <BeneficiaryNote customer={customer} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
