import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { TASK } from "./faApi";

export const TaskService = {
  save: (task, callback, errorCallback) => {
    let reqBody = {
      ...task,
    };

    let req = requests.getSimplePostRequest(TASK.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  reassign: (task, isQueue, queueId, callback, errorCallback) => {
    let reqBody = {
      ...task,
    };

    let req = requests.getSimplePostRequest(
      TASK.reassign + "?isQueue=" + isQueue + "&queueId=" + (queueId ?? ""),
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  cancel: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(TASK.cancel + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  sendBack: (task, callback, errorCallback) => {
    let reqBody = {
      ...task,
    };

    let req = requests.getSimplePostRequest(TASK.sendBack, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  complete: (task, callback, errorCallback) => {
    let reqBody = {
      ...task,
    };

    let req = requests.getSimplePostRequest(TASK.complete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(TASK.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  pause: (taskInputForm, callback, errorCallback) => {
    let reqBody = {
      ...taskInputForm,
    };

    let req = requests.getSimplePostRequest(TASK.pause, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  pauseFinish: (taskInputForm, callback, errorCallback) => {
    let reqBody = {
      ...taskInputForm,
    };

    let req = requests.getSimplePostRequest(TASK.pauseFinish, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  saveSlaLevel: (taskId, slaContractLevelId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      TASK.saveSlaLevel +
        "?taskId=" +
        taskId +
        "&slaContractLevelId=" +
        (slaContractLevelId ?? "")
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
