import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function CustomerPermissionUpdateSelect(props) {
  const { value, onChange, required, label } = props;
  const classes = useStyles();
  const { t } = useTranslation("components", { i18n });

  return (
    <FormControl variant="outlined" className={classes.w100} size="small">
      <InputLabel>{t("CUSTOMER_PERMISSION_UPDATE")}</InputLabel>
      <Select
        // classes={{
        //   outlined: classes.padding10,
        // }}
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        required={required}
        label={label ?? t("CUSTOMER_PERMISSION_UPDATE")}
      >
        <MenuItem value={null}>...</MenuItem>
        <MenuItem value={"NOT_ALLOWED"}>
          {t("CUSTOMER_PERMISSION_UPDATE_NOT_ALLOWED")}
        </MenuItem>
        <MenuItem value={"ALLOWED"}>
          {t("CUSTOMER_PERMISSION_UPDATE_ALLOWED")}
        </MenuItem>
        <MenuItem value={"ALLOWED_WITH_DOCUMENT"}>
          {t("CUSTOMER_PERMISSION_UPDATE_ALLOWED_WITH_DOCUMENT")}
        </MenuItem>
      </Select>
    </FormControl>
  );
}
