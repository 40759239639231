import React from "react";
import { FaAutoComplete } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { MESSAGE_TEMPLATE } from "../../services/faApi";

export default function MessageTemplateAutoComplete(props) {
  const { value, accountId, onChange, required, multiple, label, actionType } =
    props;

  const { t } = useTranslation("components", { i18n });

  return (
    <FaAutoComplete
      faType="async"
      value={value}
      getOptionSelected={(option, value) => {
        return option.id === value.id;
      }}
      sort="description"
      faFilterProp="description"
      getOptionLabel={(option) =>
        (option.actionType ? t("ACTION_TYPE." + option.actionType) : "") +
        " - " +
        (option.description ? option.description : "")
      }
      requestUrl={
        MESSAGE_TEMPLATE.findByDescription +
        "?accountId=" +
        accountId +
        (actionType ? "&actionType=" + actionType : "")
      }
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      label={label ?? t("MESSAGE_TEMPLATE")}
      required={required}
      multiple={multiple}
    />
  );
}
