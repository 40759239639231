import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  map: {
    flexGrow: 1,
    height: 500,
  },
  closeButton: {
    color: theme.palette.grey[500],
    right: 0,
    top: 0,
    position: "absolute",
  },
  drawer: {
    overflow: "hidden",
  },
  drawerContent: {
    padding: 10,
    height: 400,
  },
  drawerPaper: {
    height: 400,
    top: 80,
    width: "21.7%",
    minWidth: 200,
  },
  avatar: {
    width: 50,
    height: 50,
    marginRight: 15,
  },
  success: {
    backgroundColor: green[500],
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  image: {
    //height: 0,
    paddingBottom: "64%",
    width: "100%",
    position: "relative",
    overflow: "hidden",
    clear: "both",
    float: "none",
  },
  defaultImage: {
    position: "absolute",
    right: 0,
    bottom: 0,
    zIndex: 1,
    marginRight: "20px",
    marginBottom: "20px",
  },
  issueCard: {
    marginBottom: 5,
    marginLeft: "1px",
    marginRight: "1px",
  },
  solutionCard: {
    marginBottom: 5,
    marginLeft: "1px",
    marginRight: "1px",
  },
  fallbackUrlBox: {
    borderColor: "rgb(204, 204, 204)",
    borderTopLeftRadius: "7px",
    borderTopRightRadius: "7px",
    borderRadius: "7px",
    border: "1px solid #ccc",
    width: "400px",
    height: "250px",
    wordBreak: "break-all",
  },
  imageList: {
    width: "100%",
  },
  thingImageDeleteButtom: {
    position: "absolute",
    left: 0,
    bottom: 0,
    zIndex: 1,
    display: "block",
    color: "rgba(255, 255, 255, 0.54)",
  },
  thingImageAccessButtom: {
    position: "absolute",
    right: 0,
    bottom: 0,
    zIndex: 1,
    display: "block",
    color: "rgba(255, 255, 255, 0.54)",
  },
  thingImage: {
    borderRadius: "15%",
    width: 100,
    height: 100,
  },
  thingImageContainer: {
    borderRadius: "15%",
    marginRight: "10px",
    position: "relative",
  },
  eventReminderIcon: {
    paddingBottom: "20px !important",
  },
  badgeColor: {
    backgroundColor: "#1877F2",
    color: "white",
  },
}));
