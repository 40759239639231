import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { STOCK_TAKING } from "./faApi";

export const StockTakingService = {
  save: (stock, callback, errorCallback) => {
    let reqBody = {
      ...stock,
    };

    let req = requests.getSimplePostRequest(STOCK_TAKING.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(STOCK_TAKING.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  scanDetail: (stockTakingId, userId, tid, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      STOCK_TAKING.scanDetail +
        "/" +
        stockTakingId +
        "?userId=" +
        userId +
        "&tid=" +
        encodeURI(tid)
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  complete: (stockTakingId, endDescription, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      STOCK_TAKING.complete + "/" + stockTakingId,
      endDescription
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  start: (stockTakingId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      STOCK_TAKING.start + "/" + stockTakingId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  stat: (stockTakingId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      STOCK_TAKING.stat + "/" + stockTakingId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
