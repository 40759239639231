import { Button, Grid } from "@material-ui/core";
import UserAutoComplete from "components/autocomplete/UserAutoComplete";
import QueueDispatchMethodSelect from "components/select/QueueDispatchMethodSelect";
import SchedulePlanSelect from "components/select/SchedulePlanSelect";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { QueueService } from "services/QueueService";
import i18n from "../../i18n";

const QueueEdit = forwardRef((props, ref) => {
  const { onSave, queue } = props;
  const { t } = useTranslation("queue", { i18n });
  const accountId = localStorage.accountId;
  let history = useHistory();
  const faDialog = useDialog();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const newQueue = () => {
    return {
      account: { id: accountId },
      orderNo: null,
      name: null,
      description: null,
      managerUser: null,
      schedulePlan: { id: 0 },
      status: "PASSIVE",
      dispatchMethod: null,
    };
  };
  const [queueDTO, setQueueDTO] = useState(newQueue());

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsDialogOpen(true);
    },
  }));

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    if (queue) {
      setQueueDTO(queue == null ? newQueue() : { ...queue });
    }
  }, [queue]);

  const saveQueue = () => {
    setLoading(true);
    if (queueDTO.schedulePlan && queueDTO.schedulePlan.id === 0) {
      QueueService.save(
        { ...queueDTO, schedulePlan: null },
        saveOnSuccess,
        saveOnError
      );
    } else QueueService.save(queueDTO, saveOnSuccess, saveOnError);
  };

  const saveOnSuccess = (data) => {
    showAlert(t("SAVED"), "success"); // edited silindi
    setIsDialogOpen(false);
    setLoading(false);
    onSave(data);
  };
  const saveOnError = (error) => {
    console.log(error);
    showAlert(error, "error");
    setLoading(false);
  };
  const handleQueueChange = (prop, val) => {
    setQueueDTO({ ...queueDTO, [prop]: val });
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const handleChangeV = (prop, val) => {
    setQueueDTO({ ...queueDTO, [prop]: val });
  };
  const onDelete = (data) => {
    faDialog({
      description: t("Kuyruğu silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        QueueService.delete(
          queueDTO,
          (data) => {
            showAlert(t("Kuyruk Silindi"), "success");
            const tmpTo = {
              pathname: `/queue`,
              breadCrumbKey: `/queue`,
              search: "?page=0&size=10&isAsc=false&sort=name",
            };
            history.push(tmpTo);
          },
          (error) => {}
        );
      })
      .catch((err) => console.log("error:", err));
  };
  return (
    <FaDialog
      title={t("EDIT_QUEUE")}
      faOpen={isDialogOpen}
      faOnSubmit={saveQueue}
      loading={loading}
      showSaveButton
      faHandleClose={() => {
        setIsDialogOpen(false);
        setQueueDTO(queue ? queue : newQueue());
      }}
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        {queueDTO && queueDTO.orderNo && (
          <Grid item xs={12}>
            <FaInput
              label={t("ORDER_NO")}
              value={queueDTO.orderNo || ""}
              disabled
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <FaInput
            label={t("NAME")}
            required
            onChange={(event) => handleQueueChange("name", event.target.value)}
            value={queueDTO.name || ""}
          />
        </Grid>

        <Grid item xs={12}>
          <FaInput
            label={t("DESCRIPTION")}
            faType="description"
            inputProps={{ maxLength: 255 }}
            onChange={(event) =>
              handleQueueChange("description", event.target.value)
            }
            value={queueDTO.description || ""}
          />
        </Grid>

        <Grid item xs={12}>
          <SchedulePlanSelect
            value={queueDTO.schedulePlan ? queueDTO.schedulePlan.id : 0}
            onChange={(data) => {
              handleChangeV("schedulePlan", data);
            }}
            label={t("SCHEDULE_PLAN")}
          />
        </Grid>

        <Grid item xs={12}>
          <UserAutoComplete
            value={queueDTO.managerUser || ""}
            accountId={accountId}
            onChange={(data) => {
              handleChangeV("managerUser", data);
            }}
            required
            label={t("MANAGER_USER")}
          />
        </Grid>

        <Grid item xs={12}>
          <QueueDispatchMethodSelect
            value={queueDTO.dispatchMethod || ""}
            label={t("DISPATCH_METHOD")}
            required={true}
            onChange={(data) => {
              handleChangeV("dispatchMethod", data);
            }}
          />
        </Grid>

        {queueDTO.id && (
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button onClick={onDelete} color="secondary">
              {t("Sil")}
            </Button>
          </Grid>
        )}
      </Grid>
    </FaDialog>
  );
});

export default QueueEdit;
