import Grid from "@material-ui/core/Grid";
import i18n from "i18n";
import React, { useEffect } from "react";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import MyCampaigns from "./MyCampaigns";
import OpenIssues from "./MyIssues";
import MyOffers from "./MyOffers";
import { useStyles } from "./style";
import Welcome from "./Welcome";

export default function BeneficiaryHomepage() {
  const { t } = useTranslation("beneficiaryPortal", { i18n });
  let history = useHistory();

  const classes = useStyles();

  const userInfo = useSelector(getUserInfo);

  useEffect(() => {}, [userInfo]);

  useEffect(() => {}, []);

  const renderHomePage = () => {
    return (
      <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid item lg={12}>
            <Welcome />
          </Grid>
          <Grid item lg={6}>
            <OpenIssues />
          </Grid>
          <Grid item lg={6}>
            <MyCampaigns />
          </Grid>
          <Grid item lg={12}>
            <MyOffers />
          </Grid>
        </Grid>
      </div>
    );
  };

  return <>{renderHomePage()}</>;
}
