import React from "react";
import { FaAutoComplete } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { VENDOR } from "../../services/faApi";

export default function VendorAutoComplete(props) {
  const { value, accountId, onChange, required, multiple } = props;

  const { t } = useTranslation("components", { i18n });

  return (
    <FaAutoComplete
      faType="async"
      value={value}
      getOptionSelected={(option, value) => {
        return option.id === value.id;
      }}
      sort="name"
      faFilterProp="name"
      getOptionLabel={(option) => (option.name ? option.name : "")}
      requestUrl={VENDOR.findByName + "?accountId=" + accountId}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      label={t("VENDOR")}
      required={required}
      multiple={multiple}
    />
  );
}
