import React from "react";
import { FaAutoComplete } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { JOURNAL_TYPE } from "../../services/faApi";

export default function JournalTypeAutoComplete(props) {
  const { value, accountId, onChange, required, multiple, label } = props;

  const { t } = useTranslation("components", { i18n });

  return (
    <FaAutoComplete
      faType="async"
      value={value}
      getOptionSelected={(option, value) => {
        return option.id === value.id && option.source === value.source;
      }}
      sort="name"
      faFilterProp="name"
      getOptionLabel={(option) => option.name}
      requestUrl={JOURNAL_TYPE.findByName + "?accountId=" + accountId}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      label={label ?? t("JOURNAL_TYPE")}
      required={required}
      multiple={multiple}
    />
  );
}
