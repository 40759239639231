import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { THING_RELATION } from "./faApi";

export const ThingRelationService = {
  save: (thingRelation, callback, errorCallback) => {
    let reqBody = {
      ...thingRelation,
    };

    let req = requests.getSimplePostRequest(THING_RELATION.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  check: (thingRelation, callback, errorCallback) => {
    let reqBody = {
      ...thingRelation,
    };

    let req = requests.getSimplePostRequest(THING_RELATION.check, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  saveAll: (list, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(THING_RELATION.saveAll, list);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (thingRelation, callback, errorCallback) => {
    let reqBody = {
      ...thingRelation,
    };

    let req = requests.getSimplePostRequest(THING_RELATION.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findThingRelations: (thingId, accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      THING_RELATION.findThingRelations +
        "?accountId=" +
        accountId +
        "&thingId=" +
        thingId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
