import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import i18n from "../../../i18n";
import ThingsSteps from "../ThingsSteps";
import TutorialElement from "../TutorialElement";

export default function ExportDataTutorial() {
  const { code } = useParams();

  const stepsRef = useRef();

  const { t } = useTranslation("tutorialStorageStat", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            Hesabınızdaki etkileşim noktalarınıza ait{" "}
            <b>bilgilerin, görsellerin</b> ve <b>belgelerin</b> dışarı
            aktarımının yapıldığı alandır.
          </TutorialElement>
        ),
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/tutorial/exportData1.png"
                }
                width="383"
                height="89"
              ></img>
            </div>
            Etkileşim noktalarınıza ait bilgileri, görselleri ve belgeleri
            dışarı aktarmak için tıklayın.
          </TutorialElement>
        ),
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={process.env.PUBLIC_URL + "/assets/tutorial/exportData.png"}
                width="403"
                height="165"
              ></img>
              <br />
              <b>Devam Et</b> butonuna basın ve dışarı aktarın.
            </div>
          </TutorialElement>
        ),
      },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={"export_data"}
        steps={steps}
        ref={stepsRef}
        onStepChange={setStepIndex}
        afterComplete={() => {
          var el = $("[aria-label='close']");
          if (el != null && el[0] != null) el[0].click();
        }}
      />
    </div>
  );
}
