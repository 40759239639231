import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  AccountDetailImage: {
    width: "179px",
    height: "179px",
    margin: "0 auto",
  },
  AccountDetailLabelHeader: {
    //textAlign: "right"
  },
  publicReportPageTemplateImage: {
    width: 100,
  },
}));
