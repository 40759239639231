import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { BENEFICIARY_NOTE } from "./faApi";

export const BeneficiaryNoteService = {
  save: (beneficiaryNote, callback, errorCallback) => {
    let reqBody = {
      ...beneficiaryNote,
    };

    let req = requests.getSimplePostRequest(BENEFICIARY_NOTE.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
};
