import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { DOCUMENT } from "./faApi";

export const DocumentService = {
  uploadCustomerDocument: (
    customerId,
    accountId,
    name,
    description,
    document,
    callback,
    errorCallback
  ) => {
    let formData = new FormData();
    formData.append("multipartFile", document.file, document.file.name);
    formData.append("customerId", customerId);
    formData.append("accountId", accountId);
    formData.append("name", name);
    formData.append("description", description);

    let req = requests.getSimplePostRequest(
      DOCUMENT.uploadCustomerDocument,
      formData
    );
    req["header"] = { "Content-Type": "multipart/form-data" };

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  downloadCustomerDocument: (
    accountId,
    customerId,
    documentId,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(
      DOCUMENT.downloadCustomerDocument +
        "?accountId=" +
        accountId +
        "&customerId=" +
        customerId +
        "&documentId=" +
        documentId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  deleteCustomerDocument: (
    accountId,
    customerId,
    documentId,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimplePostRequest(
      DOCUMENT.deleteCustomerDocument +
        "?accountId=" +
        accountId +
        "&customerId=" +
        customerId +
        "&documentId=" +
        documentId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  downloadBeneficiaryNoteDocument: (
    accountId,
    beneficiaryNoteId,
    documentId,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(
      DOCUMENT.downloadBeneficiaryNoteDocument +
        "?accountId=" +
        accountId +
        "&beneficiaryNoteId=" +
        beneficiaryNoteId +
        "&documentId=" +
        documentId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  /* SERVICE OPERATION CLASS PROCEDURE */

  findServiceOperationProcedureDocuments: (
    serviceOperationProcedureId,
    accountId,
    callback,
    errorCallback
  ) => {
    let reqParams = {
      serviceOperationProcedureId: serviceOperationProcedureId,
      accountId: accountId,
      page: 0,
      size: 10,
      isAsc: false,
      sort: "name",
    };

    let req = requests.getSimpleGetRequest(
      DOCUMENT.findServiceOperationProcedureDocuments,
      reqParams
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  uploadServiceOperationProcedureDocument: (
    serviceOperationProcedureId,
    accountId,
    name,
    description,
    document,
    callback,
    errorCallback
  ) => {
    let formData = new FormData();
    formData.append("multipartFile", document.file, document.file.name);

    let req = requests.getSimplePostRequest(
      DOCUMENT.uploadServiceOperationProcedureDocument +
        "?serviceOperationProcedureId=" +
        serviceOperationProcedureId +
        "&accountId=" +
        accountId +
        "&name=" +
        name +
        "&description=" +
        description,
      formData
    );
    req["header"] = { "Content-Type": "multipart/form-data" };

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  downloadServiceOperationProcedureDocument: (
    serviceOperationProcedureId,
    accountId,
    documentId,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(
      DOCUMENT.downloadServiceOperationProcedureDocument +
        "?serviceOperationProcedureId=" +
        serviceOperationProcedureId +
        "&accountId=" +
        accountId +
        "&documentId=" +
        documentId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  deleteServiceOperationProcedureDocument: (
    serviceOperationProcedureId,
    accountId,
    documentId,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimplePostRequest(
      DOCUMENT.deleteServiceOperationProcedureDocument +
        "?serviceOperationProcedureId=" +
        serviceOperationProcedureId +
        "&accountId=" +
        accountId +
        "&documentId=" +
        documentId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  deleteDynamicFormDocument: (
    accountId,
    dynamicFormId,
    objectIds,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimplePostRequest(
      DOCUMENT.deleteDynamicFormDocument +
        "?accountId=" +
        accountId +
        "&dynamicFormId=" +
        dynamicFormId +
        "&objectIds=" +
        objectIds
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
