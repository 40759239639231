import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function PeriodicReportElementSelect(props) {
  const { value, onChange, required, label } = props;
  const classes = useStyles();
  const { t } = useTranslation("periodicReport", { i18n });

  return (
    <FormControl variant="outlined" className={classes.w100} size="small">
      <InputLabel>{label ?? t("Birim")}</InputLabel>
      <Select
        // classes={{
        //   outlined: classes.padding10,
        // }}
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        required={required}
        label={label ?? t("Birim")}
      >
        <MenuItem value={null}>...</MenuItem>
        <MenuItem value={"THINGS"}>{t("THINGS")}</MenuItem>
        <MenuItem value={"THINGS_12_MONTH_CHART"}>
          {t("THINGS_12_MONTH_CHART")}
        </MenuItem>
        <MenuItem value={"ISSUES_CHART"}>{t("ISSUES_CHART")}</MenuItem>
        <MenuItem value={"MY_THINGS"}>{t("MY_THINGS")}</MenuItem>
        <MenuItem value={"CALENDAR"}>{t("CALENDAR")}</MenuItem>
        <MenuItem value={"TODO"}>{t("TODO")}</MenuItem>

        <MenuItem value={"ISSUE_LIST"}>{t("ISSUE_LIST")}</MenuItem>
        <MenuItem value={"LIKE_LIST"}>{t("LIKE_LIST")}</MenuItem>
        <MenuItem value={"SUGGESTION_LIST"}>{t("SUGGESTION_LIST")}</MenuItem>
        <MenuItem value={"SURVEY_JOIN_LIST"}>{t("SURVEY_JOIN_LIST")}</MenuItem>
        <MenuItem value={"CAMPAIGN_JOIN_LIST"}>
          {t("CAMPAIGN_JOIN_LIST")}
        </MenuItem>
        <MenuItem value={"ANNOUNCEMENTS"}>{t("ANNOUNCEMENTS")}</MenuItem>
      </Select>
    </FormControl>
  );
}
