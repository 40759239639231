import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { useStyles } from "../style";
import ThingsSteps from "../ThingsSteps";
import TutorialElement from "../TutorialElement";

export default function CustomerInteractionsIssues() {
  const stepsRef = useRef();

  const { t } = useTranslation("tutorialCustomerInteractionsAll", { i18n });
  const classes = useStyles();
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            Müşterilerinizin bildirdiği <b>Sorunlar</b>’ın toplu liste olarak
            gösterildiği ekran/menü’dür.
            <p>
              Bu menüde bildirilen sorunları;
              <ul>
                <li>
                  <b>Açık/Kapalı</b> olma durumuna göre listeleyebilirsiniz.
                </li>
                <li>
                  <b>Bildirim tarihi</b>'ne göre görebilirsiniz.
                </li>
                <li>
                  <b>Sorun tipi</b>'ne göre filtreleyebilirsiniz.
                </li>
                <li>
                  <b>Bildiren</b>'e göre listeleyebilirsiniz.
                </li>
                <li>
                  <b>Kapatma tarihi</b>'ne göre listeleyebilirsiniz.
                </li>
                <li>
                  <b>Etkileşim noktasına </b> göre filtreleyebilirsiniz.
                </li>
                <li>
                  <b>Excel </b>dosyası olarak dışarı aktarabilirsiniz.
                </li>
                <li>
                  <b>Sorun</b> kapatabilirsiniz.
                </li>
                <li>
                  <b>Yeni sorun</b> ekleyebilirsiniz.
                </li>
                <li>
                  Sorun <b>detay bilgilerine</b> erişebilirsiniz.
                </li>
              </ul>
            </p>
          </TutorialElement>
        ),
      },
      {
        element: "#statusSpeedFilter",
        intro: (
          <TutorialElement icon="click">
            Açık sorunları listelemek için <b>Açık</b>’a, kapalı sorunları
            listelemek için <b>Kapalı</b>’ya tıklayın.
          </TutorialElement>
        ),
        onBeforeChange: () => {
          let element = document.querySelector("#back-to-top-anchor");
          element.scrollIntoView();
        },
      },
      {
        element: "[data-fa-column='status']",
        intro: (
          <TutorialElement icon="click">
            <b>Sorunlar</b>’ın tümünü <b>Açık</b> ve <b>Kapalı</b> olma
            durumlarına göre listelemek için tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "#dateSpeedFilter",
        intro: (
          <TutorialElement icon="click">
            Bildirilen sorunları Tarih’e göre listelemek için <b>“Bugün”</b>,
            <b>“Son 7 Gün”</b> ve <b>“Son 1 Ay”</b> seçeneklerinden birine
            tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-column='notificationDate']",
        intro: (
          <TutorialElement icon="click">
            Bildirilen sorunların tümünü <b>ilk</b> veya <b>son</b> bildirim
            tarihine göre listelemek için tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-column='id2']",
        intro: (
          <TutorialElement icon="click">
            Bildirilen sorunların tümünü <b>Sorun Tipi</b>’ne göre listelemek
            için tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-column='user']",
        intro: (
          <TutorialElement icon="click">
            Bildirilen sorunların tümünü <b>Bildiren</b>’e göre listelemek için
            tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-column='closeDate']",
        intro: (
          <TutorialElement icon="click">
            Bildirilen sorunların tümünü <b>Kapatma tarihine</b> göre listelemek
            için tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: ".appSearchBar",
        intro: (
          <TutorialElement icon="click">
            Hızlı arama için Ara’ya ve detaylı arama için Filtre’ye tıklayın.
          </TutorialElement>
        ),
      },
      {
        element:
          "button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textSizeSmall.MuiButton-sizeSmall",
        intro: (
          <TutorialElement icon="click">
            Tarih gösterimini ayarlamak için tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: null,
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/issues_date_settings.png"
                }
                width="700"
                height="261"
              ></img>
            </div>
            <br />
            Görünüm’e tıklayın.
          </TutorialElement>
        ),
        tooltipClass: classes.issueDateSettings,
      },
      {
        element: null,
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/issues_date_settings.png"
                }
                width="700"
                height="261"
              ></img>
            </div>
            <br />
            <b>Açık Tarih</b> veya <b>Akıllı Tarih</b>’ten birini seçin ve{" "}
            <b>Uygula</b> butonuna basın.
            <br />
            <br />
            <b>ÖNEMLİ NOT</b> :<br />
            <b>Açık Tarih</b>, etkileşim noktası ile ilgili her bir işlemin
            gerçekleştiği tarih ve saat bilgilerini içerir.
            <b>Akıllı Tarih</b> ise işlemin gerçekleşmesi sonrasında geçen
            süreyi içerir.
          </TutorialElement>
        ),
        tooltipClass: classes.issueDateSettings,
      },
      {
        element: "div.MuiTablePagination-selectRoot", // sayfa basina kayit sayisi
        intro: (
          <TutorialElement icon="click">
            Sayfa başına Sorun Sayısını ayarlamak için tıklayın ve 10, 25, 50 ve
            100 seçeneklerinden birini seçin.
          </TutorialElement>
        ),
        position: "top",
      },
      {
        element: ".MuiTablePagination-actions",
        intro: (
          <TutorialElement icon="click">
            Sayfalar arasında geçiş yapmak için {"“<”"} ve {"“>”"} seçeneklerine
            tıklayın.
          </TutorialElement>
        ),
        position: "top",
      },
      {
        element: "[title='Excel']",
        intro: (
          <TutorialElement icon="click">
            Listeyi <b>Dışarı Aktarmak</b> için <b>Excel</b> ikonuna tıklayın.
          </TutorialElement>
        ),
        position: "top",
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/issue_list_element.png"
                }
                width="761"
                height="308"
              ></img>
            </div>
            <br />
            Detay bilgilerine erişmek istediğiniz <b>Sorun</b>’un üzerine{" "}
            <b>çift</b> tıklayın.
          </TutorialElement>
        ),
        tooltipClass: classes.issueListElement,
      },
      {
        element: "[data-fa-button='REPORT_ISSUE']",
        intro: (
          <TutorialElement icon="click">
            <b>Yeni Sorun</b> bildirmek için tıklayın. <br />
            <br />
            <b>ÖNEMLİ NOT: </b>
            <br />
            Yeni sorun bildirmek için <b>Nasıl Yapılır</b> menüsündeki
            basamakları takip edebilirsiniz.
          </TutorialElement>
        ),
      },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={"issues"} // veri tabanındaki kodla aynı olması önemli
        steps={steps}
        ref={stepsRef}
        onStepChange={setStepIndex}
        // otherOptions={{ scrollToElement: false }}
      />
    </div>
  );
}
