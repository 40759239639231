import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { LIKECLASS } from "./faApi";

export const LikeClassService = {
  save: (likeClass, callback, errorCallback) => {
    let reqBody = {
      ...likeClass,
    };

    let req = requests.getSimplePostRequest(LIKECLASS.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (likeClass, callback, errorCallback) => {
    let reqBody = {
      ...likeClass,
    };

    let req = requests.getSimplePostRequest(LIKECLASS.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findByThingClass: (thingClassId, accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      LIKECLASS.findByThingClass +
        "?accountId=" +
        accountId +
        "&thingClassId=" +
        thingClassId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  enable: (likeClassDisableId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(LIKECLASS.enable, {
      likeClassDisableId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  disable: (likeClassId, thingClassId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(LIKECLASS.disable, {
      likeClassId,
      thingClassId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(LIKECLASS.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
