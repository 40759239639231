import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { useStyles } from "./style";

export default function RightBarDrawer(props) {
  const classes = useStyles();
  return (
    <Drawer
      open={props.open}
      variant="persistent"
      anchor="right"
      classes={{ paper: classes.drawerPaper }}
    >
      {props.onClose && (
        <IconButton
          aria-label="close"
          size="medium"
          className={classes.closeButton}
          onClick={props.onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
      {props.children}
    </Drawer>
  );
}
