import Grid from "@material-ui/core/Grid";
import ThingClassAutoComplete from "components/autocomplete/ThingClassAutoComplete";
import VendorAutoComplete from "components/autocomplete/VendorAutoComplete";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaAutoComplete, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { THING } from "../../services/faApi";
import { ThingService } from "../../services/thing/thingService";
import { useStyles } from "./ThingDetailCardStyle";

const ThingEdit = forwardRef((props, ref) => {
  const { thing, onUpdate } = props;
  const { t } = useTranslation("thingDetail", { i18n });
  const classes = useStyles();

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsThingUpdateFormOpen(true);
    },
  }));

  const [thingDTO, setThingDTO] = useState({ ...thing });
  const [hashtags, setHashtags] = useState([]);

  useEffect(() => {
    setThingDTO({ ...thing });
  }, [thing]);

  const handleChangeV = (prop, val) => {
    setThingDTO({ ...thingDTO, [prop]: val });
  };

  const [isThingUpdateFormOpen, setIsThingUpdateFormOpen] = useState(false);

  const updateThing = () => {
    let hashtagString = "";
    hashtags.map((item) => {
      hashtagString = hashtagString.concat("#" + item + " ");
    });
    ThingService.update(
      { ...thingDTO, hashtag: hashtagString },
      updateSuccessful,
      updateFail
    );
  };

  const updateSuccessful = (newData) => {
    onUpdate(newData);
    setIsThingUpdateFormOpen(false);
  };

  const updateFail = () => {};

  return (
    <FaDialog
      title={"Etkileşim Noktası - Temel Bilgiler Güncelle"}
      faOpen={isThingUpdateFormOpen}
      faOnSubmit={updateThing}
      showSaveButton
      faHandleClose={(data) => {
        setIsThingUpdateFormOpen(data);
      }}
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={0}
      >
        <Grid item xs={12}>
          <ThingClassAutoComplete
            required={true}
            value={thingDTO.thingClass}
            accountId={thingDTO?.account?.id}
            onChange={(data) => {
              setThingDTO({
                ...thingDTO,
                ["thingClass"]: data,
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FaInput
            label={t("DESCRIPTION")}
            onChange={(event) =>
              handleChangeV("description", event.target.value)
            }
            value={thingDTO.description || ""}
          />
        </Grid>
        <Grid item xs={12}>
          <FaInput
            label={t("THING_CODE")}
            onChange={(event) => handleChangeV("thingCode", event.target.value)}
            value={thingDTO.thingCode || ""}
          />
        </Grid>
        <Grid item xs={12}>
          <FaAutoComplete
            faType="async"
            value={hashtags}
            faMultiple={true}
            //faDefaultValue={hashtags ? hashtags : ""}
            getOptionSelected={(option, value) => {
              return option === value;
            }}
            sort="filter"
            autoSelect
            faFilterProp="filter"
            getOptionLabel={(option) => {
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option;
            }}
            requestUrl={
              THING.findHashtag + "?accountId=" + thingDTO?.account?.id
            }
            onChange={(event, newValue) => {
              setHashtags(newValue);
            }}
            freeSolo
            label={t("HASHTAG")}
          />
        </Grid>
        <Grid item xs={12}>
          <VendorAutoComplete
            value={thingDTO.vendor}
            accountId={thingDTO?.account?.id}
            onChange={(data) => {
              handleChangeV("vendor", data);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FaInput
            label={t("LOCATION")}
            onChange={(event) => handleChangeV("location", event.target.value)}
            value={thingDTO.location || ""}
          />
        </Grid>
      </Grid>
    </FaDialog>
  );
});

export default ThingEdit;
