import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, FormLabel, IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";
import { unwrapResult } from "@reduxjs/toolkit";
import i18n from "i18n";
import PropTypes from "prop-types";
import React, { createRef, useEffect, useState } from "react";
import { FaButton, FaInput, FaUnAuthPages } from "react-base-fa/dist/fa";
import { clearArr } from "react-base-fa/dist/fa/faBreadCrumb/breadCrumbSlice";
import useStyles from "react-base-fa/dist/fa/faUnAuthPages/style";
import { requests } from "react-base-fa/dist/services/requestCreater";
import {
  loginThunk,
  setRole,
  setUserInfo,
} from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import "react-puzzle-confirm/react-puzzle-confirm.css";
import { useDispatch } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useHistory } from "react-router-dom";
import { BENEFICIARY_PORTAL } from "services/faApi";

export default function BeneficiaryLoginLdap(props) {
  const { appLogo, partial, onSuccess } = props;

  const classes = useStyles();
  const { t } = useTranslation(["login", "errorMessages"], {
    i18n,
  });
  const history = useHistory();
  const formRef = createRef();

  const [loading, setLoading] = useState(null);

  const [values, setValues] = useState({
    email: "",
    username: "",
    password: "",
  });

  useEffect(() => {
    setLoading(false);
  }, []);

  const dispatch = useDispatch();

  async function submitForm(event) {
    event.preventDefault();
    //logIn();
  }

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const logIn = async () => {
    setLoading(true);
    try {
      const res = unwrapResult(
        await dispatch(
          loginThunk(
            requests.getSimplePostRequest(BENEFICIARY_PORTAL.loginLdap, {
              email: values.email,
              password: values.password,
            })
          )
        )
      );

      if (res && res.ERROR) {
        switch (res.ERROR) {
          case "USER_NOT_ENABLED":
            setErrorMessage(t("errorMessages:USER_NOT_ENABLED"));
            setShowError(true);
            break;
          case "USER_NOT_FOUND":
            setErrorMessage(t("errorMessages:USER_NOT_FOUND"));
            setShowError(true);
            break;
          case "USER_ACCOUNT_EXPIRED":
            setErrorMessage(t("errorMessages:USER_ACCOUNT_EXPIRED"));
            setShowError(true);
            break;
          case "USER_ACCOUNT_LOCKED":
            setErrorMessage(t("errorMessages:USER_ACCOUNT_LOCKED"));
            setShowError(true);
            break;
          case "USER_CREDENTIALS_EXPIRED":
            setErrorMessage(t("errorMessages:USER_CREDENTIALS_EXPIRED"));
            setShowError(true);
            break;
          case "WRONG_PASSWORD":
            setErrorMessage(t("errorMessages:WRONG_PASSWORD"));
            setShowError(true);
            break;
          default:
            break;
        }
        setLoading(false);
      } else {
        dispatch(setUserInfo(JSON.parse(localStorage.userInfo)));
        dispatch(clearArr());

        dispatch(setRole(["PUBLIC_BENEFICIARY"]));

        if (res.ACCOUNT_LIST && res.ACCOUNT_LIST.length === 1) {
          localStorage.setItem("beneficiaryAccountId", res.ACCOUNT_LIST[0].id);
          localStorage.setItem(
            "beneficiaryAccountName",
            res.ACCOUNT_LIST[0].fullname
          );

          if (onSuccess) onSuccess();
          else history.push("/bportal/homepage");
        } else if (res.ACCOUNT_LIST) {
          const tmpTo = {
            pathname: "/bportal/accountSelection",
            state: {
              accountList: [...res.ACCOUNT_LIST],
            },
          };

          if (onSuccess) onSuccess();
          else history.push(tmpTo);
        }
      }
    } catch (err) {
      console.log("error", `Fetch failed: ${err.message}`);
      console.log(err);
      setLoading(false);
      switch (err && err.response && err.response.status) {
        case 400:
          showErrorMessage(err.response);
          break;
        default:
          break;
      }

      setLoading(false);
    }
  };

  const showErrorMessage = (err) => {
    let message;
    if (err.data.ERROR.includes("validationError")) {
      const errorObj = JSON.parse(err.data.ERROR);
      message = errorObj.validationError.map((error) => {
        return (
          <p className={classes.errorMessage}>
            {" "}
            <strong>{error.fieldName}</strong> {error.errorMessage}
          </p>
        );
      });
    } else {
      message = t("errorMessages:" + err.data.ERROR);
    }
    setErrorMessage(message);
    setShowError(true);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const render = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <form
            ref={formRef}
            className={classes.form}
            validate="true"
            onSubmit={submitForm}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormLabel component="legend">
                  {t(
                    "Faydalanıcı portali için lütfen iletişim bilginizi girin"
                  )}
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <FaInput
                  required
                  label={t("KULLANICI_E_POSTA")}
                  autoComplete="email"
                  onChange={handleChange("email")}
                  value={values.email}
                />
              </Grid>
              <Grid item xs={12}>
                <FaInput
                  faType="password"
                  required
                  label={t("SIFRE")}
                  onChange={handleChange("password")}
                  value={values.password}
                />
              </Grid>
            </Grid>
            <Collapse in={showError}>
              <Alert
                variant="outlined"
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setShowError(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {errorMessage}
              </Alert>
            </Collapse>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FaButton
                  async
                  formRef={formRef}
                  type="submit"
                  label={t("GIRIS_YAP")}
                  variant="contained"
                  className={classes.submit}
                  loading={loading}
                  startIcon={<FontAwesomeIcon icon={faSignInAlt} />}
                  faClick={() => {
                    logIn();
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {partial && render()}
      {!!!partial && (
        <FaUnAuthPages>
          <img
            src={appLogo}
            height="78"
            alt="logo"
            className={classes.appLogo}
          />
          {render()}
        </FaUnAuthPages>
      )}
    </>
  );
}

BeneficiaryLoginLdap.propTypes = {
  loginPath: PropTypes.string,
  appLogo: PropTypes.any,
};
