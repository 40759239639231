import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, IconButton, Typography } from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import Adsense from "components/adsense/Adsense";
import UserDetail from "components/detail/UserDetail";
import RoleSelect from "components/select/RoleSelect";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaDialog, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getRole } from "react-base-fa/dist/services/sessionSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AccUserService } from "services/AccUserService";
import { ROLES } from "services/utils/Roles";
import { checkRole } from "services/utils/SecurityUtils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { ACC_USER } from "../../services/faApi";

export default function AccountUsers() {
  const tableRef = useRef();
  const labelClasses = labelStyles();
  const faDialog = useDialog();
  const { t } = useTranslation(["accountUsers", "components"], { i18n });
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const accountId = localStorage.accountId;
  const role = useSelector(getRole);
  const isEditable = checkRole([ROLES.POWER_USER, ROLES.ACCOUNTOWNER], role);

  const [loading, setLoading] = useState(false);
  const [isRegisterFormOpen, setIsRegisterFormOpen] = useState(false);
  const [roleEditWindow, setRoleEditWindow] = useState(false);

  const columns = [
    {
      field: "user.fullName",
      label: t("AD_SOYAD"),
      align: "center",
      width: "20%",
      valueFormatter: (value, row) => (
        <UserDetail value={row?.user} type="USER" />
      ),
    },
    {
      field: "authority",
      label: t("ROL"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        return value && t("ROLE_" + value, { ns: "components" });
      },
    },
    {
      field: "user.email",
      label: t("E_POSTA"),
      align: "center",
      width: "20%",
    },
    {
      field: "user.phone",
      label: t("PHONE_NUMBER"),
      align: "center",
      width: "20%",
    },
    {
      field: "createDate",
      label: t("CREATE_DATE"),
      align: "center",
      width: "10%",
      type: "dateTime",
    },
    {
      field: "deleted",
      label: t("ACTIVE"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        if (value == null || value === false) return <Check />;
        else return <Close />;
      },
    },
  ];

  const [accUser, setAccUser] = useState(null);

  // const saveSuccess = (data) => {
  //   showAlert(!vendor.id ? t("SAVED") : t("EDITED"), "success");
  //   setIsRegisterFormOpen(false);
  //   //setVendor(newVendor());
  //   tableRef.current.refreshTable();
  //   setLoading(false);
  // };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  // const saveVendor = () => {
  //   setLoading(true);
  //   VendorService.save(
  //     {
  //       ...vendor,
  //       account: { id: accountId },
  //     },
  //     saveSuccess,
  //     saveError
  //   );
  // };

  const newSearchForm = () => {
    let result = {
      fullname: null,
      authority: null,
      accountId: accountId,
      email: null,
      phone: null,
    };

    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    setAccUser(row);
    setIsRegisterFormOpen(true);
  };

  const deleteUser = () => {
    faDialog({
      description: t(
        "Kullanıcıyı hesaptan çıkarmak istediğinize emin misiniz?"
      ),
      type: "confirm",
    })
      .then((confirmation) => {
        AccUserService.softDelete(
          accUser.id,
          () => {
            tableRef.current.refreshTable();
            setIsRegisterFormOpen(false);
            showAlert(t("DELETED"), "success");
          },
          () => {}
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const activateUser = () => {
    faDialog({
      description: t(
        "Kullanıcıyı aktif hale getirmek istediğinize emin misiniz?"
      ),
      type: "confirm",
    })
      .then((confirmation) => {
        AccUserService.activate(
          accUser.id,
          () => {
            tableRef.current.refreshTable();
            setIsRegisterFormOpen(false);
            showAlert(t("ACTIVATED"), "success");
          },
          () => {}
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const renderDetailDialog = () => {
    return (
      <FaDialog
        title={"Kullanıcı Detay"}
        faOpen={isRegisterFormOpen}
        faHandleClose={() => {
          setIsRegisterFormOpen(false);
        }}
      >
        {accUser && (
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={0}
          >
            <Grid item xs={12}>
              <Grid item container spacing={1}>
                <Grid item xs={6} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("AD_SOYAD")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    <UserDetail value={accUser?.user} type="USER" />
                  </Typography>
                </Grid>

                <Grid item xs={4} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("ROL")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {t("ROLE_" + accUser.authority, { ns: "components" })}
                  </Typography>
                </Grid>

                <Grid item xs={2} className={labelClasses.LabelRow}>
                  {isEditable && accUser.authority !== ROLES.ACCOUNTOWNER && (
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => {
                        setRoleEditWindow(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </IconButton>
                  )}
                </Grid>

                <Grid item xs={6} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("E_POSTA")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {accUser.user.email}
                  </Typography>
                </Grid>

                <Grid item xs={6} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("PHONE_NUMBER")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {accUser.user.phone}
                  </Typography>
                </Grid>

                <Grid item xs={10}></Grid>
                <Grid item xs={2}>
                  {isEditable &&
                    (accUser.deleted == null || accUser.deleted == false) && (
                      <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        startIcon={<Close />}
                        onClick={() => {
                          deleteUser();
                        }}
                        title="Sil"
                      >
                        Sil
                      </Button>
                    )}
                  {isEditable &&
                    accUser.deleted != null &&
                    accUser.deleted == true && (
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        startIcon={<Check />}
                        onClick={() => {
                          activateUser();
                        }}
                        title="Aktifleştir"
                      >
                        Aktifleştir
                      </Button>
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </FaDialog>
    );
  };

  const saveRoleChange = (e) => {
    AccUserService.changeUserRole(
      accUser.id,
      accUser.authority,
      (data) => {
        setRoleEditWindow(false);
        setIsRegisterFormOpen(false);
        tableRef.current.refreshTable();
      },
      (error) => {}
    );
  };

  const renderRoleEditDialog = () => {
    return (
      <FaDialog
        title={"Rol Değiştir"}
        faOpen={roleEditWindow}
        loading={loading}
        faHandleClose={() => {
          setRoleEditWindow(false);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={0}
        >
          <Grid item xs={12}>
            <Grid item container spacing={1}>
              <RoleSelect
                value={accUser?.authority}
                onChange={(data) => {
                  setAccUser({ ...accUser, authority: data });
                }}
              />
            </Grid>
            <Grid item container spacing={1}>
              <Button
                color="primary"
                onClick={() => {
                  saveRoleChange();
                }}
                title="Kaydet"
              >
                Kaydet
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {renderDetailDialog()}
        {renderRoleEditDialog()}
        <ThingsDataTable
          searchUrl={ACC_USER.search + "?accountId=" + localStorage.accountId}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Adsense value="RklmAlt" />
          <Grid item xs={6}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <FaInput
                label={t("AD_SOYAD")}
                value={searchForm.fullname}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "fullname",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("E_POSTA")}
                value={searchForm.email}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "email",
                    event.target.value
                  );
                }}
              />
              <FaInput
                label={t("PHONE_NUMBER")}
                value={searchForm.phone}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "phone",
                    event.target.value
                  );
                }}
              />
              <RoleSelect
                value={searchForm.authority}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    authority: data,
                  });
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
