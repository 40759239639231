import { Grid } from "@material-ui/core";
import { THINGS_ENVIRONMENT } from "environments/Environments";
import { default as React, Suspense, useRef } from "react";
import FaProfile from "react-base-fa/dist/fa/faProfile";
import { useTranslation } from "react-i18next";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import Invitations from "./Invitations";
import UserAccounts from "./UserAccounts";

export default function Profile() {
  const treeRef = useRef();
  const { t } = useTranslation("profile", { i18n });
  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid container spacing={1}>
          <Grid item lg={12}>
            <FaProfile
              showFacebookButton={THINGS_ENVIRONMENT.showFacebookButton}
              showGoogleButton={THINGS_ENVIRONMENT.showGoogleButton}
              showAppleButton={THINGS_ENVIRONMENT.showAppleButton}
            />
          </Grid>
          <Grid item lg={12}>
            <UserAccounts />
          </Grid>
          <Grid item lg={12}>
            <Invitations />
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
