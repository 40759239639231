import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { MESSAGE_TEMPLATE_TARGET } from "./faApi";

export const MessageTemplateTargetService = {
  save: (mst, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(MESSAGE_TEMPLATE_TARGET.save, mst);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (mst, callback, errorCallback) => {
    let reqBody = {
      ...mst,
    };

    let req = requests.getSimplePostRequest(
      MESSAGE_TEMPLATE_TARGET.delete,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
};
