import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { STOCK_TAKING_USER } from "./faApi";

export const StockTakingUserService = {
  save: (stockTakingUser, callback, errorCallback) => {
    let reqBody = {
      ...stockTakingUser,
    };

    let req = requests.getSimplePostRequest(STOCK_TAKING_USER.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findByStockTaking: (stockTakingId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      STOCK_TAKING_USER.findByStockTaking,
      {
        stockTakingId,
      }
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (stockTakingUser, callback, errorCallback) => {
    let reqBody = {
      ...stockTakingUser,
    };

    let req = requests.getSimplePostRequest(STOCK_TAKING_USER.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
