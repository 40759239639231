import Grid from "@material-ui/core/Grid";
import CustomerHashtag from "components/hashtag/CustomerHashtag";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { CustomerService } from "services/CustomerService";
import i18n from "../../i18n";

const CustomerEdit = forwardRef((props, ref) => {
  const { onSave, customer } = props;
  const { t } = useTranslation("customer", { i18n });
  const accountId = localStorage.accountId;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const newCustomer = () => {
    return {
      account: { id: accountId },
      membershipDate: new Date(),
      email: "",
      phone: "",
      contactPermission: false,
      contactPermissionDate: "",
      gdprPermission: false,
      gdprPermissionDate: "",
      name: "",
      surname: "",
      hashtag: null,
      recordType: "MANUAL",
      description: "",
      status: "ACTIVE",
      clientInfo: {},
    };
  };
  const [customerDTO, setCustomerDTO] = useState(newCustomer());

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsDialogOpen(true);
    },
  }));

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    if (customer) {
      setCustomerDTO(customer == null ? newCustomer() : { ...customer });
    }
  }, [customer]);

  const saveCustomer = () => {
    setLoading(true);

    CustomerService.save(customerDTO, saveOnsuccess, saveOnError);
  };

  const saveOnsuccess = (data) => {
    showAlert(!customerDTO.id ? t("SAVED") : t("EDITED"), "success");
    setIsDialogOpen(false);
    setLoading(false);
    onSave(data);
    setCustomerDTO(customer ? customer : newCustomer());
  };

  const saveOnError = (error) => {
    showAlert(error, "error");
    setLoading(false);
  };

  const handleCustomerChange = (prop, val) => {
    setCustomerDTO({ ...customerDTO, [prop]: val });
  };

  return (
    <FaDialog
      title={t("ADD_CUSTOMER")}
      faOpen={isDialogOpen}
      faOnSubmit={saveCustomer}
      loading={loading}
      showSaveButton
      //disabled={customerDTO.permission}
      faHandleClose={() => {
        setIsDialogOpen(false);
        setCustomerDTO(customer ? customer : newCustomer());
      }}
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item xs={6}>
          <FaInput
            label={t("NAME")}
            onChange={(event) =>
              handleCustomerChange("name", event.target.value)
            }
            value={customerDTO.name || ""}
          />
        </Grid>

        <Grid item xs={6}>
          <FaInput
            label={t("SURNAME")}
            onChange={(event) =>
              handleCustomerChange("surname", event.target.value)
            }
            value={customerDTO.surname || ""}
          />
        </Grid>

        <Grid item xs={12}>
          <FaInput
            faType="email"
            label={t("E-MAIL")}
            onChange={(event) =>
              handleCustomerChange("email", event.target.value)
            }
            value={customerDTO.email || ""}
          />
        </Grid>

        <Grid item xs={12}>
          <FaInput
            label={t("PHONE")}
            faType="phoneNumber"
            onChange={(event) =>
              handleCustomerChange("phone", event.target.value)
            }
            value={customerDTO.phone || ""}
          />
        </Grid>

        <Grid item xs={12}>
          <FaInput
            label={t("DESCRIPTION")}
            faType="description"
            inputProps={{ maxLength: 255 }}
            onChange={(event) =>
              handleCustomerChange("description", event.target.value)
            }
            value={customerDTO.description || ""}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomerHashtag
            label={t("HASHTAG")}
            accountId={accountId}
            value={customerDTO.hashtag}
            onChange={(data) => {
              handleCustomerChange("hashtag", data);
            }}
            data-fa-search="HASHTAG"
          />
        </Grid>

        {/* {!customerDTO.id && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={customerDTO.contactPermission}
                  onChange={(event) => {
                    setCustomerDTO({
                      ...customerDTO,
                      contactPermission: event.target.checked,
                      contactPermissionDate: new Date(),
                    });
                  }}
                  color="primary"
                />
              }
              label={t("CONTACT_PERMISSION")}
            />
          </Grid>
        )}

        {!customerDTO.id && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={customerDTO.gdprPermission}
                  onChange={(event) => {
                    setCustomerDTO({
                      ...customerDTO,
                      gdprPermission: event.target.checked,
                      gdprPermissionDate: new Date(),
                    });
                  }}
                  color="primary"
                />
              }
              label={t("GPDR_PERMISSION")}
            />
          </Grid>
        )} */}
      </Grid>
    </FaDialog>
  );
});

export default CustomerEdit;
