import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardHeader, Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Add } from "@material-ui/icons";
import SlaTimePeriodSelect from "components/select/SlaTimePeriodSelect";
import ThingsDataTable from "components/thingsDataTable";
import FeatureNotAllowed from "features/subscriptions/FeatureNotAllowed";
import React, { useEffect, useRef, useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { getRole } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ROLES } from "services/utils/Roles";
import { checkRole } from "services/utils/SecurityUtils";
import i18n from "../../i18n";
import { SlaContractLevelService } from "../../services/SlaContractLevelService";

export default function AccountSLALevels(props) {
  const { t } = useTranslation(["accountDetails", "components"], {
    i18n,
  });
  const faDialog = useDialog();
  const tableRef = useRef();
  const accountId = localStorage.accountId;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [levelList, setLevelList] = useState([]);

  const [subscriptionError, setSubscriptionError] = useState(false);
  const role = useSelector(getRole);
  const isRoleExists = checkRole([ROLES.SLA], role);

  const newLevel = () => {
    return {
      account: { id: accountId },
      id: 0,
      slaContract: null,
      name: "",
      cause: "",
      description: "",
      todo: "",
      factor: 0.0,
      responseTime: 0,
      responsePeriod: "HOUR",
      solutionTime: 0,
      solutionPeriod: "HOUR",
    };
  };
  const [level, setLevel] = useState(newLevel());

  useEffect(() => {
    if (accountId) {
      refresh();
    }
  }, [accountId]);

  const refresh = () => {
    SlaContractLevelService.findByAccount(
      accountId,
      findBySlaContractOnSuccess,
      findBySlaContractOnError
    );
  };

  const findBySlaContractOnSuccess = (data) => {
    setLevelList(data.list);
  };

  const findBySlaContractOnError = (data) => {};

  const addButtonClicked = () => {
    if (!isRoleExists) {
      setSubscriptionError(true);
    } else {
      setLevel(newLevel());
      setIsDialogOpen(true);
    }
  };

  const handleChange = (prop, val) => {
    setLevel({ ...level, [prop]: val });
  };

  const saveNewLevel = () => {
    SlaContractLevelService.save(level, saveOnSuccess, saveOnError);
  };
  const saveOnSuccess = (data) => {
    refresh();
    setIsDialogOpen(false);
  };

  const saveOnError = (data) => {};

  const onDoubleClick = (row) => {
    setLevel(row);
    setIsDialogOpen(true);
  };

  const deleteLevel = (data) => {
    faDialog({
      description: t("Kaydı silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        SlaContractLevelService.delete(data, deleteOnSuccess, deleteOnError);
      })
      .catch((err) => console.log("error:", err));
  };

  const deleteOnSuccess = (data) => {
    refresh();
  };

  const deleteOnError = (data) => {};

  const columns = [
    {
      field: "name",
      label: t("LEVEL_NAME"),
      align: "center",
      width: "20%",
    },
    {
      field: "cause",
      label: t("LEVEL_CAUSE"),
      align: "center",
      width: "20%",
    },
    {
      field: "description",
      label: t("LEVEL_DESCRIPTION"),
      align: "center",
      width: "20%",
    },
    {
      field: "todo",
      label: t("TODO"),
      align: "center",
      width: "20%",
    },
    {
      field: "factor",
      label: t("FACTOR"),
      align: "center",
      width: "20%",
    },
    {
      field: "responseTime",
      label: t("RESPONSE_TIME"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        if (row.responseTime)
          return row.responseTime + " " + t("" + row.responsePeriod);
        else return null;
      },
    },
    {
      field: "solutionTime",
      label: t("SOLUTION_TIME"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        if (row.solutionTime)
          return row.solutionTime + " " + t("" + row.solutionPeriod);
        else return null;
      },
    },
    {
      field: "id",
      label: t("OPERATION"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        return (
          <IconButton
            onClick={() => deleteLevel(row)}
            size="small"
            color="primary"
            aria-label={t("SIL")}
          >
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        );
      },
    },
  ];

  const renderDialog = () => {
    return (
      <FaDialog
        //loading={loading}
        showSaveButton
        faOpen={isDialogOpen}
        title={t("EDIT_LEVEL")}
        faOnSubmit={saveNewLevel}
        faHandleClose={() => {
          setIsDialogOpen(false);
        }}
      >
        <Grid item container spacing={1}>
          <Grid item xs={12}>
            <FaInput
              required
              label={t("LEVEL_NAME")}
              value={level.name}
              inputProps={{ maxLength: 255 }}
              onChange={(event) => {
                handleChange("name", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              label={t("LEVEL_CAUSE")}
              value={level.cause}
              inputProps={{ maxLength: 255 }}
              onChange={(event) => {
                handleChange("cause", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              faType="description"
              label={t("LEVEL_DESCRIPTION")}
              value={level.description}
              inputProps={{ maxLength: 4000 }}
              onChange={(event) => {
                handleChange("description", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              faType="description"
              label={t("TODO")}
              value={level.todo}
              inputProps={{ maxLength: 4000 }}
              onChange={(event) => {
                handleChange("todo", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              faType="number"
              awnum="decimal-6"
              label={t("FACTOR")}
              value={level.factor}
              onChange={(event) => {
                handleChange("factor", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FaInput
              faType="number"
              label={t("RESPONSE_TIME")}
              value={level.responseTime}
              onChange={(event) => {
                handleChange("responseTime", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <SlaTimePeriodSelect
              value={level.responsePeriod}
              onChange={(data) => handleChange("responsePeriod", data)}
            />
          </Grid>
          <Grid item xs={6}>
            <FaInput
              faType="number"
              label={t("SOLUTION_TIME")}
              value={level.solutionTime}
              onChange={(event) => {
                handleChange("solutionTime", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <SlaTimePeriodSelect
              value={level.solutionPeriod}
              onChange={(data) => handleChange("solutionPeriod", data)}
            />
          </Grid>
        </Grid>
      </FaDialog>
    );
  };
  return (
    <>
      {renderDialog()}
      {subscriptionError && (
        <FeatureNotAllowed
          open={subscriptionError}
          onClose={() => {
            setSubscriptionError(false);
          }}
        />
      )}
      <Card>
        <CardHeader
          action={
            <IconButton aria-label="settings" onClick={addButtonClicked}>
              <Add />
            </IconButton>
          }
          title={t("ACCOUNT_SLA_LEVELS")}
          data-fa-section="ACCOUNT_SLA_LEVELS"
        />
      </Card>
      <ThingsDataTable
        data={levelList}
        columns={columns}
        ref={tableRef}
        showDeleteButton={false}
        showHeaderText={false}
        headerText={t("SLA_LEVELS")}
        showPaging={false}
        faOnDoubleClick={onDoubleClick}
      />
    </>
  );
}
