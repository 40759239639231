import { Grid, Typography } from "@material-ui/core";
import { Check, Loop } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import SystemAccountButtonGroup from "components/buttonGroup/SystemAccountButtonGroup";
import ActionTypeSelect from "components/select/ActionTypeSelect";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaButton, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { MESSAGE_TEMPLATE } from "../../services/faApi";
import MessageTemplateEdit from "./MessageTemplateEdit";

export default function MessageTemplate() {
  const tableRef = useRef();
  const { t } = useTranslation(["messageTemplate", "components"], { i18n });
  const dispatch = useDispatch();
  const faDialog = useDialog();

  const accountId = localStorage.accountId;
  const accountName = localStorage.accountName;

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const labelClasses = labelStyles();

  const columns = [
    {
      field: "actionType",
      label: t("İşlem"),
      align: "center",
      width: "15%",
      valueFormatter: (value, row) => {
        return (
          value && <b>{t("ACTION_TYPE." + value, { ns: "components" })}</b>
        );
      },
    },
    {
      field: "account.id",
      label: t("Değişiklik"),
      align: "center",
      width: "5%",
      valueFormatter: (value, row) => {
        return row.account && <Loop />;
      },
    },
    {
      field: "description",
      label: t("Açıklama"),
      align: "center",
      width: "15%",
    },
    {
      field: "emailTitle",
      label: t("E-Posta Konusu"),
      align: "center",
      width: "15%",
    },
    {
      field: "smsTemplate",
      label: t("SMS Şablonu"),
      align: "center",
      width: "20%",
    },
    {
      field: "pushTemplate",
      label: t("Push Notification Şablonu"),
      align: "center",
      width: "20%",
    },
    {
      field: "template",
      label: t("Şablon"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        if (value === true) return <Check />;
        else return null;
      },
    },
  ];

  const newSearchForm = () => {
    return {
      accountId: accountId,
      actionType: null,
      description: null,
      systemType: null,
    };
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `messageTemplateDetail/${row.id}`,
      breadCrumbKey: `/messageTemplateDetail/:id`,
    };
    history.push(tmpTo);
  };

  const messageTemplateOnChange = (data) => {
    const tmpTo = {
      pathname: `messageTemplateDetail/${data.id}`,
      breadCrumbKey: `/messageTemplateDetail/:id`,
    };
    history.push(tmpTo);
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <MessageTemplateEdit
          onChange={messageTemplateOnChange}
          open={isEditFormOpen}
          onClose={() => {
            setIsEditFormOpen(false);
          }}
        />
        <Grid item container spacing={1} style={{ marginBottom: 5 }}>
          <Grid item xs={4}>
            <Grid item xs={12} className={labelClasses.LabelLastRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("ACCOUNT", { ns: "components" })}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <SystemAccountButtonGroup
                label={accountName}
                value={searchForm.systemType}
                onChange={(data) => {
                  const temp = { ...searchForm, systemType: data };
                  setSearchForm(temp);
                  tableRef.current.search(temp);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <ThingsDataTable
          searchUrl={MESSAGE_TEMPLATE.search}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={1} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                setIsEditFormOpen(true);
              }}
              startIcon={<AddIcon />}
            >
              {t("Ekle")}
            </FaButton>
          </Grid>
          <Grid item xs={5}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faClassName="appSearchBar"
            >
              <ActionTypeSelect
                label={t("İşlem Tipi")}
                value={searchForm.actionType}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "actionType",
                    data
                  );
                }}
              />

              <FaInput
                label={t("Açıklama")}
                value={searchForm.description}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "description",
                    event.target.value
                  );
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
