import { faGlobe, faMap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import i18n from "../../i18n";

export default function ThingsMapButtonGroup() {
  let history = useHistory();

  const { t } = useTranslation("components", { i18n });
  const [pathname, setPathname] = useState();

  useEffect(() => {
    setPathname(window.location.pathname);
  }, []);

  const goToThingsMap = () => {
    const tmpTo = {
      pathname: `/mapThings`,
      breadCrumbKey: `/mapThings`,
    };
    history.push(tmpTo);
  };

  const goToIndoorMap = () => {
    const tmpTo = {
      pathname: `/mapIndoor`,
      breadCrumbKey: `/mapIndoor`,
    };
    history.push(tmpTo);
  };

  return (
    <>
      {pathname && (
        <ButtonGroup
          fullWidth
          size="small"
          variant="outlined"
          color="primary"
          aria-label="contained primary button group"
        >
          <Button
            color={"primary"}
            variant={pathname === "/mapThings" ? "contained" : "outlined"}
            onClick={() => {
              goToThingsMap();
            }}
            startIcon={<FontAwesomeIcon icon={faGlobe} />}
          >
            {t("Harita")}
          </Button>
          <Button
            color={"primary"}
            variant={pathname === "/mapIndoor" ? "contained" : "outlined"}
            onClick={() => {
              goToIndoorMap();
            }}
            startIcon={<FontAwesomeIcon icon={faMap} />}
          >
            {t("Binalar/İç mekanlar")}
          </Button>
        </ButtonGroup>
      )}
    </>
  );
}
