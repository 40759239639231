import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IssueService } from "services/issue/issueService";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import { smartTimeDiff } from "../../services/utils";
import OverviewFlow from "./overviewFlow";

export default function IssueTaskHistory(props) {
  const { taskList, issue, taskOnChange, accPreferences } = props;
  const { t } = useTranslation(["issueDetail", "components"], { i18n });
  const [issueLogList, setIssueLogList] = useState([]);

  const labelClasses = labelStyles();

  useEffect(() => {
    if (issue?.id) {
      IssueService.getIssueLogs(issue.id, setIssueLogList, () => {});
    }
  }, [issue]);

  return (
    <Card style={{ minHeight: 250 }}>
      <CardHeader title={t("TASK_HISTORY")} data-fa-section="TASK_HISTORY" />
      <CardContent>
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody>
              {taskList?.map((task, index) => (
                <TableRow key={index}>
                  <TableCell className={labelClasses.LabelValue}>
                    {smartTimeDiff(task.taskDate)}
                  </TableCell>
                  <TableCell className={labelClasses.LabelValue}>
                    {t("TASK_STATUS_" + task?.status, { ns: "components" })}
                  </TableCell>
                  <TableCell className={labelClasses.LabelValue}>
                    {task?.user?.fullname}
                    {task?.userGroup?.name}
                  </TableCell>
                  <TableCell className={labelClasses.LabelValue}>
                    {task.description}
                  </TableCell>
                </TableRow>
              ))}
              {(!taskList || taskList.length === 0) && (
                <TableRow>
                  <TableCell className={labelClasses.LabelValue}>
                    {t("NO_ASSIGNED_TASK")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <OverviewFlow
          issue={issue}
          taskList={taskList}
          issueLogList={issueLogList}
        />
      </CardContent>
    </Card>
  );
}
