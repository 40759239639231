import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@material-ui/core";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaPieChart } from "react-base-fa/dist/fa/facharts";
import { useTranslation } from "react-i18next";
import { ThingStatisticService } from "services/statistic/statisticService";
import { getDateTimeWFormat, isTNF } from "services/utils";
import { CHART_COLORS } from "services/utils/ChartConstants";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";

const InteractionCountsByPrintQr = forwardRef((props, ref) => {
  const { faType, ...other } = props;
  const { t } = useTranslation("customerInteraction", { i18n });
  const accountId = localStorage.accountId;
  const labelClasses = labelStyles();

  const [thingSearchForm, setThingSearchForm] = useState({
    accountId: accountId,
  });
  const [values, setValues] = useState(null);
  const [selectedThingName, setSelectedThingName] = useState(null);
  const [selectedThingData, setSelectedThingData] = useState(null);

  const config = {
    default: {
      containerHeight: "25rem",
      chartHeight: "18rem",
      margin: { top: 15, right: 0, bottom: 5, left: 250 },
      title: true,
      legends: [
        {
          anchor: "left",
          direction: "column",
          justify: false,
          translateX: -250,
          translateY: 0,
          itemWidth: 100,
          itemHeight: 10,
          itemsSpacing: 3,
          symbolSize: 15,
          itemDirection: "left-to-right",
        },
      ],
    },
    thing: {
      containerHeight: "25rem",
      chartHeight: "18rem",
      margin: { top: 15, right: 0, bottom: 5, left: -250 },
      title: true,
      legends: [
        {
          anchor: "right",
          direction: "column",
          justify: false,
          translateX: 0,
          translateY: 0,
          itemWidth: 100,
          itemHeight: 10,
          itemsSpacing: 3,
          symbolSize: 15,
          itemDirection: "right-to-left",
        },
      ],
    },
  };

  const mergedProps = faType
    ? { ...config.default, ...config[faType], ...other }
    : { ...config.default, ...other };

  useImperativeHandle(ref, () => ({
    refreshTable(searchForm) {
      if (searchForm) {
        ThingStatisticService.getThingsByOperation(
          { ...searchForm, operation: "SCAN_QR" },
          onSuccess,
          (error) => {}
        );
      }
    },
  }));

  useEffect(() => {
    if (selectedThingName && thingSearchForm && thingSearchForm.thingId) {
      ThingStatisticService.getInteractionCountsByOperation(
        thingSearchForm,
        onSuccessByThing,
        () => {}
      );
    }
  }, [selectedThingName, thingSearchForm]);

  const onSuccessByThing = (data) => {
    let tmp = data.map((item) => {
      console.log(item);
      return {
        ...item,
        id: t("CUSTOMER_INTERACTION_OPERATION." + item.label, {
          ns: "components",
        }),
        label: t("CUSTOMER_INTERACTION_OPERATION." + item.label, {
          ns: "components",
        }),
        minDate: item.minDate,
        maxDate: item.maxDate,
      };
    });

    // piechartta labelların üst üste gelmemesi için
    // veriler bir büyük bir küçük değer olacak şekilde sıralandı.
    let mixedTmp = [];
    let i = 0;
    while (i !== Math.ceil(tmp.length / 2)) {
      mixedTmp.push(tmp[i]);
      if (i + 1 === tmp.length - i) break;
      mixedTmp.push(tmp[tmp.length - 1 - i]);
      i += 1;
    }
    setSelectedThingData(mixedTmp);
  };

  const onSuccess = (data) => {
    let tmp = data.map((item) => {
      return {
        ...item,
        id: item.identifier,
        label: item.identifier,
        value: item.count,
      };
    });
    // piechartta labelların üst üste gelmemesi için
    // veriler bir büyük bir küçük değer olacak şekilde sıralandı.
    let mixedTmp = [];
    let i = 0;
    while (i !== Math.ceil(tmp.length / 2)) {
      mixedTmp.push(tmp[i]);
      if (i + 1 === tmp.length - i) break;
      mixedTmp.push(tmp[tmp.length - 1 - i]);
      i += 1;
    }
    setValues(mixedTmp);
  };

  return (
    <>
      {values && (
        <Card
          style={{ height: mergedProps.containerHeight, overflowY: "auto" }}
        >
          {mergedProps.title && (
            <CardHeader
              title={isTNF(
                t("INTERACTION_COUNT_BY_SCAN_QR"),
                t("INTERACTION_COUNT_BY_SCAN_QR_T2C")
              )}
              style={{ height: "40px" }}
            />
          )}
          <CardContent>
            <Grid container>
              <Grid
                item
                lg={6}
                xs={12}
                data-fa-section="INTERACTION_COUNTS_BY_SCAN_QR"
              >
                <div>
                  <FaPieChart
                    data={values}
                    height={mergedProps.chartHeight}
                    //width="300px"
                    colors={CHART_COLORS}
                    enableArcLabels={true}
                    enableArcLinkLabels={false}
                    innerRadius={0.5}
                    padAngle={1}
                    cornerRadius={5}
                    margin={mergedProps.margin}
                    arcLinkLabelsDiagonalLength={68}
                    arcLinkLabelsStraightLength={16}
                    isInteractive={true}
                    activeInnerRadiusOffset={7}
                    activeOuterRadiusOffset={7}
                    onClick={(node, event) => {
                      setSelectedThingName(node.data.identifier);
                      setThingSearchForm({
                        ...thingSearchForm,
                        thingId: node.data.thingId,
                      });
                    }}
                    tension={100}
                    legends={mergedProps.legends}
                  />
                </div>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: "80%", weight: "100%" }}
                >
                  {selectedThingData && selectedThingName ? (
                    <>
                      <Grid item xs={12}>
                        <div>
                          <FaPieChart
                            data={selectedThingData}
                            height={config.thing.chartHeight}
                            //width="300px"
                            colors={CHART_COLORS}
                            enableArcLabels={true}
                            enableArcLinkLabels={false}
                            innerRadius={0.5}
                            padAngle={1}
                            cornerRadius={5}
                            margin={config.thing.margin}
                            arcLinkLabelsDiagonalLength={68}
                            arcLinkLabelsStraightLength={16}
                            isInteractive={true}
                            activeInnerRadiusOffset={7}
                            activeOuterRadiusOffset={7}
                            tension={100}
                            legends={config.thing.legends}
                            tooltip={(e) => {
                              let data = e.datum.data;
                              return (
                                <div
                                  style={{
                                    padding: "9px 12px",
                                    background: "#e6e6e6",
                                  }}
                                >
                                  <div
                                    style={{
                                      color: e.datum.color,
                                    }}
                                  >
                                    <strong>{data.label}</strong>
                                  </div>
                                  <div>
                                    İlk etkileşim tarihi:{" "}
                                    <strong>
                                      {getDateTimeWFormat(data.minDate)}
                                    </strong>
                                  </div>
                                  <div>
                                    Son etkileşim tarihi:{" "}
                                    <strong>
                                      {getDateTimeWFormat(data.maxDate)}
                                    </strong>
                                  </div>
                                </div>
                              );
                            }}
                          />
                        </div>
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <Typography className={labelClasses.LabelHeader}>
                        {isTNF("Etkileşim noktasına ", "Varlığa ")}
                        ait etkileşim dağılımını görmek için etkişeşim noktası
                        seçiniz.
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <Grid item xs={12} style={{ marginRight: "35%" }}>
                  {selectedThingName && selectedThingData ? (
                    <Typography style={{ fontSize: 13, textAlign: "center" }}>
                      {selectedThingName}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
});
export default InteractionCountsByPrintQr;
