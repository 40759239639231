import {
  CardHeader,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import { useTranslation } from "react-i18next";
import { getDateTimeWFormat, IssueDesc } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";

export default function SolutionIssues(props) {
  const { solution, onIssueDetail } = props;
  const { t } = useTranslation("solutions", { i18n });
  const labelClasses = labelStyles();

  return (
    <>
      <Card>
        <CardHeader
          title={t("CLOSED_ISSUES")}
          data-fa-section="CLOSED_ISSUES"
        />
        <CardContent>
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                {solution?.issues.map((issue, index) => (
                  <TableRow key={index}>
                    <TableCell className={labelClasses.LabelValue}>
                      <Link
                        onClick={() => {
                          onIssueDetail(issue);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {getDateTimeWFormat(issue.notificationDate)}
                      </Link>
                    </TableCell>
                    <TableCell className={labelClasses.LabelValue}>
                      <Link
                        onClick={() => {
                          onIssueDetail(issue);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {IssueDesc(issue)}
                      </Link>
                    </TableCell>
                    <TableCell className={labelClasses.LabelValue}>
                      <Link
                        onClick={() => {
                          onIssueDetail(issue);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {issue.notifier}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
                {solution?.tasks.map((task, index) => (
                  <TableRow key={index} className={labelClasses.LabelValue}>
                    <TableCell className={labelClasses.LabelValue}>
                      {getDateTimeWFormat(task.taskDate)}
                    </TableCell>
                    <TableCell className={labelClasses.LabelValue}>
                      {task.description}
                    </TableCell>
                    <TableCell className={labelClasses.LabelValue}>
                      {task.user.fullname}
                    </TableCell>
                    <TableCell className={labelClasses.LabelValue}></TableCell>
                  </TableRow>
                ))}
                {(solution.issues == null || solution.issues.length === 0) &&
                (solution.tasks == null || solution.tasks.length === 0) ? (
                  <TableRow>
                    <TableCell colSpan={4} className={labelClasses.LabelValue}>
                      Kapatılan sorun bulunmamaktadır.
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
}
