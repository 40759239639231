import React, { useEffect } from "react";
import ReactFlow, {
  Background,
  Controls,
  MarkerType,
  useEdgesState,
  useNodesState,
} from "react-flow-renderer";
import { useTranslation } from "react-i18next";
import { ThingDesc } from "services/utils";
import i18n from "../../i18n";

const ThingRelationsOverviewFlow = (props) => {
  const { t } = useTranslation("issueDetail", { i18n });

  const { thing, thingRelations } = props;

  const [nodes, setNodes, onNodesChange] = useNodesState();
  const [edges, setEdges, onEdgesChange] = useEdgesState();

  useEffect(() => {
    let initialNodes = [];
    let initialPositions = { x: 200, y: 30 };
    if (!!thing.id) {
      let rootNode = {
        id: thing.id.toString(),
        type: "input",
        data: { label: ThingDesc(thing) },
        position: { x: initialPositions.x + 26, y: initialPositions.y },
        style: { width: "20%", height: "20%", borderRadius: "50%" },
      };
      initialNodes.push(rootNode);
    }

    if (!!thingRelations && thingRelations.length > 0) {
      let positions = { x: initialPositions.x - 300, y: initialPositions.y + 150 };

      thingRelations
        .slice(0)
        .reverse()
        .map((thingRelation, index) => {

            let relatedThing = thingRelation.sourceThing.id === thing.id ? thingRelation.targetThing : thingRelation.sourceThing;
            positions.x = positions.x + 200;
          
            let node = {
              id: relatedThing.id.toString(),
              type: "default",
              data: { label: ThingDesc(relatedThing) },
              position: { x: positions.x, y: positions.y },
            };
            initialNodes.push(node);
        });
    }

    console.log({initialNodes});
    setNodes(initialNodes);

    if (!!thingRelations && thingRelations.length > 0) {

      var tempInitialEdges = [];
      thingRelations.forEach(thingRelation => {
        let edge = {
          id: thingRelation.sourceThing.id + "_" + thingRelation.targetThing.id,
          //source: thingRelation.sourceThing.id.toString(),
          //target: thingRelation.targetThing.id.toString(),
          source: thing.id.toString(),
          target: thingRelation.sourceThing.id === thing.id ? thingRelation.targetThing.id.toString() : thingRelation.sourceThing.id.toString(),
          label: thingRelation.sourceThing.id === thing.id
          ? thingRelation.relationType.nameSource
          : thingRelation.relationType.nameTarget,
          type: "smart",
          markerEnd: {
            type: MarkerType.ArrowClosed,
          },
        };

        tempInitialEdges.push(edge);
      });

      setEdges(tempInitialEdges);
      console.log({tempInitialEdges});
    }
  }, [thingRelations]);

  return (
    <div style={{ width: "100%", height: 400 }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
      >
        <Controls />
        <Background color="#aaa" gap={16} />
      </ReactFlow>
    </div>
  );
};

export default ThingRelationsOverviewFlow;
