import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function TaskTypeSelect(props) {
  const { value, onChange, required } = props;
  const classes = useStyles();
  const { t } = useTranslation("components", { i18n });

  return (
    <FormControl variant="outlined" className={classes.w100} size="small">
      <InputLabel>{t("TASK_TYPE.")}</InputLabel>
      <Select
        // classes={{
        //   outlined: classes.padding10,
        // }}
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        required={required}
        label={t("TASK_TYPE.")}
      >
        <MenuItem value={null}>...</MenuItem>
        <MenuItem value={"TASK"}>{t("TASK_TYPE.TASK")}</MenuItem>
        <MenuItem value={"PERIODIC_TASK"}>{t("TASK_TYPE.PERIODIC")}</MenuItem>
        <MenuItem value={"STOCK_TAKING"}>
          {t("TASK_TYPE.STOCK_TAKING")}
        </MenuItem>
        <MenuItem value={"PATROL"}>{t("TASK_TYPE.PATROL")}</MenuItem>
      </Select>
    </FormControl>
  );
}
