import { Box, Button, Paper, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import parse from "html-react-parser";
import i18n from "i18n";
import PropTypes from "prop-types";
import React, { useState } from "react";
// import useStyles from "react-base-fa/dist/fa/faUnAuthPages/style";
import { useTranslation } from "react-i18next";
import "react-puzzle-confirm/react-puzzle-confirm.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useHistory, useLocation } from "react-router-dom";
import LandingHeader from "../landing/header/LandingHeader";
import { useStyles } from "../ReportIssueStyle";

export default function SurveyResult(props) {
  const { appLogo, miniAppLogo } = props;

  const classes = useStyles();
  const { t } = useTranslation("report", { i18n });
  const history = useHistory();
  const location = useLocation();
  const [publicThing, setPublicThing] = useState(location?.state?.publicThing);
  const [endMessage, setEndMessage] = useState(location?.state?.endMessage);

  const renderEndMessage = () => {
    return (
      endMessage && (
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
          style={{ paddingBottom: 50 }}
        >
          <Grid item xs={12} style={{ paddingTop: 20 }}>
            {parse(endMessage)}
          </Grid>
        </Grid>
      )
    );
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ height: "100vh", width: "100vw" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        component={Paper}
        elevation={6}
        style={{
          height: "100vh",
          width: "100vw",
          margin: "10px",
          paddingLeft: "5px",
          paddingRight: "5px",
        }}
      >
        <div>
          <Grid
            item
            container
            spacing={3}
            lg={8}
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              lg={10}
              xs={12}
              style={{ textAlign: "center", marginBottom: 92 }}
            >
              <LandingHeader
                publicThing={publicThing}
                miniAppLogo={miniAppLogo}
              />
            </Grid>
          </Grid>
          <div>
            {renderEndMessage()}
            <Typography variant="h5">
              {t("Ankete katıldığınız için teşekkürler.")}
            </Typography>
          </div>
          {publicThing && (
            <div className={classes.footer}>
              <div className={classes.actionButtons2Div}>
                {
                  <div style={{ flex: 1 }}>
                    <Button
                      className={classes.backButton}
                      onClick={() => {
                        let tmpTo = {
                          pathname: `/q/${publicThing.tid}`,
                          state: {
                            publicThing: {
                              ...publicThing,
                            },
                          },
                        };
                        history.push(tmpTo);
                      }}
                    >
                      {t("Geri Dön")}
                    </Button>
                  </div>
                }
              </div>
            </div>
          )}
        </div>
      </Box>
    </Box>
  );
}

SurveyResult.propTypes = {
  loginPath: PropTypes.string,
  appLogo: PropTypes.any,
};
