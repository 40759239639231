import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { CUSTOMER_LIST } from "./faApi";

export const CustomerListService = {
  save: (customerList, callback, errorCallback) => {
    let reqBody = {
      ...customerList,
    };

    let req = requests.getSimplePostRequest(CUSTOMER_LIST.save, reqBody);

    FaAxios(req)
      .then((response) => {
        console.log("response => ", response);
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (customerList, callback, errorCallback) => {
    let reqBody = {
      ...customerList,
    };

    let req = requests.getSimplePostRequest(CUSTOMER_LIST.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        console.log("error:", error);
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(CUSTOMER_LIST.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
};
