import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { CONTROL_ITEM } from "../../services/faApi";

export const ControlItemService = {
  save: (controlItem, callback, errorCallback) => {
    let reqBody = {
      ...controlItem,
    };

    let req = requests.getSimplePostRequest(CONTROL_ITEM.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (controlItem, callback, errorCallback) => {
    let reqBody = {
      ...controlItem,
    };

    let req = requests.getSimplePostRequest(CONTROL_ITEM.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findByThingClass: (thingClassId, accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      CONTROL_ITEM.findByThingClass +
        "?accountId=" +
        accountId +
        "&thingClassId=" +
        thingClassId +
        "&all=true"
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findByPatrolDef: (patrolDefId, accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      CONTROL_ITEM.findByPatrolDef +
        "?accountId=" +
        accountId +
        "&patrolDefId=" +
        patrolDefId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  enable: (controlItemOverrideId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(CONTROL_ITEM.enable, {
      controlItemOverrideId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  disable: (controlItemId, thingClassId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(CONTROL_ITEM.disable, {
      controlItemId,
      thingClassId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  moveUp: (accountId, thingClassId, controlItemId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(CONTROL_ITEM.moveUp, {
      accountId,
      controlItemId,
      thingClassId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  moveDown: (
    accountId,
    thingClassId,
    controlItemId,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(CONTROL_ITEM.moveDown, {
      accountId,
      controlItemId,
      thingClassId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
