import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import i18n from "../../../i18n";
import ThingsSteps from "../ThingsSteps";
import TutorialElement from "../TutorialElement";

export default function AccountNotificationTutorial() {
  const { code } = useParams();

  const stepsRef = useRef();

  const { t } = useTranslation("tutorialAccountNotification", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            <b>Bildirim seçeneklerinin</b> düzenlendiği ve yönetildiği alandır.
          </TutorialElement>
        ),
        comp: "AccountNotifications",
      },
      {
        element: "[data-fa-section='NOTIFICATION_PREFERENCES_BUTTON']",
        intro: (
          <TutorialElement icon="click">
            <b>Bildirim seçeneklerini</b> düzenlemek için tıklayın.
          </TutorialElement>
        ),
        comp: "AccountNotifications",
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "[data-fa-section='ACCOUNT_PREFERENCES_DIALOG']",
        intro: (
          <TutorialElement>
            <p>
              <ul>
                <li>
                  {" "}
                  Görev atama sonrası bildirim gönder ve Görev kapatma sonrası
                  bildirim gönder alanlarından{" "}
                  <b>Her Zaman Sor, Sorma ve Kabul Et</b>
                  seçeneklerinden birini seçin.
                </li>
                <li>
                  Sorun Bildirimi Kullanıcı Doğrulama Modeli, Öneri Kullanıcı
                  Doğrulama Modeli, Beğeni Bildirimi Kullanıcı Doğrulama Modeli
                  ve Kullanıcı İçeriği Kullanıcı Doğrulama Modeli alanlarından
                  <b> SMS, E-posta, SMS {"&"} E-posta ve Captcha</b>{" "}
                  seçeneklerinden birini seçin.
                </li>
                <li>
                  Mükerrer bildirim, öneri ve beğeni girişinin önüne geçmek için
                  Ardışık bildirim bekleme süresi, Ardışık öneri bekleme süresi
                  ve Ardışık beğeni bekleme süresi alanlarına dakika olarak{" "}
                  <b>süre</b>
                  girin.
                </li>
                <li>
                  Müşteri 'beni hatırla' süresi alanına dakika olarak{" "}
                  <b>süre</b> girin.
                </li>
                <li>
                  Bildirimde varlık seçimi yapmadan devam et (varsayılan) ve
                  Bildirim kapanışında kullanıcı doğrulaması için düğmelere
                  tıklayın.
                </li>
                <li>
                  Bildirim yapan kişinin bilgilerini göster alanında Evet ve
                  Hayır seçeneklerinden birini seçin ve <b>Kaydet</b>’e basın.
                </li>
              </ul>
            </p>
          </TutorialElement>
        ),
        comp: "AccountNotifications",
        onBeforeChange: (index) => {
          $(
            "[data-fa-section='NOTIFICATION_PREFERENCES_BUTTON']"
          )[0].firstElementChild.click();
          stepsRef.current.updateStepElement(index);
        },
      },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={"account_notification"}
        steps={steps}
        ref={stepsRef}
        onStepChange={setStepIndex}
        afterComplete={() => {
          var el = $("[aria-label='close']");
          if (el != null && el[0] != null) el[0].click();
        }}
      />
    </div>
  );
}
