import Grid from "@material-ui/core/Grid";
import OrganizationAutoComplete from "components/autocomplete/OrganizationAutoComplete";
import UserAndGroupAutoComplete from "components/autocomplete/UserAndGroupAutoComplete";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  FaDatePicker,
  FaDialog,
  FaFileUpload,
  FaInput,
} from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { getThingsPackage } from "services/utils";

import i18n from "../../i18n";
import { ThingService } from "../../services/thing/thingService";
import { useStyles } from "./ThingDetailCardStyle";

const ThingOwnerEdit = forwardRef((props, ref) => {
  const { thing, onUpdate } = props;
  const THINGS_PACKAGE = getThingsPackage();
  const { t } = useTranslation(["thingDetail", THINGS_PACKAGE], { i18n });
  const classes = useStyles();

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsThingOwnerFormOpen(true);
    },
  }));

  const [files, setFiles] = useState([]);
  const [thingChange, setThingChange] = useState({});

  useEffect(() => {
    setThingChange({
      thingId: thing.id,
      ownerUser: thing.ownerUser,
      ownerUserGroup: thing.ownerUserGroup,
      user: thing.user,
      userGroup: thing.userGroup,
      mtncUser: thing.mtncUser,
      mtncUserGroup: thing.mtncUserGroup,
      ownerUserText: thing.ownerUserText,
      userText: thing.userText,
      mtncUserText: thing.mtncUserText,
      organization: thing.organization,
      date: new Date(),
      description: "",
      document: null,
      account: thing.account,
    });
  }, [thing]);

  const [isThingOwnerFormOpen, setIsThingOwnerFormOpen] = useState(false);

  const updateThingOwner = () => {
    ThingService.changeOwner(thingChange, updateSuccessful, updateFail);
  };

  const updateSuccessful = (newData) => {
    onUpdate(newData);
    setIsThingOwnerFormOpen(false);
  };

  const updateFail = () => {};

  const filesOnChange = (files) => {
    setFiles(files);

    setThingChange({
      ...thingChange,
      document: files != null && files.length > 0 ? files[0] : null,
    });
  };

  return (
    <FaDialog
      title={"Sorumluluk Belirleme"}
      faOpen={isThingOwnerFormOpen}
      faOnSubmit={updateThingOwner}
      showSaveButton
      faHandleClose={(data) => {
        setIsThingOwnerFormOpen(data);
      }}
      faMaxWidth="sm"
    >
      <Grid item container spacing={1} data-fa-section="THING_OWNER_DIALOG">
        <Grid item xs={6}>
          <FaDatePicker
            faType="date"
            label={t("Değişiklik tarihi")}
            style={{ minHeight: 65 }}
            value={thingChange.date}
            faOnChange={(val) => {
              setThingChange({
                ...thingChange,
                date: val,
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FaInput
            value={thingChange.description}
            label={t("DESCRIPTION")}
            onChange={(event) => {
              setThingChange({
                ...thingChange,
                description: event.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <UserAndGroupAutoComplete
            label={t("THING_OWNER", { ns: THINGS_PACKAGE })}
            value={{
              user: thingChange?.ownerUser,
              userGroup: thingChange?.ownerUserGroup,
            }}
            accountId={thingChange?.account?.id}
            onChange={(data) => {
              setThingChange({
                ...thingChange,
                ownerUser: data && data.user ? data.user : null,
                ownerUserGroup: data && data.userGroup ? data.userGroup : null,
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <UserAndGroupAutoComplete
            label={t("THING_MTNC", { ns: THINGS_PACKAGE })}
            value={{
              user: thingChange?.mtncUser,
              userGroup: thingChange?.mtncUserGroup,
            }}
            accountId={thingChange?.account?.id}
            onChange={(data) => {
              setThingChange({
                ...thingChange,
                mtncUser: data && data.user ? data.user : null,
                mtncUserGroup: data && data.userGroup ? data.userGroup : null,
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <UserAndGroupAutoComplete
            label={t("THING_USER", { ns: THINGS_PACKAGE })}
            value={{
              user: thingChange?.user,
              userGroup: thingChange?.userGroup,
            }}
            accountId={thingChange?.account?.id}
            onChange={(data) => {
              setThingChange({
                ...thingChange,
                user: data && data.user ? data.user : null,
                userGroup: data && data.userGroup ? data.userGroup : null,
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FaInput
            value={thingChange.userText}
            onChange={(event) => {
              setThingChange({
                ...thingChange,
                userText: event.target.value,
              });
            }}
            helperText={t(
              "Sistemde tanımlı olmayan kişiler için bu alanı kullanabilirsiniz"
            )}
          />
        </Grid>

        {THINGS_PACKAGE === "things2care" && (
          <Grid item xs={6}>
            <OrganizationAutoComplete
              label="Organizasyon"
              value={thingChange?.organization}
              accountId={thingChange?.account?.id}
              onChange={(data) => {
                setThingChange({
                  ...thingChange,
                  organization: data,
                });
              }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FaFileUpload
            maxFileSize={20000000}
            onChange={filesOnChange}
            initialFiles={files}
            filesLimit={1}
          />
        </Grid>
      </Grid>
    </FaDialog>
  );
});

export default ThingOwnerEdit;
