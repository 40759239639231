import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
} from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";
import { alpha, withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import OrganizationAutoComplete from "components/autocomplete/OrganizationAutoComplete";
import UserAutoComplete from "components/autocomplete/UserAutoComplete";
import {
  default as React,
  Suspense,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { OrganizationService } from "services/OrganizationService";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    "& .close": {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}))((props) => <TreeItem {...props} />);

const OrganizationTree = forwardRef((props, ref) => {
  //export default function OrganizationTree(props) {
  const { onSelect } = props;
  const tableRef = useRef();
  const { t } = useTranslation("organization", { i18n });
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const accountId = localStorage.accountId;

  const [loading, setLoading] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [organizationList, setOrganizationList] = useState([]);
  const [defaultExpandedIds, setDefaultExpandedIds] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  useImperativeHandle(ref, () => ({
    refreshTree() {
      refresh();
    },
  }));

  const newOrganization = () => {
    return {
      id: "",
      name: "",
      address: "",
      parent: null,
      responsibleUser: null,
      administrativeUnit: false,
    };
  };

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    setLoading(true);
    OrganizationService.findOrganization(
      accountId,
      null,
      findOrganizationOnSuccess,
      findOrganizationOnError
    );
  };

  const findOrganizationOnSuccess = (data) => {
    setOrganizationList(data.list);
    const ids = data.list.map((item) => {
      return item.id;
    });
    setDefaultExpandedIds(ids);
    setLoading(false);
  };

  const findOrganizationOnError = () => {
    showAlert("Yüklenemedi", "error");
    setLoading(false);
  };

  const [organization, setOrganization] = useState(newOrganization());

  const handleChange = (prop) => (event) => {
    setOrganization({ ...organization, [prop]: event.target.value });
  };

  const handleChangeV = (prop, value) => {
    setOrganization({ ...organization, [prop]: value });
  };
  const saveSuccess = (data) => {
    showAlert(!organization.id ? t("SAVED") : t("EDITED"), "success");
    setIsEditFormOpen(false);
    setOrganization(newOrganization());
    refresh();
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const saveThingOrganization = () => {
    setLoading(true);
    OrganizationService.save(
      {
        ...organization,
        account: { id: accountId },
      },
      saveSuccess,
      saveError
    );
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    setOrganization(row);
    setIsEditFormOpen(true);
  };

  const renderTreeItems = (parentId) => {
    if (organizationList) {
      if (parentId == null) {
        const filteredItems = organizationList.filter(
          (item) => item.parent == null
        );
        return filteredItems.map((item) => {
          return renderTreeItem(item);
        });
      } else {
        const filteredItems = organizationList.filter(
          (item) => item.parent != null && item.parent.id === parentId
        );
        return filteredItems.map((item) => {
          return renderTreeItem(item);
        });
      }
    }
  };

  const renderTreeItem = (item) => {
    return (
      <StyledTreeItem
        nodeId={item.id}
        label={item.administrativeUnit === true ? item.name + " *" : item.name}
        onClick={() => {
          setSelectedItem(item);
          onSelect(item);
        }}
      >
        {renderTreeItems(item.id)}
      </StyledTreeItem>
    );
  };

  const renderEditForm = () => {
    return (
      <>
        <FaDialog
          title={"Organizasyon Tanımlama"}
          faOpen={isEditFormOpen}
          faOnSubmit={saveThingOrganization}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            setIsEditFormOpen(data);
            setOrganization(newOrganization());
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <OrganizationAutoComplete
                label="Üst Organizasyon"
                required={true}
                value={organization.parent}
                accountId={accountId}
                onChange={(data) => {
                  setOrganization({
                    ...organization,
                    parent: data,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                required
                maxLength={100}
                label={t("NAME")}
                onChange={handleChange("name")}
                value={organization.name || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                maxLength={100}
                label={t("ADDRESS")}
                onChange={handleChange("address")}
                value={organization.address || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <UserAutoComplete
                value={organization.responsibleUser}
                accountId={accountId}
                powerUser={true}
                onChange={(data) => {
                  handleChangeV("responsibleUser", data);
                }}
                label={t("Organizasyon Sorumlusu")}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={organization.administrativeUnit}
                    onChange={(event) => {
                      setOrganization({
                        ...organization,
                        administrativeUnit: event.target.checked,
                      });
                    }}
                    color="primary"
                  />
                }
                label={t("Yönetim Birimi")}
              />
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {renderEditForm()}
        <Card style={{ minHeight: 100 }}>
          <CardHeader
            action={
              <IconButton aria-label="settings">
                <AddIcon
                  onClick={() => {
                    setIsEditFormOpen(true);
                    setOrganization({
                      ...newOrganization(),
                      parent: selectedItem,
                    });
                  }}
                />
              </IconButton>
            }
            data-fa-section="ORGANIZATION_TREE"
            title={t("ORGANIZATION_TREE")}
          />
          <CardContent>
            {defaultExpandedIds && defaultExpandedIds.length > 0 && (
              <TreeView
                defaultExpanded={[...defaultExpandedIds]}
                defaultCollapseIcon={<MinusSquare />}
                defaultExpandIcon={<PlusSquare />}
                defaultEndIcon={<CloseSquare />}
              >
                {renderTreeItems(null)}
              </TreeView>
            )}
          </CardContent>
        </Card>
      </Suspense>
    </>
  );
});
export default OrganizationTree;
