import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import FeatureNotAllowed from "features/subscriptions/FeatureNotAllowed";
import QRCode from "qrcode.react";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { FaButton } from "react-base-fa/dist/fa";
import { getRole } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ThingService } from "services/thing/thingService";
import { getDateWFormat, ThingDesc } from "services/utils";
import { ROLES } from "services/utils/Roles";
import { checkRole } from "services/utils/SecurityUtils";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import ThingCreate from "./ThingCreate";
import "./things.css";

const MyThings = forwardRef((props, ref) => {
  const accountId = localStorage.accountId;
  const [thingList, setThingList] = useState(null);
  const { t } = useTranslation("thingDetail", { i18n });
  const labelClasses = labelStyles();
  let history = useHistory();
  const role = useSelector(getRole);
  const [loading, setLoading] = useState(false);
  const dialogRef = useRef();
  const [subscriptionError, setSubscriptionError] = useState(false);

  useImperativeHandle(ref, () => ({
    getThingId() {
      return thingList[0].id;
    },
  }));

  useEffect(() => {
    const paging = { page: 0, size: 3, isAsc: false, sort: "createDate" };
    const searchForm = { accountId: accountId };
    ThingService.search({ ...searchForm }, paging, setThingList, (error) => {});
  }, []);

  const navigateThing = (thing) => {
    const tmpTo = {
      pathname: `thingDetail/${thing.id}`,
      breadCrumbKey: `/thingDetail/:id`,
      customLabel: ThingDesc(thing),
    };
    history.push(tmpTo);
  };

  const renderImage = (thing) => {
    if (thing.thumbnail != null)
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            alt="thing"
            src={`data:image/png;base64, ${thing.thumbnail}`}
            style={{
              width: "48px",
              height: "48px",
              marginRight: "10px",
              borderRadius: "15%",
            }}
          />{" "}
          <Typography className={labelClasses.LabelValue}>
            {thing.identifier}
          </Typography>
        </div>
      );
    else
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar
            variant="square"
            style={{
              width: "48px",
              height: "48px",
              marginRight: "10px",
              borderRadius: "15%",
            }}
          >
            {" "}
            {thing.identifier.charAt(0)}
          </Avatar>{" "}
          <Typography className={labelClasses.LabelValue}>
            {thing.identifier}
          </Typography>
        </div>
      );
  };

  const renderThing = (thing, index, addButton) => {
    return (
      <Grid item xs={12} md={6} lg={4}>
        <Card style={{ minHeight: 500 }} id={index} elevation={6}>
          <CardActionArea
            data-fa-button="MY_THING_CARD"
            onClick={() => {
              if (!!!addButton) navigateThing(thing);
            }}
          >
            <CardContent style={{ position: "relative" }}>
              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  {renderImage(thing)}
                </Grid>
                <Grid item xs={8}></Grid>

                <Grid item xs={12}>
                  <Grid item container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      className={labelClasses.LabelRow}
                      data-fa-label="Nesne Kimlik Numarası"
                    >
                      <Typography className={labelClasses.LabelHeader}>
                        {t("Nesne Kimlik Numarası")}
                      </Typography>
                      <Typography className={labelClasses.LabelValue}>
                        {thing?.thid}
                      </Typography>
                    </Grid>

                    {thing?.productType && (
                      <Grid
                        item
                        xs={12}
                        className={labelClasses.LabelRow}
                        data-fa-label="PRODUCT_TYPE"
                      >
                        <Typography className={labelClasses.LabelHeader}>
                          {t("TYPE")}
                        </Typography>
                        <Typography className={labelClasses.LabelValue}>
                          {t(
                            "components:THING_PRODUCT_TYPE." +
                              thing?.productType
                          )}
                        </Typography>
                      </Grid>
                    )}

                    <Grid
                      item
                      xs={12}
                      className={labelClasses.LabelRow}
                      data-fa-label="CLASS_NAME"
                    >
                      <Typography className={labelClasses.LabelHeader}>
                        {t("CLASS_NAME")}
                      </Typography>
                      <Typography className={labelClasses.LabelValue}>
                        {thing?.thingClass?.className}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      className={labelClasses.LabelRow}
                      data-fa-label="HASHTAG"
                    >
                      <Typography className={labelClasses.LabelHeader}>
                        {t("HASHTAG")}
                      </Typography>
                      <Typography className={labelClasses.LabelValue}>
                        {thing?.hashtag}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid item container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      className={labelClasses.LabelRow}
                      data-fa-label="DESCRIPTION"
                    >
                      <Typography className={labelClasses.LabelHeader}>
                        {t("DESCRIPTION")}
                      </Typography>
                      <Typography className={labelClasses.LabelValue}>
                        {thing?.description}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={labelClasses.LabelRow}
                      data-fa-label="PUBLIC_IDENTIFIER"
                    >
                      <Typography className={labelClasses.LabelHeader}>
                        {t("PUBLIC_IDENTIFIER")}
                      </Typography>
                      <Typography className={labelClasses.LabelValue}>
                        {thing?.publicIdentifier}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={labelClasses.LabelLastRow}
                      data-fa-label="SERVICE_START_DATE"
                    >
                      <Typography className={labelClasses.LabelHeader}>
                        {t("SERVICE_START_DATE")}
                      </Typography>
                      <Typography className={labelClasses.LabelValue}>
                        {getDateWFormat(thing?.serviceStartDate)}
                      </Typography>
                    </Grid>
                    {!!!addButton && (
                      <Grid
                        item
                        xs={12}
                        className={labelClasses.LabelLastRow}
                        data-fa-label="SERVICE_START_DATE"
                        style={{ textAlign: "center" }}
                      >
                        <QRCode
                          id="thingQr"
                          data-fa-input="MY_THING_QR"
                          value={thing.thidUrl ? thing.thidUrl : ""}
                          renderAs="svg"
                          size={100}
                          style={{
                            cursor: "pointer",
                            padding: 5,
                            border: "1px solid black",
                            borderRadius: "15%",
                          }}
                        />
                      </Grid>
                    )}
                    {addButton && (
                      <Grid
                        item
                        xs={12}
                        className={labelClasses.LabelLastRow}
                        style={{ textAlign: "center" }}
                      >
                        <FaButton
                          variant="contained"
                          disabled={loading}
                          color="primary"
                          size="medium"
                          faClick={onAddClick}
                          startIcon={<Add />}
                          data-fa-button="ADD"
                          style={{
                            marginTop: 35,
                            height: 100,
                            width: 100,
                          }}
                        >
                          {t("Yeni")}
                        </FaButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    );
  };

  const onThingCreate = (data) => {
    const tmpTo = {
      pathname: `thingDetail/${data.id}`,
      breadCrumbKey: `/thingDetail/:id`,
      customLabel: ThingDesc(data),
    };
    history.push(tmpTo);
  };

  const newThingRegisterRender = () => {
    return <ThingCreate ref={dialogRef} onCreate={onThingCreate}></ThingCreate>;
  };

  const onAddClick = () => {
    ThingService.checkAddNewThing(
      accountId,
      (data) => {
        if (data) {
          dialogRef.current.openDialog();
        } else {
          setSubscriptionError(true);
        }
      },
      (error) => {
        setSubscriptionError(true);
      }
    );
  };

  return (
    <div>
      {newThingRegisterRender()}
      {subscriptionError && (
        <FeatureNotAllowed
          open={subscriptionError}
          onClose={() => {
            setSubscriptionError(false);
          }}
        />
      )}
      <Grid item container spacing={1} data-fa-section="MY_THINGS">
        {thingList && thingList.map((item, index) => renderThing(item, index))}

        {checkRole(
          [
            ROLES.OPERATOR,
            ROLES.THINGMANAGER,
            ROLES.POWER_USER,
            ROLES.ACCOUNTOWNER,
          ],
          role
        ) && renderThing({ identifier: "" }, -1, true)}
      </Grid>

      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={2}
        mt={100}
      >
        <Grid item xs={2}></Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </div>
  );
});
export default MyThings;
