import { Card } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import IssueCreate from "features/issues/IssueCreate";
import React, { useRef } from "react";
import { FaButton } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import i18n from "../../../i18n";

export default function HomepageIssueReportButton(props) {
  const { size } = props;
  const { t } = useTranslation("issue", { i18n });
  const dialogRef = useRef();
  let history = useHistory();

  const dispatch = useDispatch();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const onIssueCreate = (data) => {
    showAlert("Bildiriminiz başarıyla iletilmiştir", "success");

    const tmpTo = {
      pathname: `issues`,
      breadCrumbKey: `/issues`,
      search:
        "?page=0&size=10&isAsc=false&sort=notificationDate&accountId=" +
        localStorage.accountId,
    };
    history.push(tmpTo);
  };

  return (
    <>
      <IssueCreate
        ref={dialogRef}
        onCreate={onIssueCreate}
        thing={null}
      ></IssueCreate>
      <Grid container item lg={size} xs={12}>
        <Grid item lg={12}>
          <Card style={{ height: "100%" }}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ height: "100%" }}
            >
              <FaButton
                variant="contained"
                color="primary"
                size="large"
                style={{ height: "60px", width: "500px" }}
                faClick={() => {
                  dialogRef.current.openDialog();
                }}
                startIcon={<AddIcon />}
                data-fa-button="REPORT_ISSUE"
              >
                {t("Yeni Sorun Bildir")}
              </FaButton>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
