import { FormControlLabel, IconButton, Switch } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { DoneAll, Edit, PlayCircleOutline } from "@material-ui/icons";
import { default as React, useEffect, useState } from "react";
import { FaButton, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { AccountService } from "services/AccountService";
import { StockTakingService } from "services/StockTakingService";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import { getDateTimeWFormat } from "../../services/utils";

export default function StockTakingDetailCard(props) {
  const { stockTaking, setStockTaking } = props;

  const labelClasses = labelStyles();
  let history = useHistory();
  const faDialog = useDialog();
  const { t } = useTranslation("stockTaking", { i18n });
  const { id } = useParams();
  const accountId = localStorage.accountId; //useSelector(getAccountId);

  const [loading, setLoading] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [stockTakingEdit, setStockTakingEdit] = useState(stockTaking);
  const [accountSubscription, setAccountSubscription] = useState(null);

  useEffect(() => {
    setStockTakingEdit({ ...stockTaking });
  }, [stockTaking]);

  useEffect(() => {
    AccountService.getSubscription(
      accountId,
      (data) => {
        setAccountSubscription(data);
      },
      (error) => {}
    );
  }, []);

  const complete = () => {
    faDialog({
      type: "input",
      okText: t("Sonlandır"),
      cancelText: t("İptal"),
      title: t("Sayımı sonlandır"),
      description: t(
        "Sayımı sonlandırmak istiyor musunuz? Kapatma açıklaması girebilirsiniz"
      ),
    })
      .then((endDescription) => {
        StockTakingService.complete(
          stockTaking.id,
          endDescription,
          completeOnSuccess,
          completeOnError
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const start = () => {
    faDialog({
      type: "confirm",
      okText: t("Başlat"),
      cancelText: t("İptal"),
      title: t("Sayımı başlat"),
      description: t("Sayımı başlatmak istiyor musunuz?"),
    })
      .then((confirmation) => {
        StockTakingService.start(
          stockTaking.id,
          (data) => {
            window.location.reload();
          },
          () => {}
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const completeOnSuccess = (data) => {
    window.location.reload();
  };

  const completeOnError = () => {};

  const editButtonClicked = () => {
    setIsEditFormOpen(true);
    setStockTakingEdit({ ...stockTaking });
  };

  const saveStockTaking = () => {
    setLoading(true);
    StockTakingService.save(
      {
        ...stockTakingEdit,
        account: { id: accountId },
      },
      (data) => {
        setIsEditFormOpen(false);
        setLoading(false);
        setStockTaking(data);
      },
      () => {}
    );
  };

  const handleChange = (prop) => (event) => {
    setStockTakingEdit({ ...stockTakingEdit, [prop]: event.target.value });
  };

  const handleChangeV = (prop, value) => {
    setStockTakingEdit({ ...stockTakingEdit, [prop]: value });
  };

  const editFormRender = () => {
    return (
      <>
        <FaDialog
          title={"Sayım Tanımlama"}
          faOpen={isEditFormOpen}
          faOnSubmit={saveStockTaking}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            setIsEditFormOpen(data);
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <FaInput
                required
                maxLength={100}
                label={t("DESCRIPTION")}
                onChange={handleChange("description")}
                value={stockTakingEdit.description || ""}
              />
            </Grid>
            {accountSubscription && accountSubscription.blindCount === "YES" && (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={stockTakingEdit.blindCount}
                      onChange={(event) => {
                        handleChangeV("blindCount", event.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label={t("BLIND_COUNT")}
                />
              </Grid>
            )}
          </Grid>
        </FaDialog>
      </>
    );
  };

  return (
    <>
      {editFormRender()}
      <Card>
        <CardHeader
          action={
            <IconButton
              aria-label="settings"
              onClick={editButtonClicked}
              disabled={stockTaking.status !== "DRAFT"}
            >
              <Edit />
            </IconButton>
          }
          title={t("STOCK_TAKING_INFO")}
          data-fa-section="STOCK_TAKING_INFO"
        />
        <CardContent>
          <Grid item container spacing={0}>
            <Grid item xs={12}>
              <Grid
                item
                container
                spacing={1}
                style={{ marginLeft: 10, marginRight: 10 }}
              >
                <Grid item xs={12} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("DESCRIPTION")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {stockTaking.description}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("START_DATE")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {getDateTimeWFormat(stockTaking.startDate)}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("END_DATE")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {stockTaking.endDate &&
                      getDateTimeWFormat(stockTaking.endDate)}
                  </Typography>
                </Grid>
                {accountSubscription &&
                  accountSubscription.blindCount === "YES" && (
                    <Grid item xs={12} className={labelClasses.LabelRow}>
                      <Typography className={labelClasses.LabelHeader}>
                        {t("BLIND_COUNT")}
                      </Typography>
                      <Typography className={labelClasses.LabelValue}>
                        {stockTaking.blindCount === true ? t("YES") : t("NO")}
                      </Typography>
                    </Grid>
                  )}
                <Grid item xs={12} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("STATUS")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {t("" + stockTaking.status)}
                  </Typography>
                </Grid>

                {stockTaking.status === "ACTIVE" && (
                  <Grid item xs={12} className={labelClasses.LabelLastRow}>
                    <FaButton
                      style={{ width: "25%" }}
                      variant="contained"
                      size="small"
                      color="primary"
                      faClick={() => complete()}
                      startIcon={<DoneAll />}
                      data-fa-button="COMPLETE_STOCK_TAKING"
                    >
                      {t("COMPLETE_STOCK_TAKING")}
                    </FaButton>
                  </Grid>
                )}
                {stockTaking.status === "DRAFT" && (
                  <Grid item xs={12} className={labelClasses.LabelLastRow}>
                    <FaButton
                      style={{ width: "25%" }}
                      variant="contained"
                      size="small"
                      color="primary"
                      faClick={() => start()}
                      startIcon={<PlayCircleOutline />}
                      data-fa-button="START_STOCK_TAKING"
                    >
                      {t("START_STOCK_TAKING")}
                    </FaButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
