import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import CheckIcon from "@material-ui/icons/Check";
import UserDetail from "components/detail/UserDetail";
import ThingsDataTable from "components/thingsDataTable";
import React, { useRef, useState } from "react";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "serviceWorker";
import { ThingDesc, isTNF } from "services/utils";
import i18n from "../../i18n";
import { THING } from "../../services/faApi";
import "./things.css";

export default function ThingList(props) {
  const { thingClassId } = props;

  const { t } = useTranslation(["things", "errorMessages"], { i18n });
  const tableRef = useRef();
  let history = useHistory();
  let queryParams = qsParse(history.location.search);
  queryParams["accountId"] = localStorage.accountId;

  const accountId = localStorage.accountId;

  const newSearchForm = () => {
    let result = {
      thingClass: null,
      thingClassId: thingClassId,
      thingClassList: [],
      thingClassIdList: [],
      description: null,
      thingCode: null,
      hashtag: null,
      vendor: null,
      vendorId: null,
      vendorList: [],
      vendorIdList: [],
      summary: "",
      accountId: accountId,
      status: null,
      createDateValue: null,
      createDateStart: null,
      createDateEnd: null,
      images: true,
      openIssues: null,
      organizationList: [],
      organizationIdList: [],
      locationList: [],
      locationIdList: [],
      ownerUser: null,
      ownerUserGroup: null,
      mtncUser: null,
      mtncUserGroup: null,
      user: null,
      userGroup: null,
      ownerUserId: null,
      ownerUserGroupId: null,
      mtncUserId: null,
      mtncUserGroupId: null,
      userId: null,
      userGroupId: null,
      thid: null,
      archive: false,
    };

    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const columns = [
    {
      field: "identifier",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      width: "40%",
      valueFormatter: (value, row) => {
        //if(row.thumbnail != null)
        //FIXME public url haline getirildi
        if (row.thumbnailUrl != null) {
          console.log("row.thumbnailUrl", row.thumbnailUrl);
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={
                  BASE_URL +
                  THING.thumbnail +
                  "/" +
                  row.id +
                  "/" +
                  row.thumbnailUrl
                }
                style={{
                  width: "48px",
                  height: "40px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              />{" "}
              {value}
            </div>
          );
        } else if (row.thumbnail != null)
          //return <div style={{display: "flex", alignItems: "center"}}><img src={BASE_URL + THING.getImage + "?thingId=0" + "&objectId=" + row.defaultThumbnail } style={{width: "48px", height: "40px", marginRight: "10px"}} /> {value}</div>
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                alt="thing"
                src={`data:image/png;base64, ${row.thumbnail}`}
                style={{
                  width: "48px",
                  height: "48px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              />{" "}
              {value}
            </div>
          );
        else
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="square"
                style={{
                  width: "48px",
                  height: "48px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              >
                {" "}
                {value.charAt(0)}
              </Avatar>{" "}
              {value}
            </div>
          );
      },
    },
    {
      field: "organization",
      label: t("ORGANIZATION"),
      align: "center",
      width: "20%",
      inner: "name",
      valueFormatter: (value) => {
        return value?.name;
      },
    },
    {
      field: "ownerUser",
      label: t("OWNER"),
      align: "center",
      width: "20%",
      inner: "fullname",
      valueFormatter: (value) => {
        return <UserDetail value={value} type="USER" />;
      },
    },
    {
      field: "createDate",
      label: t("CREATE_DATE"),
      align: "center",
      width: "10%",
      type: "smartDate",
    },
    {
      field: "openIssueCount",
      label: t("OPEN_ISSUES"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        if (value && value > 0)
          return (
            <Badge
              badgeContent={value}
              color="secondary"
              style={{ paddingLeft: 12 }}
              overlap="rectangular"
            ></Badge>
          );
        return <CheckIcon />;
      },
    },
  ];

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `thingDetail/${row.id}`,
      breadCrumbKey: `/thingDetail/:id`,
      customLabel: ThingDesc(row),
    };
    history.push(tmpTo);
  };

  return (
    <>
      <ThingsDataTable
        searchUrl={THING.search + "?accountId=" + localStorage.accountId}
        exportUrl={THING.export + "?accountId=" + localStorage.accountId}
        searchForm={searchForm}
        columns={columns}
        ref={tableRef}
        showDeleteButton={false}
        showHeaderText={false}
        headerText={t("VARLIK_LISTESI")}
        faOnDoubleClick={onDoubleClick}
        data-fa-list="List"
        queryParams={{
          page: 0,
          size: 10,
          isAsc: false,
          sort: "createDate",
          accountId: localStorage.accountId,
        }}
      />
    </>
  );
}
