import { Card, CardContent, CardHeader, Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import LocationTree from "features/thingDetail/LocationTree";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Map, { Layer, Popup, Source } from "react-map-gl";
import { useLocation } from "react-router-dom";
import { CustomerInteractionService } from "services/CustomerInteractionService";
import { LocationDetailService } from "services/LocationDetailService";
import i18n from "../../i18n";

import {
  clusterCountLayer,
  clusterLayer,
  indoorHeatmapLayer,
  unclusteredPointLayer,
} from "../thingsMap/layers";
import ControlPanel from "./controlPanel";
import { emptyMapStyle } from "./emptyMapStyle";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoiYmlsYWxha2luY2kiLCJhIjoiY2thcDJhb2RoMHdoZzJ6cGZnOHZ6cDFicCJ9._lPdrre7P6yDWV2F3vbwpA"; // Set your mapbox token here

const geolocateStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  margin: 10,
};
export const parseLocation = (gpsLocation) => {
  if (gpsLocation && gpsLocation.length > 3) {
    let parsedData = JSON.parse(gpsLocation);
    if (parsedData.x && parsedData.y) {
      return { lat: parsedData.x, lng: parsedData.y };
    }
  }

  return null;
};
function filterFeaturesByDay(featureCollection, time) {
  const date = new Date(time);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const features = featureCollection.features.filter((feature) => {
    const featureDate = new Date(feature.date);
    return (
      featureDate.getFullYear() === year &&
      featureDate.getMonth() === month &&
      featureDate.getDate() === day
    );
  });
  return { type: "FeatureCollection", features };
}
export default function CustomerInteractionIndoorMap(props) {
  const { t } = useTranslation("customerInteraction", { i18n });
  const mapRef = useRef();
  const location = useLocation();

  const [popupInfo, setPopupInfo] = useState(null);
  const img = new Image();
  const [selectedLocationDetail, setSelectedLocationDetail] = useState(null);
  const [selectedLocationDetailMap, setSelectedLocationDetailMap] =
    useState(null);

  const accountId = localStorage.accountId;
  const [unique, setUnique] = useState(false);
  const [coordinateBoundries, setCoordinateBoundries] = useState({
    maxWidth: 5,
    maxHeight: 5,
  });
  const [viewport, setViewPort] = useState({
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    bearing: 0,
    pitch: 0,
  });

  const [geojson, setGeojson] = useState({
    type: "FeatureCollection",
    features: [],
  });

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(null);
  const [allDays, setAllDays] = useState(true);
  const [selectedTime, setSelectedTime] = useState(0);
  const [mapType, setMapType] = useState("heatMap");

  useEffect(() => {
    if (selectedLocationDetailMap) {
      img.src = `data:image/png;base64, ${selectedLocationDetailMap}`;
    }
  }, [selectedLocationDetailMap]);

  img.onload = () => {
    getCoordinateBoundries(img.height, img.width);
  };

  const findCustomerInteractionOnSuccess = (data) => {
    let list = [];
    let total = 0;
    data.forEach((indoorLocation) => {
      let length = data.filter(
        (x) => x.indoorLocation === indoorLocation
      ).length;
      list.push({
        indoorLocation: indoorLocation,
        count: length,
      });
      total += length;
    });

    list.forEach((item) => {
      item.normalizedValue0to1 = item.count / total;
    });

    const maxDate = Math.max(
      ...data.map((element) => {
        if (element?.date) return new Date(element.date).getTime();
      })
    );

    const minDate = Math.min(
      ...data.map((element) => {
        if (element?.date) return new Date(element.date).getTime();
      })
    );

    setStartDate(minDate ? minDate : null);
    setEndDate(maxDate ? maxDate : null);

    const temp = list
      .filter((x) => x.indoorLocation)
      .map((item, index) => {
        var location = parseLocation(item.indoorLocation.indoorLocation);
        let locs = calculateLocation(location.lat, location.lng);
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [locs.x, locs.y],
          },
          properties: {
            name: item.className,
          },
          date: item.indoorLocation.date,
        };
      });

    setGeojson({
      type: "FeatureCollection",
      features: temp,
    });
  };

  const data = useMemo(() => {
    return allDays ? geojson : filterFeaturesByDay(geojson, selectedTime);
  }, [geojson, allDays, selectedTime]);

  const calculateLocation = (x, y) => {
    let xCor =
      -coordinateBoundries.maxWidth +
      (x / 100) * (coordinateBoundries.maxWidth * 2);

    let yCor =
      coordinateBoundries.maxHeight -
      (y / 100) * (coordinateBoundries.maxHeight * 2);

    return { x: xCor, y: yCor };
  };

  const parseLocation = (gpsLocation) => {
    if (gpsLocation && gpsLocation.length > 3) {
      let parsedData = JSON.parse(gpsLocation);
      if (parsedData.x != null && parsedData.y != null) {
        return { lat: parsedData.x, lng: parsedData.y };
      } else {
      }
    } else {
    }

    return null;
  };
  function heatMapColorforValue(value) {
    var h = (1.0 - value) * 240;
    return "hsl(" + h + ", 100%, 50%)";
  }

  useEffect(() => {
    if (selectedLocationDetail && coordinateBoundries.maxWidth) {
      showAllInteractions();
    }
  }, [
    selectedLocationDetail,
    unique,
    coordinateBoundries,
    coordinateBoundries.maxWidth,
  ]);

  const showAllInteractions = () => {
    const paging = { page: 0, size: 10000, isAsc: false, sort: "date" };
    CustomerInteractionService.search(
      {
        ...location.state.searchForm,
        locationDetailId: selectedLocationDetail?.id,
        unique: unique,
      },
      paging,
      findCustomerInteractionOnSuccess,
      (error) => {}
    );
  };

  const locationDetailSelected = (item) => {
    setSelectedLocationDetail(item);

    LocationDetailService.downloadDocument(
      item.id,
      (data) => {
        setSelectedLocationDetailMap(data.content);
      },
      () => {}
    );
  };

  const getCoordinateBoundries = (imageHeight, imageWidth) => {
    let maxHeight = 10.0; // mapin max değeri
    let ratio = imageHeight / maxHeight;
    let maxWidth = imageWidth / ratio;
    setCoordinateBoundries({ maxWidth: maxWidth, maxHeight: maxHeight });
  };

  return (
    <Grid item container spacing={1}>
      <Grid item xs={3}>
        <Card elevation={6} data-fa-section="INDOOR_LOCATIONS">
          <CardHeader title={t("İç Mekan")} />
          <CardContent style={{ minHeight: 700 }}>
            <LocationTree
              accountId={accountId}
              onChange={(data) => {
                locationDetailSelected(data);
              }}
              style={{ marginBottom: "2%" }}
            />
            <Divider />
            <div
              className="input"
              style={{ textAlign: "center", marginTop: "2%" }}
            >
              <label style={{ fontSize: 16 }}>
                Biricik kullanıcı etkileşimi
              </label>
              <input
                type="checkbox"
                name="unique"
                checked={unique}
                onChange={(evt) => setUnique(evt.target.checked)}
              />
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={9}>
        <Card elevation={6}>
          <CardHeader title={t("Etkileşim Noktaları")} />
          <CardContent>
            <Grid container>
              {selectedLocationDetailMap && (
                <>
                  <div></div> {/* Silince map de gözükmüyor */}
                  <Grid item xs={12} style={{ height: "70vh" }}>
                    <Map
                      {...viewport}
                      onMove={(evt) => setViewPort(evt.viewState)}
                      mapStyle={emptyMapStyle}
                      mapboxAccessToken={MAPBOX_TOKEN}
                      ref={mapRef}
                      maxBounds={[
                        [
                          -coordinateBoundries.maxWidth * 2,
                          -coordinateBoundries.maxHeight,
                        ],
                        [
                          coordinateBoundries.maxWidth * 2,
                          coordinateBoundries.maxHeight,
                        ],
                      ]}
                    >
                      <Source
                        id="room"
                        type="image"
                        url={`data:image/png;base64, ${selectedLocationDetailMap}`}
                        coordinates={[
                          [
                            -coordinateBoundries.maxWidth,
                            coordinateBoundries.maxHeight,
                          ],
                          [
                            coordinateBoundries.maxWidth,
                            coordinateBoundries.maxHeight,
                          ],
                          [
                            coordinateBoundries.maxWidth,
                            -coordinateBoundries.maxHeight,
                          ],
                          [
                            -coordinateBoundries.maxWidth,
                            -coordinateBoundries.maxHeight,
                          ],
                        ]}
                      >
                        <Layer
                          id="overlay"
                          source="map-source"
                          type="raster"
                          paint={{ "raster-opacity": 1 }}
                        />
                      </Source>

                      {mapType === "heatMap" && (
                        <Source id="things" type="geojson" data={data}>
                          <Layer {...indoorHeatmapLayer} />
                        </Source>
                      )}
                      {mapType === "numericMap" && (
                        <Source
                          id="things"
                          type="geojson"
                          data={data}
                          cluster={true}
                          clusterMaxZoom={14}
                          clusterRadius={50}
                        >
                          <Layer {...clusterLayer} />
                          <Layer {...clusterCountLayer} />

                          <Layer {...unclusteredPointLayer} />
                        </Source>
                      )}

                      {popupInfo && (
                        <Popup
                          anchor="top"
                          longitude={Number(popupInfo.x)}
                          latitude={Number(popupInfo.y)}
                          closeOnClick={false}
                          onClose={() => setPopupInfo(null)}
                        ></Popup>
                      )}
                    </Map>
                  </Grid>
                  <Grid item xs={12} style={{ minHeight: 86 }}>
                    <ControlPanel
                      startTime={startDate}
                      endTime={endDate}
                      selectedTime={selectedTime}
                      allDays={allDays}
                      onChangeTime={setSelectedTime}
                      onChangeAllDays={setAllDays}
                      unique={unique}
                      onChangeUnique={setUnique}
                      mapType={mapType}
                      onChangeMapType={setMapType}
                    />
                  </Grid>
                </>
              )}

              {!selectedLocationDetailMap && (
                <label>Lütfen bir iç mekan planı seçiniz.</label>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

CustomerInteractionIndoorMap.propTypes = {
  searchForm: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
