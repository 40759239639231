import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { DYNAMIC_FORM } from "../../services/faApi";

export const DynamicFormService = {
  find: (name, id1, id2, callback, errorCallback) => {
    let reqBody = {
      name: name,
      id1: id1,
      id2: id2,
      page: 0,
      size: 100,
    };

    let req = requests.getSimpleGetRequest(DYNAMIC_FORM.find, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  create: (name, id1, id2, callback, errorCallback) => {
    let dynamicForm = {
      name: name,
      id1: id1,
      id2: id2,
    };

    let req = requests.getSimplePostRequest(DYNAMIC_FORM.save, {
      ...dynamicForm,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  save: (dynamicForm, callback, errorCallback) => {
    let reqBody = {
      ...dynamicForm,
    };

    let req = requests.getSimplePostRequest(DYNAMIC_FORM.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (dynamicForm, callback, errorCallback) => {
    let reqBody = {
      ...dynamicForm,
    };

    let req = requests.getSimplePostRequest(DYNAMIC_FORM.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  enable: (dynamicFormOverrideId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(DYNAMIC_FORM.enable, {
      dynamicFormOverrideId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  disable: (dynamicFormId, name, id1, id2, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(DYNAMIC_FORM.disable, {
      dynamicFormId,
      name,
      id1,
      id2,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  moveUp: (
    accountId,
    dynamicFormId,
    name,
    id1,
    id2,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(DYNAMIC_FORM.moveUp, {
      accountId,
      dynamicFormId,
      name,
      id1,
      id2,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  moveDown: (
    accountId,
    dynamicFormId,
    name,
    id1,
    id2,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(DYNAMIC_FORM.moveDown, {
      accountId,
      dynamicFormId,
      name,
      id1,
      id2,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findFormByName: (name, accountId, classId, callback, errorCallback) => {
    let reqBody = {
      name: name,
      accountId: accountId,
      classId: classId,
    };

    let req = requests.getSimpleGetRequest(
      DYNAMIC_FORM.findFormByName,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
