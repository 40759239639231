import PropTypes from "prop-types";
import React from "react";
import { APP, LOCALSTORAGE } from "services/utils/Constants";
import LoginBase from "./LoginBase";

export default function LoginTF(props) {
  const {
    appLogo,
    requestUrl,
    align,
    backGroundImage,
    signUpPath,
    fgPasswordPath,
    usernameLogin,
    userPhoneNumberLogin,
  } = props;

  localStorage.setItem(LOCALSTORAGE.APP, APP.TF);

  return (
    <LoginBase
      appLogo={appLogo}
      requestUrl={requestUrl}
      align={align}
      signUpPath={signUpPath}
      fgPasswordPath={fgPasswordPath}
      usernameLogin={usernameLogin}
      userPhoneNumberLogin={userPhoneNumberLogin}
      backGroundImage={process.env.PUBLIC_URL + "/assets/login_bg.jpg"}
    />
  );
}

LoginTF.propTypes = {
  appLogo: PropTypes.any,
  align: PropTypes.string,
  requestUrl: PropTypes.string,
  backGroundImage: PropTypes.any,
  usernameLogin: PropTypes.bool,
  signUpPath: PropTypes.string,
  fgPasswordPath: PropTypes.string,
};
