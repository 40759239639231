import { faQrcode, faSitemap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { FilterNone } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ThingClassAutoComplete from "components/autocomplete/ThingClassAutoComplete";
import ThingClassHashtag from "components/hashtag/ThingClassHashtag";
import Note from "components/noteCard";
import ThingClassDetailHelp from "features/help/ThingClassDetailHelp";
import PublicReportTemplate from "features/publicReportTemplate/PublicReportTemplate";
import Qs from "qs";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { isTNF } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { ThingClassService } from "../../services/thingClass/thingClassService";
import AdditionalFieldsEdit from "../additionalFields/additionalFieldsEdit";
import { useStyles } from "./style";
import ThingClassChildren from "./ThingClassChildren";
import ThingClassControlItemEdit from "./ThingClassControlItemEdit";
import ThingClassDocuments from "./ThingClassDocuments";
import ThingClassIssueClassEdit from "./ThingClassIssueClassEdit";
import ThingClassLikeClassEdit from "./ThingClassLikeClassEdit";
import ThingClassLink from "./ThingClassLink";
import ThingClassSuggestionClassEdit from "./ThingClassSuggestionClassEdit";

export default function ThingClassDetail() {
  const fileInputRef = useRef();
  const classes = useStyles();
  const labelClasses = labelStyles();
  const tableRef = useRef();
  const { t } = useTranslation(["thingClass", "errorMessages"], { i18n });
  const { id } = useParams();
  const accountId = localStorage.accountId; //useSelector(getAccountId);
  const faDialog = useDialog();
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState("");
  const [thingClass, setThingClass] = useState({});
  const [editThingClass, setEditThingClass] = useState({});
  const [copyThingClassName, setCopyThingClassName] = useState();
  const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false);

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    setLoading(true);
    ThingClassService.findById(id, findByIdOnSuccess, findByIdOnError);
  };

  const findByIdOnSuccess = (data) => {
    setThingClass(data);
    setLoading(false);
  };

  const findByIdOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };

  const onThingInfoUpdate = (newData) => {
    setThingClass(newData);
    showAlert("Etkileşim noktası bilgileri güncellendi", "success");
  };

  const editButtonClicked = () => {
    setEditThingClass({ ...thingClass });
    setIsModalOpen(true);
  };

  const thingsButtonClicked = () => {
    const sf = {
      sf: {
        thingClassList: [thingClass],
        thingClassIdList: [thingClass.id],
      },
    };

    const tmpTo = {
      pathname: `/things`,
      breadCrumbKey: `/things`,
      search:
        "?page=0&size=10&isAsc=false&sort=createDate&" +
        Qs.stringify(sf, { skipNulls: true }),
    };
    history.push(tmpTo);
  };

  const saveThingClass = () => {
    setLoading(true);
    ThingClassService.save(
      {
        ...editThingClass,
        account: { id: accountId },
      },
      saveSuccess,
      saveError
    );
  };

  const saveSuccess = (data) => {
    showAlert(!thingClass.id ? t("SAVED") : t("EDITED"), "success");
    setThingClass({ ...editThingClass });
    setIsModalOpen(false);
    setLoading(false);
  };

  const saveError = (data) => {
    showAlert(t("errorMessages:" + data.message), "error");
    setLoading(false);
  };

  const handleChange = (prop) => (event) => {
    setEditThingClass({ ...editThingClass, [prop]: event.target.value });
  };

  const handleUpload = (event) => {
    event.preventDefault();
    if (event?.target?.files?.length > 0) {
      ThingClassService.upload(
        thingClass.id,
        event.target.files[0],
        uploadOnSuccess,
        uploadOnError
      );
    }
  };

  const uploadOnSuccess = (data) => {
    window.location.reload();
  };

  const uploadOnError = (data) => {};

  const deleteImage = () => {
    ThingClassService.deleteFile(
      thingClass.id,
      deleteImageOnSuccess,
      deleteImageOnError
    );
  };

  const deleteImageOnSuccess = (data) => {
    setThingClass({ ...thingClass, thumbnail: null });
  };

  const deleteImageOnError = (data) => {};

  const goParent = () => {
    if (thingClass?.parent.id) {
      const tmpTo = {
        pathname: `${thingClass?.parent.id}`,
        breadCrumbKey: `/thingClassDetail/:id`,
      };
      history.push(tmpTo);
    }
  };

  const deleteThingClass = () => {
    faDialog({
      description: "Varlık tipini silmek istediğinize emin misiniz?",
      type: "confirm",
    })
      .then((confirmation) => {
        ThingClassService.delete(
          thingClass,
          (data) => {
            showAlert(t("Nokta tipi silindi"), "success");
            history.goBack();
          },
          (error) => {
            showAlert(t("Kayıt silinemedi, bağlı kayıtlar mevcut."), "error");
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const copyThingClass = () => {
    setCopyThingClassName(thingClass.className + " (kopya)");
    setIsCopyDialogOpen(true);
  };

  const submitCopyThingClass = () => {
    ThingClassService.copyThingClass(
      accountId,
      thingClass.id,
      copyThingClassName,
      copyOnSuccess,
      () => {}
    );
  };

  const copyOnSuccess = (data) => {
    showAlert(t("Nokta tipi kaydedildi"), "success");
    setIsCopyDialogOpen(false);
    const tmpTo = {
      pathname: `${data.id}`,
      breadCrumbKey: `/thingClassDetail/:id`,
    };
    history.push(tmpTo);
  };

  const renderCopyDialog = () => {
    return (
      <FaDialog
        title={"Etkileşim Noktası Tipini Kopyala"}
        faOpen={isCopyDialogOpen}
        faOnSubmit={submitCopyThingClass}
        showSaveButton
        faHandleClose={() => {
          setIsCopyDialogOpen(false);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <FaInput
              required
              maxLength={100}
              label={isTNF(t("GENERIC_CLASSNAME"), t("GENERIC_CLASSNAME_T2C"))}
              onChange={(e) => setCopyThingClassName(e.target.value)}
              value={copyThingClassName || ""}
            />
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  const renderDialog = () => {
    return (
      <FaDialog
        title={"Etkileşim Noktası Tipi Güncelle"}
        faOpen={isModalOpen}
        faOnSubmit={saveThingClass}
        showSaveButton
        faHandleClose={() => {
          setIsModalOpen(false);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <ThingClassAutoComplete
              accountId={accountId}
              value={editThingClass.parent}
              label={t("PARENT")}
              onChange={(data) => {
                setEditThingClass({
                  ...editThingClass,
                  parent: data,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              required
              maxLength={100}
              label={isTNF(t("GENERIC_CLASSNAME"), t("GENERIC_CLASSNAME_T2C"))}
              onChange={handleChange("className")}
              value={editThingClass.className || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <ThingClassHashtag
              label={t("DOMAIN")}
              accountId={accountId}
              value={editThingClass.domain}
              onChange={(data) => {
                setEditThingClass({
                  ...editThingClass,
                  domain: data,
                });
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button onClick={deleteThingClass} color="secondary">
            {t("Sil")}
          </Button>
        </Grid>
      </FaDialog>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {renderDialog()}
        {renderCopyDialog()}
        <div>
          <Grid container spacing={1}>
            <Grid item lg={6} xs={12}>
              <Grid container spacing={1}>
                <Grid item lg={12}>
                  <Card style={{ overflowY: "auto" }}>
                    <CardHeader
                      action={
                        <>
                          <ThingClassDetailHelp value="thingClassDetail.thingClassDetail" />
                          <IconButton
                            onClick={copyThingClass}
                            title={t("COPY")}
                          >
                            <FilterNone />
                          </IconButton>
                          <IconButton
                            onClick={thingsButtonClicked}
                            title="Etkileşim Noktaları"
                          >
                            <FontAwesomeIcon icon={faQrcode} />
                          </IconButton>
                          <IconButton
                            aria-label="settings"
                            onClick={editButtonClicked}
                            disabled={!!!thingClass.account}
                          >
                            <EditIcon />
                          </IconButton>
                        </>
                      }
                      title={isTNF(
                        t("THING_CLASS_INFO"),
                        t("THING_CLASS_INFO_T2C")
                      )}
                      data-fa-section="THING_CLASS_INFO"
                    />
                    <CardContent>
                      <Grid item container spacing={0}>
                        <Grid item md={12} xl={4}>
                          <div className={classes.image}>
                            <input
                              onChange={handleUpload}
                              ref={fileInputRef}
                              disabled={!!!thingClass.account}
                              type="file"
                              hidden
                            />

                            {!thingClass.thumbnail && (
                              <Button
                                onClick={() => {
                                  fileInputRef.current.click();
                                }}
                                startIcon={
                                  <Avatar
                                    style={{
                                      width: 200,
                                      height: 200,
                                    }}
                                    variant="square"
                                  >
                                    <FontAwesomeIcon icon={faSitemap} />
                                  </Avatar>
                                }
                                title="Değiştirmek için tıkla"
                                disabled={!!!thingClass.account}
                              ></Button>
                            )}
                            {thingClass.thumbnail && (
                              <>
                                <Button
                                  onClick={() => {
                                    fileInputRef.current.click();
                                  }}
                                  startIcon={
                                    <img
                                      src={`data:image/png;base64, ${thingClass.thumbnail}`}
                                      style={{ width: "200px" }}
                                    />
                                  }
                                  title="Değiştirmek için tıkla"
                                ></Button>
                                <Button
                                  color="primary"
                                  startIcon={<DeleteIcon />}
                                  onClick={() => {
                                    deleteImage();
                                  }}
                                  fullWidth={false}
                                  className={classes.imageDeleteButtom}
                                  title={t("Görseli Kaldır")}
                                ></Button>
                              </>
                            )}
                          </div>
                        </Grid>
                        <Grid item md={12} xl={8}>
                          <Grid
                            item
                            container
                            spacing={1}
                            style={{ marginLeft: 10, marginRight: 10 }}
                          >
                            <Grid
                              item
                              xs={12}
                              className={labelClasses.LabelRow}
                            >
                              <Typography className={labelClasses.LabelHeader}>
                                {isTNF(
                                  t("GENERIC_CLASSNAME"),
                                  t("GENERIC_CLASSNAME_T2C")
                                )}
                              </Typography>
                              <Typography className={labelClasses.LabelValue}>
                                {thingClass.className}
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              className={labelClasses.LabelRow}
                            >
                              <Typography className={labelClasses.LabelHeader}>
                                {t("PARENT")}
                              </Typography>
                              <Typography className={labelClasses.LabelValue}>
                                <Link onClick={goParent}>
                                  <Typography
                                    className={labelClasses.SimpleLink}
                                  >
                                    {thingClass?.parent?.className}
                                  </Typography>
                                </Link>
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              className={labelClasses.LabelRow}
                            >
                              <Typography className={labelClasses.LabelHeader}>
                                {t("DOMAIN")}
                              </Typography>
                              <Typography className={labelClasses.LabelValue}>
                                {thingClass.domain}
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              className={labelClasses.LabelLastRow}
                            >
                              <Typography className={labelClasses.LabelHeader}>
                                {t("ACCOUNT")}
                              </Typography>
                              <Typography className={labelClasses.LabelValue}>
                                {thingClass.account
                                  ? thingClass.account.fullname
                                  : t("SYSTEM")}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={6} xs={12}>
              <ThingClassChildren thingClass={thingClass} />
            </Grid>

            <Grid item lg={12} xs={12}>
              <AdditionalFieldsEdit dynamicName="THING_CLASS" dynamicId={id} />
            </Grid>

            <Grid item lg={6} xs={12}>
              <ThingClassIssueClassEdit thingClass={thingClass} />
            </Grid>

            <Grid item lg={6} xs={12}>
              <ThingClassControlItemEdit thingClass={thingClass} />
            </Grid>

            <Grid item lg={6} xs={12}>
              <ThingClassLikeClassEdit thingClass={thingClass} />
            </Grid>

            <Grid item lg={6} xs={12}>
              <ThingClassSuggestionClassEdit thingClass={thingClass} />
            </Grid>

            <Grid item lg={6} xs={12}>
              <ThingClassLink thingClass={thingClass} />
            </Grid>

            <Grid item lg={6} xs={12}>
              {thingClass && <Note thingClass={thingClass} />}
            </Grid>
            <Grid item lg={6} xs={12}>
              <ThingClassDocuments thingClass={thingClass} />
            </Grid>
            <Grid item lg={6} xs={12}>
              <PublicReportTemplate
                thingClass={thingClass}
                onUpdate={refresh}
              />
            </Grid>
          </Grid>
        </div>
      </Suspense>
    </>
  );
}
