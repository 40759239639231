import { IconButton } from "@material-ui/core";
import ThingsHelpIcon from "components/icons/ThingsHelpIcon";
import ThingsSteps from "features/tutorial/ThingsSteps";
import TutorialElement from "features/tutorial/TutorialElement";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function IssueCloseHelp(props) {
  const { value } = props;
  const { t } = useTranslation("help", { i18n });

  const stepsRef = useRef();
  const [open, setOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            Açık sorunun kapatıldığı alandır.
          </TutorialElement>
        ),
      },
      {
        element: "#statusSwitch",
        intro: (
          <TutorialElement>Sorunu kapatmak için tıklayın.</TutorialElement>
        ),
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/sorunKapatDialogKapatmaTipi.png"
                }
                width="584"
                height="300"
              ></img>
            </div>
            <br />
            <b>Kapatma Tipi</b>’ni seçin.
          </TutorialElement>
        ),
        tooltipClass: classes.issueCloseClass,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/sorunKapatDialogKapatmaTarihi.png"
                }
                width="584"
                height="300"
              ></img>
            </div>
            <br />
            <b>Kapatma Tarihi</b>'ni seçin.
          </TutorialElement>
        ),
        tooltipClass: classes.issueCloseClass,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/sorunKapatDialogAciklama.png"
                }
                width="584"
                height="300"
              ></img>
            </div>
            <br />
            İsteğe bağlı <b>Açıklama</b> girin ve <b>Sorunu Kapat</b>’a basın.
          </TutorialElement>
        ),
        tooltipClass: classes.issueCloseClass,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
    ]);
  }, []);

  return (
    <>
      {open && (
        <ThingsSteps
          code={value}
          steps={steps}
          ref={stepsRef}
          onStepChange={setStepIndex}
          afterComplete={() => {
            setOpen(true);
          }}
        />
      )}
      <IconButton
        aria-label="help"
        onClick={() => setOpen(true)}
        title={t("HELP")}
      >
        <ThingsHelpIcon />
      </IconButton>
    </>
  );
}

IssueCloseHelp.propTypes = {
  value: PropTypes.string,
};
