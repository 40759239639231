import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { UserContentService } from "services/UserContentService";
import { useStyles } from "../thingDetail/style";

export default function UserContentPhotosCarousel(props) {
  const { userContentId, images, setImages } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [loadingImages, setLoadingImages] = useState([]);

  const createCarouselItemImage = (item, index) => (
    <div key={index} className={classes.image}>
      {item.hdImage && (
        <img
          alt=""
          src={`data:image/png;base64, ${item.hdImage}`}
          style={{ maxHeight: "600px" }}
        />
      )}
    </div>
  );

  const baseChildren = <div>{images.map(createCarouselItemImage)}</div>;

  const customRenderThumb = (children) =>
    children.map((item, index) => {
      return (
        <img
          src={`data:image/png;base64, ${images[index].content}`}
          className={classes.thingImage}
          alt={item.object}
        />
      );
    });

  const customRenderItem = (item, props) => {
    if (props.isSelected === true) {
      if (
        !images[item.key].hdImage &&
        loadingImages.indexOf(item.key) < 0 &&
        loading === false
      ) {
        setLoading(true);
        setLoadingImages((prev) => {
          return [...prev, item.key];
        });
        UserContentService.downloadImage(
          userContentId,
          images[item.key].object,
          (data) => {
            onDownloadImageSuccess(data, item.key);
          },
          (error) => {
            setLoading(false);
          }
        );
      }
    }

    return <item.type {...item.props} {...props} />;
  };

  const onDownloadImageSuccess = (data, index) => {
    setLoading(false);
    setImages((prev) => {
      let row = { ...prev[index], hdImage: data.content };
      let imagesTmp = [...prev];
      imagesTmp[index] = row;

      return [...imagesTmp];
    });
  };

  return (
    <Carousel
      height="500px"
      showArrows={true}
      renderItem={customRenderItem}
      renderThumbs={customRenderThumb}
    >
      {baseChildren.props.children}
    </Carousel>
  );
}
