import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import CountryAutoComplete from "components/autocomplete/CountryAutoComplete";
import LanguageSelect from "components/select/LanguageSelect";
import TimeZoneSelect from "components/select/TimeZoneSelect";
import AccountDetailsHelp from "features/help/AccountDetailsHelp";
import React, { useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import { ACCOUNT } from "../../services/faApi";
import { useStyles } from "./AccountDetailsCardStyle";
import AccountPhoto from "./AccountPhoto";

export default function AccountDetailsCard(props) {
  const { accountDetails, thumbnail, onAccountUpdate } = props;
  const { t } = useTranslation(["accountDetails", "components"], { i18n });
  const classes = useStyles();

  const labelClasses = labelStyles();
  const [loading, setLoading] = useState(false);
  const [updateValues, setUpdateValues] = useState({ ...accountDetails });
  let history = useHistory();

  const dispatch = useDispatch();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [isAccountDetailsEditOpen, setIsAccountDetailsEditOpen] =
    useState(false);

  const editButtonClicked = () => {
    setUpdateValues({ ...accountDetails });
    setIsAccountDetailsEditOpen(true);
  };

  const saveAccountDetails = () => {
    setLoading(true);

    let reqBody = {
      ...updateValues,
    };
    let req = requests.getSimplePostRequest(ACCOUNT.update, reqBody);
    FaAxios(req)
      .then((res) => {
        showAlert("Hesap bilgileri güncellendi", "success");

        onAccountUpdate();
        setIsAccountDetailsEditOpen(false);
      })
      .catch((e) => {
        switch (e && e.response && e.response.status) {
          default:
            showAlert(t("errorMessages:UNEXPECTED_ERROR"), "error");
            break;
        }
        setLoading(false);
      });
  };

  const handleChange = (prop) => (event) => {
    setUpdateValues({ ...updateValues, [prop]: event.target.value });
  };

  const handleChangeV = (prop, value) => {
    setUpdateValues({ ...updateValues, [prop]: value });
  };

  const renderDialog = () => {
    return (
      <FaDialog
        title={"Hesap Bilgileri Güncelle"}
        faOpen={isAccountDetailsEditOpen}
        faOnSubmit={saveAccountDetails}
        showSaveButton
        faHandleClose={(data) => {
          setIsAccountDetailsEditOpen(false);
        }}
      >
        <Grid container spacing={1} data-fa-section="ACCOUNT_EDIT_DIALOG">
          <Grid item lg={12} xs={12}>
            <FaInput
              required
              id="fullname"
              label={t("fullname")}
              value={updateValues.fullname}
              onChange={handleChange("fullname")}
              name="fullname"
              autoComplete="fullname"
              inputProps={{
                maxLength: 255,
              }}
            />
          </Grid>
          <Grid item lg={6} xs={12}></Grid>
          <Grid item lg={6} xs={12}></Grid>

          <Grid item lg={12} xs={12}>
            <CountryAutoComplete
              required
              value={updateValues.country}
              onChange={(data) => {
                handleChangeV("country", data);
              }}
            />
          </Grid>

          <Grid item lg={12} xs={12}>
            <LanguageSelect
              required
              value={updateValues.language}
              onChange={(data) => {
                handleChangeV("language", data);
              }}
            />
          </Grid>

          <Grid item lg={12} xs={12}>
            <FaInput
              id="province"
              autoComplete="off"
              label={t("province")}
              value={updateValues.province}
              onChange={handleChange("province")}
              name="province"
              inputProps={{
                maxLength: 255,
              }}
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <FaInput
              id="adress"
              faType="description"
              autoComplete="off"
              label={t("adress")}
              value={updateValues.adress}
              onChange={handleChange("adress")}
              name="adress"
              inputProps={{
                maxLength: 255,
              }}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <TimeZoneSelect
              value={updateValues.timeZone}
              onChange={(data) => handleChangeV("timeZone", data)}
              inputProps={{
                placeholder: "Select Timezone...",
                name: "timeZone",
              }}
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <FaInput
              id="contactEmail"
              faType="email"
              autoComplete="off"
              label={t("contactEmail")}
              value={updateValues.contactEmail}
              onChange={handleChange("contactEmail")}
              name="contactEmail"
              inputProps={{
                maxLength: 255,
              }}
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <FaInput
              id="contactWww"
              label={t("contactWww")}
              value={updateValues.contactWww}
              onChange={handleChange("contactWww")}
              name="contactWww"
              inputProps={{
                maxLength: 255,
              }}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <FaInput
              select
              required
              id="acctype"
              autoComplete="off"
              multiline
              label={t("acctype")}
              value={updateValues.acctype}
              onChange={handleChange("acctype")}
              name="acctype"
            >
              <MenuItem value={"PERSONAL"}>{t("PERSONAL")}</MenuItem>
              <MenuItem value={"CORPORATE"}>{t("CORPORATE")}</MenuItem>
            </FaInput>
          </Grid>
          {updateValues.acctype === "CORPORATE" ? (
            <React.Fragment>
              <Grid item lg={6} xs={12}>
                <FaInput
                  required
                  id="taxnumber"
                  autoComplete="off"
                  label={t("taxnumber")}
                  value={updateValues.taxnumber}
                  onChange={handleChange("taxnumber")}
                  name="taxnumber"
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <FaInput
                  required
                  id="taxadmin"
                  autoComplete="off"
                  label={t("taxadmin")}
                  value={updateValues.taxadmin}
                  onChange={handleChange("taxadmin")}
                  name="taxadmin"
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <FaInput
                  required
                  id="workPlace"
                  autoComplete="off"
                  label={t("workPlace")}
                  value={updateValues.workPlace}
                  onChange={handleChange("workPlace")}
                  name="workPlace"
                />
              </Grid>
            </React.Fragment>
          ) : null}
        </Grid>
      </FaDialog>
    );
  };

  return (
    <>
      {renderDialog()}
      <Grid container spacing={1}>
        <Grid item xs={12} lg={3}>
          <AccountPhoto accountDetails={accountDetails} />
        </Grid>
        <Grid item xs={12} lg={9}>
          <Card style={{ minHeight: 330, overflowY: "auto" }}>
            <CardHeader
              action={
                <>
                  <AccountDetailsHelp value="accountDetails.details" />
                  <IconButton
                    aria-label="settings"
                    onClick={editButtonClicked}
                    data-fa-button="ACCOUNT_INFO_EDIT"
                  >
                    <EditIcon />
                  </IconButton>
                </>
              }
              data-fa-section="ACCOUNT_INFO"
              title={t("ACCOUNT_INFO")}
            />
            <CardContent>
              <Grid
                item
                container
                spacing={1}
                style={{ marginLeft: 10, marginRight: 10 }}
              >
                <Grid item xs={12} md={6} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("fullname")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {accountDetails.fullname}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("acctype")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {accountDetails.acctype && t("" + accountDetails.acctype)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("username")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {accountDetails?.owner?.fullname}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("country")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {accountDetails.country?.nameTr}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("language")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {accountDetails.language &&
                      t("components:LANGUAGE_" + accountDetails.language)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("province")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {accountDetails.province}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("adress")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {accountDetails.adress}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("TIMEZONE")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {accountDetails.timeZone
                      ? accountDetails.timeZone +
                        " " +
                        accountDetails.timeZoneOffset
                      : null}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={6} className={labelClasses.LabelLastRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("contactEmail")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {accountDetails.contactEmail}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} className={labelClasses.LabelLastRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("contactWww")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {accountDetails.contactWww}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
