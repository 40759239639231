import { Card, CardHeader, Grid } from "@material-ui/core";
import ThingDetail from "components/detail/ThingDetail";
import ThingsDataTable from "components/thingsDataTable";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getDateTimeWFormat, isTNF } from "services/utils";
import i18n from "../../../i18n";
import { SURVEY } from "../../../services/faApi";

export default function HomepageSurveyJoinList(props) {
  const tableRef = useRef();
  const { t } = useTranslation("survey", { i18n });
  const accountId = localStorage.accountId;
  const { id } = useParams();
  const { size } = props;

  const columns = [
    {
      field: "answerDate",
      label: t("ANSWER_DATE"),
      align: "center",
      width: "25%",
      valueFormatter: (val) => {
        return getDateTimeWFormat(val);
      },
    },
    {
      field: "survey",
      label: t("Anket"),
      align: "center",
      width: "25%",
      valueFormatter: (val) => {
        return val.name;
      },
    },
    {
      field: "thing",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      width: "25%",
      valueFormatter: (val) => {
        return <ThingDetail value={val} noLink={true} />;
      },
    },
    {
      field: "customer",
      inner: "name",
      label: t("CUSTOMER"),
      align: "center",
      width: "25%",
      valueFormatter: (val) => {
        if (val?.name && val?.surname) return val?.name + " " + val?.surname;
      },
    },
  ];

  const newSearchForm = () => {
    let start = new Date();
    start.setDate(start.getDate() - 7);
    start.setHours(0, 0, 0, 0);
    return {
      accountId: accountId,
      survey: null,
      surveyId: id,
      answerDateStart: start,
      answerDateEnd: null,
      thing: null,
      thingId: null,
    };
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  return (
    <>
      <Grid item lg={size} xs={12}>
        <Card>
          <CardHeader
            title={t("Anket Katılım Listesi")}
            data-fa-section="Anket Katılım Listesi"
          />

          <ThingsDataTable
            searchUrl={SURVEY.answerSearch}
            searchForm={searchForm}
            columns={columns}
            queryParams={{
              page: 0,
              size: 10,
              sort: "answerDate",
              isAsc: false,
            }}
            ref={tableRef}
            showHeaderText={false}
          />
        </Card>
      </Grid>
    </>
  );
}
