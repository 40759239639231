import { CardHeader } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { Edit } from "@material-ui/icons";
import ThingDetail from "components/detail/ThingDetail";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { CouponService } from "services/CouponService";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import CouponProductEdit from "./CouponProductEdit";
import { useStyles } from "./style";

export default function CouponProducts(props) {
  const { coupon } = props;

  const { t } = useTranslation("coupon", { i18n });
  const { id } = useParams();
  const dispatch = useDispatch();
  const tableRef = useRef();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [products, setProducts] = useState();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    refresh();
  }, [coupon]);

  const refresh = () => {
    setLoading(true);
    CouponService.findProducts(id, findProductsOnSuccess, findProductsOnError);
  };

  const findProductsOnSuccess = (data) => {
    setProducts(data);
    setLoading(false);
  };

  const findProductsOnError = (error) => {
    showAlert(error, "error");
    setLoading(false);
  };

  const renderProducts = () => {
    if (coupon == null || products == null || products.length === 0) {
      return <div>Kayıt bulunamadı.</div>;
    } else
      return (
        <div>
          {products?.map((product, index) => {
            if (product.thing) {
              return <ThingDetail value={product.thing} />;
            }
          })}
        </div>
      );
  };
  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <CouponProductEdit
          couponId={coupon.id}
          products={products}
          onSave={() => {
            refresh();
            setIsEditFormOpen(false);
          }}
          onClose={() => {
            setIsEditFormOpen(false);
          }}
          open={isEditFormOpen}
        />
        <Card style={{ minHeight: 204 }}>
          <CardHeader
            action={
              <IconButton
                aria-label="settings"
                data-fa-button="COUPON_PRODUCTS_EDIT"
                disabled={coupon.status === "ACTIVE"}
                onClick={() => {
                  setIsEditFormOpen(true);
                }}
              >
                <Edit />
              </IconButton>
            }
            title={t("COUPON_PRODUCTS")}
            data-fa-section="COUPON_PRODUCTS"
          />
          <CardContent>
            <Grid item container spacing={1}>
              {renderProducts()}
            </Grid>
          </CardContent>
        </Card>
      </Suspense>
    </>
  );
}
