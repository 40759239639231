import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Add } from "@material-ui/icons";
import UserDetail from "components/detail/UserDetail";
import ThingsEditIcon from "components/icons/ThingsEditIcon";
import ThingsOwnerHelp from "features/help/ThingsOwnerHelp";
import FeatureNotAllowed from "features/subscriptions/FeatureNotAllowed";
import { default as React, useEffect, useRef, useState } from "react";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getRole } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ThingTeamService } from "services/ThingTeamService";
import { getThingsPackage } from "services/utils";
import { ROLES } from "services/utils/Roles";
import { checkRole } from "services/utils/SecurityUtils";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import { useStyles } from "./style";
import ThingOrganizationEdit from "./ThingOrganizationEdit";
import ThingOwnerEdit from "./ThingOwnerEdit";
import ThingTeamEdit from "./ThingTeamEdit";

export default function ThingOwner(props) {
  const { thing, onUpdate, isEditable } = props;
  const THINGS_PACKAGE = getThingsPackage();
  const { t } = useTranslation(["thingDetail", THINGS_PACKAGE], { i18n });
  const labelClasses = labelStyles();
  const dialogThingOwnerEditRef = useRef();
  const dialogOrganizationEditRef = useRef();

  const role = useSelector(getRole);

  const tableRef = useRef();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [thingTeams, setThingTeams] = useState([]);
  const dispatch = useDispatch();
  const accountId = localStorage.accountId;
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const [thingTeam, setThingTeam] = useState();

  const isRoleExists = checkRole([ROLES.RESPONSIBILITY], role);
  const [subscriptionError, setSubscriptionError] = useState(false);

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const thingOwnerEditDialogRender = () => {
    return (
      <ThingOwnerEdit
        thing={thing}
        ref={dialogThingOwnerEditRef}
        onUpdate={onUpdate}
      ></ThingOwnerEdit>
    );
  };

  const openThingOwnerEditDialog = () => {
    dialogThingOwnerEditRef.current.openDialog();
  };

  useEffect(() => {
    if (thing && thing.id && thing.id !== 0)
      refreshThingTeam(accountId, thing.id);
  }, [thing]);

  const refreshThingTeam = (accountId, thingId) => {
    ThingTeamService.findThingTeams(
      accountId,
      thingId,
      getThingTeamSuccess,
      getThingTeamError
    );
  };

  const getThingTeamSuccess = (data) => {
    console.log(data);
    setThingTeams(data);
    setLoading(false);
  };

  const getThingTeamError = (data) => {
    setLoading(false);
  };

  const thingTeamOnChange = (data) => {
    setIsEditFormOpen(false);
    setThingTeam();
    refreshThingTeam(accountId, thing.id);
  };
  const thingTeamOnDelete = () => {
    setIsEditFormOpen(false);
    setThingTeam();
    refreshThingTeam(accountId, thing.id);
  };
  const thingOrganizationEditDialogRender = () => {
    return (
      <ThingOrganizationEdit
        thing={thing}
        ref={dialogOrganizationEditRef}
        onUpdate={onUpdate}
      ></ThingOrganizationEdit>
    );
  };

  const renderThings2Care = () => {
    return (
      <>
        <Grid item xs={6} className={labelClasses.LabelRow}>
          <Typography className={labelClasses.LabelHeader}>
            {t("THING_OWNER", { ns: THINGS_PACKAGE })}
          </Typography>
          <Typography className={labelClasses.LabelValue}>
            {thing?.ownerUser && (
              <UserDetail value={thing?.ownerUser} type="USER" />
            )}
            {thing?.ownerUserGroup && (
              <UserDetail value={thing?.ownerUserGroup} type="USER_GROUP" />
            )}
          </Typography>
          <Typography className={labelClasses.LabelValue}>
            {thing?.ownerUserText}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          className={labelClasses.LabelRow}
          style={{ height: 70 }}
        >
          <Typography className={labelClasses.LabelHeader}>
            {t("THING_MTNC", { ns: THINGS_PACKAGE })}
          </Typography>
          <Typography className={labelClasses.LabelValue}>
            {thing?.mtncUser?.fullname}
          </Typography>
          <Typography className={labelClasses.LabelValue}>
            {thing?.mtncUserGroup?.name}
          </Typography>
          <Typography className={labelClasses.LabelValue}>
            {thing?.mtncUserText}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          className={labelClasses.LabelLastRow}
          style={{ height: 70 }}
        >
          <Typography className={labelClasses.LabelHeader}>
            {t("THING_USER", { ns: THINGS_PACKAGE })}
          </Typography>
          <Typography className={labelClasses.LabelValue}>
            {thing?.user?.fullname}
          </Typography>
          <Typography className={labelClasses.LabelValue}>
            {thing?.userGroup?.name}
          </Typography>
          <Typography className={labelClasses.LabelValue}>
            {thing?.userText}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          className={labelClasses.LabelLastRow}
          style={{ height: 70 }}
        >
          <Typography className={labelClasses.LabelHeader}>
            {t("THING_ORGANIZATION", { ns: THINGS_PACKAGE })}
          </Typography>
          <Typography className={labelClasses.LabelValue}>
            {thing?.organization?.name}
          </Typography>
        </Grid>
      </>
    );
  };

  const renderThingsAndFeedbacks = () => {
    return (
      <>
        <Grid
          item
          xs={4}
          className={labelClasses.LabelLastRow}
          style={{ height: 70 }}
        >
          <Typography className={labelClasses.LabelHeader}>
            {t("THING_OWNER", { ns: THINGS_PACKAGE })}
          </Typography>
          <Typography className={labelClasses.LabelValue}>
            {thing?.ownerUser?.fullname}
          </Typography>
          <Typography className={labelClasses.LabelValue}>
            {thing?.ownerUserGroup?.name}
          </Typography>
          <Typography className={labelClasses.LabelValue}>
            {thing?.ownerUserText}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          className={labelClasses.LabelLastRow}
          style={{ height: 70 }}
        >
          <Typography className={labelClasses.LabelHeader}>
            {t("THING_MTNC", { ns: THINGS_PACKAGE })}
          </Typography>
          <Typography className={labelClasses.LabelValue}>
            {thing?.mtncUser?.fullname}
          </Typography>
          <Typography className={labelClasses.LabelValue}>
            {thing?.mtncUserGroup?.name}
          </Typography>
          <Typography className={labelClasses.LabelValue}>
            {thing?.mtncUserText}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          className={labelClasses.LabelLastRow}
          style={{ height: 70 }}
        >
          <Typography className={labelClasses.LabelHeader}>
            {t("THING_USER", { ns: THINGS_PACKAGE })}
          </Typography>
          <Typography className={labelClasses.LabelValue}>
            {thing?.user?.fullname}
          </Typography>
          <Typography className={labelClasses.LabelValue}>
            {thing?.userGroup?.name}
          </Typography>
          <Typography className={labelClasses.LabelValue}>
            {thing?.userText}
          </Typography>
        </Grid>
      </>
    );
  };

  return (
    <Card style={{ overflowY: "auto" }}>
      <CardHeader
        action={
          <>
            <ThingsOwnerHelp value="thingDetail.thingOwner" />
            {isEditable && (
              <>
                <IconButton
                  aria-label="settings"
                  onClick={() => {
                    if (isRoleExists) openThingOwnerEditDialog();
                    else setSubscriptionError(true);
                  }}
                  data-fa-button="OWNER_EDIT"
                >
                  <ThingsEditIcon />
                </IconButton>
              </>
            )}
          </>
        }
        title={t("OWNER_INFO")}
        data-fa-section="OWNER_INFO"
      />
      <CardContent>
        <Grid
          item
          container
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          {THINGS_PACKAGE === "things2care" && renderThings2Care()}
          {THINGS_PACKAGE === "thingsAndFeedbacks" &&
            renderThingsAndFeedbacks()}
        </Grid>
        {thingOwnerEditDialogRender()}
        {thingOrganizationEditDialogRender()}
        {subscriptionError && (
          <FeatureNotAllowed
            open={subscriptionError}
            onClose={() => {
              setSubscriptionError(false);
            }}
          />
        )}

        <TableContainer>
          <ThingTeamEdit
            value={thingTeam}
            onChange={thingTeamOnChange}
            onDelete={thingTeamOnDelete}
            open={isEditFormOpen}
            thing={thing}
            onClose={() => {
              setIsEditFormOpen(false);
              setThingTeam();
            }}
          />
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width={"%40"}>
                  Diğer Takım Elemanları{" "}
                  <IconButton
                    variant="contained"
                    disabled={loading}
                    color="primary"
                    size="medium"
                    onClick={() => {
                      if (isRoleExists) setIsEditFormOpen(true);
                      else setSubscriptionError(true);
                    }}
                  >
                    <Add />
                  </IconButton>
                </TableCell>
                <TableCell width={"25%"} />
                <TableCell width={"25%"} />
                <TableCell width={"10%"} />
              </TableRow>
            </TableHead>
            <TableBody>
              {thingTeams?.map((thingTeam, index) => (
                <TableRow
                  key={index}
                  onMouseEnter={() => setShowAction(true)}
                  onMouseLeave={() => setShowAction(false)}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    className={labelClasses.LabelValue}
                  >
                    {thingTeam && thingTeam.user
                      ? thingTeam.user.fullname
                      : thingTeam.email}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={labelClasses.LabelValue}
                  >
                    {thingTeam?.role?.name}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={labelClasses.LabelValue}
                  >
                    {thingTeam?.description}
                  </TableCell>
                  <TableCell component="th" scope="row" style={{ padding: 0 }}>
                    {showAction ? (
                      <IconButton
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          setThingTeam(thingTeam);
                          setIsEditFormOpen(true);
                        }}
                        color="primary"
                        aria-label={t("EDIT")}
                        className={classes.ActionButton}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </IconButton>
                    ) : (
                      <div className={classes.ActionButtonPlaceHolder} />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
