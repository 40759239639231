// Nasil Yapilir'daki tutorial listesi
export const TUTORIALS = [
  {
    name: "QUICK_START.NAME",
    description: "QUICK_START.DESCRIPTION",
    code: "quick_start",
    paths: ["/myThing"], // Free Account
  },
  {
    name: "QUICK_START.NAME",
    description: "QUICK_START.DESCRIPTION",
    code: "quick_start_beginner",
    paths: ["/myThings"], // Beginner Account
  },
  {
    name: "QUICK_START.NAME",
    description: "QUICK_START.DESCRIPTION",
    code: "quick_start_high_package",
    paths: ["/things"], // Standard and upper accounts
  },
  {
    name: "SHARE_IMAGE.NAME",
    description: "SHARE_IMAGE.DESCRIPTION",
    code: "share_image_free",
    paths: ["/myThing"],
  },
  {
    name: "SHARE_IMAGE.NAME",
    description: "SHARE_IMAGE.DESCRIPTION",
    code: "share_image_beginner",
    paths: ["/myThings"],
  },
  {
    name: "SHARE_IMAGE.NAME",
    description: "SHARE_IMAGE.DESCRIPTION",
    code: "share_image_standard",
    paths: ["/things"],
  },
  {
    name: "SHARE_WEB_LINK.NAME",
    description: "SHARE_WEB_LINK.DESCRIPTION",
    code: "share_web_link_free",
    paths: ["/myThing"],
  },
  {
    name: "SHARE_WEB_LINK.NAME",
    description: "SHARE_WEB_LINK.DESCRIPTION",
    code: "share_web_link_beginner",
    paths: ["/myThings"],
  },
  {
    name: "SHARE_WEB_LINK.NAME",
    description: "SHARE_WEB_LINK.DESCRIPTION",
    code: "share_web_link_standard",
    paths: ["/things"],
  },
  {
    name: "SHARE_FILE.NAME",
    description: "SHARE_FILE.DESCRIPTION",
    code: "share_file_free",
    paths: ["/myThing"],
  },
  {
    name: "SHARE_FILE.NAME",
    description: "SHARE_FILE.DESCRIPTION",
    code: "share_file_beginner",
    paths: ["/myThings"],
  },
  {
    name: "SHARE_FILE.NAME",
    description: "SHARE_FILE.DESCRIPTION",
    code: "share_file_standard",
    paths: ["/things"],
  },
  {
    name: "SHARE_SOCIAL_MEDIA.NAME",
    description: "SHARE_SOCIAL_MEDIA.DESCRIPTION",
    code: "share_social_media",
    paths: ["/account-details"],
  },
  {
    name: "FOLLOW_INTERACTIONS.NAME",
    description: "FOLLOW_INTERACTIONS.DESCRIPTION",
    code: "follow_interactions",
    paths: ["/customerInteractions"],
  },
  {
    name: "FOLLOW_INTERACTION_DASHBOARD.NAME",
    description: "FOLLOW_INTERACTION_DASHBOARD.DESCRIPTION",
    code: "follow_interaction_dashboard",
    paths: ["/customerInteractionDashboard"],
  },
  {
    name: "FOLLOW_ISSUES.NAME",
    description: "FOLLOW_ISSUES.DESCRIPTION",
    code: "follow_issues",
    paths: ["/issues"],
  },
  {
    name: "FOLLOW_LIKES.NAME",
    description: "FOLLOW_LIKES.DESCRIPTION",
    code: "follow_likes",
    paths: ["/likes"],
  },
  {
    name: "FOLLOW_SUGGESTIONS.NAME",
    description: "FOLLOW_SUGGESTIONS.DESCRIPTION",
    code: "follow_suggestions",
    paths: ["/suggestions"],
  },
  {
    name: "ASSIGN_TASK.NAME",
    description: "ASSIGN_TASK.DESCRIPTION",
    code: "assign_task",
    paths: ["/issues", "/issueDetail"],
  },
  {
    name: "ISSUE_CLOSE.NAME",
    description: "ISSUE_CLOSE.DESCRIPTION",
    code: "issue_close_1",
    paths: ["/issues"],
  },
  {
    name: "ISSUE_CLOSE.NAME",
    description: "ISSUE_CLOSE_2.DESCRIPTION",
    code: "issue_close_2",
    paths: ["/issues"],
  },
  {
    name: "ISSUE_CLOSE.NAME",
    description: "ISSUE_CLOSE_3.DESCRIPTION",
    code: "issue_close_3",
    paths: ["/issues"],
  },
  {
    name: "CHANGE_PASSWORD.NAME",
    description: "CHANGE_PASSWORD.DESCRIPTION",
    code: "change_password",
    paths: ["/profile"],
  },
  {
    name: "GENERAL_PAGE_FEATURES.NAME",
    description: "GENERAL_PAGE_FEATURES.DESCRIPTION",
    code: "general_page_features",
    paths: ["/profile"],
  },
  {
    name: "INTERACTION_PAGE_OPTIONS.NAME",
    description: "INTERACTION_PAGE_OPTIONS.DESCRIPTION",
    code: "interaction_page_options",
    paths: ["/accountInteraction"],
  },
  {
    name: "THING_CLASS.NAME",
    description: "THING_CLASS.DESCRIPTION",
    code: "thing_class",
    paths: ["/thingClass"],
  },
  {
    name: "CUSTOMER_MESSAGE.NAME",
    description: "CUSTOMER_MESSAGE.DESCRIPTION",
    code: "customer_messages",
    paths: ["/customerMessages"],
  },
  {
    name: "COUPON.NAME",
    description: "COUPON.DESCRIPTION",
    code: "coupon",
    paths: ["/coupon"],
  },
  {
    name: "CAMPAIGN.NAME",
    description: "CAMPAIGN.DESCRIPTION",
    code: "campaign",
    paths: ["/campaign"],
  },
];
