import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { VENDOR } from "./faApi";

export const VendorService = {
  save: (vendor, callback, errorCallback) => {
    let reqBody = {
      ...vendor,
    };

    let req = requests.getSimplePostRequest(VENDOR.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (vendor, callback, errorCallback) => {
    let reqBody = {
      ...vendor,
    };

    let req = requests.getSimplePostRequest(VENDOR.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
