import { Button, Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import {
  Add,
  ArrowDownward,
  ArrowUpward,
  Delete,
  Remove,
} from "@material-ui/icons";
import PeriodicReportElementSelect from "components/select/PeriodicReportElementSelect";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { PeriodicReportService } from "services/PeriodicReportService";
import i18n from "../../i18n";

const DetailCardEdit = forwardRef((props, ref) => {
  const { periodicReport, onClose, details } = props;
  const tableRef = useRef();
  const { t } = useTranslation("periodicReport", { i18n });
  const dispatch = useDispatch();
  const faDialog = useDialog();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newItemType, setNewItemType] = useState(null);
  const [detailItems, setDetailItems] = useState([]);
  const [deletedItems, setDeletedItems] = useState([]);

  useEffect(() => {
    if (details) {
      setDetailItems(details);
    }
  }, [isDialogOpen]);

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsDialogOpen(true);
    },
  }));

  const saveSuccess = (data) => {
    showAlert(t("Kaydedildi"), "success");
    setLoading(false);
    setIsDialogOpen(false);
    onClose();
    setDeletedItems([]);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const saveDetail = () => {
    setLoading(true);
    detailItems.map((item) => {
      return PeriodicReportService.saveDetail(item, saveSuccess, saveError);
    });

    if (deletedItems.length > 0) {
      deletedItems.map((item) => {
        return PeriodicReportService.deleteDetail(item, saveSuccess, saveError);
      });
    }
  };

  const deleteItem = (item, index) => {
    var temp = [...detailItems];
    var deleted = temp.splice(index, 1);
    setDetailItems(temp);

    setDeletedItems([...deletedItems, deleted[0]]);
  };
  const zoomInItem = (item, index) => {
    let temp = [...detailItems];
    let element = temp[index];
    element = { ...element, size: 12 };
    temp[index] = element;
    setDetailItems(temp);
  };
  const zoomOutItem = (item, index) => {
    let temp = [...detailItems];
    let element = temp[index];
    element = { ...element, size: 6 };
    temp[index] = element;
    setDetailItems(temp);
  };

  const moveUp = (item, index) => {
    if (index === 0) return;
    let temp = [...detailItems];
    let element1 = temp[index];
    let element2 = temp[index - 1];
    temp[index] = element2;
    temp[index - 1] = element1;
    setDetailItems(temp);
  };

  const moveDown = (item, index) => {
    if (index === detailItems.length - 1) return;
    let temp = [...detailItems];
    let element1 = temp[index];
    let element2 = temp[index + 1];
    temp[index] = element2;
    temp[index + 1] = element1;
    setDetailItems(temp);
  };

  const addNewItem = (data) => {
    setDetailItems([
      ...detailItems,
      {
        periodicReport: periodicReport,
        element: data,
        size: 6,
      },
    ]);
  };

  const renderItem = (item, index) => {
    return (
      <Grid item xs={item.size}>
        <Card>
          <CardHeader
            title={t(item.element, { ns: "periodicReport" })}
            action={
              <>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    deleteItem(item, index);
                  }}
                  startIcon={<Delete />}
                  style={{ marginRight: 5 }}
                ></Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    moveDown(item, index);
                  }}
                  startIcon={<ArrowDownward />}
                  style={{ marginRight: 5 }}
                ></Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    moveUp(item, index);
                  }}
                  startIcon={<ArrowUpward />}
                  style={{ marginRight: 5 }}
                ></Button>
                {item.size > 6 && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      zoomOutItem(item, index);
                    }}
                    startIcon={<Remove />}
                    style={{ marginRight: 5 }}
                  ></Button>
                )}
                {item.size < 12 && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      zoomInItem(item, index);
                    }}
                    startIcon={<Add />}
                    style={{ marginRight: 5 }}
                  ></Button>
                )}
              </>
            }
          />
          <CardContent></CardContent>
        </Card>
      </Grid>
    );
  };

  const newFormRender = () => {
    return (
      <>
        <FaDialog
          title={t("DETAIL_SETTINGS")}
          faOpen={isDialogOpen}
          faOnSubmit={saveDetail}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            setIsDialogOpen(false);
            setDetailItems([]);
          }}
          faMaxWidth="lg"
        >
          <Grid
            item
            container
            // alignItems="center"
            // justifyContent="center"
            spacing={1}
          >
            {detailItems &&
              detailItems.map((item, index) => renderItem(item, index))}

            <Grid item xs={12}>
              <PeriodicReportElementSelect
                label="Eleman ekle"
                value={newItemType}
                onChange={(data) => {
                  addNewItem(data);
                }}
              />
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  return newFormRender();
});

export default DetailCardEdit;
