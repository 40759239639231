import {
  FormControl,
  FormHelperText,
  FormLabel,
  Link,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import GenericCarousel from "components/ui/GenericCarousel";
import i18n from "i18n";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  FaButton,
  FaDatePicker,
  FaFileUpload,
  FaInput,
} from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { CampaignService } from "services/CampaignService";
import { CAMPAIGN } from "services/faApi";
import { ReportService } from "services/report/reportService";
import { GetFieldByLanguage } from "services/utils";
import { BASE_URL } from "serviceWorker";

export default function SurveyQuestionShoppingDocuments(props) {
  const {
    question,
    answer,
    onChange,
    readOnly,
    currency,
    accountId,
    currentLang,
  } = props;

  const { t } = useTranslation("report", { i18n });

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    if (answer && !answer.shoppingList) {
      addRow();
    }
  }, [answer]);

  const onShoppingItemChange = (value, index, field) => {
    let tempList = [...answer.shoppingList];
    let row = tempList[index];
    row["" + field] = value;

    onChange(question, tempList, "shoppingList");
  };

  const addRow = () => {
    let newItem = {
      vendor: "",
      documentNo: "",
      documentDate: new Date(),
      price: null,
    };

    let tempList = answer.shoppingList ? [...answer.shoppingList] : [];
    tempList.push(newItem);

    onChange(question, tempList, "shoppingList");
  };

  const deleteRow = (index) => {
    let tempList = [...answer.shoppingList];
    tempList.splice(index, 1);

    onChange(question, tempList, "shoppingList");
  };

  const downloadDocument = (id) => {
    CampaignService.downloadCustomerAnswerShopping(
      accountId,
      id,
      downloadDocumentOnSuccess,
      downloadDocuemntOnError
    );
  };

  const downloadDocumentOnSuccess = (data) => {
    const link = document.createElement("a");
    link.href = "data:application/octet-stream;base64," + data.content; //url;
    link.setAttribute("download", data.fileName);
    document.body.appendChild(link);
    link.click();
  };

  const downloadDocuemntOnError = (data) => {};

  return (
    <>
      {readOnly && (
        <GenericCarousel
          open={isDialogOpen}
          onClose={() => {
            setIsDialogOpen(false);
          }}
          label={"Görseller"}
          objectIdList={answer?.shoppingList
            .filter((i) => i.objectId)
            .map((i) => i.objectId)}
        />
      )}
      <Grid item xs={12}>
        <FormControl
          component="fieldset"
          style={{ width: "100%" }}
          required={question.notEmpty}
        >
          <Grid container item row xs={12}>
            <Grid item xs={12}>
              <FormLabel component="legend">
                {question.orderNo}.{" "}
                {GetFieldByLanguage(question.multiLangName, currentLang)}
              </FormLabel>
            </Grid>
            <Grid item xs={12}>
              <FormHelperText>
                {GetFieldByLanguage(question.multiLangHint, currentLang)}
              </FormHelperText>
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        {answer?.shoppingList?.map((shoppingItem, index) => {
          return (
            <>
              {index > 0 && (
                <Grid item xs={12} style={{ paddingLeft: 10, marginTop: 20 }}>
                  <Typography variant="h7">
                    {index + 1}. {t("SHOPPING_DOCUMENT")}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} lg={3}>
                <FaInput
                  required={question.notEmpty}
                  label={t("VENDOR")}
                  value={shoppingItem.vendor}
                  onChange={(event) => {
                    onShoppingItemChange(event.target.value, index, "vendor");
                  }}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <FaInput
                  required={question.notEmpty}
                  label={t("DOCUMENT_NO")}
                  value={shoppingItem.documentNo}
                  onChange={(event) => {
                    onShoppingItemChange(
                      event.target.value,
                      index,
                      "documentNo"
                    );
                  }}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <FaDatePicker
                  required={question.notEmpty}
                  faType={"date"}
                  value={shoppingItem.documentDate ?? null}
                  faOnChange={(val) => {
                    onShoppingItemChange(val, index, "documentDate");
                  }}
                  label={t("DOCUMENT_DATE")}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <FaInput
                  required={question.notEmpty}
                  label={t("PRICE")}
                  faType={"number"}
                  awnum={"decimal-2"}
                  value={shoppingItem.price}
                  onChange={(event) => {
                    onShoppingItemChange(event.target.value, index, "price");
                  }}
                  disabled={readOnly}
                  // FIXME para birimi gösterilmiyor
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">{currency}</InputAdornment>
                  //   ),
                  //   decimalScale: 2,
                  //   thousandSeparator: ".",
                  //   decimalSeparator: ",",
                  //   isNumericString: true,
                  // }}
                />
              </Grid>

              <Grid item xs={12} lg={2}>
                {!readOnly && (
                  <FaFileUpload
                    maxFileSize={
                      question.maxFileSize == null
                        ? 20000000
                        : question.maxFileSize * 1000000
                    }
                    // acceptedFiles={
                    //   question.fileTypes == null
                    //     ? [""]
                    //     : question.fileTypes.split(",")
                    // }
                    acceptedFiles={["image/*"]}
                    onChange={(data) => {
                      if (data != null && data.length > 0) {
                        let temp = data[0];
                        onShoppingItemChange(temp, index, "multipartFile");
                        ReportService.uploadTempFile(
                          accountId,
                          temp,
                          (data) => {
                            onShoppingItemChange(data, index, "tempFileId");
                          },
                          (error) => {}
                        );
                      } else
                        onShoppingItemChange(
                          null,
                          index,
                          "multipartFile",
                          null,
                          "tempFileId"
                        );
                    }}
                    filesLimit={1}
                    dropZoneBoxProps={{ style: { minHeight: "50px" } }}
                    dropzoneText={() => {
                      return "Belgenin fotoğrafını yükleyin";
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12} lg={1}>
                {!!!readOnly && (
                  <FaButton
                    faType="button"
                    size="large"
                    variant="outlined"
                    color="secondary"
                    label={t("Sil")}
                    onClick={() => {
                      deleteRow(index);
                    }}
                  ></FaButton>
                )}
              </Grid>
              {readOnly && shoppingItem?.objectId && (
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Link
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => {
                        //downloadDocument(shoppingItem.id);
                        setIsDialogOpen(true);
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          alt="belge"
                          src={
                            BASE_URL +
                            CAMPAIGN.getCustomerImageHd +
                            "?accountId=" +
                            accountId +
                            "&objectId=" +
                            encodeURI(shoppingItem?.objectId)
                          }
                          style={{
                            maxWidth: "500px",
                            maxHeight: "500px",
                            marginRight: "10px",
                          }}
                        />
                      </div>
                    </Link>
                  </Grid>
                </Grid>
              )}
            </>
          );
        })}
        <Grid item xs={12}>
          {!!!readOnly &&
            (!question.maxCount ||
              answer?.shoppingList?.length < question.maxCount) && (
              <FaButton
                faType="button"
                variant="outlined"
                size="large"
                label={t("Yeni Satır Ekle")}
                onClick={addRow}
              ></FaButton>
            )}
        </Grid>
      </Grid>
    </>
  );
}

SurveyQuestionShoppingDocuments.propTypes = {
  question: PropTypes.any,
  currency: PropTypes.any,
  answer: PropTypes.any,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};
