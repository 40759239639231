import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  w100: {
    width: "100%",
    minHeight: 50,
  },
  padding10: {
    padding: "14.5px 14px",
  },
}));
