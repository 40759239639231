import { Link } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { getDateTimeWFormat, qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DocumentService } from "services/DocumentService";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import { useStyles } from "./style";

const MessageTable = forwardRef((props, ref) => {
  let history = useHistory();
  const { queryParams, dataUrl } = props;
  const tableRef = useRef();
  const { t } = useTranslation("beneficiaryNote", { i18n });
  const labelClasses = labelStyles();
  const userInfo = useSelector(getUserInfo);
  const classes = useStyles();

  const accountId = localStorage.accountId;

  const [queryParameters, setQueryParameters] = useState(
    queryParams ? { ...queryParams } : qsParse(history.location.search)
  );
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    //dispatch(snackbar(snackBarObj));
  };
  useImperativeHandle(ref, () => ({
    refreshTable() {
      refresh();
    },
  }));

  useEffect(() => {
    refresh();
  }, [dataUrl]);

  const refresh = () => {
    setLoading(true);

    var req = requests.getSimpleGetRequest(dataUrl, queryParameters);
    FaAxios(req)
      .then((response) => {
        if (
          !!response.data &&
          !!response.data.list &&
          response.data.list.length > 0
        ) {
          setData(response.data.list.reverse());
        }
        setLoading(false);
      })
      .catch((error) => {
        showAlert("error", error);
        setLoading(false);
      });
  };

  const downloadDocument = (beneficiaryNoteId, item) => {
    DocumentService.downloadBeneficiaryNoteDocument(
      accountId,
      beneficiaryNoteId,
      item.id,
      downloadDocumentOnSuccess,
      downloadDocumentOnError
    );
  };

  const downloadDocumentOnSuccess = (data) => {
    const link = document.createElement("a");
    link.href = "data:application/octet-stream;base64," + data.content; //url;
    link.setAttribute("download", data.fileName);
    document.body.appendChild(link);
    link.click();
  };

  const downloadDocumentOnError = (data) => {
    console.log("downloadDocumentOnError", data);
  };

  return (
    !loading && (
      <div className={classes.base}>
        <Grid item container spacing={2}>
          {data.map((item, index) => {
            let theAlign = "";
            let username = "";
            if (item?.user?.id === userInfo?.id) {
              theAlign = "flex-end";
              username = "Ben";
            } else if (item.reply) {
              theAlign = "flex-start";
              username = "Müşteri";
            } else {
              theAlign = "flex-start";
              username = item?.user?.fullname;
            }
            return (
              <>
                <Grid container item xs={12} justify={theAlign}>
                  <Grid item style={{ maxWidth: "100%" }}>
                    <Paper elevation={3}>
                      <Card className={classes.back}>
                        <Grid className={classes.messageBox}>
                          <Typography
                            className={labelClasses.LabelHeader}
                            style={{ fontWeight: "bold" }}
                          >
                            {username}
                          </Typography>
                          <Typography className={labelClasses.LabelHeader}>
                            {item.messageTitle}
                          </Typography>

                          <Typography className={labelClasses.LabelValue}>
                            {item.messageContent}
                          </Typography>
                        </Grid>
                        <Grid className={classes.messageBox}>
                          {item.documents.map((doc, index) => {
                            return (
                              <div>
                                <Link
                                  onClick={() => {
                                    downloadDocument(item.id, doc);
                                  }}
                                  style={{
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontSize: "0.75rem",
                                    }}
                                  >
                                    {doc.fileName}
                                  </Typography>
                                </Link>
                              </div>
                            );
                          })}
                        </Grid>
                        <Box
                          className={classes.date}
                          display="flex"
                          justifyContent="flex-end"
                        >
                          <Typography
                            color="textSecondary"
                            className={classes.dateSize}
                          >
                            {getDateTimeWFormat(item.createDate)}
                          </Typography>
                        </Box>
                      </Card>
                    </Paper>
                  </Grid>
                </Grid>
              </>
            );
          })}
          {(!data || data.length === 0) && (
            <Grid container item xs={12}>
              <Grid item xs={12}>
                {t("Kayıt bulunamadı")}
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    )
  );
});
export default MessageTable;
