import axios from "axios";
import { format, parse } from "date-fns";
import i18next from "i18next";
import Qs from "qs";

//export const THINGS_PACKAGE = "things2care";
//export const THINGS_PACKAGE = "thingsAndFeedbacks";

export const getThingsPackage = () => {
  if (
    localStorage &&
    localStorage.role &&
    localStorage.role.indexOf("THINGS_2_CARE") > -1
  )
    return "things2care";
  else return "thingsAndFeedbacks";
};

export const isTNF = (value1, value2) => {
  if (getThingsPackage() === "thingsAndFeedbacks") return value1;
  else return value2;
};

export const timeout = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const makeEnum = (array) => {
  if (!(array instanceof Array)) {
    throw new Error("First argument must be an array!");
  }
  let enumObj = {};
  array.forEach((type) => {
    enumObj[type] = type;
  });
  enumObj = Object.freeze(enumObj);
  return new Proxy(enumObj, {
    get: function (target, prop, receiver) {
      if (target[prop] === undefined) {
        throw new Error(`Type ${prop} does not exist!`);
      } else {
        return Reflect.get(...arguments);
      }
    },
  });
};

export const setAuthorizationToken = (token) => {
  if (token && token !== "undefined") {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export const qsParse = (search) => {
  return Qs.parse(search, {
    ignoreQueryPrefix: true,
    allowDots: true,
    arrayFormat: "indices",
  });
};

export const qsStringfy = (queryParams) => {
  return Qs.stringify(queryParams, { arrayFormat: "indices", allowDots: true });
};

export const jsUcfirst = (string) => {
  return string && string.charAt(0).toUpperCase() + string.slice(1);
};

export const getCurrentLang = () => {
  return i18next.language || localStorage.getItem("i18nextLng");
};

export const getCurrentLangCode = () => {
  const lang = getCurrentLang();
  return lang ? lang.split("-")[0] : "tr";
};

export const getDateWFormat = (date) => {
  try {
    if (!date) return null;
    var val = new Date(date);
    return format(val, "dd/MM/yyyy");
  } catch (error) {
    return "-";
  }
};

export const getDateTimeWFormat = (date) => {
  if (!date) return null;
  var val = new Date(date);
  try {
    return format(val, "dd/MM/yyyy HH:mm");
  } catch (error) {
    return "-";
  }
};

export const getTimeWFormat = (date) => {
  try {
    if (!date) return null;
    var val = new Date(date);
    return format(val, "HH:mm");
  } catch (error) {
    return "-";
  }
};

export const getMonthYearWFormat = (date) => {
  try {
    if (!date) return null;
    var val = new Date(date);
    return format(val, "MM/yyyy");
  } catch (error) {
    return "-";
  }
};

export const getDateWFormatEn = (date) => {
  try {
    if (!date) return null;
    var val = new Date(date);
    return format(val, "yyyy-MM-dd");
  } catch (error) {
    return "-";
  }
};

export const hourMinuteToDate = (h, m) => {
  return new Date(0, 0, 0, h, m, 0, 0);
};

export const dateToMinutes = (date) => {
  return date.getHours() * 60 + date.getMinutes();
};

export const DATA_TYPES = [
  "STRING",
  "NUMERIC",
  "DATE",
  "TIME",
  "DATE_TIME",
  "TEXT",
  "ENUMS",
  "BOOLEAN",
  "MONEY",
  "YES_NO",
  "JSON",
  "FILE",
  "LINK",
];

export const REMINDER_DATE_TYPES = ["MINUTE", "HOUR", "DAY", "MONTH"];

Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000);
  return this;
};

export const smartTimeDiff = (date, start) => {
  if (date == null) return null;

  const SECOND = 1;
  const MINUTE = 60 * SECOND;
  const HOUR = 60 * MINUTE;
  const DAY = 24 * HOUR;
  const MONTH = 30 * DAY;
  //FIXME gmt+3
  //const diffTime = start == null ? ((new Date().addHours(-3)) - new Date(date)) : (new Date(date) - start);
  const diffTime =
    start == null ? new Date() - new Date(date) : new Date(date) - start;
  const delta = Math.ceil(Math.abs(diffTime) / 1000);
  const suffix = diffTime < 0 ? " sonra" : "";

  if (delta < 45 * MINUTE) return Math.floor(delta / 60) + " dakika" + suffix;

  if (delta < 90 * MINUTE) return "1 saat";

  if (delta < 24 * HOUR)
    return Math.floor(delta / (60 * 60)) + " saat" + suffix;

  if (delta < 48 * HOUR) return "1 gün";

  if (delta < 30 * DAY)
    return Math.floor(delta / (60 * 60 * 24)) + " gün" + suffix;

  if (delta < 12 * MONTH) {
    const months = Math.floor(Math.ceil(delta / (60 * 60 * 24)) / 30);
    return months <= 1 ? "1 ay" : months + " ay" + suffix;
  } else {
    const years = Math.floor(Math.ceil(delta / (60 * 60 * 24)) / 365);
    return years <= 1 ? "1 yıl" : years + " yıl" + suffix;
  }
};

export const ThingDesc = (thing) => {
  if (!thing) return null;
  let str = thing.className ? thing.className : thing?.thingClass?.className;
  if (thing.thingCode) str += " - " + thing.thingCode;
  if (thing.description) str += " - " + thing.description;
  if (thing.hashtag) str += " - " + thing.hashtag;

  return str;
};

export const IssueDesc = (issue) => {
  let str = issue?.issueClass?.className;
  if (issue.description) str += " - " + issue.description;

  return str;
};

export const ParseHashtagStr = (str) => {
  if (str == null || str.length === 0) return [];

  let list = [];
  const parts = str.split("#");
  parts.map((item) => {
    if (item.trim().length > 0) list.push(item.trim());
    return null;
  });

  return list;
};

export const ConcatHashtagStr = (list) => {
  if (list.length === 0) return null;

  let str = "";
  list.map((item, index) => {
    if (index === 0) str = "#" + item;
    else str += " #" + item;
    return null;
  });

  return str;
};

export const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const RecurrenceDesc = (recurence, t) => {
  if (recurence.frequency === "hour") {
    return "Her " + recurence.hourlyInterval + " saatte bir";
  } else if (recurence.frequency === "day") {
    if (recurence.dailyMod === "INTERVAL")
      return "Her " + recurence.dailyInterval + " günde bir";
    else if (recurence.dailyMod === "WEEKDAY") return "Her iş günü";
  } else if (recurence.frequency === "week") {
    var selectedDays = days.filter((day, index) => recurence.weeklyDays[index]);
    return (
      "Her " +
      recurence.weeklyInterval +
      " haftada bir " +
      selectedDays
        .map((item) => {
          return t("" + item);
        })
        .join(",")
    );
  } else if (recurence.frequency === "month") {
    return (
      "Her " +
      recurence.monthlyDay +
      ".günü " +
      recurence.monthlyInterval +
      " ayda bir"
    );
  } else if (recurence.frequency === "year") {
    return (
      "" +
      recurence.yearlyInterval +
      " yılda bir şu tarihte " +
      recurence.yearlyDay +
      " " +
      t(months[recurence.yearlyMonth])
    );
  }
};

export const PUBLIC_REPORT_PAGE_TEMPLATES = [
  // "TEMPLATE_1",
  // "TEMPLATE_2",
  // "TEMPLATE_3",
  // "TEMPLATE_4",
  // "TEMPLATE_5",
  "TEMPLATE_10",
  "TEMPLATE_CUSTOM",
  "TEMPLATE_6",
  "TEMPLATE_7",
  "TEMPLATE_8",
];

export const DEFAULT_UI_SETTINGS = {
  homepageItems: [
    { item: "THINGS", size: 6 },
    { item: "THINGS_12_MONTH_CHART", size: 6 },
    { item: "ISSUES_CHART", size: 12 },
  ],
};

export const LANGUAGES = ["en", "de", "tr"];

export const GetFileByLanguage = (files, lang) => {
  if (!files || !lang) return null;

  if (files[lang]) return files[lang];
  else if (files["tr"]) return files["tr"];
  else if (files["en"]) return files["en"];
  else {
    for (var prop in files) {
      if (files.hasOwnProperty(prop)) {
        return files[prop];
      }
    }
  }
};

export const GetFieldByLanguage = (field, lang, defaultValue) => {
  if (!field || !lang) return defaultValue ?? null;

  if (field[lang]) return field[lang];
  else if (field["tr"]) return field["tr"];
  else if (field["en"]) return field["en"];
  else {
    for (var prop in field) {
      if (field.hasOwnProperty(prop)) {
        return field[prop];
      }
    }
  }

  return defaultValue;
};

export const GetFileCount = (files) => {
  if (!files) return 0;

  var count = 0;
  for (var prop in files) {
    if (files.hasOwnProperty(prop)) {
      count++;
    }
  }

  return count;
};

export const GetTaskEndDate = () => {
  let tomorrow = new Date().addHours(24);
  return new Date(
    tomorrow.getFullYear(),
    tomorrow.getMonth(),
    tomorrow.getDate(),
    18,
    0,
    0,
    0
  );
};

export const GetEventStartDate = () => {
  let tomorrow = new Date().addHours(24);
  return new Date(
    tomorrow.getFullYear(),
    tomorrow.getMonth(),
    tomorrow.getDate(),
    10,
    0,
    0,
    0
  );
};

export const ParseLocation = (gpsLocation) => {
  if (gpsLocation && gpsLocation.length > 3) {
    let parsedData = JSON.parse(gpsLocation);
    if (parsedData.lat && parsedData.lng) {
      return parsedData;
    } else if (parsedData.latitude && parsedData.longitude) {
      return { lat: parsedData.latitude, lng: parsedData.longitude };
    }
  }

  return null;
};

export const HumanFileSize = (size) => {
  var i = Math.floor(Math.log(size) / Math.log(1024));
  return (
    (size / Math.pow(1024, i)).toFixed(2) * 1 +
    " " +
    ["B", "kB", "MB", "GB", "TB"][i]
  );
};

export const parseStringDate = (strDate) => {
  try {
    if (!strDate) return null;
    return parse(strDate, "yyyy-MM-dd", new Date());
  } catch (error) {
    return "-";
  }
};

export const thingsDecodeUri = (t) => {
  return decodeURIComponent(t.replace(/\+/g, " "));
};

export const roundNumber = (value, precision) => {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const calculateDate = (data) => {
  var today = new Date();
  let start = null;

  if (data === -7) {
    start = new Date(today);
    start.setDate(start.getDate() - 7);
  } else if (data === -31) {
    start = new Date(today.setMonth(today.getMonth() - 1));
  } else if (data === -1) {
    start = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0
    );
  }

  return start;
};

export const DEFAULT_COUNTRY = {
  id: 218,
  createDate: null,
  lastChangeDate: null,
  version: 0,
  deleted: false,
  iso: "TR",
  name: "TURKEY",
  nameTr: "Türkiye",
  nameEn: "Turkey",
  phoneCode: 90,
  iso3: "TUR",
  numCode: 792,
};

export const getCurrencySymbol = (currency) => {
  switch (currency) {
    case "TRY":
      return "₺";
    case "EUR":
      return "€";
    case "USD":
      return "$";
    case "GBP":
      return "£";
    default:
      return currency;
  }
};

export const priceToString = (price, currency) => {
  if (price) {
    if (currency === "TRY")
      return price.toLocaleString() + getCurrencySymbol(currency);
    else return getCurrencySymbol(currency) + price.toLocaleString();
  }

  return null;
};

export const getGMTTime = (offset, time) => {
  let operator = offset.substring(0, 1);
  offset = offset.substring(1, 6).split(":");

  let hour = Number(offset[0]);
  let minute = Number(offset[1]);
  let dateAndTime = null;
  if (operator === "+")
    dateAndTime = new Date(
      time.getFullYear(),
      time.getMonth(),
      time.getDate(),
      time.getHours() - hour,
      time.getMinutes() - minute,
      time.getSeconds()
    );
  else if (operator === "-")
    dateAndTime = new Date(
      time.getFullYear(),
      time.getMonth(),
      time.getDate(),
      time.getHours() + hour,
      time.getMinutes() + minute,
      time.getSeconds()
    );

  return dateAndTime;
};

export const getSelectedZoneTime = (offset, time) => {
  let operator = offset.substring(0, 1);
  offset = offset.substring(1, 6).split(":");

  let hour = Number(offset[0]);
  let minute = Number(offset[1]);
  let dateAndTime = null;
  if (operator === "+")
    dateAndTime = new Date(
      time.getFullYear(),
      time.getMonth(),
      time.getDate(),
      time.getHours() + hour,
      time.getMinutes() + minute,
      time.getSeconds()
    );
  else if (operator === "-")
    dateAndTime = new Date(
      time.getFullYear(),
      time.getMonth(),
      time.getDate(),
      time.getHours() - hour,
      time.getMinutes() - minute,
      time.getSeconds()
    );

  return dateAndTime;
};

export const DEFAULT_TIMEZONE = "+03:00";

//Ortama bağlı değişkenler Environments altına alındı
