import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { CUSTOMER_LIST_DETAIL } from "./faApi";

export const CustomerListDetailService = {
  save: (customerListDetail, callback, errorCallback) => {
    let reqBody = {
      ...customerListDetail,
    };

    let req = requests.getSimplePostRequest(CUSTOMER_LIST_DETAIL.save, reqBody);

    console.log("request", req);
    FaAxios(req)
      .then((response) => {
        console.log("response => ", response);
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (customerListDetail, callback, errorCallback) => {
    let reqBody = {
      ...customerListDetail,
    };

    let req = requests.getSimplePostRequest(
      CUSTOMER_LIST_DETAIL.delete,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        console.log("error:", error);
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(CUSTOMER_LIST_DETAIL.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
  findByCustomerListId: (customerListId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      CUSTOMER_LIST_DETAIL.findByCustomerListId,
      { customerListId }
    );
    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
  saveAll: (customerListDetail, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      CUSTOMER_LIST_DETAIL.saveAll,
      customerListDetail
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
