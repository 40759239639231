import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Note from "../../components/noteCard";
import i18n from "../../i18n";
import { SolutionService } from "../../services/SolutionService";
import SolutionControlItems from "./SolutionControlItems";
import SolutionDetailCard from "./SolutionDetailCard";
import SolutionIssues from "./SolutionIssues";
import SolutionPhotos from "./SolutionPhotos";
import SolutionServiceOperations from "./SolutionServiceOperations";
import SolutionStocks from "./SolutionStocks";
import { useStyles } from "./style";

export default function SolutionDetail() {
  const { t } = useTranslation("solutions", { i18n });
  const { id } = useParams();
  const accountId = localStorage.accountId;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [solution, setSolution] = useState(null);
  let history = useHistory();

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    setLoading(true);
    SolutionService.findById(id, findByIdOnSuccess, findByIdOnError);
  };

  const findByIdOnSuccess = (data) => {
    setSolution(data);
    setLoading(false);
  };
  const findByIdOnError = (error) => {
    console.log(error);
    setLoading(false);
  };

  const saveSolution = (data) => {
    setLoading(true);
    SolutionService.save(data, saveOnSuccess, saveOnError);
  };

  const saveOnSuccess = (data) => {
    setSolution(data);
    setLoading(false);
  };
  const saveOnError = (error) => {
    console.log(error);
    setLoading(false);
  };

  const issueDetail = (issue) => {
    const tmpTo = {
      pathname: `/issues/detail/${issue.id}`,
      breadCrumbKey: `/issues/detail/:id`,
    };
    history.push(tmpTo);
  };

  return (
    <>
      <Grid item container spacing={1}>
        <Grid item xs={12} lg={6}>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              {solution && (
                <SolutionDetailCard solution={solution} onSave={saveSolution} />
              )}
            </Grid>

            <Grid item xs={12}>
              {solution && (
                <SolutionIssues
                  solution={solution}
                  onIssueDetail={issueDetail}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              {solution && <SolutionStocks solution={solution} />}
            </Grid>

            <Grid item xs={12}>
              {solution && <Note solution={solution} />}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              {solution && <SolutionPhotos solution={solution} />}
            </Grid>

            <Grid item xs={12}>
              {solution && <SolutionServiceOperations solution={solution} />}
            </Grid>

            <Grid item xs={12}>
              {solution && <SolutionControlItems solution={solution} />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
