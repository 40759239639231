import {
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import ThingPhotosCarousel from "features/thingDetail/ThingPhotosCarousel";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import { FaDialog, FaFileUpload } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ServiceOperationClassService } from "services/ServiceOperationClassService";
import { gridStyles } from "styles/ThingsGridStyle";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function ServiceOperationPhotos(props) {
  const { serviceOperation } = props;
  const dispatch = useDispatch();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  const accountId = localStorage.accountId;
  const gridClasses = gridStyles();
  const { t } = useTranslation("serviceOperationClass", { i18n });
  const tableRef = useRef();
  const faDialog = useDialog();
  const classes = useStyles();
  const labelClasses = labelStyles();
  const [loading, setLoading] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [messageDocuments, setMessageDocuments] = useState([]);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [images, setImages] = useState([]);

  const [selectedHdImageIndex, setSelectedHdImageIndex] = useState(0);
  const [isCarouselOpen, setCarouselOpen] = useState(false);

  const openCarousel = (index) => {
    setSelectedHdImageIndex(index);
    setCarouselOpen(true);
  };

  const closeCarousel = () => {
    setCarouselOpen(false);
  };

  const refresh = () => {
    findDocuments();
  };

  useEffect(() => {
    if (serviceOperation && accountId) refresh();
  }, [serviceOperation, accountId]);

  const deleteDocument = (item) => {
    faDialog({
      description: t("Silmek istediğinize emin misiniz?"),
      type: "confirm",
    }).then((confirmation) => {
      ServiceOperationClassService.deleteServiceOperationDocument(
        serviceOperation.id,
        item,
        () => refresh(),
        (error) => {
          console.log(error);
        }
      );
    });
  };

  const saveDocument = () => {
    ServiceOperationClassService.uploadServiceOperationDocument(
      serviceOperation.id,
      uploadedFiles[0],
      uploadDocumentOnSuccess,
      (e) => {
        showAlert(t(e), "error");
        setLoading(false);
      }
    );
  };

  const uploadDocumentOnSuccess = () => {
    setIsEditFormOpen(false);
    setUploadedFiles([]);
    setLoading(false);
    refresh();
  };

  const findDocuments = () => {
    ServiceOperationClassService.downloadServiceOperationThumbnails(
      serviceOperation.id,
      (data) => {
        setImages(data);
      },
      (error) => console.log(error)
    );
  };

  const renderEditDialog = () => {
    return (
      <FaDialog
        title={t("Görsel Ekle")}
        loading={loading}
        faOpen={isEditFormOpen}
        faOnSubmit={() => {
          setLoading(true);
          saveDocument();
        }}
        showSaveButton
        faHandleClose={() => {
          setIsEditFormOpen(false);
          setUploadedFiles([]);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <FaFileUpload
              maxFileSize={20000000}
              onChange={(data) => {
                if (data != null && data.length > 0) {
                  setUploadedFiles(data);
                } else setUploadedFiles(null);
              }}
              initialFiles={uploadedFiles}
              filesLimit={1}
            />
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  const showCarousel = () => {
    return (
      <Dialog
        open={isCarouselOpen}
        aria-labelledby="form-dialog-title"
        onClose={closeCarousel}
      >
        <DialogContent>
          <ThingPhotosCarousel
            serviceOperationId={serviceOperation.id}
            images={images}
            setImages={setImages}
            selectedItem={selectedHdImageIndex}
          />
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {renderEditDialog()}
        {serviceOperation &&
          serviceOperation.id &&
          serviceOperation.id !== 0 &&
          showCarousel()}
        <Card
          style={{ minHeight: 150, overflowY: "auto" }}
          className="fai-photos"
        >
          <CardHeader
            action={
              <IconButton
                aria-label="settings"
                onClick={() => {
                  setUploadedFiles([]);
                  setIsEditFormOpen(true);
                }}
              >
                <EditIcon />
              </IconButton>
            }
            title={t("PHOTOS")}
          ></CardHeader>
          <CardContent>
            <Grid item container spacing={1}>
              {images.length > 0 &&
                images.map((item, index) => (
                  <div key={index} className={classes.thingImageContainer}>
                    <img
                      src={`data:image/png;base64, ${item.content}`}
                      className={classes.thingImage}
                      alt={item.object}
                      onClick={() => {
                        openCarousel(index);
                      }}
                    />
                    {
                      <IconButton
                        className={classes.thingImageDeleteButtom}
                        onClick={() => {
                          deleteDocument(item.object);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    }
                  </div>
                ))}
            </Grid>
          </CardContent>
        </Card>
      </Suspense>
    </>
  );
}
