import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { Add, Close } from "@material-ui/icons";
import Adsense from "components/adsense/Adsense";
import InvitationStatusSelect from "components/select/InvitationStatusSelect";
import RoleSelect from "components/select/RoleSelect";
import ThingsDataTable from "components/thingsDataTable";
import FeatureNotAllowed from "features/subscriptions/FeatureNotAllowed";
import React, { Suspense, useRef, useState } from "react";
import { FaButton, FaDialog, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { InvitationService } from "services/InvitationService";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { INVITATION } from "../../services/faApi";

export default function AccountUserInvitation() {
  const tableRef = useRef();
  const labelClasses = labelStyles();
  const faDialog = useDialog();
  const { t } = useTranslation(
    ["accountUserInvitation", "components", "errorMessages"],
    {
      i18n,
    }
  );
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const accountId = localStorage.accountId;

  const [loading, setLoading] = useState(false);
  const [isDetailFormOpen, setIsDetailFormOpen] = useState(false);
  const [isNewInvitationOpen, setIsNewInvitationOpen] = useState(false);
  const [invitationType, setInvitationType] = useState("EMAIL");

  const [subscriptionError, setSubscriptionError] = useState(false);

  const columns = [
    { field: "fullname", label: t("AD_SOYAD"), align: "center", width: "30%" },
    {
      field: "email",
      label: t("E_POSTA") + " / SMS",
      align: "center",
      width: "20%",
      valueFormatter: (value, row) => {
        if (value) return value;
        else return row.phone;
      },
    },
    {
      field: "invitationDate",
      label: t("INVITATION_DATE"),
      align: "center",
      width: "10%",
      type: "dateTime",
    },
    {
      field: "statusDate",
      label: t("ACCEPT_REJECT_DATE"),
      align: "center",
      width: "10%",
      type: "dateTime",
    },
    {
      field: "authority",
      label: t("ROL"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        return value && t("ROLE_" + value, { ns: "components" });
      },
    },
    {
      field: "status",
      label: t("STATUS"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        return t("STATUS_" + value);
      },
    },
  ];

  const newInvitation = () => {
    return {
      account: { id: accountId },
      authority: "",
      email: "",
      phone: "",
      fullname: "",
      invitationMsg: "",
    };
  };

  const [invitation, setInvitation] = useState(newInvitation());

  const [errorMessages, setErrorMessages] = useState({
    fullname: "",
    phone: "",
    authority: "",
    email: "",
    invitationMsg: "",
  });
  const [isFieldsEmpty, setIsFieldsEmpty] = useState({
    fullname: true,
    authority: "",
    phone: true,
    email: true,
  });

  const newSearchForm = () => {
    let result = {
      fullname: null,
      status: null,
      accountId: accountId,
      email: null,
      phone: null,
    };

    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    setInvitation(row);
    setIsDetailFormOpen(true);
  };

  const deleteInvitation = () => {
    faDialog({
      description: t("Daveti silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        InvitationService.delete(
          invitation,
          () => {
            tableRef.current.refreshTable();
            setIsDetailFormOpen(false);
            showAlert(t("DELETED"), "success");
          },
          () => {}
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const saveInvitation = () => {
    setLoading(true);
    InvitationService.invitation(
      invitation,
      (data) => {
        setIsNewInvitationOpen(false);
        tableRef.current.refreshTable();
        setLoading(false);
        setInvitationType("EMAIL");
      },
      (error) => {
        showAlert(
          t("errorMessages:" + error?.message ?? "UNEXPECTED_ERROR"),
          "error"
        );
        setLoading(false);
      }
    );
  };

  const handleChange = (prop) => (event) => {
    setInvitation({ ...invitation, [prop]: event.target.value });
    setIsFieldsEmpty({ ...isFieldsEmpty, [prop]: false });
  };

  const handleChangeV = (prop, value) => {
    setInvitation({ ...invitation, [prop]: value });
    setIsFieldsEmpty({ ...isFieldsEmpty, [prop]: false });
  };

  const onAddClick = () => {
    InvitationService.checkAddNewUser(
      accountId,
      (data) => {
        if (data) {
          setInvitation(newInvitation());
          setIsNewInvitationOpen(true);
        } else {
          setSubscriptionError(true);
        }
      },
      (error) => {
        setSubscriptionError(true);
      }
    );
  };

  const renderEditDialog = () => {
    return (
      <FaDialog
        title={t("INVITE_TEAM_MEMBER")}
        faOpen={isNewInvitationOpen}
        faOnSubmit={saveInvitation}
        loading={loading}
        showSaveButton
        faHandleClose={() => {
          setIsNewInvitationOpen(false);
          setInvitationType("EMAIL");
        }}
      >
        <Grid
          item
          container
          spacing={1}
          justify="center"
          alignItems="center"
          data-fa-section="INVITE_TEAM_MEMBER"
        >
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                {t("İletişim bilgileri seçimi")}
              </FormLabel>
              <RadioGroup
                row
                aria-label="position"
                value={invitationType}
                onChange={(e) => {
                  setInvitationType(e.target.value);
                }}
              >
                <FormControlLabel
                  value={"EMAIL"}
                  control={<Radio color="primary" />}
                  label="E-Posta"
                />
                <FormControlLabel
                  value={"SMS"}
                  control={<Radio color="primary" />}
                  label="Cep Telefonu"
                />
              </RadioGroup>
            </FormControl>
            {invitationType === "EMAIL" && (
              <FaInput
                helperText={errorMessages.email}
                faError={errorMessages.email}
                faType="email"
                required
                maxLength={100}
                label={t("E_POSTA")}
                autoComplete="email"
                onChange={handleChange("email")}
                value={invitation.email || ""}
              />
            )}
            {invitationType === "SMS" && (
              <FaInput
                required
                label={t("PHONE_NUMBER")}
                onChange={handleChange("phone")}
                faType="phoneNumber"
                value={invitation.phone}
                helperText={errorMessages.phone}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <RoleSelect
              required
              value={invitation.authority}
              onChange={(data) => {
                handleChangeV("authority", data);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              label={t("AD_SOYAD")}
              maxLength={100}
              onChange={handleChange("fullname")}
              value={invitation.fullname || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              helperText={errorMessages.invitationMsg}
              faError={errorMessages.invitationMsg}
              maxLength={255}
              multiline
              rows={5}
              label={t("INVITATION_MESSAGE")}
              onChange={handleChange("invitationMsg")}
              value={invitation.invitationMsg || ""}
            />
          </Grid>
          {/*<Grid item lg={8} xs={10} sm={10} md={10}>
                  <FaInput
                    required
                    faError={errorMessages.phone}
                    helperText={errorMessages.phone}
                    label={t("PHONE_NUMBER")}
                    onChange={(event) =>
                      handleChangeV("phone", event.target.allValues.value)
                    }
                    faMask="+## (###) ### ## ##"
                    faMaskChar="*"
                    //helperText="Örnek : +999999999999"
                    value={invitationDTO.phone}
                  />
                </Grid>*/}
        </Grid>
      </FaDialog>
    );
  };

  const renderDetailDialog = () => {
    return (
      <FaDialog
        title={"Davet Detay"}
        faOpen={isDetailFormOpen}
        faHandleClose={() => {
          setIsDetailFormOpen(false);
        }}
      >
        {invitation && (
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={0}
          >
            <Grid item xs={12}>
              <Grid item container spacing={1}>
                <Grid item xs={12} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("AD_SOYAD")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {invitation.fullname}
                  </Typography>
                </Grid>

                <Grid item xs={12} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("ROL")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {t("ROLE_" + invitation.authority, { ns: "components" })}
                  </Typography>
                </Grid>

                <Grid item xs={12} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("E_POSTA")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {invitation.email}
                  </Typography>
                </Grid>

                <Grid item xs={12} className={labelClasses.LabelLastRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("PHONE_NUMBER")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {invitation.phone}
                  </Typography>
                </Grid>

                <Grid item xs={10}></Grid>
                <Grid item xs={2}>
                  {invitation.status === "WAITING" && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      size="small"
                      startIcon={<Close />}
                      onClick={() => {
                        deleteInvitation();
                      }}
                      title="Sil"
                    >
                      Sil
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </FaDialog>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {renderDetailDialog()}
        {renderEditDialog()}
        {subscriptionError && (
          <FeatureNotAllowed
            open={subscriptionError}
            onClose={() => {
              setSubscriptionError(false);
            }}
          />
        )}
        <ThingsDataTable
          searchUrl={INVITATION.search + "?accountId=" + localStorage.accountId}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item lg={2} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={onAddClick}
              startIcon={<Add />}
              data-fa-button="ADD"
            >
              {t("ADD")}
            </FaButton>
          </Grid>
          <Grid item xs={4}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <FaInput
                label={t("AD_SOYAD")}
                value={searchForm.fullname}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "fullname",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("E_POSTA")}
                value={searchForm.email}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "email",
                    event.target.value
                  );
                }}
              />
              <FaInput
                label={t("PHONE_NUMBER")}
                value={searchForm.phone}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "phone",
                    event.target.value
                  );
                }}
              />
              <InvitationStatusSelect
                value={searchForm.status}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    status: data,
                  });
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>

        <Adsense value="RklmAlt" />
      </Suspense>
    </>
  );
}
