import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function TimePeriodSelect(props) {
  const { value, onChange, required } = props;
  const classes = useStyles();
  const { t } = useTranslation("components", { i18n });

  return (
    <FormControl variant="outlined" className={classes.w100} size="small">
      <InputLabel>{t("TIME_PERIOD")}</InputLabel>
      <Select
        // classes={{
        //   outlined: classes.padding10,
        // }}
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        required={required}
        label={t("TIME_PERIOD")}
      >
        <MenuItem value={null}>...</MenuItem>
        <MenuItem value={"MINUTE"}>{t("TIME_PERIOD_MINUTE")}</MenuItem>
        <MenuItem value={"HOUR"}>{t("TIME_PERIOD_HOUR")}</MenuItem>
        <MenuItem value={"DAY"}>{t("TIME_PERIOD_DAY")}</MenuItem>
        <MenuItem value={"WEEK"}>{t("TIME_PERIOD_WEEK")}</MenuItem>
        <MenuItem value={"MONTH"}>{t("TIME_PERIOD_MONTH")}</MenuItem>
        <MenuItem value={"YEAR"}>{t("TIME_PERIOD_YEAR")}</MenuItem>
      </Select>
    </FormControl>
  );
}
