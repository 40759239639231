import { Card, Chip, Grid } from "@material-ui/core";
import { AddCircleOutline, CheckCircleOutline } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getCurrentLangCode,
  getDateTimeWFormat,
  GetFieldByLanguage,
} from "services/utils";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function SubscriptionTrialPlan(props) {
  const { value, onSelect, period, activeSubscription, trial } = props;

  const classes = useStyles();
  const { t } = useTranslation("subscriptions", { i18n });
  const [lang, setLang] = useState(getCurrentLangCode());

  const campaignSelected = (campagin) => {
    onSelect(campagin);
  };

  const getSubscriptionEndDateStr = () => {
    if (activeSubscription.endDate != null)
      return getDateTimeWFormat(activeSubscription.endDate) + " tarihine kadar";
    else return "Zaman sınırı yok";
  };

  const renderIcon = (type) => {
    if (type === "PACKAGE_INCLUDE") return <CheckCircleOutline />;
    else return <AddCircleOutline />;
  };

  const renderContent = (item, campaign) => {
    const text = GetFieldByLanguage(item.content, lang);
    if (item.type === "THING_COUNT") {
      return text ? text.replace("?", campaign?.thingCount) : text;
    } else if (item.type === "USER_COUNT") {
      return text ? text.replace("?", campaign?.userCount) : text;
    } else if (item.type === "STORAGE_AREA") {
      return text
        ? text.replace("?", campaign?.diskSpace + " " + campaign?.diskUnit)
        : text;
    } else return text;
  };

  const renderPriceString = (price, currency) => {
    if (price && price > 0) return currency + " " + price;
    else return "Ücretsiz";
  };

  const renderPlan = (campaign) => {
    const active =
      activeSubscription && activeSubscription.salesPackage.id === campaign.id;

    return (
      <Grid item xs={12}>
        <Card key={campaign.id} className={classes.campaignCard} elevation={6}>
          <Grid container direction="row" alignItems="flex-start">
            {active && (
              <Grid item xs={12} style={{ position: "relative", padding: 5 }}>
                <Chip label="Mevcut Plan" color="primary" size="small" />
              </Grid>
            )}
            {!active && (
              <Grid
                item
                xs={12}
                style={{ position: "relative", padding: 5, height: 35 }}
              ></Grid>
            )}
            <Grid item xs={12} className={classes.campaignCardHeader}>
              {campaign.name}
            </Grid>
            <Grid item xs={12} className={classes.campaignCardDescription}>
              {((period === "YEARLY" &&
                campaign.priceYearly !== campaign.discountPriceYearly) ||
                (period === "MONTHLY" &&
                  campaign.priceMonthly !== campaign.discountPriceMonthly)) && (
                <span className={classes.oldPrice}>
                  ₺
                  {period === "YEARLY"
                    ? campaign.priceYearly
                    : campaign.priceMonthly}
                </span>
              )}

              <span className={classes.price}>
                {renderPriceString(
                  period === "YEARLY"
                    ? campaign.discountPriceYearly
                    : campaign.discountPriceMonthly,
                  "₺"
                )}
              </span>
              <span className={classes.description}>
                {campaign.description}
              </span>
            </Grid>

            {campaign.saleable !== true && (
              <Grid item xs={12} className={classes.campaignCardButton}></Grid>
            )}
            <Grid
              item
              xs={12}
              style={{ padding: 5, minHeight: 30, textAlign: "center" }}
            >
              {active && getSubscriptionEndDateStr()}
              {!active && " "}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  };

  return renderPlan(value);
}
