import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { AccountTree, Add } from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Check";
import Adsense from "components/adsense/Adsense";
import LocationAutoComplete from "components/autocomplete/LocationAutoComplete";
import OrganizationAutoComplete from "components/autocomplete/OrganizationAutoComplete";
import ThingClassAutoComplete from "components/autocomplete/ThingClassAutoComplete";
import UserAndGroupAutoComplete from "components/autocomplete/UserAndGroupAutoComplete";
import VendorAutoComplete from "components/autocomplete/VendorAutoComplete";
import DateButtonGroup from "components/buttonGroup/DateButtonGroup";
import HashtagButtonGroup from "components/buttonGroup/HashtagButtonGroup";
import YesNoButtonGroup from "components/buttonGroup/YesNoButtonGroup";
import DateRangeSelector from "components/dateRangeSelector";
import UserDetail from "components/detail/UserDetail";
import ThingHashtag from "components/hashtag/ThingHashtag";
import RightBarDrawer from "components/rigthBarDrawer";
import YesNoSelect from "components/select/YesNoSelect";
import ThingsDataTable from "components/thingsDataTable";
import FeatureNotAllowed from "features/subscriptions/FeatureNotAllowed";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { FaButton, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { getRole } from "react-base-fa/dist/services/sessionSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "serviceWorker";
import { ThingService } from "services/thing/thingService";
import { ThingDesc, calculateDate, isTNF } from "services/utils";
import { ROLES } from "services/utils/Roles";
import { checkRole } from "services/utils/SecurityUtils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { THING } from "../../services/faApi";
import ThingCreate from "./ThingCreate";
import { useStyles } from "./style";
import "./things.css";

export default function Things() {
  const { t } = useTranslation(["things", "errorMessages"], { i18n });
  const labelClasses = labelStyles();
  const tableRef = useRef();
  const dialogRef = useRef();
  let history = useHistory();
  const role = useSelector(getRole);
  let queryParams = qsParse(history.location.search);
  queryParams["accountId"] = localStorage.accountId;

  const accountId = localStorage.accountId;
  const [selectedRows, setSelectedRows] = useState([]);
  const [thingDTO, setThingDTO] = useState({
    description: "",
    status: "ACTIVE",
    serviceStatus: "IN_SERVICE",
    thingcode: "",
    account: "",
    thid: "",
    thingClass: "",
    hashtag: "",
    vendor: null,
  });

  const newSearchForm = () => {
    let result = {
      thingClass: null,
      thingClassId: null,
      thingClassList: [],
      thingClassIdList: [],
      description: null,
      thingCode: null,
      hashtag: null,
      vendor: null,
      vendorId: null,
      vendorList: [],
      vendorIdList: [],
      summary: "",
      accountId: accountId,
      status: null,
      createDateValue: null,
      createDateStart: null,
      createDateEnd: null,
      images: true,
      openIssues: null,
      organizationList: [],
      organizationIdList: [],
      locationList: [],
      locationIdList: [],
      ownerUser: null,
      ownerUserGroup: null,
      mtncUser: null,
      mtncUserGroup: null,
      user: null,
      userGroup: null,
      ownerUserId: null,
      ownerUserGroupId: null,
      mtncUserId: null,
      mtncUserGroupId: null,
      userId: null,
      userGroupId: null,
      thid: null,
      archive: false,
    };

    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [subscriptionError, setSubscriptionError] = useState(false);
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const columns = [
    {
      field: "identifier",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      width: "40%",
      valueFormatter: (value, row) => {
        //if(row.thumbnail != null)
        //FIXME public url haline getirildi
        if (row.thumbnailUrl != null) {
          console.log("row.thumbnailUrl", row.thumbnailUrl);
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={
                  BASE_URL +
                  THING.thumbnail +
                  "/" +
                  row.id +
                  "/" +
                  row.thumbnailUrl
                }
                style={{
                  width: "48px",
                  height: "40px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              />{" "}
              {value}
            </div>
          );
        } else if (row.thumbnail != null)
          //return <div style={{display: "flex", alignItems: "center"}}><img src={BASE_URL + THING.getImage + "?thingId=0" + "&objectId=" + row.defaultThumbnail } style={{width: "48px", height: "40px", marginRight: "10px"}} /> {value}</div>
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                alt="thing"
                src={`data:image/png;base64, ${row.thumbnail}`}
                style={{
                  width: "48px",
                  height: "48px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              />{" "}
              {value}
            </div>
          );
        else
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="square"
                style={{
                  width: "48px",
                  height: "48px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              >
                {" "}
                {value.charAt(0)}
              </Avatar>{" "}
              {value}
            </div>
          );
      },
    },
    {
      field: "organization",
      label: t("ORGANIZATION"),
      align: "center",
      width: "20%",
      inner: "name",
      valueFormatter: (value) => {
        return value?.name;
      },
    },
    {
      field: "ownerUser",
      label: t("OWNER"),
      align: "center",
      width: "20%",
      inner: "fullname",
      valueFormatter: (value) => {
        return <UserDetail value={value} type="USER" />;
      },
    },
    {
      field: "createDate",
      label: t("CREATE_DATE"),
      align: "center",
      width: "10%",
      type: "smartDate",
    },
    {
      field: "openIssueCount",
      label: t("OPEN_ISSUES"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        if (value && value > 0)
          return (
            <Badge
              badgeContent={value}
              color="secondary"
              style={{ paddingLeft: 12 }}
              overlap="rectangular"
            ></Badge>
          );
        return <CheckIcon />;
      },
    },
  ];

  useEffect(() => {
    if (selectedRows.length == 1) {
      setThingDTO(selectedRows[0]);
    } else {
      setThingDTO({
        description: "",
        status: "ACTIVE",
        serviceStatus: "IN_SERVICE",
        thingcode: "",
        account: "",
        thid: "",
        thingClass: "",
        hashtag: "",
        vendor: null,
      });
    }
  }, [selectedRows]);

  const onRowEdit = (event, row) => {
    const tmpTo = {
      pathname: `thingDetail/${row.id}`,
      breadCrumbKey: `/thingDetail/:id`,
      customLabel: ThingDesc(row),
    };
    history.push(tmpTo);
  };

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `thingDetail/${row.id}`,
      breadCrumbKey: `/thingDetail/:id`,
      customLabel: ThingDesc(row),
    };
    history.push(tmpTo);
  };

  const closeDrawer = () => {
    clearSelectedRows();
  };

  const clearSelectedRows = () => {
    tableRef.current.clearSelectedRows();
  };

  const onSelectedChange = (rows) => {
    setSelectedRows(rows);
  };

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const navigatePrintQR = () => {
    let selectedIds = [];

    selectedRows.map((row) => selectedIds.push(row.id));

    const tmpTo = {
      pathname: `/print-qr`,
      state: {
        selectedIds: selectedIds,
      },
    };
    history.push(tmpTo);
  };

  const renderDrawerContent = () => {
    if (selectedRows.length == 1) {
      return (
        <Grid
          item
          container
          className={classes.drawerContent}
          alignContent="space-between"
        >
          <Grid
            item
            xs={11}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {thingDTO.thumbnail && (
              <img
                src={`data:image/png;base64, ${thingDTO.thumbnail}`}
                style={{ width: "48px", marginRight: "2px" }}
              />
            )}
            {!thingDTO.thumbnail && (
              <Avatar
                className={classes.avatar}
                variant="square"
                style={{ width: "48px", marginRight: "2px" }}
              >
                {" "}
                {thingDTO?.identifier?.charAt(0)}
              </Avatar>
            )}

            <Typography>{thingDTO.description}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="caption">{t("THING_CLASS")}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{thingDTO.thingClass.className}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="caption">{t("OWNER")}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>
              {thingDTO.ownerUser && thingDTO.ownerUser.fullname}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="caption">{t("DATE")}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{thingDTO.createDate}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="caption">{t("DOMAIN")}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{thingDTO.thingClass.domain}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="caption">{t("HASHTAG")}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{thingDTO.hashtag}</Typography>
          </Grid>
          <Grid
            item
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={4}>
              <FaButton
                label={t("EXAMINE")}
                required
                className={classes.success}
                onClick={(event) => {
                  onRowEdit(null, thingDTO);
                }}
                value={thingDTO.description || ""}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    } else if (selectedRows.length > 1) {
      return (
        <Grid
          item
          container
          justifyContent="center"
          spacing={3}
          className={classes.drawerContent}
        >
          <Grid style={{ alignSelf: "flex-end" }} item xs={12}>
            <Typography
              align="center"
              variant="h6"
              component="h6"
              color="textPrimary"
            >
              {t("MULTI_SELECT", { amount: selectedRows.length })}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <FaButton
              label={t("QR Etiket Bas")}
              className={classes.success}
              onClick={navigatePrintQR}
            />
          </Grid>
        </Grid>
      );
    }
  };

  const onThingCreate = (data) => {
    const tmpTo = {
      pathname: `thingDetail/${data.id}`,
      breadCrumbKey: `/thingDetail/:id`,
      customLabel: ThingDesc(data),
    };
    history.push(tmpTo);
  };

  const newThingRegisterRender = () => {
    return <ThingCreate ref={dialogRef} onCreate={onThingCreate}></ThingCreate>;
  };

  const onAddClick = () => {
    ThingService.checkAddNewThing(
      accountId,
      (data) => {
        if (data) {
          clearSelectedRows();
          dialogRef.current.openDialog();
        } else {
          setSubscriptionError(true);
        }
      },
      (error) => {
        setSubscriptionError(true);
      }
    );
  };

  const onListByThingClass = () => {
    const tmpTo = {
      pathname: `thingsByClass`,
      breadCrumbKey: `/thingsByClass`,
      search: "?page=0&size=10&isAsc=false&sort=createDate",
    };
    history.push(tmpTo);
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {newThingRegisterRender()}
        <RightBarDrawer open={selectedRows.length > 0} onClose={closeDrawer}>
          {renderDrawerContent()}
        </RightBarDrawer>
        {subscriptionError && (
          <FeatureNotAllowed
            open={subscriptionError}
            onClose={() => {
              setSubscriptionError(false);
            }}
          />
        )}

        <Grid item container spacing={1} style={{ marginBottom: 5 }}>
          <Grid item lg={3} xs={6}>
            <Grid item xs={12} className={labelClasses.LabelLastRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("OPEN_ISSUES")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <YesNoButtonGroup
                value={searchForm.openIssues}
                onChange={(data) => {
                  const temp = { ...searchForm, openIssues: data };
                  setSearchForm(temp);
                  tableRef.current.search(temp);
                }}
                // style={{ height: 60 }}
              />
            </Grid>
          </Grid>

          <Grid item lg={4} xs={6}>
            <Grid item xs={12} className={labelClasses.LabelLastRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("CREATE_DATE")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <DateButtonGroup
                  value={searchForm.createDateValue}
                  onChange={(data) => {
                    const temp = {
                      ...searchForm,
                      createDateValue: data,
                      createDateStart: calculateDate(data),
                    };

                    setSearchForm(temp);
                    tableRef.current.search(temp);
                  }}
                  // style={{ height: 60 }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={5} xs={8}>
            <Grid item xs={12} className={labelClasses.LabelLastRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("HASHTAG")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <HashtagButtonGroup
                  value={searchForm.hashtag}
                  onChange={(data) => {
                    const temp = {
                      ...searchForm,
                      hashtag: data,
                    };

                    setSearchForm(temp);
                    tableRef.current.search(temp);
                  }}
                  // style={{ height: 60 }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <ThingsDataTable
          searchUrl={THING.search + "?accountId=" + localStorage.accountId}
          exportUrl={THING.export + "?accountId=" + localStorage.accountId}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          onSelectedChange={onSelectedChange}
          showDeleteButton={false}
          showHeaderText={false}
          headerText={t("VARLIK_LISTESI")}
          faOnDoubleClick={onDoubleClick}
          data-fa-list="List"
          queryParams={{
            page: 0,
            size: 10,
            isAsc: false,
            sort: "createDate",
            accountId: localStorage.accountId,
          }}
        />

        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={1} className={"thingsDataTableButtons"}>
            {checkRole(
              [
                ROLES.OPERATOR,
                ROLES.THINGMANAGER,
                ROLES.POWER_USER,
                ROLES.ACCOUNTOWNER,
              ],
              role
            ) && (
              <FaButton
                variant="contained"
                disabled={loading}
                color="primary"
                size="medium"
                faClick={onAddClick}
                startIcon={<Add />}
                data-fa-button="ADD"
              >
                {t("ADD")}
              </FaButton>
            )}
          </Grid>
          <Grid item xs={2} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={onListByThingClass}
              startIcon={<AccountTree />}
              data-fa-button="LIST"
            >
              {t("THINGS_BY_CLASS")}
            </FaButton>
          </Grid>
          <Grid item xs={2}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <ThingClassAutoComplete
                accountId={accountId}
                value={searchForm.thingClassList}
                filterExists={true}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    thingClassList: data,
                    thingClassIdList: data?.map((item) => {
                      return item.id;
                    }),
                  });
                }}
                multiple
                data-fa-search="THING_CLASS"
              />
              <FaInput
                label={t("DESCRIPTION")}
                value={searchForm.description}
                onChange={(event) => {
                  setSearchForm({
                    ...searchForm,
                    description: event.target.value,
                  });
                }}
                data-fa-search="DESCRIPTION"
              />
              <FaInput
                label={t("THING_CODE")}
                value={searchForm.thingCode}
                onChange={(event) => {
                  setSearchForm({
                    ...searchForm,
                    thingCode: event.target.value,
                  });
                }}
                data-fa-search="THING_CODE"
              />
              <FaInput
                label={t("Nesne Kimlik Numarası")}
                value={searchForm.thid}
                onChange={(event) => {
                  setSearchForm({
                    ...searchForm,
                    thid: event.target.value,
                  });
                }}
                data-fa-search="Nesne Kimlik Numarası"
              />

              <ThingHashtag
                label={t("HASHTAG")}
                accountId={accountId}
                value={searchForm.hashtag}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    hashtag: data,
                  });
                }}
                data-fa-search="HASHTAG"
              />

              <OrganizationAutoComplete
                accountId={accountId}
                value={searchForm.organizationList}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    organizationList: data,
                    organizationIdList: data?.map((item) => {
                      return item.id;
                    }),
                  });
                }}
                multiple
                data-fa-search="ORGANIZATION"
              />
              <LocationAutoComplete
                accountId={accountId}
                value={searchForm.locationList}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    locationList: data,
                    locationIdList: data?.map((item) => {
                      return item.id;
                    }),
                  });
                }}
                multiple
                data-fa-search="LOCATION"
              />
              <UserAndGroupAutoComplete
                label={t("OWNER")}
                value={{
                  user: searchForm?.ownerUser,
                  userGroup: searchForm?.ownerUserGroup,
                }}
                accountId={accountId}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    ownerUser: data && data.user ? data.user : null,
                    ownerUserId: data && data.user ? data.user?.id : null,
                    ownerUserGroup:
                      data && data.userGroup ? data.userGroup : null,
                    ownerUserGroupId:
                      data && data.userGroup ? data.userGroup?.id : null,
                  });
                }}
                data-fa-search="OWNER"
              />
              <UserAndGroupAutoComplete
                label={t("MTNC")}
                value={{
                  user: searchForm?.mtncUser,
                  userGroup: searchForm?.mtncUserGroup,
                }}
                accountId={accountId}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    mtncUser: data && data.user ? data.user : null,
                    mtncUserId: data && data.user ? data.user?.id : null,
                    mtncUserGroup:
                      data && data.userGroup ? data.userGroup : null,
                    mtncUserGroupId:
                      data && data.userGroup ? data.userGroup?.id : null,
                  });
                }}
                data-fa-search="MTNC"
              />
              <UserAndGroupAutoComplete
                label={t("USER")}
                value={{
                  user: searchForm?.user,
                  userGroup: searchForm?.userGroup,
                }}
                accountId={accountId}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    user: data && data.user ? data.user : null,
                    userId: data && data.user ? data.user?.id : null,
                    userGroup: data && data.userGroup ? data.userGroup : null,
                    userGroupId:
                      data && data.userGroup ? data.userGroup?.id : null,
                  });
                }}
                data-fa-search="USER"
              />
              <VendorAutoComplete
                accountId={accountId}
                value={searchForm.vendorList}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    vendorList: data,
                    vendorIdList: data?.map((item) => {
                      return item.id;
                    }),
                  });
                }}
                multiple
                data-fa-search="VENDOR"
              />

              <YesNoSelect
                value={searchForm.openIssues}
                label={t("OPEN_ISSUES")}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    openIssues: data,
                  });
                }}
                yesLabel={t("SERVICE_STATUS_YES")}
                noLabel={t("SERVICE_STATUS_NO")}
                data-fa-search="OPEN_ISSUES"
              />

              <DateRangeSelector
                label={t("CREATE_DATE")}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    createDateStart: data.startDate,
                    createDateEnd: data.endDate,
                  });
                }}
                data-fa-search="TARIH"
              />
            </FaSearch>
          </Grid>
        </Grid>

        <Adsense value="RklmAlt" />
      </Suspense>
    </>
  );
}
