import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function UserDetail(props) {
  const { value, type, user, userGroup, ...other } = props; // type: USER, USER_GROUP

  const xtype = type ? type : user ? "USER" : "USER_GROUP";
  const xvalue = value ? value : user ?? userGroup;

  return (
    <React.Fragment>
      <div {...other}>
        {/* {type === "USER" && (
          <FontAwesomeIcon icon={faUserAlt} style={{ paddingRight: 4 }} />
        )} */}
        {xtype === "USER_GROUP" && xvalue?.name && (
          <FontAwesomeIcon icon={faUsers} style={{ paddingRight: 4 }} />
        )}
        {xtype === "USER" && xvalue?.fullname}
        {xtype === "USER_GROUP" && xvalue?.name}

        {xtype === "USER" && xvalue?.title && (
          <div
            style={{
              fontSize: "11px",
            }}
          >
            {xvalue.title}
          </div>
        )}
        {xtype === "USER" && (xvalue?.department || xvalue?.company) && (
          <div
            style={{
              fontSize: "11px",
            }}
          >
            {xvalue?.department}
            {xvalue?.department && xvalue?.company && ","}
            {xvalue?.company}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
