import PropTypes from "prop-types";
import React from "react";
import { APP, LOCALSTORAGE } from "services/utils/Constants";
import SignUp from ".";

export default function SignUpThings2Care(props) {
  const { backGroundImage, loginPath, appLogo } = props;

  localStorage.setItem(LOCALSTORAGE.APP, APP.T2C);

  return (
    <SignUp
      backGroundImage={backGroundImage}
      loginPath={loginPath}
      appLogo={appLogo}
    />
  );
}

SignUpThings2Care.propTypes = {
  loginPath: PropTypes.string,
  backGroundImage: PropTypes.any,
  appLogo: PropTypes.any,
};
