import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { LOCATION } from "./faApi";

export const LocationService = {
  save: (location, callback, errorCallback) => {
    let reqBody = {
      ...location,
    };

    let req = requests.getSimplePostRequest(LOCATION.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (data, callback, errorCallback) => {
    let reqBody = {
      ...data,
    };

    let req = requests.getSimplePostRequest(LOCATION.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(LOCATION.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findByName: (accountId, name, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      LOCATION.findByName +
        "?accountId=" +
        accountId +
        "&name=" +
        encodeURI(name)
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  upload: (id, image, callback, errorCallback) => {
    let formData = new FormData();
    formData.append("multipartFile", image, image.name);
    let req = requests.getSimplePostRequest(
      LOCATION.uploadFile + "/" + id,
      formData
    );
    req["header"] = { "Content-Type": "multipart/form-data" };

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  deleteFile: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(LOCATION.deleteFile + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
