import { Badge, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ReportService } from "services/report/reportService";
import i18n from "../../../i18n";
import { useStyles } from "./style";

export default function PublicSinglePhoto(props) {
  const { publicThing, imageOnClick } = props;
  const classes = useStyles();
  const { t } = useTranslation("report", { i18n });
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const location = useLocation();
  const [image, setImage] = useState(null);
  const [imageCount, setImageCount] = useState(0);

  const [selectedHdImageIndex, setSelectedHdImageIndex] = useState(0);
  const [isCarouselOpen, setCarouselOpen] = useState(false);

  useEffect(() => {
    if (publicThing && publicThing.id) {
      refreshThingImages();
    }
  }, [publicThing]);

  const refreshThingImages = () => {
    ReportService.getDefaultImage(
      publicThing.id,
      (data) => {
        setImage(data);
        setLoading(false);
      },
      (error) => {}
    );

    ReportService.getImageCount(
      publicThing.id,
      (data) => {
        setImageCount(data);
      },
      (error) => {}
    );
  };

  return (
    <Grid
      item
      container
      spacing={1}
      alignItems="center"
      justifyContent="center"
    >
      {image && image.content && (
        <div
          className={classes.image}
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (imageCount > 1) imageOnClick();
          }}
        >
          <img
            src={`data:image/png;base64, ${image.content}`}
            style={{
              width: "100%",
              borderRadius: 8,
              border: "none",
              height: "100%",
              position: "absolute",
              left: 0,
              top: 0,
              right: "auto",
            }}
            alt=""
          />

          {imageCount > 1 && (
            <Badge
              badgeContent={"1/" + imageCount}
              classes={{ badge: classes.badgeColor }}
              style={{
                position: "absolute",
                right: 25,
                bottom: 25,
              }}
            ></Badge>
          )}
        </div>
      )}
    </Grid>
  );
}

PublicSinglePhoto.propTypes = {
  loginPath: PropTypes.string,
  appLogo: PropTypes.any,
};
