import SvgIcon from "@material-ui/core/SvgIcon";
import { alpha, withStyles } from "@material-ui/core/styles";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import {
  default as React,
  Suspense,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { LocationService } from "services/LocationService";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    "& .close": {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}))((props) => <TreeItem {...props} />);

const LocationTree = forwardRef((props, ref) => {
  //export default function OrganizationTree(props) {
  const { onChange, accountId, selectDefault, ...other } = props;
  const tableRef = useRef();
  const { t } = useTranslation("location", { i18n });

  const [loading, setLoading] = useState(false);
  const [defaultExpandedIds, setDefaultExpandedIds] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [locationList, setLocationList] = useState([]);

  useEffect(() => {
    populateLocationList();
  }, [accountId]);

  const onDoubleClick = (row) => {
    //setOrganization(row);
    //setIsEditFormOpen(true);
  };

  const populateLocationList = () => {
    if (accountId) {
      LocationService.findByName(
        accountId,
        "",
        (data) => {
          setLocationList(data.list);
          const ids = data.list.map((item) => {
            return item.id;
          });
          setDefaultExpandedIds(ids);

          if (selectDefault && data.list && data.list.length > 0) {
            for (var i = 0; i < data.list.length; i++) {
              if (
                data.list[i].locationDetails &&
                data.list[i].locationDetails.length > 0
              ) {
                setSelectedItem(data.list[i].locationDetails[0]);
                onChange(data.list[i].locationDetails[0]);
                break;
              }
            }
          }
        },
        () => {}
      );
    }
  };

  const renderLocation = (location) => {
    return (
      <StyledTreeItem nodeId={location.id} label={location.name}>
        {renderLocationDetails(location.locationDetails)}
      </StyledTreeItem>
    );
  };

  const renderLocationDetails = (locationDetails) => {
    return locationDetails.map((item, index) => {
      return (
        <StyledTreeItem
          nodeId={item.id}
          label={item.name}
          onClick={() => {
            console.log("clicked", item);
            setSelectedItem(item);
            onChange(item);
          }}
        ></StyledTreeItem>
      );
    });
  };

  const config = {
    default: {
      style: { minHeight: 200, minWidth: 400 },
    },
  };

  const mergedProps = { ...config.default, ...other };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <div {...mergedProps}>
          {defaultExpandedIds && defaultExpandedIds.length > 0 && (
            <TreeView
              defaultExpanded={[...defaultExpandedIds]}
              defaultCollapseIcon={<MinusSquare />}
              defaultExpandIcon={<PlusSquare />}
              //defaultEndIcon={<CloseSquare />}
              selected={selectedItem ? selectedItem.id : null}
            >
              {locationList.map((item, index) => {
                return renderLocation(item);
              })}
            </TreeView>
          )}
        </div>
      </Suspense>
    </>
  );
});
export default LocationTree;
