import {
  faBug,
  faExclamationCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Badge, CardHeader, IconButton } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { Check } from "@material-ui/icons";
import ThingsDataTable from "components/thingsDataTable";
import IssueCreate from "features/issues/IssueCreate";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { THING } from "services/faApi";
import { ThingDesc, isTNF } from "services/utils";
import i18n from "../../../i18n";

export default function HomepageMyThings(props) {
  const { size, refreshCount } = props;
  const { t } = useTranslation(["things", "components"], { i18n });
  const tableRef = useRef();
  const dialogRef = useRef();
  let history = useHistory();
  const [selectedThing, setSelectedThing] = useState(null);

  useEffect(() => {
    if (refreshCount > 0) {
      tableRef.current.refreshTable();
    }
  }, [refreshCount]);

  const columns = [
    {
      field: "identifier",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      width: "30%",
      valueFormatter: (value, row) => {
        //if(row.thumbnail != null)
        //FIXME public url haline getirildi
        if (row.thumbnail != null)
          //return <div style={{display: "flex", alignItems: "center"}}><img src={BASE_URL + THING.getImage + "?thingId=0" + "&objectId=" + row.defaultThumbnail } style={{width: "48px", height: "40px", marginRight: "10px"}} /> {value}</div>
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                alt="thing"
                src={`data:image/png;base64, ${row.thumbnail}`}
                style={{
                  width: "48px",
                  height: "48px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              />{" "}
              {value}
            </div>
          );
        else
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="square"
                style={{
                  width: "48px",
                  height: "48px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              >
                {" "}
                {value.charAt(0)}
              </Avatar>{" "}
              {value}
            </div>
          );
      },
    },
    {
      field: "organization",
      label: t("ORGANIZATION"),
      align: "center",
      width: "20%",
      inner: "name",
      valueFormatter: (value) => {
        return value?.name;
      },
    },
    {
      field: "ownerUser",
      label: t("OWNER"),
      align: "center",
      width: "20%",
      inner: "fullname",
      valueFormatter: (value) => {
        return value?.fullname;
      },
    },
    {
      field: "status",
      label: t("STATUS"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        return t(value);
      },
    },
    {
      field: "openIssueCount",
      label: t("SERVICE_STATUS"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        if (value && value > 0)
          return (
            <Badge
              badgeContent={value}
              color="secondary"
              style={{ paddingLeft: 12 }}
            ></Badge>
          );
        return <Check />;
      },
    },
    {
      field: "id2",
      label: t("OPERATIONS"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        return (
          <div style={{ display: "flex" }}>
            <IconButton
              onClick={() => {
                onIssueList(row);
              }}
              size="small"
              color="primary"
              title={t("Bildirimler")}
            >
              <FontAwesomeIcon icon={faExclamationCircle} />
            </IconButton>
            <IconButton
              onClick={() => {
                onIssueReport(row);
              }}
              size="small"
              color="primary"
              title={t("Sorun Bildir")}
            >
              <FontAwesomeIcon icon={faBug} />
            </IconButton>
            <IconButton
              onClick={() => {
                onDoubleClick(row);
              }}
              size="small"
              color="primary"
              title={t("Detay")}
            >
              <FontAwesomeIcon icon={faSearch} />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const newSearchForm = () => {
    return {
      summary: null,
      accountId: localStorage.accountId,
      myThings: true,
    };
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `thingDetail/${row.id}`,
      breadCrumbKey: `/thingDetail/:id`,
      customLabel: ThingDesc(row),
    };
    history.push(tmpTo);
  };

  const onIssueList = (row) => {
    const tmpTo = {
      pathname: `issues`,
      breadCrumbKey: `/issues`,
      search:
        "?page=0&size=10&isAsc=false&sort=notificationDate&accountId=" +
        localStorage.accountId,
      state: {
        thing: row,
      },
    };
    history.push(tmpTo);
  };

  const onIssueReport = (row) => {
    setSelectedThing(row);
    dialogRef.current.openDialog();
  };

  const onIssueCreate = (data) => {
    const tmpTo = {
      pathname: `issues/detail/${data.id}`,
      breadCrumbKey: `/issues/detail/:id`,
    };
    history.push(tmpTo);
  };

  return (
    <>
      <Grid item lg={size} xs={12}>
        <Card>
          <CardHeader
            title={isTNF(
              t("HOMEPAGE_ITEM.MY_THINGS", { ns: "components" }),
              t("HOMEPAGE_ITEM.MY_THINGS_T2C", { ns: "components" })
            )}
          />
        </Card>
        <ThingsDataTable
          searchUrl={THING.search + "?accountId=" + localStorage.accountId}
          exportUrl={THING.export + "?accountId=" + localStorage.accountId}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          //onSelectedChange={onSelectedChange}
          showDeleteButton={false}
          showHeaderText={false}
          headerText={t("VARLIK_LISTESI")}
          faOnDoubleClick={onDoubleClick}
          queryParams={{
            page: 0,
            size: 5,
            sort: "id",
            isAsc: false,
          }}
        />
        <IssueCreate
          ref={dialogRef}
          onCreate={onIssueCreate}
          thing={selectedThing}
        ></IssueCreate>
      </Grid>
    </>
  );
}
