import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LoadingBar from "components/loadingBar";
import i18n from "i18n";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import { FaButton } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import ReactExport from "react-export-excel";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { SurveyService } from "services/SurveyService";
import { getDateTimeWFormat } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";

export default function SurveyAllAnswers() {
  const labelClasses = labelStyles();
  const { t } = useTranslation("survey", { i18n });
  const { id } = useParams();
  const accountId = localStorage.accountId;
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const dialogRef = useRef();
  let history = useHistory();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [loading, setLoading] = useState(false);
  const [analysis, setAnalysis] = useState(null);
  const [answers, setAnswers] = useState([]);

  const [excel, setExcel] = useState(false);

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    setLoading(true);
    SurveyService.surveyAllAnswers(id, findByIdOnSuccess, findByIdOnError);
  };

  const findByIdOnSuccess = (data) => {
    setAnalysis({
      ...data,
    });

    let rows = [];

    if (data && data.answerList && data.surveyQuestions) {
      data.answerList.forEach((item, index) => {
        let row = {
          orderNo: index + 1,
          date: getDateTimeWFormat(item?.answerDate),
          name: item?.customer?.name,
          surname: item?.customer?.surname,
          email: item?.customer?.email,
          phone: item?.customer?.phone,
        };

        data.surveyQuestions.forEach((question, qIndex) => {
          row["answer" + qIndex] = renderAnswer(item, question);
        });

        rows.push(row);
      });
    }

    setAnswers(rows);

    setLoading(false);
  };

  const findByIdOnError = (error) => {
    console.log("findByIdOnError", error);
    showAlert(error, "error");
    setLoading(false);
  };

  const renderAnswer = (answer, question) => {
    let value = null;

    if (answer && question) {
      let temp = answer.details.find(
        (x) => x.surveyQuestion.id === question.id
      );

      if (
        question.questionType === "MULTIPLE_CHOICE" ||
        question.questionType === "DROPDOWN" ||
        question.questionType === "CHECKBOX"
      ) {
        value = temp?.surveyQuestionOption?.multiLangName
          ? temp?.surveyQuestionOption?.multiLangName["tr"]
          : null;
      } else if (
        question.questionType === "STAR_RATING" ||
        question.questionType === "INTEGER" ||
        question.questionType === "DECIMAL" ||
        question.questionType === "SLIDER" ||
        question.questionType === "NET_PROMOTER_SCORE"
      ) {
        value = temp?.dataNumeric;
      } else if (
        question.questionType === "TEXT" ||
        question.questionType === "COMMENT"
      ) {
        value = temp?.dataString;
      } else if (question.questionType === "MATRIX_TEMP") {
      } else if (question.questionType === "YES_NO") {
        value = temp?.dataBool;
      } else {
      }
    }

    return value;
  };

  const renderExcel = () => {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    return (
      <ExcelFile
        filename={t("Anket Sonuçları")}
        element={
          <FaButton
            style={{ width: "20%" }}
            variant="contained"
            disabled={loading}
            color="primary"
            size="medium"
            faClick={() => {
              //console.log("excel basıldı");
            }}
            startIcon={<FontAwesomeIcon icon={faFileExcel} />}
          >
            {t("İndir")}
          </FaButton>
        }
      >
        <ExcelSheet data={answers} name={t("Anket Sonuçları")}>
          <ExcelColumn label="#" value="orderNo" />
          <ExcelColumn label="Tarih" value="date" />
          <ExcelColumn label="Ad" value="name" />
          <ExcelColumn label="Soyad" value="surname" />
          <ExcelColumn label="Eposta" value="email" />
          <ExcelColumn label="Telefon" value="phone" />
          {analysis &&
            analysis.surveyQuestions &&
            analysis.surveyQuestions.map((question, qIndex) => (
              <ExcelColumn
                label={
                  question.orderNo +
                  ". " +
                  (question?.multiLangName !== null
                    ? question?.multiLangName["tr"]
                    : "")
                }
                value={"answer" + qIndex}
              />
            ))}
        </ExcelSheet>
      </ExcelFile>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid container spacing={1}>
          <Grid item lg={12}>
            <Grid item container spacing={1}>
              <Grid item lg={12}>
                <Card>
                  <CardHeader
                    title={t("Toplu Anket Cevapları")}
                    data-fa-section="Analiz"
                  />
                  <CardContent>
                    <Grid item container spacing={1}>
                      <Grid item xs={12}>
                        <Grid item container spacing={1}>
                          <Grid
                            item
                            xs={12}
                            className={labelClasses.LabelLastRow}
                          >
                            <Typography className={labelClasses.LabelHeader}>
                              {t("Anket")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {analysis?.survey.multiLangName !== null
                                ? analysis?.survey.multiLangName["tr"]
                                : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={12}>
            {renderExcel()}
          </Grid>

          <Grid item lg={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width={50}>#</TableCell>
                    <TableCell width={150}>Tarih</TableCell>
                    <TableCell width={150}>Ad</TableCell>
                    <TableCell width={150}>Soyad</TableCell>
                    <TableCell width={150}>Eposta</TableCell>
                    <TableCell width={150}>Telefon</TableCell>
                    {analysis &&
                      analysis.surveyQuestions &&
                      analysis.surveyQuestions.map((item, index) => (
                        <TableCell>
                          {item.orderNo +
                            ". " +
                            (item?.multiLangName !== null
                              ? item?.multiLangName["tr"]
                              : "")}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {answers.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item.date}</TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.surname}</TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell>{item.phone}</TableCell>
                      {analysis &&
                        analysis.surveyQuestions &&
                        analysis.surveyQuestions.map((question, qIndex) => (
                          <TableCell>{item["answer" + qIndex]}</TableCell>
                        ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
