import {
  faChevronDown,
  faChevronUp,
  faEdit,
  faVolumeMute,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import { Add, Alarm, Check, Close } from "@material-ui/icons";
import ThingsDataTable from "components/thingsDataTable";
import ThingClassAdditionalFieldsHelp from "features/help/ThingClassAdditionalFieldsHelp";
import React, { useEffect, useRef, useState } from "react";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { THINGCLASS } from "services/faApi";
import DynamicFieldDialog from "../../components/dynamicFieldDialog";
import i18n from "../../i18n";
import { DynamicFormService } from "../../services/dynamicForm/dynamicFormService";

export default function AdditionalFieldsEdit(props) {
  const { dynamicName, dynamicId } = props;

  const { t } = useTranslation(["dynamicFieldDialog", "additionalFields"], {
    i18n,
  });
  const faDialog = useDialog();
  const tableRef = useRef();

  const accountId = localStorage.accountId;
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [loading, setLoading] = useState(false);
  const [isAddFieldOpen, setIsAddFieldOpen] = useState(false);
  const [dynamicForm, setDynamicForm] = useState(null);
  const [dynamicFormList, setDynamicFormList] = useState([]);
  const [selectedDynamicForm, setSelectedDynamicForm] = useState(null);
  const [openEnableDialog, setOpenEnableDialog] = useState(false);
  const [openDisableDialog, setOpenDisableDialog] = useState(false);

  useEffect(() => {
    DynamicFormService.find(
      dynamicName,
      accountId,
      dynamicId,
      dynamicFormGetSuccess,
      dynamicFormGetError
    );
  }, [dynamicName, accountId, dynamicId]);

  const dynamicFormGetSuccess = (data) => {
    //setDynamicForm(data);
    //setDynamicFormDetail(data[0].detail);
    setDynamicFormList(data);
  };

  const dynamicFormGetError = (data) => {
    setDynamicFormList([]);
  };

  const addDynamicForm = (data, uploadFile) => {
    const d = { ...data, name: dynamicName, id1: accountId, id2: dynamicId };
    setDynamicForm(d);
    DynamicFormService.save(
      d,
      (data) => {
        saveOnSuccess(data, uploadFile);
      },
      saveOnError
    );
  };

  const saveOnSuccess = (data, uploadedFile) => {
    console.log(uploadedFile);

    if (uploadedFile === null || uploadedFile.length === 0) {
      DynamicFormService.find(
        dynamicName,
        accountId,
        dynamicId,
        dynamicFormGetSuccess,
        dynamicFormGetError
      );
      setIsAddFieldOpen(false);
      if (dynamicForm.id === 0) showAlert(t("FIELD_CREATED"), "success");
      else showAlert(t("FIELD_UPDATED"), "success");
    } else {
      uploadedFile.forEach((element) => {
        let formData = new FormData();
        formData.append(
          "multipartFile",
          element.file.file,
          element.file.file.name
        );
        formData.append("dynamicFormId", data.id);
        formData.append("language", element.language);

        let req = requests.getSimplePostRequest(
          THINGCLASS.uploadDocument,
          formData
        );

        req["header"] = { "Content-Type": "multipart/form-data" };

        FaAxios(req)
          .then((response) => {
            DynamicFormService.find(
              dynamicName,
              accountId,
              dynamicId,
              dynamicFormGetSuccess,
              dynamicFormGetError
            );
            setIsAddFieldOpen(false);
            if (dynamicForm.id === 0) showAlert(t("FIELD_CREATED"), "success");
            else showAlert(t("FIELD_UPDATED"), "success");
          })
          .catch((error) => {});
        return () => {
          //setLoading(false);
        };
      });
    }
  };

  const saveOnError = (data) => {};

  const renderFieldDialog = () => {
    return (
      <DynamicFieldDialog
        faOpen={isAddFieldOpen}
        faOnSubmit={addDynamicForm}
        onDelete={refresh}
        faHandleClose={() => {
          setIsAddFieldOpen(false);
        }}
        faEditValue={selectedDynamicForm}
      />
    );
  };

  const onEdit = (row) => {
    setSelectedDynamicForm(row);
    setIsAddFieldOpen(true);
  };

  const refresh = (data) => {
    DynamicFormService.find(
      dynamicName,
      accountId,
      dynamicId,
      dynamicFormGetSuccess,
      dynamicFormGetError
    );

    setOpenEnableDialog(false);
    setOpenDisableDialog(false);
    setIsAddFieldOpen(false);
  };

  const onError = (data) => {
    showAlert("Bağlı kayıtlar mevcut", "error");
  };

  const moveUp = (row) => {
    DynamicFormService.moveUp(
      accountId,
      row.id,
      dynamicName,
      accountId,
      dynamicId,
      refresh,
      onError
    );
  };

  const moveDown = (row) => {
    DynamicFormService.moveDown(
      accountId,
      row.id,
      dynamicName,
      accountId,
      dynamicId,
      refresh,
      onError
    );
  };

  const disableDetail = () => {
    DynamicFormService.disable(
      selectedDynamicForm.id,
      dynamicName,
      accountId,
      dynamicId,
      refresh,
      onError
    );
  };

  const enableDetail = () => {
    DynamicFormService.enable(
      selectedDynamicForm.override.id,
      refresh,
      onError
    );
  };

  const renderMoveButton = (row, up) => {
    return (
      <IconButton
        onClick={() => (up === true ? moveUp(row) : moveDown(row))}
        size="small"
        color="primary"
        aria-label={up === true ? t("MOVE_UP") : t("MOVE_DOWN")}
      >
        <FontAwesomeIcon icon={up === true ? faChevronUp : faChevronDown} />
      </IconButton>
    );
  };

  const columns = [
    {
      field: "orderNo",
      label: t("ORDER_NO"),
      align: "center",
      width: "10%",
    },
    {
      field: "label",
      label: t("LABEL"),
      align: "center",
      width: "20%",
    },
    {
      field: "dataType",
      label: t("FIELD_TYPE"),
      align: "center",
      width: "15%",
      valueFormatter: (value) => {
        return t(value);
      },
    },
    {
      field: "hint",
      label: t("HINT"),
      align: "center",
      width: "15%",
    },
    {
      field: "options",
      label: t("OPTIONS"),
      align: "center",
      width: "15%",
      valueFormatter: (value, row) => {
        let result =
          (row.min ? " Min:" + row.min : "") +
          (row.min ? " Max:" + row.max : "") +
          (row.dataType === "DATE"
            ? (row.past ? " Geçmiş:" + (row.past ? "Evet" : "Hayır") : "") +
              (row.future ? " Gelecek:" + (row.future ? "Evet" : "Hayır") : "")
            : "") +
          (row.dataType === "ENUMS"
            ? row.enumsMultiple === true
              ? "Çoklu Seçim"
              : ""
            : "") +
          (row.options && row.options.length > 0
            ? " Seçenekler:" + row.options
            : "");

        if (row.reminderDateValue) {
          return (
            <>
              {result} <Alarm label="Alarm" style={{ paddingTop: 5 }} />{" "}
              {row.reminderDateValue} {t("" + row.reminderDateType)}
            </>
          );
        }

        return result;
      },
    },
    {
      field: "multiLang",
      label: t("MULTI_LANG"),
      align: "center",
      width: "15%",
      valueFormatter: (val, row) => {
        if (
          row.dataType === "STRING" ||
          row.dataType === "TEXT" ||
          row.dataType === "FILE"
        ) {
          if (val) return <Check />;
          else return <Close />;
        }
      },
    },
    {
      field: "id",
      label: t("OPERATION"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        if (row.id2 == dynamicId) {
          //aynı seviye
          return (
            <>
              <IconButton
                onClick={() => {
                  onEdit(row);
                }}
                size="small"
                color="primary"
                title={t("Düzenle")}
              >
                <FontAwesomeIcon icon={faEdit} />
              </IconButton>
              {renderMoveButton(row, true)}
              {renderMoveButton(row, false)}
            </>
          );
        } else if (
          row.override == null ||
          row.override.disable == null ||
          row.override.disable === false
        ) {
          return (
            <>
              <IconButton
                onClick={() => {
                  setSelectedDynamicForm(row);
                  setOpenDisableDialog(true);
                }}
                size="small"
                color="primary"
                aria-label={t("GECERLI")}
                title={t("GECERLI")}
              >
                <FontAwesomeIcon icon={faVolumeUp} />
              </IconButton>
              {renderMoveButton(row, true)}
              {renderMoveButton(row, false)}
            </>
          );
        } else if (row.override != null && row.override.disable === true) {
          return (
            <IconButton
              onClick={() => {
                setSelectedDynamicForm(row);
                setOpenEnableDialog(true);
              }}
              size="small"
              color="primary"
              aria-label={t("GECERSIZ")}
              title={t("GECERSIZ")}
            >
              <FontAwesomeIcon icon={faVolumeMute} />
            </IconButton>
          );
        }
        return null;
      },
    },
  ];

  const dynamicFormAddButtonClicked = () => {
    setDynamicForm(null);
    setSelectedDynamicForm(null);
    setIsAddFieldOpen(true);
  };

  const renderDisableDialog = () => {
    return (
      <Dialog
        open={openDisableDialog}
        onClose={() => {
          setOpenDisableDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Geçersiz Yap?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Seçmiş olduğunuz ek alan, mevcut etkileşim noktası tipi ve alt
            etkileşim noktası tiplerinde gösterilmeyecektir. Devam etmek istiyor
            musunuz?.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDisableDialog(false);
            }}
            color="primary"
          >
            İptal
          </Button>
          <Button
            onClick={() => {
              disableDetail();
            }}
            color="primary"
            autoFocus
          >
            Geçersiz Yap
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderEnableDialog = () => {
    return (
      <Dialog
        open={openEnableDialog}
        onClose={() => {
          setOpenEnableDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Geçerli Yap?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Seçmiş olduğunuz ek alan, mevcut etkileşim noktası tipi ve alt
            etkileşim noktası tiplerinde gösterilmeye başlanacaktır. Devam etmek
            istiyor musunuz?.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenEnableDialog(false);
            }}
            color="primary"
          >
            İptal
          </Button>
          <Button
            onClick={() => {
              enableDetail();
            }}
            color="primary"
            autoFocus
          >
            Geçerli Yap
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {renderFieldDialog()}
      {renderDisableDialog()}
      {renderEnableDialog()}
      <Card style={{ overflowY: "auto" }}>
        <CardHeader
          action={
            <>
              <ThingClassAdditionalFieldsHelp value="thingClass.additionalFields" />
              <IconButton
                aria-label="settings"
                onClick={dynamicFormAddButtonClicked}
                data-fa-button="ADDITIONAL_FIELDS_BUTTON"
              >
                <Add />
              </IconButton>
            </>
          }
          title={t("ADDITIONAL_FIELDS")}
          data-fa-section="ADDITIONAL_FIELDS"
        />
      </Card>
      <ThingsDataTable
        data={dynamicFormList}
        columns={columns}
        ref={tableRef}
        //onSelectedChange={onSelectedChange}
        showDeleteButton={false}
        showHeaderText={false}
        headerText={t("Ek Alanlar Listesi")}
        showPaging={false}
      />
    </>
  );
}
