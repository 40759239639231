import {
  faFile,
  faInfoCircle,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import useStyles from "react-base-fa/dist/fa/faUnAuthPages/style";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import i18n from "../../../i18n";
import PublicDocument from "./PublicDocument";
import PublicPhotos from "./PublicPhotos";

export default function LandingTemplate4(props) {
  const { publicThing, onLinkClick } = props;
  const classes = useStyles();
  const { t } = useTranslation("report", { i18n });
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const location = useLocation();
  const [documentParam, setDocumentParam] = useState(null);

  const renderDocumentsAndLinks = () => {
    return (
      <Grid container spacing={1}>
        {publicThing?.documentList?.map((item, index) => (
          <Grid
            item
            lg={12}
            xs={12}
            key={index}
            style={{ textAlign: "center" }}
          >
            <Typography
              onClick={() => {
                setDocumentParam(item);
              }}
              style={{
                color: "#532363",
                borderBottom: "1px solid",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon icon={faFile} /> {item.name ?? item.fileName}
            </Typography>
          </Grid>
        ))}

        {publicThing?.thingLinkList?.map((item, index) => (
          <Grid
            item
            lg={12}
            xs={12}
            key={index}
            style={{ textAlign: "center" }}
          >
            <Typography
              onClick={() => {
                window.open(item.url, "_blank").focus();
                onLinkClick(item);
              }}
              style={{
                color: "#532363",
                borderBottom: "1px solid",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon icon={faLink} /> {item.description ?? item.url}
            </Typography>
          </Grid>
        ))}
      </Grid>
    );
  };

  const navigateReportIssue = () => {
    let tmpTo = {
      pathname:
        publicThing.food === true
          ? `/report/issueFood/${publicThing.tid}`
          : `/report/issue/${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigatePublicInfo = () => {
    let tmpTo = {
      pathname: `/report/public/${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigateReportLike = () => {
    let tmpTo = {
      pathname:
        publicThing.food === true
          ? `/report/likeFood/${publicThing.tid}`
          : `/report/like/${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigateReportSuggestion = () => {
    let tmpTo = {
      pathname:
        publicThing.food === true
          ? `/report/suggestionFood/${publicThing.tid}`
          : `/report/suggestion/${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  return (
    <>
      <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
        <p>{publicThing.thingDesc}</p>
      </Grid>
      <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
        {renderDocumentsAndLinks()}
        <PublicDocument
          value={documentParam}
          publicThing={publicThing}
          onClose={() => {
            setDocumentParam(null);
          }}
        />
      </Grid>
      <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
        <PublicPhotos publicThing={publicThing} />
      </Grid>
      <Grid item lg={10} xs={12} style={{ textAlign: "center", marginTop: 20 }}>
        <Typography
          onClick={navigatePublicInfo}
          style={{
            color: "#532363",
            borderBottom: "1px solid",
            cursor: "pointer",
          }}
        >
          <FontAwesomeIcon icon={faInfoCircle} /> Bilgi Al
        </Typography>
      </Grid>
      <Grid
        item
        lg={10}
        xs={12}
        style={{ textAlign: "center", paddingTop: 50, paddingBottom: 50 }}
      >
        <div display="flex">
          <Button onClick={navigateReportLike}>
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/pageTemplate/icons/Things_Icon_Set-36.png"
              }
              alt="like"
            />
          </Button>
          <Button onClick={navigateReportIssue}>
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/pageTemplate/icons/Things_Icon_Set-37.png"
              }
              alt="issue"
              style={{ marginLeft: 50 }}
            />
          </Button>
        </div>
      </Grid>

      <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
        <Button onClick={navigateReportSuggestion}>
          <div>
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/pageTemplate/icons/Things_Icon_Set-35.png"
              }
              alt="suggestion"
            />
            <p style={{ color: "#532363" }}>Bir önerim var!</p>
          </div>
        </Button>
      </Grid>
    </>
  );
}

LandingTemplate4.propTypes = {
  loginPath: PropTypes.string,
  appLogo: PropTypes.any,
};
