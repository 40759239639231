import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Grid,
} from "@material-ui/core";
import { Save } from "@material-ui/icons";
import PropTypes from "prop-types";
import { default as React, useEffect, useState } from "react";
import { FaInput } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { CampaignService } from "services/CampaignService";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function CampaignCustomerCheckList(props) {
  const { campaignCustomerId, status, onChange, autoSave } = props;

  const labelClasses = labelStyles();

  const { t } = useTranslation("campaign", { i18n });
  const classes = useStyles();

  const [checkList, setCheckList] = useState([]);
  const [form, setForm] = useState({});

  useEffect(() => {
    if (campaignCustomerId) {
      CampaignService.getCustomerCheckList(
        campaignCustomerId,
        (data) => {
          setCheckList(data);
          setForm({ ...form, status: status });
        },
        (error) => {}
      );
    }
  }, [campaignCustomerId]);

  const itemOnCheck = (index, check) => {
    let tmp = [...checkList];
    tmp[index].check = check;

    if (!autoSave) onChange(tmp);

    setCheckList(tmp);
  };

  const itemOnDescription = (index, description) => {
    let tmp = [...checkList];
    tmp[index].description = description;

    if (!autoSave) onChange(tmp);

    setCheckList(tmp);
  };

  const renderItem = (item, index) => {
    return (
      <>
        <Grid item xs={6}>
          {item.checkListName}
        </Grid>
        <Grid item xs={2}>
          <Checkbox
            checked={item.check}
            onChange={(event) => {
              itemOnCheck(index, event.target.checked);
            }}
            color="primary"
          />
        </Grid>
        <Grid item xs={4}>
          <FaInput
            onChange={(event) => itemOnDescription(index, event.target.value)}
            value={item.description || ""}
          />
        </Grid>
      </>
    );
  };

  const onSave = () => {
    let tmp = {
      ...form,
      campaignCustomerId: campaignCustomerId,
      checkList: checkList,
    };
    CampaignService.fillCheckList(
      tmp,
      (data) => {
        onChange(checkList);
      },
      (error) => {}
    );
  };

  return (
    <Card>
      <CardHeader title={t("Kontrol Listesi")} />
      <CardContent>
        <Grid item container spacing={1}>
          <Grid item xs={6}>
            {t("#")}
          </Grid>
          <Grid item xs={2}>
            {t("Kontrol")}
          </Grid>
          <Grid item xs={4}>
            {t("Açıklama")}
          </Grid>
          {checkList &&
            checkList.length > 0 &&
            checkList.map((item, index) => renderItem(item, index))}
          {autoSave && (
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<Save />}
                onClick={onSave}
              >
                {t("Kaydet")}
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

CampaignCustomerCheckList.propTypes = {
  campaignCustomerId: PropTypes.any,
  autoSave: PropTypes.bool,
};

CampaignCustomerCheckList.defaultProps = {
  autoSave: true,
};
