// value         |0px     600px    960px    1280px   1920px
// key           |xs      sm       md       lg       xl
// screen width  |--------|--------|--------|--------|-------->
// range         |   xs   |   sm   |   md   |   lg   |   xl

export const styles = {
  MuiTablePagination: {
    toolbar: {
      "@media (max-width:700px)": {
        flexWrap: "wrap-reverse",
        justifyContent: "center",
      },
    },
  },
  MuiTabPanel: {
    root: {
      padding: 0,
    },
  },
  MuiTab: {
    root: {
      textTransform: "initial",
    },
  },
  MuiTableCell: {
    alignCenter: {
      textAlign: "left",
    },
    root: {
      padding: 4,
    },
    head: {
      fontWeight: 600,
      color: "#9A9A9A",
    },
  },
  MuiListItem: {
    root: {
      paddingTop: "1px",
      paddingBottom: "1px",
    },
    button: {
      "&:hover": {
        backgroundColor: "#5f5f5f",
      },
    },
  },
  MuiDrawer: {
    paperAnchorRight: {
      backgroundColor: "white",
    },
    paper: {
      backgroundColor: "#151B26",
      color: "#CBD4DB",
      overflow: "hidden",
    },
  },
  MuiListItemIcon: {
    root: {
      color: "#6F7782 !important",
    },
  },
  MuiListItemText: {
    root: {
      color: "#CBD4DB",
    },
  },
  MuiIconButton: {
    root: {
      padding: 8,
      color: "#532363",
    },
    colorPrimary: {
      color: "#6F7782",
    },
  },

  MuiTypography: {
    colorPrimary: {
      color: "#151B26",
    },
    subtitle1: {
      color: "CBD4DB",
    },
    body1: {
      color: "black",
    },
    colorSecondary: {
      color: "#151B26",
    },
    caption: {
      fontSize: "0.875rem",
      fontWeight: "bold",
    },
  },
  MuiFormControlLabel: {
    label: {
      minWidth: 50,
    },
  },

  MuiButton: {
    containedPrimary: {
      backgroundColor: "#151B26",
      "&:hover": {
        backgroundColor: "#dc3545",
      },
    },
  },

  MuiTableRow: {
    root: {
      "&:last-child th, &:last-child td": {
        borderBottom: 0,
      },
    },
  },

  thingsMoru: {
    color: "#532363",
  },
};
