import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { SUGGESTION } from "./faApi";

export const SuggestionService = {
  save: (suggestion, callback, errorCallback) => {
    let reqBody = {
      ...suggestion,
    };

    let req = requests.getSimplePostRequest(SUGGESTION.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(SUGGESTION.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  downloadThumbnails: (suggestionId, callback, errorCallback) => {
    const req = requests.getSimpleGetRequest(SUGGESTION.downloadThumbnails, {
      suggestionId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  downloadImage: (suggestionId, objectId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      SUGGESTION.downloadImage +
        "?suggestionId=" +
        suggestionId +
        "&objectId=" +
        encodeURI(objectId)
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
