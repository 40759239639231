import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { TEAM_ROLES } from "./faApi";

export const TeamRolesService = {
  save: (teamRole, callback, errorCallback) => {
    console.log("BURADAYIM");
    let reqBody = {
      ...teamRole,
    };

    let req = requests.getSimplePostRequest(TEAM_ROLES.save, reqBody);

    FaAxios(req)
      .then((response) => {
        console.log("response => ", response);
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },

  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(TEAM_ROLES.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (teamRole, callback, errorCallback) => {
    let reqBody = {
      ...teamRole,
    };

    let req = requests.getSimplePostRequest(TEAM_ROLES.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },

  findByAccount: (account, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(TEAM_ROLES.findByAccount, account);
    console.log("3");
    FaAxios(req)
      .then((response) => {
        console.log(response.data);
        callback(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
};
