import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  chipOpen: {
    backgroundColor: theme.palette.success.dark,
    color: "white",
  },
  chipClose: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.contrastText,
  },
}));
