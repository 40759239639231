import React from "react";
import { FaAutoComplete } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { SERVICE_OPERATION_CLASS } from "../../services/faApi";

export default function ServiceOperationClassAutoComplete(props) {
  const { value, accountId, onChange, required, label } = props;

  const { t } = useTranslation("components", { i18n });

  return (
    <FaAutoComplete
      faType="async"
      value={value}
      getOptionSelected={(option, value) => {
        return option.id === value.id;
      }}
      sort="className"
      faFilterProp="className"
      getOptionLabel={(option) =>
        (option.code ? option.code + " - " : "") +
        (option.className ? option.className : "")
      }
      requestUrl={
        SERVICE_OPERATION_CLASS.findClassName + "?accountId=" + accountId
      }
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      label={label ?? t("SERVICE_OPERATION_CLASS")}
      required={required}
    />
  );
}
