import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { ACC_PREFERENCES } from "../../services/faApi";

export const AccPreferencesService = {
  save: (accPrefs, callback, errorCallback) => {
    let reqBody = {
      ...accPrefs,
    };

    let req = requests.getSimplePostRequest(ACC_PREFERENCES.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findByAccount: (accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      ACC_PREFERENCES.findByAccount + "?accountId=" + accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findInteractionPoint: (accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      ACC_PREFERENCES.findInteractionPoint + "?accountId=" + accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  updatePublicReportPageTemplate: (
    id,
    publicReportPageTemplate,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimplePostRequest(
      ACC_PREFERENCES.updatePublicReportPageTemplate +
        "?id=" +
        id +
        "&publicReportPageTemplate=" +
        publicReportPageTemplate
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
