import { faArrowLeft, faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import useStyles from "react-base-fa/dist/fa/faUnAuthPages/style";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getDateWFormat } from "services/utils";
import i18n from "../../../i18n";
import InfoIcon from "./icons/InfoIcon";
import LikeIcon from "./icons/LikeIcon";
import RegisterIcon from "./icons/RegisterIcon";
import ReportIcon from "./icons/ReportIcon";
import SuggestionIcon from "./icons/SuggestionIcon";
import SurveyIcon from "./icons/SurveyIcon";
import PublicDocument from "./PublicDocument";
import PublicPhotos2 from "./PublicPhotos2";
import PublicThingDetailInfo from "./PublicThingDetailInfo";

export default function LandingTemplate6(props) {
  const { publicThing, onLinkClick } = props;
  const classes = useStyles();
  const { t } = useTranslation("report", { i18n });
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const location = useLocation();
  const [documentParam, setDocumentParam] = useState(null);
  const [infoAccordionExpanded, setInfoAccordionExpanded] = useState(true);

  const [selectedTab, setSelectedTab] = useState("info");

  const [pageMode, setPageMode] = useState("welcome");

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const switchPageMode = () => {
    if (pageMode === "welcome") setPageMode("detailInfo");
    else setPageMode("welcome");
  };

  const navigateReportIssue = () => {
    let tmpTo = {
      pathname:
        publicThing.food === true
          ? `/report/issueFood/${publicThing.tid}`
          : `/report/issue/${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigateReportLike = () => {
    let tmpTo = {
      pathname:
        publicThing.food === true
          ? `/report/likeFood/${publicThing.tid}`
          : `/report/like/${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigateReportSuggestion = () => {
    let tmpTo = {
      pathname:
        publicThing.food === true
          ? `/report/suggestionFood/${publicThing.tid}`
          : `/report/suggestion/${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigateCustomer = () => {
    let tmpTo = {
      pathname: `/report/customer/tid=${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigateSurvey = (survey) => {
    let tmpTo = {
      pathname: `/report/survey/${survey.id}/${publicThing.id}/${publicThing.accountId}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigateCampaign = (survey) => {
    let tmpTo = {
      pathname: `/report/registerCampaign/${survey.id}/${publicThing.id}/${publicThing.accountId}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };
  // const navigateAnnouncement = (announcement) => {
  //   let tmpTo = {
  //     pathname: `/report/announcement/${announcement.id}/${publicThing.id}/${publicThing.accountId}`,
  //     state: {
  //       publicThing: {
  //         ...publicThing,
  //       },
  //     },
  //   };
  //   history.push(tmpTo);
  // };
  const renderDetailInfo = () => {
    return (
      <>
        <PublicThingDetailInfo
          publicThing={publicThing}
          onLinkClick={onLinkClick}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={3} style={{ textAlign: "center" }}>
            <Button
              onClick={() => {
                switchPageMode();
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  style={{ fontSize: 32, color: "#6D6D6D" }}
                />
              </div>
            </Button>
          </Grid>

          <Grid item xs={1}></Grid>
        </Grid>
      </>
    );
  };

  const renderWelcome = () => {
    return (
      <>
        <Grid
          item
          lg={10}
          xs={12}
          style={{ textAlign: "center", marginTop: 10 }}
        >
          <Grid
            item
            container
            spacing={1}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              {publicThing.interactionPageSettings.ipCustomer && (
                <Button
                  variant="outlined"
                  style={{
                    color: "#6D6D6D",
                    borderColor: "#6D6D6D",
                    border: "2px solid",
                    width: "100%",
                    borderRadius: "8px",
                  }}
                  startIcon={<RegisterIcon />}
                  size="large"
                  onClick={() => {
                    navigateCustomer();
                  }}
                >
                  Üye Ol
                </Button>
              )}
            </Grid>
            <Grid item xs={1}></Grid>

            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              {publicThing.interactionPageSettings.ipDetailInfo && (
                <Button
                  variant="outlined"
                  style={{
                    color: "#512562",
                    borderColor: "#512562",
                    border: "2px solid",
                    width: "100%",
                    borderRadius: "8px",
                  }}
                  startIcon={<InfoIcon />}
                  size="large"
                  onClick={() => {
                    switchPageMode();
                  }}
                >
                  Detaylı Bilgi
                </Button>
              )}
            </Grid>
            <Grid item xs={1}></Grid>

            {publicThing &&
              publicThing.interactionPageSettings.ipSurvey &&
              publicThing.surveyList &&
              publicThing.surveyList.length > 0 &&
              publicThing.surveyList.map((item, index) => {
                return (
                  <>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                      <Button
                        variant="outlined"
                        style={{
                          color: "#6D6D6D",
                          borderColor: "#6D6D6D",
                          border: "2px solid",
                          width: "100%",
                          borderRadius: "8px",
                        }}
                        startIcon={<SurveyIcon />}
                        size="large"
                        onClick={() => {
                          navigateSurvey(item);
                        }}
                      >
                        {item.name}
                      </Button>
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </>
                );
              })}
            {publicThing &&
              publicThing.announcementList &&
              publicThing.announcementList.length > 0 &&
              publicThing.announcementList.map((item, index) => {
                return (
                  <div style={{ marginTop: 20 }}>
                    <div className={classes.campaign}>
                      <div className={classes.campaignTexts}>
                        <div className={classes.campaignTitle}>
                          {item.subject}
                        </div>
                        <div className={classes.campaignContentTexts}>
                          {item.content}
                        </div>

                        <div className={classes.campaignDate}>
                          {`${getDateWFormat(item.startDate)}`}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            {publicThing &&
              publicThing.interactionPageSettings.ipCampaign &&
              publicThing.campaignList &&
              publicThing.campaignList.length > 0 &&
              publicThing.campaignList.map((item, index) => {
                return (
                  <>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                      <Button
                        variant="outlined"
                        style={{
                          color: "#6D6D6D",
                          borderColor: "#6D6D6D",
                          border: "2px solid",
                          width: "100%",
                          borderRadius: "8px",
                        }}
                        startIcon={<FontAwesomeIcon icon={faBullhorn} />}
                        size="large"
                        onClick={() => {
                          navigateCampaign(item);
                        }}
                      >
                        {item.name}
                      </Button>
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </>
                );
              })}
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={1}></Grid>
          {publicThing.interactionPageSettings.ipIssue && (
            <Grid item xs={3} style={{ textAlign: "center" }}>
              <Button onClick={navigateReportIssue}>
                <div>
                  <ReportIcon style={{ fontSize: 64, color: "#6D6D6D" }} />
                </div>
              </Button>
            </Grid>
          )}

          {publicThing.interactionPageSettings.ipSuggestion && (
            <Grid item xs={3} style={{ textAlign: "center" }}>
              <Button onClick={navigateReportSuggestion}>
                <div>
                  <SuggestionIcon style={{ fontSize: 64, color: "#6D6D6D" }} />
                </div>
              </Button>
            </Grid>
          )}

          {publicThing.interactionPageSettings.ipLike && (
            <Grid item xs={3} style={{ textAlign: "center" }}>
              <Button onClick={navigateReportLike}>
                <div>
                  <LikeIcon style={{ fontSize: 64, color: "#512562" }} />
                </div>
              </Button>
            </Grid>
          )}
          <Grid item xs={1}></Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
        <p>
          <Typography
            style={{ fontWeight: "bold", color: "#6D6D6D", fontSize: 28 }}
          >
            {publicThing.thingDesc}
          </Typography>
        </p>
      </Grid>
      <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
        <PublicDocument
          value={documentParam}
          publicThing={publicThing}
          onClose={() => {
            setDocumentParam(null);
          }}
        />
      </Grid>
      {publicThing.interactionPageSettings.ipImage && (
        <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
          <PublicPhotos2 publicThing={publicThing} />
        </Grid>
      )}

      {pageMode === "welcome" && renderWelcome()}
      {pageMode === "detailInfo" && renderDetailInfo()}
    </>
  );
}

LandingTemplate6.propTypes = {
  loginPath: PropTypes.string,
  appLogo: PropTypes.any,
};
