import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { Check, Remove } from "@material-ui/icons";
import ThingsDataTable from "components/thingsDataTable";
import i18n from "i18n";
import { default as React, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BENEFICIARY_PORTAL } from "services/faApi";
import { ReportService } from "services/report/reportService";
import { getDateTimeWFormat } from "services/utils";

export default function MyPermissions(props) {
  const { campaign } = props;
  const accountId = localStorage.beneficiaryAccountId;
  const { t } = useTranslation("beneficiaryPortal", { i18n });

  const [legalText, setLegalText] = useState(null);
  const [openLegalText, setOpenLegalText] = useState(null);

  const tableRef = useRef();

  const newSearchForm = () => {
    return {
      accountId: accountId,
    };
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const columns = [
    {
      field: "membershipDate",
      label: t("Tarih"),
      align: "center",
      width: "60%",
      valueFormatter: (value) => {
        return getDateTimeWFormat(value);
      },
    },
    {
      field: "contactPermission",
      label: t("İletişim İzni"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        return value ? (
          <div style={{ display: "flex" }}>
            <Check />
            <Button
              onClick={() => {
                showLegalText("TICARI_ELEKTRONIK_ILETI_RIZA");
              }}
              color="primary"
              size="small"
              variant="contained"
              style={{ marginLeft: 10 }}
            >
              Göster
            </Button>
          </div>
        ) : (
          <Remove />
        );
      },
    },
    {
      field: "gdprPermission",
      label: t("KVKK İzni"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        return value ? (
          <div style={{ display: "flex" }}>
            <Check />
            <Button
              onClick={() => {
                showLegalText("KVKK");
              }}
              color="primary"
              size="small"
              variant="contained"
              style={{ marginLeft: 10 }}
            >
              Göster
            </Button>
          </div>
        ) : (
          <Remove />
        );
      },
    },
  ];

  const showLegalText = (type) => {
    ReportService.getLegal(type, accountId, showLegalTextOnSuccess, (error) => {
      setLegalText(null);
    });
  };

  const showLegalTextOnSuccess = (data) => {
    setLegalText(data);
    setOpenLegalText(true);
  };

  const renderLegalTextDialog = () => {
    return (
      <Dialog
        open={openLegalText}
        onClose={() => {
          setOpenLegalText(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{legalText?.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div dangerouslySetInnerHTML={{ __html: legalText?.content }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenLegalText(false);
            }}
            color="primary"
          >
            Kapat
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Card>
      <CardHeader title={t("İzinlerim")} data-fa-section="PERMISSIONS" />
      {renderLegalTextDialog()}
      <ThingsDataTable
        searchUrl={BENEFICIARY_PORTAL.customers}
        searchForm={searchForm}
        columns={columns}
        ref={tableRef}
        showHeaderText={false}
        queryParams={{
          page: 0,
          size: 10,
          isAsc: false,
          sort: "membershipDate",
        }}
      />
    </Card>
  );
}
