import React from "react";
import { FaAutoComplete } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { ConcatHashtagStr, ParseHashtagStr } from "services/utils";
import i18n from "../../i18n";
import { CUSTOMER } from "../../services/faApi";

export default function CustomerHashtag(props) {
  const { value, accountId, onChange, required, label } = props;

  const { t } = useTranslation("components", { i18n });

  return (
    <FaAutoComplete
      faType="async"
      value={ParseHashtagStr(value)}
      required={required}
      faMultiple={true}
      limitTags={20}
      getOptionSelected={(option, value) => {
        return option === value;
      }}
      sort="filter"
      //autoSelect
      faFilterProp="filter"
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option;
      }}
      requestUrl={CUSTOMER.findHashtag + "?accountId=" + accountId}
      onChange={(event, newValue) => {
        onChange(ConcatHashtagStr(newValue));
      }}
      freeSolo
      label={label == null ? t("Hashtag") : label}
    />
  );
}
