import { Button } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import { default as React, Suspense, useEffect, useState } from "react";
import { FaDatePicker, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import FaButton from "react-base-fa/dist/fa/faButton";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getDateWFormat } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import SlaContractStatusSelect from "../../components/select/SlaContractStatusSelect";
import i18n from "../../i18n";
import { SlaContractService } from "../../services/SlaContractService";
import SlaContractLevels from "./SlaContractLevels";
import SlaContractThings from "./SlaContractThings";
import { useStyles } from "./style";

export default function SlaContractDetail() {
  const classes = useStyles();
  const labelClasses = labelStyles();

  const { t } = useTranslation("slaContract", { i18n });
  const { id } = useParams();
  const accountId = localStorage.accountId;
  const dispatch = useDispatch();
  let history = useHistory();
  const faDialog = useDialog();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [slaContract, setSlaContract] = useState({});
  const [slaEdit, setSlaEdit] = useState({});

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    setLoading(true);
    SlaContractService.findById(id, findByIdOnSuccess, findByIdOnError);
  };

  const findByIdOnSuccess = (data) => {
    setSlaContract({
      ...data,
    });
    setLoading(false);
  };

  const findByIdOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };

  const editButtonClicked = () => {
    setIsModalOpen(true);
    setSlaEdit({ ...slaContract });
  };

  const saveSlaContract = () => {
    setLoading(true);
    SlaContractService.save(
      {
        ...slaEdit,
        account: { id: accountId },
      },
      saveSuccess,
      saveError
    );
  };

  const saveSuccess = (data) => {
    showAlert(!slaContract.id ? t("SAVED") : t("EDITED"), "success");
    setIsModalOpen(false);
    setSlaContract({
      ...data,
    });
    setLoading(false);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const handleChange = (prop) => (event) => {
    setSlaEdit({ ...slaEdit, [prop]: event.target.value });
  };

  const handleChange2 = (prop, val) => {
    setSlaEdit({ ...slaEdit, [prop]: val });
  };

  const onDelete = () => {
    faDialog({
      description: t("Notu silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        SlaContractService.delete(
          slaContract,
          () => {
            showAlert("Silindi", "success");
            history.goBack();
          },
          (error) => {
            showAlert("Bağlı Kayıtlar Mevcut", "error");
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };
  const renderDialog = () => {
    return (
      <FaDialog
        title={t("EDIT_SLA_CONTRACT")}
        faOpen={isModalOpen}
        faOnSubmit={saveSlaContract}
        showSaveButton
        faHandleClose={() => {
          setIsModalOpen(false);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <FaInput
              required
              maxLength={100}
              label={t("NAME")}
              onChange={handleChange("name")}
              value={slaEdit.name || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              maxLength={100}
              label={t("DESCRIPTION")}
              onChange={handleChange("description")}
              value={slaEdit.description || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <FaDatePicker
              faType="date"
              label={t("START_DATE")}
              style={{ minHeight: 65 }}
              value={slaEdit.startDate}
              faOnChange={(val) => {
                setSlaEdit({
                  ...slaEdit,
                  startDate: val,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FaDatePicker
              faType="date"
              label={t("END_DATE")}
              style={{ minHeight: 65 }}
              value={slaEdit.endDate}
              faOnChange={(val) => {
                setSlaEdit({
                  ...slaEdit,
                  endDate: val,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <SlaContractStatusSelect
              value={slaEdit.status}
              onChange={(data) => {
                setSlaEdit({
                  ...slaEdit,
                  status: data,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              faType="number"
              awnum="decimal-6"
              label={t("MAX_PENALTY_PER_ISSUE")}
              value={slaEdit.maxPenaltyPerIssue}
              onChange={(event) => {
                handleChange2("maxPenaltyPerIssue", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              faType="number"
              awnum="decimal-6"
              label={t("MAX_PENALTY_PER_CONTRACT")}
              value={slaEdit.maxPenaltyPerContract}
              onChange={(event) => {
                handleChange2("maxPenaltyPerContract", event.target.value);
              }}
            />
          </Grid>
          {slaContract.id && (
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button onClick={onDelete} color="secondary">
                {t("Sil")}
              </Button>
            </Grid>
          )}
        </Grid>
      </FaDialog>
    );
  };

  const monitor = () => {
    const tmpTo = {
      pathname: `/monitorSla/${slaContract.id}`,
      breadCrumbKey: `/monitorSla/:id`,
    };
    history.push(tmpTo);
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {renderDialog()}

        <Grid container spacing={1}>
          <Grid item lg={12} xs={12}>
            <Grid item container spacing={1}>
              <Grid item lg={12}>
                <Card>
                  <CardHeader
                    action={
                      <IconButton
                        aria-label="settings"
                        onClick={editButtonClicked}
                      >
                        <EditIcon />
                      </IconButton>
                    }
                    title={t("INFO_SLA_CONTRACT")}
                    data-fa-section="INFO_SLA_CONTRACT"
                  />
                  <CardContent>
                    <Grid item container spacing={0}>
                      <Grid item xs={12}>
                        <Grid
                          item
                          container
                          spacing={1}
                          style={{ marginLeft: 10, marginRight: 10 }}
                        >
                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("NAME")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {slaContract.name}
                            </Typography>
                          </Grid>

                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("DESCRIPTION")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {slaContract.description}
                            </Typography>
                          </Grid>

                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("START_DATE")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {getDateWFormat(slaContract.startDate)}
                            </Typography>
                          </Grid>

                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("END_DATE")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {getDateWFormat(slaContract.endDate)}
                            </Typography>
                          </Grid>

                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("MAX_PENALTY_PER_ISSUE")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {slaContract.maxPenaltyPerIssue}
                            </Typography>
                          </Grid>

                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("MAX_PENALTY_PER_CONTRACT")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {slaContract.maxPenaltyPerContract}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={6}
                            className={labelClasses.LabelLastRow}
                          >
                            <Typography className={labelClasses.LabelHeader}>
                              {t("STATUS")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {t("" + slaContract.status)}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className={labelClasses.LabelLastRow}
                          >
                            <FaButton
                              style={{ width: 200 }}
                              size="small"
                              color="primary"
                              faClick={() => monitor()}
                              data-fa-button="MONITOR"
                            >
                              {t("İzleme")}
                            </FaButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={12}>
            {slaContract && slaContract.id > 0 && (
              <SlaContractLevels slaContract={slaContract} />
            )}
          </Grid>

          <Grid item lg={12}>
            {slaContract && slaContract.id > 0 && (
              <SlaContractThings slaContract={slaContract} />
            )}
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
