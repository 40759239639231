import { default as React, Suspense } from "react";
import { useTranslation } from "react-i18next";
import LoadingBar from "../../../components/loadingBar";
import i18n from "../../../i18n";
import { ThingStatisticService } from "../../../services/statistic/statisticService";
import GenericBarChart from "../ui/GenericBarChart";

export default function SolutionCountByOrganization() {
  const { t } = useTranslation("statistic", { i18n });
  const accountId = localStorage.accountId;

  var today = new Date();
  const statSettings = {
    start: new Date(today.setMonth(today.getMonth() - 1)),
    end: null,
    period: "DAY",
    cumulative: false,
    dateRange: 4,
    dateRangeType: 3,
    limit: 5,
  };

  const countLabel = t("SAYI");

  const getData = (settings, getDataOnSuccess, getDataOnError) => {
    if (accountId) {
      ThingStatisticService.solutionCountByLocation(
        accountId,
        settings,
        countLabel,
        getDataOnSuccess,
        getDataOnError
      );
    }
  };
  const renderChart = () => {
    return (
      <GenericBarChart
        title={t("Konuma Göre Müdahale Sayıları")}
        getData={getData}
        bAxisLabel={t("Gün")}
        lAxisLabel={t("Müdahale Sayısı")}
        menuDate
        menuLimit
        menuPeriod
        settings={statSettings}
      />
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>{renderChart()}</Suspense>
    </>
  );
}
