import { CardHeader, Checkbox, Link, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { Dashboard, Favorite, FavoriteBorder } from "@material-ui/icons";
import ThingsEditIcon from "components/icons/ThingsEditIcon";
import ThingsHelpElement from "features/help/ThingsHelpElement";
import { default as React, useEffect, useRef, useState } from "react";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { getDateWFormat } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { UserSubscriptionService } from "services/UserSubscriptionService";
import { getThingsPackage, isTNF } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import SubscribedUsers from "./SubscribedUsers";
import ThingEdit from "./ThingEdit";

export default function ThingDetailCard(props) {
  const { thing, onUpdate, isEditable } = props;
  const { t } = useTranslation("thingDetail", { i18n });
  const labelClasses = labelStyles();
  const dialogRef = useRef();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  let history = useHistory();
  const accountId = localStorage.accountId;
  const [showWindow, setShowWindow] = useState(false);
  const [newChecked, setNewChecked] = useState(false);
  const [subscribers, setSubscribers] = useState();
  const userInfo = useSelector(getUserInfo);
  let subscribedUsers = 0;
  const THINGS_PACKAGE = getThingsPackage();

  const onThingInfoUpdate = (newData) => {
    onUpdate(newData);
  };

  const thingEditDialogRender = () => {
    return (
      <ThingEdit
        thing={thing}
        ref={dialogRef}
        onUpdate={onThingInfoUpdate}
      ></ThingEdit>
    );
  };

  const newSubscribe = () => {
    return {
      id: "",
      account: { id: accountId },
      thing: thing,
    };
  };
  const subscribe = () => {
    UserSubscriptionService.subscribe(
      newSubscribe(),
      subscribeSuccess,
      subscribeError
    );
  };

  const subscribeSuccess = (data) => {
    getIfSubscribed(userInfo.id, thing.id);
    //console.log(data);
  };

  const subscribeError = (error) => {
    //console.log(error);
  };
  const openEditDialog = () => {
    dialogRef.current.openDialog();
  };

  const getIfSubscribed = (userId, thingId) => {
    UserSubscriptionService.findIfSubscribed(
      userId,
      thingId,
      subscribersSuccess,
      subscribersError
    );
  };
  const subscribersSuccess = (data) => {
    setNewChecked(data.subscriptionStatus);
    setSubscribers(data.count);
    //console.log(data);
  };

  const subscribersError = (error) => {
    // console.log(error);
  };

  useEffect(() => {
    if (thing && thing.id && thing.id !== 0) {
      getIfSubscribed(userInfo.id, thing.id);
    }
  }, [thing]);

  const [anchorCumulativeEl, setAnchorCumulativeEl] = useState(null);
  const handleCumulativeClose = () => {
    setAnchorCumulativeEl(null);
  };

  const handleCumulativeClick = (event) => {
    setAnchorCumulativeEl(event.currentTarget);
  };

  const goThingClass = () => {
    const tmpTo = {
      pathname: `/thingClassDetail/${thing?.thingClass.id}`,
      breadCrumbKey: `/thingClassDetail/:id`,
    };
    history.push(tmpTo);
  };

  const goProductDashboard = () => {
    const tmpTo = {
      pathname: `/productDashboard/${thing?.id}`,
      breadCrumbKey: `/productDashboard/:id`,
    };
    history.push(tmpTo);
  };

  return (
    <>
      <Card style={{ minHeight: 250, overflowY: "auto" }}>
        <CardHeader
          action={
            <>
              <SubscribedUsers
                subscribedUsers={subscribers}
                thingId={thing.id}
              ></SubscribedUsers>

              <Checkbox
                checked={newChecked}
                icon={<FavoriteBorder style={{ color: "#532363" }} />}
                checkedIcon={<Favorite style={{ color: "#532363" }} />}
                onClick={() => {
                  subscribe();
                  getIfSubscribed(userInfo.id, thing.id);
                }}
                title={newChecked ? "Üyelikten çık" : "Üye ol"}
              />

              {thing?.productType && (
                <IconButton
                  onClick={() => {
                    goProductDashboard();
                  }}
                  title={t("Ürün Paneli")}
                >
                  <Dashboard />
                </IconButton>
              )}
              <ThingsHelpElement value="thingDetail.temelBilgiler" />

              {isEditable && thing && !!!thing.system && (
                <IconButton
                  aria-label="settings"
                  onClick={() => {
                    openEditDialog();
                  }}
                  data-fa-button="THING_EDIT_BUTTON"
                >
                  <ThingsEditIcon />
                </IconButton>
              )}
            </>
          }
          title={t("Temel Bilgiler")}
          className="fai-temelBilgiler"
          data-fa-section="Temel Bilgiler"
        />
        <CardContent>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <Grid item container spacing={1}>
                <Grid
                  item
                  xs={12}
                  className={labelClasses.LabelRow}
                  data-fa-label="Nesne Kimlik Numarası"
                >
                  <Typography className={labelClasses.LabelHeader}>
                    {t("Nesne Kimlik Numarası")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {thing?.thid}
                  </Typography>
                </Grid>

                {thing?.productType && (
                  <Grid
                    item
                    xs={12}
                    className={labelClasses.LabelRow}
                    data-fa-label="PRODUCT_TYPE"
                  >
                    <Typography className={labelClasses.LabelHeader}>
                      {t("TYPE")}
                    </Typography>
                    <Typography className={labelClasses.LabelValue}>
                      {t("components:THING_PRODUCT_TYPE." + thing?.productType)}
                    </Typography>
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  className={labelClasses.LabelRow}
                  data-fa-label="CLASS_NAME"
                >
                  <Typography className={labelClasses.LabelHeader}>
                    {isTNF(t("CLASS_NAME"), t("CLASS_NAME_T2C"))}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    <Link
                      onClick={goThingClass}
                      style={{
                        textDecoration: "underline",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                    >
                      {thing?.thingClass?.className}
                    </Link>
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  className={labelClasses.LabelRow}
                  data-fa-label="THING_CODE"
                >
                  <Typography className={labelClasses.LabelHeader}>
                    {!!!thing.productType ? t("THING_CODE") : t("T_CODE")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {thing?.thingCode}
                  </Typography>
                </Grid>

                {!!!thing.productType && THINGS_PACKAGE === "things2care" && (
                  <Grid
                    item
                    xs={12}
                    className={labelClasses.LabelRow}
                    data-fa-label="VENDOR"
                  >
                    <Typography className={labelClasses.LabelHeader}>
                      {t("VENDOR")}
                    </Typography>
                    <Typography className={labelClasses.LabelValue}>
                      {thing?.vendor?.name}
                    </Typography>
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  className={labelClasses.LabelRow}
                  data-fa-label="HASHTAG"
                >
                  <Typography className={labelClasses.LabelHeader}>
                    {t("HASHTAG")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {thing?.hashtag}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item container spacing={1}>
                <Grid
                  item
                  xs={12}
                  className={labelClasses.LabelRow}
                  data-fa-label="DESCRIPTION"
                >
                  <Typography className={labelClasses.LabelHeader}>
                    {t("DESCRIPTION")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {thing?.description}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={labelClasses.LabelRow}
                  data-fa-label="PUBLIC_IDENTIFIER"
                >
                  <Typography className={labelClasses.LabelHeader}>
                    {t("PUBLIC_IDENTIFIER")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {thing?.publicIdentifier}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={labelClasses.LabelLastRow}
                  data-fa-label="SERVICE_START_DATE"
                >
                  <Typography className={labelClasses.LabelHeader}>
                    {t("SERVICE_START_DATE")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {getDateWFormat(thing?.serviceStartDate)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {thingEditDialogRender()}
    </>
  );
}
