export const GpsLocationService = {
  getLocation: (callback, errorCallback) => {
    if (!navigator.geolocation) {
      console.log("Geolocation is not supported by your browser");
      errorCallback("Geolocation is not supported by your browser");
    } else {
      console.log("Locating...");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("Locating... part2");
          const result = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          localStorage.setItem("location1", JSON.stringify(result));
          callback(result);
        },
        () => {
          console.log("Unable to retrieve your location");
          errorCallback("Unable to retrieve your location");
        }
      );
    }
  },
};
