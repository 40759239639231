import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function VerificationTypeSelect(props) {
  const { value, onChange, required, label, captcha } = props;
  const classes = useStyles();
  const { t } = useTranslation("components", { i18n });

  return (
    <FormControl variant="outlined" className={classes.w100} size="small">
      <InputLabel>{label ?? t("VERIFICATION_TYPE")}</InputLabel>
      <Select
        // classes={{
        //   outlined: classes.padding10,
        // }}
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        required={required}
        label={label}
      >
        <MenuItem value={null}>...</MenuItem>
        <MenuItem value={"SMS"}>{t("VERIFICATION_TYPE_SMS")}</MenuItem>
        <MenuItem value={"EMAIL"}>{t("VERIFICATION_TYPE_EMAIL")}</MenuItem>
        <MenuItem value={"SMS_EMAIL"}>
          {t("VERIFICATION_TYPE_SMS_EMAIL")}
        </MenuItem>
        {captcha && (
          <MenuItem value={"CAPTCHA"}>
            {t("VERIFICATION_TYPE_CAPTCHA")}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
}

VerificationTypeSelect.defaultProps = {
  captcha: true,
};
