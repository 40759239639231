import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { PeriodicReportService } from "services/PeriodicReportService";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import DetailCardEdit from "./DetailCardEdit";
const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function DetailCard(props) {
  const { periodicReport } = props;
  const { t } = useTranslation("periodicReport", { i18n });
  const { id } = useParams();
  const accountId = localStorage.accountId;
  const dispatch = useDispatch();
  const dialogRef = useRef();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    PeriodicReportService.findDetails(
      id,
      findDetailsOnSuccess,
      findDetailsOnError
    );
  };

  const findDetailsOnSuccess = (data) => {
    setDetails([...data]);
    setLoading(false);
  };

  const findDetailsOnError = (error) => {
    showAlert(error, "error");
    setLoading(false);
  };

  const openEditDialog = () => {
    dialogRef.current.openDialog();
  };
  const onClose = () => {
    refresh();
  };
  const renderDetailCardEdit = () => {
    return (
      <DetailCardEdit
        ref={dialogRef}
        periodicReport={periodicReport}
        onClose={onClose}
        details={details}
      ></DetailCardEdit>
    );
  };

  const renderDetailItem = (item, index) => {
    return (
      <Grid item lg={item.size} key={index}>
        <Card>
          <CardHeader title={t(item.element)} />
          <CardContent>
            <Grid container spacing={1}>
              <Grid item lg={12} xs={12}>
                <Grid item></Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {renderDetailCardEdit()}
        <Grid item container spacing={1}>
          <Grid item lg={12}>
            <Card>
              <CardHeader
                action={
                  <IconButton
                    aria-label="settings"
                    disabled={periodicReport.status === "ACTIVE"}
                    onClick={() => openEditDialog()}
                  >
                    <EditIcon />
                  </IconButton>
                }
                title={t("DETAILS")}
                data-fa-section="DETAILS"
              />
              <CardContent>
                <Grid container spacing={1}>
                  {details &&
                    details.length > 0 &&
                    details.map((item, index) => renderDetailItem(item, index))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
