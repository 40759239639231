import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, IconButton } from "@material-ui/core";
import SlaContractLevelRadio from "components/radio/SlaContractLevelRadio";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaDialog, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { IssueService } from "services/issue/issueService";
import { IssueDesc, ThingDesc, isTNF } from "services/utils";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { ISSUE } from "../../services/faApi";

export default function IssueSla() {
  const tableRef = useRef();
  const { t } = useTranslation("issue", { i18n });
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const accountId = localStorage.accountId;

  const [loading, setLoading] = useState(false);
  const [isSingleEditFormOpen, setIsSingleEditFormOpen] = useState(false);
  const [isMultiEditFormOpen, setIsMultiEditFormOpen] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedSlaContracts, setSelectedSlaContracts] = useState([]);

  const columns = [
    {
      field: "issue.notificationDate",
      label: t("BILDIRIM_TARIH"),
      align: "center",
      type: "smartDate",
    },
    {
      field: "thing",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      inner: "description",
      valueFormatter: (val, row) => ThingDesc(row.thing),
    },
    {
      field: "id",
      label: t("ISSUE"),
      align: "center",
      valueFormatter: (val, row) => IssueDesc(row.issue),
    },
    {
      field: "slaContract",
      label: t("SLA_CONTRACT"),
      align: "center",
      valueFormatter: (val, row) => val?.name,
    },
    {
      field: "id",
      label: t("SLA_LEVEL"),
      align: "center",
      valueFormatter: (value, row) => {
        if (row.issue.slaContractLevel) {
          return row.issue.slaContractLevel.name;
        } else {
          return (
            <IconButton
              onClick={() => {
                setSelectedIssue(row);
                setIsSingleEditFormOpen(true);
              }}
              size="small"
              color="primary"
              aria-label={t("Seviye Belirle")}
              label={t("Seviye Belirle")}
            >
              <FontAwesomeIcon icon={faEdit} />
            </IconButton>
          );
        }
      },
    },
  ];

  const newVendor = () => {
    return {
      id: "",
      name: "",
      email: "",
      phone1: "",
      phone2: "",
      fax: "",
      description: "",
    };
  };
  const [vendor, setVendor] = useState(newVendor());

  const newSearchForm = () => {
    let result = {
      name: null,
      description: null,
      accountId: accountId,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const saveSlaLevel = () => {
    IssueService.saveSlaLevel(
      selectedIssue.issue.id,
      selectedIssue.issue.slaContractLevel?.id,
      (data) => {
        setIsSingleEditFormOpen(false);
        tableRef.current.refreshTable();
      },
      (error) => {}
    );
  };

  const saveMultiSlaLevel = () => {
    let list = [];
    selectedRows.forEach((item) => {
      var contract = selectedSlaContracts.find(
        (c) => c.id === item.slaContract.id
      );
      list.push({
        issueId: item.issue.id,
        slaContractLevelId: contract.slaContractLevel?.id,
      });
    });

    IssueService.saveMultiSlaLevel(
      list,
      (data) => {
        setIsMultiEditFormOpen(false);
        tableRef.current.refreshTable();
      },
      (error) => {}
    );
  };

  const openMultiSlaAssignWindow = () => {
    if (selectedRows && selectedRows.length > 0) {
      let contracts = [];
      selectedRows.forEach((item) => {
        if (item.slaContract) {
          var result = contracts.find((c) => c.id === item.slaContract.id);

          if (!result) contracts.push(item.slaContract);
        }
      });

      setSelectedSlaContracts(contracts);
      setIsMultiEditFormOpen(true);
    }
  };

  const singleSlaAssingRender = () => {
    return (
      <>
        <FaDialog
          title={t("SLA Seviye Belirle")}
          faOpen={isSingleEditFormOpen}
          faOnSubmit={saveSlaLevel}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            setIsSingleEditFormOpen(data);
          }}
          faMaxWidth="lg"
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              {selectedIssue?.slaContract?.name}
            </Grid>
            <Grid item xs={12}>
              <SlaContractLevelRadio
                value={selectedIssue?.issue?.slaContractLevel}
                thingId={selectedIssue?.thing?.id}
                onChange={(data) => {
                  setSelectedIssue({
                    ...selectedIssue,
                    issue: { ...selectedIssue.issue, slaContractLevel: data },
                  });
                }}
              />
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  const multiSlaAssingRender = () => {
    return (
      <>
        <FaDialog
          title={t("SLA Seviye Belirle")}
          faOpen={isMultiEditFormOpen}
          faOnSubmit={saveMultiSlaLevel}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            setIsMultiEditFormOpen(data);
          }}
          faMaxWidth="lg"
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            {selectedSlaContracts?.map((item, index) => {
              return (
                <>
                  <Grid item xs={12}>
                    {item.name}
                  </Grid>
                  <Grid item xs={12}>
                    <SlaContractLevelRadio
                      value={item?.slaContractLevel}
                      slaContractId={item.id}
                      onChange={(data) => {
                        let tempValues = [...selectedSlaContracts];
                        tempValues[index] = {
                          ...tempValues[index],
                          slaContractLevel: data,
                        };
                        setSelectedSlaContracts(tempValues);
                      }}
                    />
                  </Grid>
                </>
              );
            })}
          </Grid>
        </FaDialog>
      </>
    );
  };

  const onSelectedChange = (rows) => {
    setSelectedRows(rows);
  };

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `issues/detail/${row.issue.id}`,
      breadCrumbKey: `/issues/detail/:id`,
    };
    history.push(tmpTo);
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {singleSlaAssingRender()}
        {multiSlaAssingRender()}

        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={2}>
            {/* <FaButton
              variant="contained"
              color="primary"
              size="medium"
              disabled={loading || !selectedRows || selectedRows.length === 0}
              faClick={() => {
                openMultiSlaAssignWindow();
              }}
              startIcon={<Edit />}
              data-fa-button="ASSIGN_SLA_LEVEL"
            >
              {t("ASSIGN_SLA_LEVEL")}
            </FaButton> */}
          </Grid>
          <Grid item xs={4}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
            >
              <FaInput
                label={t("NAME")}
                value={searchForm.name}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "name",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("DESCRIPTION")}
                value={searchForm.description}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "description",
                    event.target.value
                  );
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
        <ThingsDataTable
          searchUrl={
            ISSUE.issueSlaSearch + "?accountId=" + localStorage.accountId
          }
          searchForm={searchForm}
          columns={columns}
          queryParams={{
            page: 0,
            size: 50,
            sort: "id",
            isAsc: false,
          }}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
          //onSelectedChange={onSelectedChange}
        />
      </Suspense>
    </>
  );
}
