import { Button, Grid } from "@material-ui/core";
import React, { forwardRef, useEffect, useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { TeamRolesService } from "services/TeamRolesService";
import i18n from "../../i18n";

const TeamRolesEdit = forwardRef((props, ref) => {
  const { onChange, onDelete, onClose, value, open } = props;
  const { t } = useTranslation("teamRoles", { i18n });

  const dispatch = useDispatch();
  const faDialog = useDialog();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const accountId = localStorage.accountId;
  useEffect(() => {
    if (open) {
      setTeamRole(value == null ? newTeamRole() : { ...value });
    }
  }, [open]);

  const newTeamRole = () => {
    return {
      id: "",
      name: "",
      account: { id: accountId },
    };
  };
  const [teamRole, setTeamRole] = useState(newTeamRole());

  const handleChange = (prop) => (event) => {
    setTeamRole({ ...teamRole, [prop]: event.target.value });
  };

  const saveSuccess = (data) => {
    showAlert(!teamRole.id ? t("SAVED") : t("EDITED"), "success");
    setTeamRole(newTeamRole());
    setLoading(false);
    onChange(data);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const saveTeamRole = () => {
    setLoading(true);
    TeamRolesService.save(teamRole, saveSuccess, saveError);
  };

  const teamRoleDelete = () => {
    faDialog({
      description: t("Silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        TeamRolesService.delete(
          teamRole,
          (data) => {
            showAlert(t("Başarıyla silindi"), "success");
            onDelete();
          },
          (error) => {
            showAlert(t("Kayıt silinemedi, bağlı kayıtlar mevcut."), "error");
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const newFormRender = () => {
    return (
      <>
        <FaDialog
          title={"Takım Rolü Tanımlama"}
          faOpen={open}
          faOnSubmit={saveTeamRole}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            onClose();
          }}
          faMaxWidth="lg"
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <FaInput
                required
                faType="name"
                label={t("Tanım")}
                value={teamRole.name}
                inputProps={{ maxLengt: 100 }}
                onChange={handleChange("name")}
              />
            </Grid>
            {teamRole.id > 0 && (
              <Grid item xs={12} style={{ textAlign: "right" }}>
                <Button onClick={teamRoleDelete} color="secondary">
                  Sil
                </Button>
              </Grid>
            )}
          </Grid>
        </FaDialog>
      </>
    );
  };

  return newFormRender();
});

export default TeamRolesEdit;
