import { Button, ButtonGroup } from "@material-ui/core";
import React from "react";
import { getRole } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ROLES } from "services/utils/Roles";
import { checkRole } from "services/utils/SecurityUtils";
import i18n from "../../i18n";

export default function CustomerInteractionOperationButtonGroup(props) {
  const { value, onChange, ...other } = props;

  const { t } = useTranslation("components", { i18n });

  const role = useSelector(getRole);

  const survey = checkRole([ROLES.SURVEY], role);
  const campaign = checkRole([ROLES.CAMPAIGN], role);
  const userContent = checkRole([ROLES.USER_CONTENT], role);

  return (
    <ButtonGroup
      fullWidth
      size="small"
      variant="outlined"
      color="primary"
      aria-label="small outlined button group"
      {...other}
    >
      <Button
        color={"primary"}
        variant={value == null ? "contained" : "outlined"}
        onClick={() => {
          onChange(null);
        }}
      >
        {t("ALL")}
      </Button>

      <Button
        color={"primary"}
        variant={value === "ISSUE_REPORT" ? "contained" : "outlined"}
        onClick={() => {
          onChange("ISSUE_REPORT");
        }}
      >
        {t("CUSTOMER_INTERACTION_OPERATION.ISSUE_REPORT")}
      </Button>
      <Button
        color={"primary"}
        variant={value === "LIKE" ? "contained" : "outlined"}
        onClick={() => {
          onChange("LIKE");
        }}
      >
        {t("CUSTOMER_INTERACTION_OPERATION.LIKE")}
      </Button>

      <Button
        color={"primary"}
        variant={value === "SUGGESTION" ? "contained" : "outlined"}
        onClick={() => {
          onChange("SUGGESTION");
        }}
      >
        {t("CUSTOMER_INTERACTION_OPERATION.SUGGESTION")}
      </Button>
      {survey && (
        <Button
          color={"primary"}
          variant={value === "SURVEY" ? "contained" : "outlined"}
          onClick={() => {
            onChange("SURVEY");
          }}
        >
          {t("CUSTOMER_INTERACTION_OPERATION.SURVEY")}
        </Button>
      )}
      {campaign && (
        <Button
          color={"primary"}
          variant={value === "CAMPAIGN" ? "contained" : "outlined"}
          onClick={() => {
            onChange("CAMPAIGN");
          }}
        >
          {t("CUSTOMER_INTERACTION_OPERATION.CAMPAIGN")}
        </Button>
      )}
      <Button
        color={"primary"}
        variant={value === "CUSTOMER" ? "contained" : "outlined"}
        onClick={() => {
          onChange("CUSTOMER");
        }}
      >
        {t("CUSTOMER_INTERACTION_OPERATION.CUSTOMER")}
      </Button>
      <Button
        color={"primary"}
        variant={value === "SCAN_QR" ? "contained" : "outlined"}
        onClick={() => {
          onChange("SCAN_QR");
        }}
      >
        {t("CUSTOMER_INTERACTION_OPERATION.SCAN_QR")}
      </Button>
      <Button
        color={"primary"}
        variant={value === "PUBLIC_INFO" ? "contained" : "outlined"}
        onClick={() => {
          onChange("PUBLIC_INFO");
        }}
      >
        {t("CUSTOMER_INTERACTION_OPERATION.PUBLIC_INFO")}
      </Button>
      <Button
        color={"primary"}
        variant={value === "DOWNLOAD_DOCUMENT" ? "contained" : "outlined"}
        onClick={() => {
          onChange("DOWNLOAD_DOCUMENT");
        }}
      >
        {t("CUSTOMER_INTERACTION_OPERATION.DOWNLOAD_DOCUMENT")}
      </Button>
      <Button
        color={"primary"}
        variant={value === "DOWNLOAD_IMAGE" ? "contained" : "outlined"}
        onClick={() => {
          onChange("DOWNLOAD_IMAGE");
        }}
      >
        {t("CUSTOMER_INTERACTION_OPERATION.DOWNLOAD_IMAGE")}
      </Button>
      <Button
        color={"primary"}
        variant={value === "THING_LINK" ? "contained" : "outlined"}
        onClick={() => {
          onChange("THING_LINK");
        }}
      >
        {t("CUSTOMER_INTERACTION_OPERATION.THING_LINK")}
      </Button>
      <Button
        color={"primary"}
        variant={value === "SOCIAL_MEDIA" ? "contained" : "outlined"}
        onClick={() => {
          onChange("SOCIAL_MEDIA");
        }}
      >
        {t("CUSTOMER_INTERACTION_OPERATION.SOCIAL_MEDIA")}
      </Button>
      {userContent && (
        <Button
          color={"primary"}
          variant={value === "USER_CONTENT" ? "contained" : "outlined"}
          onClick={() => {
            onChange("USER_CONTENT");
          }}
        >
          {t("CUSTOMER_INTERACTION_OPERATION.USER_CONTENT")}
        </Button>
      )}
    </ButtonGroup>
  );
}
