import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";
import { GetFieldByLanguage } from "services/utils";
export default function SurveyQuestionCheckbox(props) {
  const { question, answer, onChange, readOnly, currentLang } = props;

  return (
    <Grid item xs={12}>
      <FormControl
        component="fieldset"
        style={{ width: "100%" }}
        required={question.notEmpty}
      >
        <Grid container item row xs={12}>
          <Grid item xs={12}>
            <FormLabel component="legend">
              {question.orderNo}.{" "}
              {GetFieldByLanguage(question.multiLangName, currentLang)}
            </FormLabel>
          </Grid>
          <Grid item xs={12}>
            <FormHelperText>
              {GetFieldByLanguage(question.multiLangHint, currentLang)}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid container item row xs={12}>
          {question.options.map((item, index) => {
            return (
              <FormControlLabel
                key={index}
                value={"" + item.id}
                control={
                  <Checkbox
                    checked={
                      answer?.selectedOptions?.filter(
                        (x) => x === item.id || x === "" + item.id
                      ).length > 0
                    }
                    onChange={(event) => {
                      if (event.target.checked) {
                        let tmp = answer.selectedOptions
                          ? [...answer.selectedOptions, "" + item.id]
                          : ["" + item.id];
                        onChange(question, tmp, "selectedOptions");
                      } else {
                        var tmp = [...answer.selectedOptions];
                        const index = tmp.indexOf("" + item.id);
                        tmp.splice(index, 1);
                        onChange(question, tmp, "selectedOptions");
                      }
                    }}
                    color="primary"
                    label={GetFieldByLanguage(item.multiLangName, currentLang)}
                    disabled={readOnly}
                  />
                }
                label={GetFieldByLanguage(item.multiLangName, currentLang)}
              />
            );
          })}
        </Grid>
      </FormControl>
    </Grid>
  );
}

SurveyQuestionCheckbox.propTypes = {
  question: PropTypes.any,
  answer: PropTypes.any,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};
