import { faCubes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Chip, Grid, Paper } from "@material-ui/core";
import { Add, Cancel } from "@material-ui/icons";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import { default as React, useEffect, useState } from "react";
import { FaButton, FaDialog } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { CouponService } from "services/CouponService";
import { ThingDesc, isTNF } from "services/utils";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function CouponProductEdit(props) {
  const { couponId, products, open, onSave, onClose } = props;
  const { t } = useTranslation("coupon", { i18n });
  const accountId = localStorage.accountId;

  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [editValue, setEditValue] = useState();
  const [newProducts, setNewProducts] = useState([]);
  const [deleteItems, setDeleteItems] = useState([]);
  const [sectionThing, setSectionThing] = useState(false);

  useEffect(() => {
    let tmp = products
      ? products?.map((data) => {
          let dto = {
            id: data.id,
            thing: null,
            label: null,
            key: null,
          };

          if (data.thing) {
            dto.thing = data.thing;
            dto.label = ThingDesc(data.thing);
            dto.key = "t_" + data.id;
          }

          return dto;
        })
      : [];

    setEditValue(tmp);
  }, [products]);

  const handleChipDelete = (data, index) => () => {
    var temp = [...editValue];
    temp.splice(index, 1);
    setEditValue(temp);

    setDeleteItems([...deleteItems, data.id]);
  };

  const addProductThing = (value) => {
    let dto = {
      thingClass: null,
      thing: { id: value.id },
      hashtag: null,
      label: ThingDesc(value),
      key: "t_" + value.id,
    };

    addProduct(dto);
  };

  const addProduct = (newValue) => {
    newValue.coupon = { id: couponId };
    setEditValue([...editValue, newValue]);
    setNewProducts([...newProducts, newValue]);
  };

  const save = () => {
    if (newProducts.length !== 0) {
      setLoading(true);
      saveProducts(newProducts);
    }
    if (deleteItems.length !== 0) {
      deleteProducts(deleteItems);
    }
  };

  const saveProducts = (data) => {
    CouponService.saveProducts(
      [...data],
      (data) => {
        setNewProducts([]);
        onSave();
        setLoading(false);
      },
      errorCallback
    );
  };

  const deleteProducts = (data) => {
    CouponService.deleteProducts(
      [...data],
      (data) => {
        setDeleteItems([]);
        onSave();
      },
      errorCallback
    );
  };

  const errorCallback = (error) => {
    console.log(error);
    setLoading(false);
  };
  const renderEditForm = () => {
    return (
      <>
        <FaDialog
          title={t("COUPON_PRODUCTS")}
          faOpen={open}
          faOnSubmit={save}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            onClose();
            setNewProducts([]);
            setDeleteItems([]);
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
            data-fa-section="COUPON_PRODUCT_DIALOG"
          >
            <Grid item xs={12}>
              <Paper component="ul" className={classes.chipRoot}>
                {editValue?.map((data, index) => {
                  let icon;

                  if (data.thing) {
                    icon = <FontAwesomeIcon icon={faCubes} />;
                  }

                  return (
                    <li key={data.key}>
                      <Chip
                        icon={icon}
                        label={data.label}
                        onDelete={handleChipDelete(data, index)}
                        className={classes.chip}
                      />
                    </li>
                  );
                })}
              </Paper>
              <div className={classes.buttonPanel}>
                <FaButton
                  faClick={() => {
                    setSectionThing(true);
                  }}
                  fullWidth={false}
                  startIcon={<Add />}
                >
                  {isTNF(t("ADD_THING"), t("ADD_THING_T2C"))}
                </FaButton>
              </div>

              {sectionThing ? (
                <div className={classes.hboxBlock}>
                  <ThingAutoComplete
                    accountId={accountId}
                    onChange={(data) => {
                      addProductThing(data);
                      setSectionThing(false);
                    }}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Cancel />}
                    className={classes.cancelButton}
                    onClick={() => {
                      setSectionThing(false);
                    }}
                  >
                    {t("CANCEL")}
                  </Button>
                </div>
              ) : null}
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  return renderEditForm();
}
