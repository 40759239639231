import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import i18n from "../../../i18n";
import { useStyles } from "../style";
import ThingsSteps from "../ThingsSteps";
import TutorialElement from "../TutorialElement";

export default function SubscriptionsTutorial() {
  const { code } = useParams();
  const classes = useStyles();

  const stepsRef = useRef();
  let history = useHistory();

  const { t } = useTranslation("tutorialAssignTask", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="click">
            <p>
              Üyelik paketlerinin ve içeriklerinin yer aldığı alandır.
              <br /> <br />
              Bu alanda;
              <ul>
                <li>
                  <b>Paket Seçeneklerini</b> ve{" "}
                  <b>Aylık/Yıllık üyelik bedellerini</b> görebilirsiniz.
                </li>
                <li>
                  <b>Paket Satın alma </b> işlemlerini gerçekleştirebilirsiniz.
                </li>
                <li>
                  <b>Üyelik Geçmişinizi </b>ve <b>Faturalarınızı</b> liste
                  olarak görebilirsiniz.
                </li>
              </ul>
            </p>
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: ".MuiTabs-root",
        intro: (
          <TutorialElement icon="click">
            <b>Aylık</b> ve <b>Yıllık</b> Üyelik bedellerini görmek için
            tıklayın.
          </TutorialElement>
        ),
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/tutorial/subscriptions.png"
                }
                width="816"
                height="381"
              ></img>
            </div>
            Paketi satın almak için dilediğiniz paketin <b>Satın Al</b> butonuna
            basın ve ödeme işlemlerini gerçekleştirin.
          </TutorialElement>
        ),
        tooltipClass: classes.suggestionListElement,
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/tutorial/uyelikGecmisi.png"
                }
                width="654"
                height="264"
              ></img>
            </div>
            <b>Üyelik Geçmişi</b> ve <b>Fatura</b> bilgilerini listelemek için
            tıklayın.
          </TutorialElement>
        ),
        tooltipClass: classes.issueDateSettings,
      },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={code}
        steps={steps}
        ref={stepsRef}
        onStepChange={setStepIndex}
      />
    </div>
  );
}
