import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  image: {
    position: "relative"
  },
  imageDeleteButtom: {
    position: "absolute",
    left: 0,
    bottom: 0,
    zIndex: 1,
    marginLeft: "10px",
    marginBottom: "10px"
  },
  buttonPanel: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chipRoot: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  cancelButton: {
    marginBottom: "20px",
    marginLeft: "5px",
  },
}));
