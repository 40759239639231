import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import i18n from "i18n";
import PropTypes from "prop-types";
import { default as React } from "react";
import { useTranslation } from "react-i18next";
import { labelStyles } from "styles/ThingsLabelStyle";

export default function AnswerDistribution(props) {
  const { questionAnalysis } = props;

  const labelClasses = labelStyles();
  const { t } = useTranslation("survey", { i18n });

  return (
    <Grid item container spacing={1}>
      <Grid item xs={12}>
        <Typography className={labelClasses.LabelHeader}>
          {t("Cevaplanma") + ":" + questionAnalysis?.answeredNum}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={labelClasses.LabelHeader}>
          {t("Boş bırakma") + ":" + questionAnalysis?.emptyNum}
        </Typography>
      </Grid>
    </Grid>
  );
}

AnswerDistribution.propTypes = {
  questionAnalysis: PropTypes.any,
};
