import { Grid } from "@material-ui/core";
import { default as React, Suspense, useRef, useState } from "react";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { OrganizationService } from "services/OrganizationService";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import OrganizationAdminUsers from "./OrganizationAdminUsers";
import OrganizationDetail from "./OrganizationDetail";
import OrganizationTree from "./OrganizationTree";
import OrganizationUsers from "./OrganizationUsers";

export default function Organization() {
  const treeRef = useRef();
  const { t } = useTranslation("organization", { i18n });

  const [selectedItem, setSelectedItem] = useState(null);
  const faDialog = useDialog();

  const onSelect = (data) => {
    setSelectedItem(data);
  };

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const onSave = (data) => {
    OrganizationService.save(data, saveSuccess, saveError);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
  };

  const saveSuccess = (data) => {
    showAlert(!data.id ? t("SAVED") : t("EDITED"), "success");
    setSelectedItem(data);
    treeRef.current.refreshTree();
  };

  const onDelete = (val) => {
    faDialog({
      description: t("Organizasyonu silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        OrganizationService.delete(
          val,
          (data) => {
            showAlert(t("Organizasyon silindi"), "success");
            treeRef.current.refreshTree();
            setSelectedItem(null);
          },
          (error) => {
            showAlert(t("Organizasyon silinemedi"), "error");
          }
        );
      })
      .catch((err) => {});
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid container spacing={1}>
          <Grid item lg={6} xs={12}>
            <Grid item container spacing={1}>
              <Grid item xs={12}>
                <OrganizationTree
                  ref={treeRef}
                  onSelect={(data) => setSelectedItem(data)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={6} xs={12}>
            <Grid item container spacing={1}>
              <Grid item xs={12}>
                <OrganizationDetail
                  organization={selectedItem}
                  onSave={onSave}
                  onDelete={onDelete}
                />
              </Grid>

              <Grid item xs={12}>
                <OrganizationAdminUsers organization={selectedItem} />
              </Grid>
              <Grid item xs={12}>
                <OrganizationUsers organization={selectedItem} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
