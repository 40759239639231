import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function FeedbackConfirmTypeSelect(props) {
  const { value, onChange, required, label } = props;
  const classes = useStyles();
  const { t } = useTranslation("components", { i18n });

  return (
    <FormControl variant="outlined" className={classes.w100} size="small">
      <InputLabel>{label}</InputLabel>
      <Select
        // classes={{
        //   outlined: classes.padding10,
        // }}
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        required={required}
        label={label}
      >
        <MenuItem value={null}>...</MenuItem>
        <MenuItem value={"ASK_ALWAYS"}>
          {t("FEEDBACK_CONFIRM_TYPE_ASK_ALWAYS")}
        </MenuItem>
        <MenuItem value={"NEVER"}>{t("FEEDBACK_CONFIRM_TYPE_NEVER")}</MenuItem>
        <MenuItem value={"ACCEPT"}>
          {t("FEEDBACK_CONFIRM_TYPE_ACCEPT")}
        </MenuItem>
      </Select>
    </FormControl>
  );
}
