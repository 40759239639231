import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import ThingClassAutoComplete from "components/autocomplete/ThingClassAutoComplete";
import VendorAutoComplete from "components/autocomplete/VendorAutoComplete";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaAutoComplete, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isTNF } from "services/utils";
import i18n from "../../i18n";
import { THING } from "../../services/faApi";

const ThingCreate = forwardRef((props, ref) => {
  const { t } = useTranslation(["things", "errorMessages"], { i18n });
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsThingRegisterFormOpen(true);
    },
  }));

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const accountId = localStorage.accountId;
  const userInfo = useSelector(getUserInfo);
  const [isThingRegisterFormOpen, setIsThingRegisterFormOpen] = useState(false);
  const [location, setLocation] = useState({
    address: "",
    geoCode: { lat: "", lng: "" },
  });
  const [files, setFiles] = useState([]);
  const [isGenerateThingDesc, setIsGenerateThingDesc] = useState(true);
  const [thingDTO, setThingDTO] = useState({
    description: "",
    status: "ACTIVE",
    serviceStatus: "IN_SERVICE",
    thingcode: "",
    account: "",
    thid: "",
    thingClass: { id: 1, className: "Thing" },
    hashtag: "",
    vendor: null,
    location: "",
  });

  const [loading, setLoading] = useState(false);
  const [hashtags, setHashtags] = useState([]);
  const [advanceMode, setAdvanceMode] = useState(false);

  useEffect(() => {}, []);

  const handleChangeV = (prop, val) => {
    setThingDTO({ ...thingDTO, [prop]: val });
    if (prop === "description") setIsGenerateThingDesc(false);
  };

  const saveFiles = (tid) => {
    files.forEach((fileObj) => {
      let fd = new FormData();
      fd.append("multipartFile", fileObj.file, fileObj.file.name);
      let req = requests.getSimplePostRequest(THING.uploadFile + "/" + tid, fd);
      req["header"] = { "Content-Type": "multipart/form-data" };
      FaAxios(req)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          showAlert(error.message, "error");
          setLoading(false);
        });
    });
  };

  const saveThing = () => {
    setLoading(true);
    let req = requests.getSimpleGetRequest(THING.getTids, {
      accountId: accountId,
      count: 1,
    });
    FaAxios(req)
      .then((response) => {
        // Oluşturulacak Thing'e ait hastag alınır
        let hashtagString = "";
        hashtags.map((item) => {
          hashtagString = hashtagString.concat("#" + item + " ");
          return null;
        });
        let reqBody = {
          ...thingDTO,
          thid: response.data[0],
          account: { id: accountId },
          ownerUser: { ...userInfo },
          hashtag: hashtagString,
        };
        let req = requests.getSimplePostRequest(THING.checkAndSave, reqBody);
        FaAxios(req)
          .then((res) => {
            // Thing kaydedildikten sonra kaydedilen Thing objesi alınır
            let tid = res.data.id;
            if (files && tid) {
              // Eklenti olarak dosya varsa dosyalar ayrı istek ile kaydedilir
              saveFiles(tid);
            }
            showAlert(
              isTNF("Etkileşim noktası kayıt edildi", "Varlık kayıt edildi"),
              "success"
            );
            props.onCreate(res.data);
            /*setTimeout(() => {
              setIsThingRegisterFormOpen(!isThingRegisterFormOpen);
            }, 1000);
            setTimeout(() => {
              setThingDTO({
                description: "",
                status: "ACTIVE",
                serviceStatus: "IN_SERVICE",
                thingcode: "",
                account: "",
                thid: "",
                thingClass: "",
                hashtag: "",
              });
              setFiles([]);
              setLocation({ address: "", geoCode: { lat: "", lng: "" } });
              setHashtags([]);
              refresh();
              setLoading(false);
            }, 1200);*/
          })
          .catch((e) => {
            switch (e && e.response && e.response.status) {
              case 406:
                showAlert(
                  t("errorMessages:THING_SAVE_NOT_ACCEPTABLE"),
                  "error"
                );
                break;
              case 409:
                showAlert(t("errorMessages:THING_SAVE_CONFLICT"), "error");
                break;
              case 423:
                showAlert(t("errorMessages:THING_SAVE_LOCKED"), "error");
                break;
              default:
                showAlert(t("errorMessages:UNEXPECTED_ERROR"), "error");
                break;
            }
            setLoading(false);
          });
      })
      .catch((error) => {
        showAlert(error.message, "error");
      });
  };

  const onFocusDesc = (event) => {
    if (isGenerateThingDesc) event.target.select();
  };

  const generateThingDesc = (thingClassId) => {
    setLoading(false);
    var req = requests.getSimpleGetRequest(THING.generateThingDesc, {
      accountId: accountId,
      thingClassId: thingClassId,
    });
    FaAxios(req)
      .then((response) => {
        if (!loading) {
          setThingDTO((prev) => {
            return { ...prev, ["description"]: response.data[0] };
          });
          //  setThingDTO({
          //    ...thingDTO,
          //    ["description"]: response.data[0],
          //  });
        }
      })
      .catch((error) => {
        showAlert("error", error);
        setLoading(false);
      });
    return () => {
      setLoading(false);
    };
  };

  return (
    <FaDialog
      title={"THING_CREATE"}
      isLocationOn
      faLocation={location}
      faSetLocation={setLocation}
      isDocumentUploadOn
      faFiles={files}
      faSetFiles={setFiles}
      faOpen={isThingRegisterFormOpen}
      faOnSubmit={saveThing}
      loading={loading}
      showSaveButton
      faHandleClose={(data) => {
        setIsThingRegisterFormOpen(data);
        setFiles([]);
        setLocation({
          address: "",
          geoCode: { lat: "", lng: "" },
        });
        setThingDTO({
          description: "",
          status: "ACTIVE",
          serviceStatus: "IN_SERVICE",
          thingcode: "",
          account: "",
          thid: "",
          thingClass: { id: 1, className: "Thing" },
          hashtag: "",
          vendor: null,
          location: "",
        });
      }}
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <FormControlLabel
            control={
              <Switch
                checked={advanceMode}
                onChange={() => {
                  setAdvanceMode(!advanceMode);
                }}
                color="primary"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
            label={t("Gelişmiş")}
          />
        </Grid>
        <Grid item xs={12}>
          <ThingClassAutoComplete
            required={true}
            value={thingDTO.thingClass}
            accountId={accountId}
            onChange={(data) => {
              setThingDTO({
                ...thingDTO,
                ["thingClass"]: data,
              });
              if (isGenerateThingDesc && data) generateThingDesc(data.id);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FaInput
            label={t("DESCRIPTION")}
            onChange={(event) =>
              handleChangeV("description", event.target.value)
            }
            onFocus={onFocusDesc}
            value={thingDTO.description || ""}
          />
        </Grid>
        <Grid item xs={12}>
          <FaInput
            label={t("THING_CODE")}
            onChange={(event) => handleChangeV("thingCode", event.target.value)}
            value={thingDTO.thingCode || ""}
          />
        </Grid>
        {advanceMode && (
          <Grid item xs={12}>
            <FaAutoComplete
              faType="async"
              value={hashtags}
              faMultiple={true}
              style={{ minHeight: 65 }}
              //faDefaultValue={hashtags ? hashtags : ""}
              getOptionSelected={(option, value) => {
                return option === value;
              }}
              sort="filter"
              autoSelect
              faFilterProp="filter"
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option;
              }}
              requestUrl={THING.findHashtag + "?accountId=" + accountId}
              onChange={(event, newValue) => {
                setHashtags(newValue);
              }}
              freeSolo
              label={t("HASHTAG")}
            />
          </Grid>
        )}

        {advanceMode && (
          <Grid item xs={12}>
            <VendorAutoComplete
              value={thingDTO.vendor}
              accountId={accountId}
              onChange={(data) => {
                handleChangeV("vendor", data);
              }}
            />
          </Grid>
        )}
        {advanceMode && (
          <Grid item xs={12}>
            <FaInput
              label={t("LOCATION")}
              onChange={(event) =>
                handleChangeV("location", event.target.value)
              }
              value={thingDTO.location || ""}
            />
          </Grid>
        )}

        {location.address ? (
          <>
            <Grid item xs={12}>
              <FaInput
                label={"location"}
                disabled
                value={location.address || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                label={"locationGeocode"}
                disabled
                value={
                  location.geoCode
                    ? location.geoCode.lat + " " + location.geoCode.lng
                    : ""
                }
              />
            </Grid>
          </>
        ) : null}
      </Grid>
    </FaDialog>
  );
});

export default ThingCreate;
