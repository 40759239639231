import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { QRTEMPLATE } from "../../services/faApi";

export const QRTemplateService = {
  save: (qrTemplate, image, callback, errorCallback) => {
    let reqBody = {
      ...qrTemplate,
    };

    let req = requests.getSimplePostRequest(QRTEMPLATE.save, reqBody);

    FaAxios(req)
      .then((response) => {
        if (image) {
          let fd = new FormData();
          fd.append("multipartFile", image.file, image.file.name);
          let req = requests.getSimplePostRequest(
            QRTEMPLATE.uploadFile + "/" + response.data.id,
            fd
          );
          req["header"] = { "Content-Type": "multipart/form-data" };
          FaAxios(req)
            .then((response2) => {
              callback(response.data);
            })
            .catch((error) => {
              errorCallback(error?.response?.data);
            });
        } else {
          callback(response.data);
        }
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(QRTEMPLATE.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findQRTemplate: (accountId, paging, callback, errorCallback) => {
    let s = {
      accountId: accountId,
      ...paging,
    };

    let req = requests.getSimpleGetRequest(QRTEMPLATE.findQRTemplate, s);

    FaAxios(req)
      .then((response) => {
        callback(response.data.list);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (form, callback, errorCallback) => {
    let reqBody = {
      ...form,
    };

    let req = requests.getSimplePostRequest(QRTEMPLATE.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  uploadImageElementFile: (accountId, document, callback, errorCallback) => {
    let formData = new FormData();
    console.log("document", document);
    formData.append("multipartFile", document, document.name);

    let req = requests.getSimplePostRequest(
      QRTEMPLATE.uploadImageElementFile + "/" + accountId,
      formData
    );
    req["header"] = { "Content-Type": "multipart/form-data" };

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
