import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 5,
  },
  modal: {
    width: "100%",
  },
  chipOpen: {
    backgroundColor: theme.palette.success.dark,
    color: "white",
  },
  chipClose: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.contrastText,
  },
  drawer: {
    overflow: "hidden",
  },
  drawerContent: {
    padding: 10,
    height: 400,
  },
  drawerPaper: {
    height: 400,
    top: 80,
    width: "21.7%",
    minWidth: 200,
  },
  avatar: {
    width: 50,
    height: 50,
    marginRight: 15,
  },
  success: {
    backgroundColor: green[500],
  },
  chipRoot: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  cancelButton: {
    marginBottom: "20px",
    marginLeft: "5px",
  },
  buttonPanel: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  CampaignImage: {
    //width: "100%",
    //height: "100%",
    maxHeight: "200px",
  },

  NumberPanel: {
    textAlign: "center",
    paddingBottom: 5,
    paddingTop: 10,
    cursor: "pointer",
  },
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  map: {
    flexGrow: 1,
    height: 500,
  },
  closeButton: {
    color: theme.palette.grey[500],
    right: 0,
    top: 0,
    position: "absolute",
  },

  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  image: {
    borderRadius: "15%",
    width: 100,
    height: 100,
  },
  imageDeleteButtom: {
    position: "absolute",
    left: 0,
    bottom: 0,
    zIndex: 1,
    display: "block",
    color: "rgba(255, 255, 255, 0.54)",
  },
  imageContainer: {
    borderRadius: "15%",
    marginRight: "10px",
    position: "relative",
  },
  defaultImage: {
    position: "absolute",
    right: 0,
    bottom: 0,
    zIndex: 1,
    marginRight: "20px",
    marginBottom: "20px",
  },
}));
