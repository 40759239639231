import AccountInteractionPage from "features/accountDetails/AccountInteractionPage";
import MyThing from "features/things/MyThing";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import i18n from "../../i18n";
import { useStyles } from "./QuickStartTutorialStyle";
import ThingsSteps from "./ThingsSteps";
import TutorialElement from "./TutorialElement";

export default function QuickStartTutorial() {
  const { code } = useParams();
  const classes = useStyles();

  const stepsRef = useRef();
  const myThingRef = useRef(null);
  let history = useHistory();

  const { t } = useTranslation("tutorialQuickStart", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        //0
        intro: (
          <TutorialElement header={t("welcome")} icon="tutorial" text={""} />
        ),
      },
      {
        //1
        element: "#menuMyThing",
        intro: (
          <TutorialElement header="Menu" icon="click">
            <b>{t("Etkileşim Noktam")}</b> {t(" menüsüne tıklayın")}.
          </TutorialElement>
        ),
      },
      {
        //2
        intro: (
          <TutorialElement
            icon="info"
            text={t(
              "Etkileşim noktanız hakkında bilgiler burada yer almaktadır."
            )}
          />
        ),
        comp: "MyThing",
      },
      {
        //3
        element: "[data-fa-input='QR_MENU']",
        intro: (
          <TutorialElement
            icon="info"
            text={t(
              "İşletme, Ürün ya da Hizmet Noktanıza özel Kare Kodunuzu indirin."
            )}
          />
        ),
        comp: "MyThing",
        onBeforeChange: () => {
          stepsRef.current.updateStepElement(3);
          stepsRef.current.updateStepElement(4);
        },
      },
      {
        //4
        element:
          "#qr-menu > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded",
        intro: (
          <TutorialElement icon="click">
            <div>
              <p>{t("Kare Kod’a tıklayın ve ardından")}</p>
              <p>
                <b>{t("QR İndir")}</b> {t("veya")} <b>{t("QR Etiket Bas")}</b>
                {t("’a tıklayın")}
              </p>
            </div>
          </TutorialElement>
        ),
        comp: "MyThing",
        highlightClass: classes.highlightClassCustom,
        onBeforeChange: () => {
          myThingRef.current.openPhotoMenu();
        },
        onAfterChange: () => {
          stepsRef.current.updateStepElement(4);
        },
      },
      {
        element: null,
        intro: (
          <TutorialElement icon="click">
            <div>
              <p>
                {t(
                  "Kare Kod’u bir etikete bastırın ve müşterilerinizle etkileşim kurmak istediğiniz nokta/noktalara yerleştirin."
                )}
              </p>
            </div>
          </TutorialElement>
        ),
        comp: "MyThing",
      },
      {
        element: null,
        intro: (
          <TutorialElement icon="tip">
            <div style={{ textAlign: "center" }}>
              <p>
                <b>{t("İpucu: ")}</b>
                {t(
                  "Müşterilerinizle etkileşime geçmek için birden fazla ürün ya da hizmet noktanıza"
                )}
                <b>{t(" aynı QR kodu")}</b>
                {t(" yerleştirebilirsiniz.")}
                <br />
                {t("          Ya da")}
                <br />
                {t(" Her bir ürün ya da hizmet noktanıza ")}
                <b>
                  {t(
                    "sorun, görüş ve öneri toplamak, içerik paylaşmak, anket, kampanya ve etkinlik duyurmak "
                  )}
                </b>
                için <b>{t("farklı QR kodu ")}</b>
                {t("yerleştirebilirsiniz.")}
              </p>
            </div>
          </TutorialElement>
        ),
        comp: "MyThing",
      },
      {
        element: "[data-fa-section='THING_INTERACTION_PAGE_OPTIONS']",
        intro: (
          <TutorialElement
            icon="done"
            text={t(
              "Etkileşime hazırsınız. " +
                "Artık QR kodu ile işletme, ürün ya da hizmet noktanızla ilgili sorun, görüş ve öneri toplayabilir," +
                " içerik paylaşabilir, anket, kampanya ve etkinlikleri duyurabilirsiniz."
            )}
          />
        ),
        comp: "AccountInteraction",
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
    ]);
  }, []);

  return (
    <div>
      {steps &&
        steps.length > stepIndex &&
        steps[stepIndex].comp === "MyThing" && <MyThing ref={myThingRef} />}

      {steps &&
        steps.length > stepIndex &&
        steps[stepIndex].comp === "AccountInteraction" && (
          <AccountInteractionPage />
        )}

      <ThingsSteps
        code={code}
        steps={steps}
        ref={stepsRef}
        onStepChange={setStepIndex}
        afterComplete={() => window.history.back()}
      />
    </div>
  );
}
