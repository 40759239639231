import { CardHeader, IconButton } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import AddIcon from "@material-ui/icons/Add";
import UserDetail from "components/detail/UserDetail";
import ThingsDataTable from "components/thingsDataTable";
import ActivePassiveDecorator from "components/ui/ActivePassiveDecorator";
import ReservationEdit from "features/reservation/ReservationEdit";
import { default as React, useRef } from "react";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RESERVATION } from "services/faApi";
import { gridStyles } from "styles/ThingsGridStyle";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";

export default function ThingReservation(props) {
  const { thing, onUpdate } = props;
  const { t } = useTranslation("thingDetail", { i18n });
  const labelClasses = labelStyles();
  const dialogRef = useRef();
  let history = useHistory();
  const accountId = localStorage.accountId;
  const tableRef = useRef();
  const gridClasses = gridStyles();
  const userInfo = useSelector(getUserInfo);

  const newReservation = () => {
    return {
      account: { id: accountId },
      user: userInfo,
      thing: thing,
      reservationDateStart: null,
      reservationDateEnd: null,
      description: null,
      status: "ACTIVE",
    };
  };

  const columns = [
    {
      field: "user.fullname",
      label: t("USER"),
      align: "center",
      width: "25%",
      valueFormatter: (value, row) => {
        return <UserDetail value={row?.user} type="USER" />;
      },
    },
    {
      field: "reservationDateStart",
      label: t("START_DATE"),
      align: "center",
      width: "25%",
      type: "dateTime",
    },
    {
      field: "reservationDateEnd",
      label: t("END_DATE"),
      align: "center",
      width: "25%",
      type: "dateTime",
    },
    {
      field: "description",
      label: t("DESCRIPTION"),
      align: "center",
      width: "25%",
    },
    {
      field: "status",
      label: t("STATUS"),
      align: "center",
      width: "25%",
      valueFormatter: (val) => (
        <ActivePassiveDecorator value={val}></ActivePassiveDecorator>
      ),
    },
  ];

  const refresh = () => {
    tableRef.current.refreshTable();
  };

  const renderEditDialog = () => {
    return <ReservationEdit ref={dialogRef} onSave={refresh}></ReservationEdit>;
  };

  const onDoubleClick = (row) => {
    dialogRef.current.openDialog({ ...row });
  };

  return (
    <>
      {renderEditDialog()}
      <Card style={{ minHeight: 100 }}>
        <CardHeader
          action={
            <>
              <IconButton
                aria-label="settings"
                onClick={() => dialogRef.current.openDialog(newReservation())}
              >
                <AddIcon />
              </IconButton>
            </>
          }
          title={t("THING_RESERVATIONS")}
          data-fa-section="THING_RESERVATIONS"
        />
        {thing && thing.id > 0 && (
          <ThingsDataTable
            dataUrl={RESERVATION.findThingReservations + "?thingId=" + thing.id}
            queryParams={{
              page: 0,
              size: 10,
              sort: "reservationDateStart",
              isAsc: false,
            }}
            columns={columns}
            ref={tableRef}
            showDeleteButton={false}
            showHeaderText={false}
            showHeader={false}
            columnClassName={gridClasses.GenericColumn}
            faOnDoubleClick={onDoubleClick}
          />
        )}
      </Card>
    </>
  );
}
