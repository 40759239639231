import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { useStyles } from "../style";
import ThingsSteps from "../ThingsSteps";
import TutorialElement from "../TutorialElement";

export default function LocationDetailTutorial() {
  const stepsRef = useRef();

  const { t } = useTranslation("tutorialCustomerInteractionsAll", { i18n });
  const classes = useStyles();

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            <b>İç Mekân Bilgilerinin, İç Mekân Krokilerinin ve Konum</b>{" "}
            güncellemelerinin yapıldığı sayfadır.
          </TutorialElement>
        ),
      },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={"location_detail"} // veri tabanındaki kodla aynı olması önemli
        steps={steps}
        ref={stepsRef}
        onStepChange={setStepIndex}
      />
    </div>
  );
}
