import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  w100: {
    width: "100%",
  },
  padding10: {
    padding: "14.5px 14px",
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));
