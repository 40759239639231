import {
  faEdit,
  faVolumeMute,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { Add, Close, Settings } from "@material-ui/icons";
import ThingsDataTable from "components/thingsDataTable";
import AdditionalFieldsEdit from "features/additionalFields/additionalFieldsEdit";
import ThingClassSuggestionClassHelp from "features/help/ThingClassSuggestionClassHelp";
import React, { useEffect, useRef, useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { SuggestionClassService } from "services/SuggestionClassService";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function ThingClassSuggestionClassEdit(props) {
  const { thingClass } = props;

  const { t } = useTranslation("thingClass", { i18n });
  const tableRef = useRef();
  const classes = useStyles();
  const faDialog = useDialog();

  const accountId = localStorage.accountId;
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [suggestionClass, setSuggestionClass] = useState(null);
  const [suggestionClassList, setSuggestionClassList] = useState([]);

  const [selectedSuggestionClass, setSelectedSuggestionClass] = useState(null);
  const [openEnableDialog, setOpenEnableDialog] = useState(false);
  const [openDisableDialog, setOpenDisableDialog] = useState(false);
  const [isAdditionalFieldsDialogOpen, setIsAdditionalFieldsDialogOpen] =
    useState(false);

  useEffect(() => {
    if (thingClass && accountId && thingClass.id && thingClass.id !== 0) {
      SuggestionClassService.findByThingClass(
        thingClass.id,
        accountId,
        findByThingClassOnSuccess,
        findByThingClassOnError
      );
    }
  }, [thingClass, accountId]);

  const findByThingClassOnSuccess = (data) => {
    setSuggestionClassList(data.list);
  };

  const findByThingClassOnError = (data) => {};

  const addSuggestionClass = () => {
    SuggestionClassService.save(suggestionClass, saveOnSuccess, saveOnError);
  };

  const saveOnSuccess = (data) => {
    SuggestionClassService.findByThingClass(
      thingClass.id,
      accountId,
      findByThingClassOnSuccess,
      findByThingClassOnError
    );
    setIsDialogOpen(false);
  };

  const saveOnError = (data) => {};

  const deleteSuggestionClass = () => {
    faDialog({
      description: t("Öneri tipini silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        SuggestionClassService.delete(
          suggestionClass,
          deleteOnSuccess,
          deleteOnError
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const deleteOnSuccess = (data) => {
    SuggestionClassService.findByThingClass(
      thingClass.id,
      accountId,
      findByThingClassOnSuccess,
      findByThingClassOnError
    );

    setOpenEnableDialog(false);
    setOpenDisableDialog(false);
    setIsDialogOpen(false);
  };

  const deleteOnError = (data) => {};

  const handleChange = (prop, val) => {
    setSuggestionClass({ ...suggestionClass, [prop]: val });
  };

  const renderDialog = () => {
    return (
      <FaDialog
        loading={loading}
        showSaveButton
        faOpen={isDialogOpen}
        title={t("Öneri Tipi Ekle")}
        faOnSubmit={addSuggestionClass}
        faHandleClose={() => {
          setIsDialogOpen(false);
        }}
      >
        <Grid
          item
          container
          spacing={1}
          data-fa-section="SUGGESTION_CLASS_DIALOG"
        >
          <Grid item xs={12}>
            <FaInput
              required
              id="label"
              label={t("Öneri Tipi")}
              value={suggestionClass?.className}
              onChange={(event) => {
                handleChange("className", event.target.value);
              }}
              name="label"
              inputProps={{
                maxLength: 255,
              }}
            />
          </Grid>
        </Grid>
        {suggestionClass?.id ? (
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button onClick={deleteSuggestionClass} color="secondary">
              {t("Sil")}
            </Button>
          </Grid>
        ) : null}
      </FaDialog>
    );
  };

  const renderAdditionalFieldsDialog = () => {
    return (
      isAdditionalFieldsDialogOpen &&
      suggestionClass &&
      suggestionClass.id && (
        <Dialog
          open={isAdditionalFieldsDialogOpen}
          onClose={() => {
            setIsAdditionalFieldsDialogOpen(false);
          }}
          maxWidth="lg"
          fullWidth={true}
        >
          <DialogTitle id="form-dialog-title">
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                setIsAdditionalFieldsDialogOpen(false);
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid item container spacing={1}>
              <Grid item xs={12}>
                <AdditionalFieldsEdit
                  dynamicName="SUGGESTION_CLASS"
                  dynamicId={suggestionClass.id}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )
    );
  };

  const columns = [
    {
      field: "className",
      label: t("Öneri Tipi"),
      align: "center",
      width: "80%",
      valueFormatter: (value, row) => {
        if (row.thingClass.id === thingClass.id) return value;
        else
          return (
            <>
              {value} <i>({row.thingClass.className})</i>
            </>
          );
      },
    },
    {
      field: "id",
      label: t("İşlem"),
      align: "center",
      width: "20%",
      valueFormatter: (value, row) => {
        if (row.thingClass.id === thingClass.id)
          return (
            <>
              <IconButton
                onClick={() => {
                  setSuggestionClass({ ...row });
                  setIsDialogOpen(true);
                }}
                size="small"
                color="primary"
                title={t("Düzenle")}
              >
                <FontAwesomeIcon icon={faEdit} />
              </IconButton>
              <IconButton
                onClick={() => {
                  setSuggestionClass({ ...row });
                  setIsAdditionalFieldsDialogOpen(true);
                }}
                size="small"
                color="primary"
                title={t("Ayarlar")}
                style={{ marginLeft: 10 }}
              >
                <Settings />
              </IconButton>
            </>
          );
        else if (!row.suggestionClassDisable) {
          return (
            <IconButton
              onClick={() => {
                setSelectedSuggestionClass(row);
                setOpenDisableDialog(true);
              }}
              size="small"
              color="primary"
              aria-label={t("GECERLI")}
              title={t("GECERLI")}
            >
              <FontAwesomeIcon icon={faVolumeUp} />
            </IconButton>
          );
        } else if (row.suggestionClassDisable) {
          return (
            <IconButton
              onClick={() => {
                setSelectedSuggestionClass(row);
                setOpenEnableDialog(true);
              }}
              size="small"
              color="primary"
              aria-label={t("GECERSIZ")}
              title={t("GECERSIZ")}
            >
              <FontAwesomeIcon icon={faVolumeMute} />
            </IconButton>
          );
        }
      },
    },
  ];

  const suggestionClassAddButtonClicked = () => {
    setSuggestionClass({
      id: "",
      className: "",
      domain: "",
      parent: null,
      thingClass: thingClass,
      account: { id: accountId },
    });
    setIsDialogOpen(true);
  };

  const disableControlItem = () => {
    SuggestionClassService.disable(
      selectedSuggestionClass.id,
      thingClass.id,
      deleteOnSuccess,
      deleteOnError
    );
  };

  const enableControlItem = () => {
    SuggestionClassService.enable(
      selectedSuggestionClass.suggestionClassDisable.id,
      deleteOnSuccess,
      deleteOnError
    );
  };

  const renderDisableDialog = () => {
    return (
      <Dialog
        open={openDisableDialog}
        onClose={() => {
          setOpenDisableDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Geçersiz Yap?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Seçmiş olduğunuz öneri tipi, mevcut etkileşim noktası tipi ve alt
            etkileşim noktası tiplerinde gösterilmeyecektir. Devam etmek istiyor
            musunuz?.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDisableDialog(false);
            }}
            color="primary"
          >
            İptal
          </Button>
          <Button
            onClick={() => {
              disableControlItem();
            }}
            color="primary"
            autoFocus
          >
            Geçersiz Yap
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderEnableDialog = () => {
    return (
      <Dialog
        open={openEnableDialog}
        onClose={() => {
          setOpenEnableDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Geçerli Yap?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Seçmiş olduğunuz öneri tipi, mevcut etkileşim noktası tipi ve alt
            etkileşim noktası tiplerinde gösterilmeye başlanacaktır. Devam etmek
            istiyor musunuz?.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenEnableDialog(false);
            }}
            color="primary"
          >
            İptal
          </Button>
          <Button
            onClick={() => {
              enableControlItem();
            }}
            color="primary"
            autoFocus
          >
            Geçerli Yap
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {renderDialog()}
      {renderDisableDialog()}
      {renderEnableDialog()}
      {renderAdditionalFieldsDialog()}
      <Card>
        <CardHeader
          action={
            <>
              <ThingClassSuggestionClassHelp value="thingClass.suggestionClass" />
              <IconButton
                aria-label="settings"
                onClick={suggestionClassAddButtonClicked}
                data-fa-button="SUGGESTION_CLASS_BUTTON"
              >
                <Add />
              </IconButton>
            </>
          }
          title={t("SUGGESTION_CLASSES")}
          data-fa-section="SUGGESTION_CLASSES"
        />
      </Card>
      <ThingsDataTable
        data={suggestionClassList}
        columns={columns}
        ref={tableRef}
        showDeleteButton={false}
        showHeaderText={false}
        headerText={t("Öneri Tipleri Listesi")}
        showPaging={false}
        showHeader={false}
      />
    </>
  );
}
