import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function SlaContractStatusSelect(props) {
  const { value, onChange, required } = props;
  const classes = useStyles();
  const { t } = useTranslation("components", { i18n });

  return (
    <FormControl variant="outlined" className={classes.w100} size="small">
      <InputLabel>{t("SLA_CONTRACT_STATUS")}</InputLabel>
      <Select
        // classes={{
        //   outlined: classes.padding10,
        // }}
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        required={required}
        label={t("SLA_CONTRACT_STATUS")}
      >
        <MenuItem value={null}>...</MenuItem>
        <MenuItem value={"PASSIVE"}>{t("STATISTIC_PERIOD_PASSIVE")}</MenuItem>
        <MenuItem value={"ACTIVE"}>{t("STATISTIC_PERIOD_ACTIVE")}</MenuItem>
      </Select>
    </FormControl>
  );
}
