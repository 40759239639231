import { faChartArea, faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuItem } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { FileCopy } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import ActivePassiveDecorator from "components/ui/ActivePassiveDecorator";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import { FaButton } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getDateTimeWFormat, GetFieldByLanguage } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { SurveyService } from "../../services/SurveyService";
import SurveyCount from "./SurveyCount";
import SurveyEdit from "./SurveyEdit";
import SurveyEndMessage from "./SurveyEndMessage";
import SurveyPage from "./SurveyPage";
import SurveySendMessage from "./SurveySendMessage";
import SurveyTarget from "./SurveyTarget";
import SurveyWelcomeMessage from "./SurveyWelcomeMessage";

export default function SurveyDetail() {
  const labelClasses = labelStyles();
  const { t } = useTranslation("survey", { i18n });
  const { id } = useParams();
  const accountId = localStorage.accountId;
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const dialogRef = useRef();
  let history = useHistory();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [loading, setLoading] = useState(false);
  const [survey, setSurvey] = useState({});
  const [anchor, setAnchor] = useState(null);
  const [selectedLang, setSelectedLang] = useState("tr");

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    setLoading(true);
    SurveyService.findById(id, findDetailsOnSuccess, findDetailsOnError);
  };

  const findDetailsOnSuccess = (data) => {
    setSurvey({
      ...data,
    });
    setLoading(false);
  };

  const findDetailsOnError = (error) => {
    showAlert(error, "error");
    setLoading(false);
  };

  const onSave = () => {
    refresh();
  };

  const editDialogRender = () => {
    return (
      <SurveyEdit
        ref={dialogRef}
        onSave={refresh}
        survey={survey}
        currentLang={selectedLang}
      ></SurveyEdit>
    );
  };

  const changeStatusActive = () => {
    if (!survey.registerStartDate || !survey.registerEndDate) {
      showAlert("Başlangıç ve bitiş tarihleri girilmeli", "error");
    } else
      faDialog({
        description: t("Anketi aktifleştirmek istediğinize emin misiniz?"),
        type: "confirm",
      })
        .then((confirmation) => {
          SurveyService.active(id, changeStatusOnSuccess, changeStatusOnError);
        })
        .catch((err) => console.log("error:", err));
  };
  const changeStatusPassive = () => {
    faDialog({
      description: t("Anketi pasifleştirmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        SurveyService.passive(id, changeStatusOnSuccess, changeStatusOnError);
      })
      .catch((err) => console.log("error:", err));
  };
  const changeStatusOnSuccess = (data) => {
    setSurvey({
      ...data,
    });
    setLoading(false);
  };

  const changeStatusOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };
  const navigateToAnalysis = () => {
    const tmpTo = {
      pathname: `/survey/analysis/${id}`,
      breadCrumbKey: `/survey/analysis/:id`,
    };
    history.push(tmpTo);
  };

  const navigateToAnswers = () => {
    const tmpTo = {
      pathname: `/survey/answers/${id}`,
      breadCrumbKey: `/survey/answers/:id`,
    };
    history.push(tmpTo);
  };
  const handleClick = (event) => {
    setAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setAnchor(null);
  };
  const onMenuItemClick = (event, index) => {
    setAnchor(null);
    setSelectedLang(index);
  };

  const createCopy = () => {
    faDialog({
      description: t("Anketin kopyasını oluşturmak istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        SurveyService.copy(id, copyOnSuccess, copyOnError);
      })
      .catch((err) => console.log("error:", err));
  };

  const copyOnSuccess = (data) => {
    showAlert("Anketin kopyası oluşturulmuştur", "success");

    const tmpTo = {
      pathname: `/survey/detail/${data}`,
      breadCrumbKey: `/survey/detail/:id`,
    };
    history.push(tmpTo);
  };

  const copyOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };
  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {editDialogRender()}
        <Grid container spacing={1}>
          <Grid item lg={6}>
            <Grid item container spacing={1}>
              <Grid item lg={12}>
                <Card
                //className={classes.minCardHeight}
                >
                  <CardHeader
                    title={t("SURVEY_INFO")}
                    data-fa-section="SURVEY_INFO"
                    action={
                      <div style={{ marginRight: "10px" }}>
                        <IconButton
                          onClick={createCopy}
                          size="small"
                          title={"Kopyasını oluştur"}
                        >
                          <FileCopy />
                        </IconButton>
                        <IconButton
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={handleClick}
                          size="small"
                          style={{
                            color: "#532363",
                            marginRight: "10px",
                            fontSize: "22px",
                          }}
                          title={selectedLang.toUpperCase()}
                        >
                          <ReactCountryFlag
                            countryCode={
                              selectedLang === "en"
                                ? "US"
                                : selectedLang.toUpperCase()
                            }
                            svg
                          />
                        </IconButton>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchor}
                          keepMounted
                          open={Boolean(anchor)}
                          onClose={handleClose}
                        >
                          {survey.languages &&
                            survey.languages.map((lang) => (
                              <MenuItem
                                onClick={(event) =>
                                  onMenuItemClick(event, lang.toLowerCase())
                                }
                              >
                                <ReactCountryFlag
                                  countryCode={
                                    lang === "EN" ? "US" : lang.toUpperCase()
                                  }
                                  svg
                                />
                              </MenuItem>
                            ))}
                        </Menu>
                        <IconButton
                          disabled={survey.status !== "PASSIVE"}
                          aria-label="settings"
                          style={{ marginRight: "5px" }}
                          onClick={() => {
                            dialogRef.current.openDialog();
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            navigateToAnalysis();
                          }}
                          size="small"
                          style={{
                            color: "#532363",
                            marginRight: "10px",
                            fontSize: "22px",
                          }}
                          title={t("Analiz")}
                        >
                          <FontAwesomeIcon icon={faChartArea} />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            navigateToAnswers();
                          }}
                          size="small"
                          style={{ color: "#532363", fontSize: "20px" }}
                          title={t("Anket Cevapları")}
                        >
                          <FontAwesomeIcon icon={faList} />
                        </IconButton>
                      </div>
                    }
                  />
                  <CardContent>
                    <Grid item container spacing={1}>
                      <Grid item xs={12}>
                        <Grid item container spacing={1}>
                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("NAME")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {/* {survey.multiLangName?.[selectedLang]} */}
                              {GetFieldByLanguage(
                                survey.multiLangName,
                                selectedLang
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("DESCRIPTION")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {GetFieldByLanguage(
                                survey.multiLangDescription,
                                selectedLang
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("USER_VERIFICATION_TYPE")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {t(survey.userVerificationType)}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("REGISTER_START_DATE")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {getDateTimeWFormat(survey.registerStartDate)}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("REGISTER_END_DATE")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {getDateTimeWFormat(survey.registerEndDate)}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("FINGERPRINT")}
                            </Typography>
                            {survey?.fingerPrint ? "Evet" : "Hayır"}
                          </Grid>
                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("LANGUAGES")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {survey?.languages &&
                                survey?.languages.length > 0 &&
                                survey?.languages.map(
                                  (item, index) => t(item) + " "
                                )}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            className={labelClasses.LabelLastRow}
                          >
                            <Typography className={labelClasses.LabelHeader}>
                              {t("STATUS")}
                            </Typography>
                            <Typography
                              component={"span"}
                              variant={"body2"}
                              className={labelClasses.LabelValue}
                            >
                              <ActivePassiveDecorator value={survey.status} />
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            className={labelClasses.LabelLastRow}
                          >
                            {survey.status === "PASSIVE" && (
                              <FaButton
                                style={{ width: "20%" }}
                                variant="contained"
                                size="small"
                                faClick={() => {
                                  changeStatusActive();
                                }}
                                data-fa-button="ACTIVATE"
                              >
                                {t("ACTIVATE")}
                              </FaButton>
                            )}
                            {survey.status === "ACTIVE" && (
                              <FaButton
                                style={{ width: "20%" }}
                                variant="contained"
                                size="small"
                                faClick={() => {
                                  changeStatusPassive();
                                }}
                                data-fa-button="PASSIVATE"
                              >
                                {t("PASSIVATE")}
                              </FaButton>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {survey && survey.id > 0 && (
                <SurveySendMessage
                  survey={survey}
                  onSave={refresh}
                ></SurveySendMessage>
              )}
            </Grid>
          </Grid>

          <Grid item lg={6}>
            <Grid item container spacing={1}>
              <Grid item xs={12}>
                {survey && survey.id > 0 && (
                  <SurveyCount surveyId={survey.id} />
                )}
              </Grid>

              <Grid item xs={12}>
                {survey && survey.id > 0 && (
                  <SurveyTarget survey={survey}></SurveyTarget>
                )}
              </Grid>
              <Grid item xs={12}>
                {survey && survey.id > 0 && (
                  <SurveyWelcomeMessage
                    survey={survey}
                    onSave={refresh}
                  ></SurveyWelcomeMessage>
                )}
              </Grid>
              <Grid item xs={12}>
                {survey && survey.id > 0 && (
                  <SurveyEndMessage
                    survey={survey}
                    onSave={refresh}
                  ></SurveyEndMessage>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={12}>
            {survey && survey.id > 0 && (
              <SurveyPage
                survey={survey}
                currentLang={selectedLang}
              ></SurveyPage>
            )}
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
