import AccountInteractionPage from "features/accountDetails/AccountInteractionPage";
import ThingDetail from "features/thingDetail";
import Things from "features/things";
import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { ThingService } from "services/thing/thingService";
import i18n from "../../i18n";
import { useStyles } from "./style";
import ThingsSteps from "./ThingsSteps";
import TutorialElement from "./TutorialElement";

export default function QuickStartHighPackageTutorial() {
  const { code } = useParams();
  const classes = useStyles();

  const stepsRef = useRef();
  const thingDetailRef = useRef(null);

  let history = useHistory();

  const { t } = useTranslation("tutorialQuickStart", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);
  const [thingId, setThingId] = useState(null);

  useEffect(() => {
    ThingService.getSystemThing(
      localStorage.accountId,
      (data) => {
        setThingId(data?.id);
      },
      (error) => {}
    );
  }, []);

  useEffect(() => {
    if (thingId)
      setSteps([
        {
          element: "#menuThings",
          intro: (
            <TutorialElement icon="click">
              <b>{t("Etkileşim Noktalarım")}</b> {t(" menüsüne tıklayın")}.
            </TutorialElement>
          ),
        },
        {
          element: "#menuThingList",
          intro: (
            <TutorialElement icon="click">
              <b>{t("Etkileşim Noktalarını ")}</b>{" "}
              {t("görüntülemek için Listeye tıklayın.")}
            </TutorialElement>
          ),
          onBeforeChange: (index) => {
            var element = document.querySelector("#menuThings");
            if (element?.childElementCount > 1 == false)
              $("#menuThings")[0].firstElementChild.click();
            stepsRef.current.updateStepElement(index);
          },
        },
        {
          intro: (
            <TutorialElement icon="info">
              {t("Etkileşim noktalarınız burada görüntülenmektedir.")}
            </TutorialElement>
          ),
          comp: "Things",
          onBeforeChange: (index) => {
            stepsRef.current.updateStepElement(index);
          },
        },
        {
          element: "div.MuiTableContainer-root > table > tbody > tr",
          intro: (
            <TutorialElement icon="click">
              {"Müşterilerinizle etkileşim kurmak istediğiniz"}{" "}
              <b>{t("Nokta")}</b> {"'ya tıklayın. "}
            </TutorialElement>
          ),
          comp: "Things",
          onBeforeChange: (index) => {
            stepsRef.current.updateStepElement(index);
          },
        },
        {
          element: null,
          intro: (
            <TutorialElement
              icon="info"
              text={t(
                "Etkileşim noktanız hakkında bilgiler burada yer almaktadır."
              )}
            />
          ),
          comp: "ThingDetail",
          onBeforeChange: (index) => {
            console.log(thingId);
            stepsRef.current.updateStepElement(index);
          },
        },
        {
          element: "[data-fa-input='QR_MENU']",
          intro: (
            <TutorialElement
              icon="info"
              text={t(
                "İşletme, Ürün ya da Hizmet Noktanıza özel Kare Kodunuzu indirin"
              )}
            />
          ),
          comp: "ThingDetail",
          onBeforeChange: (index) => {
            stepsRef.current.updateStepElement(index);
          },
        },
        {
          element:
            "#qr-menu > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded",
          intro: (
            <TutorialElement icon="click">
              <div>
                <p>{t("Kare Kod’a tıklayın.")}</p>
                <p>
                  <b>{t("QR İndir")}</b> {t("veya")} <b>{t("QR Etiket Bas")}</b>
                  {t("’a tıklayın")}
                </p>
              </div>
            </TutorialElement>
          ),
          comp: "ThingDetail",
          highlightClass: classes.highlightClassCustom,
          onBeforeChange: (index) => {
            thingDetailRef.current.openPhotoMenu();
            stepsRef.current.updateStepElement(index);
          },
        },
        {
          intro: (
            <TutorialElement icon="click">
              <div>
                <p>
                  Kare Kod’u Müşterilerinizle etkileşim kurmak istediğiniz
                  nokta/noktalara yerleştirin.
                </p>
              </div>
            </TutorialElement>
          ),
          comp: "ThingDetail",
          onBeforeChange: (index) => {
            stepsRef.current.updateStepElement(index);
          },
        },
        {
          intro: (
            <TutorialElement header="İPUCU" icon="click">
              <div style={{ textAlign: "center" }}>
                <p>
                  {t(
                    "Müşterilerinizle etkileşime geçmek için birden fazla ürün ya da hizmet noktanıza"
                  )}
                  <b>{t(" aynı QR kodu")}</b>
                  {t(" yerleştirebilirsiniz.")}
                  <br />
                  {t("          Ya da")}
                  <br />
                  {t(" Her bir ürün ya da hizmet noktanıza ")}
                  <b>
                    {t(
                      "sorun, görüş ve öneri toplamak, içerik paylaşmak, anket, kampanya ve etkinlik duyurmak "
                    )}
                  </b>
                  için <b>{t("farklı QR kodu ")}</b>
                  {t("yerleştirebilirsiniz.")}
                </p>
              </div>
            </TutorialElement>
          ),
          comp: "ThingDetail",
          onBeforeChange: (index) => {
            stepsRef.current.updateStepElement(index);
          },
        },
        {
          element: "[data-fa-section='THING_INTERACTION_PAGE_OPTIONS']",
          intro: (
            <TutorialElement
              icon="done"
              text={t(
                "Etkileşime hazırsınız. " +
                  "Artık QR kodu ile işletme, ürün ya da hizmet noktanızla ilgili sorun, görüş ve öneri toplayabilir," +
                  " içerik paylaşabilir, anket, kampanya ve etkinlikleri duyurabilirsiniz."
              )}
            />
          ),
          comp: "AccountInteraction",
          onBeforeChange: (index) => {
            stepsRef.current.updateStepElement(index);
          },
        },
      ]);
  }, [thingId]);

  return (
    <div>
      {steps &&
        steps.length > stepIndex &&
        steps[stepIndex].comp === "Things" && <Things />}
      {steps &&
        steps.length > stepIndex &&
        steps[stepIndex].comp === "ThingDetail" &&
        thingId && <ThingDetail ref={thingDetailRef} thingId={thingId} />}
      {steps &&
        steps.length > stepIndex &&
        steps[stepIndex].comp === "AccountInteraction" && (
          <AccountInteractionPage />
        )}
      <ThingsSteps
        code={code}
        steps={steps}
        ref={stepsRef}
        onStepChange={setStepIndex}
        afterComplete={() => {
          window.history.back();
          $("#menuThings")[0].firstElementChild.click();
        }}
      />
    </div>
  );
}
