import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getDateWFormat } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { LegalTextService } from "services/LegalTextService";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import LegalTextEdit from "./LegalTextEdit";

export default function LegalTextDetail() {
  const tableRef = useRef();
  const labelClasses = labelStyles();
  const { t } = useTranslation("legalText", { i18n });
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    if (!loading) {
      setLoading(true);
      LegalTextService.findById(
        id,
        (data) => {
          setLegalText(data);
          setLoading(false);
        },
        (error) => {
          showAlert(error.message, "error");
          setLoading(false);
        }
      );
    }
  };

  const [legalText, setLegalText] = useState(null);

  const editButtonClicked = () => {
    setIsEditFormOpen(true);
  };

  const legalTextOnChange = () => {
    setIsEditFormOpen(false);
    refresh();
  };

  const legalTextOnDelete = () => {
    history.goBack();
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <div>
          <LegalTextEdit
            onChange={legalTextOnChange}
            onDelete={legalTextOnDelete}
            onClose={() => {
              setIsEditFormOpen(false);
            }}
            value={legalText}
            open={isEditFormOpen}
          />
          <Grid container spacing={1}>
            <Grid item lg={12}>
              <Grid container spacing={1}>
                <Grid item lg={12}>
                  <Card>
                    <CardHeader
                      action={
                        <IconButton
                          aria-label="settings"
                          onClick={editButtonClicked}
                        >
                          <Edit />
                        </IconButton>
                      }
                      title={t("LEGAL_TEXT_DETAIL")}
                    />
                    <CardContent>
                      <Grid item container spacing={1}>
                        <Grid item xs={12}>
                          <Grid item container spacing={1}>
                            <Grid
                              item
                              xs={12}
                              className={labelClasses.LabelRow}
                            >
                              <Typography className={labelClasses.LabelHeader}>
                                {t("NAME")}
                              </Typography>
                              <Typography className={labelClasses.LabelValue}>
                                {legalText?.name}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={labelClasses.LabelRow}
                            >
                              <Typography className={labelClasses.LabelHeader}>
                                {t("DESCRIPTION")}
                              </Typography>
                              <Typography className={labelClasses.LabelValue}>
                                {legalText?.description}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={labelClasses.LabelRow}
                            >
                              <Typography className={labelClasses.LabelHeader}>
                                {t("TEXT_TYPE")}
                              </Typography>
                              <Typography className={labelClasses.LabelValue}>
                                {t(legalText?.type)}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={labelClasses.LabelRow}
                            >
                              <Typography className={labelClasses.LabelHeader}>
                                {t("START_DATE")}
                              </Typography>
                              <Typography className={labelClasses.LabelValue}>
                                {getDateWFormat(legalText?.startDate)}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={labelClasses.LabelRow}
                            >
                              <Typography className={labelClasses.LabelHeader}>
                                {t("END_DATE")}
                              </Typography>
                              <Typography className={labelClasses.LabelValue}>
                                {getDateWFormat(legalText?.endDate)}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={labelClasses.LabelLastRow}
                            >
                              <Typography className={labelClasses.LabelHeader}>
                                {t("CONTENT")}
                              </Typography>
                              <Typography className={labelClasses.LabelValue}>
                                {legalText?.content}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Suspense>
    </>
  );
}
