import React, { useEffect, useState } from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { MapInteractionCSS } from "react-map-interaction";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BASE_URL } from "serviceWorker";
import { CampaignService } from "services/CampaignService";
import { CAMPAIGN } from "services/faApi";
import { useStyles } from "./GenericCarouselStyle";

export default function GenericCarousel(props) {
  const { open, onClose, label, objectIdList, selectedItem } = props;

  const classes = useStyles();
  const [images, setImages] = useState([]);
  const [loadingImages, setLoadingImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const accountId = localStorage.accountId;

  useEffect(() => {}, [objectIdList]);

  const customRenderItem = (item, props) => {
    console.log({ item });
    if (props.isSelected === true) {
      if (
        !images[item.key] &&
        loadingImages.indexOf(item.key) < 0 &&
        loading === false
      ) {
        setLoading(true);
        setLoadingImages((prev) => {
          return [...prev, item.key];
        });
        CampaignService.downloadCustomerImage(
          accountId,
          objectIdList[item.key],
          (data) => {
            onDownloadImageSuccess(data, item.key);
          },
          (error) => {
            setLoading(false);
          }
        );
      }
    }

    return <item.type {...item.props} {...props} />;
  };

  const onDownloadImageSuccess = (data, index) => {
    setLoading(false);
    setImages((prev) => {
      let imagesTemp = [...prev];
      imagesTemp[index] = data?.content;

      return imagesTemp;
    });
  };

  const createCarouselItemImage = (objectId, index) => (
    <div key={index} className={classes.image}>
      {images[index] && (
        <MapInteractionCSS showControls={true}>
          <img
            alt=""
            src={`data:image/png;base64, ${images[index]}`}
            style={{ maxHeight: "600px" }}
          />
        </MapInteractionCSS>
      )}
    </div>
  );

  const baseChildren = (
    <div>{objectIdList && objectIdList.map(createCarouselItemImage)}</div>
  );

  const customRenderThumb = (children) =>
    children.map((item, index) => {
      return (
        <img
          onc
          alt="belge"
          src={
            BASE_URL +
            CAMPAIGN.getCustomerImage +
            "?accountId=" +
            accountId +
            "&objectId=" +
            encodeURI(objectIdList[index])
          }
          style={{
            width: "48px",
            height: "48px",
            marginRight: "10px",
          }}
        />
      );
    });

  return (
    <FaDialog
      title={label}
      faOpen={open}
      //faOnSubmit={saveLegalText}
      //loading={loading}
      //showSaveButton
      faHandleClose={(data) => {
        onClose();
      }}
      faMaxWidth="lg"
    >
      <Carousel
        height="500px"
        showArrows={true}
        renderItem={customRenderItem}
        renderThumbs={customRenderThumb}
        selectedItem={selectedItem}
      >
        {baseChildren.props.children}
      </Carousel>
    </FaDialog>
  );
}
