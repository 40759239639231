import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  icMekanKrokiClass: {
    minWidth: "730px !important",
    minHeight: "500px !important",
  },
  dateSettingsClass: {
    minWidth: "630px !important",
    minHeight: "200px !important",
  },
  issueCloseClass: {
    minWidth: "650px !important",
    minHeight: "330px !important",
  },
  issueSolutionClass: {
    minWidth: "830px !important",
    minHeight: "350px !important",
  },
  gorevlendirButonuClass: {
    minWidth: "640px !important",
    minHeight: "600px !important",
  },
  gorevOlusturDialogClass: {
    minWidth: "740px !important",
    minHeight: "400px !important",
  },
  accPrefHelpClass: {
    minWidth: "700px !important",
  },
  customerInteractionMap: {
    minWidth: "730px !important",
    minHeight: "400px !important",
  },
  hesapOlustur: {
    minWidth: "900px !important",
  },
}));
