import { SvgIcon } from "@material-ui/core";
import { default as React } from "react";

export default function ReportIcon(props) {
  return (
    <SvgIcon viewBox="0 0 200 200" {...props}>
      <path
        d="M17.147,112.811c8.467,13.45,39.915,12.366,55.05,10.989c-3.782,22.989-1.217,38.617,7.633,46.464
	c9.414,8.346,22.642,4.653,24.041,4.224c2.015-0.498,3.341-2.427,3.084-4.486c-4.793-38.679,15.371-55.966,23.357-61.198
	c0.11,0.121,0.222,0.238,0.334,0.35c2.301,2.301,5.352,3.569,8.591,3.569h33.573c3.239,0,6.29-1.268,8.591-3.569
	c2.301-2.301,3.568-5.352,3.568-8.591V37.11c0-3.24-1.267-6.291-3.568-8.591c-2.302-2.302-5.353-3.569-8.591-3.569h-33.573
	c-3.238,0-6.289,1.268-8.591,3.569c-0.592,0.592-1.122,1.248-1.584,1.961l-9.672-5.317c-0.657-0.414-1.415-0.634-2.191-0.634H53.388
	c-1.08-0.133-2.125-0.199-3.137-0.199c-4.426,0-8.193,1.271-11.23,3.797c-6.092,5.064-7.554,13.867-7.9,18.173
	c-8.868,5.425-8.137,15.869-7.081,21.146c-3.972,2.566-6.434,5.779-7.324,9.562c-1.307,5.552,1.103,10.918,2.99,14.041
	C14.497,99.916,13.636,107.234,17.147,112.811z M32.648,68.077c-0.403-1.214-3.682-11.825,3.758-15.333l0.314-0.135
	c1.616-0.691,2.604-2.316,2.478-4.062c0.015-1.244,0.293-9.702,4.978-13.765c2.057-1.783,4.757-2.509,8.039-2.146l0.436,0.06
	c0.21,0.03,0.419,0.042,0.604,0.039h62.901l10.923,6.006v61.823c0,0.198,0.006,0.399,0.019,0.608
	c-8.748,5.372-32.419,23.991-28.68,66.076c-3.606,0.427-9.135,0.158-13.379-4.146c-6.618-6.714-7.997-21.292-4-42.193l0.191-0.968
	c0.438-2.219-1.011-4.38-3.229-4.819c-0.53-0.104-1.067-0.105-1.594,0c-2.173,0.277-12.835,1.537-24.206,1.206
	c-14.519-0.425-23.844-3.281-26.967-8.259c-2.173-3.464-1.387-8.24,2.338-14.205l0.446-0.685c1.009-1.548,0.858-3.574-0.36-4.954
	c-0.508-0.671-3.647-5.039-2.677-9.163c0.536-2.276,2.272-4.188,5.164-5.69l0.441-0.215C32.504,72.223,33.382,70.015,32.648,68.077z
	 M135.282,100.563V37.11c0-0.047,0.003-0.093,0.01-0.186c0.017-0.122,0.028-0.243,0.034-0.365c0.12-0.841,0.517-1.635,1.122-2.239
	c0.75-0.751,1.741-1.165,2.79-1.165h33.573c1.048,0,2.039,0.414,2.791,1.166c0.751,0.75,1.165,1.74,1.165,2.789v63.453
	c0,1.048-0.414,2.039-1.166,2.79c-0.75,0.751-1.741,1.165-2.79,1.165h-33.573c-1.048,0-2.039-0.414-2.79-1.166
	C135.695,102.602,135.282,101.611,135.282,100.563z"
      />
    </SvgIcon>
  );
}
