import React from "react";
import { FaAutoComplete } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { CAMPAIGN } from "../../services/faApi";

export default function CampaignAwardAutoComplete(props) {
  const { value, campaignId, onChange, required } = props;

  const { t } = useTranslation("components", { i18n });

  return (
    <FaAutoComplete
      faType="async"
      value={value}
      getOptionSelected={(option, value) => {
        return option.id === value.id;
      }}
      sort="id"
      faFilterProp="id"
      getOptionLabel={(option) => (option.name ? option.name : "")}
      requestUrl={CAMPAIGN.findAwards + "?campaignId=" + campaignId}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      label={t("Ödül")}
      required={required}
    />
  );
}
