import { Card, CardContent, Grid, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import SurveyQuestionLibraryButtonGroup from "components/buttonGroup/SurveyQuestionLibraryButtonGroup";
import CampaignSurveyQuestionRender from "features/campaign/CampaignSurveyQuestionRender";
import SurveyQuestionEdit from "features/survey/SurveyQuestionEdit";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { FaButton } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { SurveyService } from "services/SurveyService";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";

export default function SurveyQuestionLibrary() {
  const tableRef = useRef();
  const { t } = useTranslation(["survey", "components"], { i18n });
  const dispatch = useDispatch();
  const labelClasses = labelStyles();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const dialogRef = useRef();
  const accountId = localStorage.accountId;

  const [loading, setLoading] = useState(false);
  const [buttonGroupValue, setButtonGroupValue] = useState("ALL");
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const newSearchForm = () => {
    let result = {
      surveyPageId: null,
      accountId: accountId,
      resultType: buttonGroupValue,
      paging: {
        page: 0,
        size: 10,
        isAsc: false,
        sort: "createDate",
        accountId: accountId,
      },
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  useEffect(() => {
    setSearchForm({ ...searchForm, resultType: buttonGroupValue });
  }, [buttonGroupValue]);

  useEffect(() => {
    refresh();
  }, [searchForm]);

  const callback = (data) => {
    setQuestions(data.list);
  };

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const refresh = () => {
    SurveyService.libraryQuestions(searchForm, callback, () => {});
  };

  useEffect(() => {
    if (editDialogOpen && selectedQuestion) dialogRef.current.openDialog();
    else if (!editDialogOpen) setSelectedQuestion(null);
  }, [editDialogOpen]);

  const addQuestion = () => {
    return (
      <SurveyQuestionEdit
        question={selectedQuestion}
        ref={dialogRef}
        onSave={() => {
          refresh();
        }}
        library={true}
        currentLang={"tr"}
      ></SurveyQuestionEdit>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {addQuestion()}
        <Grid item container spacing={1} style={{ marginBottom: 5 }}>
          <Grid item lg={3} xs={6}>
            <Grid item xs={12}>
              <SurveyQuestionLibraryButtonGroup
                value={buttonGroupValue}
                onChange={(data) => {
                  setButtonGroupValue(data);
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={1}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                dialogRef.current.openDialog();
              }}
              startIcon={<AddIcon />}
              data-fa-button="ADD"
            >
              {t("ADD")}
            </FaButton>
          </Grid>
        </Grid>
        <Card
          style={{
            minWidth: "100%",
            minHeight: 200,
            marginTop: 10,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <CardContent>
            {questions &&
              questions.length > 0 &&
              questions.map((item, index) => (
                <Grid
                  item
                  container
                  alignItems="center"
                  spacing={1}
                  key={index}
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid item xs={10}>
                    <CampaignSurveyQuestionRender
                      key={index}
                      question={item}
                      //onChangeAnswer={onChangeAnswer}
                      answer={{ surveyQuestion: { id: item.id } }}
                      editable={false}
                      readOnly={true}
                      accountId={accountId}
                      currentLang={"tr"}
                    />
                    <hr />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      aria-label="settings"
                      disabled={!item?.account?.id}
                      onClick={() => {
                        setSelectedQuestion(item);
                        setEditDialogOpen(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
          </CardContent>
        </Card>
      </Suspense>
    </>
  );
}
