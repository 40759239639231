import { Typography } from "@material-ui/core";
import { default as React, forwardRef } from "react";
import ReactPuzzleConfirm from "react-puzzle-confirm";

const StepCaptchaVerification = forwardRef((props, ref) => {
  const { handleBack, handleNextCallback, submit } = props;

  return (
    <>
      <div>
        <ReactPuzzleConfirm
          onClose={() => {
            handleBack();
          }}
          onSuccess={() => {
            handleNextCallback(true);
            submit();
          }}
          onFail={() => console.log("failed")}
          title="Lütfen bulmaca parçasını dikkatlice yerleştirin"
          sliderTitle="Bulmacayı tamamlamak için kaydırın"
          failMessage="Başarısız"
          successMessage="Başarılı"
          closeButtonLabel="Geri"
          refrefButtonLabel="Yenile"
          disableRefreshIdleState={true}
        />
      </div>
      <div style={{ textAlign: "center", paddingTop: 10 }}>
        <Typography style={{ fontSize: "12px" }}>
          Bulmacayı tamamlamak için kaydırın
        </Typography>
      </div>
      <div style={{ textAlign: "center" }}>
        <img
          alt="Hint"
          height="90px"
          src={process.env.PUBLIC_URL + "/assets/hint/captcha_hint.gif"}
        ></img>
      </div>
    </>
  );
});

export default StepCaptchaVerification;
