import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { default as React } from "react";
import { useTranslation } from "react-i18next";
import { CampaignService } from "services/CampaignService";
import i18n from "../../i18n";

export default function CampaignCustomerButtons(props) {
  const { campaignCustomerId, onChange, status, autoSave } = props;

  const { t } = useTranslation("campaign", { i18n });

  const statusList = [
    "REGISTERED",
    "WAITING_FOR_INFORMATION",
    "VERIFIED",
    "REJECT",
  ];

  const changeStatus = (status) => {
    if (autoSave) {
      CampaignService.changeCustomerStatusSimple(
        campaignCustomerId,
        status,
        (data) => {
          onChange(status);
        },
        (error) => {}
      );
    } else {
      onChange(status);
    }
  };

  return (
    <Card>
      <CardHeader title={t("Başvuru Durumu")} />
      <CardContent>
        <Grid item container spacing={1}>
          <Grid item xs={12}>
            <ButtonGroup
              variant="contained"
              color="primary"
              aria-label="contained primary button group"
            >
              {statusList.map((item) => (
                <Button
                  color={status === item ? "secondary" : "primary"}
                  onClick={() => {
                    changeStatus(item);
                  }}
                >
                  {t(item)}
                </Button>
              ))}
            </ButtonGroup>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

CampaignCustomerButtons.propTypes = {
  campaignCustomerId: PropTypes.any,
  autoSave: PropTypes.bool,
};

CampaignCustomerButtons.defaultProps = {
  autoSave: true,
};
