import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import LoadingBar from "components/loadingBar";
import i18n from "i18n";
import PropTypes from "prop-types";
import { default as React, Suspense } from "react";
import { FaBarChart } from "react-base-fa/dist/fa/facharts";
import { useTranslation } from "react-i18next";
import { CHART_COLORS } from "services/utils/ChartConstants";
import { labelStyles } from "styles/ThingsLabelStyle";
import AnswerDistribution from "./AnswerDistribution";

export default function SurveyAnalysisBool(props) {
  const { question, questionAnalysis } = props;

  const labelClasses = labelStyles();
  const { t } = useTranslation("survey", { i18n });
  const chartKeys = ["value"];

  const chartData = questionAnalysis.detailList.map((item) => {
    return {
      label: t(item.dataString),
      value: item.count,
    };
  });

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid container spacing={1}>
          <Grid item lg={12}>
            <Grid item container spacing={1}>
              <Grid item lg={12}>
                <Card>
                  <CardHeader
                    title={
                      question.orderNo +
                      ". " +
                      (question?.multiLangName !== null
                        ? question?.multiLangName["tr"]
                        : "")
                    }
                  />
                  <CardContent>
                    <Grid item container spacing={1}>
                      <Grid item xs={12}>
                        <AnswerDistribution
                          questionAnalysis={questionAnalysis}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FaBarChart
                          data={chartData}
                          keys={chartKeys}
                          indexBy={"label"}
                          legends={[]}
                          //height="300px"
                          bAxisLabel={t("")}
                          lAxisLabel={t("")}
                          colors={CHART_COLORS}
                          axisLeft={{
                            format: (e) => Math.floor(e) === e && e,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Seçenek</TableCell>
                                <TableCell>%</TableCell>
                                <TableCell>#</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {questionAnalysis.detailList.map(
                                (item, index) => (
                                  <TableRow key={index}>
                                    <TableCell
                                      className={labelClasses.LabelValue}
                                    >
                                      {item.dataNumeric ?? "Boş"}
                                    </TableCell>
                                    <TableCell
                                      className={labelClasses.LabelValue}
                                    >
                                      {"%" + item.percentage}
                                    </TableCell>
                                    <TableCell
                                      className={labelClasses.LabelValue}
                                    >
                                      {item.count}
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                            <TableFooter>
                              <TableRow>
                                <TableCell colSpan={2}>Toplam</TableCell>
                                <TableCell>
                                  {questionAnalysis.detailList.reduce(
                                    (accumulator, item) => {
                                      return accumulator + item.count;
                                    },
                                    0
                                  )}
                                </TableCell>
                              </TableRow>
                            </TableFooter>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}

SurveyAnalysisBool.propTypes = {
  question: PropTypes.any,
  questionAnalysis: PropTypes.any,
};
