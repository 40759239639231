import { IconButton } from "@material-ui/core";
import ThingsHelpIcon from "components/icons/ThingsHelpIcon";
import ThingsSteps from "features/tutorial/ThingsSteps";
import TutorialElement from "features/tutorial/TutorialElement";
import $ from "jquery";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function InteractionPageOptionsHelp(props) {
  const { value } = props;
  const { t } = useTranslation("help", { i18n });

  const classes = useStyles();
  const stepsRef = useRef();
  const [open, setOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            <b>Etkileşim Sayfasında</b> yer almasını istediğiniz{" "}
            <b>Etkileşim Tiplerinin</b> seçiminin yapıldığı ve yönetildiği
            alandır.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='THING_INTERACTION_PAGE_OPTIONS_EDIT']",
        intro: (
          <TutorialElement icon="click">
            <b>Etkileşim Tipi</b> seçmek için tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-section='THING_INTERACTION_PAGE_OPTIONS_SELECTION']",
        intro: (
          <TutorialElement icon="click">
            Etkileşim Sayfasında yer almasını istediğiniz <b>Etkileşim Tipi</b>{" "}
            düğmelerine tıklayın<b>*</b> ve <b>Kaydet</b>’e basın.
            <br /> <br />
            <b>*</b>: <b>Anket, Kampanya ve Kullanıcı İçeriği</b> seçenekleri
            Standart ve üstü paketlerde.
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          $(
            "[data-fa-button='THING_INTERACTION_PAGE_OPTIONS_EDIT']"
          )[0].click();
          stepsRef.current.updateStepElement(index);
        },
      },
    ]);
  }, []);

  return (
    <>
      {open && (
        <ThingsSteps
          code={value}
          steps={steps}
          ref={stepsRef}
          onStepChange={setStepIndex}
          afterComplete={() => {
            setOpen(true);
            var el = $("[aria-label='close']");
            if (el != null && el[0] != null) el[0].click();
          }}
        />
      )}
      <IconButton
        aria-label="help"
        onClick={() => setOpen(true)}
        title={t("HELP")}
      >
        <ThingsHelpIcon />
      </IconButton>
    </>
  );
}

InteractionPageOptionsHelp.propTypes = {
  value: PropTypes.string,
};
