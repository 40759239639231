import { Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Adsense from "components/adsense/Adsense";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaButton, FaDialog, FaInput, FaSearch } from "react-base-fa/dist/fa";
import FaMap from "react-base-fa/dist/fa/faMap";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getRole } from "react-base-fa/dist/services/sessionSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROLES } from "services/utils/Roles";
import { checkRole } from "services/utils/SecurityUtils";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { LOCATION } from "../../services/faApi";
import { LocationService } from "../../services/LocationService";
import { useStyles } from "./style";

export default function Location() {
  const tableRef = useRef();
  const classes = useStyles();
  const { t } = useTranslation("location", { i18n });
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const accountId = localStorage.accountId;

  const [loading, setLoading] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const role = useSelector(getRole);
  const isFree = checkRole([ROLES.FREE], role);

  const columns = [
    {
      field: "name",
      label: t("NAME"),
      align: "center",
      width: "50%",
    },
    {
      field: "description",
      label: t("DESCRIPTION"),
      align: "center",
      width: "50%",
    },
  ];

  const newLocation = () => {
    return {
      id: "",
      name: "",
      description: "",
      gpsLocation: {
        lat: 39.862645729977174,
        lng: 32.738810051232576,
      },
    };
  };

  const [location, setLocation] = useState(newLocation());

  const handleChange = (prop) => (event) => {
    setLocation({ ...location, [prop]: event.target.value });
  };

  const saveSuccess = (data) => {
    showAlert(!location.id ? t("SAVED") : t("EDITED"), "success");
    setIsEditFormOpen(false);
    setLocation(newLocation());

    setLoading(false);

    const tmpTo = {
      pathname: `/location/detail/${data.id}`,
      breadCrumbKey: `/location/detail/:id`,
    };
    history.push(tmpTo);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const saveLocation = () => {
    setLoading(true);
    LocationService.save(
      {
        ...location,
        account: { id: accountId },
        gpsLocation: JSON.stringify(location.gpsLocation),
      },
      saveSuccess,
      saveError
    );
  };

  const newSearchForm = () => {
    let result = {
      name: null,
      accountId: accountId,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    // let parsedData = JSON.parse(row.gpsLocation);
    // setLocation({
    //   ...row,
    //   gpsLocation: {
    //     lat: parsedData.lat,
    //     lng: parsedData.lng,
    //   }
    // });

    // setIsEditFormOpen(true);

    const tmpTo = {
      pathname: `/location/detail/${row.id}`,
      breadCrumbKey: `/location/detail/:id`,
    };
    history.push(tmpTo);
  };

  const onChangeKonum = (event) => {
    setLocation({
      ...location,
      gpsLocation: {
        lng: event.lngLat[0],
        lat: event.lngLat[1],
      },
    });
  };

  const renderEditWindow = () => {
    return (
      <>
        <FaDialog
          title={"Mekan Tanımla"}
          faOpen={isEditFormOpen}
          faOnSubmit={saveLocation}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            setIsEditFormOpen(data);
            setLocation(newLocation());
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <FaInput
                required
                maxLength={100}
                label={t("NAME")}
                onChange={handleChange("name")}
                value={location.name || ""}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                maxLength={100}
                label={t("DESCRIPTION")}
                onChange={handleChange("description")}
                value={location.description || ""}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid item container spacing={1} className={classes.map}>
                <FaMap
                  className={classes.map}
                  viewport={{
                    latitude: location.gpsLocation.lat,
                    longitude: location.gpsLocation.lng,
                    zoom: 16,
                    bearing: 0,
                    pitch: 0,
                  }}
                  marker={{
                    latitude: location.gpsLocation.lat,
                    longitude: location.gpsLocation.lng,
                    onMarkerDragEnd: onChangeKonum,
                  }}
                ></FaMap>
              </Grid>
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  const renderElements = () => {
    return (
      <>
        <ThingsDataTable
          searchUrl={LOCATION.search + "?accountId=" + localStorage.accountId}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={1} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                setIsEditFormOpen(true);
              }}
              startIcon={<AddIcon />}
              data-fa-button="ADD"
            >
              {t("ADD")}
            </FaButton>
          </Grid>
          <Grid item xs={5}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <FaInput
                label={t("NAME")}
                value={searchForm.name}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "name",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("NAME")}
                value={searchForm.name}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "name",
                    event.target.value
                  );
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {renderEditWindow()}

        {isFree && (
          <Grid container spacing={1}>
            <Grid item md={10}>
              {renderElements()}
            </Grid>
            <Grid item md={2}>
              <Adsense value="RklmSag" />
            </Grid>
          </Grid>
        )}

        {!isFree && renderElements()}
      </Suspense>
    </>
  );
}
