import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import PropTypes from "prop-types";
import React from "react";

export default function AnnouncementDetail(props) {
  const { announcement, onClose } = props;

  return (
    <>
      <Dialog open={true} aria-labelledby="form-dialog-title" fullScreen>
        <DialogTitle id="form-dialog-title">
          <IconButton
            aria-label="close"
            onClick={() => {
              onClose();
            }}
          >
            <ChevronLeft />
          </IconButton>
          {announcement.subject}
        </DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: announcement.content }} />
        </DialogContent>
      </Dialog>
    </>
  );
}

AnnouncementDetail.propTypes = {
  announcement: PropTypes.any,
  onClose: PropTypes.func,
};
