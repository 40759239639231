import { Button, Card, CardHeader, Grid } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { AssignmentTurnedIn, Check, Close, Send } from "@material-ui/icons";
import ThingsDataTable from "components/thingsDataTable";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import { FaButton, FaDialog } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getDateTimeWFormat, qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CouponService } from "services/CouponService";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { COUPON } from "../../services/faApi";

export default function CouponCustomers(props) {
  const { coupon } = props;

  const { t } = useTranslation("coupon", { i18n });
  const dispatch = useDispatch();
  const tableRef = useRef();
  const faDialog = useDialog();
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [channel, setChannel] = useState(null);

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    refresh();
  }, [coupon]);

  const refresh = () => {
    tableRef.current.refreshTable();
  };

  const defineCoupons = () => {
    faDialog({
      description: t("Kuponları tanımlamak istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        CouponService.createCoupons(coupon?.id, createOnSuccess, createOnError);
      })
      .catch((err) => console.log("error:", err));
  };
  const createOnSuccess = (data) => {
    refresh();
    setLoading(false);
    setIsDialogOpen(false);
  };
  const createOnError = (error) => {
    showAlert(t(error.message), "error");
    setLoading(false);
    setIsDialogOpen(false);
  };

  const sendCoupons = () => {
    setLoading(true);
    CouponService.sendCoupons(
      coupon?.id,
      channel,
      createOnSuccess,
      createOnError
    );
  };
  const columns = [
    {
      field: "name",
      label: t("CUSTOMER_NAME"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        return row.customer?.name;
      },
    },
    {
      field: "surname",
      label: t("SURNAME"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        return row.customer?.surname;
      },
    },
    {
      field: "email",
      label: t("E-MAIL"),
      align: "center",
      width: "15%",
      valueFormatter: (value, row) => {
        return row.customer?.email;
      },
    },
    {
      field: "phone",
      label: t("PHONE"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        return row.customer?.phone;
      },
    },
    {
      field: "sendStatus",
      label: t("SEND_STATUS"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        if (value === "YES") return <Check />;
        return <Close />;
      },
    },
    {
      field: "sendDate",
      label: t("SEND_DATE"),
      align: "center",
      width: "15%",
      valueFormatter: (value) => {
        return getDateTimeWFormat(value);
      },
    },
    {
      field: "usingStatus",
      label: t("USING_STATUS"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        if (value === "YES") return <Check />;
        return <Close />;
      },
    },
    {
      field: "usedDate",
      label: t("USED_DATE"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        return getDateTimeWFormat(value);
      },
    },
  ];
  const newSearchForm = () => {
    let result = {
      couponId: coupon?.id,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const renderChannelSelectDialog = () => {
    return (
      <FaDialog
        title={t("CHOOSE_CHANNEL")}
        faOpen={isDialogOpen}
        //faOnSubmit={defineCoupons}
        loading={loading}
        //showSaveButton
        faHandleClose={() => {
          setIsDialogOpen(false);
        }}
      >
        <RadioGroup
          row
          aria-label="position"
          name="position"
          defaultValue="end"
        >
          <FormControlLabel
            value="EMAIL"
            control={<Radio color="primary" />}
            label={t("EMAIL")}
            onChange={() => {
              setChannel("EMAIL");
            }}
          />
          <FormControlLabel
            value="SMS"
            control={<Radio color="primary" />}
            label={t("SMS")}
            onChange={() => {
              setChannel("SMS");
            }}
          />
        </RadioGroup>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button onClick={sendCoupons} color="secondary" disabled={!channel}>
            {t("SEND_COUPONS")}
          </Button>
        </Grid>
      </FaDialog>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {renderChannelSelectDialog()}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                action={
                  <Grid
                    item
                    container
                    row
                    spacing={1}
                    style={{ marginRight: "10px" }}
                  >
                    <Grid item xs={6}>
                      <FaButton
                        variant="contained"
                        disabled={loading}
                        color="primary"
                        size="small"
                        faClick={() => {
                          defineCoupons();
                        }}
                        startIcon={<AssignmentTurnedIn />}
                        data-fa-button="ASSIGN_COUPONS"
                      >
                        {t("ASSIGN_COUPONS")}
                      </FaButton>
                    </Grid>
                    <Grid item xs={6}>
                      <FaButton
                        variant="contained"
                        disabled={loading}
                        color="primary"
                        size="small"
                        faClick={() => {
                          setIsDialogOpen(true);
                        }}
                        startIcon={<Send />}
                        data-fa-button="SEND_COUPONS"
                      >
                        {t("SEND_COUPONS")}
                      </FaButton>
                    </Grid>
                  </Grid>
                }
                title="Müşteri Listesi"
              />

              <ThingsDataTable
                showDeleteButton={false}
                showPaging={false}
                searchForm={searchForm}
                searchUrl={COUPON.searchCustomerCoupons}
                columns={columns}
                ref={tableRef}
                queryParams={{
                  page: 0,
                  size: 10,
                  sort: "id",
                  isAsc: false,
                }}
                showHeaderText={false}
                //faOnDoubleClick={onDoubleClick}
              />
            </Card>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
