import { Card, CardContent, CardHeader, ListItem } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { FaCreditCard } from "react-base-fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import i18n from "../../i18n";
import { ACCOUNT, ACC_BUYING, SALES_PACKAGE } from "../../services/faApi";
import { useStyles } from "./style";

export default function Checkout(props) {
  const { t } = useTranslation("checkout", { i18n });
  const classes = useStyles();
  const [id, setId] = useState(props.match.params.id);
  const accountId = localStorage.accountId;
  const [loading, setLoading] = useState(false);
  const [campaign, setCampaign] = useState({});
  const [account, setAccount] = useState({});
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  const odeme = (event, cardData) => {
    event.preventDefault();
    let bodyFormData = new FormData();
    bodyFormData.append("paymentData", cardData);
    let req = requests.getSimplePostRequest(
      ACCOUNT.payment + "/" + accountId,
      bodyFormData
    );
    let checkOutReq = requests.getSimplePostRequest(ACC_BUYING.buyPackage, {
      account: { id: accountId },
      salesPackage: campaign,
    });
    FaAxios(req)
      .then((response) => {
        return FaAxios(checkOutReq);
      })
      .then((response) => {
        showAlert("Satın alma işlemi başarılı", "success");
      })
      .catch((error) => {
        showAlert(error.message, "error");
      });
  };
  let accReq = requests.getObjectByIdRequest(ACCOUNT.findById, accountId);
  useEffect(() => {
    setLoading(false);
    let req = requests.getObjectByIdRequest(SALES_PACKAGE.findById, id);
    FaAxios(req)
      .then((response) => {
        if (!loading) {
          //console.log("set campaindata ->",response.data)
          setCampaign(response.data);
        }
        return FaAxios(accReq);
      })
      .then((response) => {
        if (!loading) {
          setAccount(response.data);
        }
      });
    return () => {
      setLoading(true);
    };
  }, [id]);

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Card>
          <CardHeader title={t("ODEME_BILGILERI")} />
          <CardContent>
            <Grid container spacing={1}>
              <Grid container>
                <Grid item lg={6} xs={12}>
                  <Typography variant="h6" align={"center"} gutterBottom>
                    {t("HESAP_BILGILERI")}
                  </Typography>
                  <List>
                    <ListItem alignItems="center">
                      <Grid container>
                        <Grid item lg={4} xs={12}>
                          <strong>{t("AD_SOYAD")}</strong>
                        </Grid>
                        <Grid item lg={8} xs={12}>
                          {account.fullname}
                        </Grid>
                      </Grid>
                    </ListItem>
                    <ListItem alignItems="center">
                      <Grid container>
                        <Grid item lg={4} xs={12}>
                          <strong>{t("HESAP_TIPI")}</strong>
                        </Grid>
                        <Grid item lg={8} xs={12}>
                          {account.acctype === "PERSONAL"
                            ? "Kişisel"
                            : "Kurumsal"}
                        </Grid>
                      </Grid>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Typography variant="h6" align={"center"} gutterBottom>
                    {t("PAKET_BILGILERI")}
                  </Typography>
                  <List>
                    <ListItem alignItems="center">
                      <Grid container>
                        <Grid item lg={4} xs={12}>
                          <strong>{t("PAKET_ADI")}</strong>
                        </Grid>
                        <Grid item lg={8} xs={12}>
                          {campaign.name}
                        </Grid>
                      </Grid>
                    </ListItem>
                    <ListItem alignItems="center">
                      <Grid container>
                        <Grid item lg={4} xs={12}>
                          <strong>{t("TUTAR")}</strong>
                        </Grid>
                        <Grid item lg={8} xs={12}>
                          ₺{campaign.price}
                        </Grid>
                      </Grid>
                    </ListItem>
                    <ListItem alignItems="center">
                      <Grid container className={classes.indirim}>
                        <Grid item lg={4} xs={12}>
                          <strong>{t("INDIRIM")}</strong>
                        </Grid>
                        <Grid item lg={8} xs={12}>
                          ₺
                          {Math.ceil(
                            (campaign.price * 100) /
                              (100 - campaign.discountRate) -
                              campaign.price
                          )}
                        </Grid>
                      </Grid>
                    </ListItem>
                    <ListItem alignItems="center">
                      <Grid container>
                        <Grid item lg={4} xs={12}>
                          <strong>{t("KDV")}</strong>
                        </Grid>
                        <Grid item lg={8} xs={12}>
                          ₺{(campaign.price / 100) * 18}
                        </Grid>
                      </Grid>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <Grid container>
                <FaCreditCard checkout={odeme} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
