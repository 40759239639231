import { Grid } from "@material-ui/core";
import { default as React, forwardRef, useEffect, useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { CampaignService } from "services/CampaignService";
import i18n from "../../i18n";
import { useStyles } from "./style";

const CampaignCheckListEdit = forwardRef((props, ref) => {
  const { campaign, onSave, selectedCheckList, onClose, open } = props;
  const { t } = useTranslation("campaign", { i18n });
  const accountId = localStorage.accountId;

  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const newCampaignCheckList = () => {
    return {
      campaign: campaign,
      name: null,
    };
  };

  const [campaignCheckList, setCampaignCheckList] = useState(
    newCampaignCheckList()
  );

  useEffect(() => {
    if (selectedCheckList?.id) {
      setCampaignCheckList({ ...selectedCheckList });
    } else {
      setCampaignCheckList(newCampaignCheckList());
    }
  }, [selectedCheckList, open]);

  const saveCheckList = () => {
    setLoading(true);
    CampaignService.saveCheckList(
      { ...campaignCheckList },
      (data) => {
        onSave();
        setLoading(false);
        onClose();
      },
      errorCallback
    );
  };

  const errorCallback = (error) => {
    console.log(error);
    setLoading(false);
  };
  const handleChangeV = (prop, val) => {
    setCampaignCheckList({ ...campaignCheckList, [prop]: val });
  };
  const handleChange = (prop) => (event) => {
    setCampaignCheckList({ ...campaignCheckList, [prop]: event.target.value });
  };
  const renderEditForm = () => {
    return (
      <>
        <FaDialog
          title={t("CAMPAIGN_CHECKLIST")}
          faOpen={open}
          faOnSubmit={saveCheckList}
          loading={loading}
          showSaveButton
          faHandleClose={onClose}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <FaInput
                required
                maxLength={100}
                label={t("CAMPAIGN_CHECKLIST")}
                onChange={handleChange("name")}
                value={campaignCheckList?.name || ""}
              />
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  return renderEditForm();
});

export default CampaignCheckListEdit;
