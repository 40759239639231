import Grid from "@material-ui/core/Grid";
import Adsense from "components/adsense/Adsense";
import React, { Suspense, useEffect, useState } from "react";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { getRole } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AccountService } from "services/AccountService";
import { AccPreferencesService } from "services/accPreferences/accPreferencesService";
import { ROLES } from "services/utils/Roles";
import { checkRole } from "services/utils/SecurityUtils";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { ACCOUNT } from "../../services/faApi";
import AccountDetailsCard from "./AccountDetailsCard";
import AccountPreferencesCard from "./AccountPreferencesCard";
import AccountPreferencesPeriodic from "./AccountPreferencesPeriodic";
import AccountRecordSettings from "./AccountRecordSettings";
import AccountSocialMedia from "./AccountSocialMedia";
import AccountUISettings from "./AccountUISettings";
import { useStyles } from "./style";

export default function AccountDetails() {
  const { t } = useTranslation("accountDetails", { i18n });
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const accountId = localStorage.accountId;

  const role = useSelector(getRole);
  const isMyThing = checkRole([ROLES.MY_THING, ROLES.MY_THINGS], role);

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  const [values, setValues] = useState({
    fullname: "",
    username: "", //account dtoda yok ancak owner uzerinden country cekilebiliyor
    taxnumber: "",
    province: "",
    phone: "",
    adress: "",
    email: "",
    acctype: "",
    taxadmin: "",
    workPlace: "",
    title: "",
    country: null,
    language: null,
    timezone: null,
    id: 0,
  });

  const [accountPreferences, setAccountPreferences] = useState({
    id: 1,
    labelTitle: "Title",
    labelMessage: "Message",
    labelFormat: "Format",
    labelDPI: "200dpi",
  });

  const [accountSubscription, setAccountSubscription] = useState(null);

  const [account, setAccount] = useState();
  const [count, setCount] = useState(0);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const reload = () => {
    setLoading(false);
    let req = requests.getObjectByIdRequest(ACCOUNT.findById, accountId);
    FaAxios(req).then((response) => {
      if (!loading) {
        setValues({ ...response.data, id: accountId });
        setAccount(response.data);

        AccountService.getSubscription(
          accountId,
          (data) => {
            setAccountSubscription(data);
          },
          (error) => {}
        );
      }
    });

    return () => {
      setLoading(true);
    };
  };

  useEffect(() => {
    reload();
  }, [accountId]);

  useEffect(() => {
    getAccPreferences();
  }, [accountId]);

  const getAccPreferences = () => {
    AccPreferencesService.findByAccount(
      accountId,
      (data) => {
        setAccountPreferences(data);
      },
      (error) => {}
    );
  };

  const accPreferencesUpdated = () => {
    getAccPreferences();
  };

  const updateAccount = (e) => {
    e.preventDefault();
    const updatedData = { ...values, id: accountId };
    var req = requests.getSimplePostRequest(ACCOUNT.update, updatedData);
    FaAxios(req)
      .then((response) => {
        setAccount(response.data);
        showAlert(t("GUNCELLEME_UYARI"), "success");
      })
      .catch((error) => {
        showAlert(error.message, "error");
      });
  };

  const onAccountUpdate = () => {
    reload();
  };

  return (
    <Suspense fallback={<LoadingBar />}>
      <Grid container spacing={1}>
        <Adsense value="RklmUst" />
        <Grid item lg={12}>
          <div className={classes.root}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <AccountDetailsCard
                  accountDetails={values}
                  onAccountUpdate={onAccountUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <AccountSocialMedia
                  accountDetails={values}
                  onSave={onAccountUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <AccountPreferencesCard
                  accPreferences={accountPreferences}
                  onSave={accPreferencesUpdated}
                />
              </Grid>
              <Grid item xs={6}>
                {!!!isMyThing && (
                  <AccountRecordSettings
                    account={accountPreferences}
                    onSave={accPreferencesUpdated}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1}>
                  {!!!isMyThing && (
                    <Grid item xs={12}>
                      <AccountPreferencesPeriodic
                        accountPreferences={accountPreferences}
                        onSave={accPreferencesUpdated}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {!!!isMyThing && (
                      <AccountUISettings
                        account={accountPreferences}
                        onSave={onAccountUpdate}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      {/* <RightBarDrawer open={true}>
          
        </RightBarDrawer> */}
    </Suspense>
  );
}
