import { Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DateRangeSelector from "components/dateRangeSelector";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaButton, FaDialog, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LoadingBar from "../../components/loadingBar";
import StockTakingStatusSelect from "../../components/select/StockTakingStatusSelect";
import i18n from "../../i18n";
import { STOCK_TAKING } from "../../services/faApi";
import { StockTakingService } from "../../services/StockTakingService";
import StockTakingCreate from "./StockTakingCreate";

export default function StockTaking() {
  const tableRef = useRef();
  const { t } = useTranslation("stockTaking", { i18n });
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const accountId = localStorage.accountId;

  const [loading, setLoading] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  const columns = [
    {
      field: "description",
      label: t("DESCRIPTION"),
      align: "center",
      width: "40%",
    },
    {
      field: "startDate",
      label: t("START_DATE"),
      align: "center",
      width: "20%",
      type: "dateTime",
    },
    {
      field: "endDate",
      label: t("END_DATE"),
      align: "center",
      width: "20%",
      type: "endDate",
    },
    {
      field: "status",
      label: t("STATUS"),
      align: "center",
      width: "20%",
      valueFormatter: (status) => {
        return t("" + status);
      },
    },
  ];

  const newStockTaking = () => {
    return {
      id: "",
      description: "",
      startDate: "",
      endDate: null,
      status: "DRAFT",
      endDescription: "",
    };
  };
  const [stockTaking, setStockTaking] = useState(newStockTaking());

  const handleChange = (prop) => (event) => {
    setStockTaking({ ...stockTaking, [prop]: event.target.value });
  };

  const saveSuccess = (data) => {
    showAlert(!stockTaking.id ? t("SAVED") : t("EDITED"), "success");
    setIsEditFormOpen(false);
    setStockTaking(newStockTaking());
    tableRef.current.refreshTable();
    setLoading(false);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const saveStockTaking = () => {
    setLoading(true);
    StockTakingService.save(
      {
        ...stockTaking,
        account: { id: accountId },
      },
      saveSuccess,
      saveError
    );
  };

  const newSearchForm = () => {
    let result = {
      description: null,
      dateStart: null,
      dateEnd: null,
      accountId: accountId,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    let tmpTo = {
      pathname: `stockTaking/detail/${row.id}`,
      breadCrumbKey: `/stockTaking/detail/:id`,
      state: {
        stockTaking: {
          ...row,
        },
      },
    };
    history.push(tmpTo);
  };

  const newFormRender = () => {
    return (
      <>
        <FaDialog
          title={"Sayım Tanımlama"}
          faOpen={isEditFormOpen}
          faOnSubmit={saveStockTaking}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            setIsEditFormOpen(data);
            setStockTaking(newStockTaking());
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <FaInput
                required
                maxLength={100}
                label={t("DESCRIPTION")}
                onChange={handleChange("description")}
                value={stockTaking.description || ""}
              />
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  const stockTakingOnChange = (data) => {
    let tmpTo = {
      pathname: `stockTaking/detail/${data.id}`,
      breadCrumbKey: `/thingClassDetail/:id`,
      state: {
        stockTaking: {
          ...data,
        },
      },
    };
    history.push(tmpTo);
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {newFormRender()}

        <StockTakingCreate
          onChange={stockTakingOnChange}
          open={isEditFormOpen}
          onClose={() => {
            setIsEditFormOpen(false);
          }}
        />

        <ThingsDataTable
          searchUrl={
            STOCK_TAKING.search + "?accountId=" + localStorage.accountId
          }
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
        />

        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={1} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                setIsEditFormOpen(true);
              }}
              startIcon={<AddIcon />}
              data-fa-button="ADD"
            >
              {t("ADD")}
            </FaButton>
          </Grid>
          <Grid item xs={5}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <FaInput
                label={t("DESCRIPTION")}
                value={searchForm.description}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "description",
                    event.target.value
                  );
                }}
              />

              <DateRangeSelector
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    dateStart: data.startDate,
                    dateEnd: data.endDate,
                  });
                }}
              />

              <StockTakingStatusSelect
                value={searchForm.status}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    status: data,
                  });
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
