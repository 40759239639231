import { Grid } from "@material-ui/core";
import ThingClassAutoComplete from "components/autocomplete/ThingClassAutoComplete";
import VendorAutoComplete from "components/autocomplete/VendorAutoComplete";
import ThingsMapButtonGroup from "components/buttonGroup/ThingsMapButtonGroup";
import DateRangeSelector from "components/dateRangeSelector";
import ThingStatusSelect from "components/select/ThingStatusSelect";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FaInput, FaSearch } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import Map, { GeolocateControl, Layer, Popup, Source } from "react-map-gl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ThingService } from "services/thing/thingService";
import i18n from "../../i18n";
import ThingMapDetail from "./ThingMapDetail";
import DrawControl from "./drawControl";
import {
  clusterCountLayer,
  clusterLayer,
  unclusteredPointLayer,
} from "./layers";
import { useStyles } from "./style";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoiYmlsYWxha2luY2kiLCJhIjoiY2thcDJhb2RoMHdoZzJ6cGZnOHZ6cDFicCJ9._lPdrre7P6yDWV2F3vbwpA"; // Set your mapbox token here

const geolocateStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  margin: 10,
};

export default function ThingsMap(props) {
  const [loading, setLoading] = useState(false);
  const [features, setFeatures] = useState({});

  const [viewport, setViewPort] = useState({
    latitude: 39.862645729977174,
    longitude: 32.738810051232576,
    zoom: 6,
    bearing: 0,
    pitch: 0,
  });
  const [geojson, setGeojson] = useState({
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [32.685632575303316, 39.882920037461844],
        },
      },
    ],
  });
  const mapRef = useRef();
  const sourceRef = useRef();
  const classes = useStyles();
  const { t } = useTranslation("things", { i18n });
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const accountId = localStorage.accountId;

  const [popupInfo, setPopupInfo] = useState(null);

  const [searchForm, setSearchForm] = useState({
    thingClass: null,
    thingClassId: null,
    thingClassList: [],
    thingClassIdList: [],
    description: null,
    thingCode: null,
    vendor: null,
    vendorId: null,
    vendorList: [],
    vendorIdList: [],
    summary: null,
    accountId: accountId,
    status: null,
    createDateStart: null,
    createDateEnd: null,
    images: false,
    gpsLocation: true,
    polygons: [],
  });

  const onClick = (event) => {
    if (
      event &&
      event.features &&
      event.features[0] &&
      event.features[0].properties &&
      event.features[0].properties.id
    ) {
      const feature = event.features[0];

      setPopupInfo({
        id: feature.properties.id,
        lng: event.lngLat.lng,
        lat: event.lngLat.lat,
      });
    } else if (
      event &&
      event.features &&
      event.features[0] &&
      event.features[0].properties &&
      event.features[0].properties.cluster_id
    ) {
      const feature = event.features[0];
      const clusterId = feature.properties.cluster_id;

      let map = mapRef.current.getMap();
      const mapboxSource = map.getSource("things");

      mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) {
          return;
        }

        setViewPort({
          ...viewport,
          latitude: feature.geometry.coordinates[1],
          longitude: feature.geometry.coordinates[0],
          zoom: zoom,
        });

        map.easeTo({
          center: feature.geometry.coordinates,
          zoom,
          duration: 500,
        });
      });
    }
  };

  const onUpdate = useCallback((e) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);

  const onDelete = useCallback((e) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
  }, []);

  useEffect(() => {
    reloadData();
  }, []);

  const reloadData = () => {
    searchSubmit();
  };

  useEffect(() => {
    let polygons = [];
    let polygonList = Object.values(features);
    if (polygonList && polygonList.length > 0) {
      polygonList.forEach((x) => {
        polygons.push(x.geometry.coordinates);
      });
    }

    console.log("polygons", polygons);
    setSearchForm({ ...searchForm, polygons: polygons });
  }, [features]);

  const parseLocation = (gpsLocation) => {
    if (gpsLocation && gpsLocation.length > 3) {
      let parsedData = JSON.parse(gpsLocation);
      if (parsedData.lat && parsedData.lng) {
        return parsedData;
      } else if (parsedData.latitude && parsedData.longitude) {
        return { lat: parsedData.latitude, lng: parsedData.longitude };
      }
    }

    return null;
  };

  const findGpsLocationThingsOnSuccess = (data) => {
    const temp = data.map((item) => {
      var location = parseLocation(item.gpsLocation);
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [location.lng, location.lat],
        },
        properties: {
          id: item.id,
        },
      };
    });

    setGeojson({
      type: "FeatureCollection",
      features: temp,
    });
  };

  const searchSubmit = () => {
    const paging = { page: 0, size: 10000, isAsc: false, sort: "createDate" };
    ThingService.search(
      searchForm,
      paging,
      findGpsLocationThingsOnSuccess,
      (error) => {}
    );
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    const paging = { page: 0, size: 10000, isAsc: false, sort: "createDate" };
    ThingService.search(
      { ...searchForm, summary: summary },
      paging,
      findGpsLocationThingsOnSuccess,
      (error) => {}
    );
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm({
      thingClass: null,
      thingClassId: null,
      thingClassList: [],
      thingClassIdList: [],
      description: null,
      thingCode: null,
      vendor: null,
      vendorId: null,
      vendorList: null,
      vendorIdList: null,
      summary: null,
      accountId: accountId,
      status: null,
      createDateStart: null,
      createDateEnd: null,
      images: false,
      gpsLocation: true,
    });
  };

  const goToIndoorMap = () => {
    const tmpTo = {
      pathname: `/mapIndoor`,
      breadCrumbKey: `/mapIndoor`,
    };
    history.push(tmpTo);
  };

  return (
    <>
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item container spacing={1} style={{ marginBottom: 5 }}>
          <Grid item lg={3} xs={6}>
            <Grid item xs={12}>
              <ThingsMapButtonGroup />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={7}>
          <FaSearch
            onSearch={searchSubmit}
            onClear={searchClear}
            onSummarySearch={searchSummary}
            setSearchInfo={setSearchInfo}
            faClassName="appSearchBar"
          >
            <ThingClassAutoComplete
              accountId={accountId}
              value={searchForm.thingClassList}
              filterExists={true}
              onChange={(data) => {
                setSearchForm({
                  ...searchForm,
                  thingClassList: data,
                  thingClassIdList: data?.map((item) => {
                    return item.id;
                  }),
                });
              }}
              multiple
            />
            <FaInput
              label={t("DESCRIPTION")}
              value={searchForm.description}
              onChange={(event) => {
                setSearchForm({
                  ...searchForm,
                  description: event.target.value,
                });
              }}
            />
            <FaInput
              label={t("THING_CODE")}
              value={searchForm.thingCode}
              onChange={(event) => {
                setSearchForm({
                  ...searchForm,
                  thingCode: event.target.value,
                });
              }}
            />
            <VendorAutoComplete
              accountId={accountId}
              value={searchForm.vendorList}
              onChange={(data) => {
                setSearchForm({
                  ...searchForm,
                  vendorList: data,
                  vendorIdList: data?.map((item) => {
                    return item.id;
                  }),
                });
              }}
              multiple
            />
            <ThingStatusSelect
              value={searchForm.status}
              onChange={(data) => {
                setSearchForm({
                  ...searchForm,
                  status: data,
                });
              }}
            />
            <DateRangeSelector
              onChange={(data) => {
                setSearchForm({
                  ...searchForm,
                  createDateStart: data.startDate,
                  createDateEnd: data.endDate,
                });
              }}
            />
          </FaSearch>
        </Grid>
      </Grid>

      <div style={{ height: "83vh", marginTop: 5 }}>
        <Map
          {...viewport}
          width="100%"
          height="100%"
          mapStyle="mapbox://styles/mapbox/light-v10"
          onMove={(evt) => setViewPort(evt.viewState)}
          mapboxAccessToken={MAPBOX_TOKEN}
          onClick={onClick}
          ref={mapRef}
          interactiveLayerIds={[unclusteredPointLayer.id, clusterLayer.id]}
        >
          <GeolocateControl
            style={geolocateStyle}
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation={true}
          />

          <Source
            id="things"
            type="geojson"
            data={geojson}
            cluster={true}
            clusterMaxZoom={14}
            clusterRadius={50}
          >
            <Layer {...clusterLayer} />
            <Layer {...clusterCountLayer} />
            <Layer {...unclusteredPointLayer} />
          </Source>

          {popupInfo && (
            <Popup
              anchor="top"
              longitude={Number(popupInfo.lng)}
              latitude={Number(popupInfo.lat)}
              closeOnClick={false}
              onClose={() => setPopupInfo(null)}
              maxWidth={"400px"}
            >
              <ThingMapDetail thingId={popupInfo.id} />
            </Popup>
          )}
          <DrawControl
            position="top-left"
            displayControlsDefault={false}
            controls={{
              polygon: true,
              trash: true,
            }}
            onCreate={onUpdate}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        </Map>
      </div>
    </>
  );
}
