import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import IndoorLocationHelp from "features/help/IndoorLocationHelp";
import IndoorLocationMapHelp from "features/help/IndoorLocationMapHelp";
import { default as React, Suspense, useEffect, useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import FaMap from "react-base-fa/dist/fa/faMap";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { LocationService } from "../../services/LocationService";
import LocationDetailItems from "./LocationDetailItems";
import { useStyles } from "./style";

export default function LocationDetail() {
  const classes = useStyles();
  const labelClasses = labelStyles();
  const { t } = useTranslation("location", { i18n });
  const { id } = useParams();
  const accountId = localStorage.accountId; //useSelector(getAccountId);
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  const faDialog = useDialog();
  let history = useHistory();

  const [openGpsLocationChangeDialog, setOpenGpsLocationChangeDialog] =
    useState(false);
  const [newGpsLocation, setNewGpsLocation] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState({});

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    setLoading(true);
    LocationService.findById(id, findByIdOnSuccess, findByIdOnError);
  };

  const findByIdOnSuccess = (data) => {
    let parsedData = JSON.parse(data.gpsLocation);
    setLocation({
      ...data,
      gpsLocation: {
        lat: parsedData.lat,
        lng: parsedData.lng,
      },
    });
    setNewGpsLocation({
      ...data,
      gpsLocation: {
        lat: parsedData.lat,
        lng: parsedData.lng,
      },
    });
    setLoading(false);
  };

  const findByIdOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };

  const onLocationInfoUpdate = (newData) => {
    setLocation(newData);
    showAlert("İç mekan bilgileri güncellendi", "success");
  };

  const editButtonClicked = () => {
    setIsModalOpen(true);
  };

  const saveLocation = () => {
    setLoading(true);
    LocationService.save(
      {
        ...location,
        account: { id: accountId },
        gpsLocation: JSON.stringify(location.gpsLocation),
      },
      saveSuccess,
      saveError
    );
  };

  const saveSuccess = (data) => {
    showAlert(!location.id ? t("SAVED") : t("EDITED"), "success");
    setIsModalOpen(false);
    let parsedData = JSON.parse(data.gpsLocation);
    setLocation({
      ...data,
      gpsLocation: {
        lat: parsedData.lat,
        lng: parsedData.lng,
      },
    });
    setOpenGpsLocationChangeDialog(false);
    setLoading(false);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const handleChange = (prop) => (event) => {
    setLocation({ ...location, [prop]: event.target.value });
  };

  const handleUpload = (event) => {
    event.preventDefault();
    if (event?.target?.files?.length > 0) {
      LocationService.upload(
        location.id,
        event.target.files[0],
        uploadOnSuccess,
        uploadOnError
      );
    }
  };

  const uploadOnSuccess = (data) => {
    window.location.reload();
  };

  const uploadOnError = (data) => {};

  const deleteImage = () => {
    LocationService.deleteFile(
      location.id,
      deleteImageOnSuccess,
      deleteImageOnError
    );
  };

  const deleteImageOnSuccess = (data) => {
    setLocation({ ...location, thumbnail: null });
  };

  const deleteImageOnError = (data) => {};

  const onChangeKonum = (event) => {
    setOpenGpsLocationChangeDialog(true);
    setNewGpsLocation({
      ...newGpsLocation,
      gpsLocation: {
        lng: event.lngLat[0],
        lat: event.lngLat[1],
      },
    });
  };

  const deleteLocation = (item) => {
    faDialog({
      description: t("İç mekanı silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        LocationService.delete(
          { id: item.id },
          (data) => {
            showAlert(t("İç mekan silindi"), "success");
            setIsModalOpen(false);
            const tmpTo = {
              pathname: `/location`,
              breadCrumbKey: `/location`,
              search: "?page=0&size=10&isAsc=true&sort=name",
            };
            history.push(tmpTo);
          },
          (error) => {
            showAlert(t("Kayıt silinemedi, bağlı kayıtlar mevcut."), "error");
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const renderDialog = () => {
    return (
      <FaDialog
        title={"İç Mekan Bilgileri Güncelle"}
        faOpen={isModalOpen}
        faOnSubmit={saveLocation}
        showSaveButton
        faHandleClose={() => {
          setIsModalOpen(false);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <FaInput
              required
              maxLength={100}
              label={t("NAME")}
              onChange={handleChange("name")}
              value={location.name || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              required
              maxLength={100}
              label={t("DESCRIPTION")}
              onChange={handleChange("description")}
              value={location.description || ""}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button
            onClick={() => {
              deleteLocation(location);
            }}
            color="secondary"
          >
            {t("Sil")}
          </Button>
        </Grid>
      </FaDialog>
    );
  };

  const closeGpsLocationDialog = () => {
    setOpenGpsLocationChangeDialog(false);
  };

  const changeGpsLocation = () => {
    console.log("newGpsLocation: ", newGpsLocation);
    const newItem = {
      ...location,
      gpsLocation: JSON.stringify(newGpsLocation.gpsLocation),
    };

    LocationService.save(newItem, saveSuccess, saveError);
  };

  const renderGpsLocationChangeDialog = () => {
    return (
      <Dialog
        open={openGpsLocationChangeDialog}
        onClose={closeGpsLocationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Konum değiştirilsin mi?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Haritadan seçmiş olduğunuz konum mekanın yeni konumu olarak
            ayarlanacaktır.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeGpsLocationDialog} color="primary">
            İptal
          </Button>
          <Button onClick={changeGpsLocation} color="primary" autoFocus>
            Değiştir
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {renderDialog()}
        {renderGpsLocationChangeDialog()}

        <Grid container spacing={1}>
          <Grid item lg={6} xs={12}>
            <Grid item container spacing={1}>
              <Grid item lg={12}>
                <Card>
                  <CardHeader
                    action={
                      <>
                        <IndoorLocationHelp value="locationDetail.location" />
                        <IconButton
                          aria-label="settings"
                          onClick={editButtonClicked}
                        >
                          <EditIcon />
                        </IconButton>
                      </>
                    }
                    title={t("LOCATION_INFO")}
                    data-fa-section="LOCATION_INFO"
                  />
                  <CardContent>
                    <Grid item container spacing={0}>
                      <Grid item xs={12}>
                        <Grid
                          item
                          container
                          spacing={1}
                          style={{ marginLeft: 10, marginRight: 10 }}
                        >
                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("NAME")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {location.name}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className={labelClasses.LabelLastRow}
                          >
                            <Typography className={labelClasses.LabelHeader}>
                              {t("DESCRIPTION")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {location.description}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              {location && location.id > 0 && (
                <Grid item lg={12}>
                  <LocationDetailItems location={location} />
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item lg={6} xs={12}>
            {newGpsLocation && newGpsLocation.id > 0 && (
              <Card>
                <CardHeader title={t("LOCATION")} data-fa-section="LOCATION" 
                action={
                  <IndoorLocationMapHelp value="indoorLocation.map"></IndoorLocationMapHelp>
                }
                />
                <CardContent>
                  <Grid item container spacing={1}>
                    <Grid item xs={12} className={classes.map}>
                      <FaMap
                        className={classes.map}
                        viewport={{
                          latitude: newGpsLocation.gpsLocation.lat,
                          longitude: newGpsLocation.gpsLocation.lng,
                          zoom: 16,
                          bearing: 0,
                          pitch: 0,
                        }}
                        marker={{
                          latitude: newGpsLocation.gpsLocation.lat,
                          longitude: newGpsLocation.gpsLocation.lng,
                          onMarkerDragEnd: onChangeKonum,
                        }}
                      ></FaMap>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
