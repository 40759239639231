import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import i18n from "../../../i18n";
import { useStyles } from "../style";
import ThingsSteps from "../ThingsSteps";
import TutorialElement from "../TutorialElement";

export default function AccountUserTutorial() {
  const { code } = useParams();

  const stepsRef = useRef();

  const { t } = useTranslation("tutorialAccountUser", { i18n });
  const classes = useStyles();
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            <b>Kullanıcıların</b> listelendiği ve yönetildiği Menü/Sayfadır.
            <p>
              Bu menüde;
              <ul>
                <li>
                  Kullanıcıları{" "}
                  <b>
                    Ad{"&"}Soyad, Rol, E-posta, Telefon Numarası, Katılma Tarihi
                  </b>{" "}
                  ve <b>Aktif/Pasif</b> olma durumlarına göre
                  listeleyebilirsiniz.
                </li>
                <li>Kullanıcı Detay bilgilerine erişebilirsiniz.</li>
                <li>Kullanıcı silebilirsiniz.</li>
              </ul>
            </p>
          </TutorialElement>
        ),
      },
      {
        element: ".MuiTableHead-root",
        intro: (
          <TutorialElement icon="click">
            Kullanıcıları{" "}
            <b>Ad{"&"}Soyad, Rol, E-posta, Telefon Numarası, Katılma Tarihi</b>{" "}
            ve <b>Aktif/Pasif</b> olma durumlarına göre listelemek için kolon
            isimlerine tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "div.MuiTableContainer-root > table > tbody > tr:nth-child(1)",
        intro: (
          <TutorialElement icon="click">
            <b>Kullanıcı Detay</b> bilgilerine erişmek için listedeki bir
            Kullanıcıya çift tıklayın.
          </TutorialElement>
        ),
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/accountUserDetail.png"
                }
                width="470"
                height="230"
              ></img>
            </div>
            <br />
            Kullanıcıyı silmek için <b>Sil</b>’e tıklayın.
          </TutorialElement>
        ),
        tooltipClass: classes.accountUserDetail,
      },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={"account_users"}
        steps={steps}
        ref={stepsRef}
        onStepChange={setStepIndex}
      />
    </div>
  );
}
