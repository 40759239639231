import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import i18n from "../../../i18n";
import ThingsSteps from "../ThingsSteps";
import TutorialElement from "../TutorialElement";

export default function ProfileTutorial() {
  const { code } = useParams();

  const stepsRef = useRef();

  const { t } = useTranslation("tutorialProfile", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            {t("Kullanıcı ile ilgili bilgilerin gösterildiği ekran/menü’dür. ")}
            <p>
              Bu menüde;
              <ul>
                <li>
                  <b>Profil Fotoğrafı</b> ekleyebilirsiniz.
                </li>
                <li>
                  <b>Kişisel Bilgilerinizi</b> ve <b>İletişim Tercihlerinizi</b>{" "}
                  güncelleyebilirsiniz.
                </li>
                <li>
                  <b>Sosyal Medya</b> hesaplarınızı ekleyebilirsiniz.
                </li>
                <li>
                  <b>Yeni Hesap(lar)</b> ekleyebilir ve Hesaplarınız arasında
                  geçiş yapabilir ve hesabınızı silebilirsiniz.
                </li>
                <li>
                  <b>Yeni Kullanıcı Davet</b> durumlarını izleyebilirsiniz.
                </li>
              </ul>
            </p>
          </TutorialElement>
        ),
      },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={"profile"}
        steps={steps}
        ref={stepsRef}
        onStepChange={setStepIndex}
      />
    </div>
  );
}
