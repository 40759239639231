import { Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { Add } from "@material-ui/icons";
import ThingsDataTable from "components/thingsDataTable";
import i18n from "i18n";
import { default as React, Suspense, useRef, useState } from "react";
import { FaButton, FaDialog } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { BeneficiaryPortalService } from "services/BeneficiaryPortalService";
import { BENEFICIARY_PORTAL } from "services/faApi";
import { getDateTimeWFormat, isTNF, qsParse } from "services/utils";

export default function CustomerThings(props) {
  const { campaign } = props;
  const accountId = localStorage.beneficiaryAccountId;
  const { t } = useTranslation("beneficiaryPortal", { i18n });
  const [loading, setLoading] = useState(false);
  const [thing, setThing] = useState(null);
  let history = useHistory();
  const faDialog = useDialog();

  const dispatch = useDispatch();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  const dialogRef = useRef();

  const tableRef = useRef();

  const newSearchForm = () => {
    let result = {
      accId: accountId,
      thingClass: null,
      thingClassId: null,
      thingClassList: [],
      thingClassIdList: [],
      description: null,
      thingCode: null,
      hashtag: null,
      vendor: null,
      vendorId: null,
      vendorList: [],
      vendorIdList: [],
      summary: "",
      accountId: null,
      status: null,
      createDateStart: null,
      createDateEnd: null,
      images: true,
      openIssues: null,
      organizationList: [],
      organizationIdList: [],
      locationList: [],
      locationIdList: [],
      ownerUser: null,
      ownerUserGroup: null,
      mtncUser: null,
      mtncUserGroup: null,
      user: null,
      userGroup: null,
      ownerUserId: null,
      ownerUserGroupId: null,
      mtncUserId: null,
      mtncUserGroupId: null,
      userId: null,
      userGroupId: null,
      thid: null,
      archive: false,
    };

    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());
  const [isThingListOpen, setIsThingListOpen] = useState(false);

  const columns = [
    {
      field: "createDate",
      label: t("Tarih"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        return getDateTimeWFormat(value);
      },
    },
    {
      field: "identifier",
      label: t("Varlık"),
      align: "center",
      width: "60%",
      valueFormatter: (value, row) => {
        return row.thing.identifier;
      },
    },
  ];

  const columnsThings = [
    {
      field: "identifier",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      width: "70%",
      valueFormatter: (value, row) => {
        return row.thing.identifier;
      },
    },
    {
      field: "button",
      label: t("İşlem"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        if (row.subscribed) {
          return (
            <FaButton disabled={loading} color="secondary" size="xs">
              {t("Üye olundu")}
            </FaButton>
          );
        } else {
          return (
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="xs"
              faClick={() => {
                createCustomerThing(accountId, row.thing.id);
              }}
              startIcon={<Add />}
              data-fa-button="ADD"
            >
              {t("Üye ol")}
            </FaButton>
          );
        }
      },
    },
  ];

  const refresh = () => {
    tableRef.current.refreshTable();
  };
  const createCustomerThing = (accId, thingId) => {
    faDialog({
      description: t("Üye olmak istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        BeneficiaryPortalService.createCustomerThing(
          accId,
          thingId,
          (data) => {
            showAlert("Üye Olundu", "success");
            setIsThingListOpen(false);
            refresh();
          },
          (error) => {}
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const renderDetailDialog = () => {
    return (
      <FaDialog
        faMaxWidth="xl"
        title={"Ürün ve Hizmetler"}
        faOpen={isThingListOpen}
        faHandleClose={() => {
          setIsThingListOpen(false);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={0}
        >
          <ThingsDataTable
            searchUrl={BENEFICIARY_PORTAL.searchThings + "?accId=" + accountId}
            searchForm={searchForm}
            queryParams={{
              page: 0,
              size: 10,
              sort: "description",
              isAsc: false,
            }}
            columns={columnsThings}
            ref={tableRef}
            //onSelectedChange={onSelectedChange}
            showDeleteButton={false}
            showHeaderText={false}
            headerText={t("VARLIK_LISTESI")}
            //faOnDoubleClick={onDoubleClick}
            data-fa-list="List"
          />
        </Grid>
      </FaDialog>
    );
  };
  return (
    <Suspense>
      {renderDetailDialog()}

      <Card>
        <CardHeader
          title={t("İlgilendiğim Ürün ve Hizmetler")}
          data-fa-section="CUSTOMER_THINGS"
        />

        <ThingsDataTable
          dataUrl={
            BENEFICIARY_PORTAL.findCustomerThings + "?accId=" + accountId
          }
          queryParams={{
            page: 0,
            size: 10,
            sort: "createDate",
            isAsc: false,
          }}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
        />
        <FaButton
          variant="contained"
          disabled={loading}
          color="primary"
          size="medium"
          faClick={() => {
            setIsThingListOpen(true);
          }}
          startIcon={<Add />}
          data-fa-button="ADD"
        >
          {t("Yeni bir ürün ve hizmete abone ol")}
        </FaButton>
      </Card>
    </Suspense>
  );
}
