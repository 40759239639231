import ThingDetail from "features/thingDetail";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { ThingService } from "services/thing/thingService";
import "./things.css";

const MyThing = forwardRef((props, ref) => {
  const accountId = localStorage.accountId;
  const [thingId, setThingId] = useState(null);

  const thingDetailRef = useRef();

  useEffect(() => {
    const paging = { page: 0, size: 1, isAsc: false, sort: "createDate" };
    const searchForm = { accountId: accountId };
    ThingService.search(
      { ...searchForm },
      paging,
      searchOnSuccess,
      (error) => {}
    );
  }, []);

  useImperativeHandle(ref, () => ({
    openPhotoMenu() {
      thingDetailRef.current.openPhotoMenu();
    },
  }));

  const searchOnSuccess = (data) => {
    if (data && data.length > 0) {
      setThingId(data[0].id);
    }
  };

  return thingId && <ThingDetail ref={thingDetailRef} thingId={thingId} />;
});
export default MyThing;
