import { CardHeader } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import ThingsDataTable from "components/thingsDataTable";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { CUSTOMER } from "services/faApi";
import { gridStyles } from "styles/ThingsGridStyle";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function CustomerDevice(props) {
  const { customer } = props;
  const classes = useStyles();
  const tableRef = useRef();
  const gridClasses = gridStyles();

  const { t } = useTranslation("customer", { i18n });

  const columns = [
    {
      field: "clientInfo.visitorId",
      label: t("VISITOR_ID"),
      align: "center",
      width: "12%",
    },
    {
      field: "clientInfo.ipAddress",
      label: t("IP_ADDRESS"),
      align: "center",
      width: "12%",
    },
    {
      field: "clientInfo.browser",
      label: t("BROWSER"),
      align: "center",
      width: "20%",
      valueFormatter: (value, row) => {
        return (
          row.clientInfo.browser +
          " " +
          row.clientInfo.browserType +
          " " +
          row.clientInfo.browserMajorVersion
        );
      },
    },
    {
      field: "clientInfo.deviceType",
      label: t("DEVICE_TYPE"),
      align: "center",
      width: "12%",
    },
    {
      field: "clientInfo.platform",
      label: t("PLATFORM"),
      align: "center",
      width: "12%",
    },
    {
      field: "clientInfo.platformVersion",
      label: t("PLATFORM_VERSION"),
      align: "center",
      width: "12%",
    },
    {
      field: "createDate",
      label: t("Tarih"),
      align: "center",
      width: "12%",
      type: "dateTime",
    },
  ];

  return (
    <>
      <Card style={{ minHeight: 100 }} className="fai-customerInteractions">
        <CardHeader
          title={t("Cihaz Geçmişi")}
          data-fa-section="Cihaz Geçmişi"
        />
        {customer && customer.id > 0 && (
          <ThingsDataTable
            dataUrl={CUSTOMER.findDevices + "?id=" + customer.id}
            queryParams={{
              page: 0,
              size: 10,
              sort: "createDate",
              isAsc: false,
            }}
            columns={columns}
            ref={tableRef}
            showDeleteButton={false}
            showHeaderText={false}
            columnClassName={gridClasses.GenericColumn}
          />
        )}
      </Card>
    </>
  );
}
