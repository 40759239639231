import { faMap, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import BeneficiaryNote from "components/beneficiaryNote";
import ThingDetail from "components/detail/ThingDetail";
import PropTypes from "prop-types";
import { default as React, useEffect, useRef, useState } from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CampaignService } from "services/CampaignService";
import { getDateTimeWFormat, isTNF } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import SurveyGPSLocation from "../survey/SurveyGPSLocation";
import CampaignCustomerButtons from "./CampaignCustomerButtons";
import CampaignCustomerCheckList from "./CampaignCustomerCheckList";
import CampaignCustomerLog from "./CampaignCustomerLog";
import CampaignSurveyQuestionRender from "./CampaignSurveyQuestionRender";
import { useStyles } from "./style";

export default function CampaignCustomerDetail(props) {
  const {
    id,
    campaignId,
    isOpen,
    onClose,
    onPrevious,
    onNext,
    currentItem,
    totalItems,
  } = props;

  const labelClasses = labelStyles();

  const { t } = useTranslation("campaign", { i18n });
  const dispatch = useDispatch();
  const classes = useStyles();
  const dialogRef = useRef();
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  const accountId = localStorage.accountId;
  const [campaign, setCampaign] = useState(null);
  const [campaignAnswers, setCampaignAnswers] = useState();

  useEffect(() => {
    if (
      campaignId &&
      isOpen &&
      (campaign == null || campaign.id !== campaignId)
    ) {
      CampaignService.getCampaign(
        campaignId,
        accountId,
        (data) => {
          setCampaign(data);
        },
        (error) => {}
      );
    }
  }, [isOpen]);

  useEffect(() => {
    if (id) refresh();
  }, [id]);

  const refresh = () => {
    CampaignService.customerFindById(
      id,
      (data) => {
        setCampaignAnswers(data);
      },
      (error) => {}
    );
  };

  const renderPage = () => {
    return (
      <Card style={{ minWidth: "100%", minHeight: 200, marginTop: 10 }}>
        <CardHeader title={t("CAMPAIGN_QUESTIONS")} />
        <CardContent>
          {campaign?.questionList.map((item, index) => (
            <div>
              <CampaignSurveyQuestionRender
                key={index}
                question={item}
                onChangeAnswer={() => {}}
                answer={campaignAnswers?.answers.find(
                  (i) => i.campaignQuestion.id === item.id
                )}
                editable={false}
                readOnly={true}
                accountId={accountId}
                currentLang={"tr"}
              />
            </div>
          ))}
        </CardContent>
      </Card>
    );
  };

  const renderGPSLocation = () => {
    return (
      <SurveyGPSLocation
        ref={dialogRef}
        answer={campaignAnswers}
      ></SurveyGPSLocation>
    );
  };

  const renderHeader = () => {
    return (
      <>
        {renderGPSLocation()}
        <Card
        //className={classes.minCardHeight}
        >
          <CardHeader />
          <CardContent>
            <Grid item container spacing={1}>
              <Grid item xs={12}>
                <Grid item container spacing={1}>
                  <Grid item xs={4} className={labelClasses.LabelLastRow}>
                    <Typography className={labelClasses.LabelHeader}>
                      {isTNF(t("THING"), t("THING_T2C"))}
                    </Typography>
                    <ThingDetail value={campaignAnswers?.thing} />
                  </Grid>

                  <Grid item xs={2} className={labelClasses.LabelLastRow}>
                    <Typography className={labelClasses.LabelHeader}>
                      {t("COMPLETION_TIME")}
                    </Typography>
                    <Typography className={labelClasses.LabelValue}>
                      {`${campaignAnswers?.completionTime} saniye`}
                    </Typography>
                  </Grid>

                  <Grid item xs={2} className={labelClasses.LabelLastRow}>
                    <Typography className={labelClasses.LabelHeader}>
                      {t("REGISTER_DATE")}
                    </Typography>
                    <Typography className={labelClasses.LabelValue}>
                      {getDateTimeWFormat(campaignAnswers?.createDate)}
                    </Typography>
                  </Grid>

                  <Grid item xs={2} className={labelClasses.LabelLastRow}>
                    <Typography className={labelClasses.LabelHeader}>
                      {t("GPS_LOCATION")}
                    </Typography>
                    <IconButton
                      aria-label="settings"
                      onClick={() => {
                        dialogRef.current.openDialog();
                      }}
                    >
                      <FontAwesomeIcon icon={faMap} />
                    </IconButton>
                  </Grid>
                  <Grid item xs={2} className={labelClasses.LabelLastRow}>
                    <Typography className={labelClasses.LabelHeader}>
                      {t("CUSTOMER")}
                    </Typography>
                    <IconButton
                      aria-label="settings"
                      onClick={() => {
                        let customerId = campaignAnswers?.customer?.id;
                        const tmpTo = {
                          pathname: `/customer/detail/${customerId}`,
                          breadCrumbKey: `/customer/detail/:id`,
                        };
                        history.push(tmpTo);
                      }}
                    >
                      <FontAwesomeIcon icon={faUser} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  };

  const renderButtons = () => {
    return (
      <div style={{ marginBottom: 5 }}>
        <Button
          color="primary"
          variant="contained"
          startIcon={<ArrowLeft />}
          onClick={onPrevious}
          disabled={currentItem === 0}
        >
          {t("Önceki [" + currentItem + "]")}
        </Button>

        <Button
          color="primary"
          variant="contained"
          endIcon={<ArrowRight />}
          style={{ float: "right" }}
          onClick={onNext}
          disabled={currentItem === totalItems - 1}
        >
          {t("Sonraki [" + (totalItems - currentItem - 1) + "]")}
        </Button>
      </div>
    );
  };

  return (
    <FaDialog
      title={t("CAMPAIGN_ANSWER_DETAIL")}
      faOpen={isOpen}
      loading={loading}
      faHandleClose={() => {
        onClose();
      }}
      faMaxWidth="lg"
    >
      <div>
        {renderButtons()}
        {renderHeader()}
        {renderPage()}

        <CampaignCustomerButtons
          campaignCustomerId={campaignAnswers?.id}
          status={campaignAnswers?.status}
          onChange={refresh}
        />

        <Grid item container spacing={1} style={{ marginTop: 10 }}>
          <Grid item xs={7}>
            <CampaignCustomerCheckList
              campaignCustomerId={campaignAnswers?.id}
              status={campaignAnswers?.status}
              onChange={refresh}
            />
          </Grid>
          <Grid item xs={5}>
            <div>
              <CampaignCustomerLog id={campaignAnswers?.id} />
              <BeneficiaryNote
                campaignCustomer={campaignAnswers}
                title={t("Mesajlaşma Geçmişi")}
                sms={
                  campaignAnswers && campaignAnswers.customer.phone
                    ? true
                    : false
                }
                email={
                  campaignAnswers && campaignAnswers.customer.email
                    ? true
                    : false
                }
                push={false}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </FaDialog>
  );
}

CampaignCustomerDetail.propTypes = {
  id: PropTypes.any,
  campaignId: PropTypes.any,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  currentItem: PropTypes.any,
  totalItems: PropTypes.any,
};
