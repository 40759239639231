import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { ACC_USER } from "./faApi";

export const AccUserService = {
  findAccUserRole: (accountId, userId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(ACC_USER.findAccUserRole, {
      accountId,
      userId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findAccUserSecurity: (accountId, userId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(ACC_USER.findAccUserSecurity, {
      accountId,
      userId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  disconnect: (accountId, userId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      ACC_USER.disconnect + "?accountId=" + accountId + "&userId=" + userId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  softDelete: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(ACC_USER.softDelete + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  activate: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(ACC_USER.activate + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  changeUserRole: (userId, authority, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      ACC_USER.changeUserRole + "?userId=" + userId + "&authority=" + authority
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
