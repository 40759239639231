import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  ThingDetailImage: {
    width: "100%",
    objectFit: "scale-down",
  },
  ThingDetailLabelHeader: {
    fontSize: "12px",
    lineHeight: 1.42857,
    //borderTop: "1px solid #ddd",
    borderBottom: "1px solid #cfdae0",
    fontFamily: "Open Sans,Helvetica,Arial,sans-serif",
    fontWeight: 400,
    color: "#9A9A9A",
  },

  LabelRow: {
    borderBottom: "1px solid #cfdae0",
  },

  LabelLastRow: {},

  LabelHeader: {
    fontSize: "10px",
    fontFamily: "Open Sans,Helvetica,Arial,sans-serif",
    fontWeight: 400,
    color: "#9A9A9A",
  },
  LabelValue: {
    fontSize: "12px",
    fontFamily: "Open Sans,Helvetica,Arial,sans-serif",
    fontWeight: 400,
    color: "#747474",
  },

  ActionButton: {
    fontSize: "1rem",
    padding: "0px",
  },

  ActionButtonPlaceHolder: {
    width: "18px",
    height: "20px",
  },
}));
