import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import InfoIcon from "@material-ui/icons/Info";
import DeleteIcon from '@material-ui/icons/Delete';
import { useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { Add } from "@material-ui/icons";
import { useStyles } from "./style";
import { FaButton } from "react-base-fa/dist/fa";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";


export default function IssuePhotosCarousel(props) {
  const { images, uploadImages, deleteImage } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const { t } = useTranslation("thingDetail", { i18n });
  const fileInputRef = useRef();

  const handleChange = (event) => {
    event.preventDefault();
    if (event?.target?.files?.length > 0) {
      uploadImages(event.target.files);
    }

  }


  const createCarouselItemImage = (item, index) => (
    <div key={index}>
        <img src={`data:image/png;base64, ${item.content}`} />
        
    </div>
);

const baseChildren = <div>{images.map(createCarouselItemImage)}</div>;

  return (
    <Carousel showArrows={true}>
      {baseChildren.props.children}
    </Carousel>
  );
}
