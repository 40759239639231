import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import i18n from "../../../i18n";
import { useStyles } from "../style";
import ThingsSteps from "../ThingsSteps";
import TutorialElement from "../TutorialElement";

export default function ThingClassTutorial() {
  const { code } = useParams();
  const classes = useStyles();

  const stepsRef = useRef();
  let history = useHistory();

  const { t } = useTranslation("tutorialAssignTask", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="click">
            <p>
              Etkileşim Noktası tiplerinin liste olarak gösterildiği
              Menü/Sayfadır.
              <br /> <br />
              Bu menüde Etkileşim Noktası Tiplerini;
              <ul>
                <li>
                  <b>Alfabetik</b> olarak listeleyebilirsiniz.
                </li>
                <li>
                  <b>Ata Sınıfı</b>na göre listeleyebilirsiniz.
                </li>
                <li>
                  <b>Hashtag</b>e göre listeleyebilirsiniz.
                </li>
                <li>
                  <b>Hesaba</b> göre listeleyebilirsiniz.
                </li>
                <li>
                  <b>Yeni Etkileşim Noktası Tipi</b> ekleyebilirsiniz.
                </li>
                <li>
                  Nokta Tipi <b>detay bilgilerine</b> erişebilirsiniz.
                </li>
              </ul>
            </p>
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-section='SYSTEM_BUTTON_GROUP']",
        intro: (
          <TutorialElement icon="click">
            Etkileşim Noktası Tiplerini <b>Hesaba</b> göre listelemek için
            tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-section='HASHTAG_BUTTON_GROUP']",
        intro: (
          <TutorialElement>
            Etkileşim Noktası Tiplerini <b>Hashtag</b>'e göre listelemek için
            tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-column='className']",
        intro: (
          <TutorialElement>
            Etkileşim Noktası Tiplerini <b>Alfabetik</b> olarak listelemek için
            tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-column='parent']",
        intro: (
          <TutorialElement>
            Etkileşim Noktası Tiplerini <b>Ata Sınıfa</b> göre listelemek için
            tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-column='domain']",
        intro: (
          <TutorialElement>
            Etkileşim Noktası Tiplerini <b>Hashtag</b>'e göre listelemek için
            tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-column='id']",
        intro: (
          <TutorialElement>
            Etkileşim Noktası Tiplerini <b>Hesaba</b> göre listelemek için
            tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='ADD']",
        intro: (
          <TutorialElement>
            <b>Yeni Etkileşim Nokta Tipi</b> eklemek için tıklayın.
          </TutorialElement>
        ),
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/thingClassAddDialogAtaSinif.png"
                }
                width="467"
                height="249"
              ></img>
            </div>
            <br />
            Sistemde tanımlı bir <b>Ata Sınıf</b> seçin ya da boş bırakın.
          </TutorialElement>
        ),
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/thingClassAddDialogThingClass.png"
                }
                width="467"
                height="249"
              ></img>
            </div>
            <br />
            <b>Yeni Etkileşim Nokta Tipi</b> girin.
          </TutorialElement>
        ),
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/thingClassAddDialogHashtag.png"
                }
                width="467"
                height="249"
              ></img>
            </div>
            <br />
            Kullanmak istediğiniz sınıflandırma sistemini seçmek için{" "}
            <b>Hashtag</b>’a tıklayın,
            <b>
              THING*<sup>1</sup>
            </b>{" "}
            ya da sistemde tanımlı{" "}
            <b>
              Taşınır Mal*<sup>2</sup>
            </b>{" "}
            ve{" "}
            <b>
              UniClass*<sup>3</sup>
            </b>{" "}
            kutucuğundan birini işaretleyin ve <b>Kaydet</b> butonuna basın.
            <br />
            <br />
            <b>
              *<sup>1</sup>
            </b>
            : THING seçerseniz kendinize özel bir sınıflandırma sistemi
            oluşturabilirsiniz.
            <br />
            <br />
            <b>
              *<sup>2</sup>
            </b>
            : Taşınır Mal Yönetmeliği kapsamında bir sınıflandırma sistemidir.
            <br />
            <br />
            <b>
              *<sup>3</sup>
            </b>
            : UniClass 2015, Birleşik Krallık (UK) inşaat sektörünün tüm
            sektörleri için birleşik bir sınıflandırma sistemidir.
          </TutorialElement>
        ),
      },
      {
        element: "div.MuiTableContainer-root > table > tbody > tr:nth-child(1)",
        intro: (
          <TutorialElement>
            Detay bilgilerine erişmek istediğiniz <b>Nokta Tipi</b>’nin üzerine{" "}
            <b>çift</b> tıklayın.
          </TutorialElement>
        ),
      },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={code}
        steps={steps}
        ref={stepsRef}
        onStepChange={setStepIndex}
      />
    </div>
  );
}
