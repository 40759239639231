import ThingDetail from "features/thingDetail";
import MyThings from "features/things/MyThings";
import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import i18n from "../../i18n";
import { useStyles } from "./QuickStartTutorialStyle";
import ThingsSteps from "./ThingsSteps";
import TutorialElement from "./TutorialElement";

export default function ShareWebLinkBeginnerTutorial() {
  const { code } = useParams();
  const classes = useStyles();

  const stepsRef = useRef();
  const myThingsRef = useRef(null);
  const thingDetailRef = useRef(null);
  let history = useHistory();

  const { t } = useTranslation("tutorialShareWebLink", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);
  const [thingId, setThingId] = useState(null);

  useEffect(() => {
    setSteps([
      {
        element: "#menuMyThings",
        intro: (
          <TutorialElement
            header="Menu"
            icon="click"
            text={
              "Etkileşim Noktanıza ait web bağlantısı paylaşmak için  tıklayın."
            }
          />
        ),
      },
      // 1
      {
        intro: (
          <TutorialElement icon="info">
            {t("Etkileşim noktalarınız burada görüntülenmektedir.")}
          </TutorialElement>
        ),
        comp: "MyThings",
      },
      // 2
      {
        element: "[data-fa-button='MY_THING_CARD']",
        intro: (
          <TutorialElement icon="click">
            {"Web bağlantısı eklemek istediğiniz "}
            <b>{t("Nokta")}</b> {"'ya tıklayın."}
          </TutorialElement>
        ),
        comp: "MyThings",
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
          setThingId(myThingsRef.current.getThingId());
        },
      },
      {
        intro: (
          <TutorialElement icon="info">
            {t("Etkileşim noktasına ait tüm bilgiler burada yer almaktadır.")}
          </TutorialElement>
        ),
        comp: "ThingDetail",
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "[data-fa-button='WEB_LINKS_ADD']",
        intro: (
          <TutorialElement header="Menu" icon="click">
            {t("Web bağlantısı eklemek için tıklayın.")}
          </TutorialElement>
        ),
        comp: "ThingDetail",
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "[data-fa-section='WEB_LINK_DIALOG']",
        intro: (
          <TutorialElement icon="info">
            {t("Açıklama bilgilerini girin. URL’yi girin.")} <br />
            {t("Etkileşim için ")} <b>{t("Herkes'i")}</b> {t(" seçin ve ")}{" "}
            <b>{t("Kaydet'e")}</b> {t(" basın.")}
          </TutorialElement>
        ),
        comp: "ThingDetail",
        onBeforeChange: (index) => {
          $("[data-fa-button='WEB_LINKS_ADD']").click();
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "[data-fa-section='WEB_LINKS_CARD']",
        intro: (
          <TutorialElement icon="info">
            {t("Web bağlantılarınızı bu alanda görebilirsiniz. ")} <br />
            {t(
              "Artık Web bağlantı(ları)nızı müşterilerinizle paylaşmaya hazırsınız."
            )}
          </TutorialElement>
        ),
        comp: "ThingDetail",
        onBeforeChange: (index) => {
          $("[aria-label='close']")[0].click();
          stepsRef.current.updateStepElement(index);
        },
      },
    ]);
  }, []);

  return (
    <div>
      {steps &&
        steps.length > stepIndex &&
        steps[stepIndex].comp === "MyThings" && <MyThings ref={myThingsRef} />}
      {steps &&
        steps.length > stepIndex &&
        steps[stepIndex].comp === "ThingDetail" && (
          <ThingDetail ref={thingDetailRef} thingId={thingId} />
        )}

      <ThingsSteps
        code={code}
        steps={steps}
        ref={stepsRef}
        onStepChange={setStepIndex}
        afterComplete={() => window.history.back()}
      />
    </div>
  );
}
