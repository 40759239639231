import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { CUSTOMER_MESSAGE } from "./faApi";

export const CustomerMessageService = {
  save: (message, callback, errorCallback) => {
    let reqBody = {
      ...message,
    };

    let req = requests.getSimplePostRequest(CUSTOMER_MESSAGE.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(CUSTOMER_MESSAGE.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  changeStatusActive: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      CUSTOMER_MESSAGE.changeStatusActive + "/" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  changeStatusDraft: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      CUSTOMER_MESSAGE.changeStatusDraft + "/" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  sendNow: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      CUSTOMER_MESSAGE.startNow + "/" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  saveTargets: (target, callback, errorCallback) => {
    let reqBody = {
      ...target,
    };

    let req = requests.getSimplePostRequest(
      CUSTOMER_MESSAGE.saveTargets,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  saveAllTargets: (targets, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      CUSTOMER_MESSAGE.saveAllTargets,
      targets
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  deleteTargets: (targets, callback, errorCallback) => {
    let reqUrl = "?";
    for (let i = 0; i < targets.length; i++) {
      reqUrl += "id=" + targets[i];
      if (i !== targets.length - 1) {
        reqUrl += "&";
      }
    }

    let reqBody = {
      ...targets,
    };
    let req = requests.getSimplePostRequest(
      CUSTOMER_MESSAGE.deleteTargets + reqUrl,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },

  findTargets: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      CUSTOMER_MESSAGE.findTargets + "?customerMessageTemplateId=" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findDocuments: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      CUSTOMER_MESSAGE.findDocuments + "?customerMessageTemplateId=" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  uploadDocument: (id, document, callback, errorCallback) => {
    let formData = new FormData();
    formData.append("multipartFile", document.file, document.file.name);

    let req = requests.getSimplePostRequest(
      CUSTOMER_MESSAGE.uploadDocument + "/" + id,
      formData
    );
    req["header"] = { "Content-Type": "multipart/form-data" };

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  downloadDocument: (
    customerMessageTemplateId,
    id,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(
      CUSTOMER_MESSAGE.downloadDocument +
        "?customerMessageTemplateId=" +
        customerMessageTemplateId +
        "&id=" +
        encodeURI(id)
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  deleteDocument: (customerMessageTemplateId, id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      CUSTOMER_MESSAGE.deleteDocument +
        "?customerMessageTemplateId=" +
        customerMessageTemplateId +
        "&id=" +
        id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  searchDetail: (searchForm, callback, errorCallback) => {
    let reqBody = {
      ...searchForm,
    };
    let req = requests.getSimplePostRequest(
      CUSTOMER_MESSAGE.searchDetail,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
