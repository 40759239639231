import { IconButton } from "@material-ui/core";
import ThingsHelpIcon from "components/icons/ThingsHelpIcon";
import ThingsSteps from "features/tutorial/ThingsSteps";
import TutorialElement from "features/tutorial/TutorialElement";
import $ from "jquery";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

export default function ThingClassDetailHelp(props) {
  const { value } = props;
  const { t } = useTranslation("help", { i18n });

  const stepsRef = useRef();
  const [open, setOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            <b>Etkileşim Noktası Tipi Bilgileri</b>’nin güncellendiği alandır.
          </TutorialElement>
        ),
      },
      {
        element: "[aria-label='settings']",
        intro: (
          <TutorialElement icon="tutorial">
            Etkileşim Noktası Tipi Bilgilerini güncellemek için tıklayın.
            <br />
            <br />
            <b>ÖNEMLİ NOT:</b>
            <br />
            Sistemden gelen nokta tipleri güncellenemez ve silinemez.
            <br />
            Sadece hesabınıza eklediğiniz nokta tiplerini güncelleyebilirsiniz.
          </TutorialElement>
        ),
      },
      {
        element: "#dialog-form",
        intro: (
          <TutorialElement icon="tutorial">
            <b>Ata Sınıfı, Etkileşim Noktası Tipini</b> ve/veya <b>Hashtag</b>{" "}
            bilgilerini güncelleyin ve <b>Kaydet</b> butonuna basın.
            <br />
            <br />
            Nokta Tipini silmek için <b>Sil</b> butonuna basın.
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          $("[aria-label='settings']")[0].firstElementChild.click();
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "[title='Kopyala']",
        intro: (
          <TutorialElement icon="click">
            Etkileşim Noktası Tipini kopyalamak için tıklayın.
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          $("[aria-label='close']")[0].click();
          stepsRef.current.updateStepElement(index);
        },
      },
    ]);
  }, []);

  return (
    <>
      {open && (
        <ThingsSteps
          code={value}
          steps={steps}
          ref={stepsRef}
          onStepChange={setStepIndex}
          afterComplete={() => {
            setOpen(true);
            var el = $("[aria-label='close']");
            if (el != null && el[0] != null) el[0].click();
          }}
        />
      )}
      <IconButton
        aria-label="help"
        onClick={() => setOpen(true)}
        title={t("HELP")}
      >
        <ThingsHelpIcon />
      </IconButton>
    </>
  );
}

ThingClassDetailHelp.propTypes = {
  value: PropTypes.string,
};
