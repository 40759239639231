import { Button, CircularProgress, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import LoadingBar from "components/loadingBar";
import React, { Suspense, useEffect, useState } from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import FaButton from "react-base-fa/dist/fa/faButton";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AccountService } from "services/AccountService";
import { ACCOUNT } from "services/faApi";
import { getDateTimeWFormat, HumanFileSize } from "services/utils";
import { BASE_URL } from "serviceWorker";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import { useStyles } from "./AccountDetailsCardStyle";

export default function AccountExportData(props) {
  const {} = props;
  const { t } = useTranslation(["accountDetails", "components"], { i18n });
  const classes = useStyles();
  const accountId = localStorage.accountId;

  const labelClasses = labelStyles();
  const [loading, setLoading] = useState(false);

  const [lastExportData, setLastExportData] = useState(null);
  const [isOpenSummaryDialog, setIsOpenSummaryDialog] = useState(false);
  const [dataSummary, setDataSummary] = useState();

  const dispatch = useDispatch();
  const faDialog = useDialog();

  useEffect(() => {
    if (lastExportData && lastExportData.completed === "NO") {
      const interval = setInterval(() => {
        refresh();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [lastExportData]);

  const refresh = () => {
    AccountService.getLastExportData(
      accountId,
      (data) => {
        setLastExportData(data);
      },
      (error) => {}
    );
  };

  useEffect(() => {
    if (accountId) {
      refresh();
    }
  }, [accountId]);

  const downloadData = () => {
    AccountService.downloadExportData(
      accountId,
      lastExportData.id,
      downloadDataonSuccess,
      (error) => {}
    );
  };

  const onDelete = () => {
    AccountService.deleteExportData(
      accountId,
      lastExportData.id,
      (data) => {
        refresh();
      },
      (error) => {
        refresh();
      }
    );
  };
  const exportData = () => {
    AccountService.exportData(
      accountId,
      (data) => {
        refresh();
      },
      exportDataonError
    );
    setIsOpenSummaryDialog(false);
    // faDialog({
    //   description: t(
    //     "Veriyi dışarıya almak istediğinize emin misiniz? İşleminiz kayıtlı etkileşim noktası miktarınıza ve bilgilerinize göre uzun sürebilir."
    //   ),
    //   type: "confirm",
    // })
    //   .then((confirmation) => {
    //     setLoading(true);
    //     AccountService.exportData(
    //       accountId,
    //       (data) => {
    //         refresh();
    //       },
    //       exportDataonError
    //     );
    //   })
    //   .catch((err) => console.log("error:", err));
  };

  const downloadDataonSuccess = (data) => {
    const link = document.createElement("a");
    link.href = "data:application/octet-stream;base64," + data.content;
    link.setAttribute("download", data.fileName);
    document.body.appendChild(link);
    link.click();
  };

  const exportDataonError = (data) => {};

  const getExportDataSummary = () => {
    AccountService.getExportDataSummary(
      accountId,
      (data) => {
        setDataSummary(data);
        setIsOpenSummaryDialog(true);
      },
      () => {}
    );
  };

  const renderSummaryDialog = () => {
    return (
      <FaDialog
        loading={loading}
        faOpen={isOpenSummaryDialog}
        title={t("Veri Özeti")}
        faHandleClose={() => setIsOpenSummaryDialog(false)}
      >
        {dataSummary ? (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            data-fa-section="DATA_SUMMARY"
          >
            <Grid item xs={10}>
              <strong>{dataSummary?.thingCount}</strong> adet etkileşim
              noktasına ait veri indirilecektir. İşleminiz kayıtlı etkileşim
              noktası miktarınıza ve bilgilerinize göre uzun sürebilir. Devam
              etmek istiyor musunuz?
            </Grid>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button onClick={exportData} color="primary" variant="contained">
                {t("Devam et")}
              </Button>
            </Grid>
          </Grid>
        ) : null}
      </FaDialog>
    );
  };

  return (
    <Suspense fallback={<LoadingBar />}>
      {renderSummaryDialog()}
      <Grid
        item
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
      >
        <Grid item xs={12}>
          <Card style={{ marginTop: "16px", minHeight: 158 }}>
            <CardHeader
              title={t("DATA_EXPORT")}
              data-fa-section="DATA_EXPORT"
              style={{ height: 40 }}
            />
            <CardContent>
              <Grid
                item
                container
                spacing={1}
                style={{ marginLeft: 10, marginRight: 10 }}
              >
                <Grid item xs={12} className={labelClasses.LabelLastRow}>
                  <Typography className={labelClasses.LabelValue}>
                    Hesaptaki etkileşim noktalarınıza ait bilgileri, görselleri
                    ve dokümanları dışarı aktarabilirsiniz.
                  </Typography>
                </Grid>
                {lastExportData && lastExportData.completed === "NO" && (
                  <Grid item xs={12} className={labelClasses.LabelRow}>
                    <Typography className={labelClasses.LabelValue}>
                      <CircularProgress size={20} /> Başlama zamanı{" "}
                      {getDateTimeWFormat(lastExportData.startDate)}
                      {lastExportData.fileSize > 0 &&
                        "(" + HumanFileSize(lastExportData.fileSize) + ")"}
                      <Button onClick={onDelete} color="secondary">
                        {t("Sil")}
                      </Button>
                    </Typography>
                  </Grid>
                )}
                {lastExportData && lastExportData.completed === "YES" && (
                  <Grid item xs={6} className={labelClasses.LabelRow}>
                    <Typography className={labelClasses.LabelValue}>
                      Oluşturulma zamanı{" "}
                      {getDateTimeWFormat(lastExportData.endDate)}{" "}
                      {"(" + HumanFileSize(lastExportData.fileSize) + ")"}
                      <div>
                        <FaButton
                          style={{ width: "40%" }}
                          size="small"
                          color="primary"
                          faClick={() => {
                            window.location =
                              BASE_URL +
                              ACCOUNT.downloadExportDataRaw +
                              "/" +
                              accountId +
                              "/" +
                              lastExportData.id +
                              "/data.zip";
                          }}
                        >
                          {t("İndir")}
                        </FaButton>
                        <Button onClick={onDelete} color="secondary">
                          {t("Sil")}
                        </Button>
                      </div>
                    </Typography>
                  </Grid>
                )}
                {!lastExportData && (
                  <Grid
                    item
                    xs={6}
                    className={labelClasses.LabelLastRow}
                    data-fa-button="DATA_EXPORT_BUTTON"
                  >
                    <FaButton
                      style={{ width: "40%" }}
                      size="small"
                      color="primary"
                      faClick={() => getExportDataSummary()}
                    >
                      {t("Veri dışarı aktar")}
                    </FaButton>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Suspense>
  );
}
