import { Collapse, Grid, Link, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import PropTypes from "prop-types";
import React, { createRef, useRef, useState } from "react";
import AuthCode from "react-auth-code-input";
import { selectThemePrefer } from "react-base-fa/dist/AppSlice";
import { FaButton, FaInput, FaUnAuthPages } from "react-base-fa/dist/fa";
import useStyles from "react-base-fa/dist/fa/faUnAuthPages/style";
import { requests } from "react-base-fa/dist/services/requestCreater";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import PasswordStrengthBar from "react-password-strength-bar";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { RECAPTCHA_CODE } from "services/utils/Constants";
import i18n from "../../i18n";
import { USER_APPLICATION } from "../../services/faApi";
import { authStyles } from "./style";

export default function ChangePassword(props) {
  const { backGroundImage, loginPath, appLogo } = props;

  const theme = useSelector(selectThemePrefer);

  const classes = useStyles();
  const authClasses = authStyles();

  const { t } = useTranslation("changePassword", { i18n });

  const history = useHistory();
  const location = useLocation();
  //console.log("location ->",location);
  let formRef = createRef();
  let recaptchaRef = useRef();
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const [values, setValues] = useState({
    token: "",
    password: "",
    passwordConf: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    password: "",
    token: "",
    passwordConf: "",
  });
  const [isFieldsEmpty, setIsFieldsEmpty] = useState({
    token: true,
    password: true,
    passwordConf: true,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setIsFieldsEmpty({ ...isFieldsEmpty, [prop]: false });
  };

  const handleChangeConfirmation = (prop) => (value) => {
    setValues({ ...values, [prop]: value });
    setIsFieldsEmpty({ ...isFieldsEmpty, [prop]: false });
  };

  async function sendByMail(event) {
    event.preventDefault();

    let req = requests.getSimplePostRequest(
      USER_APPLICATION.changeForgotPassword +
        "/" +
        values.token +
        "/" +
        location.state.changeMailValues.hash
    );
    axios
      .post(req.url, {
        username: location.state.changeMailValues.email,
        newPassword: values.password,
      })
      .then((response) => {
        setIsMessageSent(true);
        setAlertType("success");
        setAlertMessage(t("BASAIRILI_MESAJ"));
        setShowAlert(true);

        setTimeout(() => {
          history.push("login");
        }, 4000);
      })
      .catch((err) => {
        console.warn(err);
        setAlertType("error");
        setAlertMessage(t("EPOSTA_BULUNAMADI"));
        setShowAlert(true);
      });
  }
  async function sendByPhone(event) {
    event.preventDefault();
    let myPhoneNumber = location.state.changePhoneValues.phone;
    const regEx = new RegExp(/[\s()*]/g);
    myPhoneNumber = myPhoneNumber.replaceAll(regEx, "");
    let req = requests.getSimplePostRequest(
      USER_APPLICATION.changeForgotPassword +
        "/" +
        values.token +
        "/" +
        location.state.changePhoneValues.hash
    );
    axios
      .post(req.url, {
        username: myPhoneNumber,
        newPassword: values.password,
      })
      .then((response) => {
        setIsMessageSent(true);
        setAlertType("success");
        setAlertMessage(t("BASAIRILI_MESAJ"));
        setShowAlert(true);

        setTimeout(() => {
          history.push("login");
        }, 4000);
      })
      .catch((err) => {
        console.warn(err);
        setAlertType("error");
        setAlertMessage(t("TELEFON_BULUNAMADI"));
        setShowAlert(true);
      });
  }
  const checkPassword = () => {
    if (values.password !== values.passwordConf) {
      setErrorMessages({
        ...errorMessages,
        ["passwordConf"]: t("SIFRE_AYNI_DEGIL"),
      });
    } else {
      setErrorMessages({ ...errorMessages, ["passwordConf"]: "" });
    }
  };

  const changePasswordeScreen = () => {
    return (
      <FaUnAuthPages>
        <img src={appLogo} height="78" alt="logo" className={classes.appLogo} />
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          theme={theme}
          badge="bottomright"
          sitekey={RECAPTCHA_CODE}
        />

        <form
          ref={formRef}
          className={classes.form}
          validate="true"
          onSubmit={location.state.changeMailValues ? sendByMail : sendByPhone}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AuthCode
                onChange={handleChangeConfirmation("token")}
                containerClassName={authClasses.container}
                inputClassName={authClasses.input}
                allowedCharacters="numeric"
              />
            </Grid>

            <Grid item xs={12}>
              <FaInput
                faType="password"
                required
                label={t("YENI_SIFRE")}
                onChange={handleChange("password")}
                onBlur={checkPassword}
                value={values.password}
                helperText={errorMessages.password}
                faError={errorMessages.password}
              />
              <PasswordStrengthBar
                password={values.password}
                scoreWords={[
                  t("PASSWORD_LVL1"),
                  t("PASSWORD_LVL2"),
                  t("PASSWORD_LVL3"),
                  t("PASSWORD_LVL4"),
                  t("PASSWORD_LVL5"),
                ]}
                shortScoreWord={t("PASSWORD_TOO_SHORT")}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                faType="password"
                required
                label={t("YENI_SIFRE_TEKRAR")}
                onChange={handleChange("passwordConf")}
                onBlur={checkPassword}
                value={values.passwordConf}
                helperText={errorMessages.passwordConf}
                faError={errorMessages.passwordConf}
              />
            </Grid>
          </Grid>
          <Collapse in={showAlert}>
            <Alert
              variant="outlined"
              severity={alertType}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setShowAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {alertMessage}
            </Alert>
          </Collapse>
          <FaButton
            async
            disabled={
              isFieldsEmpty.passwordConf ||
              isFieldsEmpty.token ||
              isFieldsEmpty.password ||
              isMessageSent ||
              errorMessages.token ||
              errorMessages.passwordConf ||
              errorMessages.password
            }
            type="submit"
            formRef={formRef}
            label={t("GONDER")}
            variant="contained"
            style={{ marginTop: "10px", marginBottom: "15px" }}
            className={classes.submit}
            loading={loading}
          />
          <Grid container justify="center">
            <Grid item>
              <Link
                onClick={() => history.push(loginPath)}
                className={classes.link}
                variant="body2"
              >
                {t("GIRIS_YAP")}
              </Link>
            </Grid>
          </Grid>
        </form>
      </FaUnAuthPages>
    );
  };

  const forwardingScreen = () => {
    return (
      <FaUnAuthPages maxWidth="md">
        <Grid
          container
          spacing={3}
          justify="center"
          alignItems="center"
          style={{ marginBottom: 5 }}
        >
          <Grid
            item
            container
            spacing={3}
            lg={8}
            justify="center"
            alignItems="center"
          >
            <img
              src={appLogo}
              height="78"
              alt="logo"
              className={classes.appLogo}
            />

            <Grid
              container
              item
              spacing={2}
              lg={12}
              xs={12}
              alignItems="center"
              justify="center"
            >
              <Grid item lg={12} xs={12}>
                <Typography variant="subtitle1">
                  {t("FORWARDING_PAGE_TEXT")}
                </Typography>
              </Grid>

              <Grid item lg={3} xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  component="span"
                  color="primary"
                  disableElevation
                  onClick={() => {
                    history.push("forgot-password");
                  }}
                >
                  {t("DEVAM_ET")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FaUnAuthPages>
    );
  };

  console.log("location.state", location.state);
  return location.state &&
    (location.state.changeMailValues || location.state.changePhoneValues)
    ? changePasswordeScreen()
    : forwardingScreen();
}

ChangePassword.propTypes = {
  loginPath: PropTypes.string,
  backGroundImage: PropTypes.any,
  appLogo: PropTypes.any,
};
