import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import i18n from "../../i18n";
import { SALES_PACKAGE } from "../../services/faApi";
import { useStyles } from "./style";

export default function Campaigns() {
  const { t } = useTranslation("campagins", { i18n });
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const queryParams = {
    page: 0,
    size: 20,
    sort: "id",
    isAsc: true,
  };

  const req = requests.getSimpleGetRequest(SALES_PACKAGE.findAll, queryParams);

  useEffect(() => {
    setLoading(true);
    FaAxios(req)
      .then((response) => {
        if (!loading) {
          setCampaigns(response.data.list);
        }
      })
      .catch((error) => {
        showAlert(error.message, "error");
        setLoading(false);
      });

    return () => {
      setLoading(false);
    };
  }, [campaigns]);

  const goCheckout = (id) => (event) => {
    event.preventDefault();
    const tmpTo = {
      pathname: `/checkout/${id}`,
      breadCrumbKey: `/checkout/:id`,
    };
    history.push(tmpTo);
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        {campaigns.map((campagin) => {
          return (
            <Card key={campagin.id} className={classes.campaignCard}>
              <Grid container>
                <Grid
                  item
                  sm={3}
                  xs={12}
                  className={classes.campaignCardHeader}
                >
                  {campagin.name}
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  className={classes.campaignCardDescription}
                >
                  <span className={classes.oldPrice}>
                    ₺
                    {Math.ceil(
                      (campagin.price * 100) / (100 - campagin.discountRate)
                    )}
                  </span>
                  <span className={classes.price}>₺{campagin.price}</span>
                  <span className={classes.description}>
                    {campagin.description}
                  </span>
                </Grid>
                <Grid
                  item
                  sm={3}
                  xs={12}
                  className={classes.campaignCardButton}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={goCheckout(campagin.id)}
                    size="small"
                    startIcon={<FontAwesomeIcon icon={faCartPlus} />}
                    data-fa-button="PURCHASE"
                  >
                    {t("PURCHASE")}
                  </Button>
                </Grid>
              </Grid>
            </Card>
          );
        })}
      </Container>
    </div>
  );
}
