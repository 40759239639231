import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import i18n from "../../../i18n";
import ThingsSteps from "../ThingsSteps";
import TutorialElement from "../TutorialElement";

export default function ThingDetailTutorial() {
  const { code } = useParams();

  const stepsRef = useRef();

  const { t } = useTranslation("tutorialThingDetail", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement header="Menu" icon="click">
            Etkileşim Noktanız ile ilgili bilgi, belge, görsel, bildirim,
            tarihçe, etkileşim sayıları ve istatistiklerin yer aldığı
            Menü/Sayfa’dır.
            <p>
              Bu sayfada Etkileşim Noktanızla ilgili;
              <ul>
                <li>
                  <b>Temel Bilgileri </b> düzenleyebilirsiniz.
                </li>
                <li>
                  <b>Görsel</b> ekleyebilirsiniz.
                </li>
                <li>
                  <b>Ek Alanları</b> güncelleyebilirsiniz.
                </li>
                <li>
                  <b>Dosya</b> paylaşabilirsiniz.
                </li>
                <li>
                  <b>Web Bağlantıları</b> oluşturabilirsiniz.
                </li>
                <li>
                  <b>Hatırlatıcılar</b> oluşturabilirsiniz.
                </li>
                <li>
                  Harita üzerinden veya Mekanlar içinden <b>Konum</b>{" "}
                  seçebilirsiniz.
                </li>
                <li>
                  <b>Not</b> ekleyebilirsiniz.
                </li>
                <li>
                  <b>Bildirimleri</b> izleyebilir ve detay sayfasına
                  erişebilirsiniz.
                </li>
                <li>
                  <b>Etkileşim sayfası</b> seçebilirsiniz.
                </li>
                <li>
                  <b>İlişkili Etkileşim Noktası</b> ekleyebilirsiniz.
                </li>
              </ul>
            </p>
          </TutorialElement>
        ),
      },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={"thing_detail"}
        steps={steps}
        ref={stepsRef}
        onStepChange={setStepIndex}
      />
    </div>
  );
}
