import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import LoadingBar from "components/loadingBar";
import i18n from "i18n";
import PropTypes from "prop-types";
import { default as React, Suspense } from "react";
import { FaBarChart } from "react-base-fa/dist/fa/facharts";
import { useTranslation } from "react-i18next";
import { CHART_COLORS } from "services/utils/ChartConstants";
import { labelStyles } from "styles/ThingsLabelStyle";
import AnswerDistribution from "./AnswerDistribution";
import OptionsTable from "./OptionsTable";

export default function SurveyAnalysisMatrix(props) {
  const { question, questionAnalysis } = props;

  const labelClasses = labelStyles();
  const { t } = useTranslation("survey", { i18n });
  const chartKeys = question.options
    .filter((x) => x.optionType === "COLUMN")
    .map((x) => x.name);

  const columns = question.options
    .filter((x) => x.optionType === "COLUMN")
    .map((x) => x.name);
  const rows = question.options
    .filter((x) => x.optionType === "ROW")
    .map((x) => x.name);

  console.log({ columns });
  console.log({ rows });

  question.options
    .filter((x) => x.optionType === "ROW")
    .map((x) => {
      let tmp = {
        value: x.name,
      };

      question.options
        .filter((y) => y.optionType === "COLUMN")
        .map((y) => {
          tmp["" + y.name] = -1;
        });

      return tmp;
    });

  const chartData = questionAnalysis.detailList
    .filter((x) => x.surveyQuestionOptionId)
    .map((item) => {
      let name = question.options.find(
        (x) => x.id === item.surveyQuestionOptionId
      ).name;
      return {
        label: name,
        ["" + name]: item.count,
      };
    });

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid container spacing={1}>
          <Grid item lg={12}>
            <Grid item container spacing={1}>
              <Grid item lg={12}>
                <Card>
                  <CardHeader
                    title={
                      question.orderNo +
                      ". " +
                      (question?.multiLangName !== null
                        ? question?.multiLangName["tr"]
                        : "")
                    }
                  />
                  <CardContent>
                    <Grid item container spacing={1}>
                      <Grid item xs={12}>
                        <AnswerDistribution
                          questionAnalysis={questionAnalysis}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FaBarChart
                          data={chartData}
                          keys={chartKeys}
                          indexBy={"label"}
                          // legends={[]}
                          height="300px"
                          //bAxisLabel={t("")}
                          //lAxisLabel={t("")}
                          colors={CHART_COLORS}
                          axisBottom={{
                            format: (e) => Math.floor(e) === e && e,
                          }}
                          layout={"horizontal"}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <OptionsTable
                          question={question}
                          questionAnalysis={questionAnalysis}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}

SurveyAnalysisMatrix.propTypes = {
  question: PropTypes.any,
  questionAnalysis: PropTypes.any,
};
