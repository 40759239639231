import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { ACCOUNT_UI_SETTINGS } from "./faApi";

export const AccountUISettingsService = {
  saveAll: (recordSettings, callback, errorCallback) => {
    let reqBody = {
      ...recordSettings,
    };

    let req = requests.getSimplePostRequest(
      ACCOUNT_UI_SETTINGS.saveAll,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findByAccountId: (accountId, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(
      ACCOUNT_UI_SETTINGS.findByAccountId,
      accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
