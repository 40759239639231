import {
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { getDateTimeWFormat } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { RecurrenceDesc } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import { RecurrenceService } from "../../services/RecurrenceService";
import { useStyles } from "./style";

export default function ThingRecurrences(props) {
  const { thing, isEditable } = props;
  const faDialog = useDialog();
  const classes = useStyles();
  const labelClasses = labelStyles();

  const { t } = useTranslation("thingDetail", { i18n });

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDetailDialogOpen, setIsDetailDialogOpen] = useState(false);
  const [recurrenceList, setRecurrenceList] = useState([]);

  useEffect(() => {
    if (thing && thing.id) {
      RecurrenceService.findByThing(
        thing.account.id,
        thing.id,
        findOnSuccess,
        findOnError
      );
    }
  }, [thing]);

  const findOnSuccess = (data) => {
    setRecurrenceList(data);
  };

  const findOnError = () => {};
  return (
    <>
      <Card style={{ minHeight: 100 }} className="fai-thingLinks">
        <CardHeader
          title={t("PERIODIC_TASKS")}
          data-fa-section="PERIODIC_TASKS"
        />
        <CardContent>
          <Grid item container spacing={1}>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                {/* <TableHead>
                  <TableRow>
                    <TableCell>{t("PERIODIC_TASK_DESCRIPTION")}</TableCell>
                    <TableCell>{t("USER")}</TableCell>
                    <TableCell>{t("RECURRENCE_START_DATE")}</TableCell>
                    <TableCell>{t("RECURRENCE_END_DATE")}</TableCell>
                  </TableRow>
                </TableHead> */}
                <TableBody>
                  {recurrenceList.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell className={labelClasses.LabelValue}>
                        {row.name}
                      </TableCell>
                      <TableCell className={labelClasses.LabelValue}>
                        {row.user?.fullname}
                        {row.userGroup?.name}
                      </TableCell>
                      <TableCell className={labelClasses.LabelValue}>
                        {getDateTimeWFormat(row.nextDate)}
                      </TableCell>
                      <TableCell className={labelClasses.LabelValue}>
                        {getDateTimeWFormat(row.endDate)}
                      </TableCell>
                      <TableCell className={labelClasses.LabelValue}>
                        {RecurrenceDesc(row, t)}
                      </TableCell>
                      <TableCell className={labelClasses.LabelValue}>
                        {row.status === "ACTIVE" ? "Aktif" : "Pasif"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
