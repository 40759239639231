import { Card, Grid, IconButton } from "@material-ui/core";
import {
  AddCircleOutline,
  CheckCircleOutline
} from "@material-ui/icons";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import React, { useEffect, useState } from "react";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { SubscriptionService } from "services/SubscriptionService";
import {
  GetFieldByLanguage,
  getCurrentLangCode,
  priceToString,
  qsParse
} from "services/utils";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function SignUpSalesPackages(props) {
  const { onChange } = props;

  const { t } = useTranslation(["signUp", "errorMessages"], { i18n });
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [lang, setLang] = useState(getCurrentLangCode());

  const productParam = qsParse(history.location.search)?.product;

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [period, setPeriod] = useState("YEARLY");
  
  useEffect(() => {
    findActiveList();
  }, []);

  const findActiveList = () => {
    SubscriptionService.findActiveList(
      (data) => {
        setSubscriptionList(data);
        setLoading(false);
        checkActiveStepViaProduct(data);
      },
      (error) => {
        showAlert(error.message, "error");
        setLoading(false);
      }
    );
  };

  const checkActiveStepViaProduct = (list) => {
    if (productParam && list && list.length > 0) {
      let i = 0;
      for (const item of list) {
        if (item.monthlyPlanReferenceCode === productParam) {
          handleOnChange(i, list);
          setActiveStep(i);
          setPeriod("MONTHLY");
          return;
        } else if (item.yearlyPlanReferenceCode === productParam) {
          handleOnChange(i, list);
          setActiveStep(i);
          setPeriod("YEARLY");
          return;
        }
        i++;
      }
    } else if (list && list.length > 0) {
      handleOnChange(0, list);
    }
  };

  const handleOnChange = (index, list) => {
    if (list) {
      if (period === "YEARLY") onChange(list[index]?.yearlyPlanReferenceCode);
      else onChange(list[index]?.monthlyPlanReferenceCode);
    } else {
      if (period === "YEARLY")
        onChange(subscriptionList[index]?.yearlyPlanReferenceCode);
      else onChange(subscriptionList[index]?.monthlyPlanReferenceCode);
    }
  };

  const handleNext = () => {
    handleOnChange(activeStep + 1);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    handleOnChange(activeStep - 1);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  
  const renderIcon = (type) => {
    if (type === "PACKAGE_INCLUDE") return <CheckCircleOutline />;
    else return <AddCircleOutline />;
  };

  const renderContent = (item, campaign) => {
    const text = GetFieldByLanguage(item.content, lang);
    if (item.type === "THING_COUNT") {
      return text ? text.replace("?", campaign?.thingCount) : text;
    } else if (item.type === "USER_COUNT") {
      return text ? text.replace("?", campaign?.userCount) : text;
    } else if (item.type === "STORAGE_AREA") {
      return text
        ? text.replace("?", campaign?.diskSpace + " " + campaign?.diskUnit)
        : text;
    } else return text;
  };

  const renderPriceString = (price, currency) => {
    if (price && price > 0) return  priceToString(price, currency);
    else return "Ücretsiz";
  };

  const getStepContent = () => {
    let campaign = subscriptionList[activeStep];
    return (
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        style={{ minHeight: 700 }}
      >
        <Grid
          item
          xs={12}
          style={{ position: "relative", padding: 5, height: 35 }}
        ></Grid>
        <Grid item xs={12} className={classes.campaignCardHeader}>
          {campaign.name}
        </Grid>
        <Grid item xs={12} className={classes.campaignCardDescription}>
          {((period === "YEARLY" &&
            campaign.priceYearly !== campaign.discountPriceYearly) ||
            (period === "MONTHLY" &&
              campaign.priceMonthly !== campaign.discountPriceMonthly)) && (
            <span className={classes.oldPrice}>
              {renderPriceString(period === "YEARLY"
                ? campaign.priceYearly
                : campaign.priceMonthly, campaign.currency)}
            </span>
          )}

          <span className={classes.price}>
            {renderPriceString(period === "YEARLY"
              ? campaign.discountPriceYearly
              : campaign.discountPriceMonthly, campaign.currency)}
          </span>
          <span className={classes.description}>{t(period)}</span>
          <span className={classes.description}>{campaign.description}</span>
        </Grid>
        <Grid item xs={12} style={{ minHeight: 400, paddingTop: 20 }}>
          {campaign.salesPackageDetail.map((item, index) => (
            <Grid
              container
              direction="row"
              justifyContent="center"
              style={{ paddingLeft: 50 }}
              key={index}
            >
              <Grid item xs={2}>
                {renderIcon(item.type)}
              </Grid>
              <Grid item xs={10}>
                {renderContent(item, campaign)}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {subscriptionList && subscriptionList.length > 0 && (
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ height: "100%" }}
        >
          <Grid item xs={1}>
            <IconButton
              onClick={() => {
                handleBack(true);
              }}
              disabled={activeStep === 0}
            >
              <ArrowBackIosIcon />
            </IconButton>
          </Grid>
          <Grid item xs={10}>
            <Card
              elevation={5}
              style={{
                backgroundImage:
                  "linear-gradient(155deg, #C74593 2%, #7d42c1 40%, #d419ae 95%, #d419ae)",
                color: "white",
                borderRadius: "40px",
              }}
            >
              {getStepContent()}
            </Card>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              onClick={() => {
                handleNext(true);
              }}
              disabled={activeStep === subscriptionList.length - 1}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </>
  );
}
