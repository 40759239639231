import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CardHeader,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useState } from "react";
import { FaDialog, FaFileUpload } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import { CustomerMessageService } from "../../services/CustomerMessageService";
import { useStyles } from "./MessageDetailCardStyle";

export default function CustomerMessageDocuments(props) {
  const { message } = props;
  const faDialog = useDialog();
  const labelClasses = labelStyles();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  const [files, setFiles] = useState([]);

  const { t } = useTranslation("customerMessage", { i18n });

  const [showAction, setShowAction] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDetailDialogOpen, setIsDetailDialogOpen] = useState(false);
  const [messageDocuments, setMessageDocuments] = useState([]);
  const [theDocument, setDocument] = useState({});

  useEffect(() => {
    if (message && message.id)
      CustomerMessageService.findDocuments(
        message.id,
        findDocumentsOnSuccess,
        findDocumentsOnError
      );
  }, [message]);

  const findDocumentsOnSuccess = (data) => {
    setMessageDocuments(data);
  };

  const findDocumentsOnError = () => {};

  const saveDocument = () => {
    CustomerMessageService.uploadDocument(
      message.id,
      files[0],
      uploadDocumentOnSuccess,
      uploadDocumentOnError
    );
  };

  const uploadDocumentOnSuccess = () => {
    CustomerMessageService.findDocuments(
      message.id,
      findDocumentsOnSuccess,
      findDocumentsOnError
    );
    setIsEditDialogOpen(false);
    setFiles([]);
    setLoading(false);
  };

  const uploadDocumentOnError = (e) => {
    showAlert(t(e), "error");
    setLoading(false);
  };

  const handleChangeV = (prop, val) => {
    setDocument({ ...theDocument, [prop]: val });
  };

  const downloadDocument = (item) => {
    CustomerMessageService.downloadDocument(
      message.id,
      item.id,
      downloadDocumentOnSuccess,
      downloadDocumentOnError
    );
  };

  const downloadDocumentOnSuccess = (data) => {
    const link = document.createElement("a");
    link.href = "data:application/octet-stream;base64," + data.content; //url;
    link.setAttribute("download", data.fileName);
    document.body.appendChild(link);
    link.click();
  };

  const downloadDocumentOnError = (data) => {};

  const deleteDocument = (item) => {
    faDialog({
      description: t("Silmek istediğinize emin misiniz?"),
      type: "confirm",
    }).then((confirmation) => {
      CustomerMessageService.deleteDocument(
        message.id,
        item.id,
        deleteDocumentOnSuccess,
        deleteDocumentOnError
      );
    });
  };

  const deleteDocumentOnSuccess = (data) => {
    CustomerMessageService.findDocuments(
      message.id,
      findDocumentsOnSuccess,
      findDocumentsOnError
    );

    setIsDetailDialogOpen(false);
  };

  const deleteDocumentOnError = (data) => {};

  const renderEditDialog = () => {
    return (
      <FaDialog
        title={t("Dosya Ekle")}
        loading={loading}
        faOpen={isEditDialogOpen}
        faOnSubmit={() => {
          setLoading(true);
          saveDocument();
        }}
        showSaveButton
        faHandleClose={() => {
          setIsEditDialogOpen(false);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <FaFileUpload
              maxFileSize={20000000}
              onChange={(data) => {
                if (data != null && data.length > 0) {
                  setFiles(data);
                } else setFiles(null);
              }}
              initialFiles={files}
              filesLimit={1}
            />
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  const renderDetailDialog = () => {
    return (
      <FaDialog
        title={t("Dosya Detay")}
        faOpen={isDetailDialogOpen}
        faHandleClose={() => {
          setIsDetailDialogOpen(false);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={0}
        >
          <Grid item xs={12}>
            <Grid item container spacing={1}>
              <Grid item xs={12} className={labelClasses.LabelRow}>
                <Link
                  onClick={() => {
                    downloadDocument(theDocument);
                  }}
                  title={t("İndir")}
                  className={labelClasses.SimpleLink}
                >
                  <Typography className={labelClasses.LabelValue}>
                    {theDocument.fileName}
                  </Typography>
                </Link>
              </Grid>

              <Grid item xs={2}>
                {
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    startIcon={<FontAwesomeIcon icon={faTrash} />}
                    onClick={() => {
                      deleteDocument(theDocument);
                    }}
                    title={t("Sil")}
                  >
                    {t("Sil")}
                  </Button>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FaDialog>
    );
  };
  const renderDocuments = () => {
    if (
      message == null ||
      messageDocuments == null ||
      messageDocuments.length === 0
    ) {
      return <div>Kayıt bulunamadı.</div>;
    } else
      return (
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody>
              {messageDocuments?.map((item, index) => (
                <TableRow
                  key={index}
                  onMouseEnter={() => setShowAction(true)}
                  onMouseLeave={() => setShowAction(false)}
                >
                  <TableCell>
                    <Link
                      onClick={() => {
                        downloadDocument(item);
                      }}
                      className={labelClasses.SimpleLink}
                    >
                      <Typography className={labelClasses.LabelValue}>
                        {item.fileName}
                      </Typography>
                    </Link>
                  </TableCell>

                  <TableCell align="right">
                    {showAction ? (
                      <IconButton
                        disabled={message.status !== "DRAFT"}
                        onClick={() => {
                          setDocument(item);
                          setIsDetailDialogOpen(true);
                        }}
                        size="small"
                        color="primary"
                        aria-label={t("EDIT")}
                        className={classes.ActionButton}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </IconButton>
                    ) : (
                      <div className={classes.ActionButtonPlaceHolder} />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
  };
  return (
    <>
      {renderEditDialog()}
      {renderDetailDialog()}
      <Card style={{ minHeight: 203 }} className="fai-thingDocuments">
        <CardHeader
          action={
            <IconButton
              aria-label="settings"
              disabled={message.status !== "DRAFT"}
              onClick={() => {
                setDocument({
                  fileName: "",
                });
                setIsEditDialogOpen(true);
              }}
            >
              <AddIcon />
            </IconButton>
          }
          title={t("FILES")}
          data-fa-section="FILES"
        />
        <CardContent>
          <Grid item container spacing={1}>
            {renderDocuments()}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
