import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { useStyles } from "../style";
import ThingsSteps from "../ThingsSteps";
import TutorialElement from "../TutorialElement";

export default function LocationTutorial() {
  const stepsRef = useRef();

  const { t } = useTranslation("tutorialCustomerInteractionsAll", { i18n });
  const classes = useStyles();

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            İç Mekanların tanımlandığı ve izlendiği Menü/Sayfadır.
            <p>
              Bu menüde;
              <ul>
                <li>
                  <b>İç Mekân</b> tanımlayabilirsiniz.
                </li>
                <li>
                  Tanımlı İç mekanları <b>izleyebilir</b> ve{" "}
                  <b>detay bilgilerine</b> erişebilirsiniz.
                </li>
              </ul>
            </p>
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='ADD']",
        intro: (
          <TutorialElement icon="click">
            <b>İç Mekân</b> tanımlamak için tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "#dialog-form",
        intro: (
          <TutorialElement icon="click">
            İç Mekân’a ait <b>Ad</b> ile <b>Açıklama</b> girin ve <b>Kaydet</b>{" "}
            butonuna basın.
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          $("[data-fa-button='ADD']")[0].firstElementChild.click();
          stepsRef.current.updateStepElement(index);
        },
      },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={"location"} // veri tabanındaki kodla aynı olması önemli
        steps={steps}
        ref={stepsRef}
        onStepChange={setStepIndex}
        afterComplete={() => {
          var el = $("[aria-label='close']");
          if (el != null && el[0] != null) el[0].click();
        }}
      />
    </div>
  );
}
