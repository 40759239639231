import { CardHeader, useTheme } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { FaBarChart } from "react-base-fa/dist/fa/facharts";
import { useTranslation } from "react-i18next";
import { ThingStatisticService } from "services/statistic/statisticService";
import { CHART_COLORS } from "services/utils/ChartConstants";
import i18n from "../../../i18n";

export default function HomepageIssueStats(props) {
  const { size, refreshCount } = props;
  const { t } = useTranslation("components", { i18n });

  const theme = useTheme();
  const accountId = localStorage.accountId;

  const [issueClassCountByMonth, setIssueClassCountByMonth] = useState([]);
  const [issueClassCountByMonthCategory, setIssueClassCountByMonthCategory] =
    useState([]);

  useEffect(() => {
    ThingStatisticService.issueClassCountByMonth(
      accountId,
      setIssueClassCountByMonth,
      setIssueClassCountByMonthCategory
    );
  }, [refreshCount]);

  return (
    <Grid item lg={size} xs={12}>
      <Card>
        <CardHeader title={t("HOMEPAGE_ITEM.ISSUES_CHART")} />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item lg={12} xs={12}>
              <Grid item>
                <FaBarChart
                  data={issueClassCountByMonth}
                  keys={issueClassCountByMonthCategory}
                  indexBy={"label"}
                  height="300px"
                  bAxisLabel="Sorun Sayısı"
                  lAxisLabel="Sorun İstatistikleri"
                  stacked
                  colors={CHART_COLORS}
                  margin={{ top: 50, right: 300, bottom: 50, left: 60 }}
                  legends={[
                    {
                      dataFrom: "keys",
                      anchor: "right",
                      direction: "column",
                      justifyContent: false,
                      translateX: 130,
                      translateY: 0,
                      itemsSpacing: 2,
                      itemWidth: 100,
                      itemHeight: 12,
                      itemTextColor: theme.palette.text.primary,
                      itemDirection: "left-to-right",
                      itemOpacity: 0.85,
                      symbolSize: 12,
                      effects: [
                        {
                          on: "hover",
                          style: {
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                  //colorBy="index"
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
