import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
    closeButton: {
        color: theme.palette.grey[500],
        right: 0,
        top: 0,
        position: "absolute"
    },
    drawer: {
        overflow: 'hidden'
    },
    drawerContent: {
        padding: 10,
        height: 400
    },
    drawerPaper: {
        height: "100%",
        top: 80,
        right: 25,
        width: "20.3vw",
        minWidth: 200,
        overflow: "hidden",
        zIndex: 900
    }
}));