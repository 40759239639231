import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  circularNode: {
    height: 80,
    width: 130,
    borderRadius: "50%",
    border: "1px solid #532363",
    background: "#efeff5",
    "& label": {
      display: "block",
      color: "#4E4E4F",
      fontSize: "12px",
      transform: "translateY(50%)",
      textAlign: "center",
    },
  },
  userNode: {
    height: 50,
    width: 130,
    borderRadius: "10%",
    border: "1px solid #532363",
    background: "#efeff5",
    "& label": {
      display: "block",
      color: "#4E4E4F",
      fontSize: "12px",
      transform: "translateY(50%)",
      textAlign: "center",
    },
  },
}));
