import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { THING_LINK } from "../../services/faApi";

export const ThingLinkService = {
  save: (thingLink, callback, errorCallback) => {
    let reqBody = {
      ...thingLink,
    };

    let req = requests.getSimplePostRequest(THING_LINK.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (thingLink, callback, errorCallback) => {
    let reqBody = {
      ...thingLink,
    };

    let req = requests.getSimplePostRequest(THING_LINK.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  moveUp: (thingLink, callback, errorCallback) => {
    let reqBody = {
      ...thingLink,
    };

    let req = requests.getSimplePostRequest(THING_LINK.moveUp, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  moveDown: (thingLink, callback, errorCallback) => {
    let reqBody = {
      ...thingLink,
    };

    let req = requests.getSimplePostRequest(THING_LINK.moveDown, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findThingLinks: (thingId, accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      THING_LINK.findThingLinks +
        "?accountId=" +
        accountId +
        "&thingId=" +
        thingId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findThingClassLinks: (thingClassId, accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      THING_LINK.findThingClassLinks +
        "?accountId=" +
        accountId +
        "&thingClassId=" +
        thingClassId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  makePublic: (id, accountId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      THING_LINK.makePublic + "?id=" + id + "&accountId=" + accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  makePrivate: (id, accountId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      THING_LINK.makePrivate + "?id=" + id + "&accountId=" + accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
