import { IconButton } from "@material-ui/core";
import { VisibilityOff } from "@material-ui/icons";
import ThingsHelpIcon from "components/icons/ThingsHelpIcon";
import ThingsSteps from "features/tutorial/ThingsSteps";
import TutorialElement from "features/tutorial/TutorialElement";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

export default function ThingsImageHelp(props) {
  const { value } = props;
  const { t } = useTranslation("help", { i18n });

  const stepsRef = useRef();
  const [open, setOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            Etkileşim Noktanıza ait <b>Görsellerin</b> kaydedildiği alandır.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='PHOTOS_ADD']",
        intro: (
          <TutorialElement icon="click">
            <b>Görsel</b> eklemek için tıklayın.
          </TutorialElement>
        ),
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/tutorial/klasor_image.png"
                }
                width="382"
                height="266"
              ></img>
            </div>
            <br />
            Etkileşim noktanızın <b>Görselini</b> ekleyin.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-section='imageArea']",
        intro: (
          <TutorialElement icon="info">
            {t("Etkileşim Noktası görseli ")} <b>“Herkese Kapalı”</b>{" "}
            {t("olarak eklenir.")}
            <br />
          </TutorialElement>
        ),
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/tutorial/thingImages.png"
                }
                width="404"
                height="101"
              ></img>
            </div>
            Görselin sağ alt köşesindeki <VisibilityOff /> simgesine tıklayın.
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        intro: (
          <TutorialElement icon="info">
            {t(
              "Artık Kare Kod’u okutan müşterinize Görselinizi paylaşmaya hazırsınız."
            )}
            <br />
            <br />
            <b>ÖNEMLİ NOT : </b>
            <br />
            Görselin paylaşımı için{" "}
            <b>Hesap Yönetimi {">"} Hesap Seçenekleri</b> alt menüsü{" "}
            <b>Etkileşim Sayfası Seçenekleri</b> kısmındaki <b>Görsel</b>{" "}
            düğmesinin aktif olduğundan emin olun.
          </TutorialElement>
        ),
      },
    ]);
  }, []);

  return (
    <>
      {open && (
        <ThingsSteps
          code={value}
          steps={steps}
          ref={stepsRef}
          onStepChange={setStepIndex}
          afterComplete={() => setOpen(false)}
        />
      )}
      <IconButton
        aria-label="help"
        onClick={() => setOpen(true)}
        title={t("HELP")}
      >
        <ThingsHelpIcon />
      </IconButton>
    </>
  );
}

ThingsImageHelp.propTypes = {
  value: PropTypes.string,
};
