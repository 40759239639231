import { Grid } from "@material-ui/core";
import CustomerThingAutoComplete from "components/autocomplete/CustomerThingAutoComplete";
import IssueClassByThingClassAutoComplete from "components/autocomplete/IssueClassByThingClassAutoComplete";
import DynamicFieldsEdit from "components/dynamicFieldsEdit";
import i18n from "i18n";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { DynamicFormService } from "services/dynamicForm/dynamicFormService";
import { GpsLocationService } from "services/location/GpsLocationService";
import { ReportService } from "services/report/reportService";

const IssueEdit = forwardRef((props, ref) => {
  const { onSave, value } = props;
  const { t } = useTranslation("issue", { i18n });
  const location = useLocation();
  const dispatch = useDispatch();
  const faDialog = useDialog();
  //   const classes = useStyles();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const userInfo = useSelector(getUserInfo);

  const [loading, setLoading] = useState(false);
  const accountId = localStorage.beneficiaryAccountId;
  const [thing, setThing] = useState(null);
  const [files, setFiles] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dynamicFormList, setDynamicFormList] = useState([]);
  const [dynamicValues, setDynamicValues] = useState([]);

  const newIssue = () => {
    return {
      thing: null,
      notifier: userInfo?.fullname,
      account: { id: accountId },
      description: null,
      issueClass: null,
      email: userInfo?.email,
      phone: userInfo?.phone,
      gpsLocation: null,
      verificationType: null, // ?
      visitorId: null, // ?
      membership: true,
    };
  };
  const [issue, setIssue] = useState(newIssue());

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsDialogOpen(true);
    },
  }));

  useEffect(() => {
    if (location.state && location.state.publicThing) {
      GpsLocationService.getLocation(getLocationOnSuccess, () => {});
      setLoading(false);
    }
  }, []);

  const getLocationOnSuccess = (data) => {
    setIssue((prev) => {
      return { ...prev, gpsLocation: JSON.stringify(data) };
    });
  };

  const onDynamicValuesChange = (values) => {
    setDynamicValues(values);
  };

  const handleChangeV = (prop, val) => {
    setIssue({ ...issue, [prop]: val });
  };

  const saveSuccess = (data) => {
    onSave();
    showAlert(!issue.id ? t("SAVED") : t("EDITED"), "success");
    setIsDialogOpen(false);
    setIssue(newIssue());
    setDynamicFormList([]);
    setDynamicValues([]);
    setLoading(false);
    onSave();
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const save = () => {
    setLoading(true);
    ReportService.reportIssue(issue, files, saveSuccess, saveError);
  };

  const findDynamicFields = (newIssueClass) => {
    if (newIssueClass && newIssueClass.id) {
      DynamicFormService.findFormByName(
        "ISSUE_CLASS",
        accountId,
        newIssueClass.id,
        setDynamicFormList,
        (error) => {
          setDynamicFormList(null);
        }
      );
    } else {
      setDynamicFormList(null);
    }
  };

  return (
    <>
      <FaDialog
        isDocumentUploadOn
        faFiles={files}
        faSetFiles={setFiles}
        documentUploadText="Görsel Yükle"
        title={t("ISSUE_EDIT")}
        faOpen={isDialogOpen}
        faOnSubmit={save}
        loading={loading}
        showSaveButton
        faHandleClose={(data) => {
          setIsDialogOpen(false);
          setIssue(newIssue());
          setDynamicFormList([]);
          setDynamicValues([]);
          setFiles([]);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <FaInput
              label={t("NOTIFIER")}
              required
              onChange={(event) =>
                handleChangeV("notifier", event.target.value)
              }
              value={issue.notifier || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomerThingAutoComplete
              required
              accountId={accountId}
              value={issue?.thing || null}
              onChange={(val) => {
                handleChangeV("thing", val);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <IssueClassByThingClassAutoComplete
              value={issue.issueClass}
              required={true}
              accountId={accountId}
              thingClassId={issue?.thing?.thingClass?.id}
              onChange={(data) => {
                handleChangeV("issueClass", data);
                findDynamicFields(data);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              label={t("DESCRIPTION")}
              faType="description"
              inputProps={{ maxLength: 255 }}
              onChange={(event) =>
                handleChangeV("description", event.target.value)
              }
              value={issue.description || ""}
            />
          </Grid>
          {dynamicFormList && dynamicFormList.length > 0 && (
            <Grid item xs={12}>
              <DynamicFieldsEdit
                selectedDetails={dynamicFormList}
                details={dynamicFormList}
                data={dynamicValues}
                onValuesChange={onDynamicValuesChange}
              />
            </Grid>
          )}
          {/* {announcement.id > 0 && (
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button onClick={announcementDelete} color="secondary">
                Sil
              </Button>
            </Grid>
          )} */}
        </Grid>
      </FaDialog>
    </>
  );
});

export default IssueEdit;
