import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import i18n from "../../../i18n";
import { useStyles } from "../style";
import ThingsSteps from "../ThingsSteps";
import TutorialElement from "../TutorialElement";

export default function CustomerInteractionDashboardTutorial() {
  const { code } = useParams();

  const stepsRef = useRef();

  const { t } = useTranslation("tutorialCouponDetail", { i18n });
  const classes = useStyles();
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            <b>Müşteri Etkileşimleri</b>’nin, Sayı, Dağılım ve Trendlerinin{" "}
            <b>Liste, Grafik, Diyagram</b> olarak ve ayrıca
            <b>Takvim, Harita ve İç mekân</b> üzerinde gösterildiği
            Menü/Sayfadır.
            <p>
              <ul>
                <li>
                  <b>Toplam, Günlük, Haftalık ve Aylık</b> Etkileşim Sayılarını
                  ve değişimlerini izleyebilirsiniz.
                </li>
                <li>
                  <b>Etkileşim Takvimi</b> üzerinde Günlük Etkileşimleri
                  izleyebilirsiniz.
                </li>
                <li>
                  Etkileşimleri konumsal olarak <b>Harita</b> ve <b>İç Mekân</b>{" "}
                  üzerinde izleyebilirsiniz.
                </li>
                <li>
                  <b>Popüler Etkileşimleri</b> Liste olarak izleyebilirsiniz.
                </li>
                <li>
                  Günün saatine göre etkileşim sayılarını grafiksel olarak
                  izleyebilirsiniz.
                </li>
                <li>
                  <b>Etkileşim dağılımını</b> diyagram olarak izleyebilirsiniz.
                </li>
                <li>
                  <b>Etkileşim Trendlerini</b> grafiksel olarak
                  izleyebilirsiniz.
                </li>
                <li>
                  <b>QR Okutma Sayılarının</b> Etkileşim Noktalarına Göre
                  Dağılımını diyagram olarak izleyebilirsiniz.
                </li>
                <li>
                  Etkileşim Noktalarının <b>Etkileşim Tipine Göre</b> Dağılımını
                  diyagram olarak izleyebilirsiniz.
                </li>
              </ul>
            </p>
          </TutorialElement>
        ),
        tooltipClass: classes.customerInteractionDashboard,
      },
      {
        element: "[data-fa-section='TOTAL_INTERACTION_COUNT']",
        intro: (
          <TutorialElement icon="info">
            Toplam, Günlük, Haftalık ve Aylık Etkileşim Sayılarını ve
            değişimlerini izleyin.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='Harita']",
        intro: (
          <TutorialElement icon="info">
            Etkileşim sayılarını konumsal olarak İç Mekanlar üzerinde izleyin.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-section='CUSTOMER_INTERACTION_MAP_CARD']",
        intro: (
          <TutorialElement icon="info">
            Etkileşim sayılarını konumsal olarak Harita üzerinde izleyin.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-section='CUSTOMER_INTERACTION_DAILY_HEAT_MAP']",
        intro: (
          <TutorialElement icon="info">
            Etkileşim Takvimi üzerinde Günlük Etkileşimleri izleyin.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-section='TOP_INTERACTIONS']",
        intro: (
          <TutorialElement icon="info">
            Popüler Etkileşimleri izleyin.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-section='CUSTOMER_INTERACTION_DAY_TIME_HEATMAP']",
        intro: (
          <TutorialElement icon="info">
            Günün saatine göre etkileşim sayılarını izleyin.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-section='INTERACTION_COUNTS_BY_OPERATION_CHART']",
        intro: (
          <TutorialElement icon="info">
            Etkileşim dağılımını izleyin.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-section='INTERACTION_COUNT_BY_DATE']",
        intro: (
          <TutorialElement icon="info">
            Etkileşim trendlerini izleyin.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-section='INTERACTION_COUNTS_BY_SCAN_QR']",
        intro: (
          <TutorialElement icon="info">
            QR Okutma Sayılarının Etkileşim Noktalarına Göre Dağılımını izleyin.
          </TutorialElement>
        ),
      },
      {
        element:
          "[data-fa-section='INTERACTION_COUNTS_BY_OPERATION_AND_DETAILS']",
        intro: (
          <TutorialElement icon="info">
            Etkileşim Noktalarının Etkileşim Tipine Göre Dağılımını izleyin.
          </TutorialElement>
        ),
      },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={"customer_interaction_dashboard"}
        steps={steps}
        ref={stepsRef}
        onStepChange={setStepIndex}
      />
    </div>
  );
}
