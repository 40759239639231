import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { PROJECT } from "./faApi";

export const ProjectService = {
  save: (project, callback, errorCallback) => {
    let reqBody = {
      ...project,
    };

    let req = requests.getSimplePostRequest(PROJECT.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  delete: (project, callback, errorCallback) => {
    let reqBody = {
      ...project,
    };

    let req = requests.getSimplePostRequest(PROJECT.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(PROJECT.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findUsersByProject: (projectId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      PROJECT.findUsersByProject + "?projectId=" + projectId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  saveUser: (user, callback, errorCallback) => {
    let reqBody = {
      ...user,
    };

    let req = requests.getSimplePostRequest(PROJECT.saveUser, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  deleteUser: (user, callback, errorCallback) => {
    let reqBody = {
      ...user,
    };

    let req = requests.getSimplePostRequest(PROJECT.deleteUser, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },

  findThingsByProject: (projectId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      PROJECT.findThingsByProject + "?projectId=" + projectId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  saveThing: (thing, callback, errorCallback) => {
    let reqBody = {
      ...thing,
    };

    let req = requests.getSimplePostRequest(PROJECT.saveThing, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  deleteThing: (thing, callback, errorCallback) => {
    let reqBody = {
      ...thing,
    };

    let req = requests.getSimplePostRequest(PROJECT.deleteThing, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
};
