import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import ThingsDataTable from "components/thingsDataTable";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { CampaignService } from "services/CampaignService";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import CampaignAwardEdit from "./CampaignAwardEdit";
import { useStyles } from "./style";

export default function CampaignAwards(props) {
  const { campaign } = props;
  const accountId = localStorage.accountId;
  const { t } = useTranslation("campaign", { i18n });
  const { id } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef();
  const dialogRef = useRef();
  const faDialog = useDialog();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedAward, setSelectedAward] = useState(false);
  const [awards, setAwards] = useState();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    refresh();
  }, [campaign]);

  const columns = [
    {
      field: "name",
      label: t("NAME"),
      align: "center",
      width: "50%",
    },
    {
      field: "amount",
      label: t("AMOUNT"),
      align: "center",
      width: "50%",
    },
    {
      field: "id",
      label: t("OPERATION"),
      align: "center",
      width: "20%",
      valueFormatter: (value, row) => {
        return (
          <IconButton
            disabled={campaign.status !== "DRAFT"}
            onClick={() => deleteAward(row)}
            size="small"
            color="primary"
            aria-label={t("SIL")}
          >
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        );
      },
    },
  ];

  const refresh = () => {
    setLoading(true);
    CampaignService.findAwards(id, findAwardOnSuccess, errorCallback);
  };
  const findAwardOnSuccess = (data) => {
    setAwards(data);
    setLoading(false);
  };

  const errorCallback = (error) => {
    showAlert(error, "error");
    setLoading(false);
  };

  const onDoubleClick = (row) => {
    if (campaign.status === "DRAFT") {
      setSelectedAward(row);
      setIsDialogOpen(true);
    }
  };

  const deleteAward = (award) => {
    faDialog({
      description: t(t("Ödülü silmek istediğinize emin misiniz?")),
      type: "confirm",
    })
      .then((confirmation) => {
        CampaignService.deleteAward(
          award,
          (data) => {
            refresh();
          },
          errorCallback
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const editDialogRender = () => {
    return (
      <CampaignAwardEdit
        campaign={campaign}
        open={isDialogOpen}
        onSave={() => {
          refresh();
          setSelectedAward(null);
        }}
        selectedAward={selectedAward}
        onClose={() => {
          setIsDialogOpen(false);
          setSelectedAward(null);
        }}
      ></CampaignAwardEdit>
    );
  };
  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {editDialogRender()}
        <Card>
          <CardHeader
            title={t("CAMPAIGN_AWARDS")}
            data-fa-section="CAMPAIGN_AWARDS"
            action={
              <IconButton
                data-fa-button="CAMPAIGN_AWARDS_ADD"
                disabled={campaign.status !== "DRAFT"}
                aria-label="settings"
                onClick={() => setIsDialogOpen(true)}
              >
                <AddIcon />
              </IconButton>
            }
          />
          <ThingsDataTable
            data={awards}
            columns={columns}
            ref={tableRef}
            showDeleteButton={false}
            showHeaderText={false}
            headerText={t("AWARDS")}
            showPaging={false}
            faOnDoubleClick={onDoubleClick}
          />
        </Card>
      </Suspense>
    </>
  );
}
