import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { Add } from "@material-ui/icons";
import ThingsDataTable from "components/thingsDataTable";
import ControlItemEdit from "features/controlItem/ControlItemEdit";
import React, { useEffect, useRef, useState } from "react";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ControlItemService } from "services/controlItem/controlItemService";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function PatrolDefControlItems(props) {
  const { patrolDef } = props;
  const faDialog = useDialog();
  const classes = useStyles();
  let history = useHistory();

  const { t } = useTranslation(["patrolDef", "thingClass"], { i18n });
  const tableRef = useRef();

  const accountId = localStorage.accountId;
  const [loading, setLoading] = useState(false);
  const [isControlItemDialogOpen, setIsControlItemDialogOpen] = useState(false);
  const [selectedControlItem, setSelectedControlItem] = useState(null);

  const [controlItemList, setControlItemList] = useState([]);

  useEffect(() => {
    if (patrolDef && accountId && patrolDef.id > 0) {
      refresh();
    }
  }, [patrolDef, accountId]);

  const refresh = () => {
    ControlItemService.findByPatrolDef(
      patrolDef.id,
      accountId,
      (data) => {
        setControlItemList(data.list);
      },
      (error) => {}
    );
  };

  const onEdit = (row) => {
    if (patrolDef.status !== "ACTIVE") {
      setSelectedControlItem(row);
      setIsControlItemDialogOpen(true);
    }
  };

  const columns = [
    { field: "orderNo", label: t("ORDER_NO"), align: "center", width: "5%" },
    {
      field: "question",
      label: t("question"),
      align: "center",
      width: "35%",
    },
    {
      field: "itemType",
      label: t("itemType"),
      align: "center",
      width: "40%",
      valueFormatter: (value) => {
        return t(value, { ns: "thingClass" });
      },
    },
    {
      field: "documentAttachmentType",
      label: t("documentAttachmentType"),
      align: "center",
      width: "40%",
      valueFormatter: (value) => {
        return t(value, { ns: "thingClass" });
      },
    },
    {
      field: "id",
      label: t("OPERATION"),
      align: "center",
      width: "20%",
      valueFormatter: (value, row) => {
        return (
          <>
            <IconButton
              onClick={() => onEdit(row)}
              size="small"
              color="primary"
              aria-label={t("SIL")}
              disabled={patrolDef.status === "ACTIVE"}
            >
              <FontAwesomeIcon icon={faEdit} />
            </IconButton>
          </>
        );
      },
    },
  ];

  const thingAddButtonClicked = () => {
    setSelectedControlItem(null);
    setIsControlItemDialogOpen(true);
  };

  const controlItemOnChange = () => {
    refresh();
    setIsControlItemDialogOpen(false);
  };

  return (
    <>
      <ControlItemEdit
        onChange={controlItemOnChange}
        open={isControlItemDialogOpen}
        patrolDef={patrolDef}
        value={selectedControlItem}
        onClose={() => {
          setIsControlItemDialogOpen(false);
        }}
      />
      <Grid item lg={12} xs={12}>
        <Card>
          <CardHeader
            action={
              <IconButton
                aria-label="settings"
                onClick={thingAddButtonClicked}
                disabled={patrolDef.status === "ACTIVE"}
              >
                <Add />
              </IconButton>
            }
            title={t("PATROL_CONTROL_ITEMS")}
            data-fa-section="PATROL_CONTROL_ITEMS"
          />
          <ThingsDataTable
            data={controlItemList}
            columns={columns}
            ref={tableRef}
            showDeleteButton={false}
            showHeaderText={false}
            headerText={t("Kontrol Soruları")}
            showPaging={false}
            showHeader={false}
          />
        </Card>
      </Grid>
    </>
  );
}
