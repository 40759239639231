import Grid from "@material-ui/core/Grid";
import AdditionalFieldsCard from "features/additionalFields/AdditionalFieldsCard";
import React, { useEffect, useState } from "react";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getAccountId } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { LikeService } from "services/LikeService";
import i18n from "../../i18n";
import LikeDetailCard from "./LikeDetailCard";
import { useStyles } from "./style";

export default function LikeDetail() {
  const { t } = useTranslation("like", { i18n });
  const { id } = useParams();
  const accountId = useSelector(getAccountId);
  const classes = useStyles();
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  const [loading, setLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState("");
  const [like, setLike] = useState({});

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    setLoading(true);
    LikeService.findById(
      id,
      (data) => {
        setLike(data);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  return (
    <>
      <Grid item container spacing={1}>
        <Grid item xs={12} lg={12}>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <LikeDetailCard like={like} />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <AdditionalFieldsCard
              classId={like?.likeClass?.id}
              className="LIKE_CLASS"
              isEditable={false}
              objectId={like?.id}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
