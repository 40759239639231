import { Steps } from "intro.js-react";
import React, { forwardRef, useState } from "react";
import { UserTutorialService } from "services/UserTutorialService";

const ThingsSteps = forwardRef((props, ref) => {
  const { code, afterComplete, ...other } = props;
  const [stepsEnabled, setStepsEnabled] = useState(true);

  const complete = () => {
    UserTutorialService.complete(
      code,
      (data) => {},
      (error) => {}
    );
    if (afterComplete != null) afterComplete();
  };

  return (
    <Steps
      enabled={stepsEnabled}
      initialStep={0}
      ref={ref}
      onExit={() => {
        setStepsEnabled(false);
      }}
      options={{
        exitOnOverlayClick: false,
        exitOnEsc: false,
        nextLabel: "İleri",
        prevLabel: "Geri",
        doneLabel: "Tamamla",
        // ...props.otherOptions,
      }}
      onComplete={complete}
      onBeforeChange={(nextStepIndex, nextElement) => {
        if (props.onStepChange) props.onStepChange(nextStepIndex);

        if (props.steps && props.steps[nextStepIndex].onBeforeChange)
          props.steps[nextStepIndex].onBeforeChange(nextStepIndex);

        return true;
      }}
      onAfterChange={(nextStepIndex, nextElement) => {
        if (props.steps && props.steps[nextStepIndex].onAfterChange)
          props.steps[nextStepIndex].onAfterChange(nextStepIndex);

        return true;
      }}
      {...other}
    />
  );
});
export default ThingsSteps;
