import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function SlaMeasurementSelect(props) {
  const { value, onChange, required } = props;
  const classes = useStyles();
  const { t } = useTranslation("slaContract", { i18n });

  return (
    <FormControl variant="outlined" className={classes.w100} size="small">
      <InputLabel>{t("SLA_MEASUREMENT_METHOD")}</InputLabel>
      <Select
        // classes={{
        //   outlined: classes.padding10,
        // }}
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        required={required}
        label={t("SLA_MEASUREMENT_METHOD")}
      >
        <MenuItem value={null}>...</MenuItem>
        <MenuItem value={"ISSUE_REPORT_TO_ISSUE_CLOSE"}>
          {t("ISSUE_REPORT_TO_ISSUE_CLOSE")}
        </MenuItem>
        <MenuItem value={"TASK_ASSIGN_TO_TASK_FINISH"}>
          {t("TASK_ASSIGN_TO_TASK_FINISH")}
        </MenuItem>
        <MenuItem value={"TASK_ASSIGN_TO_ISSUE_CLOSE"}>
          {t("TASK_ASSIGN_TO_ISSUE_CLOSE")}
        </MenuItem>
      </Select>
    </FormControl>
  );
}
