import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import DateRangeSelector from "components/dateRangeSelector";
import RightBarDrawer from "components/rigthBarDrawer";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { FaButton, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { getDateTimeWFormat, qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "serviceWorker";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { THING } from "../../services/faApi";
import ThingCreate from "./ThingCreate";
import { useStyles } from "./style";
import "./things.css";

export default function ThingsFood() {
  const { t } = useTranslation(["things", "errorMessages"], { i18n });
  const tableRef = useRef();
  const dialogRef = useRef();
  let history = useHistory();
  let queryParams = qsParse(history.location.search);
  queryParams["accountId"] = localStorage.accountId;

  const accountId = localStorage.accountId;
  const [selectedRows, setSelectedRows] = useState([]);
  const [thingDTO, setThingDTO] = useState({
    description: "",
    status: "ACTIVE",
    serviceStatus: "IN_SERVICE",
    thingcode: "",
    account: "",
    thid: "",
    thingClass: "",
    hashtag: "",
    vendor: null,
  });

  const [searchForm, setSearchForm] = useState({
    thingClass: null,
    thingClassId: null,
    description: null,
    thingCode: null,
    vendor: null,
    vendorId: null,
    summary: null,
    accountId: accountId,
    status: null,
    createDateStart: null,
    createDateEnd: null,
    images: true,
  });

  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const columns = [
    {
      field: "identifier",
      label: "Teslimat",
      align: "center",
      width: "30%",
      valueFormatter: (value, row) => {
        //if(row.thumbnail != null)
        //FIXME public url haline getirildi
        if (row.defaultThumbnail != null)
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={
                  BASE_URL +
                  THING.getImage +
                  "?thingId=0" +
                  "&objectId=" +
                  row.defaultThumbnail
                }
                style={{ width: "48px", marginRight: "10px" }}
              />{" "}
              {value}
            </div>
          );
        //return <div style={{display: "flex", alignItems: "center"}}><img src={`data:image/png;base64, ${row.thumbnail}`} style={{width: "48px", marginRight: "10px"}} /> {value}</div>
        else
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="square"
                style={{ width: "48px", marginRight: "10px" }}
              >
                {" "}
                {value.charAt(0)}
              </Avatar>{" "}
              {value}
            </div>
          );
      },
    },
    {
      field: "createDate",
      label: "Tarih",
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        return getDateTimeWFormat(value);
      },
    },
    {
      field: "account",
      label: t("OWNER"),
      align: "center",
      width: "30%",
      inner: "fullname",
      valueFormatter: (value) => {
        return value.fullname;
      },
    },
    {
      field: "openIssueCount",
      label: "Bildirimler",
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        if (value && value > 0)
          return (
            <Badge
              badgeContent={value}
              color="secondary"
              style={{ paddingLeft: 12 }}
            ></Badge>
          );
        return <CheckIcon />;
      },
    },
  ];

  useEffect(() => {
    if (selectedRows.length == 1) {
      setThingDTO(selectedRows[0]);
    } else {
      setThingDTO({
        description: "",
        status: "ACTIVE",
        serviceStatus: "IN_SERVICE",
        thingcode: "",
        account: "",
        thid: "",
        thingClass: "",
        hashtag: "",
        vendor: null,
      });
    }
  }, [selectedRows]);

  const onRowEdit = (event, row) => {
    const tmpTo = {
      pathname: `thingDetailFood/${row.id}`,
      breadCrumbKey: `/thingDetailFood/:id`,
      customLabel: row.description,
    };
    history.push(tmpTo);
  };

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `thingDetailFood/${row.id}`,
      breadCrumbKey: `/thingDetailFood/:id`,
      customLabel: row.description,
    };
    history.push(tmpTo);
  };

  const closeDrawer = () => {
    clearSelectedRows();
  };

  const clearSelectedRows = () => {
    tableRef.current.clearSelectedRows();
  };

  const onSelectedChange = (rows) => {
    setSelectedRows(rows);
  };

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const searchClear = () => {
    setSearchForm({
      thingClass: null,
      thingClassId: null,
      description: null,
      thingCode: null,
      vendor: null,
      vendorId: null,
      summary: null,
      accountId: accountId,
      status: null,
      createDateStart: null,
      createDateEnd: null,
      images: true,
    });
  };

  const renderDrawerContent = () => {
    if (selectedRows.length == 1) {
      return (
        <Grid
          item
          container
          className={classes.drawerContent}
          alignContent="space-between"
        >
          <Grid
            item
            xs={11}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {thingDTO.thumbnail && (
              <img
                src={`data:image/png;base64, ${thingDTO.thumbnail}`}
                style={{ width: "48px", marginRight: "2px" }}
              />
            )}
            {!thingDTO.thumbnail && (
              <Avatar
                className={classes.avatar}
                variant="square"
                style={{ width: "48px", marginRight: "2px" }}
              >
                {" "}
                {thingDTO?.identifier?.charAt(0)}
              </Avatar>
            )}

            <Typography>{thingDTO.description}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="caption">{t("THING_CLASS")}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{thingDTO.thingClass.className}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="caption">{t("OWNER")}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>
              {thingDTO.ownerUser && thingDTO.ownerUser.fullname}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="caption">{t("DATE")}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{thingDTO.createDate}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="caption">{t("DOMAIN")}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{thingDTO.thingClass.domain}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="caption">{t("HASHTAG")}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{thingDTO.hashtag}</Typography>
          </Grid>
          <Grid
            item
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={4}>
              <FaButton
                label={t("EXAMINE")}
                required
                className={classes.success}
                onClick={(event) => {
                  onRowEdit(null, thingDTO);
                }}
                value={thingDTO.description || ""}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    } else if (selectedRows.length > 1) {
      return (
        <Grid item container spacing={3} className={classes.drawerContent}>
          <Grid style={{ alignSelf: "flex-end" }} item xs={12}>
            <Typography
              align="center"
              variant="h6"
              component="h6"
              color="textPrimary"
            >
              {t("MULTI_SELECT", { amount: selectedRows.length })}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <FaButton
              label={t("Export")}
              required
              className={classes.success}
              onClick={(event) => console.log("delete")}
              value={thingDTO.description || ""}
            />
          </Grid>
        </Grid>
      );
    }
  };

  const onThingCreate = (data) => {
    const tmpTo = {
      pathname: `thingDetailFood/${data.id}`,
      breadCrumbKey: `/thingDetailFood/:id`,
      customLabel: data.description,
    };
    history.push(tmpTo);
  };

  const newThingRegisterRender = () => {
    return <ThingCreate ref={dialogRef} onCreate={onThingCreate}></ThingCreate>;
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {newThingRegisterRender()}
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={2}>
            {/* <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                clearSelectedRows();
                dialogRef.current.openDialog();
              }}
              startIcon={<Add />}
            >
              {t("ADD")}
            </FaButton> */}
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
            >
              <FaInput
                label={t("DESCRIPTION")}
                value={searchForm.description}
                onChange={(event) => {
                  setSearchForm({
                    ...searchForm,
                    description: event.target.value,
                  });
                }}
              />
              <DateRangeSelector
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    createDateStart: data.startDate,
                    createDateEnd: data.endDate,
                  });
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
        <RightBarDrawer open={selectedRows.length > 0} onClose={closeDrawer}>
          {renderDrawerContent()}
        </RightBarDrawer>

        {
          <ThingsDataTable
            searchUrl={THING.search + "?accountId=" + localStorage.accountId}
            searchForm={searchForm}
            columns={columns}
            ref={tableRef}
            onSelectedChange={onSelectedChange}
            showDeleteButton={false}
            showHeaderText={false}
            headerText={t("VARLIK_LISTESI")}
            faOnDoubleClick={onDoubleClick}
          />
        }
      </Suspense>
    </>
  );
}
