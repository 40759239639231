import { Button, CardHeader, Checkbox, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import BackupIcon from "@material-ui/icons/Backup";
import IssueClassByThingClassAutoComplete from "components/autocomplete/IssueClassByThingClassAutoComplete";
import ThingSelector from "components/selector/ThingSelector";
import React, { useState } from "react";
import {
  FaButton,
  FaDialog,
  FaFileUpload,
  FaInput,
} from "react-base-fa/dist/fa";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IssueService } from "services/issue/issueService";
import { ThingService } from "services/thing/thingService";
import { isTNF } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../../i18n";

export default function HomepageIssueReportForm(props) {
  const { size } = props;
  const { t } = useTranslation(["issue", "components"], { i18n });
  const userInfo = useSelector(getUserInfo);
  const accountId = localStorage.accountId;
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const labelClasses = labelStyles();

  const newIssue = () => {
    return {
      thing: null,
      description: "",
      user: {},
      notificationDate: "",
      status: "",
      notifier: userInfo?.fullname,
      hashtag: "",
      issueClass: {},
      noThing: false,
    };
  };
  const [issueDTO, setIssueDTO] = useState(newIssue());
  const [files, setFiles] = useState([]);
  const [tempFiles, setTempFiles] = useState([]);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const handleChange = (prop, val) => {
    setIssueDTO({ ...issueDTO, [prop]: val });
  };

  const noThingOnCheck = (checked) => {
    if (checked) {
      ThingService.getSystemThing(
        accountId,
        (data) => {
          setIssueDTO((prev) => {
            return { ...prev, thing: data };
          });
        },
        (error) => {}
      );
    } else {
      setIssueDTO((prev) => {
        return { ...prev, thing: null };
      });
    }
  };

  const saveIssue = () => {
    let data = {
      ...issueDTO,
      status: 0,
      user: userInfo,
      account: { id: accountId },
      notificationDate: new Date(),
    };
    IssueService.save(data, tempFiles, onSaveSuccess, (error) => {});
  };

  const onSaveSuccess = (data) => {
    const tmpTo = {
      pathname: `/issues/detail/${data?.id}`,
      breadCrumbKey: `/issues/detail/:id`,
    };
    history.push(tmpTo);
  };

  const renderEditDialog = () => {
    return (
      <FaDialog
        title={t("Dosya Ekle")}
        loading={loading}
        showSaveButton
        faOnSubmit={() => {
          setIsEditDialogOpen(false);
        }}
        faOpen={isEditDialogOpen}
        faHandleClose={() => {
          setIsEditDialogOpen(false);
          setFiles([]);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <FaFileUpload
              maxFileSize={20000000}
              onChange={(data) => {
                if (data != null && data.length > 0) {
                  setFiles(data);
                  let documents = tempFiles;
                  documents.push(data[0]);
                  setTempFiles(documents);
                  console.log(data);
                } else setFiles(null);
              }}
              initialFiles={files}
              filesLimit={1}
            />
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  return (
    <>
      {renderEditDialog()}
      <Grid item lg={size} xs={12}>
        <Card>
          <CardHeader title={t("components:HOMEPAGE_ITEM.ISSUE_REPORT_FORM")} />
          <CardContent>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Grid item xs={12}>
                <FaInput
                  label={t("NOTIFIER")}
                  required
                  onChange={(event) =>
                    handleChange("notifier", event.target.value)
                  }
                  value={issueDTO.notifier || ""}
                />
              </Grid>
              {!issueDTO.noThing && (
                <Grid item xs={12}>
                  <ThingSelector
                    required={true}
                    value={issueDTO.thing}
                    accountId={accountId}
                    onChange={(data) => {
                      setIssueDTO({
                        ...issueDTO,
                        thing: data,
                      });
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={issueDTO.noThing}
                      onChange={(event) => {
                        setIssueDTO({
                          ...issueDTO,
                          noThing: event.target.checked,
                        });
                        noThingOnCheck(event.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label={isTNF(t("NO_THING_ISSUE"), t("NO_THING_ISSUE_T2C"))}
                />
              </Grid>
              <Grid item xs={12}>
                <IssueClassByThingClassAutoComplete
                  value={issueDTO.issueClass}
                  required={true}
                  accountId={accountId}
                  thingClassId={issueDTO.thing?.thingClass?.id}
                  onChange={(data) => {
                    setIssueDTO({
                      ...issueDTO,
                      issueClass: data,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FaInput
                  label={t("DESCRIPTION")}
                  faType="description"
                  inputProps={{ maxLength: 255 }}
                  onChange={(event) =>
                    handleChange("description", event.target.value)
                  }
                  value={issueDTO.description || ""}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<BackupIcon />}
                  onClick={() => {
                    setIsEditDialogOpen(true);
                  }}
                >
                  {t("UPLOAD_FILE")}
                </Button>
              </Grid>
              <Grid container row item xs={9}>
                {tempFiles?.map((file, index) => (
                  <Typography
                    className={labelClasses.LabelValue}
                    style={{ marginRight: 10 }}
                  >
                    {file.file.name}
                  </Typography>
                ))}
              </Grid>
              <Grid xs={12}>
                <FaButton
                  variant="contained"
                  color="primary"
                  size="large"
                  faClick={() => {
                    saveIssue();
                  }}
                  startIcon={<AddIcon />}
                  data-fa-button="REPORT_ISSUE"
                >
                  {t("Yeni Sorun Bildir")}
                </FaButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
