import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import GetAppIcon from "@material-ui/icons/GetApp";
import QRCode from "qrcode.react";
import { default as React, useRef } from "react";
import FaButton from "react-base-fa/dist/fa/faButton";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import i18n from "../../i18n";
import { THING } from "../../services/faApi";
import { useStyles } from "./ThingDetailCardStyle";
import ThingOwnerEdit from "./ThingOwnerEdit";

export default function ThingQR(props) {
  const { thing, onUpdate } = props;
  const { t } = useTranslation("thingDetail", { i18n });
  const classes = useStyles();
  const history = useHistory();
  const dialogThingOwnerEditRef = useRef();
  const dialogThingFolderEditRef = useRef();

  const updateStatus = (newStatus) => {
    let req = requests.getSimplePostRequest(THING.update, {
      ...thing,
      status: newStatus,
    });
    FaAxios(req)
      .then((response) => {
        history.go(0);
      })
      .catch((error) => {
        console.log("error->", error);
      });
  };

  const deleteThing = () => {
    console.log("delete");
    //TODO
    /*var req = requests.getSimplePostRequest(THING.delete, thing);
    FaAxios(req)
      .then((response) => {
        const tmpTo = {
          pathname: `/things?page=0&size=10&isAsc=true&sort=description`
        };
        history.push(tmpTo);
      })
      .catch((error) => {
        console.log("err", error);
      });*/
  };

  const thingOwnerEditDialogRender = () => {
    return (
      <ThingOwnerEdit
        thing={thing}
        ref={dialogThingOwnerEditRef}
        onUpdate={onUpdate}
      ></ThingOwnerEdit>
    );
  };

  const openThingOwnerEditDialog = () => {
    dialogThingOwnerEditRef.current.openDialog();
  };

  const thingFolderEditDialogRender = () => {
    return <></>;
  };

  const openThingFolderEditDialog = () => {
    dialogThingFolderEditRef.current.openDialog();
  };

  const renderStatusChange = () => {
    if (thing.status === "UNREGISTERED") {
      return (
        <Grid container item lg={12} justifyContent="space-around">
          <FaButton
            style={{ width: "40%" }}
            variant="outlined"
            size="small"
            color="primary"
            faClick={() => updateStatus("ACTIVE")}
          >
            {t("REGISTER")}
          </FaButton>
          <FaButton
            style={{ width: "40%" }}
            variant="outlined"
            size="small"
            color="secondary"
            faClick={() => deleteThing()}
          >
            {t("DELETE")}
          </FaButton>
        </Grid>
      );
    } else if (thing.status === "ACTIVE") {
      return (
        <Grid item lg={12} style={{ textAlign: "center" }}>
          <FaButton
            style={{ width: "70%" }}
            variant="outlined"
            size="small"
            color="primary"
            faClick={() => updateStatus("INACTIVE")}
          >
            {t("INACTIVATE")}
          </FaButton>
        </Grid>
      );
    } else if (thing.status === "INACTIVE") {
      return (
        <Grid item lg={12} style={{ textAlign: "center" }}>
          <FaButton
            style={{ width: "70%" }}
            variant="outlined"
            size="small"
            color="primary"
            faClick={() => updateStatus("ACTIVE")}
          >
            {t("ACTIVATE")}
          </FaButton>
        </Grid>
      );
    }
  };

  return (
    <>
      <Grid
        item
        container
        spacing={1}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item lg={12}>
          <Typography
            noWrap={false}
            style={{
              wordWrap: "break-word",
              margin: 10,
              textAlign: "center",
              fontSize: 8,
            }}
          >
            {thing?.thid}
          </Typography>
        </Grid>
        <Grid item lg={12} style={{ textAlign: "center" }}>
          <QRCode
            id="thingQr"
            value={thing.thidUrl ? thing.thidUrl : ""}
            renderAs="svg"
          />
        </Grid>
        <Grid item lg={12} style={{ textAlign: "center" }}>
          <FaButton
            style={{ width: "40%" }}
            startIcon={<GetAppIcon />}
            variant="outlined"
            size="small"
            color="primary"
          >
            {t("CREATE_QR")}
          </FaButton>
        </Grid>

        <Grid item lg={12}>
          <table
            width="100%"
            style={{ borderSpacing: "0px", paddingLeft: "10px" }}
          >
            <tr>
              <td width="25%" className={classes.ThingDetailLabelHeader}>
                {t("OWNER_NAME")}
              </td>
              <td width="65%" className={classes.ThingDetailLabelHeader}>
                {thing?.ownerUser?.fullname}
              </td>
              <td width="10%" className={classes.ThingDetailLabelHeader}>
                <IconButton
                  size="small"
                  style={{ right: 0 }}
                  onClick={() => {
                    openThingOwnerEditDialog();
                  }}
                >
                  <EditIcon fontSize="small" style={{ fontSize: "16px" }} />
                </IconButton>
              </td>
            </tr>
          </table>
        </Grid>
      </Grid>
      {thingOwnerEditDialogRender()}
      {thingFolderEditDialogRender()}
    </>
  );
}
