import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import React, { Suspense, useEffect, useState } from "react";
import { getUserInfo, setRole } from "react-base-fa/dist/services/sessionSlice";
import { useDispatch, useSelector } from "react-redux";
import { AccUserService } from "services/AccUserService";
import LoadingBar from "../../components/loadingBar";

export default function SuccessResult(props) {
  const { paymentResult } = props;
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();

  const userInfo = useSelector(getUserInfo);

  const accountId = localStorage.accountId;

  useEffect(() => {
    setOpen(true);

    AccUserService.findAccUserRole(
      accountId,
      userInfo.id,
      findAccUserRoleOnSuccess,
      () => {}
    );
  }, [paymentResult]);

  const findAccUserRoleOnSuccess = (data) => {
    dispatch(setRole(data));
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Dialog
          open={open}
          onClose={() => {
            window.location.reload();
            //setOpen(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Ödemeniz Alındı"}</DialogTitle>
          <DialogContent>
            <div
              style={{
                backgroundColor: "green",
                padding: "16px",
                border: "1px solid #cfdae0",
                borderRadius: "4px",
              }}
            >
              Ödemeniz başarıyla alınmıştır.
            </div>
          </DialogContent>
        </Dialog>
      </Suspense>
    </>
  );
}
