import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { ThingStatisticService } from "services/statistic/statisticService";
import { getDateTimeWFormat } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";

const FirstAndLastInteractions = forwardRef((props, ref) => {
  const { ...other } = props;
  const { t } = useTranslation(["customerInteraction", "components"], { i18n });
  const labelClasses = labelStyles();

  const [firstInteraction, setFirstInteraction] = useState(null);
  const [lastInteraction, setLastInteraction] = useState(null);

  useImperativeHandle(ref, () => ({
    refreshTable(searchForm) {
      if (searchForm)
        ThingStatisticService.getFirstAndLastInteraction(
          searchForm,
          onSuccess,
          (error) => {}
        );
    },
  }));

  const onSuccess = (data) => {
    if (data && data.length > 0) {
      data.map((item) => {
        if (item?.firstInteractionDate)
          setFirstInteraction({
            operation: item.operation,
            date: item?.firstInteractionDate,
          });
        else if (item?.lastInteractionDate)
          setLastInteraction({
            operation: item.operation,
            date: item?.lastInteractionDate,
          });
      });
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={6}>
                  {firstInteraction && (
                    <>
                      <Typography className={labelClasses.LabelHeader}>
                        {t("FIRST_INTERACTION")}
                      </Typography>
                      <Typography style={{ fontSize: "14px" }}>
                        {t(
                          "CUSTOMER_INTERACTION_OPERATION." +
                            firstInteraction.operation,
                          {
                            ns: "components",
                          }
                        )}{" "}
                        - {getDateTimeWFormat(firstInteraction.date)}
                      </Typography>
                    </>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {lastInteraction && (
                    <>
                      <Typography className={labelClasses.LabelHeader}>
                        {t("LAST_INTERACTION")}
                      </Typography>
                      <Typography style={{ fontSize: "14px" }}>
                        {t(
                          "CUSTOMER_INTERACTION_OPERATION." +
                            lastInteraction.operation,
                          {
                            ns: "components",
                          }
                        )}{" "}
                        - {getDateTimeWFormat(lastInteraction.date)}
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
});
export default FirstAndLastInteractions;
