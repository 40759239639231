import { Avatar } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ThingsDataTable from "components/thingsDataTable";
import i18n from "i18n";
import { default as React, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { BENEFICIARY_PORTAL } from "services/faApi";
import { getDateTimeWFormat } from "services/utils";
import { useStyles } from "./style";

export default function MyOffers(props) {
  const { coupon } = props;
  const accountId = localStorage.accountId;
  const { t } = useTranslation(["beneficiaryPortal", "report"], { i18n });
  let history = useHistory();
  const tableRef = useRef();
  const classes = useStyles();
  const newSearchForm = () => {
    return {
      accountId: localStorage.beneficiaryAccountId,
      sendStatus: "YES",
    };
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const columns = [
    {
      field: "sendDate",
      label: t("Tanım Tarihi"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        return getDateTimeWFormat(value);
      },
    },
    {
      field: "coupon",
      label: t("Kupon"),
      align: "center",
      width: "40%",
      valueFormatter: (value) => {
        if (value.thumbnail != null)
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                alt="thing"
                src={`data:image/png;base64, ${value.thumbnail}`}
                style={{
                  width: "24px",
                  height: "24px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              />{" "}
              {value.name}
            </div>
          );
        else
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="square"
                style={{
                  width: "24px",
                  height: "24px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              >
                {" "}
                {value.name.charAt(0)}
              </Avatar>{" "}
              {value.name}
            </div>
          );
      },
    },
    {
      field: "couponNo",
      label: t("Kupon Kodu"),
      align: "center",
      width: "20%",
    },
    {
      field: "usedDate",
      label: t("Kullanım Tarihi"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        return getDateTimeWFormat(value);
      },
    },
  ];

  // const onDoubleClick = (row) => {
  //   const tmpTo = {
  //     pathname: `/bportal/campaignDetail/${row.id}`,
  //     breadCrumbKey: `/bportal/campaignDetail/:id`,
  //   };
  //   history.push(tmpTo);
  // };

  return (
    <Card>
      <CardHeader title={t("Bana Özel Teklifler")} data-fa-section="COUPONS" />
      <ThingsDataTable
        searchUrl={BENEFICIARY_PORTAL.coupons}
        searchForm={searchForm}
        columns={columns}
        ref={tableRef}
        showHeaderText={false}
        queryParams={{
          page: 0,
          size: 10,
          isAsc: false,
          sort: "sendDate",
        }}
        // faOnDoubleClick={onDoubleClick}
      />
    </Card>
  );
}
