import { Button, Grid, Typography } from "@material-ui/core";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import AuthCode from "react-auth-code-input";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { USER } from "services/faApi";
import { CONFIRMATION_TOKEN_COUNTDOWN } from "services/utils/Constants";
import i18n from "../../i18n";
import { authStyles } from "./authStyle";

const StepEmailVerification = forwardRef((props, ref) => {
  const { setIsShowNextButton, reportEmail, submit } = props;
  const { t } = useTranslation("report", { i18n });
  const dispatch = useDispatch();
  const authClasses = authStyles();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [retryCount, setRetryCount] = useState(1);
  const [confirmationTokens, setConfirmationTokens] = useState({
    smsToken: "",
    emailToken: "",
    smsHash: "",
    emailHash: "",
  });
  const [isActivated, setIsActivated] = useState({
    sms: false,
    email: false,
    captcha: false,
  });
  const [isCountDownOn2, setIsCountDownOn2] = useState(false);

  let timer2 = 0;
  let seconds2 = CONFIRMATION_TOKEN_COUNTDOWN;
  const [countDownTime2, setCountDownTime2] = useState();

  useImperativeHandle(ref, () => ({
    sendEmailToken(callback) {
      sendEmailToken(callback);
    },
  }));

  useEffect(() => {
    if (isCountDownOn2) {
      const interval = setInterval(() => {
        if (timer2 == 0 && seconds2 > 0) {
          countDown2();
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isCountDownOn2]);

  function startTimer() {
    if (timer2 == 0 && seconds2 > 0) {
      setIsCountDownOn2(true);
      //timer2 = setInterval(countDown2, 1000);
    }
  }

  function secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  async function sendEmailToken(callback) {
    let req = requests.getSimplePostRequest(
      USER.emailVerifyOnly +
        "/" +
        confirmationTokens.emailToken +
        "/" +
        confirmationTokens.emailHash
    );
    FaAxios(req)
      .then((response) => {
        setIsActivated({
          ...isActivated,
          email: true,
        });
        callback(true);
        submit();
      })
      .catch((err) => {
        showAlert(t("EMAIL_CONFIRMATION_ERROR_MSG"), "error");
        callback(false);
      });
  }
  async function getMailConfirmationToken() {
    let req = requests.getSimpleGetRequest(USER.verificationTokenViaEmail, {
      email: reportEmail,
    });
    FaAxios(req)
      .then((response) => {
        setConfirmationTokens({
          ...confirmationTokens,
          ["emailHash"]: response.data.HASH,
        });
      })
      .catch((e) => {
        console.log("error -> ", e);
      });
  }
  async function emailConfirmation() {
    startTimer();
    getMailConfirmationToken();
  }

  function countDown2() {
    let tempSeconds = seconds2 - 1;
    seconds2 = tempSeconds;
    setCountDownTime2(secondsToTime(tempSeconds));

    if (tempSeconds == 0) {
      clearInterval(timer2);
      setCountDownTime2(null);
      setIsCountDownOn2(false);
      setRetryCount((prev) => {
        return prev + 1;
      });
    }
  }
  const handleChangeConfirmation = (prop) => (value) => {
    setConfirmationTokens({
      ...confirmationTokens,
      [prop]: value,
    });

    if (prop == "emailToken") {
      setIsShowNextButton(value !== "");
    } else if (prop == "smsToken") {
      setIsShowNextButton(value !== "");
    }
  };

  return (
    <React.Fragment>
      <Grid
        container
        item
        alignItems="center"
        justify="center"
        spacing={2}
        lg={12}
        xs={12}
      >
        <Grid item lg={12} xs={12}>
          <Typography variant="subtitle1">{reportEmail}</Typography>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Button
            disabled={isCountDownOn2 || isActivated.email}
            variant="contained"
            fullWidth
            component="span"
            color="primary"
            disableElevation
            onClick={emailConfirmation}
          >
            {retryCount > 1 ? t("EPOSTA_GONDER_YENIDEN") : t("EPOSTA_GONDER")}
            {countDownTime2
              ? "-" +
                (countDownTime2.h * 3600 +
                  countDownTime2.m * 60 +
                  countDownTime2.s)
              : ""}
          </Button>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Typography variant="subtitle1">{t("KOD_GIRIS_ACIKLAMA")}</Typography>
        </Grid>
        <Grid item lg={8} xs={12} style={{ height: 70 }}>
          <AuthCode
            onChange={handleChangeConfirmation("emailToken")}
            containerClassName={authClasses.container}
            inputClassName={
              !isCountDownOn2 || isActivated.email
                ? authClasses.inputDisabled
                : authClasses.input
            }
            allowedCharacters="numeric"
            disabled={!isCountDownOn2 || isActivated.email}
          />
        </Grid>
        {retryCount > 1 && (
          <Grid item lg={12} xs={12}>
            <Typography variant="subtitle1">
              {t("TEKRAR_GONDER_ACIKLAMA")}
            </Typography>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
});

export default StepEmailVerification;
