import {
  Button,
  ButtonGroup,
  FormControl,
  FormHelperText,
  FormLabel,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";
import { GetFieldByLanguage } from "services/utils";

export default function SurveyQuestionNPS(props) {
  const { question, answer, onChange, readOnly, currentLang } = props;

  const scoreArray = Array(11).fill(null);

  return (
    <Grid item container alignItems="center" spacing={1}>
      <Grid item xs={12}>
        <FormControl component="fieldset" required={question.notEmpty}>
          <Grid container item row xs={12}>
            <Grid item xs={12}>
              <FormLabel component="legend">
                {question.orderNo}.{" "}
                {GetFieldByLanguage(question.multiLangName, currentLang)}
              </FormLabel>
            </Grid>
            <Grid item xs={12}>
              <FormHelperText>
                {GetFieldByLanguage(question.multiLangHint, currentLang)}
              </FormHelperText>
            </Grid>
          </Grid>
          <div>
            <div xs={12}>
              <div style={{ float: "left" }}>
                <Typography style={{ fontSize: "12px" }}>
                  Hiç Önermezdim
                </Typography>
              </div>
              <div style={{ float: "right" }}>
                <Typography style={{ fontSize: "12px" }}>
                  Kesinlikle Önerirdim
                </Typography>
              </div>
            </div>
            <div>
              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
                size="large"
              >
                {scoreArray.map((item, index) => {
                  return (
                    <Button
                      key={index}
                      variant={
                        answer?.dataNumeric === index ? "contained" : null
                      }
                      color={answer?.dataNumeric === index ? "primary" : null}
                      onClick={() => {
                        onChange(question, index, "dataNumeric");
                      }}
                      disabled={readOnly}
                      size="small"
                    >
                      {index}
                    </Button>
                  );
                })}
              </ButtonGroup>
            </div>
          </div>
        </FormControl>
      </Grid>
    </Grid>
  );
}

SurveyQuestionNPS.propTypes = {
  question: PropTypes.any,
  answer: PropTypes.any,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};
