import { Help } from "@material-ui/icons";
import Issues from "features/issues";
import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import i18n from "../../i18n";
import { useStyles } from "./style";
import ThingsSteps from "./ThingsSteps";
import TutorialElement from "./TutorialElement";

export default function IssueClose_3_Tutorial() {
  const { code } = useParams();
  const classes = useStyles();

  const stepsRef = useRef();
  let history = useHistory();

  const { t } = useTranslation("tutorialAssignTask", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        element: "#menuCustomerInteraction", // parent menu
        intro: (
          <TutorialElement
            icon="click"
            text={"Bildirilen Sorunları izlemek için tıklayın."}
          />
        ),
        position: "right",
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "#menuIssues", // child menu
        intro: (
          <TutorialElement icon="click">
            {t(
              "Açık ve Kapalı tüm sorunları Liste halinde izlemek için tıklayın."
            )}
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          var element = document.querySelector("#menuCustomerInteraction");
          if (element.childElementCount > 1 == false)
            $("#menuCustomerInteraction")[0].firstElementChild.click();
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: null,
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/tutorial/sorunlar_list.png"
                }
                width="740"
                height="256"
              ></img>
            </div>
            <br />
            Kapatmak istediğiniz Soruna ait <b>Sorun Detay</b> sayfasına gitmek
            için listeden soruna çift tıklayın.
          </TutorialElement>
        ),
        comp: "Issues",
        tooltipClass: classes.sorunlarListClass,

        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: null,
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/sorunSolutionAdd.png"
                }
                width="775"
                height="262"
              ></img>
            </div>
            <br />
            Sorun ile ilgili Servis/Operasyon Bilgilerini girmek için tıklayın.
          </TutorialElement>
        ),
        comp: "Issues",
        tooltipClass: classes.sorunSolutionAddClass,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: null,
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/sorunSolutionDialog.png"
                }
                width="948"
                height="408"
              ></img>{" "}
            </div>
            <br />
            Sorun ile ilgili{" "}
            <b>
              Yapılan İşlemleri, İşçilik Sürelerini, Kullanılan Malzemeleri ve
              Miktarlarını
            </b>{" "}
            girmek ve sorunu kapatmak için <b>Sorun Detay</b> sayfası
            Servis/Operasyon alanındaki yardıma <Help /> tıklayın ve yönergeleri
            takip edin.
          </TutorialElement>
        ),
        comp: "Issues",
        tooltipClass: classes.sorunSolutionDialogClass,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
    ]);
  }, []);

  return (
    <div>
      {steps &&
        steps.length > stepIndex &&
        steps[stepIndex].comp === "Issues" && <Issues />}

      <ThingsSteps
        code={code}
        steps={steps}
        ref={stepsRef}
        onStepChange={setStepIndex}
        afterComplete={() => {
          window.history.back();
          $("#menuCustomerInteraction")[0].firstElementChild.click();
        }}
      />
    </div>
  );
}
