import { ROLES } from "./Roles";

export const checkRole = (permittedRoles, userRoles) => {
  let result = false;
  if (permittedRoles && userRoles) {
    permittedRoles.map((item) => {
      if (userRoles.includes(item)) result = true;
    });
  }
  return result;
};

export const checkRoleByThing = (
  permittedRoles,
  userRoles,
  userInfo,
  userGroups,
  organizations,
  thing
) => {
  let result = false;
  if (permittedRoles && userRoles) {
    permittedRoles.map((item) => {
      if (userRoles.includes(item)) {
        // thingmanager ise sorumlusu olduğu organizasyonda olmalı
        if (item === ROLES.THINGMANAGER) {
          if (organizations) {
            organizations.map((org) => {
              if (thing?.organization?.code.startsWith(org)) result = true;
            });
          }
        } else if (item === ROLES.OPERATOR) {
          if (thing && thing.mtncUser && thing.mtncUser.id == userInfo.id)
            result = true;
          else if (
            thing &&
            thing.mtncUserGroup &&
            userGroups &&
            userGroups.includes(thing.mtncUserGroup.id)
          ) {
            result = true;
          }
          //FIXME işlemi yapan kişi
        } else result = true;
      }
    });
  }

  return result;
};

export const checkRoleByIssue = (
  permittedRoles,
  userRoles,
  userInfo,
  userGroups,
  organizations,
  issue
) => {
  let result = checkRoleByThing(
    permittedRoles,
    userRoles,
    userInfo,
    userGroups,
    organizations,
    issue?.thing
  );

  if (!result) {
    if (permittedRoles && userRoles) {
      permittedRoles.map((item) => {
        if (userRoles.includes(item)) {
          // thingmanager ise sorumlusu olduğu organizasyonda olmalı

          if (item === ROLES.THINGMANAGER) {
            if (organizations) {
              organizations.map((org) => {
                if (issue?.organization?.code.startsWith(org)) result = true;
              });
            }
            if (
              issue?.tasks &&
              issue?.tasks.length > 0 &&
              issue?.tasks[0].user?.id === userInfo.id
            ) {
              result = true;
            }
            if (
              issue?.tasks &&
              issue?.tasks.length > 0 &&
              userGroups &&
              userGroups.includes(issue?.tasks[0].userGroup?.id)
            ) {
              result = true;
            }
          }
        }
      });
    }
  }

  if (result && issue && issue.status === "CLOSE") result = false;

  return result;
};

export const checkRoleByTask = (userInfo, userGroups, task) => {
  let result = false;

  if (task.user && task.user.id === userInfo.id) result = true;
  else if (
    task.userGroup &&
    userGroups &&
    userGroups.includes(task.userGroup.id)
  )
    result = true;

  return result;
};

export const checkRolePromise = (permitedRoles, userRole) => {
  return new Promise((resolve, reject) => {
    const isRoleExists = checkRole(permitedRoles, userRole);
    if (isRoleExists) {
      resolve();
    } else {
      reject();
    }
  });
};
