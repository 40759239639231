import SurveyQuestionCheckbox from "features/report/survey/inputs/SurveyQuestionCheckbox";
import SurveyQuestionDate from "features/report/survey/inputs/SurveyQuestionDate";
import SurveyQuestionDropdown from "features/report/survey/inputs/SurveyQuestionDropdown";
import SurveyQuestionFile from "features/report/survey/inputs/SurveyQuestionFile";
import SurveyQuestionImage from "features/report/survey/inputs/SurveyQuestionImage";
import SurveyQuestionMatrix from "features/report/survey/inputs/SurveyQuestionMatrix";
import SurveyQuestionMenuMatrix from "features/report/survey/inputs/SurveyQuestionMenuMatrix";
import SurveyQuestionMultipleChoice from "features/report/survey/inputs/SurveyQuestionMultipleChoice";
import SurveyQuestionNPS from "features/report/survey/inputs/SurveyQuestionNPS";
import SurveyQuestionNumeric from "features/report/survey/inputs/SurveyQuestionNumeric";
import SurveyQuestionRanking from "features/report/survey/inputs/SurveyQuestionRanking";
import SurveyQuestionRating from "features/report/survey/inputs/SurveyQuestionRating";
import SurveyQuestionShoppingAmount from "features/report/survey/inputs/SurveyQuestionShoppingAmount";
import SurveyQuestionShoppingDocuments from "features/report/survey/inputs/SurveyQuestionShoppingDocuments";
import SurveyQuestionSlider from "features/report/survey/inputs/SurveyQuestionSlider";
import SurveyQuestionYesNo from "features/report/survey/inputs/SurveyQuestionYesNo";
import PropTypes from "prop-types";
import { default as React } from "react";
import SurveyQuestionText from "../report/survey/inputs/SurveyQuestionText";

export default function CampaignSurveyQuestionRender(props) {
  const {
    question,
    answer,
    onChangeAnswer,
    currency,
    editable,
    readOnly,
    accountId,
    currentLang,
  } = props;

  if (question.questionType === "MULTIPLE_CHOICE")
    return (
      <SurveyQuestionMultipleChoice
        question={question}
        answer={answer}
        onChange={onChangeAnswer}
        editable={editable}
        readOnly={readOnly}
        currentLang={currentLang}
      />
    );
  else if (question.questionType === "CHECKBOX")
    return (
      <SurveyQuestionCheckbox
        question={question}
        answer={answer}
        onChange={onChangeAnswer}
        editable={editable}
        readOnly={readOnly}
        currentLang={currentLang}
      />
    );
  else if (question.questionType === "STAR_RATING")
    return (
      <SurveyQuestionRating
        question={question}
        answer={answer}
        onChange={onChangeAnswer}
        editable={editable}
        readOnly={readOnly}
        currentLang={currentLang}
      />
    );
  else if (question.questionType === "DROPDOWN")
    return (
      <>
        <SurveyQuestionDropdown
          question={question}
          answer={answer}
          onChange={onChangeAnswer}
          editable={editable}
          readOnly={readOnly}
          currentLang={currentLang}
        />
      </>
    );
  else if (
    question.questionType === "TEXT" ||
    question.questionType === "COMMENT"
  )
    return (
      <SurveyQuestionText
        question={question}
        answer={answer}
        onChange={onChangeAnswer}
        editable={editable}
        readOnly={readOnly}
        currentLang={currentLang}
      />
    );
  else if (
    question.questionType === "INTEGER" ||
    question.questionType === "DECIMAL" ||
    question.questionType === "PERCENTAGE"
  )
    return (
      <SurveyQuestionNumeric
        question={question}
        answer={answer}
        onChange={onChangeAnswer}
        editable={editable}
        readOnly={readOnly}
        currentLang={currentLang}
      />
    );
  else if (
    question.questionType === "DATE" ||
    question.questionType === "TIME" ||
    question.questionType === "DATE_TIME"
  )
    return (
      <SurveyQuestionDate
        question={question}
        answer={answer}
        onChange={onChangeAnswer}
        editable={editable}
        readOnly={readOnly}
        currentLang={currentLang}
      />
    );
  else if (question.questionType === "MATRIX")
    return (
      <SurveyQuestionMatrix
        question={question}
        answer={answer}
        onChange={onChangeAnswer}
        editable={editable}
        readOnly={readOnly}
        currentLang={currentLang}
      />
    );
  else if (question.questionType === "RANKING")
    return (
      <SurveyQuestionRanking
        question={question}
        answer={answer}
        onChange={onChangeAnswer}
        editable={editable}
        readOnly={readOnly}
        currentLang={currentLang}
      />
    );
  else if (question.questionType === "SLIDER")
    return (
      <SurveyQuestionSlider
        question={question}
        answer={answer}
        onChange={onChangeAnswer}
        editable={editable}
        readOnly={readOnly}
        currentLang={currentLang}
      />
    );
  else if (question.questionType === "MENU_MATRIX")
    return (
      <SurveyQuestionMenuMatrix
        question={question}
        answer={answer}
        onChange={onChangeAnswer}
        editable={editable}
        readOnly={readOnly}
        currentLang={currentLang}
      />
    );
  else if (question.questionType === "NET_PROMOTER_SCORE")
    return (
      <SurveyQuestionNPS
        question={question}
        answer={answer}
        onChange={onChangeAnswer}
        editable={editable}
        readOnly={readOnly}
        currentLang={currentLang}
      />
    );
  else if (question.questionType === "YES_NO")
    return (
      <SurveyQuestionYesNo
        question={question}
        answer={answer}
        onChange={onChangeAnswer}
        editable={editable}
        readOnly={readOnly}
        currentLang={currentLang}
      />
    );
  else if (question.questionType === "FILE")
    return (
      <SurveyQuestionFile
        question={question}
        answer={answer}
        onChange={onChangeAnswer}
        editable={editable}
        readOnly={readOnly}
        accountId={accountId}
        currentLang={currentLang}
      />
    );
  else if (question.questionType === "SHOPPING_AMOUNT")
    return (
      <SurveyQuestionShoppingAmount
        question={question}
        answer={answer}
        onChange={onChangeAnswer}
        currency={currency}
        editable={editable}
        readOnly={readOnly}
        currentLang={currentLang}
      />
    );
  else if (question.questionType === "SHOPPING_DOCUMENTS")
    return (
      <SurveyQuestionShoppingDocuments
        question={question}
        answer={answer}
        onChange={onChangeAnswer}
        currency={currency}
        editable={editable}
        readOnly={readOnly}
        accountId={accountId}
        currentLang={currentLang}
      />
    );
  else if (question.questionType === "IMAGE")
    return (
      <SurveyQuestionImage
        question={question}
        answer={answer}
        onChange={onChangeAnswer}
        editable={editable}
        readOnly={readOnly}
        accountId={accountId}
        currentLang={currentLang}
      ></SurveyQuestionImage>
    );
  else return null;
}

CampaignSurveyQuestionRender.propTypes = {
  question: PropTypes.any,
  answer: PropTypes.any,
  currency: PropTypes.any,
  editable: PropTypes.any,
  readOnly: PropTypes.any,
  onChangeAnswer: PropTypes.func,
};
