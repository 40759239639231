import { faMagic } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import React, { useRef, useState } from "react";
import { FaButton, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { qsParse } from "services/utils";
import i18n from "../../i18n";

export default function VisitDateSelector(props) {
  const { value, accountId, onChange, required, multiple } = props;
  const tableRef = useRef();

  const { t } = useTranslation(["customer", "components"], { i18n });

  const [showWindow, setShowWindow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectDay, setSelectDay] = useState(false);
  const [selectHour, setSelectHour] = useState(true);
  const [selectDayHour, setSelectDayHour] = useState(false);

  const onDoubleClick = (newValue) => {
    if (multiple === true) {
      if (selectedItems.findIndex((i) => i.id === newValue.id) === -1) {
        setSelectedItems([...selectedItems, newValue]);
      }
    } else {
      setShowWindow(false);
      if (onChange) onChange(newValue);
    }
  };

  const multipleSelectedOk = () => {
    setShowWindow(false);
    if (onChange) onChange(selectedItems);
  };
  let history = useHistory();

  const newDate = () => {
    let result = {
      hour: null,
      day: null,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };
  const searchClear = () => {
    setSelectedItems([]);
  };

  const days = [
    "Pazartesi",
    "Salı",
    "Çarşamba",
    "Perşembe",
    "Cuma",
    "Cumartesi",
    "Pazar",
  ];

  const hours = [
    "00.00",
    "01.00",
    "02.00",
    "03.00",
    "04.00",
    "05.00",
    "06.00",
    "07.00",
    "08.00",
    "09.00",
    "10.00",
    "11.00",
    "12.00",
    "13.00",
    "14.00",
    "15.00",
    "16.00",
    "17.00",
    "18.00",
    "19.00",
    "20.00",
    "21.00",
    "22.00",
    "23.00",
  ];
  const [searchForm, setSearchForm] = useState(newDate());

  const renderSelector = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <FaInput
          onClear={searchClear}
          label={t("Ziyaret Tarihi")}
          value={selectedItems.map((item, index) =>
            item?.hour && item?.day
              ? "{ " + item.hour + ", " + item.day + " }"
              : item?.hour
              ? "{ " + item?.hour + " }"
              : "{ " + item?.day + " }"
          )}
          onChange={(event) => {
            setSearchForm({
              ...searchForm,
              date: event.target.value,
            });
          }}
        />
        <Tooltip title="Detaylı Seçim">
          <IconButton color="primary" style={{ marginLeft: "8px" }}>
            <FontAwesomeIcon
              icon={faMagic}
              onClick={() => {
                setShowWindow(true);
              }}
            />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  const renderWindow = () => {
    if (selectHour) {
      return (
        <>
          {hours && (
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={3}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {hours.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        <FormControlLabel
                          key={index}
                          value={index}
                          control={
                            <Checkbox
                              checked={item.check}
                              color="primary"
                              onChange={(event) => {
                                const tmp = {
                                  hour: item,
                                  day: null,
                                };
                                if (event.target.checked) {
                                  selectedItems.push(tmp);
                                } else {
                                  for (
                                    var i = 0;
                                    i < selectedItems.length;
                                    i++
                                  ) {
                                    if (
                                      selectedItems[i].hour === tmp.hour &&
                                      selectedItems[i].day === tmp.day
                                    ) {
                                      selectedItems.splice(i, 1);
                                    }
                                  }
                                }
                              }}
                            />
                          }
                          label={t(item)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {multiple === true && (
            <div style={{ textAlign: "center" }}>
              <FaButton
                faClick={() => {
                  multipleSelectedOk();
                }}
                fullWidth={false}
                startIcon={<Check />}
              >
                {t("Seç")}
              </FaButton>
            </div>
          )}
        </>
      );
    } else if (selectDay) {
      return (
        <>
          {days && (
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={3}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {days.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        <FormControlLabel
                          key={index}
                          value={index}
                          control={
                            <Checkbox
                              checked={item.check}
                              color="primary"
                              onChange={(event) => {
                                const tmp = {
                                  hour: null,
                                  day: item,
                                };
                                if (event.target.checked) {
                                  selectedItems.push(tmp);
                                } else {
                                  for (
                                    var i = 0;
                                    i < selectedItems.length;
                                    i++
                                  ) {
                                    if (
                                      selectedItems[i].hour === tmp.hour &&
                                      selectedItems[i].day === tmp.day
                                    ) {
                                      selectedItems.splice(i, 1);
                                    }
                                  }
                                }
                              }}
                            />
                          }
                          label={t(item)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {multiple === true && (
            <div style={{ textAlign: "center" }}>
              <FaButton
                faClick={() => {
                  multipleSelectedOk();
                }}
                fullWidth={false}
                startIcon={<Check />}
              >
                {t("Seç")}
              </FaButton>
            </div>
          )}
        </>
      );
    } else if (selectDayHour) {
      return (
        <>
          {days && hours && (
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell component="th"> </TableCell>
                    {days.map((day, index) => (
                      <TableCell component="th">{day}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {hours.map((hour, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {hour}
                      </TableCell>
                      {days.map((day, index) => (
                        <TableCell
                          component="th"
                          scope="column"
                          style={{ textAlign: "center" }}
                        >
                          <FormControlLabel
                            key={index}
                            value={index}
                            control={
                              <Checkbox
                                checked={day.check}
                                color="primary"
                                onChange={(event) => {
                                  const tmp = {
                                    hour: hour,
                                    day: day,
                                  };
                                  if (event.target.checked) {
                                    selectedItems.push(tmp);
                                  } else {
                                    for (
                                      var i = 0;
                                      i < selectedItems.length;
                                      i++
                                    ) {
                                      if (
                                        selectedItems[i].hour === tmp.hour &&
                                        selectedItems[i].day === tmp.day
                                      ) {
                                        selectedItems.splice(i, 1);
                                      }
                                    }
                                  }
                                }}
                              />
                            }
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {multiple === true && (
            <div style={{ textAlign: "center" }}>
              <FaButton
                faClick={() => {
                  multipleSelectedOk();
                }}
                fullWidth={false}
                startIcon={<Check />}
              >
                {t("Seç")}
              </FaButton>
            </div>
          )}
        </>
      );
    }
  };
  return (
    <>
      {renderSelector()}

      <FaDialog
        title={t("Ziyaret Seç")}
        faOpen={showWindow}
        onClear={searchClear}
        faHandleClose={(data) => {
          setSelectedItems([]);
          setShowWindow(false);
        }}
        faMaxWidth="md"
      >
        <Grid>
          <FaButton
            style={{ width: "20%", marginLeft: 25 }}
            size="small"
            color="primary"
            faClick={() => {
              setSelectDay(false);
              setSelectDayHour(false);
              setSelectHour(true);
            }}
            fullWidth={false}
          >
            {t("Saat")}
          </FaButton>
          <FaButton
            style={{ width: "20%", marginLeft: 25 }}
            size="small"
            color="primary"
            faClick={() => {
              setSelectDayHour(false);
              setSelectHour(false);
              setSelectDay(true);
            }}
            fullWidth={false}
          >
            {t("Gün")}
          </FaButton>
          <FaButton
            style={{ width: "20%", marginLeft: 25 }}
            size="small"
            color="primary"
            faClick={() => {
              setSelectHour(false);
              setSelectDay(false);
              setSelectDayHour(true);
            }}
            fullWidth={false}
          >
            {t("Gün-Saat")}
          </FaButton>
          <FaButton
            style={{ width: "20%", marginLeft: 25 }}
            size="small"
            color="primary"
            faClick={() => {
              setSelectedItems([]);
            }}
            fullWidth={false}
          >
            {t("Temizle")}
          </FaButton>
        </Grid>

        <div>{showWindow && renderWindow()}</div>
      </FaDialog>
    </>
  );
}
