import { createTheme, makeStyles } from "@material-ui/core/styles";

export const authStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
  },

  input: {
    width: 60,
    height: 60,
    padding: 0,
    fontSize: 24,
    textAlign: "center",
    marginRight: 12,
    color: "#494949",
    border: "1px solid #d6d6d6",
    borderRadius: 4,
    background: "#fff",
    backgroundClip: "paddingBox",
  },

  inputDisabled: {
    width: 60,
    height: 60,
    padding: 0,
    fontSize: 24,
    textAlign: "center",
    marginRight: 12,
    color: "#494949",
    border: "1px solid #d6d6d6",
    borderRadius: 4,
    background: "rgba(0, 0, 0, 0.12)",
    backgroundClip: "paddingBox",
  },
}));

export const buttonTheme = createTheme({
  palette: {
    primary: {
      main: "#3D9B35",
    },
    secondary: {
      main: "#115293",
    },
  },
});
