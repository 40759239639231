import { FormControl, FormHelperText, FormLabel } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Rating } from "@material-ui/lab";
import PropTypes from "prop-types";
import React from "react";
import { GetFieldByLanguage } from "services/utils";
export default function SurveyQuestionRating(props) {
  const { question, answer, onChange, readOnly, currentLang } = props;

  return (
    <Grid item xs={12}>
      <FormControl
        component="fieldset"
        style={{ width: "100%" }}
        required={question.notEmpty}
      >
        <Grid container item row xs={12} spacing={1}>
          <Grid item xs={12}>
            <FormLabel component="legend">
              {question.orderNo}.{" "}
              {GetFieldByLanguage(question.multiLangName, currentLang)}
            </FormLabel>
          </Grid>
          <Grid item xs={12}>
            <FormHelperText>
              {GetFieldByLanguage(question.multiLangHint, currentLang)}
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <Rating
              name={"rating" + question.id}
              required={question.notEmpty}
              max={question.max}
              value={Number(answer?.dataNumeric)}
              onChange={(event) => {
                onChange(question, event.target.value, "dataNumeric");
              }}
              disabled={readOnly}
            />
          </Grid>
        </Grid>
      </FormControl>
    </Grid>
  );
}

SurveyQuestionRating.propTypes = {
  question: PropTypes.any,
  answer: PropTypes.any,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};
