import { CardHeader } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ThingsEditIcon from "components/icons/ThingsEditIcon";
import ThingsAdditionalFieldsHelp from "features/help/ThingsAdditionalFieldsHelp";
import React, { useEffect, useState } from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import DynamicFieldsDetail from "../../components/dynamicFieldsDetail";
import DynamicFieldsEdit from "../../components/dynamicFieldsEdit";
import i18n from "../../i18n";
import { DYNAMIC_FORM_DATA, THING_DYNAMIC_FORM } from "../../services/faApi";

export default function ThingAdditionalFields(props) {
  const { thing, editMode, onChange, isEditable } = props;

  const FORM_TYPE_ENUM = "THING_CLASS";
  const { t } = useTranslation("thingDetail", { i18n });
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(!editMode ? false : true);
  const [dynamicValues, setDynamicValues] = useState([]);

  const [dynamicFormList, setDynamicFormList] = useState([]);

  const [dynamicFormData, setDynamicFormData] = useState({
    name: "THING_CLASS",
    objectId: thing?.id,
    detailData: [],
  });

  useEffect(() => {
    if (thing && thing.id > 0) {
      setLoading(true);
      const req = requests.getSimpleGetRequest(THING_DYNAMIC_FORM.findForm, {
        accountId: thing.account.id,
        thingClassId: thing?.thingClass?.id,
      });
      FaAxios(req)
        .then((res) => {
          let fields = [];
          if (res.data) {
            setDynamicFormList(res.data);
            fields = res.data;
            const dataReq = requests.getSimpleGetRequest(
              THING_DYNAMIC_FORM.findByObjectId,
              { objectId: thing.id }
            );
            FaAxios(dataReq)
              .then((res) => {
                setDynamicFormData(res.data);
              })
              .catch((err) => {
                console.log(err);
                setDynamicFormData({
                  name: "THING_CLASS",
                  objectId: thing?.id,
                  detailData: [],
                });
              });
          }
          setLoading(false);
        })
        .catch((err) => {
          setDynamicFormList([]);
          setLoading(false);
        });

      setIsDialogOpen(!editMode ? false : true);
    }
  }, [thing]);

  const uploadFile = (data) => {
    let formData = new FormData();
    formData.append(
      "multipartFile",
      data.dataFile.file,
      data.dataFile.file.name
    );
    formData.append("name", "THING_CLASS");
    formData.append("objectId", thing.id);
    formData.append("dynamicFormId", data.dynamicFormId);

    let req = requests.getSimplePostRequest(
      THING_DYNAMIC_FORM.uploadDocument,
      formData
    );
    req["header"] = { "Content-Type": "multipart/form-data" };

    FaAxios(req)
      .then((response) => {
        let tempValues = [...dynamicValues];
        tempValues[data.index] = {
          ...tempValues[data.index],
          dataFile: response.data.dataFile,
          dataString: response.data.dataString,
        };

        console.log("tempValues", tempValues);
        setDynamicValues(tempValues);
        setDynamicFormData({ ...dynamicFormData, detailData: tempValues });
      })
      .catch((error) => {});
    return () => {
      //setLoading(false);
    };
  };

  const saveDynamicFormData = () => {
    let uploadFiles = [];
    dynamicValues.map((item, index) => {
      if (item.dataFile && item.dataFile.file != null) {
        uploadFiles.push({ ...item, index: index });
        item.dataFile = null;
      }
    });

    let formData = dynamicFormData;
    formData.detailData = dynamicValues;
    formData.name = "THING_CLASS";
    formData.objectId = thing.id;

    const req = requests.getSimplePostRequest(DYNAMIC_FORM_DATA.save, formData);
    FaAxios(req)
      .then((res) => {
        //setDynamicFormData(res.data); showAlert(t('FORM_SAVED'), "success");
        if (uploadFiles != null && uploadFiles.length > 0) {
          uploadFiles.map(uploadFile);

          setIsDialogOpen(false);
          if (onChange) onChange(dynamicValues);
        } else {
          setIsDialogOpen(false);
          if (onChange) onChange(dynamicValues);
        }
      })
      .catch((err) => {
        console.log(err); //showAlert(err.message, "error");
      });
  };

  const updateFail = () => {};

  const onDynamicValuesChange = (values) => {
    setDynamicValues(values);
  };

  const renderDialog = () => {
    return (
      <FaDialog
        title={t("Additional Info Update Title")}
        faOpen={isDialogOpen}
        faOnSubmit={saveDynamicFormData}
        showSaveButton
        faHandleClose={() => {
          setIsDialogOpen(false);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={0}
          data-fa-section="THING_ADDITIONAL_FIELD_DIALOG"
        >
          <DynamicFieldsEdit
            selectedDetails={dynamicFormList}
            details={dynamicFormList}
            data={dynamicFormData.detailData}
            onValuesChange={onDynamicValuesChange}
            objectId={thing.id}
          />
        </Grid>
      </FaDialog>
    );
  };

  return (
    <>
      {renderDialog()}
      {!editMode && (
        <Card
          style={{ minHeight: 100, overflowY: "auto" }}
          className="fai-additionalFields"
        >
          <CardHeader
            action={
              <>
                <ThingsAdditionalFieldsHelp value="thingDetail.additionalFields" />
                {isEditable && (
                  <IconButton
                    aria-label="settings"
                    onClick={() => {
                      setIsDialogOpen(true);
                    }}
                    data-fa-button="THINGS_ADDITIONAL_FIELD_BUTTON"
                  >
                    <ThingsEditIcon />
                  </IconButton>
                )}
              </>
            }
            title={t("ADDITIONAL_FIELDS")}
            data-fa-section="ADDITIONAL_FIELDS"
          />
          <CardContent>
            <Grid item container spacing={1}>
              <DynamicFieldsDetail
                selectedDetails={dynamicFormList}
                details={dynamicFormList}
                data={dynamicFormData.detailData}
                objectId={thing.id}
              />
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
}
