import { Grid } from "@material-ui/core";
import React, { forwardRef, useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { IssueService } from "services/issue/issueService";
import i18n from "../../i18n";

const IssueReopen = forwardRef((props, ref) => {
  const { onChange, onClose, open, issue } = props;
  const { t } = useTranslation("issueDetail", { i18n });
  const dispatch = useDispatch();
  const accountId = localStorage.accountId;

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(null);

  const saveSuccess = (data) => {
    showAlert(t("REOPENED"), "success");
    setLoading(false);
    onChange(data);
    onClose();
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const reopen = () => {
    setLoading(true);

    IssueService.reopen(issue.id, description, saveSuccess, saveError);
  };

  const newFormRender = () => {
    return (
      <>
        <FaDialog
          title={t("REOPEN")}
          faOpen={open}
          faOnSubmit={reopen}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            onClose();
          }}
          faMaxWidth="sm"
          saveButtonTitle={t("REOPEN")}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <FaInput
                required
                maxLength={255}
                faType="description"
                label={t("DESCRIPTION")}
                onChange={(event) => setDescription(event.target.value)}
                value={description || ""}
              />
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  return newFormRender();
});

export default IssueReopen;
