import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardHeader, Grid, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import UserAutoComplete from "components/autocomplete/UserAutoComplete";
import UserDetail from "components/detail/UserDetail";
import ThingsDataTable from "components/thingsDataTable";
import { default as React, Suspense, useEffect, useState } from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { OrganizationUserService } from "services/OrganizationUserService";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";

export default function OrganizationUsers(props) {
  const { organization } = props;
  const { t } = useTranslation("organization", { i18n });
  const accountId = localStorage.accountId;
  const faDialog = useDialog();
  const labelClasses = labelStyles();

  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [organizationUserList, setOrganizationUserList] = useState([]);
  const [user, setUser] = useState(null);
  const [childrenAccess, setChildrenAccess] = useState(false);

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const columns = [
    {
      field: "user",
      label: t("USER"),
      align: "center",
      width: "90%",
      valueFormatter: (value, row) => <UserDetail value={value} type="USER" />,
    },
    {
      field: "id",
      label: t("İşlem"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        return (
          <IconButton
            onClick={() => deleteOrganizationUser(row)}
            size="small"
            color="primary"
            aria-label={t("Sil")}
          >
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        );
      },
    },
  ];

  useEffect(() => {
    refresh();
  }, [organization]);

  const refresh = () => {
    if (organization && organization.id > 0) {
      OrganizationUserService.findUsersByOrganization(
        organization.id,
        findByOrganizationOnSuccess,
        findByOrganizationOnError
      );
    }
  };

  const findByOrganizationOnSuccess = (data) => {
    setOrganizationUserList(data.list);
  };

  const findByOrganizationOnError = (error) => {
    console.log(error);
  };

  const addOrganizationUser = () => {
    const data = { organization, user, childrenAccess };
    OrganizationUserService.save(data, saveOnSuccess, saveOnError);
  };

  const saveOnSuccess = (data) => {
    refresh();
    setIsEditFormOpen(false);
  };

  const saveOnError = (data) => {
    showAlert(
      "Organizasyon kullanıcısı eklenemedi - çakışan yetki tanımı mevcut",
      "error"
    );
  };

  const deleteOrganizationUser = (data) => {
    faDialog({
      description: t("Kaydı silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        console.log("confirmation", confirmation);
        OrganizationUserService.delete(data, deleteOnSuccess, deleteOnError);
      })
      .catch((err) => console.log("error:", err));
  };

  const deleteOnSuccess = (data) => {
    refresh();
  };

  const deleteOnError = (data) => {};

  const renderEditForm = () => {
    return (
      <>
        <FaDialog
          title={"Organizasyon Kullanıcısı Tanımlama"}
          faOpen={isEditFormOpen}
          faOnSubmit={addOrganizationUser}
          //loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            setIsEditFormOpen(data);
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <UserAutoComplete
                value={user}
                accountId={accountId}
                // powerUser={true}
                onChange={(data) => {
                  setUser(data);
                }}
                label={t("USER")}
                required={true}
              />
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {renderEditForm()}
        <Card style={{ minHeight: 100 }}>
          <CardHeader
            action={
              <IconButton
                aria-label="settings"
                disabled={!organization}
                onClick={() => {
                  setIsEditFormOpen(true);
                  setUser(null);
                  setChildrenAccess(false);
                }}
              >
                <AddIcon />
              </IconButton>
            }
            title={t("ORGANIZATION_USERS")}
            data-fa-section="ORGANIZATION_USERS"
          />

          <ThingsDataTable
            data={organizationUserList}
            columns={columns}
            //ref={tableRef}
            showDeleteButton={false}
            showHeaderText={false}
            headerText={t("ORGANIZATION_USERS")}
            showPaging={false}
            showHeader={true}
          />
        </Card>
      </Suspense>
    </>
  );
}
