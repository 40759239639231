import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import i18n from "i18n";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import useStyles from "react-base-fa/dist/fa/faUnAuthPages/style";
import { useTranslation } from "react-i18next";
import "react-puzzle-confirm/react-puzzle-confirm.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useHistory, useParams } from "react-router-dom";
import { BeneficiaryPortalService } from "services/BeneficiaryPortalService";
import { getDateWFormat, thingsDecodeUri } from "services/utils";
import BeneficiaryLogin from "../BeneficiaryLogin";
import BeneficiaryPortalLayout from "../BeneficiaryPortalLayout";

export default function CampaignStatus(props) {
  const { appLogo } = props;
  const { id, campaignId, accountId } = useParams();
  const classes = useStyles();
  const { t } = useTranslation("beneficiaryPortal", { i18n });

  let history = useHistory();

  const [loading, setLoading] = useState(null);
  const [campaign, setCampaign] = useState(null);

  useEffect(() => {
    setLoading(true);
    BeneficiaryPortalService.getCampaign(
      campaignId,
      accountId,
      campaignStatusOnSuccess,
      campaignStatusOnError
    );
  }, []);

  const campaignStatusOnSuccess = (data) => {
    setLoading(false);
    setCampaign(data);
  };

  const campaignStatusOnError = (error) => {
    setLoading(false);
  };

  const renderNotFound = () => {
    return (
      <Grid
        item
        container
        spacing={0}
        lg={12}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} style={{ textAlign: "center" }}>
          :( Üzgünüz, aradığınız kampanyayı bulamadık.
        </Grid>
      </Grid>
    );
  };

  const renderCampaignDetails = () => {
    return (
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
          <Typography variant="h5">{campaign.name}</Typography>
        </Grid>
        <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
          <div>
            {campaign && campaign.thumbnail && (
              <img
                src={"data:image/jpeg;base64," + campaign.thumbnail}
                alt="CampaignImage"
                style={{ maxHeight: 200 }}
              />
            )}
          </div>
        </Grid>
        <Grid
          item
          lg={10}
          xs={12}
          style={{ textAlign: "left", paddingLeft: 20 }}
        >
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              {"Kampanya tarihleri: " +
                getDateWFormat(campaign.registerStartDate) +
                " - " +
                getDateWFormat(campaign.registerEndDate)}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const onSuccess = () => {
    var t1 = thingsDecodeUri(id);
    var t2 = Buffer.from(t1, "base64");

    var accId = Buffer.from(thingsDecodeUri(accountId), "base64");

    const tmpTo = {
      pathname: `/bportal/campaignDetail/${t2}/${accId}`,
      breadCrumbKey: `/bportal/campaignDetail/:id/:accId`,
    };
    history.push(tmpTo);
  };

  return (
    <BeneficiaryPortalLayout appLogo={appLogo}>
      {!loading && !campaign ? renderNotFound() : null}
      {!loading && campaign && (
        <>
          {renderCampaignDetails()}
          <BeneficiaryLogin partial={true} onSuccess={onSuccess} />
        </>
      )}
    </BeneficiaryPortalLayout>
  );
}

CampaignStatus.propTypes = {
  loginPath: PropTypes.string,
  appLogo: PropTypes.any,
};
