import Issues from "features/issues";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import i18n from "../../i18n";
import { useStyles } from "./style";
import ThingsSteps from "./ThingsSteps";
import TutorialElement from "./TutorialElement";

export default function GeneralPageFeatures() {
  const { code } = useParams();
  const classes = useStyles();

  const stepsRef = useRef();
  const myThingRef = useRef(null);
  let history = useHistory();

  const { t } = useTranslation("tutorialQuickStart", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        element:
          "#root > div > header > div > div > div:nth-child(3) > button:nth-child(5)",
        intro: (
          <TutorialElement icon="tutorial">
            Kullanıcı <b>Yardım Metinlerine</b> ulaşmak için tıklayın.
          </TutorialElement>
        ),
        comp: "Issues",
      },
      {
        element:
          "#root > div > header > div > div > div:nth-child(3) > button:nth-child(4)",
        intro: (
          <TutorialElement header="Menu" icon="click">
            <b>{t("Tema ve Dil")}</b>{" "}
            {t(" seçeneklerini ayarlamak için tıklayın. ")}
          </TutorialElement>
        ),
        comp: "Issues",
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/tutorial/temaVeDilSec.png"
                }
                width="255"
                height="362"
              ></img>
            </div>
            <br />
            <b>Tema ve Dil</b> seçeneklerini ayarlayın.
          </TutorialElement>
        ),
        tooltipClass: classes.temaVeDilSecClass,
        comp: "Issues",
      },
      {
        element:
          "#root > div > header > div > div > div:nth-child(3) > button:nth-child(2)",
        intro: (
          <TutorialElement icon="click">
            Yeni Bildirimleri görmek için tıklayın.{" "}
          </TutorialElement>
        ),
        comp: "Issues",
      },
      {
        element:
          "#root > div > header > div > div > div:nth-child(3) > button:nth-child(1)",
        intro: (
          <TutorialElement icon="click">
            <b>Profil</b> sayfanıza erişmek, <b>Parola Değiştirmek</b> veya{" "}
            <b>Çıkış Yapmak</b> için tıklayın.
          </TutorialElement>
        ),
        comp: "Issues",
      },
      {
        element: "#navbarAdvancedSearchButton",
        intro: (
          <TutorialElement icon="tip">
            <b>Gelişmiş Arama</b> (filtreleme) seçenekleri için tıklayın ve
            açılan penceredeki seçenekleri kullanarak arama yapın.
          </TutorialElement>
        ),
        comp: "Issues",
      },
      {
        element:
          "#root > div > main > div.MuiContainer-root.makeStyles-container-20.MuiContainer-disableGutters > div > div > div > div.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1.MuiGrid-item.MuiGrid-align-items-xs-center.MuiGrid-justify-content-xs-center > div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6 > div > form > div",
        intro: (
          <TutorialElement icon="click">
            <b>Hızlı Arama</b> kutucuğuna tıklayın ve anahtar kelime ya da
            sözcük girerek arama yapın.
          </TutorialElement>
        ),
        comp: "Issues",
      },
      {
        element: ".MuiTablePagination-root",
        intro: (
          <TutorialElement icon="info">
            {t(
              "Sayfa başına gösterilen kayıt sayılarının düzenlendiği ve sayfalar arası geçiş yapıldığı alandır."
            )}
          </TutorialElement>
        ),
        comp: "Issues",
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: ".MuiTablePagination-actions",
        intro: (
          <TutorialElement icon="click">
            {t(
              "Sayfalar arasında geçiş yapmak için “<” ve “>” seçeneklerine tıklayın."
            )}
          </TutorialElement>
        ),
        comp: "Issues",
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "div.MuiTablePagination-selectRoot",
        intro: (
          <TutorialElement icon="click">
            {t(
              "Sayfa başına Etkileşim Sayısını ayarlamak için tıklayın ve 10, 25, 50 ve 100 seçeneklerinden birini seçin."
            )}
          </TutorialElement>
        ),
        comp: "Issues",
      },
      {
        element: "[title='Excel']",
        intro: (
          <TutorialElement icon="click">
            {"Listeyi Dışarı Aktarmak için "}
            <b>Excel</b>
            {" ikonuna tıklayın."}
          </TutorialElement>
        ),
        comp: "Issues",
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element:
          "button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textSizeSmall.MuiButton-sizeSmall",
        intro: (
          <TutorialElement icon="click">
            Tarih gösterimini ayarlamak için tıklayın.
          </TutorialElement>
        ),
        comp: "Issues",
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
    ]);
  }, []);

  return (
    <div>
      {steps &&
        steps.length > stepIndex &&
        steps[stepIndex].comp === "Issues" && <Issues />}

      <ThingsSteps
        code={code}
        steps={steps}
        ref={stepsRef}
        onStepChange={setStepIndex}
        afterComplete={() => window.history.back()}
      />
    </div>
  );
}
