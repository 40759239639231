import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { THING_DICTIONARY } from "./faApi";

export const ThingDictionaryService = {
  findClassName: (accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      THING_DICTIONARY.findClassName +
        "?domain=ISSUE_CLOSE_TYPE&className=&accountId=" +
        accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
