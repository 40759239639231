import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { THING_DYNAMIC_FORM } from "./faApi";

export const ThingDynamicFormService = {
  findForm: (accountId, thingClassId, callback, errorCallback) => {
    const req = requests.getSimpleGetRequest(THING_DYNAMIC_FORM.findForm, {
      accountId,
      thingClassId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findByObjectId: (objectId, callback, errorCallback) => {
    const req = requests.getSimpleGetRequest(
      THING_DYNAMIC_FORM.findByObjectId,
      {
        objectId,
      }
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findFormAndData: (
    accountId,
    name,
    classId,
    objectId,
    callback,
    errorCallback
  ) => {
    const req = requests.getSimpleGetRequest(
      THING_DYNAMIC_FORM.findFormAndData,
      {
        accountId,
        name,
        classId,
        objectId,
      }
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
