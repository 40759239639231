import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import UserDetail from "components/detail/UserDetail";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import { FaButton } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { QueueService } from "services/QueueService";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import QueueEdit from "./QueueEdit";
import QueueLog from "./QueueLog";
import QueueState from "./QueueState";
import QueueTarget from "./QueueTarget";
import QueueUsers from "./QueueUsers";

export default function QueueDetail() {
  const labelClasses = labelStyles();
  const { t } = useTranslation(["queue", "components"], { i18n });
  const { id } = useParams();
  const accountId = localStorage.accountId;
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const dialogRef = useRef();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [loading, setLoading] = useState(false);
  const [queue, setQueue] = useState({});

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    setLoading(true);
    QueueService.findById(id, findByIdOnSuccess, findByIdOnError);
  };

  const findByIdOnSuccess = (data) => {
    setQueue({
      ...data,
    });
    setLoading(false);
  };

  const findByIdOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };

  const onSave = () => {
    refresh();
  };

  const editDialogRender = () => {
    return (
      <QueueEdit queue={queue} ref={dialogRef} onSave={onSave}></QueueEdit>
    );
  };
  const openEditDialog = () => {
    dialogRef.current.openDialog();
  };

  const changeStatusActive = () => {
    faDialog({
      description: t("Kuyruğu aktifleştirmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        QueueService.active(
          id,
          changeStatusActiveOnSuccess,
          changeStatusActiveOnError
        );
      })
      .catch((err) => console.log("error:", err));
  };
  const changeStatusActiveOnSuccess = (data) => {
    setQueue({
      ...data,
    });
    setLoading(false);
  };

  const changeStatusActiveOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };

  const changeStatusPassive = () => {
    faDialog({
      description: t("Kuyruğu pasif hale getirmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        QueueService.passive(
          id,
          changeStatusPassiveOnSuccess,
          changeStatusPassiveOnError
        );
      })
      .catch((err) => console.log("error:", err));
  };
  const changeStatusPassiveOnSuccess = (data) => {
    setQueue({
      ...data,
    });
    setLoading(false);
  };

  const changeStatusPassiveOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {editDialogRender()}
        <Grid container spacing={1}>
          <Grid item lg={6} xs={12}>
            <Grid item container spacing={1}>
              <Grid item lg={12}>
                <Card>
                  <CardHeader
                    title={t("QUEUE_INFO")}
                    data-fa-section="QUEUE_INFO"
                    action={
                      <IconButton
                        disabled={queue.status !== "PASSIVE"}
                        aria-label="settings"
                      >
                        <EditIcon onClick={() => openEditDialog()} />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <Grid item container spacing={1}>
                      <Grid item xs={12}>
                        <Grid item container spacing={1}>
                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("ORDER_NO")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {queue.orderNo}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("NAME")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {queue.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("DESCRIPTION")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {queue.description}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("MANAGER_USER")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {queue.managerUser && (
                                <UserDetail
                                  value={queue.managerUser}
                                  type="USER"
                                />
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("SCHEDULE_PLAN")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {queue.schedulePlan && queue.schedulePlan?.name}
                              {!queue.schedulePlan &&
                                t("SCHEDULE_PLAN_ALWAYS", { ns: "components" })}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("DISPATCH_METHOD")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {t(
                                "QUEUE_DISPATCH_METHOD_" + queue.dispatchMethod,
                                {
                                  ns: "components",
                                }
                              )}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className={labelClasses.LabelLastRow}
                          >
                            <Typography className={labelClasses.LabelHeader}>
                              {t("STATUS")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {t(queue.status)}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className={labelClasses.LabelLastRow}
                          >
                            {queue.status === "PASSIVE" ? (
                              <FaButton
                                style={{ width: "25%" }}
                                variant="contained"
                                size="small"
                                faClick={() => {
                                  changeStatusActive();
                                }}
                                //startIcon={<FontAwesomeIcon icon={faUserEdit} />}
                                data-fa-button="ACTIVATE"
                              >
                                {t("ACTIVATE")}
                              </FaButton>
                            ) : (
                              <>
                                {queue.status === "ACTIVE" && (
                                  <FaButton
                                    style={{ width: "25%", marginLeft: 10 }}
                                    variant="contained"
                                    size="small"
                                    color="secondary"
                                    faClick={() => {
                                      changeStatusPassive();
                                    }}
                                    //startIcon={<Refresh />}
                                    data-fa-button="PASSIVATE"
                                  >
                                    {t("PASSIVATE")}
                                  </FaButton>
                                )}
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                {queue && queue.id > 0 && (
                  <QueueTarget queue={queue}></QueueTarget>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={6} xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {queue && queue.id > 0 && (
                  <QueueUsers queue={queue}></QueueUsers>
                )}
              </Grid>
              <Grid item xs={12}>
                {queue && queue.id > 0 && (
                  <QueueState queue={queue}></QueueState>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={12} xs={12}>
            {queue && queue.id > 0 && <QueueLog queue={queue}></QueueLog>}
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
