import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FaAutoComplete } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { ACCOUNT } from "../../services/faApi";

export default function UserAutoComplete(props) {
  const { value, accountId, onChange, required, label, powerUser } = props;

  const { t } = useTranslation("components", { i18n });

  return (
    <FaAutoComplete
      faType="async"
      value={value}
      getOptionSelected={(option, value) => {
        return option.id === value.id;
      }}
      sort="fullname"
      faFilterProp="fullname"
      getOptionLabel={(option) => (option.fullname ? option.fullname : "")}
      requestUrl={
        ACCOUNT.findAccountUsers +
        "?accountId=" +
        accountId +
        (powerUser === true ? "&powerUser=true" : "")
      }
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      label={label == null ? t("USER") : label}
      required={required}
      renderOption={(option) => (
        <React.Fragment>
          <div>
            <FontAwesomeIcon icon={faUserAlt} style={{ paddingRight: 4 }} />
            {option && option.fullname}
            
            {option && option.title && (
              <div
                style={{
                  fontSize: "11px",
                }}
              >
                {option.title}
              </div>
            )}
            {option && option.department && (
              <div
                style={{
                  fontSize: "11px",
                }}
              >
                {option.department}
              </div>
            )}
            {option && option.company && (
              <div
                style={{
                  fontSize: "11px",
                }}
              >
                {option.company}
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    />
  );
}
