import { Grid, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import i18n from "i18n";
import { default as React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BeneficiaryPortalService } from "services/BeneficiaryPortalService";
import { labelStyles } from "styles/ThingsLabelStyle";

export default function CustomerInfo() {
  const accountId = localStorage.beneficiaryAccountId;
  const { t } = useTranslation("beneficiaryPortal", { i18n });
  const [customer, setCustomer] = useState({});
  const labelClasses = labelStyles();

  useEffect(() => {
    BeneficiaryPortalService.getCustomerInfo(
      accountId,
      setCustomer,
      (error) => {}
    );
  }, []);

  return (
    <Card>
      <CardHeader
        title={t("Kayıtlı Kişisel Bilgileri")}
        data-fa-section="CUSTOMER_INFO"
      />
      <Grid item container spacing={1}>
        <Grid item xs={12}>
          <Grid item container spacing={1}>
            <Grid item xs={6} className={labelClasses.LabelRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("NAME")}
              </Typography>
              <Typography className={labelClasses.LabelValue}>
                {customer.name}
              </Typography>
            </Grid>
            <Grid item xs={6} className={labelClasses.LabelRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("SURNAME")}
              </Typography>
              <Typography className={labelClasses.LabelValue}>
                {customer.surname}
              </Typography>
            </Grid>
            <Grid item xs={6} className={labelClasses.LabelRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("PHONE")}
              </Typography>
              <Typography className={labelClasses.LabelValue}>
                {customer.phone}
              </Typography>
            </Grid>
            <Grid item xs={6} className={labelClasses.LabelRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("E-MAIL")}
              </Typography>
              <Typography className={labelClasses.LabelValue}>
                {customer.email}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
