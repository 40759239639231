import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { THINGCLASS } from "../../services/faApi";

export const ThingClassService = {
  save: (thingClass, callback, errorCallback) => {
    let reqBody = {
      ...thingClass,
    };

    let req = requests.getSimplePostRequest(THINGCLASS.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  upload: (id, image, callback, errorCallback) => {
    let formData = new FormData();
    formData.append("multipartFile", image, image.name);
    let req = requests.getSimplePostRequest(
      THINGCLASS.uploadFile + "/" + id,
      formData
    );
    req["header"] = { "Content-Type": "multipart/form-data" };

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(THINGCLASS.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (thingClass, callback, errorCallback) => {
    let reqBody = { ...thingClass };
    let req = requests.getSimplePostRequest(THINGCLASS.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  deleteFile: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(THINGCLASS.deleteFile + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findChildren: (parentId, accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      THINGCLASS.findChildren +
        "?accountId=" +
        accountId +
        "&parentId=" +
        parentId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  downloadDocument: (dynamicFormId, objectId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      THINGCLASS.downloadDocument +
        "?dynamicFormId=" +
        dynamicFormId +
        "&objectId=" +
        encodeURI(objectId)
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  updatePublicReportPageTemplate: (
    id,
    publicReportPageTemplate,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimplePostRequest(
      THINGCLASS.updatePublicReportPageTemplate +
        "?id=" +
        id +
        "&publicReportPageTemplate=" +
        publicReportPageTemplate
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        console.log("error", error?.response?.data);
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getHashtagList: (accountId, limit, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(THINGCLASS.getHashtagList, {
      accountId,
      limit,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  checkAddNewThingClass: (accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      THINGCLASS.checkAddNewThingClass + "?accountId=" + accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  copyThingClass: (accountId, thingClassId, name, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      THINGCLASS.copyThingClass +
        "?accountId=" +
        accountId +
        "&thingClassId=" +
        thingClassId +
        "&name=" +
        encodeURI(name)
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  uploadThingClassDocument: (
    id,
    document,
    name,
    description,
    accessType,
    accountId,
    callback,
    errorCallback
  ) => {
    let formData = new FormData();
    formData.append("multipartFile", document.file, document.file.name);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("accessType", accessType);
    formData.append("accountId", accountId);

    let req = requests.getSimplePostRequest(
      THINGCLASS.uploadThingClassDocument + "/" + id,
      formData
    );
    req["header"] = { "Content-Type": "multipart/form-data" };

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findDocuments: (thingClassId, accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      THINGCLASS.findDocuments +
        "?thingClassId=" +
        thingClassId +
        "&accountId=" +
        accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  downloadThingClassDocument: (
    thingClassId,
    objectId,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(
      THINGCLASS.downloadThingClassDocument +
        "?thingClassId=" +
        thingClassId +
        "&objectId=" +
        encodeURI(objectId)
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  deleteDocument: (thingClassId, objectId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      THINGCLASS.deleteDocument +
        "?thingClassId=" +
        thingClassId +
        "&objectId=" +
        encodeURI(objectId)
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  makeDocumentPublic: (objectId, accountId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      THINGCLASS.makeDocumentPublic +
        "?objectId=" +
        encodeURI(objectId) +
        "&accountId=" +
        accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  makeDocumentPrivate: (objectId, accountId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      THINGCLASS.makeDocumentPrivate +
        "?objectId=" +
        encodeURI(objectId) +
        "&accountId=" +
        accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  makeDocumentAttendant: (objectId, accountId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      THINGCLASS.makeDocumentAttendant +
        "?objectId=" +
        encodeURI(objectId) +
        "&accountId=" +
        accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
