import { faRuler } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { default as React, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";

export default function MenuPeriod(props) {
  const { value, onChange } = props;
  const { t } = useTranslation("statistic", { i18n });
  const [anchorPeriodEl, setAnchorPeriodEl] = useState(null);

  const [period, setPeriod] = useState(value);

  const handlePeriodClick = (event) => {
    setAnchorPeriodEl(event.currentTarget);
  };

  const handlePeriodClose = () => {
    setAnchorPeriodEl(null);
  };

  const changePeriod = (newPeriod) => {
    setPeriod(newPeriod);
    setAnchorPeriodEl(null);
    onChange(newPeriod);
  };

  const renderMenu = () => {
    return (
      <>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handlePeriodClick}
          startIcon={
            <FontAwesomeIcon icon={faRuler} style={{ color: "#6F7782" }} />
          }
          data-fa-button="MENU_PERIOD"
        >
          {t("" + period)}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorPeriodEl}
          keepMounted
          open={Boolean(anchorPeriodEl)}
          onClose={handlePeriodClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            onClick={() => {
              changePeriod("DAY");
            }}
          >
            {t("DAY")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              changePeriod("WEEK");
            }}
          >
            {t("WEEK")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              changePeriod("MONTH");
            }}
          >
            {t("MONTH")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              changePeriod("YEAR");
            }}
          >
            {t("YEAR")}
          </MenuItem>
        </Menu>
      </>
    );
  };

  return <>{renderMenu()}</>;
}
