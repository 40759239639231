import { Button, ButtonGroup } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

export default function SurveyQuestionLibraryButtonGroup(props) {
  const { value, onChange, ...other } = props;

  const { t } = useTranslation("components", { i18n });

  return (
    <ButtonGroup
      fullWidth
      size="small"
      variant="outlined"
      color="primary"
      aria-label="contained primary button group"
      {...other}
    >
      <Button
        color={"primary"}
        variant={value === "ALL" ? "contained" : "outlined"}
        onClick={() => {
          onChange("ALL");
        }}
      >
        {t("ALL")}
      </Button>
      <Button
        color={"primary"}
        variant={value === "MY_QUESTIONS" ? "contained" : "outlined"}
        onClick={() => {
          onChange("MY_QUESTIONS");
        }}
      >
        {t("Benim Sorularım")}
      </Button>
      <Button
        color={"primary"}
        variant={value === "POOL" ? "contained" : "outlined"}
        onClick={() => {
          onChange("POOL");
        }}
      >
        {t("Hazır Sorular")}
      </Button>
    </ButtonGroup>
  );
}
