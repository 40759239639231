import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { CUSTOMER, PUBLIC, UNSUBSCRIBE } from "./faApi";

export const CustomerService = {
  save: (customer, callback, errorCallback) => {
    let reqBody = {
      ...customer,
    };

    let req = requests.getSimplePostRequest(CUSTOMER.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(CUSTOMER.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findCustomerInteraction: (
    visitorId,
    accountId,
    page,
    size,
    sort,
    isAsc,
    callback,
    errorCallback
  ) => {
    let s = {
      visitorId,
      accountId,
      page,
      size,
      sort,
      isAsc,
    };
    var req = requests.getSimpleGetRequest(CUSTOMER.findCustomerInteraction, s);

    FaAxios(req)
      .then((response) => {
        callback(response.data.list);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  activate: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(CUSTOMER.activate + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  deactivate: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(CUSTOMER.deactivate + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  allow: (id, type, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      CUSTOMER.allow + "/" + id + "/" + type
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  allowWithDocument: (
    id,
    type,
    name,
    description,
    document,
    callback,
    errorCallback
  ) => {
    let formData = new FormData();
    formData.append("multipartFile", document.file, document.file.name);
    formData.append("id", id);
    formData.append("type", type);
    formData.append("name", name);
    formData.append("description", description);

    let req = requests.getSimplePostRequest(
      CUSTOMER.allowWithDocument,
      formData
    );
    req["header"] = { "Content-Type": "multipart/form-data" };

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  remove: (id, type, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      CUSTOMER.remove + "/" + id + "/" + type
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  anonymize: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(CUSTOMER.anonym + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  unsubscribe: (customerId, accountId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      UNSUBSCRIBE.remove + "/" + customerId + "/" + accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  beneficiaryNoteReply: (reply, files, callback, errorCallback) => {
    let reqBody = {
      ...reply,
    };

    let req = requests.getSimplePostRequest(
      PUBLIC.beneficiaryNoteReply,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        if (files != null && files.length > 0) {
          let fd = new FormData();
          files.forEach((item) => {
            //fd.append("multipartFiles", item.file, item.file.name);
            fd.append("multipartFiles", item.file, item.name);
          });

          let req2 = requests.getSimplePostRequest(
            PUBLIC.uploadBeneficiaryNote + "/" + response.data,
            fd
          );
          req2["header"] = { "Content-Type": "multipart/form-data" };
          FaAxios(req2)
            .then((response2) => {
              callback(response.data);
            })
            .catch((error) => {
              errorCallback(error?.response?.data);
            });
        } else {
          callback(response.data);
        }
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  removeFromList: (customerId, customerListId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      CUSTOMER.removeFromList +
        "?customerId=" +
        customerId +
        "&customerListId=" +
        customerListId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
