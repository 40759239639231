import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { SLA_CONTRACT_LEVEL } from "./faApi";

export const SlaContractLevelService = {
  save: (location, callback, errorCallback) => {
    let reqBody = {
      ...location,
    };

    let req = requests.getSimplePostRequest(SLA_CONTRACT_LEVEL.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findBySlaContract: (slaContractId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      SLA_CONTRACT_LEVEL.findBySlaContract + "?slaContractId=" + slaContractId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findByThing: (thingId, slaContractId, task, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      SLA_CONTRACT_LEVEL.findByThing +
        "?1=1" +
        (thingId ? "&thingId=" + thingId : "") +
        (slaContractId ? "&slaContractId=" + slaContractId : "") +
        (task ? "&task=" + task : "")
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findByAccount: (accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      SLA_CONTRACT_LEVEL.findByAccount + "?accountId=" + accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  delete: (obj, callback, errorCallback) => {
    let reqBody = {
      ...obj,
    };

    let req = requests.getSimplePostRequest(SLA_CONTRACT_LEVEL.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
