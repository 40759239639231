import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function SurveyQuestionTypeSelect(props) {
  const { value, onChange, required } = props;
  const classes = useStyles();
  const { t } = useTranslation("survey", { i18n });

  return (
    <FormControl variant="outlined" className={classes.w100} size="small">
      <InputLabel>{t("SURVEY_QUESTION_TYPE")}</InputLabel>
      <Select
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        required={required}
        label={t("SURVEY_QUESTION_TYPE")}
      >
        <MenuItem value={null}>...</MenuItem>
        <MenuItem value={"MULTIPLE_CHOICE"}>{t("MULTIPLE_CHOICE")}</MenuItem>
        <MenuItem value={"CHECKBOX"}>{t("CHECKBOX")}</MenuItem>
        <MenuItem value={"STAR_RATING"}>{t("STAR_RATING")}</MenuItem>
        <MenuItem value={"DROPDOWN"}>{t("DROPDOWN")}</MenuItem>
        <MenuItem value={"YES_NO"}>{t("YES_NO")}</MenuItem>
        <MenuItem value={"TEXT"}>{t("TEXT")}</MenuItem>
        <MenuItem value={"COMMENT"}>{t("COMMENT")}</MenuItem>
        <MenuItem value={"INTEGER"}>{t("INTEGER")}</MenuItem>
        <MenuItem value={"DECIMAL"}>{t("DECIMAL")}</MenuItem>
        <MenuItem value={"DATE"}>{t("DATE")}</MenuItem>
        <MenuItem value={"TIME"}>{t("TIME")}</MenuItem>
        <MenuItem value={"DATE_TIME"}>{t("DATE_TIME")}</MenuItem>
        <MenuItem value={"PERCENTAGE"}>{t("PERCENTAGE")}</MenuItem>
        <MenuItem value={"MATRIX"}>{t("MATRIX")}</MenuItem>
        <MenuItem value={"RANKING"}>{t("RANKING")}</MenuItem>
        <MenuItem value={"SLIDER"}>{t("SLIDER")}</MenuItem>
        <MenuItem value={"MENU_MATRIX"}>{t("MENU_MATRIX")}</MenuItem>
        <MenuItem value={"NET_PROMOTER_SCORE"}>
          {t("NET_PROMOTER_SCORE")}
        </MenuItem>
        <MenuItem value={"FILE"}>{t("FILE")}</MenuItem>
      </Select>
    </FormControl>
  );
}
