import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import ThingsSteps from "../ThingsSteps";
import TutorialElement from "../TutorialElement";

export default function MyThingsBeginnerTutorial() {
  const stepsRef = useRef();
  const myThingsRef = useRef();
  const thingDetailRef = useRef();

  const { t } = useTranslation("", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);
  const [thingId, setThingId] = useState(null);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            <b>
              Etkileşim Noktaları’nın toplu olarak gösterildiği Menü/Sayfa’dır.
            </b>
            <br />
            <br />
            Bu menüde;
            <ul>
              <li>
                Etkileşim Noktalarının <b>detay bilgilerine</b> erişebilirsiniz.
              </li>
              <li>Yeni Etkileşim Noktası ekleyebilirsiniz.</li>
            </ul>
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-section='MY_THINGS']",
        intro: (
          <TutorialElement icon="tutorial">
            Etkileşim Noktası <b>detay bilgilerine</b> erişmek için herhangi
            birine tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='ADD']",
        intro: (
          <TutorialElement icon="tutorial">
            <b>Yeni Etkileşim Noktası</b> eklemek için tıklayın.
          </TutorialElement>
        ),
      },
      {
        intro: (
          <TutorialElement icon="tutorial">
            <b>ÖNEMLİ NOT:</b> <br />
            <br />
            Yeni kayıt için sadece Etkileşim Noktası Tipi zorunlu alandır ve
            varsayılan nokta tipi “Thing” olarak gelir. Diğer bütün bilgiler
            daha sonra güncellenebilir.
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          $("[data-fa-button='ADD']")[0].firstElementChild.click();
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: ".MuiFormControlLabel-root",
        intro: (
          <TutorialElement icon="tutorial">
            <b>Detaylı</b> kayıt yapmak için tıklayın.
          </TutorialElement>
        ),
        position: "right",
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "#dialog-form > div > div:nth-child(2)",
        intro: (
          <TutorialElement icon="tutorial">
            <b>Etkileşim Noktası</b> seçmek için tıklayın veya varsayılanı
            “Think” olarak bırakın.
          </TutorialElement>
        ),
        position: "right",
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "#dialog-form > div > div:nth-child(3)",
        intro: (
          <TutorialElement icon="tutorial">
            İsteğe bağlı olarak <b>Açıklama</b> bilgisi girin.
          </TutorialElement>
        ),
        position: "right",
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "#dialog-form > div > div:nth-child(4)",
        intro: (
          <TutorialElement icon="tutorial">
            İsteğe bağlı olarak <b>Envanter No</b> bilgisi girin.
          </TutorialElement>
        ),
        position: "right",
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element:
          "div.MuiDialogContent-root.makeStyles-dialogContent-82.MuiDialogContent-dividers > div > div > p",
        intro: (
          <TutorialElement icon="tutorial">
            <b>Görsel</b> yüklemek için tıklayın.
          </TutorialElement>
        ),
        position: "right",
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element:
          "div.MuiDialogContent-root.makeStyles-dialogContent-82.MuiDialogContent-dividers > div",
        intro: (
          <TutorialElement icon="tutorial">
            Etkileşim noktanızın Görselini ekleyin
          </TutorialElement>
        ),
        position: "right",
        onBeforeChange: (index) => {
          $(
            "div.MuiDialogContent-root.makeStyles-dialogContent-82.MuiDialogContent-dividers > div > div > button:nth-child(2)"
          ).click();
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "[aria-label='close']",
        intro: (
          <TutorialElement icon="tutorial">
            Kayıt sayfasına dönmek için tıklayın.
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "[type='submit']",
        intro: (
          <TutorialElement icon="tutorial">Kaydet'e tıklayın.</TutorialElement>
        ),
        position: "right",
        onBeforeChange: (index) => {
          $("[aria-label='close']").click();
          stepsRef.current.updateStepElement(index);
        },
      },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={"my_things_beginner_tutorial"}
        steps={steps}
        ref={stepsRef}
        onStepChange={setStepIndex}
        afterComplete={() => {
          var el = $("[aria-label='close']");
          if (el != null && el[0] != null) el[0].click();
        }}
      />
    </div>
  );
}
