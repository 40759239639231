import { IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Check";
import React from "react";

export default function RefreshPeriodButton(props) {
  const { anchorEl, setAnchorEl, refreshPeriod, setRefreshPeriod, title } =
    props;

  const changeRefreshPeriod = (param) => {
    setRefreshPeriod(param);
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={title ?? "Yenileme Periyodu"}>
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <Settings />
        </IconButton>
      </Tooltip>
      <Menu
        id="refresh-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem disabled={true}>{"Otomatik Yenileme"}</MenuItem>
        <MenuItem
          onClick={() => {
            changeRefreshPeriod(15);
          }}
        >
          15 Saniye {refreshPeriod === 15 ? <CheckIcon /> : null}
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeRefreshPeriod(30);
          }}
        >
          30 Saniye {refreshPeriod === 30 ? <CheckIcon /> : null}
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeRefreshPeriod(60);
          }}
        >
          1 Dakika {refreshPeriod === 60 ? <CheckIcon /> : null}
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeRefreshPeriod(120);
          }}
        >
          2 Dakika {refreshPeriod === 120 ? <CheckIcon /> : null}
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeRefreshPeriod(300);
          }}
        >
          5 Dakika {refreshPeriod === 300 ? <CheckIcon /> : null}
        </MenuItem>
      </Menu>
    </>
  );
}
