import { CardHeader, CircularProgress, Link } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { green, grey } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { getDateTimeWFormat } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { ISSUE } from "../../services/faApi";
import { useStyles } from "./style";
import ThingIssuePhotosCarousel from "./ThingIssuePhotosCarousel";

export default function ThingEvents(props) {
  const { thingId } = props;

  const { t } = useTranslation("thingDetail", { i18n });

  const [issues, setIssues] = useState([]);
  const [issueImages, setIssueImages] = useState([]);
  const [issueHdImages, setIssueHdImages] = useState([]);
  const classes = useStyles();
  const [isCarouselOpen, setCarouselOpen] = useState(false);
  const [selectedHdImageIndex, setSelectedHdImageIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    if (issues) {
      issues.map((item) => {
        refreshIssueImages(item.id);
      });
    }
  }, [issues]);

  const refresh = () => {
    const queryParams = {
      thingId: props.thingId,
      page: 0,
      size: 10,
      sort: "notificationDate",
      isAsc: false,
    };
    var req = requests.getSimpleGetRequest(ISSUE.findByThing, queryParams);
    FaAxios(req)
      .then((response) => {
        console.log("response2", response);
        setIssues(response.data.list);
      })
      .catch((error) => {
        //showAlert(error.message, "error");
        //setLoading(false);
      });
    return () => {
      //setLoading(false);
    };
  };

  const refreshIssueImages = (issueId) => {
    console.log("refreshIssueImages", issueId);
    const req = requests.getSimplePostRequest(
      ISSUE.downloadThumbnails + "/" + issueId
    );
    FaAxios(req)
      .then((response) => {
        console.log(response.data);
        setIssueImages((prev) => [
          ...prev,
          { issueId: issueId, images: response.data },
        ]);
      })
      .catch((error) => {
        console.log("refreshIssueImages error", issueId);
      });
  };

  const GreenSwitch = withStyles({
    switchBase: {
      color: grey[300],
      "&$checked": {
        color: green[500],
      },
      "&$checked + $track": {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const statusFormatter = (value) => {
    if (value === "OPEN") {
      return (
        <FormControlLabel
          control={<GreenSwitch disabled size="small" />}
          label={t("ACIK")}
        />
      );
    } else {
      return (
        <FormControlLabel
          disabled
          control={<Switch checked size="small" />}
          label={t("KAPALI")}
        />
      );
    }
  };

  const openCarousel = (issueId, index) => {
    console.log("open car");
    getIssueHdImages(issueId);
    setSelectedHdImageIndex(index);
  };

  const getIssueHdImages = (issueId) => {
    setLoading(true);
    const req = requests.getSimplePostRequest(
      ISSUE.downloadFiles + "/" + issueId
    );
    FaAxios(req)
      .then((response) => {
        console.log(response.data);
        setIssueHdImages(response.data);
        setCarouselOpen(true);
        setLoading(false);
      })
      .catch((error) => {
        console.log("refreshIssueImages error", issueId);
        setLoading(false);
      });
  };

  const closeCarousel = () => {
    console.log("open car");
    setCarouselOpen(false);
  };

  const renderIssueImages = (images, issueId) => {
    if (images) {
      return (
        <ImageList rowHeight={70} className={classes.imageList} cols={5}>
          {images.map((item, index) => (
            <ImageListItem key={index} cols={images.length == 1 ? 2 : 1}>
              <img
                src={`data:image/png;base64, ${item.content}`}
                alt={item.object}
                onClick={() => {
                  openCarousel(issueId, index);
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      );
    } else return null;
  };

  return (
    <>
      <Dialog
        open={isCarouselOpen}
        aria-labelledby="form-dialog-title"
        onClose={closeCarousel}
      >
        <DialogContent>
          <ThingIssuePhotosCarousel
            images={issueHdImages}
            selectedItem={selectedHdImageIndex}
          />
        </DialogContent>
      </Dialog>

      {loading && (
        <Dialog open={true} aria-labelledby="form-dialog-title">
          <DialogContent>
            <CircularProgress color="inherit" size={20} />
          </DialogContent>
        </Dialog>
      )}

      <Card>
        <CardHeader title="Sorunlar" />
        <CardContent>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width="15%">Tarih</TableCell>
                  <TableCell width="25%">Fotoğraf</TableCell>
                  <TableCell width="20%">Sorun</TableCell>
                  <TableCell width="30%">Açıklama</TableCell>
                  <TableCell width="10%">İşlem</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {issues?.map((notification, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {getDateTimeWFormat(notification.notificationDate)}
                    </TableCell>
                    <TableCell>
                      {issueImages
                        ?.filter((item) => item.issueId == notification.id)
                        .map((ii, index2) =>
                          renderIssueImages(ii.images, ii.issueId)
                        )}
                    </TableCell>
                    <TableCell>
                      {notification?.issueClass?.className}{" "}
                      {notification?.solutionType}
                    </TableCell>
                    <TableCell>{notification?.description}</TableCell>
                    <TableCell>
                      <Link
                        onClick={() => {
                          props.onIssueDetail(notification);
                        }}
                      >
                        Detay
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
}
