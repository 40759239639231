import { FormControlLabel, Switch, withStyles } from "@material-ui/core";
import { green, grey } from "@material-ui/core/colors";
import React from "react";

export default function ThingsSwitch(props) {
  const { value, label, onChange } = props;

  const GreenSwitch = withStyles({
    switchBase: {
      color: grey[300],
      "&$checked": {
        color: green[500],
      },
      "&$checked + $track": {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

  return (
    <FormControlLabel
      label={label}
      labelPlacement="end"
      control={
        <GreenSwitch
          checked={value}
          onChange={(event) => {
            if (onChange) onChange(event.target.checked);
          }}
        />
      }
    />
  );
}
