import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function ThingProductTypeSelect(props) {
  const { value, onChange, required, product, service, equipment } = props;
  const classes = useStyles();
  const { t } = useTranslation("components", { i18n });

  return (
    <FormControl variant="outlined" className={classes.w100} size="small">
      <InputLabel>{t("THING_PRODUCT_TYPE.NAME")}</InputLabel>
      <Select
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        required={required}
        label={t("THING_PRODUCT_TYPE.NAME")}
      >
        <MenuItem value={null}>...</MenuItem>
        {((!product && !service && !equipment) || product) && (
          <MenuItem value={"PRODUCT"}>
            {t("THING_PRODUCT_TYPE.PRODUCT")}
          </MenuItem>
        )}
        {((!product && !service && !equipment) || service) && (
          <MenuItem value={"SERVICE"}>
            {t("THING_PRODUCT_TYPE.SERVICE")}
          </MenuItem>
        )}
        {((!product && !service && !equipment) || equipment) && (
          <MenuItem value={"EQUIPMENT"}>
            {t("THING_PRODUCT_TYPE.EQUIPMENT")}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
}
