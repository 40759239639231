import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardHeader, Grid, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import ThingDetail from "components/detail/ThingDetail";
import ThingsDataTable from "components/thingsDataTable";
import { default as React, Suspense, useEffect, useState } from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { useTranslation } from "react-i18next";
import { ProjectService } from "services/ProjectService";
import { isTNF } from "services/utils";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";

export default function ProjectThings(props) {
  const { project } = props;
  const { t } = useTranslation("project", { i18n });
  const accountId = localStorage.accountId;
  const faDialog = useDialog();

  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [projectThingList, setProjectThingList] = useState([]);
  const [thing, setThing] = useState(null);

  const columns = [
    {
      field: "thing",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      width: "90%",
      valueFormatter: (value, row) => {
        return <ThingDetail value={row.thing} />;
      },
    },
    {
      field: "id",
      label: t("İşlem"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        return (
          <IconButton
            onClick={() => deleteThing(row)}
            size="small"
            color="primary"
            aria-label={t("Sil")}
          >
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        );
      },
    },
  ];

  useEffect(() => {
    refresh();
  }, [project]);

  const refresh = () => {
    if (project && project.id > 0) {
      ProjectService.findThingsByProject(
        project.id,
        findByOnSuccess,
        findByOnError
      );
    }
  };

  const findByOnSuccess = (data) => {
    setProjectThingList(data.list);
  };

  const findByOnError = (error) => {
    console.log(error);
  };

  const addThing = () => {
    const data = { project, thing };
    ProjectService.saveThing(data, saveOnSuccess, saveOnError);
  };

  const saveOnSuccess = (data) => {
    refresh();
    setIsEditFormOpen(false);
  };

  const saveOnError = (data) => {};

  const deleteThing = (data) => {
    faDialog({
      description: t("Kaydı silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        ProjectService.deleteThing(data, deleteOnSuccess, deleteOnError);
      })
      .catch((err) => console.log("error:", err));
  };

  const deleteOnSuccess = (data) => {
    refresh();
  };

  const deleteOnError = (data) => {};

  const renderEditForm = () => {
    return (
      <>
        <FaDialog
          title={"Varlık Tanımlama"}
          faOpen={isEditFormOpen}
          faOnSubmit={addThing}
          //loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            setIsEditFormOpen(data);
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <ThingAutoComplete
                value={thing}
                accountId={accountId}
                onChange={(data) => {
                  setThing(data);
                }}
                required
              />
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {renderEditForm()}
        <Card style={{ minHeight: 100 }}>
          <CardHeader
            action={
              <IconButton aria-label="settings">
                <AddIcon
                  onClick={() => {
                    setIsEditFormOpen(true);
                    setThing(null);
                  }}
                />
              </IconButton>
            }
            title={t("PROJECT_THINGS")}
            data-fa-section="PROJECT_THINGS"
          />

          <ThingsDataTable
            data={projectThingList}
            columns={columns}
            //ref={tableRef}
            showDeleteButton={false}
            showHeaderText={false}
            headerText={t("PROJECT_THINGS")}
            showPaging={false}
            showHeader={false}
          />
        </Card>
      </Suspense>
    </>
  );
}
