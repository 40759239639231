import { CardHeader } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import IssueSolutionsHelp from "features/help/IssueSolutionsHelp";
import SolutionCreate from "features/solutions/SolutionCreate";
import FeatureNotAllowed from "features/subscriptions/FeatureNotAllowed";
import React, { useState } from "react";
import {
  getGroups,
  getOrganizations,
  getRole,
  getUserInfo,
} from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROLES } from "services/utils/Roles";
import { checkRole, checkRoleByIssue } from "services/utils/SecurityUtils";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import { smartTimeDiff } from "../../services/utils";
import { useStyles } from "./IssueDetailCardStyle";

export default function IssueSolutionsCard(props) {
  const { thing, solutionList, issue, refresh } = props;
  const { t } = useTranslation("issueDetail", { i18n });
  const classes = useStyles();
  const labelClasses = labelStyles();
  const userInfo = useSelector(getUserInfo);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  let history = useHistory();

  const userRole = useSelector(getRole);
  const userGroups = useSelector(getGroups);
  const userOrganizations = useSelector(getOrganizations);

  let isIssueEditable = checkRoleByIssue(
    [ROLES.OPERATOR, ROLES.THINGMANAGER, ROLES.POWER_USER, ROLES.ACCOUNTOWNER],
    userRole,
    userInfo,
    userGroups,
    userOrganizations,
    issue
  );
  let isServiceOperationMenuShow = checkRole(
    [ROLES.OPERATOR, ROLES.THINGMANAGER, ROLES.POWER_USER, ROLES.ACCOUNTOWNER],
    userRole
  );
  let isServiceOperationMenu = checkRole([ROLES.SERVICE_OPERATION], userRole);
  let isTaskManagement = checkRole([ROLES.TASK_MANAGEMENT], userRole);
  const [subscriptionError, setSubscriptionError] = useState(false);

  const openDialog = () => {
    if (isTaskManagement) setIsDialogOpen(true);
    else {
      setSubscriptionError(true);
    }
  };

  const onSave = () => {
    setIsDialogOpen(false);
    refresh();
  };

  const onClose = () => {
    setIsDialogOpen(false);
  };

  const goToSolutionDetail = (solutionId) => {
    const tmpTo = {
      pathname: `/solutions/detail/${solutionId}`,
      breadCrumbKey: `/solutions/detail/:id`,
    };
    history.push(tmpTo);
  };

  const renderDialog = () => {
    let value = {
      id: "",
      notificationDate: new Date(),
      thing: thing,
      user: { ...userInfo },
      description: "",
      solutionType: 1,
      serviceOperationClasses: [],
      solutionStocks: [],
      formSection: false,
      laborTime: null,
    };

    console.log("value thing", value?.thing?.thid);
    console.log("thing1", thing);
    return (
      <SolutionCreate
        onSave={onSave}
        //onDelete={onDelete}
        onClose={onClose}
        value={value}
        open={isDialogOpen}
        defaultIssue={issue}
      ></SolutionCreate>
    );
  };
  return (
    <>
      {isDialogOpen && renderDialog()}
      {subscriptionError && (
        <FeatureNotAllowed
          open={subscriptionError}
          onClose={() => {
            setSubscriptionError(false);
          }}
        />
      )}
      <Card style={{ minHeight: 250, overflowY: "auto" }}>
        <CardHeader
          title={t("SOLUTION_INFO")}
          data-fa-section="SOLUTION_INFO"
          action={
            <>
              <IssueSolutionsHelp value="issueDetail.solutions" />
              {isIssueEditable && (
                <IconButton
                  data-fa-button="SOLUTION_INFO_ADD"
                  aria-label="settings"
                  onClick={() => {
                    openDialog();
                  }}
                >
                  <AddIcon />
                </IconButton>
              )}
            </>
          }
        />
        <CardContent>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableBody>
                {solutionList?.map((solution, index) => (
                  <TableRow
                    key={index}
                    onDoubleClick={() => {
                      if (isServiceOperationMenu && isServiceOperationMenuShow)
                        goToSolutionDetail(solution.id);
                    }}
                    hover
                    className={classes.hover}
                  >
                    <TableCell className={labelClasses.LabelValue}>
                      {smartTimeDiff(solution.notificationDate)}
                    </TableCell>
                    <TableCell className={labelClasses.LabelValue}>
                      {solution.user.fullname}
                    </TableCell>
                    <TableCell className={labelClasses.LabelValue}>
                      {t(solution.solutionType)} - {solution.description}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
}
