import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import SlaContractAutoComplete from "components/autocomplete/SlaContractAutoComplete";
import LoadingBar from "components/loadingBar";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getMonthYearWFormat } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import { SlaContractService } from "../../services/SlaContractService";
import { useStyles } from "./style";

export default function SlaContractMonitor() {
  const faDialog = useDialog();
  const { id } = useParams();

  const labelClasses = labelStyles();

  const classes = useStyles();

  const { t } = useTranslation("slaContract", { i18n });
  const tableRef = useRef();

  const accountId = localStorage.accountId;
  const [loading, setLoading] = useState(false);
  const [slaContract, setSlaContract] = useState(null);

  const [monitor, setMonitor] = useState(null);

  useEffect(() => {
    if (slaContract && accountId && slaContract.id > 0) {
      refresh();
    }
  }, [slaContract, accountId]);

  useEffect(() => {
    if (id) {
      SlaContractService.findById(id, setSlaContract, (error) => {});
    }
  }, [id]);

  const refresh = () => {
    SlaContractService.monitor(
      slaContract.id,
      monitorOnSuccess,
      monitorOnError
    );
  };

  const monitorOnSuccess = (data) => {
    setMonitor(data);
  };

  const monitorOnError = (data) => {};

  const hourToFormat = (hour) => {
    let MINUTES = hour * 60;

    var m = MINUTES % 60;

    var h = (MINUTES - m) / 60;

    var HHMM =
      (h < 10 ? "0" : "") +
      Math.floor(h).toString() +
      ":" +
      (m < 10 ? "0" : "") +
      Math.floor(m).toString();

    return HHMM;
  };

  const renderDetail = (levelId, date, type) => {
    const detail = monitor.detailList.filter(
      (value) => value.date === date && value.levelId === levelId
    )[0];
    console.log(levelId, date, detail);

    if (type === 1) {
      return (
        <>
          <TableCell className={labelClasses.LabelValue + " " + classes.right}>
            {detail.issueCount}
          </TableCell>
          <TableCell className={labelClasses.LabelValue + " " + classes.right}>
            {detail.activeIssue}
          </TableCell>
          <TableCell className={labelClasses.LabelValue + " " + classes.right}>
            {hourToFormat(detail.responseExceedHour)}
          </TableCell>
          <TableCell
            className={
              labelClasses.LabelValue +
              " " +
              classes.tableSeparator +
              " " +
              classes.right
            }
          >
            {detail.responsePenalty}
          </TableCell>
        </>
      );
    } else {
      return (
        <>
          <TableCell className={labelClasses.LabelValue + " " + classes.right}>
            {detail.issueCount}
          </TableCell>
          <TableCell className={labelClasses.LabelValue + " " + classes.right}>
            {detail.activeIssue}
          </TableCell>
          <TableCell className={labelClasses.LabelValue + " " + classes.right}>
            {hourToFormat(detail.solutionExceedHour)}
          </TableCell>
          <TableCell
            className={
              labelClasses.LabelValue +
              " " +
              classes.tableSeparator +
              " " +
              classes.right
            }
          >
            {detail.solutionPenalty}
          </TableCell>
        </>
      );
    }
  };

  const renderTable = () => {
    return (
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.tableSeparator + " " + classes.center}
                rowSpan={3}
                width={100}
              >
                Dönem
              </TableCell>
              <TableCell
                className={classes.tableSeparator + " " + classes.center}
                rowSpan={3}
                width={100}
              >
                Tür
              </TableCell>
              <TableCell
                className={classes.tableSeparator + " " + classes.center}
                colSpan={monitor?.levelList?.length * 4}
              >
                Ağırlık
              </TableCell>
            </TableRow>
            <TableRow>
              {monitor?.levelList.map((level, index) => (
                <TableCell
                  className={classes.tableSeparator + " " + classes.center}
                  colSpan={4}
                >
                  {level.name}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              {monitor?.levelList.map((level, index) => (
                <>
                  <TableCell width={50} className={classes.right}>
                    #
                  </TableCell>
                  <TableCell width={50} className={classes.right}>
                    Aktif
                  </TableCell>
                  <TableCell width={70} className={classes.right}>
                    Gecikme
                  </TableCell>
                  <TableCell
                    width={70}
                    className={classes.right + " " + classes.tableSeparator}
                  >
                    Ceza
                  </TableCell>
                </>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {monitor?.dateList.map((date, index) => (
              <>
                <TableRow key={"md1_" + index} className={classes.striptedBg}>
                  <TableCell
                    rowSpan={2}
                    className={
                      labelClasses.LabelValue + " " + classes.tableSeparator
                    }
                  >
                    {getMonthYearWFormat(date)}
                  </TableCell>
                  <TableCell
                    className={
                      labelClasses.LabelValue + " " + classes.tableSeparator
                    }
                  >
                    {t("RESPONSE")}
                  </TableCell>
                  {monitor?.levelList.map((level, index) =>
                    renderDetail(level.id, date, 1)
                  )}
                </TableRow>
                <TableRow key={"md2_" + index}>
                  <TableCell
                    className={
                      labelClasses.LabelValue + " " + classes.tableSeparator
                    }
                  >
                    {t("SOLUTION")}
                  </TableCell>
                  {monitor?.levelList.map((level, index) =>
                    renderDetail(level.id, date, 0)
                  )}
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item lg={12}>
            <Card>
              <CardHeader
                title={t("SLA_CONTRACT_DETAIL")}
                data-fa-section="SLA_CONTRACT_DETAIL"
              />
              <CardContent>
                <Grid item container spacing={0}>
                  <Grid item xs={12}>
                    <Grid item container spacing={1}>
                      <Grid item xs={6} className={labelClasses.LabelLastRow}>
                        <SlaContractAutoComplete
                          value={slaContract}
                          accountId={accountId}
                          onChange={(data) => {
                            setSlaContract(data);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item lg={12}>
            <Card>
              <CardHeader
                title={t("SLA_MONITOR")}
                data-fa-section="SLA_MONITOR"
              />
              <CardContent>{monitor && renderTable()}</CardContent>
            </Card>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
