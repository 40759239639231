import { Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaButton, FaDialog, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { VendorService } from "services/VendorService";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { VENDOR } from "../../services/faApi";

export default function Vendor() {
  const tableRef = useRef();
  const { t } = useTranslation("vendor", { i18n });
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const accountId = localStorage.accountId;

  const [loading, setLoading] = useState(false);
  const [isRegisterFormOpen, setIsRegisterFormOpen] = useState(false);

  const columns = [
    {
      field: "name",
      label: t("NAME"),
      align: "center",
      width: "30%",
    },
    {
      field: "email",
      label: t("EMAIL"),
      align: "center",
      width: "20%",
    },
    {
      field: "phone1",
      label: t("PHONE1"),
      align: "center",
      width: "10%",
    },
    {
      field: "phone2",
      label: t("PHONE2"),
      align: "center",
      width: "10%",
    },
    {
      field: "fax",
      label: t("FAX"),
      align: "center",
      width: "10%",
    },
    {
      field: "description",
      label: t("DESCRIPTION"),
      align: "center",
      width: "20%",
    },
  ];

  const newVendor = () => {
    return {
      id: "",
      name: "",
      email: "",
      phone1: "",
      phone2: "",
      fax: "",
      description: "",
    };
  };
  const [vendor, setVendor] = useState(newVendor());

  const handleChange = (prop) => (event) => {
    setVendor({ ...vendor, [prop]: event.target.value });
  };

  const saveSuccess = (data) => {
    showAlert(!vendor.id ? t("SAVED") : t("EDITED"), "success");
    setIsRegisterFormOpen(false);
    //setVendor(newVendor());
    tableRef.current.refreshTable();
    setLoading(false);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const saveVendor = () => {
    setLoading(true);
    VendorService.save(
      {
        ...vendor,
        account: { id: accountId },
      },
      saveSuccess,
      saveError
    );
  };

  const newSearchForm = () => {
    let result = {
      name: null,
      description: null,
      accountId: accountId,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    setVendor(row);
    setIsRegisterFormOpen(true);
  };

  const newVendorRegisterRender = () => {
    return (
      <>
        <FaDialog
          title={t("EDIT_VENDOR")}
          faOpen={isRegisterFormOpen}
          faOnSubmit={saveVendor}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            setIsRegisterFormOpen(data);
            setVendor(newVendor());
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <FaInput
                required
                inputProps={{ maxLength: 100 }}
                label={t("NAME")}
                onChange={handleChange("name")}
                value={vendor.name || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                inputProps={{ maxLength: 100 }}
                label={t("EMAIL")}
                onChange={handleChange("email")}
                value={vendor.email || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                inputProps={{ maxLength: 100 }}
                label={t("PHONE1")}
                onChange={handleChange("phone1")}
                value={vendor.phone1 || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                inputProps={{ maxLength: 100 }}
                label={t("PHONE2")}
                onChange={handleChange("phone2")}
                value={vendor.phone2 || ""}
              />
            </Grid>

            <Grid item xs={12}>
              <FaInput
                inputProps={{ maxLength: 100 }}
                label={t("FAX")}
                onChange={handleChange("fax")}
                value={vendor.fax || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                inputProps={{ maxLength: 100 }}
                label={t("DESCRIPTION")}
                onChange={handleChange("description")}
                value={vendor.description || ""}
              />
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {newVendorRegisterRender()}
        <ThingsDataTable
          searchUrl={VENDOR.search + "?accountId=" + localStorage.accountId}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={2} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                setIsRegisterFormOpen(true);
                setVendor(newVendor());
              }}
              startIcon={<AddIcon />}
              data-fa-button="ADD"
            >
              {t("ADD")}
            </FaButton>
          </Grid>
          <Grid item xs={4}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <FaInput
                label={t("NAME")}
                value={searchForm.name}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "name",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("DESCRIPTION")}
                value={searchForm.description}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "description",
                    event.target.value
                  );
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
