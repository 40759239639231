import { Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import {
  FaButton,
  FaDatePicker,
  FaDialog,
  FaInput,
  FaSearch,
} from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LoadingBar from "../../components/loadingBar";
import SlaContractStatusSelect from "../../components/select/SlaContractStatusSelect";
import i18n from "../../i18n";
import { SlaContractService } from "../../services/SlaContractService";
import { SLA_CONTRACT } from "../../services/faApi";
import { useStyles } from "./style";

export default function SlaContract() {
  const tableRef = useRef();
  const classes = useStyles();
  const { t } = useTranslation("slaContract", { i18n });
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const accountId = localStorage.accountId;

  const [loading, setLoading] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  const columns = [
    {
      field: "name",
      label: t("NAME"),
      align: "center",
      width: "25%",
    },
    {
      field: "description",
      label: t("DESCRIPTION"),
      align: "center",
      width: "35%",
    },
    {
      field: "startDate",
      label: t("START_DATE"),
      align: "center",
      width: "15%",
      type: "dateTime",
    },
    {
      field: "endDate",
      label: t("END_DATE"),
      align: "center",
      width: "15%",
      type: "dateTime",
    },
    {
      field: "status",
      label: t("STATUS"),
      align: "center",
      width: "10%",
      valueFormatter: (val) => {
        return t("" + val);
      },
    },
  ];

  const newSlaContract = () => {
    return {
      id: "",
      name: "",
      startDate: new Date(),
      endDate: null,
      status: "ACTIVE",
      type: "SLA",
      maxPenaltyPerIssue: null,
      maxPenaltyPerContract: null,
    };
  };

  const [slaContract, setSlaContract] = useState(newSlaContract());

  const handleChange = (prop) => (event) => {
    setSlaContract({ ...slaContract, [prop]: event.target.value });
  };

  const handleChange2 = (prop, val) => {
    setSlaContract({ ...slaContract, [prop]: val });
  };

  const saveSuccess = (data) => {
    showAlert(!slaContract.id ? t("SAVED") : t("EDITED"), "success");
    setIsEditFormOpen(false);
    setSlaContract(newSlaContract());

    setLoading(false);

    const tmpTo = {
      pathname: `/slaContract/detail/${data.id}`,
      breadCrumbKey: `/slaContract/detail/:id`,
    };
    history.push(tmpTo);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const saveSlaContract = () => {
    setLoading(true);
    SlaContractService.save(
      {
        ...slaContract,
        account: { id: accountId },
      },
      saveSuccess,
      saveError
    );
  };

  const newSearchForm = () => {
    let result = {
      name: null,
      description: null,
      status: null,
      accountId: accountId,
      type: "SLA",
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `/slaContract/detail/${row.id}`,
      breadCrumbKey: `/slaContract/detail/:id`,
    };
    history.push(tmpTo);
  };

  const renderEditWindow = () => {
    return (
      <>
        <FaDialog
          title={t("EDIT_SLA_CONTRACT")}
          faOpen={isEditFormOpen}
          faOnSubmit={saveSlaContract}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            setIsEditFormOpen(data);
            setSlaContract(newSlaContract());
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <FaInput
                required
                maxLength={100}
                label={t("NAME")}
                onChange={handleChange("name")}
                value={slaContract.name || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                maxLength={100}
                label={t("DESCRIPTION")}
                onChange={handleChange("description")}
                value={slaContract.description || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FaDatePicker
                faType="date"
                label={t("START_DATE")}
                style={{ minHeight: 65 }}
                value={slaContract.startDate}
                onChange={(value) => {
                  setSlaContract({
                    ...slaContract,
                    startDate: value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FaDatePicker
                faType="date"
                label={t("END_DATE")}
                style={{ minHeight: 65 }}
                value={slaContract.endDate}
                onChange={(value) => {
                  setSlaContract({
                    ...slaContract,
                    endDate: value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                faType="number"
                awnum="decimal-6"
                label={t("MAX_PENALTY_PER_ISSUE")}
                value={slaContract.maxPenaltyPerIssue}
                onChange={(event) => {
                  handleChange2("maxPenaltyPerIssue", event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                faType="number"
                awnum="decimal-6"
                label={t("MAX_PENALTY_PER_CONTRACT")}
                value={slaContract.maxPenaltyPerContract}
                onChange={(event) => {
                  handleChange2("maxPenaltyPerContract", event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <SlaContractStatusSelect
                value={slaContract.status}
                onChange={(data) => {
                  setSlaContract({
                    ...slaContract,
                    status: data,
                  });
                }}
              />
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {renderEditWindow()}
        <ThingsDataTable
          searchUrl={
            SLA_CONTRACT.search + "?accountId=" + localStorage.accountId
          }
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={1} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                setIsEditFormOpen(true);
              }}
              startIcon={<AddIcon />}
              data-fa-button="ADD"
            >
              {t("ADD")}
            </FaButton>
          </Grid>
          <Grid item xs={5}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <FaInput
                label={t("NAME")}
                value={searchForm.name}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "name",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("DESCRIPTION")}
                value={searchForm.description}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "description",
                    event.target.value
                  );
                }}
              />

              <SlaContractStatusSelect
                value={searchForm.status}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    status: data,
                  });
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
