import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { NOTE } from "./faApi";

export const NoteService = {
  save: (note, callback, errorCallback) => {
    let reqBody = {
      ...note,
    };

    let req = requests.getSimplePostRequest(NOTE.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
  delete: (note, callback, errorCallback) => {
    let reqBody = {
      ...note,
    };

    let req = requests.getSimplePostRequest(NOTE.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
};
