import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tab,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { default as React, Suspense, useEffect, useState } from "react";
import { FaDatePicker, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { RecurrenceService } from "services/RecurrenceService";
import { getDateWFormat, getTimeWFormat } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { useStyles } from "./style";

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function RecurrenceDetailRecurrence(props) {
  const { patrolDef, setPatrolDef } = props;
  const classes = useStyles();
  const labelClasses = labelStyles();
  const { t } = useTranslation("recurrence", { i18n });
  const { id } = useParams();
  const accountId = localStorage.accountId;
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recurence, setRecurence] = useState({});

  useEffect(() => {
    setRecurence({ ...patrolDef });
  }, [patrolDef]);

  const editButtonClicked = () => {
    setRecurence({ ...patrolDef });
    setIsModalOpen(true);
  };

  const savePatrolDef = () => {
    setLoading(true);
    const recurrenceReqObject = {
      ...recurence,
      hourlyInterval:
        recurence.frequency === "hour" ? recurence.hourlyInterval : null,
      dailyMod: recurence.frequency === "day" ? recurence.dailyMod : null,
      dailyInterval:
        recurence.frequency === "day" ? recurence.dailyInterval : null,

      weeklyInterval:
        recurence.frequency === "week" ? recurence.weeklyInterval : null,
      weeklyDays:
        recurence.frequency === "week"
          ? recurence.weeklyDays
          : [false, false, false, false, false, false, false],

      monthlyDay: recurence.frequency === "month" ? recurence.monthlyDay : null,
      monthlyInterval:
        recurence.frequency === "month" ? recurence.monthlyInterval : null,

      yearlyInterval:
        recurence.frequency === "year" ? recurence.yearlyInterval : null,
      yearlyDay: recurence.frequency === "year" ? recurence.yearlyDay : null,
      yearlyMonth:
        recurence.frequency === "year" ? recurence.yearlyMonth : null,
    };

    RecurrenceService.save(
      {
        ...recurrenceReqObject,
        account: { id: accountId },
      },
      saveSuccess,
      saveError
    );
  };

  const saveSuccess = (data) => {
    showAlert(!patrolDef.id ? t("SAVED") : t("EDITED"), "success");
    setIsModalOpen(false);
    setPatrolDef({
      ...data,
    });
    setLoading(false);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const handleTabChange = (event, newValue) => {
    setRecurence({ ...recurence, frequency: newValue });
  };

  const handleChange = (prop, val) => {
    setRecurence({ ...recurence, [prop]: val });
  };

  const handleChangeWeeklyDays = (index, checked) => {
    var wd = recurence.weeklyDays;
    wd[index] = checked;

    setRecurence({ ...recurence, weeklyDays: wd });
  };

  const handleChipDelete = (index) => () => {
    var temp = [...recurence.mtncRecurrenceTargets];
    temp.splice(index, 1);
    setRecurence({ ...recurence, mtncRecurrenceTargets: temp });
  };

  const renderDialog = () => {
    if (!isModalOpen) return null;
    return (
      <FaDialog
        title={"Yinelenme Bilgileri Güncelle"}
        faOpen={isModalOpen}
        faOnSubmit={savePatrolDef}
        showSaveButton
        faHandleClose={() => {
          setIsModalOpen(false);
        }}
        faMaxWidth="lg"
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <Typography variant="caption">{t("RECURRENCE_PERIOD")}</Typography>

            <TabContext value={recurence.frequency}>
              <Box display="flex" color="transparent" flexDirection="column">
                <TabList
                  value={recurence.frequency}
                  onChange={handleTabChange}
                  scrollButtons="auto"
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="icon label tabs example"
                >
                  <Tab label="Saatlik" value="hour" />
                  <Tab label="Günlük" value="day" />
                  <Tab label="Haftalık" value="week" />
                  <Tab label="Aylık" value="month" />
                  <Tab label="Yıllık" value="year" />
                </TabList>
                <Divider />
              </Box>
              <TabPanel value="hour">
                <div className={classes.hboxBlock}>
                  Her
                  <FaInput
                    faType={"number"}
                    value={recurence.hourlyInterval}
                    style={{ width: 90, paddingLeft: 10, paddingRight: 10 }}
                    onChange={(event) => {
                      handleChange("hourlyInterval", event.target.value);
                    }}
                  />
                  saatte bir
                </div>
              </TabPanel>
              <TabPanel value="day">
                <FormControl component="fieldset">
                  <RadioGroup
                    value={recurence.dailyMod}
                    onChange={(event) => {
                      handleChange("dailyMod", event.target.value);
                    }}
                  >
                    <div className={classes.hboxBlock}>
                      <FormControlLabel
                        value="INTERVAL"
                        control={<Radio />}
                        label=" "
                      />
                      Her
                      <FaInput
                        faType={"number"}
                        value={recurence.dailyInterval}
                        style={{ width: 90, paddingLeft: 10, paddingRight: 10 }}
                        onChange={(event) => {
                          handleChange("dailyInterval", event.target.value);
                        }}
                      />
                      günde bir
                    </div>
                    <div className={classes.hboxBlock}>
                      <FormControlLabel
                        value="WEEKDAY"
                        control={<Radio />}
                        label=" "
                      />
                      Her iş günü
                    </div>
                  </RadioGroup>
                </FormControl>
              </TabPanel>
              <TabPanel value="week">
                <div className={classes.hboxBlock}>
                  Her
                  <FaInput
                    faType={"number"}
                    value={recurence.weeklyInterval}
                    style={{ width: 90, paddingLeft: 10, paddingRight: 10 }}
                    onChange={(event) => {
                      handleChange("weeklyInterval", event.target.value);
                    }}
                  />
                  haftada bir
                </div>
                {days.map((day, index) => (
                  <FormControlLabel
                    key={index}
                    value={index}
                    control={
                      <Checkbox
                        checked={recurence.weeklyDays[index]}
                        onChange={(event) => {
                          handleChangeWeeklyDays(index, event.target.checked);
                        }}
                        name="notEmpty"
                        color="primary"
                      />
                    }
                    label={t(day)}
                  />
                ))}
              </TabPanel>
              <TabPanel value="month">
                <div className={classes.hboxBlock}>
                  Her
                  <FaInput
                    faType={"number"}
                    value={recurence.monthlyDay}
                    style={{ width: 90, paddingLeft: 10, paddingRight: 10 }}
                    onChange={(event) => {
                      handleChange("monthlyDay", event.target.value);
                    }}
                  />
                  .günü
                  <FaInput
                    faType={"number"}
                    value={recurence.monthlyInterval}
                    style={{ width: 90, paddingLeft: 10, paddingRight: 10 }}
                    onChange={(event) => {
                      handleChange("monthlyInterval", event.target.value);
                    }}
                  />
                  ayda bir
                </div>
              </TabPanel>
              <TabPanel value="year">
                <div className={classes.hboxBlock}>
                  Yineleme
                  <FaInput
                    faType={"number"}
                    value={recurence.yearlyInterval}
                    style={{ width: 90, paddingLeft: 10, paddingRight: 10 }}
                    onChange={(event) => {
                      handleChange("yearlyInterval", event.target.value);
                    }}
                  />
                  yılda bir
                </div>
                <div className={classes.hboxBlock}>
                  Şu tarihte
                  <FaInput
                    faType={"number"}
                    value={recurence.yearlyDay}
                    style={{ width: 90, paddingLeft: 10, paddingRight: 10 }}
                    onChange={(event) => {
                      handleChange("yearlyDay", event.target.value);
                    }}
                  />
                  <Select
                    className={classes.timeSelect}
                    value={recurence.yearlyMonth}
                    onChange={(event) => {
                      handleChange("yearlyMonth", event.target.value);
                    }}
                  >
                    {months.map((item, index) => (
                      <MenuItem key={index} value={index}>
                        {t(item)}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </TabPanel>
            </TabContext>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              {t("RECURRENCE_INTERVAL")}
            </Typography>

            <Grid item container spacing={1}>
              <Grid item lg={4} xs={12}>
                <FaDatePicker
                  faType="date"
                  required
                  label={t("RECURRENCE_START_DATE")}
                  style={{ minHeight: 65 }}
                  value={recurence.startDate}
                  faOnChange={(val) => {
                    handleChange("startDate", val);
                  }}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <FaDatePicker
                  faType="time"
                  required
                  label={t("START_TIME")}
                  value={recurence.startTime}
                  faOnChange={(val) => {
                    handleChange("startTime", val);
                  }}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <FaDatePicker
                  faType="date"
                  label={t("RECURRENCE_END_DATE")}
                  style={{ minHeight: 65 }}
                  value={recurence.endDate}
                  faOnChange={(val) => {
                    handleChange("endDate", val);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  const renderPeriod = () => {
    if (recurence.frequency === "hour") {
      return "Her " + recurence.hourlyInterval + " saatte bir";
    } else if (recurence.frequency === "day") {
      if (recurence.dailyMod === "INTERVAL")
        return "Her " + recurence.dailyInterval + " günde bir";
      else if (recurence.dailyMod === "WEEKDAY") return "Her iş günü";
    } else if (recurence.frequency === "week") {
      var selectedDays = days.filter(
        (day, index) => recurence.weeklyDays[index]
      );
      return (
        "Her " +
        recurence.weeklyInterval +
        " haftada bir " +
        selectedDays
          .map((item) => {
            return t("" + item);
          })
          .join(",")
      );
    } else if (recurence.frequency === "month") {
      return (
        "Her " +
        recurence.monthlyDay +
        ".günü " +
        recurence.monthlyInterval +
        " ayda bir"
      );
    } else if (recurence.frequency === "year") {
      return (
        "" +
        recurence.yearlyInterval +
        " yılda bir şu tarihte " +
        recurence.yearlyDay +
        " " +
        t(months[recurence.yearlyMonth])
      );
    }
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {renderDialog()}

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid item container spacing={1}>
              <Grid item lg={12}>
                <Card>
                  <CardHeader
                    action={
                      <IconButton
                        aria-label="settings"
                        onClick={editButtonClicked}
                        disabled={patrolDef.status === "ACTIVE"}
                      >
                        <EditIcon />
                      </IconButton>
                    }
                    title={t("RECURRENCE_INFO")}
                    data-fa-section="RECURRENCE_INFO"
                  />
                  <CardContent>
                    <Grid item container spacing={0}>
                      <Grid item xs={12}>
                        <Grid
                          item
                          container
                          spacing={1}
                          style={{ marginLeft: 10, marginRight: 10 }}
                        >
                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("START_TIME")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {getTimeWFormat(recurence.startTime)}
                            </Typography>
                          </Grid>

                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("RECURRENCE_PERIOD")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {renderPeriod()}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("RECURRENCE_START_DATE")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {getDateWFormat(recurence.startDate)}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("RECURRENCE_END_DATE")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {getDateWFormat(recurence.endDate)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
