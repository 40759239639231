import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import ThingsSwitch from "components/ui/ThingsSwitch";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { SurveyService } from "services/SurveyService";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function SurveyEndMessage(props) {
  const { survey, onSave } = props;
  const labelClasses = labelStyles();
  const { t } = useTranslation("survey", { i18n });
  const dispatch = useDispatch();
  const dialogRef = useRef();
  const classes = useStyles();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [loading, setLoading] = useState(false);
  const [surveyDTO, setSurveyDTO] = useState({});
  const [selectedLang, setSelectedLang] = useState("tr");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [endMessageEdit, setEndMessageEdit] = useState(
    EditorState.createEmpty()
  );

  useEffect(() => {
    setSurveyDTO(survey);
    if (survey.endMessage) {
      const blocksFromHTML = convertFromHTML(survey.endMessage);
      const content = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        )
      );
      setEditorState(content);
      setEndMessageEdit(content);
    }
  }, [survey]);

  const handleChangeV = (prop, val) => {
    setSurveyDTO({ ...surveyDTO, [prop]: val });
  };

  const saveSurvey = () => {
    setLoading(true);
    SurveyService.save(
      surveyDTO,
      () => {
        setIsDialogOpen(false);
        onSave();
        setLoading(false);
      },
      () => {}
    );
  };

  const onEditorStateChange = (data) => {
    setEndMessageEdit(data);
    let row = convertToRaw(data.getCurrentContent());
    setSurveyDTO({
      ...surveyDTO,
      endMessage: draftToHtml(row),
    });
  };

  const editDialogRender = () => {
    return (
      <FaDialog
        title={t("Bitiş mesajı tanımla")}
        faOpen={isDialogOpen}
        faOnSubmit={saveSurvey}
        loading={loading}
        showSaveButton
        faHandleClose={() => {
          setIsDialogOpen(false);
          setSurveyDTO(survey);
        }}
        faMaxWidth="lg"
      >
        <Grid item xs={12}>
          <ThingsSwitch
            label={t("Bitiş mesajı göster")}
            value={surveyDTO?.showEndMessage}
            onChange={(val) => {
              handleChangeV("showEndMessage", val);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            editorState={endMessageEdit}
            onEditorStateChange={onEditorStateChange}
            wrapperClassName={classes.wrapperClass}
            editorClassName={classes.editorClass}
            toolbarClassName={classes.toolbarClass}
          />
        </Grid>
      </FaDialog>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {editDialogRender()}
        <Card>
          <CardHeader
            title={t("Bitiş mesajı")}
            data-fa-section="SURVEY_INFO"
            action={
              <IconButton
                onClick={() => {
                  setIsDialogOpen(true);
                }}
              >
                <EditIcon />
              </IconButton>
            }
          />
          <CardContent>
            <Grid item container spacing={1}>
              <Grid item xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Bitiş mesajı göster")}
                </Typography>
                {survey?.showEndMessage ? "Evet" : "Hayır"}
              </Grid>
              <Grid item xs={12} className={labelClasses.LabelLastRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Bitiş mesajı")}
                </Typography>
                <Editor editorState={editorState} readOnly toolbarHidden />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Suspense>
    </>
  );
}
