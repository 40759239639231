import { Card, CardContent } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import React, { Suspense, useEffect, useState } from "react";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { STATISTIC, THING } from "../../services/faApi";
import { ThingService } from "../../services/thing/thingService";
import { useStyles } from "./style";
import ThingDetailCard from "./ThingDetailCard";
import ThingEvents from "./ThingEvents";
import ThingPhotos from "./ThingPhotos";
import ThingQR from "./ThingQR";

export default function ThingDetailFood() {
  const { t } = useTranslation("thingDetail", { i18n });
  const { id } = useParams();
  const accountId = localStorage.accountId; //useSelector(getAccountId);
  const classes = useStyles();
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState("");
  const [thing, setThing] = useState({
    thingFolder: { name: "" },
    hashtag: "",
    thingCode: "",
    thingClass: { className: "" },
    gpsLocation: "",
    id: 0,
  });
  const [images, setImages] = useState([]);
  const [openGpsLocationChangeDialog, setOpenGpsLocationChangeDialog] =
    useState(false);
  const [newGpsLocation, setNewGpsLocation] = useState("");

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    setLoading(true);
    let req = requests.getObjectByIdRequest(THING.findById, id);
    FaAxios(req)
      .then((response) => {
        setThing(response.data);
        loadChartData(response.data.id);
        const imgReq = requests.getSimpleGetRequest(THING.downloadThumbnails, {
          thingId: response.data.id,
        });
        return FaAxios(imgReq);
      })
      .then((response) => {
        setLoading(false);
        refreshImages(response);
      })
      .catch((error) => {
        showAlert(error.message, "error");
        setLoading(false);
      });
  };

  const refreshImages = (response) => {
    setImages(response.data);
    if (response?.data?.length > 0) {
      setThumbnail("data:image/jpeg;base64," + response.data[0].content);
    }
  };

  const loadChartData = (thingId) => {
    let req = requests.getSimpleGetRequest(
      STATISTIC.chartThingIssueClassCount,
      { thingId }
    );
    FaAxios(req)
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const uploadImages = async (newImages) => {
    for await (const fileObj of newImages) {
      let fd = new FormData();
      fd.append("multipartFile", fileObj, fileObj.name);
      let req = requests.getSimplePostRequest(
        THING.uploadFile + "/" + thing.id,
        fd
      );
      req["header"] = { "Content-Type": "multipart/form-data" };
      await FaAxios(req)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          showAlert(error.message, "error");
          setLoading(false);
        });
    }

    const imgReq = requests.getSimpleGetRequest(THING.downloadThumbnails, {
      thingId: thing.id,
    });
    FaAxios(imgReq)
      .then((response) => {
        refreshImages(response);
      })
      .catch((error) => {
        showAlert(error.message, "error");
        setLoading(false);
      });
  };

  const deleteImage = (fileName) => {
    const deleteReq = requests.getDeleteByIdRequest();
  };

  const issueDetail = (issue) => {
    const tmpTo = {
      pathname: `/issues/detailFood/${issue.id}`,
      breadCrumbKey: `/issues/detailFood/:id`,
    };
    history.push(tmpTo);
  };

  const gpsLocationChange = (event) => {
    setOpenGpsLocationChangeDialog(true);
    setNewGpsLocation({
      lng: event.lngLat[0],
      lat: event.lngLat[1],
    });
  };

  const closeGpsLocationDialog = () => {
    setOpenGpsLocationChangeDialog(false);
  };

  const changeGpsLocation = () => {
    ThingService.changeGpsLocation(
      thing.id,
      JSON.stringify(newGpsLocation),
      gpsLocationChanged
    );
  };

  const gpsLocationChanged = (data) => {
    setThing(data);
    setOpenGpsLocationChangeDialog(false);
  };

  const onThingInfoUpdate = (newData) => {
    setThing(newData);
    showAlert("Etkileşim noktası bilgileri güncellendi", "success");
  };

  const setDefaultImage = (objectId) => {
    ThingService.setDefaultImage(
      thing.id,
      objectId,
      setDefaultImageSuccess,
      setDefaultImageError
    );
  };

  const setDefaultImageSuccess = (data) => {
    refresh();
  };
  const setDefaultImageError = (data) => {
    showAlert("Varsayılan resim ayarlanamadı", "error");
  };

  const renderGpsLocationChangeDialog = () => {
    return (
      <Dialog
        open={openGpsLocationChangeDialog}
        onClose={closeGpsLocationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Konum değiştirilsin mi?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Haritadan seçmiş olduğunuz konum nesnenin yeni konumu olarak
            ayarlanacaktır.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeGpsLocationDialog} color="primary">
            İptal
          </Button>
          <Button onClick={changeGpsLocation} color="primary" autoFocus>
            Değiştir
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {renderGpsLocationChangeDialog()}
      <Suspense fallback={<LoadingBar />}>
        <Grid container spacing={1}>
          <Grid item lg={9}>
            <Grid item container spacing={1}>
              <Grid item xs={12} lg={6}>
                <ThingDetailCard
                  thing={thing}
                  thumbnail={thumbnail}
                  onUpdate={onThingInfoUpdate}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <ThingPhotos
                  images={images}
                  setDefaultImage={setDefaultImage}
                  uploadImages={uploadImages}
                  deleteImage={deleteImage}
                  thingId={thing.id}
                />
              </Grid>
              {/* <Grid item xs={12} lg={6}>
                <ThingAdditionalFields thing={thing} />
              </Grid> */}
              {/* <Grid item xs={12} lg={6}>
                <ThingDocuments thing={thing} />
              </Grid> */}
              {/* <Grid item xs={6}>
              <ThingStatistics type="pie" title="Sorun Sınıflarına Göre Sayılar" />
            </Grid>
            <Grid item xs={6}>
              <ThingStatistics type="pie" title="Etkileşim Noktası Tipi için Sorun Sınıflarına Göre Sayılar" />
            </Grid>
            <Grid item xs={12}>
              <ThingStatistics type="bar" title="Sorun Tarihlerine Göre İstatistikler" />
            </Grid>
            <Grid item xs={6}>
              <ThingStatistics type="line" title="Etkileşim Noktası için Ortalama Sorun Çözülme Süresi" />
            </Grid>
            <Grid item xs={6}>
              <ThingStatistics type="line" title="Etkileşim Noktası Tipi için Ortalama Sorun Çözülme Süresi" />
            </Grid> */}
              <Grid item xs={12}>
                <ThingEvents onIssueDetail={issueDetail} thingId={id} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={3}>
            {/* {faRightBar} */}

            <Card style={{ minHeight: 100, marginBottom: "10px" }}>
              <CardContent>
                <ThingQR thing={thing} onUpdate={onThingInfoUpdate} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
