import { CardHeader } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import ThingDetail from "components/detail/ThingDetail";
import UserDetail from "components/detail/UserDetail";
import React, { useRef } from "react";
import { getDateTimeWFormat } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { isTNF } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import { useStyles } from "./SolutionDetailCardStyle";
import SolutionDetailEdit from "./SolutionDetailEdit";

export default function SolutionDetailCard(props) {
  const { solution, onSave } = props;
  const dialogRef = useRef();

  const { t } = useTranslation("solutions", { i18n });
  const classes = useStyles();
  const labelClasses = labelStyles();

  return (
    <>
      <SolutionDetailEdit ref={dialogRef} solution={solution} onSave={onSave} />
      <Card style={{ minHeight: 250 }}>
        <CardHeader
          action={
            <IconButton
              aria-label="settings"
              onClick={() => {
                dialogRef.current.openDialog();
              }}
            >
              <EditIcon />
            </IconButton>
          }
          title={t("SOLUTION_INFO")}
          data-fa-section="SOLUTION_INFO"
        />
        <CardContent>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <Grid item container spacing={1}>
                <Grid item xs={12} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {isTNF(t("THING"), t("THING_T2C"))}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    <ThingDetail value={solution.thing} />
                  </Typography>
                </Grid>
                <Grid item xs={12} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("NOTIFICATION_DATE")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {getDateTimeWFormat(solution.notificationDate)}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("USER")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    <UserDetail value={solution.user} type="USER" />
                  </Typography>
                </Grid>
                <Grid item xs={12} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("SOLUTION_TYPE")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {t(solution.solutionType)}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("DESCRIPTION")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {solution.description}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={labelClasses.LabelLastRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("LABOR_TIME")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {solution.laborTime} {solution.laborTime && t("MINUTES")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
