import { faMagic } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import CustomerAutoComplete from "components/autocomplete/CustomerAutoComplete";
import ThingsDataTable from "components/thingsDataTable";
import ActivePassiveDecorator from "components/ui/ActivePassiveDecorator";
import React, { useRef, useState } from "react";
import { FaButton, FaDialog, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CUSTOMER } from "services/faApi";
import { qsParse, smartTimeDiff } from "services/utils";
import i18n from "../../i18n";
import VisitDateSelector from "./VisitDateSelector";

export default function CustomerSelector(props) {
  const { value, accountId, onChange, required, multiple } = props;
  const tableRef = useRef();

  const { t } = useTranslation(["customer", "components"], { i18n });

  const [showWindow, setShowWindow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [days, setDays] = useState([]);
  const [hours, setHours] = useState([]);

  const columns = [
    {
      field: "membershipDate",
      label: t("MEMBERSHIP_DATE"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        return smartTimeDiff(value);
      },
    },
    {
      field: "name",
      label: t("NAME"),
      align: "center",
      width: "20%",
    },
    {
      field: "surname",
      label: t("SURNAME"),
      align: "center",
      width: "20%",
    },
    {
      field: "email",
      label: t("E-MAIL"),
      align: "center",
      width: "20%",
    },
    {
      field: "phone",
      label: t("PHONE"),
      align: "center",
      width: "20%",
    },

    {
      field: "status",
      label: t("STATUS"),
      align: "center",
      width: "10%",
      valueFormatter: (val) => <ActivePassiveDecorator value={val} />,
    },
  ];

  const onDoubleClick = (newValue) => {
    if (multiple === true) {
      if (selectedItems.findIndex((i) => i.id === newValue.id) === -1) {
        setSelectedItems([...selectedItems, newValue]);
      }
    } else {
      setShowWindow(false);
      if (onChange) onChange(newValue);
    }
  };

  const multipleSelectedOk = () => {
    setShowWindow(false);
    if (onChange) onChange(selectedItems);
  };
  let history = useHistory();

  const newSearchForm = () => {
    let result = {
      thing: null,
      thingId: null,
      membershipDateStart: null,
      membershipDateEnd: null,
      email: null,
      phone: null,
      accountId: accountId,
      name: null,
      surname: null,
      hashtag: null,
      recordType: null,
      status: null,
      description: null,
      visitDates: null,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.clearSelectedRows();
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.clearSelectedRows();
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const searchClear = () => {
    if (days) setDays([]);
    setSearchForm(newSearchForm());
  };

  const renderSelector = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <CustomerAutoComplete
          value={value}
          accountId={accountId}
          onChange={(newValue) => {
            if (onChange) onChange(newValue);
          }}
          required={required}
          style={{ minHeight: 0 }}
          multiple={multiple}
        />
        <Tooltip title="Detaylı Seçim">
          <IconButton color="primary" style={{ marginLeft: "8px" }}>
            <FontAwesomeIcon
              icon={faMagic}
              onClick={() => {
                setShowWindow(true);
              }}
            />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  const handleChipDelete = (index) => () => {
    var temp = [...selectedItems];
    temp.splice(index, 1);
    setSelectedItems(temp);
  };

  const onSelectedChange = (rows) => {
    if (multiple) setSelectedItems(rows);
  };

  const renderWindow = () => {
    return (
      <>
        <FaDialog
          title={t("Müşteri Seç")}
          faOpen={showWindow}
          onClear={searchClear}
          faHandleClose={(data) => {
            setShowWindow(false);
          }}
          faMaxWidth="xl"
        >
          <div style={{ minHeight: "800px" }}>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              spacing={0}
            >
              <Grid item xs={12}>
                <FaSearch
                  onSearch={searchSubmit}
                  onClear={searchClear}
                  onSummarySearch={searchSummary}
                  faType="form"
                >
                  <FaInput
                    label={t("NAME")}
                    value={searchForm.name}
                    onChange={(event) => {
                      setSearchForm({
                        ...searchForm,
                        name: event.target.value,
                      });
                    }}
                  />
                  <FaInput
                    label={t("SURNAME")}
                    value={searchForm.surname}
                    onChange={(event) => {
                      setSearchForm({
                        ...searchForm,
                        surname: event.target.value,
                      });
                    }}
                  />
                  <VisitDateSelector
                    required={true}
                    onClear={searchClear}
                    accountId={accountId}
                    value={days}
                    onChange={(data) => {
                      setDays(data);
                      setSearchForm({
                        ...searchForm,
                        visitDates: data,
                      });
                    }}
                    multiple={true}
                  />
                </FaSearch>
              </Grid>
            </Grid>
            <ThingsDataTable
              searchUrl={
                CUSTOMER.search + "?accountId=" + localStorage.accountId
              }
              searchForm={searchForm}
              columns={columns}
              ref={tableRef}
              showHeaderText={false}
              faOnDoubleClick={onDoubleClick}
              queryParams={{
                page: 0,
                size: 10,
                sort: "id",
                isAsc: false,
              }}
              onSelectedChange={multiple && onSelectedChange}
            />
            {multiple === true && (
              <div style={{ textAlign: "center" }}>
                <FaButton
                  faClick={() => {
                    multipleSelectedOk();
                  }}
                  fullWidth={false}
                  startIcon={<Check />}
                >
                  {t("Seç")}
                </FaButton>
              </div>
            )}
          </div>
        </FaDialog>
      </>
    );
  };

  return (
    <>
      {renderSelector()}
      {showWindow && renderWindow()}
    </>
  );
}
