import InnerHTML from "dangerously-set-html-content";
import $ from "jquery";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";

export default function LandingTemplateCustom(props) {
  const { publicThing, onLinkClick } = props;
  // const classes = useStyles();
  // const { t } = useTranslation("report", { i18n });
  const history = useHistory();

  const thingObject = {
    data: () => {
      return publicThing;
    },

    hello: () => {
      alert("helloo");
      const h1 = $("#h1"); //dummy import kalkmasın diye
    },

    reportLike: () => {
      let tmpTo = {
        pathname:
          publicThing.food === true
            ? `/report/likeFood/${publicThing.tid}`
            : `/report/like/${publicThing.tid}`,
        state: {
          publicThing: {
            ...publicThing,
          },
        },
      };
      history.push(tmpTo);
    },

    reportSuggestion: () => {
      let tmpTo = {
        pathname:
          publicThing.food === true
            ? `/report/suggestionFood/${publicThing.tid}`
            : `/report/suggestion/${publicThing.tid}`,
        state: {
          publicThing: {
            ...publicThing,
          },
        },
      };
      history.push(tmpTo);
    },

    reportIssue: () => {
      let tmpTo = {
        pathname:
          publicThing.food === true
            ? `/report/issueFood/${publicThing.tid}`
            : `/report/issue/${publicThing.tid}`,
        state: {
          publicThing: {
            ...publicThing,
          },
        },
      };
      history.push(tmpTo);
    },

    linkClick: (id) => {
      onLinkClick({ id: id });
    },
  };

  window.thingObject = thingObject;

  return (
    <InnerHTML
      style={{ width: "100%" }}
      html={publicThing.customInteractionPageHtml}
    />
  );
}

LandingTemplateCustom.propTypes = {
  loginPath: PropTypes.string,
  appLogo: PropTypes.any,
};
