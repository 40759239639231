import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { ISSUECLASS } from "../../services/faApi";

export const IssueClassService = {
  save: (issueClass, callback, errorCallback) => {
    let reqBody = {
      ...issueClass,
    };

    let req = requests.getSimplePostRequest(ISSUECLASS.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (issueClass, callback, errorCallback) => {
    let reqBody = {
      ...issueClass,
    };

    let req = requests.getSimplePostRequest(ISSUECLASS.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findByThingClass: (thingClassId, accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      ISSUECLASS.findByThingClass +
        "?accountId=" +
        accountId +
        "&thingClassId=" +
        thingClassId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  enable: (issueClassDisableId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(ISSUECLASS.enable, {
      issueClassDisableId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  disable: (accountId, issueClassId, thingClassId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(ISSUECLASS.disable, {
      accountId,
      issueClassId,
      thingClassId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(ISSUECLASS.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
