import Grid from "@material-ui/core/Grid";
import AdditionalFieldsCard from "features/additionalFields/AdditionalFieldsCard";
import React, { useEffect, useState } from "react";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { getAccountId } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AccPreferencesService } from "services/accPreferences/accPreferencesService";
import { IssueService } from "services/issue/issueService";
import BeneficiaryNote from "../../components/beneficiaryNote";
import Note from "../../components/noteCard";
import i18n from "../../i18n";
import { ISSUE } from "../../services/faApi";
import { SolutionService } from "../../services/SolutionService";
import IssueDetailCard from "./IssueDetailCard";
import IssuePhotos from "./IssuePhotos";
import IssueSolutionsCard from "./IssueSolutionsCard";
import IssueTaskCard from "./IssueTaskCard";
import IssueTaskHistory from "./IssueTaskHistory";
import { useStyles } from "./style";

export default function IssueDetail() {
  const { t } = useTranslation("issueDetail", { i18n });
  const { id } = useParams();
  const accountId = useSelector(getAccountId); //localStorage.accountId;
  const classes = useStyles();
  const faDialog = useDialog();
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  const [loading, setLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState("");
  const [solutionList, setSolutionList] = useState([]);
  const [issue, setIssue] = useState({
    createDate: "",
    closeDate: "",
    closed: null,
    closeNote: "",
    issueFolder: { name: "" },
    hashtag: "",
    issueCode: "",
    issueClass: { className: "" },
    tasks: [],
    thing: null,
  });
  const [images, setImages] = useState([]);
  const [accPreferences, setAccPreferences] = useState(null);

  useEffect(() => {
    refresh();
  }, [id]);

  useEffect(() => {
    if (accountId)
      AccPreferencesService.findByAccount(accountId, (data) => {
        setAccPreferences(data);
      });
  }, [accountId]);

  const refresh = () => {
    setLoading(true);
    let req = requests.getObjectByIdRequest(ISSUE.findById, id);
    FaAxios(req)
      .then((response) => {
        setIssue(response.data);
        loadChartData(response.data.id);
        const imgReq = requests.getSimplePostRequest(
          ISSUE.downloadThumbnails + "/" + response.data.id
        );
        return FaAxios(imgReq);
      })
      .then((response) => {
        setLoading(false);
        refreshImages(response);
      })
      .catch((error) => {
        showAlert(error.message, "error");
        setLoading(false);
      });

    SolutionService.findIssueSolutions(id, solutionListRefresh);
  };

  const solutionListRefresh = (solutionList) => {
    setSolutionList(solutionList);
  };

  const refreshImages = (response) => {
    setImages(response.data);
    if (response?.data?.length > 0) {
      setThumbnail("data:image/jpeg;base64," + response.data[0].content);
    }
  };

  const loadChartData = (issueId) => {
    // let req = requests.getSimpleGetRequest(STATISTIC.chartIssueIssueClassCount, { issueId });
    // FaAxios(req)
    //   .then(response => {
    //     console.log("CHART DATA");
    //     console.log(response);
    //   }).catch(err => {
    //     console.log(err);
    //   })
  };

  const uploadImages = async (newImages) => {
    for await (const fileObj of newImages) {
      let fd = new FormData();
      fd.append("multipartFile", fileObj, fileObj.name);
      let req = requests.getSimplePostRequest(
        ISSUE.uploadFile + "/" + issue.id,
        fd
      );
      req["header"] = { "Content-Type": "multipart/form-data" };
      await FaAxios(req)
        .then((response) => {})
        .catch((error) => {
          showAlert(t("errorMessages:" + error.response.data), "error");
          setLoading(false);
        });
    }

    const imgReq = requests.getSimplePostRequest(
      ISSUE.downloadThumbnails + "/" + issue.id
    );
    FaAxios(imgReq)
      .then((response) => {
        refreshImages(response);
      })
      .catch((error) => {
        showAlert(error.message, "error");
        setLoading(false);
      });
  };

  const deleteImage = (objectId) => {
    faDialog({
      description: t("Kaydı silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        IssueService.deleteFile(
          accountId,
          objectId,
          issue.id,
          () => {
            refresh();
          },
          (e) => {
            console.log(e);
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const taskOnChange = () => {
    refresh();
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item lg={6} xs={12}>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <IssueDetailCard
                issue={issue}
                thumbnail={thumbnail}
                onChange={() => {
                  refresh();
                }}
                accPreferences={accPreferences}
              />
            </Grid>

            <Grid item xs={12}>
              <AdditionalFieldsCard
                classId={issue?.issueClass?.id}
                className="ISSUE_CLASS"
                isEditable={false}
                objectId={issue?.id}
              />
            </Grid>

            <Grid item xs={12}>
              <IssueTaskCard
                issue={issue}
                firstTask={issue.tasks[0]}
                taskOnChange={taskOnChange}
                accPreferences={accPreferences}
              />
            </Grid>

            <Grid item xs={12}>
              {issue && issue.id > 0 && <Note issue={issue} />}
            </Grid>

            <Grid item xs={12}>
              {issue && issue.id > 0 && <BeneficiaryNote issue={issue} />}
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={6} xs={12}>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <IssuePhotos
                images={images}
                uploadImages={uploadImages}
                deleteImage={deleteImage}
                issueId={issue.id}
              />
            </Grid>

            <Grid item xs={12}>
              <IssueSolutionsCard
                thing={issue?.thing}
                solutionList={solutionList}
                issue={issue}
                refresh={refresh}
              />
            </Grid>

            <Grid item xs={12}>
              <IssueTaskHistory issue={issue} taskList={issue.tasks} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container spacing={1}>
        {/* <Grid item xs={6}>
          <IssueStatistics type="pie" title="Sorun Sınıflarına Göre Sayılar" />
        </Grid>
        <Grid item xs={6}>
          <IssueStatistics type="pie" title="Etkileşim Noktası Tipi için Sorun Sınıflarına Göre Sayılar" />
        </Grid>
        <Grid item xs={12}>
          <IssueStatistics type="bar" title="Sorun Tarihlerine Göre İstatistikler" />
        </Grid>
        <Grid item xs={6}>
          <IssueStatistics type="line" title="Etkileşim noktası için Ortalama Sorun Çözülme Süresi" />
        </Grid>
        <Grid item xs={6}>
          <IssueStatistics type="line" title="Etkileşim Noktası Tipi için Ortalama Sorun Çözülme Süresi" />
        </Grid> */}
        {/* <Grid item xs={12}>
          <IssueEvents />
        </Grid> */}
        {/* <Grid item xs={12}>
          <IssueMap />
        </Grid> */}
      </Grid>
      {/* <RightBarDrawer open={true}>
        <issueQR issue={issue} />
      </RightBarDrawer> */}
    </>
  );
}
