import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    chipOpen: {
        backgroundColor: theme.palette.success.dark,
        color:"white"
    },
    chipClose: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText
    },
    selectInput: {
            padding: '10px 26px 10px 12px',

    },
    mt: {
        marginTop:theme.spacing(2)
    },
    gridList: {
        width: '100%'
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    imgPaper: {
        padding:theme.spacing(1)
    },
}));