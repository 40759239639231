import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { THING_EVENT, THING_LINK } from "./faApi";

export const ThingEventService = {
  save: (thingEvent, callback, errorCallback) => {
    let reqBody = {
      ...thingEvent,
    };

    let req = requests.getSimplePostRequest(THING_EVENT.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (thingEvent, callback, errorCallback) => {
    let reqBody = {
      ...thingEvent,
    };

    let req = requests.getSimplePostRequest(THING_EVENT.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findEvents: (thingId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      THING_EVENT.findEvents + "?thingId=" + thingId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  makePublic: (id, accountId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      THING_LINK.makePublic + "?id=" + id + "&accountId=" + accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  makePrivate: (id, accountId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      THING_LINK.makePrivate + "?id=" + id + "&accountId=" + accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
