import { CardHeader } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { getDateTimeWFormat } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { isTNF } from "services/utils";
import i18n from "../../i18n";
import { useStyles } from "./IssueDetailCardStyle";

export default function IssueDetailCard(props) {
  const { issue, thumbnail } = props;
  const { t } = useTranslation("issueDetail", { i18n });
  const classes = useStyles();

  return (
    <Card style={{ minHeight: 250 }}>
      <CardHeader title="Temel Bilgiler" />
      <CardContent>
        <Grid item container spacing={1}>
          <Grid item xs={12}>
            <Grid item container spacing={1}>
              <Grid item xs={3} className={classes.IssueDetailLabelHeader}>
                <Typography variant="caption">{t("BILDIRIM_TARIH")}</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography>
                  {getDateTimeWFormat(issue.notificationDate)}
                </Typography>
              </Grid>

              <Grid item xs={3} className={classes.IssueDetailLabelHeader}>
                <Typography variant="caption">{t("NOTIFIER")}</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography>{issue.notifier}</Typography>
              </Grid>

              <Grid item xs={3} className={classes.IssueDetailLabelHeader}>
                <Typography variant="caption">
                  {isTNF(t("THING"), t("THING_T2C"))}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography>{issue?.thing?.description}</Typography>
              </Grid>

              {/* Description */}
              <Grid item xs={3} className={classes.IssueDetailLabelHeader}>
                <Typography variant="caption">{t("DESCRIPTION")}</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography>{issue?.description}</Typography>
              </Grid>

              {/* Class Name */}
              <Grid item xs={3} className={classes.IssueDetailLabelHeader}>
                <Typography variant="caption">{t("CLASS_NAME")}</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography>{issue?.issueClass?.className}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
