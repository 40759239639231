import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { CUSTOM_INTERACTION_PAGE } from "./faApi";

export const CustomInteractionPageService = {
  createCustomInteractionPage: (
    customInteractionPage,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimplePostRequest(
      CUSTOM_INTERACTION_PAGE.createCustomInteractionPage,
      customInteractionPage
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  getCustomInteractionPageByThingId: (thingId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      CUSTOM_INTERACTION_PAGE.getCustomInteractionPageByThingId,
      { thingId }
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getCustomInteractionPageByAccountId: (accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      CUSTOM_INTERACTION_PAGE.getCustomInteractionPageByAccountId,
      { accountId }
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getCustomInteractionPageByThingClassId: (
    thingClassId,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(
      CUSTOM_INTERACTION_PAGE.getCustomInteractionPageByThingClassId,
      { thingClassId }
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
