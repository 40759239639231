import { SvgIcon } from "@material-ui/core";
import { default as React } from "react";

export default function ThingsEditIcon(props) {
  return (
    <SvgIcon viewBox="0 0 50 50" {...props}>
      <g>
        <path
          d="M8.216,38.394h9.113l24.407-24.407l-9.113-9.113L8.216,29.281V38.394z M11.583,35.027v-4.353l4.353,4.353
		H11.583z M13.963,28.294l14.452-14.452l4.353,4.353L18.316,32.647L13.963,28.294z M36.976,13.987l-1.828,1.828l-4.353-4.353
		l1.828-1.828L36.976,13.987z"
        />
        <rect x="8.216" y="41.76" width="33.665" height="3.366" />
      </g>
    </SvgIcon>
  );
}
