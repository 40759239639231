import {
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Adsense from "components/adsense/Adsense";
import ThingsDataTable from "components/thingsDataTable";
import FeatureNotAllowed from "features/subscriptions/FeatureNotAllowed";
import React, { Suspense, useRef, useState } from "react";
import { FaButton, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getRole } from "react-base-fa/dist/services/sessionSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LegalTextService } from "services/LegalTextService";
import { ROLES } from "services/utils/Roles";
import { checkRole } from "services/utils/SecurityUtils";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { LEGAL_TEXT } from "../../services/faApi";
import LegalTextEdit from "./LegalTextEdit";

export default function LegalText() {
  const tableRef = useRef();
  const { t } = useTranslation(["legalText", "components"], { i18n });
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const accountId = localStorage.accountId;
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  const [legalText, setLegalText] = useState(null);
  const [openLegalText, setOpenLegalText] = useState(false);

  const [subscriptionError, setSubscriptionError] = useState(false);
  const role = useSelector(getRole);

  const isRoleExists = checkRole([ROLES.CUSTOM_LEGAL], role);

  const columns = [
    {
      field: "name",
      label: t("NAME"),
      align: "center",
    },
    {
      field: "description",
      label: t("DESCRIPTION"),
      align: "center",
    },
    {
      field: "startDate",
      label: t("START_DATE"),
      align: "center",
      type: "dateTime",
    },
    {
      field: "endDate",
      label: t("END_DATE"),
      align: "center",
      type: "dateTime",
    },
  ];

  const newSearchForm = () => {
    let result = {
      accountId: accountId,
      name: null,
      description: null,
    };

    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `legalText/detail/${row.id}`,
      breadCrumbKey: `/legalText/detail/:id`,
    };
    history.push(tmpTo);
  };

  const legalTextOnChange = (data) => {
    const tmpTo = {
      pathname: `legalText/detail/${data.id}`,
      breadCrumbKey: `/legalText/detail/:id`,
    };
    history.push(tmpTo);
  };

  const showLegalText = (type) => {
    LegalTextService.getLegalTextByType(
      type,
      showLegalTextOnSuccess,
      (error) => {
        setLegalText(null);
      }
    );
  };

  const showLegalTextOnSuccess = (data) => {
    setLegalText(data);
    setOpenLegalText(true);
  };

  const renderLegalTextDialog = () => {
    return (
      <Dialog
        open={openLegalText}
        onClose={() => {
          setOpenLegalText(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{legalText?.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div dangerouslySetInnerHTML={{ __html: legalText?.content }} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <>
      {renderLegalTextDialog()}
      {subscriptionError && (
        <FeatureNotAllowed
          open={subscriptionError}
          onClose={() => {
            setSubscriptionError(false);
          }}
        />
      )}
      <Suspense fallback={<LoadingBar />}>
        <LegalTextEdit
          onChange={legalTextOnChange}
          open={isEditFormOpen}
          onClose={() => {
            setIsEditFormOpen(false);
          }}
        />
        <ThingsDataTable
          searchUrl={LEGAL_TEXT.search}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={1} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                if (isRoleExists) setIsEditFormOpen(true);
                else setSubscriptionError(true);
              }}
              startIcon={<AddIcon />}
            >
              {t("ADD")}
            </FaButton>
          </Grid>
          <Grid item xs={5}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <FaInput
                label={t("NAME")}
                value={searchForm.name}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "name",
                    event.target.value
                  );
                }}
              />
              <FaInput
                label={t("DESCRIPTION")}
                value={searchForm.description}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "description",
                    event.target.value
                  );
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Adsense value="RklmAlt" />
          <Grid item lg={12}>
            <Grid container spacing={1}>
              <Grid item lg={12}>
                <Card>
                  <CardHeader title={t("Things Yasal Metinleri")} />
                  <CardContent>
                    <Grid item container spacing={1}>
                      <Grid item lg={4} xs={12}>
                        <Grid item container spacing={1}>
                          <Grid item xs={12}>
                            <FaButton
                              style={{ marginRight: 5 }}
                              size="small"
                              color="contained"
                              faClick={() => showLegalText("KVKK")}
                              data-fa-button="SHOW_LEGAL"
                            >
                              {t("KVKK")}
                            </FaButton>
                          </Grid>
                          <Grid item xs={12}>
                            <FaButton
                              style={{ marginRight: 5 }}
                              size="small"
                              color="contained"
                              faClick={() =>
                                showLegalText("KULLANICI_SOZLESMESI")
                              }
                              data-fa-button="SHOW_LEGAL"
                            >
                              {t("KULLANICI_SOZLESMESI")}
                            </FaButton>
                          </Grid>

                          <Grid item xs={12}>
                            <FaButton
                              style={{ marginRight: 5 }}
                              size="small"
                              color="contained"
                              faClick={() => showLegalText("LISANS_SOZLESMESI")}
                              data-fa-button="SHOW_LEGAL"
                            >
                              {t("LISANS_SOZLESMESI")}
                            </FaButton>
                          </Grid>
                          <Grid item xs={12}>
                            <FaButton
                              style={{ marginRight: 5 }}
                              size="small"
                              color="contained"
                              faClick={() =>
                                showLegalText(
                                  "TICARI_ELEKTRONIK_ILETI_BILGILENDIRME"
                                )
                              }
                              data-fa-button="SHOW_LEGAL"
                            >
                              {t("TICARI_ELEKTRONIK_ILETI_BILGILENDIRME")}
                            </FaButton>
                          </Grid>
                          <Grid item xs={12}>
                            <FaButton
                              style={{ marginRight: 5 }}
                              size="small"
                              color="contained"
                              faClick={() =>
                                showLegalText("TICARI_ELEKTRONIK_ILETI_RIZA")
                              }
                              data-fa-button="SHOW_LEGAL"
                            >
                              {t("TICARI_ELEKTRONIK_ILETI_RIZA")}
                            </FaButton>
                          </Grid>
                          <Grid item xs={12}>
                            <FaButton
                              style={{ marginRight: 5 }}
                              size="small"
                              color="contained"
                              faClick={() =>
                                showLegalText("FAYDALANICI_AYDINLATMA_METNI")
                              }
                              data-fa-button="SHOW_LEGAL"
                            >
                              {t("FAYDALANICI_AYDINLATMA_METNI")}
                            </FaButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
