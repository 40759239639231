import { THINGS_ENVIRONMENT } from "environments/Environments";
import React from "react";
/*
App demosu için App componenti olarak aşağıdaki kısım aktif edilmelidir.
*/
import App from "react-base-fa";
import { DialogProvider } from "react-base-fa/dist/fa/faBasicDialog";
import { setAuthorizationToken } from "react-base-fa/dist/services/utils";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { contextRoutes, privateRoutes, unAuthenticated } from "routes";
import { APP } from "services/utils/Constants";
import { tutorialRoutes } from "tutorials";
import store from "./app/store";
import i18n from "./i18n";
import "./index.css";
import { styles } from "./indexStyle";
import * as serviceWorker from "./serviceWorker";

setAuthorizationToken(localStorage.getItem("jwtToken"));

// axios.interceptors.response.use(
//   function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response;
//   },
//   function (error) {
//     if (error.response) {
//       if (error.response.status === 401) {
//         dispatch(setAuth(false));
//       }
//     } else {
//       dispatch(setAuth(false));
//     }
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     return Promise.reject(error);
//   }
// );

const themeObj = (themePrefer) => ({
  palette: {
    type: themePrefer || "light",
    primary: {
      main: "#042366",
      light: "#0134a0",
      dark: "#03194a",
      contrastText: "#fff",
    },
    secondary: {
      main: "#dc3545",
      light: "#f53c4e",
      dark: "#b92d3b",
      contrastText: "#fff",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    info: {
      light: "#64b5f6",
      main: "#2196f3",
      dark: "#1976d2",
      contrastText: "#fff",
    },
    warning: {
      light: "#ffb74d",
      main: "#ff9800",
      dark: "#f57c00",
      contrastText: "#fff",
    },
    success: {
      light: "#81c784",
      main: "#3D9B35",
      dark: "#388e3c",
      contrastText: "#fff",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  overrides: styles,
});

const getLoginPath = () => {
  if (localStorage.app === APP.T2C) return "/login-t2c";
  else return "/login";
};

const getAppLogo = () => {
  if (localStorage.app === APP.T2C) {
    return THINGS_ENVIRONMENT.appLogoT2CDark;
  } else {
    return THINGS_ENVIRONMENT.appLogo;
  }
};

const getAppBarLogo = () => {
  if (localStorage.app === APP.T2C) return THINGS_ENVIRONMENT.appLogoT2CWhite;
  else return THINGS_ENVIRONMENT.appBarLogo;
};

render(
  <Provider store={store}>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <DialogProvider>
        <App
          i18n={i18n}
          unAuthenticated={unAuthenticated}
          privateRoutes={privateRoutes}
          loginPath={getLoginPath()}
          forbiddenPath="/forbidden"
          contextRoutes={contextRoutes}
          themeObj={themeObj}
          appName="Things"
          mainPage={{ pathName: "/homepage" }}
          profilePic={process.env.PUBLIC_URL + "/assets/avatar.svg"}
          appLogo={getAppLogo()}
          appBarLogo={getAppBarLogo()}
          //faRightBar={<RightBar/>}
          appBarCreateHelp={false}
          appBarHelp={true}
          appBarSettings={true}
          appBarMessages={true}
          appBarProfile={true}
          appBarUsername={false}
          appBarChangePassword={true}
          appBarCheckLegalText={false}
          companyLogo={THINGS_ENVIRONMENT.companyLogo}
          tutorialRoutes={tutorialRoutes}
        />
      </DialogProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
