import {
  FormControl,
  FormHelperText,
  FormLabel,
  InputAdornment,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";
import { FaInput } from "react-base-fa/dist/fa";
import { GetFieldByLanguage } from "services/utils";
export default function SurveyQuestionShoppingAmount(props) {
  const { question, answer, onChange, readOnly, currency, currentLang } = props;

  return (
    <Grid item xs={12}>
      <FormControl
        component="fieldset"
        style={{ width: "100%" }}
        required={question.notEmpty}
      >
        <Grid container item row xs={12}>
          <Grid item xs={12}>
            <FormLabel component="legend">
              {question.orderNo}.{" "}
              {GetFieldByLanguage(question.multiLangName, currentLang)}
            </FormLabel>
          </Grid>
          <Grid item xs={12}>
            <FormHelperText>
              {GetFieldByLanguage(question.multiLangHint, currentLang)}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid container item row xs={12}>
          <Grid item xs={12}>
            <FaInput
              required={question.notEmpty}
              faType={"number"}
              awnum={question.questionType === "DECIMAL" && "decimal-6"}
              min={question.min}
              max={question.max}
              value={answer?.dataNumeric}
              onChange={(event) => {
                onChange(question, event.target.value, "dataNumeric");
              }}
              disabled={readOnly}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{currency}</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </FormControl>
    </Grid>
  );
}

SurveyQuestionShoppingAmount.propTypes = {
  question: PropTypes.any,
  currency: PropTypes.any,
  answer: PropTypes.any,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};
