import { Chip, Grid } from "@material-ui/core";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaButton, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { MessagesService } from "services/MessagesService";
import { getDateTimeWFormat } from "services/utils";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { INBOX } from "../../services/faApi";

export default function Inbox() {
  const tableRef = useRef();
  const { t } = useTranslation("messsage", { i18n });
  const dispatch = useDispatch();
  let history = useHistory();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const accountId = localStorage.accountId;
  const [selectedRows, setSelectedRows] = useState([]);

  const [loading, setLoading] = useState(false);

  const columns = [
    {
      field: "read",
      label: t(""),
      align: "center",
      width: "5%",
      valueFormatter: (val) =>
        val === true ? (
          ""
        ) : (
          <Chip label="Yeni" color="secondary" size="small"></Chip>
        ),
    },
    {
      field: "messageDate",
      label: t("Tarih"),
      align: "center",
      width: "20%",
      valueFormatter: (val) => getDateTimeWFormat(val),
    },
    {
      field: "subject",
      label: t("Konu"),
      align: "center",
      width: "25%",
    },
    {
      field: "text",
      label: t("İçerik"),
      align: "center",
      width: "50%",
    },
  ];

  const newSearchForm = () => {
    return {
      subject: null,
      text: null,
      accountId: accountId,
    };
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `inbox/detail/${row.id}`,
      breadCrumbKey: `/inbox/detail/:id`,
    };
    history.push(tmpTo);
  };

  const onSelectedChange = (rows) => {
    setSelectedRows(rows);
  };

  const markRead = () => {
    if (selectedRows && selectedRows.length > 0) {
      let idList = selectedRows.map((item) => item.id);
      MessagesService.markRead(
        accountId,
        idList,
        (data) => {
          tableRef.current.clearSelectedRows();
          tableRef.current.refreshTable();
        },
        (error) => {}
      );
    }
  };

  const markUnread = () => {
    if (selectedRows && selectedRows.length > 0) {
      let idList = selectedRows.map((item) => item.id);
      MessagesService.markUnread(
        accountId,
        idList,
        (data) => {
          tableRef.current.clearSelectedRows();
          tableRef.current.refreshTable();
        },
        (error) => {}
      );
    }
  };

  const markAllRead = () => {
    MessagesService.markAllRead(
      accountId,
      (data) => {
        tableRef.current.clearSelectedRows();
        tableRef.current.refreshTable();
      },
      (error) => {}
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={1}>
            <FaButton
              variant="contained"
              color="primary"
              size="medium"
              disabled={loading || !selectedRows || selectedRows.length === 0}
              faClick={() => {
                markRead();
              }}
            >
              {t("Okundu")}
            </FaButton>
          </Grid>
          <Grid item xs={1}>
            <FaButton
              variant="contained"
              color="primary"
              size="medium"
              disabled={loading || !selectedRows || selectedRows.length === 0}
              faClick={() => {
                markUnread();
              }}
            >
              {t("Okunmadı")}
            </FaButton>
          </Grid>
          <Grid item xs={1}>
            <FaButton
              variant="contained"
              color="primary"
              size="medium"
              disabled={loading}
              faClick={() => {
                markAllRead();
              }}
            >
              {t("Tümü Okundu")}
            </FaButton>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faClassName="appSearchBar"
            >
              <FaInput
                label={t("Konu")}
                value={searchForm.subject}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "subject",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("İçerik")}
                value={searchForm.text}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "text",
                    event.target.value
                  );
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
        <ThingsDataTable
          searchUrl={INBOX.search + "?accountId=" + localStorage.accountId}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          queryParams={{
            page: 0,
            size: 10,
            sort: "messageDate",
            isAsc: false,
          }}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
          onSelectedChange={onSelectedChange}
        />
      </Suspense>
    </>
  );
}
