import { IconButton } from "@material-ui/core";
import ThingsDataTable from "components/thingsDataTable";
import React, { useEffect, useRef, useState } from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { UserSubscriptionService } from "services/UserSubscriptionService";
import { getDateTimeWFormat } from "services/utils";
import i18n from "../../i18n";

export default function SubscribedUsers(props) {
  const { subscribedUsers, thingId } = props;
  const tableRef = useRef();

  const { t } = useTranslation(["customer", "components"], { i18n });

  const [showWindow, setShowWindow] = useState(false);
  const [subscribers, setSubscribers] = useState([]);
  const columns = [
    {
      field: "name",
      label: t("Üye"),
      align: "center",
      width: "30%",
      valueFormatter: (value, row) => {
        return row.user?.fullname;
      },
    },
    {
      field: "subscriptionDate",
      label: t("Üyelik Tarihi"),
      align: "center",
      width: "30%",
      valueFormatter: (value, row) => {
        return getDateTimeWFormat(row?.subscriptionDate);
      },
    },
  ];

  const getSubscribers = (thingId) => {
    UserSubscriptionService.findSubscriptionList(
      thingId,
      subscribersSuccess,
      subscribersError
    );
  };
  const subscribersSuccess = (data) => {
    setSubscribers(data);
  };

  const subscribersError = (error) => {
    // console.log(error);
  };
  useEffect(() => {
    if (thingId && thingId !== 0) {
      getSubscribers(thingId);
    }
  }, [thingId]);
  const renderSelector = () => {
    return (
      <IconButton
        size="small"
        onClick={() => {
          getSubscribers(thingId);
          setShowWindow(true);
        }}
        title={"Üye olmuş kullanıcı sayısı"}
        style={{ color: "#532363" }}
      >
        {subscribedUsers}
      </IconButton>
    );
  };

  const renderWindow = () => {
    return (
      <FaDialog
        title={t("Üye Listesi")}
        faOpen={showWindow}
        faHandleClose={(data) => {
          setShowWindow(false);
        }}
        faMaxWidth="xl"
      >
        <ThingsDataTable
          data={subscribers}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          queryParams={{
            page: 0,
            size: 10,
            sort: "id",
            isAsc: false,
          }}
        />
      </FaDialog>
    );
  };
  return (
    <>
      {showWindow && renderWindow()}
      {renderSelector()}
    </>
  );
}
