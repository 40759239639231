import { CardHeader } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Issues from "features/issues";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";

export default function HomepageIssueList(props) {
  const { size, startDate, refreshCount } = props;
  const { t } = useTranslation("components", { i18n });

  return (
    <Grid item lg={size} xs={12}>
      <Card>
        <CardHeader title={t("HOMEPAGE_ITEM.ISSUE_LIST")} />
        <Issues startDate={startDate} refreshCount={refreshCount} />
      </Card>
    </Grid>
  );
}
