import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import EditIcon from "@material-ui/icons/Edit";
import CampaignSurveyQuestionRender from "features/campaign/CampaignSurveyQuestionRender";
import PropTypes from "prop-types";
import {
  createRef,
  default as React,
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react";
import { FaButton, FaInput } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { SurveyService } from "services/SurveyService";
import { GetFieldByLanguage } from "services/utils";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import ChooseFromLibrary from "./chooseFromLibrary";
import { useStyles } from "./style";
import SurveyPageEdit from "./SurveyPageEdit";
import SurveyQuestionEdit from "./SurveyQuestionEdit";

export default function SurveyPage(props) {
  const { survey, currentLang } = props;

  const accountId = localStorage.accountId;

  const { t } = useTranslation("survey", { i18n });
  const { id } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  let refArray = [];
  const pageDialogRef = useRef();
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [isQuestionDialogOpen, setIsQuestionDialogOpen] = useState(false);

  const [selectedQuestion, setSelectedQuestion] = useState(false);

  const dialogRef = useRef();
  const chooseFromLibraryRef = useRef();

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const newPage = () => {
    return {
      survey: { id: survey.id },
      name: null,
      description: null,
    };
  };
  const [pageDTO, setPageDTO] = useState(newPage());
  const savePage = () => {
    setLoading(true);
    SurveyService.savePage(pageDTO, saveOnSuccess, saveOnError);
  };

  const saveOnSuccess = (data) => {
    showAlert(!pageDTO.id ? t("SAVED") : t("EDITED"), "success");
    setLoading(false);
    setPageDTO(newPage());
    refresh();
  };

  const saveOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };

  const handlePageChange = (prop) => (event) => {
    setPageDTO({ ...pageDTO, [prop]: event.target.value });
  };

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    if (survey) refresh();
  }, [survey]);

  const refresh = () => {
    setLoading(true);
    SurveyService.findPages(id, findPagesOnSuccess, findPagesOnError);
    SurveyService.findQuestions(
      id,
      findQuestionsOnSuccess,
      findQuestionsOnError
    );
  };

  const findQuestionsOnSuccess = (data) => {
    setQuestions(data);
    setLoading(false);
  };
  const findQuestionsOnError = (error) => {
    showAlert(error, "error");
    setLoading(false);
  };
  const findPagesOnSuccess = (data) => {
    setPages(data);
    setLoading(false);
  };

  const findPagesOnError = (error) => {
    showAlert(error, "error");
    setLoading(false);
  };
  const movePageDown = (page) => {
    let surveyId = page?.survey?.id;
    let pageId = page?.id;
    SurveyService.pageMoveDown(surveyId, pageId, moveOnSuccess, () => {});
  };
  const movePageUp = (page) => {
    let surveyId = page?.survey?.id;
    let pageId = page?.id;
    SurveyService.pageMoveUp(surveyId, pageId, moveOnSuccess, () => {});
  };
  const moveOnSuccess = () => {
    refresh();
  };
  const renderMoveButton = (page, up) => {
    return (
      <IconButton
        onClick={() => (up === true ? movePageUp(page) : movePageDown(page))}
        disabled={survey.status !== "PASSIVE"}
        aria-label={up === true ? "Sola taşı" : "Sağa taşı"}
        title={up === true ? "Sola taşı" : "Sağa taşı"}
      >
        {up === true ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </IconButton>
    );
  };
  const onChangeAnswer = () => {};

  const moveQuestionDown = (question) => {
    let surveyId = question?.surveyPage?.survey?.id;
    let questionId = question?.id;
    SurveyService.questionMoveDown(
      surveyId,
      questionId,
      moveQuestionSuccess,
      () => {}
    );
  };
  const moveQuestionUp = (question) => {
    let surveyId = question?.surveyPage?.survey?.id;
    let questionId = question?.id;
    SurveyService.questionMoveUp(
      surveyId,
      questionId,
      moveQuestionSuccess,
      () => {}
    );
  };
  const moveQuestionSuccess = () => {
    refresh();
  };

  const renderQuestionMoveButton = (question, up) => {
    return (
      <IconButton
        onClick={() =>
          up === true ? moveQuestionUp(question) : moveQuestionDown(question)
        }
        aria-label={up === true ? "Yukarı taşı" : "Aşağı taşı"}
      >
        <FontAwesomeIcon icon={up === true ? faChevronUp : faChevronDown} />
      </IconButton>
    );
  };

  const questionEditButtonClicked = (question) => {
    setSelectedQuestion(question);
    dialogRef.current.openDialog();
  };

  const renderPage = (page, index) => {
    let pageRef = createRef();
    let questionDialogref = createRef();
    return (
      <div key={index}>
        <SurveyPageEdit
          ref={pageRef}
          page={page}
          survey={survey}
          currentLang={currentLang}
          onSave={() => {
            refresh();
          }}
        />
        <SurveyQuestionEdit
          ref={questionDialogref}
          pageId={page.id}
          onSave={() => {
            refresh();
          }}
          openChooseFromLibrary={() => {
            chooseFromLibraryRef.current.openDialog();
          }}
          currentLang={currentLang}
        />
        <ChooseFromLibrary
          ref={chooseFromLibraryRef}
          onSave={() => {
            refresh();
          }}
          multiple={true}
          page={page}
          currentLang="tr"
        ></ChooseFromLibrary>
        <Card
          style={{
            minWidth: "100%",
            minHeight: 200,
          }}
        >
          <CardContent>
            <Grid item container justifyContent="flex-end">
              {renderMoveButton(page, true)}
              {renderMoveButton(page, false)}
              <IconButton
                aria-label="settings"
                disabled={survey.status !== "PASSIVE"}
                onClick={() => {
                  questionDialogref.current.openDialog();
                }}
                title={t("Soru Ekle")}
              >
                <Add style={{ fontSize: 33 }} />
              </IconButton>
              <IconButton
                aria-label="settings"
                disabled={survey.status !== "PASSIVE"}
                onClick={() => {
                  pageRef.current.openDialog();
                }}
                title={t("Düzenle")}
              >
                <EditIcon />
              </IconButton>
            </Grid>
            <div style={{ marginBottom: 10 }}>
              <Typography>
                {GetFieldByLanguage(page.multiLangDescription, currentLang)}
              </Typography>
            </div>
            {questions.map(
              (item, index) =>
                item.surveyPage.id === page.id && (
                  <>
                    <Grid
                      item
                      container
                      alignItems="center"
                      spacing={1}
                      key={index}
                    >
                      <Grid item xs={10}>
                        <CampaignSurveyQuestionRender
                          key={index}
                          question={item}
                          onChangeAnswer={onChangeAnswer}
                          answer={{ surveyQuestion: { id: item.id } }}
                          editable={true}
                          readOnly={false}
                          accountId={accountId}
                          currentLang={currentLang}
                        />
                      </Grid>
                      <Grid item xs={2} style={{ textAlign: "right" }}>
                        {survey.status === "PASSIVE" && (
                          <>
                            {renderQuestionMoveButton(item, true)}
                            {renderQuestionMoveButton(item, false)}
                            <IconButton
                              aria-label="settings"
                              onClick={() => {
                                questionEditButtonClicked(item);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <hr />
                  </>
                )
            )}
          </CardContent>
        </Card>
      </div>
    );
  };

  function a11yProps(index) {
    return {
      id: `wrapped-tab-${index}`,
      "aria-controls": `wrapped-tabpanel-${index}`,
    };
  }

  const renderNewPage = () => {
    return (
      <div>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
          style={{ paddingLeft: 20, paddingBottom: 30 }}
        >
          <Grid item lg={6} xs={12}>
            <FaInput
              label={t("NAME")}
              required
              onChange={(event) =>
                setPageDTO({
                  ...pageDTO,
                  multiLangName: {
                    ...pageDTO.multiLangName,
                    [currentLang]: event.target.value,
                  },
                })
              }
              value={pageDTO.multiLangName?.[currentLang] || ""}
            />
          </Grid>
          <Grid item lg={6}></Grid>
          <Grid item lg={6} xs={12}>
            <FaInput
              label={t("DESCRIPTION")}
              faType="description"
              inputProps={{ maxLength: 255 }}
              onChange={(event) => {
                setPageDTO({
                  ...pageDTO,
                  multiLangDescription: {
                    ...pageDTO.multiLangDescription,
                    [currentLang]: event.target.value,
                  },
                });
              }}
              value={pageDTO.multiLangDescription?.[currentLang] || ""}
            />
          </Grid>
          <Grid item lg={6}></Grid>
          <Grid item lg={6} xs={12}>
            <FaButton
              variant="contained"
              disabled={loading}
              type="submit"
              size="medium"
              faClick={() => {
                savePage();
              }}
              startIcon={<Add />}
            >
              {t("Ekle")}
            </FaButton>
          </Grid>

          <Grid item lg={6}></Grid>
        </Grid>
      </div>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <SurveyPageEdit
          ref={pageDialogRef}
          survey={survey}
          onSave={() => {
            refresh();
          }}
          currentLang={currentLang}
        />
        <SurveyQuestionEdit
          ref={dialogRef}
          question={selectedQuestion}
          currentLang={currentLang}
          onSave={() => {
            refresh();
          }}
          onClose={() => {}}
        />
        <Paper color="default">
          <Tabs value={value} onChange={handleChange} indicatorColor="primary">
            {pages.map((page, index) => {
              return (
                <Tab
                  label={GetFieldByLanguage(page.multiLangName, currentLang)}
                  {...a11yProps(index)}
                />
              );
            })}
            <Tab
              title="Yeni Sayfa"
              label={<Add />}
              {...a11yProps(pages.length)}
            />
          </Tabs>
        </Paper>
        {pages.map((page, index) => {
          return (
            <TabPanel value={value} index={index}>
              {renderPage(page, index)}
            </TabPanel>
          );
        })}
        <TabPanel value={value} index={pages.length}>
          {renderNewPage()}
        </TabPanel>
      </Suspense>
    </>
  );
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Paper>
          <Typography>{children}</Typography>
        </Paper>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
