import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import { green, grey } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import IssueClassAutoComplete from "components/autocomplete/IssueClassAutoComplete";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import UserAutoComplete from "components/autocomplete/UserAutoComplete";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useEffect, useRef, useState } from "react";
import {
  FaAutoComplete,
  FaDatePicker,
  FaDialog,
  FaInput,
  FaSearch,
} from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { ISSUE, TASK, THING_DICTIONARY } from "../../services/faApi";
import {
  IssueDesc,
  ThingDesc,
  isTNF,
  smartTimeDiff,
} from "../../services/utils";
import { useStyles } from "./style";

export default function IssuesFood() {
  const { t } = useTranslation(["issue", "issueCloseType"], { i18n });
  const [loading, setLoading] = useState(false);
  const [isNewIssueDialog, setIsNewIssueDialog] = useState(false);
  const [isNewTaskDialog, setIsNewTaskDialog] = useState(false);
  const [isSorunKapatDialog, setIsSorunKapatDialog] = useState(false);
  const [selectedIssueId, setSelectedIssueId] = useState(null);
  const faDialog = useDialog();
  const [files, setFiles] = useState([]);
  const classes = useStyles();
  const accountId = localStorage.accountId;
  const tableRef = useRef();

  const userInfo = useSelector(getUserInfo);
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  let queryParams = qsParse(history.location.search);
  queryParams["accountId"] = localStorage.accountId;
  const [issueDTO, setIssueDTO] = useState({
    thing: {},
    description: "",
    user: {},
    notificationDate: "",
    status: "",
    notifier: userInfo?.fullname,
    hashtag: "",
    issueClass: {},
  });

  const [taskDTO, setTaskDTO] = useState({
    taskDate: new Date(),
    description: "",
    user: {
      id: 0,
    },
    issues: [],
  });

  const [closeIssueDTO, setCloseIssueDTO] = useState({
    issueId: 0,
    closeNote: "",
    closeDate: new Date(),
    closeType: {
      id: null,
    },
  });

  const [searchForm, setSearchForm] = useState({
    notifier: null,
    issueClass: null,
    issueClassId: null,
    thing: null,
    thingId: null,
    user: null,
    userId: null,
    description: null,
    accountId: accountId,
  });

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const searchClear = () => {
    setSearchForm({
      notifier: null,
      issueClass: null,
      issueClassId: null,
      thing: null,
      thingId: null,
      user: null,
      userId: null,
      description: null,
      accountId: accountId,
    });
  };

  const GreenSwitch = withStyles({
    switchBase: {
      color: grey[300],
      "&$checked": {
        color: green[500],
      },
      "&$checked + $track": {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const columns = [
    {
      field: "notificationDate",
      label: t("BILDIRIM_TARIH"),
      align: "center",
      valueFormatter: (value) => {
        return smartTimeDiff(value);
      },
    },
    {
      field: "thing",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      inner: "description",
      valueFormatter: (val) => ThingDesc(val),
    },
    {
      field: "id",
      label: t("ISSUE"),
      align: "center",
      valueFormatter: (val, row) => IssueDesc(row),
    },
    {
      field: "status",
      label: t("DURUM"),
      align: "center",
      valueFormatter: (value, row) => {
        let text = value === "OPEN" ? t("ACIK") : t("KAPALI");
        let className = value === "OPEN" ? classes.chipOpen : classes.chipClose;
        if (value === "OPEN") {
          return (
            <FormControlLabel
              control={
                <GreenSwitch
                  //checked={false}
                  onChange={() => {
                    startCloseIssue(row);
                  }}
                />
              }
              label={t("ACIK")}
            />
          );
        } else {
          return (
            <FormControlLabel
              disabled
              control={<Switch checked />}
              label={t("KAPALI")}
            />
          );
        }
      },
    },
    {
      field: "closeDate",
      label: t("CLOSE_DATE"),
      align: "center",
      valueFormatter: (value, row) => {
        return value == null ? null : (
          <div>
            <div>
              <i>{smartTimeDiff(row.closeDate)}</i>
            </div>
            <div>{row.closeNote}</div>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    refresh();
  }, [history.location.search]);

  const refresh = () => {
    tableRef.current.refreshTable();
  };

  const openTaskAssign = (data) => {
    setIsNewTaskDialog(true);
    setSelectedIssueId(data.id);
  };

  const handleIssueChange = (prop, val) => {
    setIssueDTO({ ...issueDTO, [prop]: val });
  };

  const saveIssue = () => {
    setLoading(true);
    let req = requests.getSimplePostRequest(ISSUE.save, {
      ...issueDTO,
      status: 0,
      user: userInfo,
      account: { id: accountId },
      notificationDate: new Date(),
    });
    FaAxios(req)
      .then((response) => {
        showAlert(!issueDTO.id ? t("SAVED") : t("EDITED"), "success");
        refresh();
        setFiles([]);
        setIssueDTO({
          thing: {},
          description: "",
          user: {},
          notificationDate: "",
          status: "",
          notifier: userInfo?.fullname,
          hashtag: "",
          issueClass: {},
        });
        setIsNewIssueDialog(false);
        setLoading(false);
      })
      .catch((error) => {
        showAlert(error.message, "error");
        setLoading(false);
      });
  };

  const saveTask = () => {
    setLoading(true);
    let req = requests.getSimplePostRequest(TASK.save, {
      ...taskDTO,
      account: { id: accountId },
      issues: [{ id: selectedIssueId }],
    });
    FaAxios(req)
      .then((response) => {
        refresh();
        setTaskDTO({
          taskDate: new Date(),
          description: "",
          user: {
            id: 0,
          },
          issues: [],
        });

        setIsNewTaskDialog(false);
        setLoading(false);
      })
      .catch((error) => {
        showAlert(error.message, "error");
        setLoading(false);
      });
  };

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `issues/detailFood/${row.id}`,
      breadCrumbKey: `/issues/detailFood/:id`,
    };
    history.push(tmpTo);
  };

  const startCloseIssue = (row) => {
    setCloseIssueDTO({
      issueId: row.id,
      closeNote: "",
      closeDate: new Date(),
      closeType: {
        id: null,
      },
    });
    setIsSorunKapatDialog(true);
  };

  const addNewIssueRender = () => {
    return (
      <FaDialog
        title={t("REPORT_ISSUE")}
        isDocumentUploadOn
        faFiles={files}
        faSetFiles={setFiles}
        faOpen={isNewIssueDialog}
        faOnSubmit={saveIssue}
        loading={loading}
        showSaveButton
        faHandleClose={() => {
          setIsNewIssueDialog(false);
          setFiles([]);
          setIssueDTO({
            thing: {},
            description: "",
            user: {},
            notificationDate: "",
            status: "",
            notifier: userInfo?.fullname,
            hashtag: "",
            issueClass: {},
          });
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <FaInput
              label={t("NOTIFIER")}
              required
              onChange={(event) =>
                handleIssueChange("notifier", event.target.value)
              }
              value={issueDTO.notifier || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <ThingAutoComplete
              required={true}
              value={issueDTO.thing}
              accountId={accountId}
              onChange={(data) => {
                setIssueDTO({
                  ...issueDTO,
                  ["thing"]: data,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <IssueClassAutoComplete
              value={issueDTO.issueClass}
              accountId={accountId}
              onChange={(data) => {
                setIssueDTO({
                  ...issueDTO,
                  ["issueClass"]: data,
                });
              }}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <FaInput
              label={t("SORUN")}
              required
              onChange={(event) =>
                handleIssueChange("hashtag", event.target.value)
              }
              value={issueDTO.hashtag || ""}
            />
          </Grid> */}
          <Grid item xs={12}>
            <FaInput
              label={t("DESCRIPTION")}
              onChange={(event) =>
                handleIssueChange("description", event.target.value)
              }
              value={issueDTO.description || ""}
            />
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  const closeIssueRender = () => {
    return (
      <FaDialog
        title={t("SORUN_KAPAT")}
        faOpen={isSorunKapatDialog}
        faOnSubmit={sorunKapat}
        loading={loading}
        showSaveButton
        faHandleClose={() => {
          setIsSorunKapatDialog(false);
          setCloseIssueDTO({
            issueId: 0,
            closeNote: "",
            closeDate: new Date(),
            closeType: {
              id: null,
            },
          });
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <FaInput
              value={closeIssueDTO.closeNote}
              label={t("CLOSE_NOTE")}
              onChange={(event) => {
                handleChangeCloseIssue("closeNote", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FaDatePicker
              faType="dateTime"
              label={t("CLOSE_DATE")}
              style={{ minHeight: 65 }}
              value={closeIssueDTO.closeDate}
              faOnChange={(val) => {
                handleChangeCloseIssue("closeDate", val);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FaAutoComplete
              faType="async"
              style={{ minheight: 65 }}
              value={closeIssueDTO.closeType}
              getOptionSelected={(option, value) => option.id === value.id}
              sort="className"
              faFilterProp="className"
              getOptionLabel={(option) =>
                option.className ? t("issueCloseType:" + option.className) : ""
              }
              requestUrl={
                THING_DICTIONARY.findClassName +
                "?domain=ISSUE_CLOSE_TYPE&accountId=" +
                accountId
              }
              onChange={(event, newValue) => {
                handleChangeCloseIssue("closeType", newValue);
              }}
              label={t("CLOSE_TYPE")}
            />
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  const handleChangeTask = (prop, val) => {
    setTaskDTO({ ...taskDTO, [prop]: val });
  };

  const handleChangeCloseIssue = (prop, val) => {
    setCloseIssueDTO({ ...closeIssueDTO, [prop]: val });
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const sorunKapat = () => {
    setLoading(true);
    let tmp_closeDate = closeIssueDTO.closeDate
      ? new Date(closeIssueDTO.closeDate).addHours(+3)
      : null; //FIXME +3 saat

    let req = requests.getSimpleGetRequest(ISSUE.closeIssue, {
      issueId: closeIssueDTO.issueId,
      closeNote: closeIssueDTO.closeNote,
      closeDate: tmp_closeDate
        ? tmp_closeDate.toJSON().slice(0, 10) +
          " " +
          tmp_closeDate.toJSON().slice(11, 16)
        : null,
      closeTypeId: closeIssueDTO.closeType?.id,
    });
    FaAxios(req)
      .then((response) => {
        refresh();
        setCloseIssueDTO({
          issueId: 0,
          closeNote: "",
          closeDate: new Date(),
        });

        setIsSorunKapatDialog(false);
        setLoading(false);
      })
      .catch((error) => {
        showAlert(error.message, "error");
        setLoading(false);
      });
  };

  const addNewTaskRender = () => {
    return (
      <FaDialog
        title={t("GOREV_OLUSTUR")}
        faOpen={isNewTaskDialog}
        faOnSubmit={saveTask}
        loading={loading}
        showSaveButton
        faHandleClose={() => {
          setIsNewTaskDialog(false);
          setTaskDTO({
            taskDate: new Date(),
            description: "",
            user: {
              id: 0,
            },
          });
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <UserAutoComplete
              value={taskDTO.user}
              accountId={accountId}
              onChange={(data) => {
                setTaskDTO({
                  ...taskDTO,
                  ["user"]: data,
                });
              }}
              label={t("USER")}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              faType="description"
              value={taskDTO.description}
              label={t("TASK_DESCRIPTION")}
              onChange={(event) => {
                handleChangeTask("description", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FaDatePicker
              faType="dateTime"
              label={t("TASK_DATE")}
              style={{ minHeight: 65 }}
              value={taskDTO.taskDate}
              faOnChange={(val) => {
                handleChangeTask("taskDate", val);
              }}
            />
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {addNewIssueRender()}
        {addNewTaskRender()}
        {closeIssueRender()}
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={2}>
            {/* <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                setIsNewIssueDialog(true);
              }}
              startIcon={<AddIcon />}
            >
              {t("REPORT_ISSUE")}
            </FaButton> */}
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
            >
              <FaInput
                label={t("NOTIFIER")}
                value={searchForm.notifier}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "notifier",
                    event.target.value
                  );
                }}
              />
              <ThingAutoComplete
                accountId={accountId}
                value={searchForm.thing}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "thing",
                    data,
                    "thingId",
                    data?.id
                  );
                }}
              />
              <IssueClassAutoComplete
                accountId={accountId}
                value={searchForm.issueClass}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "issueClass",
                    data,
                    "issueClassId",
                    data?.id
                  );
                }}
              />
              <FaInput
                label={t("DESCRIPTION")}
                value={searchForm.description}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "description",
                    event.target.value
                  );
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
        <ThingsDataTable
          searchUrl={ISSUE.search + "?accountId=" + localStorage.accountId}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
          // showEditButton
          // onEditAction={onRowEdit}
          // showDeleteButton
          // onDeleteAction={onRowDelete}
        />
      </Suspense>
    </>
  );
}
