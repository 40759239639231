import {
  ImageList,
  ImageListItem,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ReportService } from "services/report/reportService";
import i18n from "../../../i18n";
import { useStyles } from "./style";

export default function PublicImageList(props) {
  const { publicThing, imageOnClick } = props;
  const classes = useStyles();
  const { t } = useTranslation("report", { i18n });
  const history = useHistory();

  const theme = useTheme();
  const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));

  console.log("greaterThanMid:", greaterThanMid);

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const location = useLocation();
  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    if (publicThing && publicThing.id) {
      refreshThingImages();
    }
  }, [publicThing]);

  const refreshThingImages = () => {
    ReportService.downloadImages(
      publicThing.id,
      (data) => {
        setImageList(data);
        setLoading(false);
      },
      (error) => {}
    );
  };

  const getColSize = (index) => {
    const mod = index % 7;
    switch (mod) {
      case 0:
      case 6:
        return 2;
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return 1;

      default:
        return 1;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
      }}
    >
      {imageList && imageList.length > 0 && (
        <ImageList
          rowHeight={greaterThanMid ? 320 : 160}
          className={classes.imageList}
          cols={3}
        >
          {imageList.map((item, index) => (
            <ImageListItem key={item.object} cols={getColSize(index)}>
              <img
                alt=""
                src={`data:image/png;base64, ${item.content}`}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  imageOnClick(imageList, index);
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </div>
  );
}

PublicImageList.propTypes = {
  loginPath: PropTypes.string,
  appLogo: PropTypes.any,
};
