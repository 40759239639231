import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { RECURRENCE } from "./faApi";

export const RecurrenceService = {
  save: (recurrence, callback, errorCallback) => {
    let reqBody = {
      ...recurrence,
    };

    let req = requests.getSimplePostRequest(RECURRENCE.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (recurrence, callback, errorCallback) => {
    let reqBody = {
      ...recurrence,
    };
    let req = requests.getSimplePostRequest(RECURRENCE.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findByThing: (accountId, thingId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      RECURRENCE.findByThing + "?accountId=" + accountId + "&thingId=" + thingId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(RECURRENCE.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  activation: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(RECURRENCE.activation + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  passivation: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(RECURRENCE.passivation + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findNextOccuranceList: (recurrence, callback, errorCallback) => {
    let reqBody = {
      ...recurrence,
    };

    let req = requests.getSimplePostRequest(
      RECURRENCE.findNextOccuranceList,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findPreviousOccuranceList: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      RECURRENCE.findPreviousOccuranceList + "?id=" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
