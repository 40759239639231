import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Link,
  Paper,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { green } from "@material-ui/core/colors";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import PropTypes from "prop-types";
import React, { createRef, useEffect, useRef, useState } from "react";
import AuthCode from "react-auth-code-input";
import { selectThemePrefer } from "react-base-fa/dist/AppSlice";
import { FaButton, FaInput } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
// import useStyles from "react-base-fa/dist/fa/faUnAuthPages/style";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { getCurrentLang } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import ReactPuzzleConfirm from "react-puzzle-confirm";
import "react-puzzle-confirm/react-puzzle-confirm.css";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FingerPrintService } from "services/FingerPrintService";
import i18n from "../../i18n";
import { USER } from "../../services/faApi";
import { GpsLocationService } from "../../services/location/GpsLocationService";
import { ReportService } from "../../services/report/reportService";
import { authStyles } from "./authStyle";
import LandingHeader from "./landing/header/LandingHeader";
import { useStyles } from "./ReportIssueStyle";

export default function Customer(props) {
  const { loginPath, appLogo, miniAppLogo } = props;
  const { tid } = useParams();
  const authClasses = authStyles();
  const classes = useStyles();
  const { t } = useTranslation("report", { i18n });
  const history = useHistory();
  const [showError, setShowError] = useState(false);

  const [loading, setLoading] = useState(true);
  const [gpsError, setGpsError] = useState(false);
  let formRef = createRef();
  let recaptchaRef = useRef();
  const lang = getCurrentLang();
  const location = useLocation();
  const [publicThing, setPublicThing] = useState(location?.state?.publicThing);
  const [survey, setSurvey] = useState(location?.state?.survey);
  const [campaignCustomer, setCampaignCustomer] = useState(
    location?.state?.campaignCustomer
  );
  const [surveyCustomer, setSurveyCustomer] = useState(
    location?.state?.surveyCustomer
  );

  const [userVerificationType, setUserVerificationType] = useState(
    location?.state?.userVerificationType
  );

  const [activationCodeSent, setActivationCodeSent] = useState(false);

  const [isShowNextButton, setIsShowNextButton] = useState(false);

  const [legalText, setLegalText] = useState(null);
  const [openLegalText, setOpenLegalText] = useState(null);

  const [visitorId, setVisitorId] = useState(null);
  const [blockReport, setBlockReport] = useState(false);
  const [createCustomerResult, setCreateCustomerResult] = useState(null);

  const [existingCustomer, setExistingCustomer] = useState(null);

  const STEP_USER_INFO = 0;
  const STEP_USER_VERIFY = 1;
  const STEP_SENDING = 2;
  const STEP_COMPLETED = 3;

  const VERIFICATION_EMAIL = "EMAIL";
  const VERIFICATION_SMS = "SMS";
  const VERIFICATION_CAPTCHA = "CAPTCHA";

  const [errorMessages, setErrorMessages] = useState({
    description: "",
    phone: "",
    email: "",
  });

  const [isFieldsEmpty, setIsFieldsEmpty] = useState({
    description: true,
    phone: true,
    email: true,
  });

  useEffect(() => {
    GpsLocationService.getLocation(getLocationOnSuccess, getLocationOnError);
  }, []);

  useEffect(() => {
    FingerPrintService.getVisitorId(visitorIdOnSuccess, (error) => {
      visitorIdOnSuccess(null);
    });
  }, []);

  const visitorIdOnSuccess = (dataVisitorId) => {
    setVisitorId(dataVisitorId);
    setReport((prev) => {
      return { ...report, visitorId: dataVisitorId };
    });
    ReportService.checkCustomer(
      publicThing?.id ?? survey?.accountId,
      dataVisitorId,
      (data) => {
        setLoading(false);
        if (data && data.id) setExistingCustomer(data);
      },
      (error) => {
        setLoading(false);
      }
    );

    ReportService.checkVisitor(
      publicThing?.id ?? survey?.accountId,
      dataVisitorId,
      "CUSTOMER",
      publicThing ? publicThing.id : null,
      (data) => {
        if (data === false) {
          setBlockReport(true);
        }
      },
      (error) => {}
    );
  };

  const [files, setFiles] = useState([]);

  const onFileChange = (data) => {
    if (data && data.length > 0) {
      setFiles([...files, ...data]);
    } else setFiles(data);
  };

  const getLocationOnSuccess = (data) => {
    setReport((prev) => {
      return { ...prev, gpsLocation: JSON.stringify(data) };
    });
  };

  const getLocationOnError = (data) => {
    setGpsError(data);
  };

  const [report, setReport] = useState({
    account: {
      id: location.state.publicThing?.accountId ?? surveyCustomer?.account?.id,
    },
    thing: { id: location.state.publicThing?.id },
    description: "",
    email: "",
    phone: "",
    gpsLocation: null,
    verificationType:
      location.state.userVerificationType === "SMS"
        ? VERIFICATION_SMS
        : VERIFICATION_EMAIL,
    aydinlatma: false,
    contactPermission: false,
    gdprPermission: false,
    name: "",
    surname: "",
  });
  const [reported, setReported] = useState(false);

  const theme = useSelector(selectThemePrefer);
  const dispatch = useDispatch();

  const submitReport = () => {
    setLoading(true);
    const data = {
      ...report,
      campaignCustomer: campaignCustomer,
      surveyCustomer: surveyCustomer,
    };
    ReportService.customer(data, reportIssueOnSuccess, reportIssueOnError);
  };

  const submitExistingCustomerReport = () => {
    setLoading(true);
    const data = {
      ...report,
      campaignCustomer: campaignCustomer,
      surveyCustomer: surveyCustomer,
      id: existingCustomer.id,
    };
    ReportService.existingCustomerRegisterThing(
      data,
      reportIssueOnSuccess,
      reportIssueOnError
    );
  };

  const reportIssueOnSuccess = (data) => {
    setCreateCustomerResult(data);
    setLoading(false);
    setReported(true);
    setExistingCustomer(null);
    setActiveStep((prevActiveStep) => STEP_COMPLETED);
  };

  const reportIssueOnError = (data) => {
    setLoading(false);
    showAlert(t("SUBMIT_ERROR_MSG"), "error");
    handleNextCallback(false);
  };

  const handleChange = (prop) => (event) => {
    if (
      prop === "aydinlatma" ||
      prop === "contactPermission" ||
      prop === "gdprPermission"
    ) {
      setReport({ ...report, [prop]: event.target.checked });
    } else {
      setReport({ ...report, [prop]: event.target.value });
    }
    setIsFieldsEmpty({
      ...isFieldsEmpty,
      [prop]: event.target.value ? false : true,
    });

    if (prop === "email" && report.verificationType === VERIFICATION_EMAIL) {
      setIsShowNextButton(event.target.value !== "");
    } else if (prop === "description") {
      setIsShowNextButton(event.target.value !== "");
    }
  };

  const handleChangeV = (prop, value) => {
    setReport({ ...report, [prop]: value });
    setIsFieldsEmpty({ ...isFieldsEmpty, [prop]: value ? false : true });

    if (prop === "phone" && report.verificationType === VERIFICATION_SMS) {
      setIsShowNextButton(value !== "");
    }
  };

  const handleChangeConfirmation = (prop) => (value) => {
    setConfirmationTokens({
      ...confirmationTokens,
      [prop]: value,
    });

    if (prop == "emailToken") {
      setIsShowNextButton(value !== "");
    } else if (prop == "smsToken") {
      setIsShowNextButton(value !== "");
    }
  };

  const [activeStep, setActiveStep] = useState(0);
  const steps = ["İletişim bilgileri", "Doğrula"];

  useEffect(() => {
    if (activeStep === STEP_USER_INFO) {
      if (
        (report.email !== "" || report.phone !== "") &&
        report.aydinlatma === true
      ) {
        setIsShowNextButton(true);
      } else {
        setIsShowNextButton(false);
      }
    }
  }, [report, activeStep]);

  const [confirmationTokens, setConfirmationTokens] = useState({
    smsToken: "",
    emailToken: "",
    smsHash: "",
    emailHash: "",
  });

  const [isActivated, setIsActivated] = useState({
    sms: false,
    email: false,
    captcha: false,
  });

  const [isCountDownOn, setIsCountDownOn] = useState(false);
  const [isCountDownOn2, setIsCountDownOn2] = useState(false);

  async function getSMSConfirmationToken() {
    let myPhoneNumber = report.phone;
    const regEx = new RegExp(/[\s()*]/g);
    myPhoneNumber = myPhoneNumber.replaceAll(regEx, "");
    let req = requests.getSimpleGetRequest(USER.verificationTokenViaPhone, {
      phone: myPhoneNumber,
    });
    FaAxios(req)
      .then((response) => {
        setConfirmationTokens({
          ...confirmationTokens,
          ["smsHash"]: response.data.HASH,
        });
      })
      .catch((e) => {
        console.log("error -> ", e);
      });
  }

  function phoneConfirmation() {
    startTimer();
    getSMSConfirmationToken();
  }

  let timer = 0;
  let seconds = 180;
  let timer2 = 0;
  let seconds2 = 180;

  function startTimer() {
    if (timer == 0 && seconds > 0) {
      setIsCountDownOn(true);
      timer = setInterval(countDown, 1000);
    }
  }

  async function sendEmailToken(callback) {
    let req = requests.getSimplePostRequest(
      USER.emailVerifyOnly +
        "/" +
        confirmationTokens.emailToken +
        "/" +
        confirmationTokens.emailHash
    );
    FaAxios(req)
      .then((response) => {
        setIsActivated({
          ...isActivated,
          email: true,
        });
        callback(true);
        submitReport();
      })
      .catch((err) => {
        showAlert(t("EMAIL_CONFIRMATION_ERROR_MSG"), "error");
        callback(false);
      });
  }

  const [countDownTime, setCountDownTime] = useState();
  const [countDownTime2, setCountDownTime2] = useState();

  function secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  async function sendSmsToken(callback) {
    let req = requests.getSimplePostRequest(
      USER.phoneVerifyOnly +
        "/" +
        confirmationTokens.smsToken +
        "/" +
        confirmationTokens.smsHash
    );
    FaAxios(req)
      .then((response) => {
        setIsActivated({
          ...isActivated,
          sms: true,
        });
        callback(true);
        submitReport();
      })
      .catch((err) => {
        showAlert("SMS_CONFIRMATION_ERROR_MSG", "error");
        callback(false);
        submitReport();
      });
  }

  function countDown() {
    let tempSeconds = seconds - 1;
    seconds = tempSeconds;
    setCountDownTime(secondsToTime(tempSeconds));

    if (tempSeconds == 0) {
      clearInterval(timer);
      setCountDownTime(null);
      setIsCountDownOn(false);
    }
  }

  async function emailConfirmation() {
    startTimer2();
    getMailConfirmationToken();
  }

  function countDown2() {
    let tempSeconds = seconds2 - 1;
    seconds2 = tempSeconds;
    setCountDownTime2(secondsToTime(tempSeconds));

    if (tempSeconds == 0) {
      clearInterval(timer2);
      setCountDownTime2(null);
      setIsCountDownOn2(false);
    }
  }
  function startTimer2() {
    if (timer2 == 0 && seconds2 > 0) {
      setIsCountDownOn2(true);
      timer2 = setInterval(countDown2, 1000);
    }
  }
  async function getMailConfirmationToken() {
    let req = requests.getSimpleGetRequest(USER.verificationTokenViaEmail, {
      email: report.email,
    });
    FaAxios(req)
      .then((response) => {
        setConfirmationTokens({
          ...confirmationTokens,
          ["emailHash"]: response.data.HASH,
        });
      })
      .catch((e) => {
        console.log("error -> ", e);
      });
  }

  const emailVerificationRender = (email) => {
    return (
      <React.Fragment>
        <Grid
          container
          item
          alignItems="center"
          justify="center"
          spacing={2}
          lg={12}
          xs={12}
        >
          <Grid item lg={12} xs={12}>
            <Typography variant="subtitle1">{email}</Typography>
          </Grid>
          <Grid item lg={4} xs={12} style={{ height: 70 }}>
            <AuthCode
              onChange={handleChangeConfirmation("emailToken")}
              containerClassName={authClasses.container}
              inputClassName={
                isActivated.email
                  ? authClasses.inputDisabled
                  : authClasses.input
              }
              allowedCharacters="numeric"
              disabled={isActivated.email}
            />
          </Grid>
          <Grid item lg={2} xs={12} style={{ height: 70 }}>
            <Button
              disabled={isCountDownOn2 || isActivated.email}
              variant="contained"
              fullWidth
              component="span"
              color="primary"
              style={{ height: 60 }}
              disableElevation
              onClick={emailConfirmation}
            >
              {countDownTime2
                ? countDownTime2.h * 3600 +
                  countDownTime2.m * 60 +
                  countDownTime2.s
                : t("EPOSTA_GONDER")}
            </Button>
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const puzzleRender = () => {
    return (
      <>
        <div>
          <ReactPuzzleConfirm
            onClose={() => {
              handleBack();
            }}
            onSuccess={() => {
              setIsActivated({
                ...isActivated,
                captcha: true,
              });
              //callback(true);
              handleNextCallback(true);
              submitReport();
            }}
            onFail={() => console.log("failed")}
            title="Lütfen bulmaca parçasını dikkatlice yerleştirin"
            sliderTitle="Bulmacayı tamamlamak için kaydırın"
            failMessage="Başarısız"
            successMessage="Başarılı"
            closeButtonLabel="Geri"
            refrefButtonLabel="Yenile"
            disableRefreshIdleState={true}
          />
        </div>
        <div style={{ textAlign: "center", paddingTop: 10 }}>
          <Typography style={{ fontSize: "12px" }}>
            Bulmacayı tamamlamak için kaydırın
          </Typography>
        </div>
        <div style={{ textAlign: "center" }}>
          <img
            alt="Hint"
            height="90px"
            src={process.env.PUBLIC_URL + "/assets/hint/captcha_hint.gif"}
          ></img>
        </div>
      </>
    );
  };

  const phoneVerificationRender = (phone) => {
    return (
      <React.Fragment>
        <Grid container item spacing={2} lg={12} xs={12}>
          <Grid item lg={12} xs={12}>
            <Typography variant="subtitle1">{phone}</Typography>
          </Grid>
          <Grid item lg={4} xs={12} style={{ height: 70 }}>
            <AuthCode
              onChange={handleChangeConfirmation("smsToken")}
              containerClassName={authClasses.container}
              inputClassName={
                isActivated.sms ? authClasses.inputDisabled : authClasses.input
              }
              allowedCharacters="numeric"
              disabled={isActivated.sms}
            />
          </Grid>
          <Grid item lg={2} xs={12} style={{ height: 70 }}>
            <Button
              disabled={isCountDownOn || isActivated.sms}
              variant="contained"
              fullWidth
              component="span"
              color="primary"
              style={{ height: 60 }}
              disableElevation
              onClick={phoneConfirmation}
            >
              {countDownTime
                ? countDownTime.h * 3600 +
                  countDownTime.m * 60 +
                  countDownTime.s
                : t("SMS_GONDER")}
            </Button>
          </Grid>
          <Grid item lg={6}></Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const renderUserInfo = () => {
    return (
      <>
        <div>
          <FaInput
            label={t("Adınız")}
            onChange={handleChange("name")}
            value={report.name || ""}
          />
        </div>
        <div>
          <FaInput
            label={t("Soyadınız")}
            onChange={handleChange("surname")}
            value={report.surname || ""}
          />
        </div>
        <div>
          {!(
            userVerificationType === "SMS" || userVerificationType === "EMAIL"
          ) && (
            <FormControl component="fieldset">
              <FormLabel component="legend">
                {t("İletişim bilgileri seçimi")}
              </FormLabel>
              <RadioGroup
                row
                aria-label="position"
                value={report.verificationType}
                onChange={handleChange("verificationType")}
              >
                <FormControlLabel
                  value={VERIFICATION_EMAIL}
                  control={<Radio color="primary" />}
                  label="E-Posta"
                />
                <FormControlLabel
                  value={VERIFICATION_SMS}
                  control={<Radio color="primary" />}
                  label="Cep Telefonu"
                />
              </RadioGroup>
            </FormControl>
          )}
        </div>
        {report.verificationType === VERIFICATION_EMAIL && (
          <div>
            <FaInput
              faType="email"
              label={t("EMAIL")}
              autoComplete="email"
              onChange={handleChange("email")}
              value={report.email || ""}
              helperText="Size geri bildirim yapmak için e-posta adresinizi girebilirsiniz"
            />
          </div>
        )}
        {report.verificationType === VERIFICATION_SMS && (
          <div>
            <FaInput
              label={t("PHONE_NUMBER")}
              onChange={(event) => handleChangeV("phone", event.target.value)}
              faType="phoneNumber"
              value={report.phone}
              helperText="Size geri bildirim yapmak için telefon numaranızı girebilirsiniz"
            />
          </div>
        )}
        <div>
          <Grid item container alignItems="center" justify="center" spacing={1}>
            <Grid item lg={1} xs={1}>
              <Checkbox
                checked={report.aydinlatma}
                onChange={handleChange("aydinlatma")}
                name="aydinlatma"
                color="primary"
              />
            </Grid>
            <Grid item lg={9} xs={11}>
              <Link
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => {
                  showLegalText("FAYDALANICI_AYDINLATMA_METNI");
                  //FIXME
                }}
              >
                {t("Aydınlatma Metninde belirtilen ilkeleri kabul ediyorum")}
              </Link>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid item container alignItems="center" justify="center" spacing={1}>
            <Grid item lg={1} xs={1}>
              <Checkbox
                checked={report.contactPermission}
                onChange={handleChange("contactPermission")}
                name="contactPermission"
                color="primary"
              />
            </Grid>
            <Grid item lg={9} xs={11}>
              <Link
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => {
                  showLegalText("TICARI_ELEKTRONIK_ILETI_RIZA");
                }}
              >
                {t("Elektronik Ticaret İletisi almak istiyorum")}
              </Link>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid item container alignItems="center" justify="center" spacing={1}>
            <Grid item lg={1} xs={1}>
              <Checkbox
                checked={report.gdprPermission}
                onChange={handleChange("gdprPermission")}
                name="gdprPermission"
                color="primary"
              />
            </Grid>
            <Grid item lg={9} xs={11}>
              <Link
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => {
                  showLegalText("KVKK");
                }}
              >
                {t("Kişisel verilerin kullanımını kabul ediyorum")}
              </Link>
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case STEP_USER_INFO:
        return renderUserInfo();
      case STEP_USER_VERIFY:
        return (
          <>
            {report.verificationType === VERIFICATION_EMAIL
              ? emailVerificationRender(report.email)
              : null}
            {report.verificationType === VERIFICATION_SMS
              ? phoneVerificationRender(report.phone)
              : null}
            {report.verificationType === VERIFICATION_CAPTCHA
              ? puzzleRender()
              : null}
          </>
        );
      case STEP_SENDING:
        return (
          <div style={{ textAlign: "center" }}>
            <Typography style={{ fontSize: "20px" }}>
              Kaydınız gönderiliyor, lütfen bekleyiniz...
            </Typography>
            <LinearProgress />
          </div>
        );
      default:
        return (
          <>
            <div style={{ textAlign: "center" }}>
              <CheckCircleOutlineIcon
                style={{ fontSize: "64px", color: green[500] }}
              />
              {createCustomerResult && createCustomerResult.created && (
                <Typography style={{ fontSize: "20px" }}>
                  Kaydınız başarıyla gerçekleştirilmiştir.
                </Typography>
              )}
              {createCustomerResult && !createCustomerResult.created && (
                <Typography style={{ fontSize: "20px" }}>
                  Kaydınız sistemde bulunmuştur, üyelik bilgileriniz
                  güncellenmiştir.
                </Typography>
              )}
            </div>
            <div className={classes.footer}>
              <div className={classes.actionButtons2Div}>
                <div style={{ flex: 1 }}>
                  <Button
                    className={classes.backButton}
                    onClick={() => {
                      let tmpTo = {
                        pathname: `/q/${publicThing.tid}`,
                        state: {
                          publicThing: {
                            ...publicThing,
                          },
                        },
                      };
                      history.push(tmpTo);
                    }}
                  >
                    {t("Geri")}
                  </Button>
                </div>
              </div>
            </div>
          </>
        );
    }
  };

  const handleNext = (event) => {
    checkNextButtonStatus(activeStep + 1);

    if (activeStep === STEP_USER_INFO) {
      if (
        !activationCodeSent &&
        report.verificationType === VERIFICATION_EMAIL
      ) {
        emailConfirmation();
        setActivationCodeSent(true);
      } else if (
        !activationCodeSent &&
        report.verificationType === VERIFICATION_SMS
      ) {
        phoneConfirmation();
        setActivationCodeSent(true);
      }

      setIsShowNextButton(false);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);

      // if (report.verificationType == "1") {
      //   setIsShowNextButton(confirmationTokens.emailToken !== "");
      // } else if (report.verificationType == "2") {
      //   setIsShowNextButton(confirmationTokens.smsToken !== "");
      // }
    } else if (activeStep === STEP_USER_VERIFY) {
      if (report.verificationType === VERIFICATION_EMAIL) {
        sendEmailToken(handleNextCallback);
      } else if (report.verificationType === VERIFICATION_SMS) {
        sendSmsToken(handleNextCallback);
      } else if (report.verificationType === VERIFICATION_CAPTCHA) {
        handleNextCallback(true);
      }
    } else if (activeStep === STEP_SENDING) {
      //
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleNextCallback = (result) => {
    if (result === true) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === STEP_USER_INFO) {
      history.goBack();
    } else {
      checkNextButtonStatus(activeStep - 1);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const checkNextButtonStatus = (stepNum) => {
    let showNext = false;
    if (stepNum === STEP_USER_INFO) {
      if (report.verificationType === VERIFICATION_EMAIL && report.email !== "")
        showNext = true;
      else if (
        report.verificationType === VERIFICATION_SMS &&
        report.phone !== ""
      )
        showNext = true;
      else if (report.verificationType === VERIFICATION_CAPTCHA)
        showNext = true;
    } else if (stepNum === STEP_USER_VERIFY) {
    }

    setIsShowNextButton(showNext);
  };

  const renderReported = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        component={Paper}
        elevation={6}
        style={{
          height: "100vh",
          width: "100vw",
          margin: "10px",
          paddingLeft: "5px",
          paddingRight: "5px",
        }}
      >
        <div>
          <Grid
            item
            container
            spacing={3}
            lg={8}
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              lg={10}
              xs={12}
              style={{ textAlign: "center", marginBottom: 92 }}
            >
              <LandingHeader
                publicThing={publicThing}
                miniAppLogo={miniAppLogo}
              />
            </Grid>
          </Grid>
        </div>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <div style={{ textAlign: "center" }}>
              <CheckCircleOutlineIcon
                style={{ fontSize: "64px", color: green[500] }}
              />
              <Typography style={{ fontSize: "20px" }}>
                Kaydınız başarıyla gerçekleştirilmiştir, lütfen daha sonra
                tekrar deneyin.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={5}>
            <FaButton
              faType="button"
              size="large"
              label={t("Geri")}
              onClick={() => {
                history.goBack();
              }}
              style={{ height: "60px" }}
            ></FaButton>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const showLegalText = (type) => {
    ReportService.getLegal(
      type,
      publicThing?.id ?? survey?.accountId,
      showLegalTextOnSuccess,
      (error) => {
        setLegalText(null);
      }
    );
  };

  const showLegalTextOnSuccess = (data) => {
    setLegalText(data);
    setOpenLegalText(true);
  };

  const renderLegalTextDialog = () => {
    return (
      <Dialog
        open={openLegalText}
        onClose={() => {
          setOpenLegalText(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{legalText?.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div dangerouslySetInnerHTML={{ __html: legalText?.content }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenLegalText(false);
            }}
            color="primary"
          >
            Kapat
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderExistingCustomer = () => {
    return (
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        {existingCustomer.email && (
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="subtitle1">
              {existingCustomer.email} e-posta adresiniz sistemde bulundu.
            </Typography>
          </Grid>
        )}
        {existingCustomer.phone && (
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="subtitle1">
              {existingCustomer.phone} telefon numaranız sistemde bulundu.
            </Typography>
          </Grid>
        )}

        <Grid item xs={5}>
          <FaButton
            faType="button"
            size="large"
            color="secondary"
            variant="outlined"
            label={t("Hayır bilgilerimi kendim gireceğim")}
            onClick={() => {
              setExistingCustomer(null);
            }}
            style={{ height: "60px" }}
          ></FaButton>
        </Grid>
        <Grid item xs={5}>
          <FaButton
            faType="button"
            color="primary"
            size="large"
            onClick={submitExistingCustomerReport}
            style={{ height: "60px" }}
            label={t("Evet böyle devam et")}
          ></FaButton>
        </Grid>
      </Grid>
    );
  };

  const renderLoading = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        component={Paper}
        elevation={6}
        style={{
          height: "100vh",
          width: "100vw",
          margin: "10px",
          paddingLeft: "5px",
          paddingRight: "5px",
        }}
      >
        <Grid
          item
          container
          spacing={0}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
            <img
              src={appLogo}
              width="250"
              height="92"
              alt="logo"
              className={classes.appLogo}
            />
          </Grid>
          <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
            <LinearProgress />
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderContent = () => {
    return (
      <>
        {blockReport && renderReported()}
        {renderLegalTextDialog()}
        {!blockReport && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            component={Paper}
            elevation={6}
            style={{
              height: "100vh",
              width: "100vw",
              margin: "10px",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
          >
            <div>
              <Grid
                item
                container
                spacing={3}
                lg={8}
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  lg={10}
                  xs={12}
                  style={{ textAlign: "center", marginBottom: 92 }}
                >
                  <LandingHeader
                    publicThing={publicThing}
                    miniAppLogo={miniAppLogo}
                  />
                </Grid>
              </Grid>
              {!existingCustomer && activeStep < steps.length && (
                <div>
                  <Typography variant="h5">
                    {activeStep + 1}/{steps.length} {steps[activeStep]}
                  </Typography>
                </div>
              )}
            </div>

            {existingCustomer && renderExistingCustomer()}

            {!existingCustomer && (
              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Grid item xs={12}></Grid>
                <Grid item xs={12}>
                  {getStepContent(activeStep)}
                </Grid>

                {activeStep < steps.length && (
                  <>
                    <div className={classes.footer}>
                      <div className={classes.actionButtons2Div}>
                        <div style={{ flex: 1 }}>
                          <Button
                            className={classes.backButton}
                            onClick={handleBack}
                          >
                            {t("Geri")}
                          </Button>
                        </div>

                        <div style={{ flex: 1 }}>
                          <Button
                            className={classes.nextButton}
                            onClick={handleNext}
                            disabled={!isShowNextButton}
                          >
                            {activeStep === steps.length - 1
                              ? t("Gönder")
                              : t("İleri")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Grid>
            )}
          </Box>
        )}
      </>
    );
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ height: "100vh", width: "100vw" }}
    >
      {loading && renderLoading()}
      {!loading && renderContent()}
    </Box>
  );
}

Customer.propTypes = {
  loginPath: PropTypes.string,
  appLogo: PropTypes.any,
};
