import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { SOLUTION } from "./faApi";

export const SolutionService = {
  save: (solution, callback, errorCallback) => {
    let reqBody = {
      ...solution,
    };

    let req = requests.getSimplePostRequest(SOLUTION.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findIssueSolutions: (issueId, callback) => {
    let req = requests.getSimpleGetRequest(SOLUTION.findIssueSolutions, {
      issueId: issueId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data.list);
      })
      .catch((error) => {
        //showAlert(error.message, "error");
      });
    return () => {
      //setLoading(false);
    };
  },

  lastSolutions: (accountId, callback) => {
    let req = requests.getSimpleGetRequest(SOLUTION.lastSolutions, {
      accountId: accountId,
      limit: 4,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        //showAlert(error.message, "error");
      });
    return () => {
      //setLoading(false);
    };
  },

  createSolutionControlItems: (
    accountId,
    thingId,
    issueClassIdList,
    serviceOperationClassIdList,
    callback
  ) => {
    let params = "?accountId=" + accountId + "&thingId=" + thingId;
    issueClassIdList.map((item) => {
      params = params + "&issueClassIdList=" + item;
      return null;
    });
    serviceOperationClassIdList.map((item) => {
      params = params + "&serviceOperationClassIdList=" + item;
      return null;
    });

    let req = requests.getSimpleGetRequest(
      SOLUTION.createSolutionControlItems + params
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        //showAlert(error.message, "error");
      });
    return () => {
      //setLoading(false);
    };
  },

  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(SOLUTION.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  downloadFiles: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(SOLUTION.downloadFiles + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  downloadThumbnails: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      SOLUTION.downloadThumbnails + "/" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  uploadFile: async (solutionId, newImages, callback, errorCallback) => {
    let e = false;
    for await (const fileObj of newImages) {
      let fd = new FormData();
      fd.append("multipartFile", fileObj, fileObj.name);
      let req = requests.getSimplePostRequest(
        SOLUTION.uploadFile + "/" + solutionId,
        fd
      );
      req["header"] = { "Content-Type": "multipart/form-data" };
      await FaAxios(req)
        .then((response) => {})
        .catch((error) => {
          e = true;
        });
    }

    if (!e) callback();
    else errorCallback();
    return () => {
      //setLoading(false);
    };
  },

  findByPatrol: (patrolId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      SOLUTION.findByPatrol + "?patrolId=" + patrolId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
