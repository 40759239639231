import { Box, LinearProgress, Paper, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { green } from "@material-ui/core/colors";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import PropTypes from "prop-types";
import React, { createRef, useEffect, useRef, useState } from "react";
import { selectThemePrefer } from "react-base-fa/dist/AppSlice";
import { FaButton, FaFileUpload, FaInput } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import useStyles from "react-base-fa/dist/fa/faUnAuthPages/style";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { getCurrentLang } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useHistory, useLocation, useParams } from "react-router-dom";
import GenericSelect from "../../components/select/GenericSelect";
import i18n from "../../i18n";
import { USER } from "../../services/faApi";
import { GpsLocationService } from "../../services/location/GpsLocationService";
import { ReportService } from "../../services/report/reportService";
import { imageResize } from "../../services/utils/imageUtils";

export default function ReportIssueFood(props) {
  const { loginPath, appLogo } = props;
  const { tid } = useParams();
  const classes = useStyles();
  const { t } = useTranslation("report", { i18n });
  const history = useHistory();
  const [showError, setShowError] = useState(false);

  const [loading, setLoading] = useState(true);
  const [gpsError, setGpsError] = useState(false);
  let formRef = createRef();
  let recaptchaRef = useRef();
  const lang = getCurrentLang();
  const location = useLocation();
  const [publicThing, setPublicThing] = useState(location?.state?.publicThing);
  const [activationCodeSent, setActivationCodeSent] = useState(false);

  const [isShowNextButton, setIsShowNextButton] = React.useState(false);

  const [errorMessages, setErrorMessages] = useState({
    description: "",
    phone: "",
    email: "",
  });

  const [isFieldsEmpty, setIsFieldsEmpty] = useState({
    description: true,
    phone: true,
    email: true,
  });

  useEffect(() => {
    if (location.state && location.state.publicThing) {
      //setPublicThing(location.state.publicThing);
      //let tmp = { ...report, thing: { id: location.state.publicThing.id } };
      //console.log("tmp", tmp);
      //setReport(tmp);
      //console.log("location.state.publicThing.id ", location.state.publicThing.id );
      GpsLocationService.getLocation(getLocationOnSuccess, getLocationOnError);
      setLoading(false);
    }
  }, []);

  const [files, setFiles] = useState([]);

  const onFileChange = async (data) => {
    // if(data && data.length > 0) {

    //   const file = data[0].file;
    //   const image = await imageResize(file);
    //   const newFile = { file: image, name: file.name};
    //   setFiles([...files, newFile]);
    // }

    if (data && data.length > 0) {
      const processedFiles = await processImageFiles(data);
      setFiles([...files, ...processedFiles]);
    } else {
      const processedFiles = await processImageFiles(data);
      setFiles(processedFiles);
    }
  };

  const processImageFiles = async (data) => {
    let processedFiles = [];
    for await (let item of data) {
      const image = await imageResize(item.file);
      processedFiles.push({ file: image, name: item.file.name });
    }

    return processedFiles;
  };

  const getLocationOnSuccess = (data) => {
    let tmp = { ...report, gpsLocation: JSON.stringify(data) };
    setReport(tmp);
  };

  const getLocationOnError = (data) => {
    setGpsError(data);
  };

  const [report, setReport] = useState({
    thing: { id: location.state.publicThing.id },
    description: "",
    email: "",
    phone: "",
    issueClass: "",
    gpsLocation: null,
    emailPhoneType: 1,
  });
  const [reported, setReported] = useState(false);

  const theme = useSelector(selectThemePrefer);
  const dispatch = useDispatch();

  let submitCallbackTmp;
  const submitReport = (callback) => {
    submitCallbackTmp = callback;
    setLoading(true);
    ReportService.reportIssue(
      report,
      files,
      reportIssueOnSuccess,
      reportIssueOnError
    );
  };

  const reportIssueOnSuccess = (data) => {
    setLoading(false);
    setReported(true);
    submitCallbackTmp(true);
  };

  const reportIssueOnError = (data) => {
    setLoading(false);
    showAlert(t("SUBMIT_ERROR_MSG"), "error");
    submitCallbackTmp(false);
  };

  const handleChange = (prop) => (event) => {
    setReport({ ...report, [prop]: event.target.value });
    setIsFieldsEmpty({
      ...isFieldsEmpty,
      [prop]: event.target.value ? false : true,
    });

    if (prop == "email" && report.emailPhoneType == 1) {
      setIsShowNextButton(event.target.value !== "");
    }
  };

  const handleChangeV = (prop, value) => {
    setReport({ ...report, [prop]: value });
    setIsFieldsEmpty({ ...isFieldsEmpty, [prop]: value ? false : true });

    if (prop == "phone" && report.emailPhoneType == 2) {
      setIsShowNextButton(value !== "");
    }
  };

  const handleChangeConfirmation = (prop) => (event) => {
    setConfirmationTokens({
      ...confirmationTokens,
      [prop]: event.target.value,
    });

    if (prop == "emailToken") {
      setIsShowNextButton(event.target.value !== "");
    } else if (prop == "smsToken") {
      setIsShowNextButton(event.target.value !== "");
    }
  };

  const findByTidOnSuccess = (data) => {
    setPublicThing(data);
    setLoading(false);
    setReport({ ...report, thing: { id: data.id } });
  };

  const findByTidOnError = () => {
    setLoading(false);
  };

  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Sorun seçimi", "Açıklama", "Fotoğraf yükle"];

  useEffect(() => {
    if (activeStep == 3) {
      setIsShowNextButton(
        (report.emailPhoneType == 1 && report.email !== "") ||
          (report.emailPhoneType == 2 && report.phone !== "")
      );
    }
  }, [activeStep]);

  const [confirmationTokens, setConfirmationTokens] = useState({
    smsToken: "",
    emailToken: "",
    smsHash: "",
    emailHash: "",
  });

  const [isActivated, setIsActivated] = useState({
    sms: false,
    email: false,
  });

  const [isCountDownOn, setIsCountDownOn] = useState(false);
  const [isCountDownOn2, setIsCountDownOn2] = useState(false);

  async function getSMSConfirmationToken() {
    let myPhoneNumber = report.phone;
    const regEx = new RegExp(/[\s()*]/g);
    myPhoneNumber = myPhoneNumber.replaceAll(regEx, "");
    //console.log("myphone number",myPhoneNumber)
    let req = requests.getSimpleGetRequest(USER.verificationTokenViaPhone, {
      phone: myPhoneNumber,
    });
    //console.log("myReq->", req);
    FaAxios(req)
      .then((response) => {
        //console.log("response->", response);
        setConfirmationTokens({
          ...confirmationTokens,
          ["smsHash"]: response.data.HASH,
        });
      })
      .catch((e) => {
        console.log("error -> ", e);
      });
  }

  function phoneConfirmation() {
    startTimer();
    getSMSConfirmationToken();
  }

  let timer = 0;
  let seconds = 180;
  let timer2 = 0;
  let seconds2 = 180;

  function startTimer() {
    //console.log("inside start timer");
    if (timer == 0 && seconds > 0) {
      setIsCountDownOn(true);
      timer = setInterval(countDown, 1000);
    }
  }

  async function sendEmailToken(callback) {
    let req = requests.getSimplePostRequest(
      USER.emailVerifyOnly +
        "/" +
        confirmationTokens.emailToken +
        "/" +
        confirmationTokens.emailHash
    );
    FaAxios(req)
      .then((response) => {
        setIsActivated({
          ...isActivated,
          ["email"]: true,
        });
        callback(true);
        submitReport(handleNextCallback);
      })
      .catch((err) => {
        showAlert(t("EMAIL_CONFIRMATION_ERROR_MSG"), "error");
        callback(false);
      });
  }

  const [countDownTime, setCountDownTime] = useState();
  const [countDownTime2, setCountDownTime2] = useState();

  function secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  async function sendSmsToken(callback) {
    let req = requests.getSimplePostRequest(
      USER.phoneVerifyOnly +
        "/" +
        confirmationTokens.smsToken +
        "/" +
        confirmationTokens.smsHash
    );
    FaAxios(req)
      .then((response) => {
        setIsActivated({
          ...isActivated,
          ["sms"]: true,
        });
        callback(true);
        submitReport(handleNextCallback);
      })
      .catch((err) => {
        showAlert("SMS_CONFIRMATION_ERROR_MSG", "error");
        callback(false);
        submitReport(handleNextCallback);
      });
  }

  function countDown() {
    //console.log("inside countdown", seconds);
    let tempSeconds = seconds - 1;
    //console.log("inside countdown", tempSeconds);
    seconds = tempSeconds;
    setCountDownTime(secondsToTime(tempSeconds));

    if (tempSeconds == 0) {
      clearInterval(timer);
      setCountDownTime(null);
      setIsCountDownOn(false);
    }
  }

  async function emailConfirmation() {
    startTimer2();
    getMailConfirmationToken();
  }

  function countDown2() {
    //console.log("inside countdown", seconds2);
    let tempSeconds = seconds2 - 1;
    //console.log("inside countdown", tempSeconds);
    seconds2 = tempSeconds;
    setCountDownTime2(secondsToTime(tempSeconds));

    if (tempSeconds == 0) {
      clearInterval(timer2);
      setCountDownTime2(null);
      setIsCountDownOn2(false);
    }
  }
  function startTimer2() {
    //console.log("inside start timer");
    if (timer2 == 0 && seconds2 > 0) {
      setIsCountDownOn2(true);
      timer2 = setInterval(countDown2, 1000);
    }
  }
  async function getMailConfirmationToken() {
    let req = requests.getSimpleGetRequest(USER.verificationTokenViaEmail, {
      email: report.email,
    });
    //console.log("myReq->", req);
    FaAxios(req)
      .then((response) => {
        //console.log("response->", response);
        setConfirmationTokens({
          ...confirmationTokens,
          ["emailHash"]: response.data.HASH,
        });
      })
      .catch((e) => {
        console.log("error -> ", e);
      });
  }

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <GenericSelect
            faLabel={t("ISSUE_CLASS")}
            value={report.issueClass}
            valuesList={publicThing?.issueClassList}
            labelProperty="className"
            helperText="Nasıl bir sorunla karşılaştınız?"
            required
            onChange={(data) => {
              handleChangeV("issueClass", data);
              if (data && data != "") setIsShowNextButton(true);
              else setIsShowNextButton(false);
            }}
          />
        );
      case 1:
        return (
          <FaInput
            faType="description"
            helperText="Sorunla ilgili bir açıklama girebilirsiniz."
            label={t("DESCRIPTION")}
            onChange={handleChange("description")}
            value={report.description || ""}
          />
        );
      case 2:
        return (
          <>
            <FaFileUpload
              maxFileSize={20000000}
              dropzoneText={() => {
                return "Fotoğraf yüklemek ister misiniz?";
              }}
              onChange={onFileChange}
              initialFiles={files}
              capture="environment"
              filesLimit={5}
              showPreviewInDropZone={false}
              dropZoneBoxProps={{ style: { minHeight: "50px" } }}
            />
          </>
        );
      case 3:
        return (
          <div style={{ textAlign: "center" }}>
            <Typography style={{ fontSize: "20px" }}>
              Bildiriminiz gönderiliyor, lütfen bekleyiniz...
            </Typography>
            <LinearProgress />
          </div>
        );
      default:
        return (
          <div style={{ textAlign: "center" }}>
            <CheckCircleOutlineIcon
              style={{ fontSize: "64px", color: green[500] }}
            />
            <Typography style={{ fontSize: "20px" }}>
              Bildiriminiz başarıyla gönderilmiştir.
            </Typography>
          </div>
        );
    }
  };

  const handleNext = (event) => {
    if (activeStep == 2) {
      submitReport(handleNextCallback);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleNextCallback = (result) => {
    if (result === true) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      //className={classes.image}
      style={{ height: "100vh", width: "100vw" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        component={Paper}
        elevation={6}
        style={{
          height: "100vh",
          width: "100vw",
          margin: "10px",
          paddingLeft: "5px",
          paddingRight: "5px",
        }}
      >
        {/* <FaStepper
        stepContent={getStepContent}
        steps={steps}
        faResetButton={false}
        faNextButton={isShowNextButton}
        faNextValidation={nextStepValidation}
      ></FaStepper> */}
        {/* <FaStepper stepContent={getStepContent} steps={steps} formRef={formRef} formId="formName"></FaStepper>       */}
        <div>
          <Grid
            item
            container
            spacing={3}
            lg={8}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
              <img
                src={appLogo}
                height="78"
                alt="logo"
                className={classes.appLogo}
              />
            </Grid>
          </Grid>
          {activeStep < steps.length && (
            <div>
              <Typography variant="h5">
                {activeStep + 1}/{steps.length} {steps[activeStep]}
              </Typography>
            </div>
          )}
        </div>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            {getStepContent(activeStep)}
          </Grid>

          {activeStep < steps.length && (
            <>
              <Grid item xs={5}>
                <FaButton
                  faType="button"
                  size="large"
                  disabled={activeStep === 0}
                  label={t("Geri")}
                  onClick={handleBack}
                  style={{ height: "60px" }}
                ></FaButton>
              </Grid>
              <Grid item xs={5}>
                <FaButton
                  faType="button"
                  color="primary"
                  size="large"
                  onClick={handleNext}
                  disabled={!isShowNextButton}
                  style={{ height: "60px" }}
                  label={
                    activeStep === steps.length - 1 ? t("Gönder") : t("İleri")
                  }
                ></FaButton>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

ReportIssueFood.propTypes = {
  loginPath: PropTypes.string,
  appLogo: PropTypes.any,
};
