import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { ISSUE } from "../../services/faApi";

export const IssueService = {
  save: async (issue, files, callback, errorCallback) => {
    let reqBody = {
      ...issue,
    };

    let req = requests.getSimplePostRequest(ISSUE.save, reqBody);

    FaAxios(req)
      .then(async (response) => {
        if (files && files.length > 0) {
          files.forEach(async (fileObj) => {
            let fd = new FormData();
            fd.append("multipartFile", fileObj.file, fileObj.file.name);
            let req = requests.getSimplePostRequest(
              ISSUE.uploadFile + "/" + response.data.id,
              fd
            );
            req["header"] = { "Content-Type": "multipart/form-data" };
            await FaAxios(req)
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                errorCallback(error?.response?.data);
              });
          });

          callback(response.data);
        } else {
          callback(response.data);
        }
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  lastIssues: (accountId, callback) => {
    let req = requests.getSimpleGetRequest(ISSUE.lastIssues, {
      accountId: accountId,
      limit: 4,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        //showAlert(error.message, "error");
      });
    return () => {
      //setLoading(false);
    };
  },

  findByActiveIssues: (thid, thingId, callback) => {
    let req = requests.getSimpleGetRequest(ISSUE.findByActiveIssues, {
      cipher: thid,
      thingId: thingId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        //showAlert(error.message, "error");
      });
    return () => {
      //setLoading(false);
    };
  },

  saveSlaLevel: (issueId, slaContractLevelId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      ISSUE.saveSlaLevel +
        "?issueId=" +
        issueId +
        "&slaContractLevelId=" +
        (slaContractLevelId ?? "")
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  saveMultiSlaLevel: (list, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(ISSUE.saveMultiSlaLevel, list);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  closeIssue: (closeIssueDTO, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(ISSUE.closeIssue, closeIssueDTO);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  assignThing: (issueId, thingId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      ISSUE.assignThing + "?issueId=" + issueId + "&thingId=" + thingId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  deleteFile: (accountId, objectId, issueId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      ISSUE.deleteFile +
        "?accountId=" +
        accountId +
        "&objectId=" +
        objectId +
        "&issueId=" +
        issueId
    );
    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  approveConfirmation: (issueId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      ISSUE.approveConfirmation + "?issueId=" + issueId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  rejectConfirmation: (issueId, description, callback, errorCallback) => {
    let data = { id: issueId, description };

    let req = requests.getSimplePostRequest(ISSUE.rejectConfirmation, data);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getIssueLogs: (issueId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(ISSUE.getIssueLogs, {
      id: issueId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  changeOwner: (issueId, userId, userGroupId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      ISSUE.changeOwner +
        `?issueId=${issueId}&userId=${userId ?? ""}&userGroupId=${
          userGroupId ?? ""
        }`
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  reopen: (issueId, reopenNote, callback, errorCallback) => {
    let data = { id: issueId, description: reopenNote };

    let req = requests.getSimplePostRequest(ISSUE.reopen, data);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
