import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { USER } from "./faApi";

export const UserService = {
  getUiSettings: (callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(USER.getUiSettings);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  saveUiSettings: (uiSettings, callback, errorCallback) => {
    let reqBody = {
      ...uiSettings,
    };

    let req = requests.getSimplePostRequest(USER.saveUiSettings, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  appleLogin: (data, callback, errorCallback) => {
    let reqBody = {
      ...data,
    };

    let req = requests.getSimplePostRequest(USER.appleLogin, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getLocation: (callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(USER.getLocation);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  updateLocation: (data, callback, errorCallback) => {
    let reqBody = {
      ...data,
    };

    let req = requests.getSimplePostRequest(USER.updateLocation, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  saveSocialMediaAccounts: (googleId, facebookId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      USER.saveSocialMediaAccounts +
        "?googleId=" +
        (googleId ? googleId : "") +
        "&facebookId=" +
        (facebookId ? facebookId : "")
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  saveAppleAccount: (appleLoginForm, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      USER.saveAppleAccount,
      appleLoginForm
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
