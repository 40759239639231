import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paperTotal: {
    backgroundColor: "#11AECF",
    color: "white",
  },
  paperDaily: {
    backgroundColor: "#3CBB78",
    color: "white",
  },
  paperWeekly: {
    backgroundColor: "#A1419A",
    color: "white",
  },
  paperMonthly: {
    backgroundColor: "#EF3133",
    color: "white",
  },

  title: {
    textAlign: "center",
    fontSize: "16px",
    paddingTop: "5px",
  },

  value: {
    textAlign: "center",
    fontSize: "20px",
  },

  percentagePositive: {
    textAlign: "right",
    marginTop: "-20px",
    color: "white",
  },

  percentageNegative: {
    textAlign: "right",
    marginTop: "-20px",
    color: "black",
  },

  percentageIcon: {
    width: "16px",
    height: "16px",
    paddingTop: "3px",
  },

  percentagePlaceholder: {
    width: "16px",
    height: "16px",
    paddingTop: "20px",
  },
}));
