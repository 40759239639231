import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import { Rating } from "@material-ui/lab";
import ServiceOperationClassAutoComplete from "components/autocomplete/ServiceOperationClassAutoComplete";
import StockAutoComplete from "components/autocomplete/StockAutoComplete";
import UserAutoComplete from "components/autocomplete/UserAutoComplete";
import ThingSelector from "components/selector/ThingSelector";
import update from "immutability-helper";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
  FaDatePicker,
  FaDialog,
  FaInput,
  FaStepper,
} from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AccPreferencesService } from "services/accPreferences/accPreferencesService";
import { SOLUTION } from "services/faApi";
import { SolutionService } from "services/SolutionService";
import i18n from "../../i18n";
import OpenIssues from "./OpenIssues";

const SolutionCreate = forwardRef((props, ref) => {
  const { onSave, onDelete, onClose, value, open, defaultIssue } = props;

  const userInfo = useSelector(getUserInfo);
  const tableRef = useRef();
  const { t } = useTranslation("solutions", { i18n });
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const [accPreferences, setAccPreferences] = useState(null);

  const [selectedServiceOperationClass, setSelectedServiceOperationClass] =
    useState(null);
  const [isServiceOperationClassFormOpen, setIsServiceOperationClassFormOpen] =
    useState(false);

  const [isStockFormOpen, setIsStockFormOpen] = useState(false);
  const [solutionStocks, setSolutionStocks] = useState([]);

  const [solutionControlItems, setSolutionControlItems] = useState([]);

  const [selectedStock, setSelectedStock] = useState(null);
  const [selectedStockAmount, setSelectedStockAmount] = useState(1);

  useEffect(() => {
    if (open) {
      setSolution(value == null ? newSolution() : { ...value });
    }
  }, [open]);

  const accountId = localStorage.accountId;

  const populateControlItemsOnSuccess = (data) => {
    setSolutionControlItems(data);
  };

  const populateControlItemsOnError = () => {};

  useEffect(() => {
    if (accountId)
      AccPreferencesService.findByAccount(accountId, (data) => {
        setAccPreferences(data);
      });
  }, [accountId]);

  const newSolution = () => {
    return {
      id: "",
      notificationDate: new Date(),
      thing: null,
      user: { ...userInfo },
      description: "",
      solutionType: 1,
      serviceOperationClasses: [],
      solutionStocks: [],
      formSection: false,
      laborTime: null,
    };
  };
  const [solution, setSolution] = useState(newSolution());

  const handleChange = (prop) => (event) => {
    setSolution({
      ...solution,
      [prop]: event.target.value,
    });
  };

  const handleChangeV = (prop, value) => {
    setSolution({ ...solution, [prop]: value });
  };

  const saveSuccess = (data) => {
    showAlert(!solution.id ? t("SAVED") : t("EDITED"), "success");
    setSolution(newSolution());
    setLoading(false);
    onSave(data);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const [isShowNextButton, setIsShowNextButton] = useState(false);
  const steps = [
    "Giriş",
    "Ele Alınan Bildirimler",
    t("SOLUTION_DETAILS"),
    t("USED_MATERIALS"),
    t("CHECK_QUESTIONS"),
  ];

  const [selectedIssues, setSelectedIssues] = useState([]);

  const handleChipDelete = (index) => {
    var temp = [...solution.serviceOperationClasses];
    temp.splice(index, 1);
    setSolution({ ...solution, serviceOperationClasses: temp });
  };

  useEffect(() => {
    if (solution?.thing) {
      let issueClassIdList = [];
      [...selectedIssues].forEach((item) => {
        if (item) {
          issueClassIdList.push(item?.issueClass?.id);
        }
      });
      let serviceOperationClassIdList = solution.serviceOperationClasses.map(
        (item) => item.id
      );
      SolutionService.createSolutionControlItems(
        accountId,
        solution.thing.id,
        issueClassIdList,
        serviceOperationClassIdList,
        populateControlItemsOnSuccess,
        populateControlItemsOnError
      );
      setIsShowNextButton(true);
    } else {
      setIsShowNextButton(false);
    }
  }, [selectedIssues, solution?.serviceOperationClasses, solution?.thing]);

  const addServiceOperationClass = () => {
    setIsServiceOperationClassFormOpen(false);
    if (selectedServiceOperationClass == null) return;
    if (
      solution.serviceOperationClasses.findIndex(
        (i) => i.id === selectedServiceOperationClass.id
      ) === -1
    ) {
      setSolution({
        ...solution,
        serviceOperationClasses: [
          ...solution.serviceOperationClasses,
          selectedServiceOperationClass,
        ],
      });
    }

    setSelectedServiceOperationClass({});
  };

  const newServiceOperationClassRender = () => {
    return (
      <>
        <Dialog
          open={isServiceOperationClassFormOpen}
          onClose={(data) => {
            setIsServiceOperationClassFormOpen(false);
          }}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle id="form-dialog-title">Yapılan İşlem Ekle</DialogTitle>
          <DialogContent>
            <Grid item container justifyContent="center" spacing={1}>
              <Grid item xs={12}>
                <ServiceOperationClassAutoComplete
                  accountId={accountId}
                  value={selectedServiceOperationClass}
                  onChange={(data) => {
                    setSelectedServiceOperationClass(data);
                  }}
                  label="Bakım görevi eklemek için seçiniz"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={addServiceOperationClass} color="primary">
              Ekle
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const stockDelete = (index) => {
    var temp = [...solutionStocks];
    temp.splice(index, 1);
    setSolutionStocks([...temp]);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <ThingSelector
                required={true}
                value={solution.thing}
                accountId={accountId}
                onChange={(data) => {
                  handleChangeV("thing", data);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FaDatePicker
                faType="dateTime"
                label={t("NOTIFICATION_DATE")}
                value={solution.notificationDate}
                faOnChange={(data) => {
                  handleChangeV("notificationDate", data);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <UserAutoComplete
                value={solution.user}
                required={true}
                accountId={accountId}
                onChange={(data) => {
                  handleChangeV("user", data);
                }}
                label={t("USER")}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                label={t("DESCRIPTION")}
                onChange={handleChange("description")}
                value={solution.description || ""}
              />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <OpenIssues
            thing={solution.thing}
            setIssues={setSelectedIssues}
            accPreferences={accPreferences}
            defaultIssue={defaultIssue}
          />
        );
      case 2:
        return (
          <>
            {newServiceOperationClassRender()}
            <Grid item lg={12} xs={12} style={{ minHeight: 100 }}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("SOLUTION_DETAILS")}</TableCell>
                      <TableCell style={{ textAlign: "right" }}>
                        <IconButton
                          onClick={() => {
                            setSelectedServiceOperationClass(null);
                            setIsServiceOperationClassFormOpen(true);
                          }}
                        >
                          <Add />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {solution?.serviceOperationClasses.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell>{data.className}</TableCell>
                        <TableCell style={{ textAlign: "right" }}>
                          <IconButton
                            onClick={() => {
                              handleChipDelete(index);
                            }}
                          >
                            <Remove />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                    {solution.serviceOperationClasses.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={2}>
                          Kayıt bulunmamaktadır.
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item lg={12} xs={12}>
              <FaInput
                label={t("LABOR_TIME_MINUTES")}
                faType="number"
                onChange={(event) => {
                  handleChangeV("laborTime", event.target.value);
                }}
                value={solution.laborTime || ""}
              />
            </Grid>
          </>
        );
      case 3:
        return (
          <>
            {newStockRender()}
            <Grid item lg={12} xs={12} style={{ minHeight: 230 }}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell width="50%">{t("USED_MATERIALS")}</TableCell>
                      <TableCell width="30%">Kod</TableCell>
                      <TableCell width="15%">Miktar</TableCell>
                      <TableCell width="5%" style={{ textAlign: "right" }}>
                        <IconButton
                          onClick={() => {
                            setIsStockFormOpen(true);
                          }}
                        >
                          <Add />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {solutionStocks?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.stock.name}</TableCell>
                        <TableCell>{item.stock.code}</TableCell>
                        <TableCell>
                          {item.amount} {item.stock.unit.name}
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}>
                          <IconButton
                            onClick={() => {
                              stockDelete(index);
                            }}
                          >
                            <Remove />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                    {solutionStocks == null || solutionStocks.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={4}>
                          Malzeme kaydı bulunmamaktadır.
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>
        );
      default:
        return (
          <Grid item xs={12} style={{ minHeight: 230 }}>
            {(solutionControlItems == null ||
              solutionControlItems.length === 0) && (
              <Typography variant="caption">
                Kontrol listesi bulunmamaktadır.
              </Typography>
            )}
            {solutionControlItems != null &&
              solutionControlItems.length > 0 && (
                <>
                  <Typography variant="caption">
                    {t("CHECK_QUESTIONS")}
                  </Typography>
                  {solutionControlItems.map((item, index) =>
                    createControlItem(item, index)
                  )}
                </>
              )}
          </Grid>
        );
    }
  };

  const newStockRender = () => {
    return (
      <>
        <Dialog
          open={isStockFormOpen}
          onClose={(data) => {
            setIsStockFormOpen(false);
          }}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle id="form-dialog-title">
            Kullanılan Malzeme Ekle
          </DialogTitle>
          <DialogContent>
            <Grid item container justifyContent="center" spacing={1}>
              <Grid item xs={12}>
                <StockAutoComplete
                  accountId={accountId}
                  value={selectedStock}
                  label="Kullanılan malzeme eklemek için seçiniz"
                  onChange={(data) => {
                    setSelectedStock(data);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FaInput
                  label={
                    t("Miktar") +
                    (selectedStock == null
                      ? ""
                      : " (" + selectedStock?.unit?.name + ")")
                  }
                  faType="number"
                  value={selectedStockAmount}
                  onChange={(event) => {
                    setSelectedStockAmount(event.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={addStock} color="primary">
              Ekle
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const addStock = () => {
    if (!selectedStock) {
      showAlert("Lütfen stok seçin", "error");
    } else if (!selectedStockAmount) {
      showAlert("Lütfen stok miktarı girin", "error");
    } else {
      const newValue = {
        stock: selectedStock,
        amount: selectedStockAmount,
      };

      setSolutionStocks([...solutionStocks, newValue]);
      setSelectedStock({});
      setSelectedStockAmount(1);

      setIsStockFormOpen(false);
    }
  };

  const handleDetailChangeYesNo = (index, val) => {
    var updatedItem = update(solutionControlItems[index], {
      yesNoValue: { $set: val },
    });
    var newData = update(solutionControlItems, {
      $splice: [[index, 1, updatedItem]],
    });
    setSolutionControlItems(newData);
  };

  const handleDetailChangeRating = (index, val) => {
    var updatedItem = update(solutionControlItems[index], {
      ratingValue: { $set: val },
    });
    var newData = update(solutionControlItems, {
      $splice: [[index, 1, updatedItem]],
    });
    setSolutionControlItems(newData);
  };

  const createControlItem = (item, index) => {
    return (
      <div>
        <div>
          <b>{item.controlItem.orderNo}.</b> {item.controlItem.question}
        </div>
        <div>
          {item.controlItem.itemType === "YES_NO" && (
            <RadioGroup
              row
              aria-label="position"
              value={"" + item.yesNoValue}
              onChange={(event) => {
                handleDetailChangeYesNo(index, event.target.value);
              }}
            >
              <FormControlLabel
                value={"YES"}
                control={<Radio color="primary" />}
                label={item.controlItem.yesLabel}
              />
              <FormControlLabel
                value={"NO"}
                control={<Radio color="primary" />}
                label={item.controlItem.noLabel}
              />
            </RadioGroup>
          )}
          {item.controlItem.itemType === "RATING_SCALE" && (
            <Rating
              max={item.maxRating}
              defaultValue={item.ratingValue}
              onChange={(event) => {
                handleDetailChangeRating(index, event.target.value);
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const nextValidation = (stepIndex, callback) => {
    if (stepIndex === 4) {
      saveSolution(callback);
    }
    return callback(true);
  };

  const saveSolution = (callback) => {
    if (
      solution.formSection &&
      solutionControlItems &&
      solutionControlItems.length > 0
    ) {
      setSolution({ ...solution, formSection: false });
      return;
    }

    if (
      solutionControlItems &&
      solutionControlItems.length > 0 &&
      accPreferences.emptySolutionControlList === "NO"
    ) {
      let empty = false;
      solutionControlItems.map((item) => {
        if (item.controlItem.itemType === "YES_NO" && item.yesNoValue == null) {
          empty = true;
        } else if (
          item.controlItem.itemType === "RATING_SCALE" &&
          item.ratingValue == null
        ) {
          empty = true;
        }
      });

      if (empty) {
        showAlert("Lütfen kontrol listesinin tamamını cevaplayın", "error");
        callback(false);
        return;
      }
    }

    setLoading(true);
    var req = requests.getSimplePostRequest(SOLUTION.save, {
      ...solution,
      solutionControlItems: [...solutionControlItems],
      solutionStocks: [...solutionStocks],
      account: { id: accountId },
      issues: selectedIssues,
    });
    FaAxios(req)
      .then((response) => {
        showAlert(!solution.id ? t("SAVED") : t("EDITED"), "success");
        callback(true);
        saveSuccess(response.data);
      })
      .catch((error) => {
        showAlert(error.message, "error");
        callback(false);
        setLoading(false);
      });
    setLoading(false);
  };

  const newFormRender = () => {
    return (
      <>
        <FaDialog
          title={"Yeni Servis Operasyon"}
          faOpen={open}
          faOnSubmit={saveSolution}
          loading={loading}
          faHandleClose={(data) => {
            onClose();
            //setLabelPaperTemplate(newLabelPaperTemplate());
          }}
          faMaxWidth="lg"
        >
          <FaStepper
            stepContent={getStepContent}
            steps={steps}
            faResetButton={false}
            faNextButton={isShowNextButton}
            faNextValidation={nextValidation}
            faAlternativeLabel={false}
          ></FaStepper>
        </FaDialog>
      </>
    );
  };

  return newFormRender();
});

export default SolutionCreate;
