import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";
import { GetFieldByLanguage } from "services/utils";
export default function SurveyQuestionYesNo(props) {
  const { question, answer, onChange, readOnly, currentLang } = props;

  return (
    <Grid item xs={12}>
      <FormControl
        component="fieldset"
        style={{ width: "100%" }}
        required={question.notEmpty}
      >
        <Grid container item row xs={12}>
          <Grid item xs={12}>
            <FormLabel component="legend">
              {question.orderNo}.{" "}
              {GetFieldByLanguage(question.multiLangName, currentLang)}
            </FormLabel>
          </Grid>
          <Grid item xs={12}>
            <FormHelperText>
              {GetFieldByLanguage(question.multiLangHint, currentLang)}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item xs={6}>
            <RadioGroup
              value={
                answer?.dataBool ? "1" : answer?.dataBool === false ? "2" : ""
              }
              label={GetFieldByLanguage(question.multiLangName, currentLang)}
              onChange={(event) => {
                if (event.target.value === "1")
                  onChange(question, true, "dataBool");
                else if (event.target.value === "2")
                  onChange(question, false, "dataBool");
                else onChange(question, null, "dataBool");
              }}
            >
              <FormControlLabel
                value={"1"}
                disabled={readOnly}
                control={<Radio color="primary" />}
                label={
                  GetFieldByLanguage(
                    question.multiLangYesLabel,
                    currentLang
                  ) === null
                    ? "Evet"
                    : GetFieldByLanguage(
                        question.multiLangYesLabel,
                        currentLang
                      )
                }
              />
              <FormControlLabel
                value={"2"}
                disabled={readOnly}
                control={<Radio color="primary" />}
                label={
                  GetFieldByLanguage(question.multiLangNoLabel, currentLang) ===
                  null
                    ? "Hayır"
                    : GetFieldByLanguage(question.multiLangNoLabel, currentLang)
                }
              />
            </RadioGroup>
          </Grid>
        </Grid>
      </FormControl>
    </Grid>
  );
}

SurveyQuestionYesNo.propTypes = {
  question: PropTypes.any,
  answer: PropTypes.any,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};
