import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { JOURNAL } from "./faApi";

export const JournalService = {
  save: (journal, callback, errorCallback) => {
    let reqBody = {
      ...journal,
    };

    let req = requests.getSimplePostRequest(JOURNAL.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (journal, callback, errorCallback) => {
    let reqBody = {
      ...journal,
    };

    let req = requests.getSimplePostRequest(JOURNAL.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
