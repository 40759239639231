import { Chip } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

export default function CampaignCustomerStatusDecorator(props) {
  const { value } = props;

  const { t } = useTranslation("campaign", { i18n });

  const render = () => {
    if (value === "REGISTERED") return <Chip size="small" label={t(value)} />;
    else if (value === "WAITING_FOR_INFORMATION")
      return <Chip size="small" label={t(value)} />;
    else if (value === "VERIFIED")
      return <Chip size="small" label={t(value)} />;
    else if (value === "WIN") return <Chip size="small" label={t(value)} />;
    else if (value === "WIN_BACKUP")
      return <Chip size="small" label={t(value)} />;
    else if (value === "REJECT") return <Chip size="small" label={t(value)} />;
    else return null;
  };

  return render();
}
