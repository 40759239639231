import FullCalendar from "@fullcalendar/react"; // must go before plugins
// prettier-ignore
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
// prettier-ignore
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
// prettier-ignore
import timeGridPlugin from "@fullcalendar/timegrid"; // a plugin!
import React, { useEffect, useRef, useState } from "react";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RESERVATION } from "services/faApi";
import { CHART_COLORS } from "services/utils/ChartConstants";
import i18n from "../../i18n";
import ReservationEdit from "./ReservationEdit";

export default function CalendarView(props) {
  const { searchForm } = props;
  const { t } = useTranslation(["calendar", "errorMessages"], { i18n });

  let history = useHistory();
  const userInfo = useSelector(getUserInfo);
  const dialogRef = useRef();
  const accountId = localStorage.accountId;

  const [render, setRender] = useState(1);
  let thingColors = {};

  const refresh = () => {
    setRender((currentRender) => currentRender + 1);
  };

  const handleEventClick = (eventInfo) => {
    dialogRef.current.openDialog({
      ...eventInfo.event.extendedProps.reservation,
    });
  };

  useEffect(() => {
    console.log(searchForm);
  }, [searchForm]);

  function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  }

  const findColor = (thingId) => {
    if (thingId in thingColors) return thingColors[thingId];
    else {
      let index = Object.keys(thingColors).length;
      if (index >= 3) index = index + 1;
      thingColors[thingId] = CHART_COLORS[index];
      // setThingColors({ ...thingColors, [thingId]: CHART_COLORS[index] });
      return CHART_COLORS[index];
    }
  };

  const calendarEvents = (arg, successCallback, failureCallback) => {
    var list = [];

    let startDate = arg.start.toJSON().slice(0, 10);
    let endDate = arg.end.toJSON().slice(0, 10);

    let newSearch = { ...searchForm };

    newSearch.reservationDateStart = arg.start;
    newSearch.reservationDateEnd = arg.end;

    var req = requests.getSimplePostRequest(RESERVATION.findByDateRange, {
      ...newSearch,
    });

    FaAxios(req)
      .then((response) => {
        if (response.data != null) {
          response.data.map((element, index) => {
            list.push({
              title:
                (element?.status === "PASSIVE" ? "[iPTAL]" : "") +
                element?.user?.fullname +
                ": " +
                element.thing?.identifier,
              start: element.reservationDateStart,
              end: element.reservationDateEnd,
              extendedProps: {
                reservation: element,
              },
              backgroundColor:
                element.status === "PASSIVE"
                  ? CHART_COLORS[3]
                  : findColor(element.thing.id),
            });
          });
        }
        successCallback(list);
      })
      .catch((error) => {
        //showAlert(error.message, "error");
        //setLoading(false);
      });

    return () => {
      //setLoading(false);
    };
  };

  const selectDateRange = (param) => {
    dialogRef.current.openDialog({
      account: { id: accountId },
      user: userInfo,
      thing: {},
      reservationDateStart: param.start,
      reservationDateEnd: param.end,
      description: null,
      status: "ACTIVE",
    });
  };

  const esLocale = {
    code: "tr",
    week: {
      dow: 1, // Monday is the first day of the week.
      doy: 7, // The week that contains Jan 1st is the first week of the year.
    },
    buttonText: {
      prev: "geri",
      next: "ileri",
      today: "bugün",
      month: "Ay",
      week: "Hafta",
      day: "Gün",
      list: "Ajanda",
    },
    weekText: "Hf",
    allDayText: "Tüm gün",
    moreLinkText: "daha fazla",
    noEventsText: "Gösterilecek etkinlik yok",
  };

  return (
    <>
      <ReservationEdit ref={dialogRef} onSave={refresh}></ReservationEdit>
      {render && (
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
          initialView="timeGridWeek"
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          eventClick={handleEventClick}
          eventContent={renderEventContent}
          events={calendarEvents}
          height="auto"
          dayMaxEvents={5}
          slotMinTime="07:00:00"
          slotMaxTime="20:00:00"
          locale="tr"
          eventDisplay="block"
          locales={[esLocale]}
          selectable={true}
          select={selectDateRange}
        />
      )}
    </>
  );
}
