import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { ACCOUNT_RECORD_SETTINGS } from "./faApi";

export const AccountRecordSettingsService = {
  save: (recordSetting, callback, errorCallback) => {
    let reqBody = {
      ...recordSetting,
    };

    let req = requests.getSimplePostRequest(
      ACCOUNT_RECORD_SETTINGS.save,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findByAccountId: (accountId, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(
      ACCOUNT_RECORD_SETTINGS.findByAccountId,
      accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
