import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import React from "react";
import { FaAutoComplete, FaInput } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { TeamRolesService } from "services/TeamRolesService";
import i18n from "../../i18n";
import { TEAM_ROLES } from "../../services/faApi";

const filter = createFilterOptions();

export default function ThingTeamAutoComplete(props) {
  const { value, accountId, onChange, required } = props;

  const { t } = useTranslation("components", { i18n });
  const [open, toggleOpen] = React.useState(false);

  const handleClose = () => {
    setDialogValue({
      name: "",
    });

    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    name: "",
  });

  const handleSubmit = () => {
    TeamRolesService.save(
      {
        name: dialogValue.name,
        account: { id: accountId },
      },
      saveSuccess,
      saveError
    );
  };

  const saveSuccess = (data) => {
    onChange(data);
    handleClose();
  };

  const saveError = () => {};

  return (
    <>
      <FaAutoComplete
        faType="async"
        value={value}
        getOptionSelected={(option, value) => {
          return option.id === value.id;
        }}
        sort="name"
        faFilterProp="name"
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name ? option.name : "";
        }}
        requestUrl={TEAM_ROLES.findByName + "?accountId=" + accountId}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                name: newValue,
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue({
              name: newValue.name,
            });
          } else {
            onChange(newValue);
          }
        }}
        label={t("Takım Rolü")}
        required={required}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (params.inputValue !== "") {
            filtered.push({
              name: params.inputValue,
              inputValue: `Ekle "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        freeSolo
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Yeni takım rolü kategorisi ekle
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Aradığınız kaydı listede bulamadınız mı? Lütfen ekleyin.
          </DialogContentText>
          <FaInput
            required
            maxLength={100}
            label={t("NAME")}
            onChange={(event) =>
              setDialogValue({ ...dialogValue, name: event.target.value })
            }
            value={dialogValue.name || ""}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            İptal
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Ekle
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
