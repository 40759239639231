import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  appLogo: {
    margin: theme.spacing(1),
    objectFit: "contain",
  },
  companyLogo: {
    margin: theme.spacing(1),
    objectFit: "contain",
    maxWidth: 200,
    maxHeight: 200,
    position: "absolute",
    top: 70,
    left: 10,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  avatarC: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  link: {
    cursor: "pointer",
    marginLeft: "5px",
    marginTop: "10px",
    marginBottom: "10px",
    fontSize: "16px",
  },
  linkUnderline: {
    cursor: "pointer",
    fontSize: "14px",
    textDecoration: "underline",
  },
  googleButton: {
    "&:hover": {
      backgroundColor: "#DB4437",
      color: "white",
    },
    color: "#DB4437",
    width: "100%",
  },
  facebookButton: {
    "&:hover": {
      backgroundColor: "#3b5998",
      color: "white",
    },
    color: "#3b5998",
    width: "100%",
  },
  appleButton: {
    "&:hover": {
      backgroundColor: "#000000",
      color: "white",
    },
    color: "#000000",
    width: "100%",
  },
  errorMessage: {
    margin: theme.spacing(0.5),
  },
  appSwitchImage: {
    height: 40,
    paddingLeft: 20,
  },
}));
