import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { CUSTOMER_INTERACTION, STATISTIC } from "../../services/faApi";

const handlePieChartData = (data) => {
  var chartData = data.map((item) => {
    return {
      id: item.label === "_OTHER_" ? "Diğer" : item.label, //FIXME translation
      label: item.label === "_OTHER_" ? "Diğer" : item.label,
      value: item.value,
      rowId: item.id,
      minDate: item?.minDate,
      maxDate: item?.maxDate,
    };
  });

  return chartData;
};

const handleBarChartData = (data, countLabel) => {
  var chartData = data.map((item) => {
    var t = { label: item.label };
    t["" + countLabel] = item.value;

    return t;
  });

  return { chartData: chartData, chartKeys: [countLabel] };
};

const handleBarChartData2 = (data) => {
  let chartDataCategory = new Set();
  let chartData = data.map((month) => {
    var t = { label: month.category };
    month.subList.map((item) => {
      let label = item.label === "_OTHER_" ? "Diğer" : item.label; //FIXME translation
      t["" + label] = item.value;
      chartDataCategory.add(label);
    });

    return t;
  });

  return {
    chartData: chartData,
    chartKeys: Array.from(chartDataCategory),
  };
};

export const ThingStatisticService = {
  activeThingCountByThingClass: (accountId, limit, callback) => {
    let req = requests.getSimpleGetRequest(
      STATISTIC.activeThingCountByThingClass,
      {
        accountId: accountId,
        limit: limit,
      }
    );

    FaAxios(req)
      .then((response) => {
        var chartData = handlePieChartData(response.data);
        callback(chartData);
      })
      .catch((error) => {
        //showAlert(error.message, "error");
      });
    return () => {
      //setLoading(false);
    };
  },

  activeThingCountByOrganization: (accountId, limit, callback) => {
    let req = requests.getSimpleGetRequest(
      STATISTIC.activeThingCountByOrganization,
      {
        accountId: accountId,
        limit: limit,
      }
    );

    FaAxios(req)
      .then((response) => {
        var chartData = handlePieChartData(response.data);
        callback(chartData);
      })
      .catch((error) => {
        //showAlert(error.message, "error");
      });
    return () => {
      //setLoading(false);
    };
  },

  activeThingCountByLocation: (accountId, limit, callback) => {
    let req = requests.getSimpleGetRequest(
      STATISTIC.activeThingCountByLocation,
      {
        accountId: accountId,
        limit: limit,
      }
    );

    FaAxios(req)
      .then((response) => {
        var chartData = handlePieChartData(response.data);
        callback(chartData);
      })
      .catch((error) => {
        //showAlert(error.message, "error");
      });
    return () => {
      //setLoading(false);
    };
  },

  activeThingCountByVendor: (accountId, limit, callback) => {
    let req = requests.getSimpleGetRequest(STATISTIC.activeThingCountByVendor, {
      accountId: accountId,
      limit: limit,
    });

    FaAxios(req)
      .then((response) => {
        var chartData = handlePieChartData(response.data);
        callback(chartData);
      })
      .catch((error) => {
        //showAlert(error.message, "error");
      });
    return () => {
      //setLoading(false);
    };
  },

  thingCountByMonth: (accountId, countLabel, callback) => {
    let req = requests.getSimpleGetRequest(STATISTIC.thingCountByMonth, {
      accountId: accountId,
    });

    FaAxios(req)
      .then((response) => {
        var chartData = response.data.map((item) => {
          var t = { label: item.label };
          t["" + countLabel] = item.value;

          return t;
        });

        callback(chartData);
      })
      .catch((error) => {
        //showAlert(error.message, "error");
      });
    return () => {
      //setLoading(false);
    };
  },

  issueClassCountByMonth: (accountId, callback, callbackCategory) => {
    let req = requests.getSimpleGetRequest(STATISTIC.issueClassCountByMonth, {
      accountId: accountId,
      limit: 3,
    });

    FaAxios(req)
      .then((response) => {
        let chartDataCategory = new Set();
        let chartData = response.data.map((month) => {
          var t = { label: month.category };
          month.subList.map((item) => {
            let label = item.label == "_OTHER_" ? "Diğer" : item.label; //FIXME translation
            t["" + label] = item.value;
            chartDataCategory.add(label);
          });

          return t;
        });

        callback(chartData);
        callbackCategory(Array.from(chartDataCategory));
      })
      .catch((error) => {
        //showAlert(error.message, "error");
      });
    return () => {
      //setLoading(false);
    };
  },

  storageStat: (accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(STATISTIC.storageStat, {
      accountId: accountId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  issueCountByDate: (
    accountId,
    statSettings,
    countLabel,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(STATISTIC.issueCountByDate, {
      accountId,
      start:
        statSettings.start == null
          ? null
          : statSettings.start.toJSON().slice(0, 10),
      end:
        statSettings.end == null
          ? null
          : statSettings.end.toJSON().slice(0, 10),
      period: statSettings.period,
      cumulative: statSettings.cumulative,
      thingClassId: statSettings.thingClassId,
    });

    FaAxios(req)
      .then((response) => {
        var chartData = response.data.map((item) => {
          var t = { label: item.label };
          t["" + countLabel] = item.value;

          return t;
        });

        callback({ chartData: chartData, chartKeys: [countLabel] });
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  issueClassCountByDate: (
    accountId,
    statSettings,
    countLabel,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(STATISTIC.issueClassCountByDate, {
      accountId,
      start: statSettings.start == null ? null : statSettings.start,
      end: statSettings.end == null ? null : statSettings.end,
      period: statSettings.period,
      limit: statSettings.limit,
    });

    FaAxios(req)
      .then((response) => {
        let chartData = handleBarChartData2(response.data);
        callback(chartData);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  issueCountByThingClass: (
    accountId,
    statSettings,
    countLabel,
    callback,
    callbackCategory,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(STATISTIC.issueCountByThingClass, {
      accountId,
      start: statSettings.start == null ? null : statSettings.start,
      end: statSettings.end == null ? null : statSettings.end,
      period: statSettings.period,
      limit: statSettings.limit,
    });

    FaAxios(req)
      .then((response) => {
        let chartData = handleBarChartData2(response.data);
        callback(chartData);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  closedIssueOpenTimeByThingClass: (
    accountId,
    statSettings,
    countLabel,
    callback,
    callbackCategory,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(
      STATISTIC.closedIssueOpenTimeByThingClass,
      {
        accountId,
        start: statSettings.start == null ? null : statSettings.start,
        end: statSettings.end == null ? null : statSettings.end,
        period: statSettings.period,
        limit: statSettings.limit,
      }
    );

    FaAxios(req)
      .then((response) => {
        let chartData = handleBarChartData2(response.data);
        callback(chartData);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  openIssueOpenTimeByThingClass: (
    accountId,
    statSettings,
    countLabel,
    callback,
    callbackCategory,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(
      STATISTIC.openIssueOpenTimeByThingClass,
      {
        accountId,
        start: statSettings.start == null ? null : statSettings.start,
        end: statSettings.end == null ? null : statSettings.end,
        period: statSettings.period,
        limit: statSettings.limit,
      }
    );

    FaAxios(req)
      .then((response) => {
        let chartData = handleBarChartData2(response.data);
        callback(chartData);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  issueCountByVendor: (
    accountId,
    statSettings,
    countLabel,
    callback,
    callbackCategory,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(STATISTIC.issueCountByVendor, {
      accountId,
      start: statSettings.start == null ? null : statSettings.start,
      end: statSettings.end == null ? null : statSettings.end,
      period: statSettings.period,
      limit: statSettings.limit,
    });

    FaAxios(req)
      .then((response) => {
        let chartData = handleBarChartData2(response.data);
        callback(chartData);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  issueCountByOrganization: (
    accountId,
    statSettings,
    countLabel,
    callback,
    callbackCategory,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(STATISTIC.issueCountByOrganization, {
      accountId,
      start: statSettings.start == null ? null : statSettings.start,
      end: statSettings.end == null ? null : statSettings.end,
      period: statSettings.period,
      limit: statSettings.limit,
    });

    FaAxios(req)
      .then((response) => {
        let chartData = handleBarChartData2(response.data);
        callback(chartData);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  issueCountByLocation: (
    accountId,
    statSettings,
    countLabel,
    callback,
    callbackCategory,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(STATISTIC.issueCountByLocation, {
      accountId,
      start: statSettings.start == null ? null : statSettings.start,
      end: statSettings.end == null ? null : statSettings.end,
      period: statSettings.period,
      limit: statSettings.limit,
    });

    FaAxios(req)
      .then((response) => {
        let chartData = handleBarChartData2(response.data);
        callback(chartData);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  issueCountByUser: (
    accountId,
    statSettings,
    countLabel,
    callback,
    callbackCategory,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(STATISTIC.issueCountByUser, {
      accountId,
      start: statSettings.start == null ? null : statSettings.start,
      end: statSettings.end == null ? null : statSettings.end,
      period: statSettings.period,
      limit: statSettings.limit,
      userType: "OWNER",
    });

    FaAxios(req)
      .then((response) => {
        let chartData = handleBarChartData2(response.data);
        callback(chartData);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  solutionCountByDate: (
    accountId,
    statSettings,
    countLabel,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(STATISTIC.solutionCountByDate, {
      accountId,
      start:
        statSettings.start == null
          ? null
          : statSettings.start.toJSON().slice(0, 10),
      end:
        statSettings.end == null
          ? null
          : statSettings.end.toJSON().slice(0, 10),
      period: statSettings.period,
      cumulative: statSettings.cumulative,
      thingClassId: statSettings.thingClassId,
    });

    FaAxios(req)
      .then((response) => {
        var chartData = handleBarChartData(response.data, countLabel);
        callback(chartData);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  solutionCountByLocation: (
    accountId,
    statSettings,
    countLabel,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(STATISTIC.solutionCountByLocation, {
      accountId,
      start: statSettings.start == null ? null : statSettings.start,
      end: statSettings.end == null ? null : statSettings.end,
      period: statSettings.period,
      limit: statSettings.limit,
    });

    FaAxios(req)
      .then((response) => {
        let chartData = handleBarChartData2(response.data);
        callback(chartData);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  solutionCountByOrganization: (
    accountId,
    statSettings,
    countLabel,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(
      STATISTIC.solutionCountByOrganization,
      {
        accountId,
        start: statSettings.start == null ? null : statSettings.start,
        end: statSettings.end == null ? null : statSettings.end,
        period: statSettings.period,
        limit: statSettings.limit,
      }
    );

    FaAxios(req)
      .then((response) => {
        let chartData = handleBarChartData2(response.data);
        callback(chartData);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  solutionCountByThingClass: (
    accountId,
    statSettings,
    countLabel,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(
      STATISTIC.solutionCountByThingClass,
      {
        accountId,
        start: statSettings.start == null ? null : statSettings.start,
        end: statSettings.end == null ? null : statSettings.end,
        period: statSettings.period,
        limit: statSettings.limit,
      }
    );

    FaAxios(req)
      .then((response) => {
        let chartData = handleBarChartData2(response.data);
        callback(chartData);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  solutionStocks: (
    accountId,
    statSettings,
    countLabel,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(STATISTIC.solutionStocks, {
      accountId,
      start: statSettings.start == null ? null : statSettings.start,
      end: statSettings.end == null ? null : statSettings.end,
      period: statSettings.period,
      limit: statSettings.limit,
    });

    FaAxios(req)
      .then((response) => {
        let chartData = handleBarChartData2(response.data);
        callback(chartData);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  solutionLaborTimeByThingClass: (
    accountId,
    statSettings,
    countLabel,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(
      STATISTIC.solutionLaborTimeByThingClass,
      {
        accountId,
        start: statSettings.start == null ? null : statSettings.start,
        end: statSettings.end == null ? null : statSettings.end,
      }
    );

    FaAxios(req)
      .then((response) => {
        let chartData = handleBarChartData2(response.data);
        callback(chartData);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  solutionCountByUser: (
    accountId,
    statSettings,
    countLabel,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(STATISTIC.solutionCountByUser, {
      accountId,
      start: statSettings.start == null ? null : statSettings.start,
      end: statSettings.end == null ? null : statSettings.end,
      period: statSettings.period,
      limit: statSettings.limit,
      userType: "OWNER",
    });

    FaAxios(req)
      .then((response) => {
        let chartData = handleBarChartData2(response.data);
        callback(chartData);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  solutionCountBySolutionUser: (
    accountId,
    statSettings,
    countLabel,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(
      STATISTIC.solutionCountBySolutionUser,
      {
        accountId,
        start: statSettings.start == null ? null : statSettings.start,
        end: statSettings.end == null ? null : statSettings.end,
        period: statSettings.period,
        limit: statSettings.limit,
      }
    );

    FaAxios(req)
      .then((response) => {
        let chartData = handleBarChartData2(response.data);
        callback(chartData);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  solutionLaborTimeBySolutionUser: (
    accountId,
    statSettings,
    countLabel,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(
      STATISTIC.solutionLaborTimeBySolutionUser,
      {
        accountId,
        start: statSettings.start == null ? null : statSettings.start,
        end: statSettings.end == null ? null : statSettings.end,
        period: statSettings.period,
        limit: statSettings.limit,
      }
    );

    FaAxios(req)
      .then((response) => {
        let chartData = handleBarChartData2(response.data);
        callback(chartData);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  solutionCountByVendor: (
    accountId,
    statSettings,
    countLabel,
    callback,
    callbackCategory,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(STATISTIC.solutionCountByVendor, {
      accountId,
      start: statSettings.start == null ? null : statSettings.start,
      end: statSettings.end == null ? null : statSettings.end,
      period: statSettings.period,
      limit: statSettings.limit,
    });

    FaAxios(req)
      .then((response) => {
        let chartData = handleBarChartData2(response.data);
        callback(chartData);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  thingScanCountByDate: (
    accountId,
    thingId,
    statSettings,
    countLabel,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(STATISTIC.thingScanCountByDate, {
      accountId,
      start:
        statSettings.start == null
          ? null
          : statSettings.start.toJSON().slice(0, 10),
      end:
        statSettings.end == null
          ? null
          : statSettings.end.toJSON().slice(0, 10),
      period: statSettings.period,
      cumulative: statSettings.cumulative,
      thingId: thingId,
    });

    FaAxios(req)
      .then((response) => {
        var chartData = response.data.map((item) => {
          var t = { label: item.label };
          t["" + countLabel] = item.value;

          return t;
        });

        callback({ chartData: chartData, chartKeys: [countLabel] });
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  interactionCountByDate: (searchForm, countLabel, callback, errorCallback) => {
    let reqBody = {
      ...searchForm,
    };

    let req = requests.getSimplePostRequest(
      STATISTIC.interactionCountByDate,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        var chartData = response.data.map((item) => {
          var t = { label: item.label };
          t["" + countLabel] = item.value;

          return t;
        });

        callback({ chartData: chartData, chartKeys: [countLabel] });
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  interactionCountByDate2: (
    searchForm,
    countLabel,
    t,
    callback,
    errorCallback
  ) => {
    let reqBody = {
      ...searchForm,
    };

    let req = requests.getSimplePostRequest(
      STATISTIC.interactionCountByDate2,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        var chartData = response.data;

        chartData.forEach((i) => {
          if (i.subList && i.subList.length > 0) {
            i.subList.forEach((item) => {
              item.label = t("CUSTOMER_INTERACTION_OPERATION." + item.label);
            });
          }

          return t;
        });

        chartData = handleBarChartData2(chartData);
        callback(chartData);

        // console.log("da1:", chartData);

        // let chartData2 = handleBarChartData2(chartData);

        // console.log("da:", chartData2);
        // callback(chartData2);

        //callback({ chartData: chartData, chartKeys: [countLabel] });
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getInteractionCountsByOperation: (searchForm, callback, errorCallback) => {
    let reqBody = {
      ...searchForm,
    };

    let req = requests.getSimplePostRequest(
      CUSTOMER_INTERACTION.getInteractionCountsByOperation,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        var chartData = handlePieChartData(response.data);
        callback(chartData);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getTopCounts: (searchForm, callback, errorCallback) => {
    let reqBody = {
      ...searchForm,
    };

    let req = requests.getSimplePostRequest(
      CUSTOMER_INTERACTION.getTopCounts,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getTopThingCounts: (searchForm, callback, errorCallback) => {
    let reqBody = {
      ...searchForm,
    };

    let req = requests.getSimplePostRequest(
      CUSTOMER_INTERACTION.getTopThingCounts,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getThingsByOperation: (searchForm, callback, errorCallback) => {
    let reqBody = {
      ...searchForm,
    };

    let req = requests.getSimplePostRequest(
      CUSTOMER_INTERACTION.getThingsByOperation,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getInteractionCountsByThing: (searchForm, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      CUSTOMER_INTERACTION.getInteractionCountsByThing,
      searchForm
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getFirstAndLastInteraction: (searchForm, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      CUSTOMER_INTERACTION.getFirstAndLastInteraction,
      searchForm
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
