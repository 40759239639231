import { IconButton } from "@material-ui/core";
import ThingsHelpIcon from "components/icons/ThingsHelpIcon";
import ThingsSteps from "features/tutorial/ThingsSteps";
import TutorialElement from "features/tutorial/TutorialElement";
import $ from "jquery";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

export default function ThingClassSuggestionClassHelp(props) {
  const { value } = props;
  const { t } = useTranslation("help", { i18n });

  const stepsRef = useRef();
  const [open, setOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            Etkileşim Noktası Tipine özel Öneri Tiplerinin* eklendiği alandır.
            <br />
            <br />
            *: Öneri bildiriminde bulunan kişiye seçenek olarak gösterilir.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='SUGGESTION_CLASS_BUTTON']",
        intro: (
          <TutorialElement icon="click">
            <b>Öneri Tipi</b> eklemek için tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "#dialog-form > div > div:nth-child(1)",
        intro: (
          <TutorialElement icon="click">
            Yeni <b>Öneri Tipi</b> girin ve <b>Kaydet</b> butonuna basın.
          </TutorialElement>
        ),
        position: "right",
        onBeforeChange: (index) => {
          $(
            "[data-fa-button='SUGGESTION_CLASS_BUTTON']"
          )[0].firstElementChild.click();
          stepsRef.current.updateStepElement(index);
        },
      },
    ]);
  }, []);

  return (
    <>
      {open && (
        <ThingsSteps
          code={value}
          steps={steps}
          ref={stepsRef}
          onStepChange={setStepIndex}
          afterComplete={() => {
            setOpen(true);
            var el = $("[aria-label='close']");
            if (el != null && el[0] != null) el[0].click();
          }}
        />
      )}
      <IconButton
        aria-label="help"
        onClick={() => setOpen(true)}
        title={t("HELP")}
      >
        <ThingsHelpIcon />
      </IconButton>
    </>
  );
}

ThingClassSuggestionClassHelp.propTypes = {
  value: PropTypes.string,
};
