import {
  Avatar,
  Backdrop,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import ThingsDataTable from "components/thingsDataTable";
import UserAccountsHelp from "features/help/UserAccountsHelp";
import { default as React, Suspense, useRef, useState } from "react";
import { FaButton } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import {
  getRole,
  getUserInfo,
  setAccountId,
  setAccountName,
  setAccountTimezone,
  setGroups,
  setOrganizations,
  setRole,
} from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AccountService } from "services/AccountService";
import { AccUserService } from "services/AccUserService";
import { ROLES } from "services/utils/Roles";
import { checkRole } from "services/utils/SecurityUtils";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { ACCOUNT } from "../../services/faApi";
import { useStyles } from "./style";

export default function UserAccounts() {
  const tableRef = useRef();
  const { t } = useTranslation(["accountUserInvitation", "components"], {
    i18n,
  });
  const classes = useStyles();
  const faDialog = useDialog();
  const dispatch = useDispatch();
  const accountId = localStorage.accountId;
  let history = useHistory();
  const userInfo = useSelector(getUserInfo);
  const role = useSelector(getRole);
  const isAccountOwner = checkRole([ROLES.ACCOUNTOWNER], role);

  const [selectedAccUser, setSelectedAccUser] = useState(null);

  const [loading, setLoading] = useState(false);

  const switchAccount = (row) => {
    setSelectedAccUser(row);

    faDialog({
      description: t("Seçtiğiniz hesaba geçiş yapmak istiyor musunuz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        AccUserService.findAccUserSecurity(
          row.account.id,
          row.user.id,
          (data) => {
            dispatch(setOrganizations(data?.organizations));
            dispatch(setGroups(data?.groups));
            dispatch(setRole(data?.roles));
            dispatch(setAccountId(row.account.id));
            dispatch(setAccountName(row.account.fullname));
            dispatch(setAccountTimezone(row.account.timeZone));
            //window.location.reload();
            window.location = "/homepage";
          },
          (error) => {
            console.log("error findAccUserOrganizations", error);
            //window.location.reload();
            window.location = "/homepage";
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const rejectDisconnectMessage = () => {
    faDialog({
      description: t("Hesap sahibi olduğunuz için ilişiğinizi kesemezsiniz."),
      type: "info",
    })
      .then((confirmation) => {
        console.log("ebru");
      })
      .catch((err) => console.log("error:", err));
  };

  const disconnectAccount = (row) => {
    setSelectedAccUser(row);

    faDialog({
      description: t(
        "Seçtiğiniz hesaptan ilişiğinizi kesmek istiyor musunuz? Onay vermeniz durumunda bu hesaba geçiş yapılamayacaktır."
      ),
      type: "confirm",
    })
      .then((confirmation) => {
        AccUserService.disconnect(
          row.account.id,
          row.user.id,
          disconnectOnSuccess,
          disconnectOnError
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const deleteAccount = (row) => {
    setSelectedAccUser(row);

    faDialog({
      description: t(
        "Seçtiğiniz hesabı sistemden silmek istiyor musunuz? Onay vermeniz durumunda bu hesaba bağlı TÜM BİLGİLER KALICI OLARAK SİLİNECEKTİR."
      ),
      type: "confirm",
    })
      .then((confirmation) => {
        const aktifHesap = accountId == row.account.id;
        faDialog({
          description: t(
            "HESABI KALICI OLARAK SİLMEK İSTEDİĞİNİZE EMİN MİSİNİZ?" +
              (aktifHesap
                ? "(Aktif hesap silineceği için yeniden giriş yapmanız gerekecektir)"
                : "")
          ),
          type: "confirm",
        })
          .then((confirmation) => {
            setLoading(true);
            AccountService.delete(
              row.account,
              (data) => {
                tableRef.current.refreshTable();
                setLoading(false);
                if (aktifHesap) {
                  window.location.reload();
                }
              },
              (data) => {
                setLoading(false);
              }
            );
          })
          .catch((err) => console.log("error:", err));
      })
      .catch((err) => console.log("error:", err));
  };

  const disconnectOnSuccess = (data) => {
    tableRef.current.refreshTable();
  };

  const disconnectOnError = (data) => {};

  const addAccount = () => {
    const tmpTo = {
      pathname: `accountIncentiveScreen`,
      breadCrumbKey: `/accountIncentiveScreen`,
    };
    history.push(tmpTo);
  };

  const columns = [
    {
      field: "account.fullname",
      label: t("ACCOUNT"),
      align: "center",
      width: "20%",
      valueFormatter: (value, row) => {
        if (row.account.img != null) {
          return <div style={{display: "flex", alignItems: "center"}}><img src={row.account.img} style={{width: "48px", height: "40px", marginRight: "10px", borderRadius: "15%"}} /> {" "}{value}</div>
        }        
        else
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="square"
                style={{
                  width: "48px",
                  height: "48px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              >
                {" "}
                {value.charAt(0)}
              </Avatar>{" "}
              {value}
            </div>
          );
      },
    },    
    {
      field: "authority",
      label: t("AUTHORITY"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        return value && t("ROLE_" + value, { ns: "components" });
      },
    },
    {
      field: "id",
      label: t("OPERATION"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        if (accountId == row.account.id) {
          return (
            <>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ marginRight: 5 }}
                disabled={true}
                data-fa-button="SWITCH_ACCOUNT"
              >
                {t("SWITCH_ACCOUNT")}
              </Button>
              {isAccountOwner && row.account?.owner?.id === userInfo?.id && (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => deleteAccount(row)}
                  style={{ marginRight: 5 }}
                  data-fa-button="DELETE_ACCOUNT"
                >
                  {t("DELETE_ACCOUNT")}
                </Button>
              )}
              {!isAccountOwner && row.account?.owner?.id !== userInfo?.id && (
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => disconnectAccount(row)}
                  style={{ marginRight: 5 }}
                  data-fa-button="DISCONNECT_ACCOUNT"
                >
                  {t("DISCONNECT_ACCOUNT")}
                </Button>
              )}
            </>
          );
        } else {
          return (
            <>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => switchAccount(row)}
                style={{ marginRight: 5 }}
                data-fa-button="SWITCH_ACCOUNT"
              >
                {t("SWITCH_ACCOUNT")}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => {
                  isAccountOwner
                    ? rejectDisconnectMessage()
                    : disconnectAccount(row);
                }}
                style={{ marginRight: 5 }}
                data-fa-button="DISCONNECT_ACCOUNT"
              >
                {t("DISCONNECT_ACCOUNT")}
              </Button>
              {row.account?.owner?.id === userInfo?.id && (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => deleteAccount(row)}
                  style={{ marginRight: 5 }}
                  data-fa-button="DELETE_ACCOUNT"
                >
                  {t("DELETE_ACCOUNT")}
                </Button>
              )}
            </>
          );
        }
      },
    },
  ];

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Card data-fa-section="ACCOUNTS">
          <CardHeader
            title={t("ACCOUNTS")}
            data-fa-button="ACCOUNTS"
            action={<UserAccountsHelp value="profile.userAccounts" />}
          />
          <ThingsDataTable
            dataUrl={ACCOUNT.findByUser}
            columns={columns}
            ref={tableRef}
            showDeleteButton={false}
            showHeaderText={false}
            queryParams={{
              page: 0,
              size: 50,
              sort: "id",
              isAsc: false,
            }}
          />

          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={2} className={"thingsDataTableButtons"}>
              <FaButton
                variant="contained"
                disabled={loading}
                color="primary"
                size="medium"
                faClick={() => {
                  addAccount();
                }}
                startIcon={<Add />}
                data-fa-button="CREATE_ACCOUNT"
              >
                {t("CREATE_ACCOUNT")}
              </FaButton>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={6}></Grid>
          </Grid>

          <Backdrop style={{ zIndex: 1000 }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Card>
      </Suspense>
    </>
  );
}
