import {
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { green, grey } from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import AccountRecordSettingsHelp from "features/help/AccountRecordSettingsHelp";
import React, { useEffect, useState } from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { AccountRecordSettingsService } from "services/AccountRecordSettingsService";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import { useStyles } from "./AccountDetailsCardStyle";

export default function AccountRecordSettings(props) {
  const { account, onSave } = props;
  const { t } = useTranslation(["accountRecordSettings", "components"], {
    i18n,
  });
  const labelClasses = labelStyles();
  const accountId = localStorage.accountId;
  const classes = useStyles();
  const [isEditOpen, setIsEditOpen] = useState(false);

  const newAccountRecordSettings = () => {
    return {
      account: { id: accountId },
      photo: null,
      location: null,
      description: null,
      publicIdentifier: null,
      publicDescription: null,
      hashtag: null,
      vendor: null,
      serviceStartDate: null,
      relatedThings: null,
      interactiveThing: null,
      owner: null,
      dynamicFields: null,
      stepper: null,
    };
  };
  const [editValue, setEditValue] = useState(newAccountRecordSettings());
  const [accountRecordSettings, setAccountRecordSettings] = useState(
    newAccountRecordSettings()
  );

  useEffect(() => {
    if (account) {
      AccountRecordSettingsService.findByAccountId(
        accountId,
        (data) => {
          if (data) {
            setAccountRecordSettings(data);
            setEditValue(data);
          }
        },
        (error) => {
          setAccountRecordSettings(newAccountRecordSettings());
          setEditValue(newAccountRecordSettings());
          console.log(error);
        }
      );
    }
  }, [account]);

  const GreenSwitch = withStyles({
    switchBase: {
      color: grey[300],
      "&$checked": {
        color: green[500],
      },
      "&$checked + $track": {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const save = () => {
    AccountRecordSettingsService.save(
      editValue,
      (data) => {
        onSave();
      },
      (error) => {}
    );
  };

  const handleChange = (event) => {
    setEditValue({
      ...editValue,
      [event.target.name]: event.target.checked,
    });
  };

  const renderDialog = () => {
    return (
      <FaDialog
        title={t("ACCOUNT_RECORD_SETTINGS")}
        faOpen={isEditOpen}
        faOnSubmit={() => {
          save();
          setIsEditOpen(false);
        }}
        showSaveButton
        faHandleClose={(data) => {
          setIsEditOpen(false);
          setEditValue(accountRecordSettings);
        }}
        faMaxWidth="sm"
      >
        <Grid
          container
          spacing={1}
          data-fa-section="ACCOUNT_RECORD_SETTINGS_EDIT"
        >
          <Grid item xs={6}>
            <FormGroup>
              <FormControlLabel
                label={t("PHOTO")}
                labelPlacement="end"
                control={
                  <GreenSwitch
                    checked={editValue.photo}
                    onChange={handleChange}
                    name="photo"
                  />
                }
              />

              <FormControlLabel
                label={t("DESCRIPTION")}
                labelPlacement="end"
                control={
                  <GreenSwitch
                    checked={editValue.description}
                    onChange={handleChange}
                    name="description"
                  />
                }
              />
              <FormControlLabel
                label={t("HASHTAG")}
                labelPlacement="end"
                control={
                  <GreenSwitch
                    checked={editValue.hashtag}
                    onChange={handleChange}
                    name="hashtag"
                  />
                }
              />
              <FormControlLabel
                label={t("SERVICE_START_DATE")}
                labelPlacement="end"
                control={
                  <GreenSwitch
                    checked={editValue.serviceStartDate}
                    onChange={handleChange}
                    name="serviceStartDate"
                  />
                }
              />
              <FormControlLabel
                label={t("INTERACTIVE_THING")}
                labelPlacement="end"
                control={
                  <GreenSwitch
                    checked={editValue.interactiveThing}
                    onChange={handleChange}
                    name="interactiveThing"
                  />
                }
              />
              <FormControlLabel
                label={t("DYNAMIC_FIELDS")}
                labelPlacement="end"
                control={
                  <GreenSwitch
                    checked={editValue.dynamicFields}
                    onChange={handleChange}
                    name="dynamicFields"
                  />
                }
              />
              <FormControlLabel
                label={t("STEPPER")}
                labelPlacement="end"
                control={
                  <GreenSwitch
                    checked={editValue.stepper}
                    onChange={handleChange}
                    name="stepper"
                  />
                }
              />
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <FormGroup>
              <FormControlLabel
                label={t("LOCATION")}
                labelPlacement="end"
                control={
                  <GreenSwitch
                    checked={editValue.location}
                    onChange={handleChange}
                    name="location"
                  />
                }
              />
              <FormControlLabel
                label={t("PUBLIC_IDENTIFIER")}
                labelPlacement="end"
                control={
                  <GreenSwitch
                    checked={editValue.publicIdentifier}
                    onChange={handleChange}
                    name="publicIdentifier"
                  />
                }
              />
              <FormControlLabel
                label={t("VENDOR")}
                labelPlacement="end"
                control={
                  <GreenSwitch
                    checked={editValue.vendor}
                    onChange={handleChange}
                    name="vendor"
                  />
                }
              />
              <FormControlLabel
                label={t("RELATED_THINGS")}
                labelPlacement="end"
                control={
                  <GreenSwitch
                    checked={editValue.relatedThings}
                    onChange={handleChange}
                    name="relatedThings"
                  />
                }
              />

              <FormControlLabel
                label={t("OWNER")}
                labelPlacement="end"
                control={
                  <GreenSwitch
                    checked={editValue.owner}
                    onChange={handleChange}
                    name="owner"
                  />
                }
              />
              <FormControlLabel
                label={t("PUBLIC_DESCRIPTION")}
                labelPlacement="end"
                control={
                  <GreenSwitch
                    checked={editValue.publicDescription}
                    onChange={handleChange}
                    name="publicDescription"
                  />
                }
              />
            </FormGroup>
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  return (
    <>
      {renderDialog()}
      <Card style={{ marginTop: "16px", overflowY: "auto" }}>
        <CardHeader
          action={
            <>
              <AccountRecordSettingsHelp value="accountDetails.accountRecordSettings" />

              <IconButton
                aria-label="settings"
                onClick={setIsEditOpen}
                data-fa-button="ACCOUNT_RECORD_SETTINGS"
              >
                <EditIcon />
              </IconButton>
            </>
          }
          title={t("ACCOUNT_RECORD_SETTINGS")}
        />
        <CardContent>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <Grid
                item
                container
                spacing={1}
                style={{ marginLeft: 10, marginRight: 10 }}
              >
                <Grid item xs={5} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("PHOTO")}
                  </Typography>
                </Grid>
                <Grid item xs={1} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelValue}>
                    {accountRecordSettings?.photo ? <CheckIcon /> : null}
                  </Typography>
                </Grid>
                <Grid item xs={5} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("LOCATION")}
                  </Typography>
                </Grid>
                <Grid item xs={1} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelValue}>
                    {accountRecordSettings?.location ? <CheckIcon /> : null}
                  </Typography>
                </Grid>
                <Grid item xs={5} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("DESCRIPTION")}
                  </Typography>
                </Grid>
                <Grid item xs={1} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelValue}>
                    {accountRecordSettings?.description ? <CheckIcon /> : null}
                  </Typography>
                </Grid>
                <Grid item xs={5} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("PUBLIC_IDENTIFIER")}
                  </Typography>
                </Grid>
                <Grid item xs={1} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelValue}>
                    {accountRecordSettings?.publicIdentifier ? (
                      <CheckIcon />
                    ) : null}
                  </Typography>
                </Grid>
                <Grid item xs={5} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("HASHTAG")}
                  </Typography>
                </Grid>
                <Grid item xs={1} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelValue}>
                    {accountRecordSettings?.hashtag ? <CheckIcon /> : null}
                  </Typography>
                </Grid>
                <Grid item xs={5} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("VENDOR")}
                  </Typography>
                </Grid>
                <Grid item xs={1} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelValue}>
                    {accountRecordSettings?.vendor ? <CheckIcon /> : null}
                  </Typography>
                </Grid>
                <Grid item xs={5} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("SERVICE_START_DATE")}
                  </Typography>
                </Grid>
                <Grid item xs={1} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelValue}>
                    {accountRecordSettings?.serviceStartDate ? (
                      <CheckIcon />
                    ) : null}
                  </Typography>
                </Grid>
                <Grid item xs={5} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("RELATED_THINGS")}
                  </Typography>
                </Grid>
                <Grid item xs={1} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelValue}>
                    {accountRecordSettings?.relatedThings ? (
                      <CheckIcon />
                    ) : null}
                  </Typography>
                </Grid>
                <Grid item xs={5} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("INTERACTIVE_THING")}
                  </Typography>
                </Grid>
                <Grid item xs={1} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelValue}>
                    {accountRecordSettings?.interactiveThing ? (
                      <CheckIcon />
                    ) : null}
                  </Typography>
                </Grid>
                <Grid item xs={5} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("OWNER")}
                  </Typography>
                </Grid>
                <Grid item xs={1} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelValue}>
                    {accountRecordSettings?.owner ? <CheckIcon /> : null}
                  </Typography>
                </Grid>
                <Grid item xs={5} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("PUBLIC_DESCRIPTION")}
                  </Typography>
                </Grid>
                <Grid item xs={1} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelValue}>
                    {accountRecordSettings?.publicDescription ? (
                      <CheckIcon />
                    ) : null}
                  </Typography>
                </Grid>
                <Grid item xs={5} className={labelClasses.LabelLastRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("DYNAMIC_FIELDS")}
                  </Typography>
                </Grid>
                <Grid item xs={1} className={labelClasses.LabelLastRow}>
                  <Typography className={labelClasses.LabelValue}>
                    {accountRecordSettings?.dynamicFields ? (
                      <CheckIcon />
                    ) : null}
                  </Typography>
                </Grid>
                <Grid item xs={5} className={labelClasses.LabelLastRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("STEPPER")}
                  </Typography>
                </Grid>
                <Grid item xs={1} className={labelClasses.LabelLastRow}>
                  <Typography className={labelClasses.LabelValue}>
                    {accountRecordSettings?.stepper ? <CheckIcon /> : null}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
