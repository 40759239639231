import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  campaignCard: {
    marginBottom: theme.spacing(2),
  },
  campaignCardHeader: {
    height: 70,
    alignContent: "center",
    display: "grid",
    textAlign: "center",
    //backgroundColor: theme.palette.primary.dark,
    //color: theme.palette.primary.contrastText,
    fontWeight: "bold",
    fontSize: "1.5em",
  },
  campaignCardDescription: {
    height: 100,
    alignContent: "center",
    display: "grid",
    textAlign: "center",
    borderBottom: "1px solid #cdd3e0",
  },
  campaignCardProperties: {
    height: 100,
    alignContent: "center",
    display: "grid",
    textAlign: "center",
    borderBottom: "1px solid #cdd3e0",
  },
  campaignPropertyItems: {
    listStyleType: "none",
  },
  oldPrice: {
    display: "block",
    fontSize: "1.5em",
    whiteSpace: "nowrap",
    fontWeight: "400",
    "&::after": {
      content: "' '",
      display: "block",
      width: 90,
      margin: "-15px auto 5px",
      borderBottom: "2px solid rgba(255,61,0,.8)",
      transform: "rotate(-5deg)",
    },
  },
  price: {
    fontSize: "2em",
    fontWeight: "bold",
  },
  description: {
    fontSize: "1em",
  },
  campaignCardButton: {
    height: 80,
    alignContent: "center",
    display: "grid",
    textAlign: "center",
    justifyContent: "center",
  },
}));
