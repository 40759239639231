import { CardHeader } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { default as React, Suspense, useEffect, useState } from "react";
import { FaPieChart } from "react-base-fa/dist/fa/facharts";
import { useTranslation } from "react-i18next";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { ThingStatisticService } from "../../services/statistic/statisticService";
import { useStyles } from "./style";

export default function StorageStat() {
  const classes = useStyles();
  const { t } = useTranslation("report", { i18n });
  const accountId = localStorage.accountId;

  const [statusReport, setStatusReport] = useState({
    capacity: 0,
    used: 0,
    thingsUsed: 0,
    issuesUsed: 0,
    solutionsUsed: 0,
    available: 0,
  });

  const [chartUsedData, setChartUsedData] = useState([]);
  const [chartDetailData, setChartDetailData] = useState([]);

  useEffect(() => {
    if (accountId) {
      ThingStatisticService.storageStat(
        accountId,
        storageStatSuccess,
        storageStatError
      );
    }
  }, [accountId]);

  const storageStatSuccess = (data) => {
    var chartData1 = [];
    chartData1.push({
      id: "Boş",
      label: "Boş",
      value: Math.round(data.available * 100) / 100,
      color: "#3D9B35",
    });
    chartData1.push({
      id: "Kullanılan",
      label: "Kullanılan",
      value: Math.round(data.used * 100) / 100,
      color: "#EF233C",
    });

    setChartUsedData(chartData1);

    var chartData2 = [];
    if (data.thingsUsed)
      chartData2.push({
        id: "Etkileşim Noktaları",
        label: "Etkileşim Noktaları",
        value: Math.round(data.thingsUsed * 100) / 100,
      });
    if (data.issuesUsed)
      chartData2.push({
        id: "Bildirimler",
        label: "Bildirimler",
        value: Math.round(data.issuesUsed * 100) / 100,
      });
    if (data.solutionsUsed)
      chartData2.push({
        id: "Servis/Operasyon",
        label: "Servis/Operasyon",
        value: Math.round(data.solutionsUsed * 100) / 100,
      });
    if (data.CustomerUsed)
      chartData2.push({
        id: "Müşteriler",
        label: "Müşteriler",
        value: Math.round(data.CustomerUsed * 100) / 100,
      });
    if (data.serviceOperationUsed)
      chartData2.push({
        id: "Bakım Görevleri",
        label: "Bakım Görevleri",
        value: Math.round(data.serviceOperationUsed * 100) / 100,
      });
    if (data.customerMessageTemplateUsed * 100)
      chartData2.push({
        id: "Müşteri Mesajları",
        label: "Müşteri Mesajları",
        value: Math.round(data.customerMessageTemplateUsed * 100) / 100,
      });
    if (data.beneficiaryNoteUsed)
      chartData2.push({
        id: "Faydalanıcı Notları",
        label: "Faydalanıcı Notları",
        value: Math.round(data.beneficiaryNoteUsed * 100) / 100,
      });
    if (data.campaignUsed)
      chartData2.push({
        id: "Kampanyalar",
        label: "Kampanyalar",
        value: Math.round(data.campaignUsed * 100) / 100,
      });
    if (data.thingChangedUsed)
      chartData2.push({
        id: "Varlık Sahibi Değişimi",
        label: "Varlık Sahibi Değişimi",
        value: Math.round(data.thingChangedUsed) / 100,
      });
    if (chartData2.length === 0)
      chartData2.push({
        id: "Boş",
        label: "Depolama Alanı Kullanılmıyor",
        value: 100,
      });
    setChartDetailData(chartData2);

    setStatusReport(data);
  };

  const storageStatError = (data) => {};

  const renderChart = () => {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item lg={6} xs={12} component={Card} className={classes.card}>
            <CardHeader
              title={t("STORAGE_DISC_STATUS")}
              data-fa-section="STORAGE_DISC_STATUS"
            />
            <CardContent>
              <Grid container spacing={1}>
                <Grid item lg={12} xs={12}>
                  <Grid item>
                    <FaPieChart
                      data={chartUsedData}
                      // legends={[]}
                      height="300px"
                      innerRadius={0.05}
                      padAngle={2}
                      colors={["#3D9B35", "#EF233C"]}
                      arcLabel={(e) => e.value + " MB"}
                    />
                  </Grid>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <Typography variant="caption">
                    Toplam depolama alanı {statusReport.capacity} MB
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
          <Grid item lg={6} xs={12} component={Card} className={classes.card}>
            <CardHeader
              title={t("USED_DISC_STORAGE")}
              data-fa-section="USED_DISC_STORAGE"
            />
            <CardContent>
              <Grid container spacing={1}>
                <Grid item lg={12} xs={12}>
                  <Grid item>
                    <FaPieChart
                      data={chartDetailData}
                      // legends={[]}
                      height="300px"
                      innerRadius={0.05}
                      padAngle={2}
                      arcLabel={(e) => e.value + " MB"}
                      colors={[
                        "#F54E62",
                        "#F9F13D",
                        "#60DC10",
                        "#19DFEC",
                        "#1959EC",
                        "#EB48EE",
                        "#6F545C",
                        "#AFC2BE",
                      ]}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>{renderChart()}</Suspense>
    </>
  );
}
