import {
  faCubes,
  faFolder,
  faGlobe,
  faHashtag,
  faMap,
  faSitemap,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { Add, Cancel, Edit } from "@material-ui/icons";
import LocationAutoComplete from "components/autocomplete/LocationAutoComplete";
import OrganizationAutoComplete from "components/autocomplete/OrganizationAutoComplete";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import ThingClassAutoComplete from "components/autocomplete/ThingClassAutoComplete";
import ThingHashtag from "components/hashtag/ThingHashtag";
import TargetGroupEdit from "components/targetGroup/TargetGroupEdit";
import ThingsDataTable from "components/thingsDataTable";
import { default as React, Suspense, useEffect, useState } from "react";
import { FaButton, FaDialog } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { useTranslation } from "react-i18next";
import { StockTakingUserService } from "services/StockTakingUserService";
import { ThingDesc, isTNF } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function StockTakingTargets(props) {
  const { stockTaking, onSave } = props;
  const { t } = useTranslation("stockTaking", { i18n });
  const accountId = localStorage.accountId;
  const faDialog = useDialog();
  const labelClasses = labelStyles();
  const classes = useStyles();

  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [userList, setUserList] = useState([]);
  const [user, setUser] = useState(null);
  const [userGroup, setUserGroup] = useState(null);
  const [stockTakingEdit, setStockTakingEdit] = useState(null);

  const [sectionHashtag, setSectionHashtag] = useState(false);
  const [sectionThingClass, setSectionThingClass] = useState(false);
  const [sectionThing, setSectionThing] = useState(false);
  const [sectionOrganization, setSectionOrganization] = useState(false);
  const [sectionLocation, setSectionLocation] = useState(false);

  const columns = [
    {
      field: "user",
      label: t("USER"),
      align: "center",
      width: "90%",
      valueFormatter: (value, row) => {
        return row.user ? row.user.fullname : row.userGroup?.name;
      },
    },
    {
      field: "id",
      label: t("İşlem"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        return (
          <IconButton
            onClick={() => deleteUser(row)}
            size="small"
            color="primary"
            aria-label={t("Sil")}
            disabled={stockTaking.status !== "DRAFT"}
          >
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        );
      },
    },
  ];

  useEffect(() => {
    refresh();
  }, [stockTaking]);

  const refresh = () => {
    if (stockTaking && stockTaking.id > 0) {
      StockTakingUserService.findByStockTaking(
        stockTaking.id,
        findByOnSuccess,
        findByOnError
      );
    }
  };

  const findByOnSuccess = (data) => {
    setUserList(data.list);
  };

  const findByOnError = (error) => {
    console.log(error);
  };

  const addUser = () => {
    const data = { stockTaking, user, userGroup };
    StockTakingUserService.save(data, saveOnSuccess, saveOnError);
  };

  const saveOnSuccess = (data) => {
    refresh();
    setIsEditFormOpen(false);
  };

  const saveOnError = (data) => {};

  const deleteUser = (data) => {
    faDialog({
      description: t("Kaydı silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        StockTakingUserService.delete(data, deleteOnSuccess, deleteOnError);
      })
      .catch((err) => console.log("error:", err));
  };

  const deleteOnSuccess = (data) => {
    refresh();
  };

  const deleteOnError = (data) => {};

  const renderTargets = () => {
    if (
      stockTaking == null ||
      stockTaking.targets == null ||
      stockTaking.targets.length === 0
    ) {
      return (
        <ThingsDataTable
          data={[]}
          columns={columns}
          //ref={tableRef}
          showDeleteButton={false}
          showHeaderText={false}
          showPaging={false}
          showHeader={false}
        />
      );
    } else
      return (
        <ul className={classes.chipRoot}>
          {stockTaking?.targets?.map((data, index) => {
            let icon;
            let label;

            if (data.thingClass) {
              icon = <FontAwesomeIcon icon={faSitemap} />;
              label = data.thingClass.className;
            } else if (data.thing) {
              icon = <FontAwesomeIcon icon={faCubes} />;
              label = ThingDesc(data.thing);
            } else if (data.hashtag) {
              icon = <FontAwesomeIcon icon={faHashtag} />;
              label = data.hashtag;
            } else if (data.organization) {
              icon = <FontAwesomeIcon icon={faFolder} />;
              label = data.organization.name;
            } else if (data.location) {
              icon = <FontAwesomeIcon icon={faGlobe} />;
              label = data.location.name;
            } else if (data.locationDetail) {
              icon = <FontAwesomeIcon icon={faMap} />;
              label = data.locationDetail.name;
            }

            return (
              <li key={data.key}>
                <Chip icon={icon} label={label} className={classes.chip} />
              </li>
            );
          })}
        </ul>
      );
  };

  const handleChipDelete = (index) => () => {
    var temp = [...stockTakingEdit.targets];
    temp.splice(index, 1);
    setStockTakingEdit({ ...stockTakingEdit, targets: temp });
  };

  const addTargetHashtag = (value) => {
    let dto = {
      thingClass: null,
      thing: null,
      hashtag: value,
      label: value,
      key: "h_" + value,
    };

    addTarget(dto);
  };

  const addTargetThingClass = (value) => {
    let dto = {
      thingClass: { id: value.id },
      thing: null,
      hashtag: null,
      label: value.className,
      key: "c_" + value.id,
    };

    addTarget(dto);
  };

  const addTargetThing = (value) => {
    let dto = {
      thingClass: null,
      thing: { id: value.id },
      hashtag: null,
      label: ThingDesc(value),
      key: "t_" + value.id,
    };

    addTarget(dto);
  };

  const addTargetOrganization = (value) => {
    let dto = {
      organization: { id: value.id },
      label: value.name,
      key: "o_" + value.id,
    };

    addTarget(dto);
  };

  const addTargetLocation = (value) => {
    let dto = {
      location: { id: value.id },
      label: value.name,
      key: "l_" + value.id,
    };

    addTarget(dto);
  };

  const addTargetLocationDetail = (value) => {
    let dto = {
      locationDetail: { id: value.id },
      label: value.name,
      key: "ld_" + value.id,
    };

    addTarget(dto);
  };

  const addTarget = (newValue) => {
    setStockTakingEdit({
      ...stockTakingEdit,
      targets: [...stockTakingEdit.targets, newValue],
    });
  };

  const renderEditForm = () => {
    return (
      <>
        <FaDialog
          title={"TARGET_GROUP"}
          faOpen={isEditFormOpen}
          faOnSubmit={addUser}
          //loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            setIsEditFormOpen(data);
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <Paper component="ul" className={classes.chipRoot}>
                {stockTakingEdit?.targets?.map((data, index) => {
                  let icon;

                  if (data.thingClass) {
                    icon = <FontAwesomeIcon icon={faSitemap} />;
                  } else if (data.thing) {
                    icon = <FontAwesomeIcon icon={faCubes} />;
                  } else if (data.hashtag) {
                    icon = <FontAwesomeIcon icon={faHashtag} />;
                  } else if (data.organization) {
                    icon = <FontAwesomeIcon icon={faFolder} />;
                  } else if (data.location) {
                    icon = <FontAwesomeIcon icon={faGlobe} />;
                  } else if (data.locationDetail) {
                    icon = <FontAwesomeIcon icon={faMap} />;
                  }

                  return (
                    <li key={data.key}>
                      <Chip
                        icon={icon}
                        label={data.label}
                        onDelete={handleChipDelete(index)}
                        className={classes.chip}
                      />
                    </li>
                  );
                })}
              </Paper>
              <div className={classes.buttonPanel}>
                <FaButton
                  faClick={() => {
                    setSectionHashtag(true);
                  }}
                  fullWidth={false}
                  startIcon={<Add />}
                >
                  {t("ADD_HASHTAG")}
                </FaButton>
                <FaButton
                  faClick={() => {
                    setSectionThingClass(true);
                  }}
                  fullWidth={false}
                  startIcon={<Add />}
                >
                  {isTNF(t("ADD_THINGCLASS"), t("ADD_THINGCLASS_T2C"))}
                </FaButton>
                <FaButton
                  faClick={() => {
                    setSectionThing(true);
                  }}
                  fullWidth={false}
                  startIcon={<Add />}
                >
                  {isTNF(t("ADD_THING"), t("ADD_THING_T2C"))}
                </FaButton>
                <FaButton
                  faClick={() => {
                    setSectionOrganization(true);
                  }}
                  fullWidth={false}
                  startIcon={<Add />}
                >
                  {t("ADD_ORGANIZATION")}
                </FaButton>
                <FaButton
                  faClick={() => {
                    setSectionLocation(true);
                  }}
                  fullWidth={false}
                  startIcon={<Add />}
                >
                  {t("ADD_LOCATION")}
                </FaButton>
              </div>
              {sectionHashtag ? (
                <div className={classes.hboxBlock}>
                  <ThingHashtag
                    accountId={accountId}
                    onChange={(data) => {
                      addTargetHashtag(data);
                      setSectionHashtag(false);
                    }}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Cancel />}
                    className={classes.cancelButton}
                    onClick={() => {
                      setSectionHashtag(false);
                    }}
                  >
                    {t("CANCEL")}
                  </Button>
                </div>
              ) : null}

              {sectionThingClass ? (
                <div className={classes.hboxBlock}>
                  <ThingClassAutoComplete
                    accountId={accountId}
                    onChange={(data) => {
                      addTargetThingClass(data);
                      setSectionThingClass(false);
                    }}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Cancel />}
                    className={classes.cancelButton}
                    onClick={() => {
                      setSectionThingClass(false);
                    }}
                  >
                    {t("CANCEL")}
                  </Button>
                </div>
              ) : null}

              {sectionThing ? (
                <div className={classes.hboxBlock}>
                  <ThingAutoComplete
                    accountId={accountId}
                    onChange={(data) => {
                      addTargetThing(data);
                      setSectionThing(false);
                    }}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Cancel />}
                    className={classes.cancelButton}
                    onClick={() => {
                      setSectionThing(false);
                    }}
                  >
                    {t("CANCEL")}
                  </Button>
                </div>
              ) : null}

              {sectionOrganization ? (
                <div className={classes.hboxBlock}>
                  <OrganizationAutoComplete
                    accountId={accountId}
                    onChange={(data) => {
                      addTargetOrganization(data);
                      setSectionOrganization(false);
                    }}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Cancel />}
                    className={classes.cancelButton}
                    onClick={() => {
                      setSectionOrganization(false);
                    }}
                  >
                    {t("CANCEL")}
                  </Button>
                </div>
              ) : null}

              {sectionLocation ? (
                <div className={classes.hboxBlock}>
                  <LocationAutoComplete
                    accountId={accountId}
                    onChange={(data) => {
                      addTargetLocation(data);
                      setSectionLocation(false);
                    }}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Cancel />}
                    className={classes.cancelButton}
                    onClick={() => {
                      setSectionLocation(false);
                    }}
                  >
                    {t("CANCEL")}
                  </Button>
                </div>
              ) : null}
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <TargetGroupEdit
          value={stockTaking.targets}
          onSave={(data) => {
            onSave(data);
            setIsEditFormOpen(false);
          }}
          onClose={() => {
            setIsEditFormOpen(false);
          }}
          open={isEditFormOpen}
        />
        <Card style={{ minHeight: 100 }}>
          <CardHeader
            action={
              <IconButton
                aria-label="settings"
                disabled={stockTaking.status !== "DRAFT"}
                onClick={() => {
                  setIsEditFormOpen(true);
                }}
              >
                <Edit />
              </IconButton>
            }
            title={t("STOCK_TAKING_SCOPE")}
            data-fa-section="STOCK_TAKING_SCOPE"
          />

          <Grid item container spacing={0}>
            <Grid item xs={12} className={labelClasses.LabelRow}>
              <Typography className={labelClasses.LabelValue}>
                {renderTargets()}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Suspense>
    </>
  );
}
