import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { MESSAGE_TEMPLATE } from "./faApi";

export const MessageTemplateService = {
  save: (salesPackage, callback, errorCallback) => {
    let reqBody = {
      ...salesPackage,
    };

    let req = requests.getSimplePostRequest(MESSAGE_TEMPLATE.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (salesPackage, callback, errorCallback) => {
    let reqBody = {
      ...salesPackage,
    };

    let req = requests.getSimplePostRequest(MESSAGE_TEMPLATE.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },

  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(MESSAGE_TEMPLATE.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },

  getListWithTargets: (searchForm, callback, errorCallback) => {
    let reqBody = { ...searchForm };
    let req = requests.getSimplePostRequest(
      MESSAGE_TEMPLATE.getListWithTargets,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
};
