import { Button, Grid } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import UserAutoComplete from "components/autocomplete/UserAutoComplete";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaDatePicker, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ProxyService } from "services/ProxyService";
import i18n from "../../i18n";

const ProxyEdit = forwardRef((props, ref) => {
  const { onSave, onClose, proxy } = props;
  const { t } = useTranslation("proxy", { i18n });
  const accountId = localStorage.accountId;
  const faDialog = useDialog();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const newProxy = () => {
    return {
      account: { id: accountId },
      description: null,
      sourceUser: null,
      targetUser: null,
      startDate: null,
      endDate: null,
      includeQueueDispatch: false,
    };
  };
  const [proxyDTO, setProxyDTO] = useState(newProxy());

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsDialogOpen(true);
    },
  }));

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    if (proxy?.id) {
      setProxyDTO(proxy == null ? newProxy() : { ...proxy });
    } else {
      setProxyDTO(newProxy());
    }
  }, [proxy, isDialogOpen]);

  const saveProxy = () => {
    setLoading(true);
    ProxyService.save({ ...proxyDTO }, saveOnSuccess, saveOnError);
  };

  const saveOnSuccess = (data) => {
    showAlert(!proxyDTO.id ? t("SAVED") : t("EDITED"), "success");
    setIsDialogOpen(false);
    setLoading(false);
    onSave();
  };

  const saveOnError = (error) => {
    showAlert(t(error.message), "error");
    setLoading(false);
  };

  const handleChange = (prop) => (event) => {
    setProxyDTO({ ...proxyDTO, [prop]: event.target.value });
  };

  const handleChangeV = (prop, val) => {
    setProxyDTO({ ...proxyDTO, [prop]: val });
  };
  const onDelete = (data) => {
    faDialog({
      description: t("Kuralı silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        ProxyService.delete(
          proxyDTO,
          (data) => {
            showAlert(t("Vekalet Silindi"), "success");
            onSave();
          },
          (error) => {}
        );
      })
      .catch((err) => console.log("error:", err));
  };
  return (
    <FaDialog
      title={t("EDIT_PROXY")}
      faOpen={isDialogOpen}
      faOnSubmit={saveProxy}
      loading={loading}
      showSaveButton
      faHandleClose={() => {
        setIsDialogOpen(false);
        onClose();
      }}
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item xs={12}>
          <UserAutoComplete
            value={proxyDTO?.sourceUser}
            accountId={accountId}
            onChange={(data) => {
              handleChangeV("sourceUser", data);
            }}
            label={t("SOURCE_USER")}
          />
        </Grid>

        <Grid item xs={12}>
          <UserAutoComplete
            value={proxyDTO?.targetUser}
            accountId={accountId}
            onChange={(data) => {
              handleChangeV("targetUser", data);
            }}
            label={t("TARGET_USER")}
          />
        </Grid>

        <Grid item xs={12}>
          <FaInput
            label={t("DESCRIPTION")}
            faType="description"
            inputProps={{ maxLength: 255 }}
            onChange={handleChange("description")}
            value={proxyDTO.description || ""}
          />
        </Grid>

        <Grid item xs={12}>
          <FaDatePicker
            label={t("START_DATE")}
            faType="dateTime"
            value={proxyDTO.startDate}
            faOnChange={(val) => {
              handleChangeV("startDate", val);
            }}
            data-fa-input="START_DATE"
          />
        </Grid>

        <Grid item xs={12}>
          <FaDatePicker
            label={t("END_DATE")}
            faType="dateTime"
            value={proxyDTO.endDate}
            faOnChange={(val) => {
              handleChangeV("endDate", val);
            }}
            data-fa-input="END_DATE"
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl variant="outlined" size="small">
            <FormControlLabel
              control={
                <Checkbox
                  checked={proxyDTO.includeQueueDispatch}
                  onChange={(event) => {
                    handleChangeV("includeQueueDispatch", event.target.checked);
                  }}
                  color="primary"
                />
              }
              label={t("INCLUDE_QUEUE_DISPATCH")}
            />
          </FormControl>
        </Grid>
      </Grid>

      {proxyDTO.id && (
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button
            onClick={() => {
              setIsDialogOpen(false);
              onDelete();
            }}
            color="secondary"
          >
            {t("Sil")}
          </Button>
        </Grid>
      )}
    </FaDialog>
  );
});

export default ProxyEdit;
