import { Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ActivePassiveSelect from "components/select/ActivePassiveSelect";
import ThingsDataTable from "components/thingsDataTable";
import ActivePassiveDecorator from "components/ui/ActivePassiveDecorator";
import React, { Suspense, useRef, useState } from "react";
import { FaButton, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { PERIODIC_REPORT } from "../../services/faApi";
import PeriodicReportEdit from "./PeriodicReportEdit";

export default function PeriodicReport() {
  const tableRef = useRef();
  const { t } = useTranslation(["periodicReport", "components"], { i18n });
  const dispatch = useDispatch();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const dialogRef = useRef();

  const accountId = localStorage.accountId;

  const [loading, setLoading] = useState(false);

  const columns = [
    {
      field: "name",
      label: t("NAME"),
      align: "center",
      width: "40%",
    },
    {
      field: "description",
      label: t("DESCRIPTION"),
      align: "center",
      width: "40%",
    },
    {
      field: "status",
      label: t("STATUS"),
      align: "center",
      width: "20%",
      valueFormatter: (val) => <ActivePassiveDecorator value={val} />,
    },
  ];

  const newSearchForm = () => {
    let result = {
      accountId: accountId,
      name: null,
      description: null,
      status: null,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `periodicReport/detail/${row.id}`,
      breadCrumbKey: `/periodicReport/detail/:id`,
    };
    history.push(tmpTo);
  };

  const refresh = () => {
    tableRef.current.refreshTable();
  };

  const onCreate = (data) => {
    const tmpTo = {
      pathname: `/periodicReport/detail/${data.id}`,
      breadCrumbKey: `/periodicReport/detail/:id`,
    };
    history.push(tmpTo);
  };

  const addPeriodicReport = () => {
    return (
      <PeriodicReportEdit
        ref={dialogRef}
        onSave={onCreate}
      ></PeriodicReportEdit>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {addPeriodicReport()}
        <ThingsDataTable
          searchUrl={PERIODIC_REPORT.search}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={1} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                dialogRef.current.openDialog();
              }}
              startIcon={<AddIcon />}
              data-fa-button="ADD"
            >
              {t("ADD")}
            </FaButton>
          </Grid>
          <Grid item xs={5}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <FaInput
                label={t("NAME")}
                value={searchForm.name}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "name",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("DESCRIPTION")}
                faType="description"
                value={searchForm.descripton}
                inputProps={{ maxLength: 255 }}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "description",
                    event.target.value
                  );
                }}
              />

              <ActivePassiveSelect
                value={searchForm.status}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "status",
                    data
                  );
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
