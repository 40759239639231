import { CardHeader } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Suggestions from "features/suggestions";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";

export default function HomepageSuggestionList(props) {
  const { size } = props;
  const { t } = useTranslation("components", { i18n });
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 7);
  startDate.setHours(0, 0, 0, 0);
  return (
    <Grid item lg={size} xs={12}>
      <Card>
        <CardHeader title={t("HOMEPAGE_ITEM.SUGGESTION_LIST")} />
        <Suggestions startDate={startDate} />
      </Card>
    </Grid>
  );
}
