// Thingsin çalıştığı ortamı belirlemek için kullanılıyor.
// Yeni bir değişken eklenirse diğer bütün ortamlara eklenmesi gerekli.

const DEFAULT = {
  showGoogleButton: true, //Login sayfasında google gösterilecek mi?
  showFacebookButton: true, //Login sayfasında facebook gösterilecek mi?
  showAppleButton: false, //Login sayfasında apple gösterilecek mi? //FIXME
  showBeneficiaryButton: true, //Login sayfasında faydalanıcı girişi gösterilecek mi?
  showSignUpButton: true, //Login sayfasında kayıt ol gösterilecek mi?
  showForgotPasswordButton: true, //Login sayfasında şifremi unuttum gösterilecek mi?
  fingerprint: true, //fingerprint modülü aktif olacak mı?
  companyLogo: null, //Kurulum yapılan kurumun logosu
  loginPageHelp: false, //Login sayfasında yardım masası kılavuzu gösterilecek mi?
  appLogo: process.env.PUBLIC_URL + "/assets/things.png", //login sayfası logo
  appBarLogo: process.env.PUBLIC_URL + "/assets/things_menu.png", //menu logo
  appLogoT2CWhite: process.env.PUBLIC_URL + "/assets/t2c-logo-white.png", //Things2Care logo
  appLogoT2CDark: process.env.PUBLIC_URL + "/assets/t2c-logo-dark.png", //Things2Care logo
  forceUserLocation: true, //Kullanıcı konumunun girişini zorla (Dahili, kat, oda)
};

const CEVRE_BAKANLIGI = {
  showGoogleButton: false,
  showFacebookButton: false,
  showAppleButton: false,
  showBeneficiaryButton: false,
  showSignUpButton: false,
  showForgotPasswordButton: false,
  fingerprint: false,
  companyLogo: process.env.PUBLIC_URL + "/assets/things_menu.png",
  loginPageHelp: true,
  appLogo: process.env.PUBLIC_URL + "/assets/cevreLogo.png",
  appBarLogo: process.env.PUBLIC_URL + "/assets/cevreLogo.png",
  appLogoT2CWhite: process.env.PUBLIC_URL + "/assets/cevreLogo.png",
  appLogoT2CDark: process.env.PUBLIC_URL + "/assets/cevreLogo.png",
  forceUserLocation: true,
};

const TANAP = {
  showGoogleButton: false,
  showFacebookButton: false,
  showAppleButton: false,
  showBeneficiaryButton: false,
  showSignUpButton: false,
  showForgotPasswordButton: false,
  fingerprint: false,
  companyLogo: process.env.PUBLIC_URL + "/assets/things_menu.png",
  loginPageHelp: false,
  appLogo: process.env.PUBLIC_URL + "/assets/tanap.svg",
  appBarLogo: process.env.PUBLIC_URL + "/assets/tanap.svg",
  appLogoT2CWhite: process.env.PUBLIC_URL + "/assets/tanap.svg",
  appLogoT2CDark: process.env.PUBLIC_URL + "/assets/tanap.svg",
  forceUserLocation: false,
};

export const THINGS_ENVIRONMENT = {
  showGoogleButton: DEFAULT.showGoogleButton,
  showFacebookButton: DEFAULT.showFacebookButton,
  showAppleButton: DEFAULT.showAppleButton,
  showBeneficiaryButton: DEFAULT.showBeneficiaryButton,
  showSignUpButton: DEFAULT.showSignUpButton,
  showForgotPasswordButton: DEFAULT.showForgotPasswordButton,
  fingerprint: DEFAULT.fingerprint,
  companyLogo: DEFAULT.companyLogo,
  loginPageHelp: DEFAULT.loginPageHelp,
  appLogo: DEFAULT.appLogo,
  appBarLogo: DEFAULT.appBarLogo,
  appLogoT2CWhite: DEFAULT.appLogoT2CWhite,
  appLogoT2CDark: DEFAULT.appLogoT2CDark,
  forceUserLocation: DEFAULT.forceUserLocation,
};
