// import { configureStore } from '@reduxjs/toolkit';
// import counterReducer from 'features/counter/counterSlice';
// import reduxDenemeReducer from 'features/reduxDeneme/ReduxDenemeSlice';
// export default configureStore({
//   reducer: {
//     reduxDeneme: reduxDenemeReducer,
//   },
// });
import { configureStore,getDefaultMiddleware  } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import rootReducer from './rootReducer'
import { ThunkMiddleware } from 'redux-thunk';

const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware({ thunk: true,
    immutableCheck: true,
    serializableCheck: false}), logger as ThunkMiddleware]
})

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default
    store.replaceReducer(newRootReducer)
  })
}

export type AppDispatch = typeof store.dispatch

export default store



