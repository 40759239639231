export const AUT = {
  login: "user/login",
  //login: "user/loginMaster",
  logout: "aut/logout",
};

export const USER_APPLICATION = {
  signUp: "user/register",
  verify: "userApplication/verify",
  findByUsername: "user/findByUsername",
  confrim: "user/",
  changepassword: "user/changePassword",
  changeForgotPassword: "user/changeForgotPassword",
  forgotPasswordByEmail: "user/forgotPasswordByEmail",
  forgotPasswordByPhone: "user/forgotPasswordByPhone",
};

export const COUNTRY = {
  getLikeName: "country/name_like",
  findAll: "country/findAll",
  delete: "country/delete",
  findById: "country/findById",
  save: "country/save",
  findByNameContaining: "country/findByNameContaining",
};

export const USER = {
  getObj: "user",
  update: "user/update",
  changePassword: "user/changePassword",
  changeCurrentAccount: "user/changeCurrentAccount",
  findByUsername: "user/findByUsername",
  verificationTokenViaEmail: "user/verificationTokenViaEmail",
  verificationTokenViaPhone: "user/verificationTokenViaPhone",
  phoneVerify: "user/phoneVerify",
  phoneVerifyOnly: "user/phoneVerifyOnly",
  findByEmail: "user/findByEmail",
  findByPhone: "user/findByPhone",
  emailVerify: "user/emailVerify",
  emailVerifyOnly: "user/emailVerifyOnly",
  findByFacebookId: "user/findByFacebookId",
  findByGoogleId: "user/findByGoogleId",
  findByAppleCode: "user/findByAppleCode",
  facebookVerify: "user/facebookVerify",
  googleVerify: "user/googleVerify",
  getUiSettings: "user/getUiSettings",
  saveUiSettings: "user/saveUiSettings",
  appleLogin: "user/appleLogin",
  appleRegister: "user/appleRegister",
  getLocation: "user/getLocation",
  updateLocation: "user/updateLocation",
  saveSocialMediaAccounts: "user/saveSocialMediaAccounts",
  saveAppleAccount: "user/saveAppleAccount",
};

export const THINGS_USER = {
  thingsPhoneVerify: "thingsUser/thingsPhoneVerify",
  thingsEmailVerify: "thingsUser/thingsEmailVerify",
  thingsGoogleVerify: "thingsUser/thingsGoogleVerify",
  thingsFacebookVerify: "thingsUser/thingsFacebookVerify",
  thingsAppleRegister: "thingsUser/thingsAppleRegister",
};

export const ACCOUNT = {
  findByUsername: "account/findByUsername",
  findById: "account/findById",
  findByOwner: "account/findByOwner",
  update: "account/update",
  createAccount: "account/createAccount",
  payment: "account/payment",
  getAccTransaction: "account/getAccTransaction",
  findToinCountByAccount: "account/findToinCountByAccount",
  findAccountUsers: "account/findAccountUsers",
  findAccountUserGroups: "account/findAccountUserGroups",
  findAccountUsersAndGroups: "account/findAccountUsersAndGroups",
  uploadLogo: "account/uploadLogo",
  downloadLogo: "account/downloadLogo",
  downloadLogoThumbnail: "account/downloadLogoThumbnail",
  findByUser: "account/findByUser",
  findUserAccounts: "account/findUserAccounts",
  exportData: "account/exportData",
  getLastExportData: "account/getLastExportData",
  downloadExportData: "account/downloadExportData",
  downloadExportDataRaw: "account/downloadExportDataRaw",
  deleteExportData: "account/deleteExportData",
  delete: "account/delete",
  getSubscription: "account/getSubscription",
  deleteLogo: "account/deleteLogo",
  updateSocialMedia: "account/updateSocialMedia",
  getExportDataSummary: "account/getExportDataSummary",
  thumbnail: "account/thumbnail",
};

export const ACC_PREFERENCES = {
  saveAccountPreferences: "accPreferences/saveAccountPreferences",
  saveFeedbacks: "accPreferences/saveFeedbacks",
  savePeriodicPreferences: "accPreferences/savePeriodicPreferences",
  findByAccount: "accPreferences/findByAccount",
  updatePublicReportPageTemplate:
    "accPreferences/updatePublicReportPageTemplate",
  saveInteractionPagePreferences:
    "accPreferences/saveInteractionPagePreferences",
};

export const ACCOUNT_RECORD_SETTINGS = {
  save: "accountRecordSettings/save",
  findByAccountId: "accountRecordSettings/findByAccountId",
};

export const ACCOUNT_UI_SETTINGS = {
  saveAll: "accountUISettings/saveAll",
  findByAccountId: "accountUISettings/findByAccountId",
};

export const INSPECTION = {
  findByAccount: "inspection/findByAccount",
  delete: "inspection/delete",
  save: "inspection/save",
};
export const SOLUTION = {
  findByAccount: "solution/findByAccount",
  delete: "solution/delete",
  save: "solution/save",
  findIssueSolutions: "solution/findIssueSolutions",
  lastSolutions: "solution/lastSolutions",
  createSample: "solution/createSample",
  search: "solution/search",
  export: "solution/export",
  createSolutionControlItems: "solution/createSolutionControlItems",
  findById: "solution/findById",
  downloadFiles: "solution/downloadFiles",
  downloadThumbnails: "solution/downloadThumbnails",
  uploadFile: "solution/uploadFile",
  findByPatrol: "solution/findByPatrol",
};

export const ACC_BUYING = {
  findToinCountByAccount: "accBuying/findToinCountByAccount",
  buyPackage: "accBuying/buyPackage",
};
export const ACC_USER = {
  findAccUserRole: "accUser/findAccUserRole",
  disconnect: "accUser/disconnect",
  search: "accUser/search",
  softDelete: "accUser/softDelete",
  activate: "accUser/activate",
  changeUserRole: "accUser/changeUserRole",
  findAccUserSecurity: "accUser/findAccUserSecurity",
};
export const INVITATION = {
  invitation: "invitation/invitation",
  save: "invitation/save",
  findByAccount: "invitation/findByAccount",
  findByUser: "invitation/findByUser",
  inviteAll: "invitation/inviteAll",
  findByInvitationCode: "invitation/findByInvitationCode",
  acception: "invitation/acception",
  rejection: "invitation/rejection",
  delete: "invitation/delete",
  search: "invitation/search",
  checkAddNewUser: "invitation/checkAddNewUser",
};

export const THING = {
  getTids: "thing/get_tid",
  findByAccount: "thing/findByAccount",
  findById: "thing/findById",
  checkAndSave: "thing/check_and_save",
  update: "thing/update",
  delete: "thing/delete",
  findHashtag: "thing/findHashtag",
  findDescription: "thing/findDescription",
  downloadFiles: "thing/downloadFiles",
  downloadThumbnails: "thing/downloadThumbnails",
  uploadFile: "thing/uploadFile",
  generateThingDesc: "thing/generateThingDesc",
  printQR: "thing/printQR",
  printQRThumbnail: "thing/printQRThumbnail",
  changeGpsLocation: "thing/changeGpsLocation",
  changeIndoorLocation: "thing/changeIndoorLocation",
  createSample: "thing/createSample",
  search: "thing/search",
  export: "thing/export",
  setDefaultImage: "thing/setDefaultImage",

  uploadDocument: "thing/uploadDocument",
  findDocuments: "thing/findDocuments",
  downloadDocument: "thing/downloadDocument",
  downloadImage: "thing/downloadImage",
  deleteDocument: "thing/deleteDocument",
  // moveUpDocument: "thing/moveUpDocument",
  // moveDownDocument: "thing/moveDownDocument",
  makeDocumentPublic: "thing/makeDocumentPublic",
  makeDocumentPrivate: "thing/makeDocumentPrivate",
  makeDocumentAttendant: "thing/makeDocumentAttendant",

  deleteImage: "thing/deleteImage",
  changeOrganization: "thing/changeOrganization",
  changeOwner: "thing/changeOwner",
  getImage: "thing/getImage",
  makeImagePublic: "thing/makeImagePublic",
  makeImagePrivate: "thing/makeImagePrivate",

  findMissingDataList: "thing/findMissingDataList",
  searchMissingData: "thing/searchMissingData",
  check_tid: "thing/check_tid",
  findThingHistory: "thing/findThingHistory",
  checkThingDesc: "thing/checkThingDesc",
  getSystemThing: "thing/getSystemThing",
  archive: "thing/archive",
  unarchive: "thing/unarchive",
  convertToProduct: "thing/convertToProduct",
  convertToInteractionPoint: "thing/convertToInteractionPoint",
  updatePublicReportPageTemplate: "thing/updatePublicReportPageTemplate",
  getHashtagList: "thing/getHashtagList",
  checkAddNewThing: "thing/checkAddNewThing",
  thumbnail: "thing/thumbnail",
};

export const THINGCLASS = {
  findAll: "thingClass/findAll",
  save: "thingClass/save",
  findGenericClassName: "thingClass/findGenericClassName",
  findById: "thingClass/findById",
  findClassName: "thingClass/findClassName",
  delete: "thingClass/deleteThingClass",
  findByAccount: "thingClass/findByAccount",
  uploadFile: "thingClass/uploadFile",
  search: "thingClass/search",
  export: "thingClass/export",
  deleteFile: "thingClass/deleteFile",
  findHashtag: "thingClass/findHashtag",
  findChildren: "thingClass/findChildren",
  uploadDocument: "thingClass/uploadDocument",
  downloadDocument: "thingClass/downloadDocument",
  updatePublicReportPageTemplate: "thingClass/updatePublicReportPageTemplate",
  getHashtagList: "thingClass/getHashtagList",
  checkAddNewThingClass: "thingClass/checkAddNewThingClass",
  copyThingClass: "thingClass/copyThingClass",
  uploadThingClassDocument: "thingClass/uploadThingClassDocument",
  findDocuments: "thingClass/findDocuments",
  downloadThingClassDocument: "thingClass/downloadThingClassDocument",
  deleteDocument: "thingClass/deleteDocument",
  makeDocumentAttendant: "thingClass/makeDocumentAttendant",
  makeDocumentPublic: "thingClass/makeDocumentPublic",
  makeDocumentPrivate: "thingClass/makeDocumentPrivate",
};

export const SERVICE_OPERATION_CLASS = {
  findClassName: "serviceOperationClass/findClassName",
  save: "serviceOperationClass/save",
  findById: "serviceOperationClass/findById",
  delete: "serviceOperationClass/delete",
  search: "serviceOperationClass/search",

  saveStock: "serviceOperationClassStock/save",
  searchStock: "serviceOperationClassStock/search",
  deleteStock: "serviceOperationClassStock/delete",

  saveProcedure: "serviceOperationClassProcedure/save",
  searchProcedure: "serviceOperationClassProcedure/search",
  deleteProcedure: "serviceOperationClassProcedure/delete",

  uploadServiceOperationDocument: "serviceOperationClass/uploadFile",
  downloadServiceOperationThumbnails:
    "serviceOperationClass/downloadServiceOperationThumbnails",
  deleteServiceOperationDocument: "serviceOperationClass/deleteDocument",
  downloadServiceOperationDocument: "serviceOperationClass/downloadFile",
  getHashtagList: "serviceOperationClass/getHashtagList",
};

export const ORGANIZATION = {
  findOrganization: "organization/findOrganization",
  save: "organization/save",
  delete: "organization/delete",
};

export const ORGANIZATION_USER = {
  findByOrganization: "organizationUser/findByOrganization",
  findUsersByOrganization: "organizationUser/findUsersByOrganization",
  findManagersByOrganization: "organizationUser/findManagersByOrganization",
  save: "organizationUser/save",
  delete: "organizationUser/delete",
};

export const ISSUE = {
  findByAccount: "issue/findByAccount",
  findByThing: "issue/findByThing",
  findById: "issue/findById",
  update: "issue/update",
  save: "issue/save",
  findByAccountAndIssueDescription: "issue/findByAccountAndIssueDescription",
  delete: "issue/delete",
  downloadFiles: "issue/downloadFiles",
  downloadThumbnails: "issue/downloadThumbnails",
  closeIssue: "issue/closeIssue",
  lastIssues: "issue/lastIssues",
  findByActiveIssues: "issue/findByActiveIssues",
  createSample: "issue/createSample",
  search: "issue/search",
  export: "issue/export",
  uploadFile: "issue/uploadFile",
  issueSlaSearch: "issue/issueSlaSearch",
  saveSlaLevel: "issue/saveSlaLevel",
  saveMultiSlaLevel: "issue/saveMultiSlaLevel",
  findThingIssues: "issue/findThingIssues",
  assignThing: "issue/assignThing",
  deleteFile: "issue/deleteFile",
  approveConfirmation: "issue/approveConfirmation",
  rejectConfirmation: "issue/rejectConfirmation",
  getIssueLogs: "issue/getIssueLogs",
  changeOwner: "issue/changeOwner",
  reopen: "issue/reopen",
};

export const ISSUECLASS = {
  findAll: "issueClass/findAll",
  save: "issueClass/save",
  findGenericClassName: "issueClass/findGenericClassName",
  findClassName: "issueClass/findClassName",
  delete: "issueClass/delete",
  findByAccount: "issueClass/findByAccount",
  findByThingClass: "issueClass/findByThingClass",
  enable: "issueClass/enable",
  disable: "issueClass/disable",
  search: "issueClass/search",
  findById: "issueClass/findById",
};

export const LIKECLASS = {
  findAll: "likeClass/findAll",
  save: "likeClass/save",
  findGenericClassName: "likeClass/findGenericClassName",
  findClassName: "likeClass/findClassName",
  delete: "likeClass/delete",
  findByAccount: "likeClass/findByAccount",
  findByThingClass: "likeClass/findByThingClass",
  enable: "likeClass/enable",
  disable: "likeClass/disable",
  search: "likeClass/search",
  findById: "likeClass/findById",
};

export const SUGGESTIONCLASS = {
  findAll: "suggestionClass/findAll",
  save: "suggestionClass/save",
  findGenericClassName: "suggestionClass/findGenericClassName",
  findClassName: "suggestionClass/findClassName",
  delete: "suggestionClass/delete",
  findByAccount: "suggestionClass/findByAccount",
  findByThingClass: "suggestionClass/findByThingClass",
  enable: "suggestionClass/enable",
  disable: "suggestionClass/disable",
  search: "suggestionClass/search",
  findById: "suggestionClass/findById",
};

export const NOTIFICATION = {
  findByThing: "Notification/findByThing",
};

export const SALES_PACKAGE = {
  findAll: "salesPackage/findAll",
  findById: "salesPackage/findById",
  save: "salesPackage/save",
  findDefaultSalesPackage: "salesPackage/findDefaultSalesPackage",
  findTrialSalesPackage: "salesPackage/findTrialSalesPackage",
};

export const FILES = {
  uploadFile: "file/uploadFile",
  uploadIssueFile: "file/uploadIssueFile",
  downloadFiles: "file/downloadFiles",
  downloadIssueFiles: "file/downloadIssueFiles",
};

export const VENDOR = {
  findAll: "vendor/findAll",
  save: "vendor/save",
  delete: "vendor/delete",
  findByName: "vendor/findByName",
  search: "vendor/search",
};

export const THING_CHANGE = {
  search: "thingChange/search",
  findById: "thingChange/findById",
};

export const USER_GROUP = {
  save: "userGroup/save",
  delete: "userGroup/delete",
  findByName: "userGroup/findByName",
  search: "userGroup/search",
};

export const STATISTIC = {
  chartThingIssueClassCount: "statistic/chartThingIssueClassCount",
  chartThingClassIssueClassCount: "statistic/chartThingClassIssueClassCount",
  chartIssueDateCount: "statistic/chartIssueDateCount",
  chartThingIssueTimeMMA: "statistic/chartThingIssueTimeMMA",
  chartThingClassIssueTimeMMA: "statistic/chartThingClassIssueTimeMMA",
  activeThingCountByThingClass: "statistic/activeThingCountByThingClass",
  activeThingCountByOrganization: "statistic/activeThingCountByOrganization",
  activeThingCountByLocation: "statistic/activeThingCountByLocation",
  activeThingCountByVendor: "statistic/activeThingCountByVendor",
  thingCountByMonth: "statistic/thingCountByMonth",
  issueClassCountByMonth: "statistic/issueClassCountByMonth",
  storageStat: "statistic/storageStat",
  issueCountByDate: "statistic/issueCountByDate",
  issueClassCountByDate: "statistic/issueClassCountByDate",
  issueCountByThingClass: "statistic/issueCountByThingClass",
  issueCountByVendor: "statistic/issueCountByVendor",
  issueCountByOrganization: "statistic/issueCountByOrganization",
  issueCountByLocation: "statistic/issueCountByLocation",
  issueCountByUser: "statistic/issueCountByUser",
  solutionCountByDate: "statistic/solutionCountByDate",
  solutionCountByThingClass: "statistic/solutionCountByThingClass",
  solutionStocks: "statistic/solutionStocks",
  solutionLaborTimeByThingClass: "statistic/solutionLaborTimeByThingClass",
  solutionCountByVendor: "statistic/solutionCountByVendor",
  solutionCountByOrganization: "statistic/solutionCountByOrganization",
  solutionCountByLocation: "statistic/solutionCountByLocation",
  solutionCountByUser: "statistic/solutionCountByUser",
  solutionCountBySolutionUser: "statistic/solutionCountBySolutionUser",
  solutionLaborTimeBySolutionUser: "statistic/solutionLaborTimeBySolutionUser",
  openIssueOpenTimeByThingClass: "statistic/openIssueOpenTimeByThingClass",
  closedIssueOpenTimeByThingClass: "statistic/closedIssueOpenTimeByThingClass",
  thingScanCountByDate: "statistic/thingScanCountByDate",
  interactionCountByDate: "statistic/interactionCountByDate",
  interactionCountByDate2: "statistic/interactionCountByDate2",
  limit: 9,
};

export const SLA_CALENDAR = {
  findByAccount: "slaCalendar/findByAccount",
  save: "slaCalendar/save",
  update: "slaCalendar/update",
  delete: "slaCalendar/delete",
  findById: "slaCalendar/findById",
};

export const SLA_CALENDAR_WEEK = {
  save: "slaCalendarWeek/save",
  findByCalendar: "slaCalendarWeek/findByCalendar",
  update: "slaCalendarWeek/save",
  delete: "slaCalendarWeek/delete",
};

export const SLA_CALENDAR_HOLIDAY = {
  save: "slaCalendarHoliday/save",
  update: "slaCalendarHoliday/update",
  delete: "slaCalendarHoliday/delete",
  findByCalendar: "slaCalendarHoliday/findByCalendar",
};

export const DYNAMIC_FORM = {
  checkAndSave: "dynamicForm/checkAndSave",
  find: "dynamicForm/find",
  update: "dynamicForm/update",
  save: "dynamicForm/save",
  findById: "dynamicForm/findById",
  delete: "dynamicForm/delete",
  enable: "dynamicForm/enable",
  disable: "dynamicForm/disable",
  moveUp: "dynamicForm/moveUp",
  moveDown: "dynamicForm/moveDown",
  findFormByName: "dynamicForm/findFormByName",
};

export const THING_DYNAMIC_FORM = {
  saveForm: "thingDynamicForm/saveForm",
  findForm: "thingDynamicForm/findForm",
  deleteForm: "thingDynamicForm/deleteForm",
  findByObjectId: "thingDynamicForm/findByObjectId",
  findByAccount: "thingDynamicForm/findByAccount",
  uploadDocument: "thingDynamicForm/uploadDocument",
  findFormAndData: "thingDynamicForm/findFormAndData",
};

export const ISSUE_DYNAMIC_FORM = {
  saveForm: "issueDynamicForm/saveForm",
  findForm: "issueDynamicForm/findForm",
  deleteForm: "issueDynamicForm/deleteForm",
  findByFormAndObjectId: "issueDynamicForm/findByFormAndObjectId",
  findByAccount: "issueDynamicForm/findByAccount",
};

export const DYNAMIC_FORM_DATA = {
  save: "dynamicFormData/save",
};

export const TASK = {
  findByAccountAndDateRange: "task/findByAccountAndDateRange",
  findByAccount: "task/findByAccount",
  findTaskByAccount: "task/findTaskByAccount",
  save: "task/save",
  update: "task/update",
  delete: "task/delete",
  findById: "task/findById",
  search: "task/search",
  todoSearch: "task/todoSearch",
  cancel: "task/cancel",
  reassign: "task/reassign",
  sendBack: "task/sendBack",
  complete: "task/complete",
  pause: "task/pause",
  pauseFinish: "task/pauseFinish",
  saveSlaLevel: "task/saveSlaLevel",
};

export const RECURRENCE = {
  save: "mtncRecurrence/save",
  delete: "mtncRecurrence/delete",
  update: "mtncRecurrence/update",
  search: "mtncRecurrence/search",
  findByThing: "mtncRecurrence/findByThing",
  findById: "mtncRecurrence/findById",
  activation: "mtncRecurrence/activation",
  passivation: "mtncRecurrence/passivation",
  findNextOccuranceList: "mtncRecurrence/findNextOccuranceList",
  findPreviousOccuranceList: "mtncRecurrence/findPreviousOccuranceList",
};

export const THING_DICTIONARY = {
  findClassName: "thingDictionary/findClassName",
};

export const PUBLIC = {
  findByTid: "public/findByTid",
  reportIssue: "public/reportIssue",
  reportLike: "public/reportLike",
  reportSuggestion: "public/reportSuggestion",
  reportUserContent: "public/reportUserContent",
  uploadFiles: "public/uploadFiles",
  uploadSuggestionFiles: "public/uploadSuggestionFiles",
  uploadUserContentFiles: "public/uploadUserContentFiles",
  getPublicInfo: "public/getPublicInfo",
  linkClick: "public/linkClick",
  downloadDocument: "public/downloadDocument",
  downloadDocumentRaw: "public/downloadDocumentRaw",
  downloadThumbnails: "public/downloadThumbnails",
  downloadImages: "public/downloadImages",
  getDefaultImage: "public/getDefaultImage",
  getImageCount: "public/getImageCount",
  downloadImage: "public/downloadImage",
  checkVisitor: "public/checkVisitor",
  checkCustomer: "public/checkCustomer",
  customer: "public/customer",
  existingCustomerRegisterThing: "public/existingCustomerRegisterThing",
  saveAccessLog: "public/saveAccessLog",
  beneficiaryNote: "public/beneficiaryNote",
  beneficiaryNoteReply: "public/beneficiaryNoteReply",
  uploadBeneficiaryNote: "public/uploadBeneficiaryNote",
  getSurvey: "public/getSurvey",
  getSurveyWithoutThing: "public/getSurveyWithoutThing",
  answerSurvey: "public/answerSurvey",
  getCampaign: "public/getCampaign",
  registerCampaign: "public/registerCampaign",
  uploadCampaignFiles: "public/uploadCampaignFiles",
  uploadCampaignShoppingFiles: "public/uploadCampaignShoppingFiles",
  getIssue: "public/getIssue",
  getLegal: "public/getLegal",
  accountAvatar: "public/accountAvatar",
  uploadTempFile: "public/uploadTempFile",
  uploadTempImage: "public/uploadTempImage",
  findFormByName: "public/findFormByName",
  saveCustomer: "public/saveCustomer",
  findActiveList: "public/findActiveList",
  isSurveyAnswerBlock: "public/isSurveyAnswerBlock",
  findCountry: "public/findCountry",
};

export const QRTEMPLATE = {
  save: "qrTemplate/save",
  delete: "qrTemplate/delete",
  findQRTemplate: "qrTemplate/findQRTemplate",
  findById: "qrTemplate/findById",
  uploadFile: "qrTemplate/uploadFile",
  uploadImageElementFile: "qrTemplate/uploadImageElementFile",
};

export const THING_LINK = {
  save: "thingLink/save",
  delete: "thingLink/delete",
  findThingLinks: "thingLink/findThingLinks",
  findThingClassLinks: "thingLink/findThingClassLinks",
  makePublic: "thingLink/makePublic",
  makePrivate: "thingLink/makePrivate",
  moveUp: "thingLink/moveUp",
  moveDown: "thingLink/moveDown",
};

export const THING_EVENT = {
  save: "event/save",
  delete: "event/delete",
  findEvents: "event/findEvents",
};

export const CONTROL_ITEM = {
  findAll: "controlItem/findAll",
  save: "controlItem/save",
  delete: "controlItem/delete",
  findByThingClass: "controlItem/findByThingClass",
  findByPatrolDef: "controlItem/findByPatrolDef",
  enable: "controlItem/enable",
  disable: "controlItem/disable",
  moveUp: "controlItem/moveUp",
  moveDown: "controlItem/moveDown",
};

export const STOCK = {
  save: "stock/save",
  delete: "stock/delete",
  search: "stock/search",
  export: "stock/export",
  findStock: "stock/findStock",
};

export const STOCK_CATEGORY = {
  findByName: "stockCategory/findByName",
  save: "stockCategory/save",
};

export const STOCK_TAKING = {
  save: "stockTaking/save",
  delete: "stockTaking/delete",
  search: "stockTaking/search",
  scanDetail: "stockTaking/scanDetail",
  start: "stockTaking/start",
  complete: "stockTaking/complete",
  searchDetail: "stockTaking/searchDetail",
  stat: "stockTaking/stat",
  findById: "stockTaking/findById",
};

export const STOCK_TAKING_USER = {
  save: "stockTakingUser/save",
  delete: "stockTakingUser/delete",
  findByStockTaking: "stockTakingUser/findByStockTaking",
};

export const STOCK_UNIT = {
  findByName: "stockUnit/findByName",
  findByQuantity: "stockUnit/findByQuantity",
  save: "stockUnit/save",
};

export const STOCK_QUANTITY = {
  findByName: "stockQuantity/findByName",
};

export const LOCATION = {
  save: "location/save",
  delete: "location/delete",
  uploadFile: "location/uploadFile",
  search: "location/search",
  deleteFile: "location/deleteFile",
  findById: "location/findById",
  findByName: "location/findByName",
};

export const LOCATION_DETAIL = {
  save: "locationDetail/save",
  update: "locationDetail/update",
  delete: "locationDetail/delete",
  uploadFile: "locationDetail/uploadFile",
  deleteFile: "locationDetail/deleteFile",
  findById: "locationDetail/findById",
  findByLocation: "locationDetail/findByLocation",
  downloadDocument: "locationDetail/downloadDocument",
  uploadDocument: "locationDetail/uploadDocument",
  createCopy: "locationDetail/createCopy",
};

export const SLA_CONTRACT = {
  save: "slaContract/save",
  delete: "slaContract/delete",
  search: "slaContract/search",
  findById: "slaContract/findById",
  monitor: "slaContract/monitor",
  findContract: "slaContract/findContract",
  findContractsByThing: "slaContract/findContractsByThing",
  findContractsByThingTask: "slaContract/findContractsByThingTask",
};

export const SLA_CONTRACT_LEVEL = {
  save: "slaContractLevel/save",
  delete: "slaContractLevel/delete",
  findBySlaContract: "slaContractLevel/findBySlaContract",
  findByThing: "slaContractLevel/findByThing",
  findByAccount: "slaContractLevel/findByAccount",
};

export const SLA_CONTRACT_THING = {
  saveAll: "slaContractThing/saveAll",
  delete: "slaContractThing/delete",
  search: "slaContractThing/search",
  deleteTargets: "slaContractThing/deleteAll",
};

export const SUBSCRIPTION = {
  subscribe: "subscription/subscribe",
  findSubscriptionList: "subscription/findSubscriptionList",
  findInvoiceList: "subscription/findInvoiceList",
  findActiveSubscription: "subscription/findActiveSubscription",
  createCheckoutForm: "subscription/createCheckoutForm",
  startTrial: "subscription/startTrial",
  checkAvailability: "subscription/checkAvailability",
};

export const LABEL_PAPER_TEMPLATE = {
  findLabelPaperTemplateList: "labelPaperTemplate/findLabelPaperTemplateList",
};

export const LEGAL_TEXT = {
  checkLegalText: "legalText/checkLegalText",
  getLegalTextByType: "legalText/getLegalTextByType",
  search: "legal/search",
  save: "legal/save",
  findById: "legal/findById",
  delete: "legal/delete",
};

export const LEGAL = {
  getLegal: "legal/getLegal",
};

export const PATROL_DEF = {
  save: "patrolDef/save",
  delete: "patrolDef/delete",
  search: "patrolDef/search",
  findPatrolDef: "patrolDef/findPatrolDef",
  findById: "patrolDef/findById",
  activation: "patrolDef/activation",
  passivation: "patrolDef/passivation",
  findNextDate: "patrolDef/findNextDate",
  getCounts: "patrolDef/getCounts",
};

export const PATROL_DEF_THING = {
  save: "patrolDefThing/save",
  saveAll: "patrolDefThing/saveAll",
  delete: "patrolDefThing/delete",
  search: "patrolDefThing/search",
  moveUp: "patrolDefThing/moveUp",
  moveDown: "patrolDefThing/moveDown",
};

export const PATROL_DEF_USER = {
  save: "patrolDefUser/save",
  delete: "patrolDefUser/delete",
  findByPatrolDef: "patrolDefUser/findByPatrolDef",
};

export const PATROL = {
  search: "patrol/search",
  findDetailsByPatrol: "patrol/findDetailsByPatrol",
};

export const THING_RELATION = {
  findAll: "thingRelation/findAll",
  save: "thingRelation/save",
  check: "thingRelation/check",
  saveAll: "thingRelation/saveAll",
  delete: "thingRelation/delete",
  findThingRelations: "thingRelation/findThingRelations",
};

export const RELATION_TYPE = {
  findAll: "relationType/findAll",
  save: "relationType/save",
  delete: "relationType/delete",
  findByName: "relationType/findByName",
  search: "relationType/search",
  findInteractionPoint: "relationType/findInteractionPoint",
};

export const LIKE = {
  search: "like/search",
  export: "like/export",
  findById: "like/findById",
};

export const SUGGESTION = {
  save: "suggestion/save",
  search: "suggestion/search",
  export: "suggestion/export",
  findById: "suggestion/findById",
  downloadThumbnails: "suggestion/downloadThumbnails",
  downloadFiles: "suggestion/downloadFiles",
  downloadImage: "suggestion/downloadImage",
};

export const EMAIL_SMS = {
  search: "emailSms/search",
};

export const MESSAGE = {
  findById: "message/findById",
  markRead: "message/markRead",
  markAllRead: "message/markAllRead",
  markUnread: "message/markUnread",
};

export const INBOX = {
  search: "inbox/search",
};

export const ACCESS_LOG = {
  save: "accessLog/save",
  findById: "accessLog/findById",
};

export const CUSTOMER = {
  search: "customer/search",
  findById: "customer/findById",
  findCustomer: "customer/findCustomer",
  findCustomerInteraction: "customer/findCustomerInteraction",
  findHashtag: "customer/findHashtag",
  save: "customer/save",
  activate: "customer/active",
  deactivate: "customer/passive",
  findHistory: "customer/findHistory",
  findDevices: "customer/findDevices",
  findThings: "customer/findThings",
  allow: "customer/allow",
  allowWithDocument: "customer/allowWithDocument",
  remove: "customer/remove",
  anonym: "customer/anonym",
  findLists: "customer/findLists",
  removeFromList: "customer/removeFromList",
};

export const CUSTOMER_MESSAGE = {
  save: "customerMessageTemplate/save",
  findById: "customerMessageTemplate/findById",
  search: "customerMessageTemplate/search",
  searchDetail: "customerMessageTemplate/searchDetail",
  changeStatusDraft: "customerMessageTemplate/changeStatusDraft",
  changeStatusActive: "customerMessageTemplate/changeStatusActive",
  startNow: "customerMessageTemplate/startNow",
  findDocuments: "customerMessageTemplate/findDocuments",
  uploadDocument: "customerMessageTemplate/uploadDocument",
  downloadDocument: "customerMessageTemplate/downloadDocument",
  deleteDocument: "customerMessageTemplate/deleteDocument",
  findTargets: "customerMessageTemplateTarget/findTargets",
  saveTargets: "customerMessageTemplateTarget/save",
  saveAllTargets: "customerMessageTemplateTarget/saveAll",
  deleteTargets: "customerMessageTemplateTarget/deleteByIdAll",
};
export const CAMPAIGN = {
  save: "campaign/save",
  findById: "campaign/findById",
  search: "campaign/search",
  changeStatus: "campaign/changeStatus",
  searchDetail: "campaign/searchDetail",
  deleteCampaign: "campaign/delete",
  getCampaign: "campaign/getCampaign",
  getCampaignNumbers: "campaign/getCampaignNumbers",
  findCampaign: "campaign/findCampaign",
  setDefaultImage: "campaign/setDefaultImage",

  findCalendars: "CampaignCalendar/findCalendars",
  saveCalendar: "CampaignCalendar/save",
  deleteCalendar: "CampaignCalendar/delete",

  findUsers: "campaignUser/findUsers",
  saveUser: "campaignUser/save",
  deleteUser: "campaignUser/delete",

  findTargets: "campaignTarget/findTargets",
  saveTargets: "campaignTarget/saveAll",
  deleteTargets: "campaignTarget/deleteByIdAll",

  findQuestions: "campaignQuestion/findQuestions",
  saveQuestion: "campaignQuestion/save",
  deleteQuestion: "campaignQuestion/delete",
  questionMoveUp: "campaignQuestion/moveUp",
  questionMoveDown: "campaignQuestion/moveDown",

  findPages: "campaignPage/findPages",
  savePage: "campaignPage/save",
  deletePage: "campaignPage/delete",

  searchCustomer: "campaignCustomer/search",
  exportCustomer: "campaignCustomer/exportCustomers",
  changeCustomerStatus: "campaignCustomer/changeStatus",
  changeCustomerStatusSimple: "campaignCustomer/changeStatusSimple",
  customerFindById: "campaignCustomer/findById",
  getNext: "campaignCustomer/getNext",
  getPrevious: "campaignCustomer/getPrevious",
  downloadCustomerAnswer: "campaignCustomer/download",
  downloadCustomerAnswerShopping: "campaignCustomer/downloadShopping",
  getCustomerCheckList: "campaignCustomer/getCheckList",
  fillCheckList: "campaignCustomer/fillCheckList",
  evaluate: "campaignCustomer/evaluate",
  getCustomerLogList: "campaignCustomer/getLogList",
  exportCustomers: "campaignCustomer/exportCustomers",
  getCustomerImage: "campaignCustomer/getImage",
  getCustomerImageHd: "campaignCustomer/getImageHd",
  downloadCustomerImage: "campaignCustomer/downloadImage",
  changeJoinStatus: "campaignCustomer/changeJoinStatus",

  findAwards: "campaignAward/findAwards",
  saveAward: "campaignAward/save",
  deleteAward: "campaignAward/delete",

  findCheckLists: "campaignCheckList/findCheckLists",
  deleteCheckList: "campaignCheckList/delete",
  saveCheckList: "campaignCheckList/save",
};
export const POLICY = {
  save: "policy/save",
  findById: "policy/findById",
  search: "policy/search",
  active: "policy/active",
  passive: "policy/passive",
  delete: "policy/delete",
  /*searchDetail: "customerMessageTemplate/searchDetail",
  changeStatusDraft: "customerMessageTemplate/changeStatusDraft",
  changeStatusActive: "customerMessageTemplate/changeStatusActive",
  startNow: "customerMessageTemplate/startNow",
  findDocuments: "customerMessageTemplate/findDocuments",
  uploadDocument: "customerMessageTemplate/uploadDocument",
  downloadDocument: "customerMessageTemplate/downloadDocument",
  deleteDocument: "customerMessageTemplate/deleteDocument",
  findTargets: "customerMessageTemplateTarget/findTargets",
  saveTargets: "customerMessageTemplateTarget/save",
  saveAllTargets: "customerMessageTemplateTarget/saveAll",
  deleteTargets: "customerMessageTemplateTarget/deleteByIdAll",*/
};

export const POLICY_TARGET = {
  findTargets: "policyTarget/findTargets",
  saveTargets: "policyTarget/save",
  saveAllTargets: "policyTarget/saveAll",
  deleteTargets: "policyTarget/deleteByIdAll",
};

export const POLICY_LOG = {
  findLogs: "policyLog/findLogs",
};

export const QUEUE = {
  save: "queue/save",
  search: "queue/search",
  findById: "queue/findById",
  active: "queue/active",
  passive: "queue/passive",
  delete: "queue/delete",
  moveUp: "queue/moveUp",
  moveDown: "queue/moveDown",
  changeOrder: "queue/changeOrder",
  findQueue: "queue/findQueue",
};

export const QUEUE_TARGET = {
  saveAllTargets: "queueTarget/saveAll",
  findTargets: "queueTarget/findTargets",
  deleteTargets: "queueTarget/deleteByIdAll",
};

export const QUEUE_USER = {
  findUsers: "queueUser/findUsers",
  findNextUser: "queueUser/findNextUser",
  save: "queueUser/save",
  delete: "queueUser/delete",
};

export const QUEUE_LOG = {
  findLogs: "queueLog/findLogs",
};

export const SCHEDULE_PLAN = {
  search: "schedulePlan/search",
  save: "schedulePlan/save",
  delete: "schedulePlan/delete",
  findSchedulePlan: "schedulePlan/findSchedulePlan",
};

export const DOCUMENT = {
  uploadCustomerDocument: "document/uploadCustomerDocument",
  deleteCustomerDocument: "document/deleteCustomerDocument",
  downloadCustomerDocument: "document/downloadCustomerDocument",
  downloadBeneficiaryNoteDocument: "document/downloadBeneficiaryNoteDocument",
  findCustomerDocuments: "document/findCustomerDocuments",

  uploadServiceOperationDocument: "document/uploadServiceOperationDocument",
  deleteServiceOperationDocument: "document/deleteServiceOperationDocument",
  downloadServiceOperationDocument: "document/downloadServiceOperationDocument",
  findServiceOperationDocuments: "document/findServiceOperationDocuments",
  downloadServiceOperationThumbnails:
    "document/downloadServiceOperationThumbnails",

  uploadServiceOperationProcedureDocument:
    "document/uploadServiceOperationProcedureDocument",
  deleteServiceOperationProcedureDocument:
    "document/deleteServiceOperationProcedureDocument",
  downloadServiceOperationProcedureDocument:
    "document/downloadServiceOperationProcedureDocument",
  findServiceOperationProcedureDocuments:
    "document/findServiceOperationProcedureDocuments",

  findDocuments: "document/findCampaignDocuments",
  uploadDocument: "document/uploadCampaignDocument",
  downloadDocument: "document/downloadCampaignDocument",
  deleteDocument: "document/deleteCampaignDocument",

  getCampaignImage: "document/getCampaignImage",
  uploadCampaignImage: "document/uploadCampaignImage",
  deleteCampaignImage: "document/deleteCampaignImage",

  deleteDynamicFormDocument: "document/deleteDynamicFormDocument",
};

export const PERIODIC_REPORT = {
  save: "periodicReport/save",
  findById: "periodicReport/findById",
  delete: "periodicReport/delete",
  search: "periodicReport/search",
  active: "periodicReport/active",
  passive: "periodicReport/passive",
  generate: "periodicReport/generate",
  findDetails: "periodicReportDetail/findDetails",
  findLogs: "periodicReportLog/findLogs",
  saveDetail: "periodicReportDetail/save",
  deleteDetail: "periodicReportDetail/delete",
};

export const RECURRENCE_USER = {
  save: "recurrenceUser/save",
  delete: "recurrenceUser/delete",
  findByRecurrence: "recurrenceUser/findByRecurrence",
};

export const PERIODIC_REPORT_USER = {
  save: "periodicReportUser/save",
  delete: "periodicReportUser/delete",
  findByPeriodicReport: "periodicReportUser/findByPeriodicReport",
};

export const UNSUBSCRIBE = {
  remove: "unsubscribe/remove",
};

export const NOTE = {
  save: "note/save",
  delete: "note/delete",
  search: "note/search",
};
export const BENEFICIARY_NOTE = {
  save: "beneficiaryBeneficiaryNote/save",
  findCustomerBeneficiaryNotes:
    "beneficiaryBeneficiaryNote/findCustomerBeneficiaryNotes",
  findIssueBeneficiaryNotes:
    "beneficiaryBeneficiaryNote/findIssueBeneficiaryNotes",
  findCampaignCustomerBeneficiaryNotes:
    "beneficiaryBeneficiaryNote/findCampaignCustomerBeneficiaryNotes",
  findSuggestionBeneficiaryNotes:
    "beneficiaryBeneficiaryNote/findSuggestionBeneficiaryNotes",
};

export const SURVEY = {
  save: "survey/save",
  findById: "survey/findById",
  active: "survey/active",
  passive: "survey/passive",
  search: "survey/search",
  delete: "survey/delete",
  analysis: "survey/analysis",
  surveyAllAnswers: "survey/surveyAllAnswers",
  getSurvey: "survey/getSurvey",
  findSurvey: "survey/findSurvey",
  copy: "survey/copy",
  getDailyCounts: "survey/getDailyCounts",

  findTargets: "surveyTarget/findTargets",
  deleteTargets: "surveyTarget/deleteByIdAll",
  saveTargets: "surveyTarget/saveAll",
  findTargetHashtag: "surveyTarget/findHastag",

  savePage: "surveyPage/save",
  findPages: "surveyPage/findPages",
  pageMoveUp: "surveyPage/moveUp",
  pageMoveDown: "surveyPage/moveDown",
  deletePage: "surveyPage/delete",

  saveQuestion: "surveyQuestion/save",
  deleteQuestion: "surveyQuestion/delete",
  questionMoveUp: "surveyQuestion/moveUp",
  questionMoveDown: "surveyQuestion/moveDown",
  findQuestions: "surveyQuestion/findQuestions",
  libraryQuestions: "surveyQuestion/libraryQuestions",
  addQuestionToLibrary: "surveyQuestion/addQuestionToLibrary",
  saveAllQuestions: "surveyQuestion/saveAll",

  answerSearch: "surveyAnswer/search",
  exportAnswers: "surveyAnswer/export",
  answerFindById: "surveyAnswer/findById",
  getNext: "surveyAnswer/getNext",
  getPrevious: "surveyAnswer/getPrevious",

  findQuestionCategories: "surveyQuestionCategory/findAll",
  saveQuestionCategory: "surveyQuestionCategory/save",
};

export const PROXY = {
  search: "proxy/search",
  save: "proxy/save",
  delete: "proxy/delete",
};

export const CUSTOMER_INTERACTION = {
  search: "customerInteraction/search",
  export: "customerInteraction/export",
  getDailyCounts: "customerInteraction/getDailyCounts",
  getInteractionCountsByOperation:
    "customerInteraction/getInteractionCountsByOperation",
  getTopCounts: "customerInteraction/getTopCounts",
  getTopThingCounts: "customerInteraction/getTopThingCounts",
  getDayTimeHeatmap: "customerInteraction/getDayTimeHeatmap",
  getThingsByOperation: "customerInteraction/getThingsByOperation",
  getInteractionCountsByThing:
    "customerInteraction/getInteractionCountsByThing",
  getFirstAndLastInteraction: "customerInteraction/getFirstAndLastInteractions",
};

export const MESSAGE_TEMPLATE = {
  save: "messageTemplate/save",
  delete: "messageTemplate/delete",
  search: "messageTemplate/search",
  findById: "messageTemplate/findById",
  findByDescription: "messageTemplate/findByDescription",
  findBaseTemplate: "messageTemplate/findBaseTemplate",
  getListWithTargets: "messageTemplate/getListWithTargets",
};

export const MESSAGE_TEMPLATE_TARGET = {
  save: "messageTemplateTarget/save",
  search: "messageTemplateTarget/search",
  delete: "messageTemplateTarget/delete",
};

export const BENEFICIARY_PORTAL = {
  getLoginType: "beneficiary/getLoginType",
  login: "beneficiary/login",
  loginLdap: "beneficiary/loginLdap",
  getCampaign: "beneficiary/getCampaign",
  checkCampaignAccess: "beneficiary/checkCampaignAccess",
  getCampaignCustomerStatus: "beneficiary/getCampaignCustomerStatus",
  openIssues: "beneficiary/openIssues",
  campaigns: "beneficiary/campaigns",
  customers: "beneficiary/customers",
  getCustomerInfo: "beneficiary/getCustomerInfo",
  getCampaignCustomer: "beneficiary/getCampaignCustomer",
  findCustomerThings: "beneficiary/findCustomerThings",
  searchCustomerThings: "beneficiary/searchCustomerThings",
  interactions: "beneficiary/interactions",
  findEmailSMS: "beneficiary/findEmailSMS",
  searchThings: "beneficiary/searchThings",
  createCustomerThing: "beneficiary/createCustomerThing",
  coupons: "beneficiary/coupons",
};

export const TEAM_ROLES = {
  save: "teamRole/save",
  delete: "teamRole/delete",
  search: "teamRole/search",
  findById: "teamRole/findById",
  findByName: "teamRole/findByName",
  findByAccount: "teamRole/findByAccount",
};

export const THING_TEAM = {
  save: "thingTeam/save",
  delete: "thingTeam/delete",
  search: "thingTeam/search",
  findThingTeams: "thingTeam/findThingTeams",
  findByName: "thingTeam/findByName",
};

export const CUSTOMER_LIST = {
  save: "customerList/save",
  delete: "customerList/delete",
  search: "customerList/search",
  findById: "customerList/findById",
  findCustomerList: "customerList/findCustomerList",
};

export const CUSTOMER_LIST_DETAIL = {
  save: "customerListDetail/save",
  delete: "customerListDetail/delete",
  search: "customerListDetail/search",
  findById: "customerListDetail/findById",
  findByCustomerListId: "customerListDetail/findByCustomerListId",
  saveAll: "customerListDetail/saveAll",
};

export const USER_SUBSCRIPTION = {
  subscribe: "userSubscription/subscribe",
  findSubscriptionList: "userSubscription/findSubscriptionList",
  findIfSubscribed: "userSubscription/findIfSubscribed",
};

export const CUSTOM_INTERACTION_PAGE = {
  createCustomInteractionPage:
    "customInteractionPage/createCustomInteractionPage",

  getCustomInteractionPageByThingId:
    "customInteractionPage/getCustomInteractionPageByThingId",
  getCustomInteractionPageByThingClassId:
    "customInteractionPage/getCustomInteractionPageByThingClassId",
  getCustomInteractionPageByAccountId:
    "customInteractionPage/getCustomInteractionPageByAccountId",
};

export const COUPON = {
  save: "coupon/save",
  delete: "coupon/delete",
  search: "coupon/search",
  changeStatus: "coupon/changeStatus",
  findById: "coupon/findById",
  active: "coupon/active",
  passive: "coupon/passive",
  stat: "coupon/countUsedCoupons",
  getDailyCounts: "coupon/getDailyCounts",
  saveTargets: "couponTarget/saveAll",
  findTargets: "couponTarget/findTargets",
  deleteTargets: "couponTarget/deleteByIdAll",
  saveProducts: "couponProducts/saveAll",
  findProducts: "couponProducts/findProducts",
  deleteProducts: "couponProducts/deleteByIdAll",
  createCoupons: "customerCoupon/createCoupons",
  searchCustomerCoupons: "customerCoupon/search",
  sendCoupons: "customerCoupon/sendCoupons",
  checkCouponCode: "customerCoupon/checkCouponCode",
  sendValidationCode: "customerCoupon/sendValidationCode",
  checkValidationCode: "customerCoupon/checkValidationCode",
};

export const ANNOUNCEMENT = {
  save: "announcement/save",
  delete: "announcement/delete",
  search: "announcement/search",
};

export const SYNC = {
  ldapUserSync: "sync/ldapUserSync",
  ldapOrganizationSync: "sync/ldapOrganizationSync",
  startLocationDetailThumbnails: "sync/startLocationDetailThumbnails",
  startThingThumbnails: "sync/startThingThumbnails",
};

export const JOURNAL_TYPE = {
  save: "journalType/save",
  delete: "journalType/delete",
  findByName: "journalType/findByName",
  search: "journalType/search",
};

export const JOURNAL = {
  search: "journal/search",
  save: "journal/save",
  delete: "journal/delete",
  findThingJournals: "journal/findThingJournals",
  findByAccountAndDateRange: "journal/findByAccountAndDateRange",
};

export const RESERVATION = {
  search: "reservation/search",
  save: "reservation/save",
  delete: "reservation/delete",
  changeStatus: "reservation/changeStatus",
  findByDateRange: "reservation/findByDateRange",
  passive: "reservation/passive",
  active: "reservation/active",
  findThingReservations: "reservation/findThingReservations",
};

export const PROJECT = {
  search: "project/search",
  save: "project/save",
  delete: "project/delete",
  findById: "project/findById",

  saveUser: "projectUser/save",
  findUsersByProject: "projectUser/findByProject",
  deleteUser: "projectUser/delete",

  saveThing: "projectThing/save",
  findThingsByProject: "projectThing/findByProject",
  deleteThing: "projectThing/delete",
};

export const USER_CONTENT = {
  search: "userContent/search",
  export: "userContent/export",
  findById: "userContent/findById",
  downloadThumbnails: "userContent/downloadThumbnails",
  downloadImage: "userContent/downloadImage",
  save: "userContent/save",
};

export const USER_TUTORIAL = {
  start: "userTutorial/start",
  complete: "userTutorial/complete",
  findTutorialList: "userTutorial/findTutorialList",
};
