import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function SolutionPhotosCarousel(props) {
  const { images, uploadImages, deleteImage, selectedItem } = props;
  // const classes = useStyles();
  // const theme = useTheme();
  // const [loading, setLoading] = useState(false);
  // const lg = useMediaQuery(theme.breakpoints.up("lg"));
  // const { t } = useTranslation("thingDetail", { i18n });
  // const fileInputRef = useRef();

  // const handleChange = (event) => {
  //   event.preventDefault();
  //   if (event?.target?.files?.length > 0) {
  //     uploadImages(event.target.files);
  //   }

  // }

  const createCarouselItemImage = (item, index) => (
    <div key={index}>
      <img src={`data:image/png;base64, ${item.content}`} />
    </div>
  );

  const baseChildren = <div>{images.map(createCarouselItemImage)}</div>;

  return (
    <Carousel showArrows={true} selectedItem={selectedItem}>
      {baseChildren.props.children}
    </Carousel>
  );
}
