import {
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
} from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import InnerHTML from "dangerously-set-html-content";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { SubscriptionService } from "services/SubscriptionService";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import ChooseDeposite from "./ChooseDeposite";
import Invoices from "./Invoices";
import { useStyles } from "./style";
import SubscriptionHistory from "./SubscriptionHistory";
import SubscriptionPlan from "./SubscriptionPlan";
import SubscriptionTrialPlan from "./SubscriptionTrialPlan";
import SuccessResult from "./SuccessResult";

export default function Subscriptions() {
  const tableRef = useRef();
  const classes = useStyles();
  const { t } = useTranslation(["subscriptions", "errorMessages"], { i18n });
  const accountId = localStorage.accountId;
  const [loading, setLoading] = useState(false);

  const [period, setPeriod] = useState("MONTHLY");
  const [newCampaign, setNewCampaign] = useState(null);
  const [deposite, setDeposite] = useState(null);
  const [paymentResult, setPaymentResult] = useState(null);

  const [subscription, setSubscription] = useState(null);
  const [activeSubscription, setActiveSubscription] = useState(null);

  const [listType, setListType] = useState("SUBSCRIPTIONS");
  const [paymentFormHtml, setPaymentFormHtml] = useState();

  const [showChooseDeposite, setShowChooseDeposite] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);

  const handleTabChange = (event, newValue) => {
    setPeriod(newValue);
  };

  const handleListChange = (event, newValue) => {
    setListType(newValue);
  };

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [salesPackages, setSalesPackages] = useState([]);

  useEffect(() => {
    setLoading(true);
    SubscriptionService.findActiveList(
      (data) => {
        setSalesPackages(data);
        setLoading(false);
      },
      (error) => {
        showAlert(error.message, "error");
        setLoading(false);
        setLoading(false);
      }
    );

    SubscriptionService.findActiveSubscription(
      accountId,
      (data) => {
        setActiveSubscription(data);
      },
      (error) => {}
    );
  }, []);

  const packageSelected = (selected) => {
    setSubscription({
      period,
      campaign: selected,
      price:
        period === "YEARLY"
          ? selected.discountPriceYearly * 12
          : selected.discountPriceMonthly,
      currency: selected.currency,
      country: null,
      paymentMethod: null,
    });
    setNewCampaign(selected);
    setShowChooseDeposite(true);
  };

  const depositeSelected = (selected) => {
    setDeposite(selected);

    const data = {
      ...selected,
      country: selected?.country?.name,
      accountId: accountId,
      salesPackageId: subscription.campaign.id,
      salesPackagePriceId: subscription.campaign.salesPackagePriceId,
      monthPeriod: subscription.period === "YEARLY" ? 12 : 1,
    };

    setLoading(true);

    SubscriptionService.createCheckoutForm(
      data,
      (data) => {
        setShowChooseDeposite(false);
        setPaymentFormHtml(data.checkoutForm);
        setLoading(false);
      },
      (error) => {
        showAlert(
          t("errorMessages:" + error?.message ?? "UNEXPECTED_ERROR"),
          "error"
        );
        setLoading(false);
      }
    );
  };

  const onPaymentSuccess = (result) => {
    setPaymentResult(result);
    setShowCheckout(false);
    window.location.reload();
  };

  const renderHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "5px",
        }}
      >
        <a
          href="#"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            textDecoration: "none",
          }}
        ></a>
        <div style={{ textAlign: "right", overflowY: "auto" }}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {/* <div
              style={{
                marginRight: "8px",
                paddingTop: "15px",
                paddingRight: "10px",
              }}
            >
              Göster:{" "}
            </div> */}
            <div>
              <Tabs
                value={period}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
                aria-label="disabled tabs example"
              >
                <Tab label="Aylık" value="MONTHLY" />
                <Tab label="Yıllık" value="YEARLY" />
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {activeSubscription &&
          activeSubscription.salesPackage.salesPackageType === "TRIAL" && (
            <SubscriptionTrialPlan
              value={activeSubscription.salesPackage}
              campaigns={salesPackages}
              activeSubscription={activeSubscription}
              onSelect={packageSelected}
              period={period}
            />
          )}

        {renderHeader()}

        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                {salesPackages &&
                  salesPackages.length > 0 &&
                  salesPackages.map((item, index) => {
                    return (
                      <TableCell key={index} style={{ verticalAlign: "top" }}>
                        <SubscriptionPlan
                          value={item}
                          campaigns={salesPackages}
                          activeSubscription={activeSubscription}
                          onSelect={packageSelected}
                          period={period}
                        />
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* <ComparePlans campaigns={campaigns} /> */}
        <div style={{ textAlign: "center", paddingTop: 10 }}>
          <img
            alt="Payment methods"
            src={process.env.PUBLIC_URL + "/assets/payment/iyzico_band.png"}
          ></img>
        </div>
        {paymentFormHtml && <InnerHTML html={paymentFormHtml} />}

        <TabContext value={listType}>
          <Tabs
            value={listType}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleListChange}
            aria-label="disabled tabs example"
          >
            <Tab label="Üyelik Geçmişi" value="SUBSCRIPTIONS" />
            <Tab label="Faturalar" value="INVOICES" />
          </Tabs>
          <TabPanel value="SUBSCRIPTIONS">
            <SubscriptionHistory />
          </TabPanel>
          <TabPanel value="INVOICES">
            <Invoices />
          </TabPanel>
        </TabContext>

        {newCampaign && (
          <ChooseDeposite
            subscription={subscription}
            onSelect={depositeSelected}
            open={showChooseDeposite}
            onClose={() => {
              setShowChooseDeposite(false);
            }}
            loading={loading}
          />
        )}
        {/* {deposite && (
          <Checkout
            subscription={subscription}
            onSelect={depositeSelected}
            onSuccess={onPaymentSuccess}
            open={showCheckout}
            onClose={() => {
              setShowCheckout(false);
            }}
          />
        )} */}
        {paymentResult && <SuccessResult paymentResult={paymentResult} />}
      </Suspense>
    </>
  );
}
