import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "react-puzzle-confirm/react-puzzle-confirm.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BeneficiaryPortalService } from "services/BeneficiaryPortalService";
import BeneficiaryLoginLdap from "./BeneficiaryLoginLdap";
import BeneficiaryLoginSystem from "./BeneficiaryLoginSystem";

export default function BeneficiaryLogin(props) {
  const { appLogo, partial, onSuccess } = props;

  const [loginType, setLoginType] = useState("");

  useEffect(() => {
    BeneficiaryPortalService.getLoginType(
      (data) => {
        setLoginType(data);
      },
      (error) => {
        setLoginType("SYSTEM");
      }
    );
  }, []);

  return (
    <>
      {loginType === "SYSTEM" && (
        <BeneficiaryLoginSystem
          appLogo={appLogo}
          partial={partial}
          onSuccess={onSuccess}
        />
      )}
      {loginType === "LDAP" && (
        <BeneficiaryLoginLdap
          appLogo={appLogo}
          partial={partial}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
}

BeneficiaryLogin.propTypes = {
  loginPath: PropTypes.string,
  appLogo: PropTypes.any,
};
