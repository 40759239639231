import { faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import { green, grey } from "@material-ui/core/colors";
import PauseIcon from "@material-ui/icons/Pause";
import IssueClassAutoComplete from "components/autocomplete/IssueClassAutoComplete";
import SlaContractAutoComplete from "components/autocomplete/SlaContractAutoComplete";
import SlaContractLevelAutoComplete from "components/autocomplete/SlaContractLevelAutoComplete";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import DateRangeSelector from "components/dateRangeSelector";
import UserDetail from "components/detail/UserDetail";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaSearch } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import {
  getGroups,
  getOrganizations,
  getRole,
  getUserInfo,
} from "react-base-fa/dist/services/sessionSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getDateTimeWFormat,
  IssueDesc,
  isTNF,
  smartTimeDiff,
} from "services/utils";
import { ROLES } from "services/utils/Roles";
import { checkRole, checkRoleByIssue } from "services/utils/SecurityUtils";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { ISSUE } from "../../services/faApi";
import { useStyles } from "./style";

export default function SlaIssuesList() {
  const tableRef = useRef();
  const classes = useStyles();
  const { t } = useTranslation("issue", { i18n });
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const accountId = localStorage.accountId;
  const userRole = useSelector(getRole);
  const isTaskManagement = checkRole([ROLES.TASK_MANAGEMENT], userRole);
  const userInfo = useSelector(getUserInfo);
  const userGroups = useSelector(getGroups);
  const userOrganizations = useSelector(getOrganizations);
  const [loading, setLoading] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  const GreenSwitch = withStyles({
    switchBase: {
      color: green[300],
      "&$checked": {
        color: grey[500],
      },
      "&$checked + $track": {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const columns = [
    {
      field: "id",
      label: t("ID"),
      align: "center",
    },
    {
      field: "notificationDate",
      label: t("BILDIRIM_TARIH"),
      align: "center",
      type: "smartDateTime",
      // className: classes.justBigScreens,
    },
    {
      field: "identifier",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      // inner: "description",
      valueFormatter: (value, row) => {
        if (row.thing.thumbnail != null)
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                alt="thing"
                src={`data:image/png;base64, ${row.thing.thumbnail}`}
                style={{
                  width: "48px",
                  height: "48px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              />{" "}
              {row.thing.identifier}
            </div>
          );
        else
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="square"
                style={{
                  width: "48px",
                  height: "48px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              >
                {" "}
                {row.thing.identifier.charAt(0)}
              </Avatar>{" "}
              {row.thing.identifier}
            </div>
          );
      },
    },
    {
      field: "id2",
      label: t("ISSUE"),
      align: "center",
      valueFormatter: (val, row) => IssueDesc(row),
    },
    {
      field: "user",
      label: t("NOTIFIER"),
      align: "center",
      valueFormatter: (value) => <UserDetail value={value} type="USER" />,
    },
    {
      field: "id",
      label: t("SORUMLU"),
      align: "center",
      valueFormatter: (value, row) => {
        if (!isTaskManagement) {
          return (
            <Tooltip title={"Üyelik paketiniz desteklememektedir."}>
              <span>
                <IconButton
                  onClick={() => {}}
                  size="small"
                  color="primary"
                  disabled={true}
                >
                  <FontAwesomeIcon icon={faUserEdit} />
                </IconButton>
              </span>
            </Tooltip>
          );
        } else {
          if (row.responsibleUser || row.responsibleUserGroup) {
            return (
              <div>
                <div>{smartTimeDiff(row.responsibleChangeDate)}</div>
                <div>
                  {row.responsibleUser ? (
                    <UserDetail value={row.responsibleUser} type="USER" />
                  ) : (
                    <UserDetail
                      value={row.responsibleUserGroup}
                      type="USER_GROUP"
                    />
                  )}
                </div>
              </div>
            );
          } else {
            let isTaskAssignable = checkRoleByIssue(
              [ROLES.THINGMANAGER, ROLES.POWER_USER, ROLES.ACCOUNTOWNER],
              userRole,
              userInfo,
              userGroups,
              userOrganizations,
              row
            );
            return (
              isTaskAssignable && (
                <IconButton
                  onClick={() => {}}
                  size="small"
                  color="primary"
                  title={t("ATAMA")}
                >
                  <FontAwesomeIcon icon={faUserEdit} />
                </IconButton>
              )
            );
          }
        }
      },
    },
    {
      field: "status",
      label: t("DURUM"),
      align: "center",

      valueFormatter: (value, row) => {
        let isTaskClosable = checkRoleByIssue(
          [
            ROLES.OPERATOR,
            ROLES.THINGMANAGER,
            ROLES.POWER_USER,
            ROLES.ACCOUNTOWNER,
          ],
          userRole,
          userInfo,
          userGroups,
          userOrganizations,
          row
        );
        if (value === "OPEN" || value === "REJECT") {
          return (
            <>
              {isTaskClosable && (
                <FormControlLabel
                  control={
                    <GreenSwitch
                      //checked={false}
                      onChange={() => {}}
                    />
                  }
                  label={t(value)}
                />
              )}
              {!isTaskClosable && (
                <FormControlLabel
                  disabled
                  control={<GreenSwitch />}
                  label={t(value)}
                />
              )}
            </>
          );
        } else if (value === "CONFIRMATION") {
          return (
            <FormControlLabel
              disabled
              control={<Switch checked />}
              label={t(value)}
            />
          );
        } else {
          return (
            <FormControlLabel
              disabled
              control={<Switch checked />}
              label={t(value)}
            />
          );
        }
      },
    },
    {
      field: "closeDate",
      label: t("CLOSE_DATE"),
      align: "center",
      valueFormatter: (value, row) => {
        let paused = row.paused;
        let pauseDate = getDateTimeWFormat(row.pauseDate);
        return (
          <>
            {paused && (
              <Tooltip title={pauseDate}>
                <PauseIcon fontSize="small" />
              </Tooltip>
            )}

            {value == null ? null : (
              <div>
                <div>
                  <i>{smartTimeDiff(row.closeDate)}</i>
                </div>
                <div>{row.closeNote}</div>
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "slaContractLevel",
      label: t("Hedef"),
      align: "center",
      valueFormatter: (value) => {
        return value != null ? value?.slaContract?.name : null;
      },
    },
    {
      field: "slaContractLevel",
      label: t("SLA Seviyesi"),
      align: "center",
      valueFormatter: (value) => {
        return value != null ? value?.name : null;
      },
    },
  ];

  const newSearchForm = () => {
    let result = {
      accountId: accountId,
      slaContractExist: true,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `issues/detail/${row.id}`,
      breadCrumbKey: `/issues/detail/:id`,
    };
    history.push(tmpTo);
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <ThingsDataTable
          searchUrl={ISSUE.search + "?accountId=" + localStorage.accountId}
          exportUrl={ISSUE.export + "?accountId=" + localStorage.accountId}
          searchForm={searchForm}
          queryParams={{
            page: 0,
            size: 10,
            isAsc: false,
            sort: "notificationDate",
            accountId: localStorage.accountId,
          }}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={5}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <ThingAutoComplete
                accountId={accountId}
                value={searchForm.thing}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "thing",
                    data,
                    "thingId",
                    data?.id
                  );
                }}
                id="thingAutoComplete"
              />

              <IssueClassAutoComplete
                accountId={accountId}
                value={searchForm.issueClass}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "issueClass",
                    data,
                    "issueClassId",
                    data?.id
                  );
                }}
              />
              <SlaContractAutoComplete
                value={searchForm.slaContract}
                accountId={accountId}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "slaContract",
                    data,
                    "slaContractId",
                    data?.id
                  );
                }}
              />
              <SlaContractLevelAutoComplete
                value={searchForm.slaContractLevel}
                accountId={accountId}
                slaContractId={searchForm?.slaContract?.id}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "slaContractLevel",
                    data,
                    "slaContractLevelId",
                    data?.id
                  );
                }}
              />
              <DateRangeSelector
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    notificationDateStart: data.startDate,
                    notificationDateEnd: data.endDate,
                  });
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
