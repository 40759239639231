import Issues from "features/issues";
import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import i18n from "../../i18n";
import { useStyles } from "./style";
import ThingsSteps from "./ThingsSteps";
import TutorialElement from "./TutorialElement";

export default function IssueClose_2_Tutorial() {
  const { code } = useParams();
  const classes = useStyles();

  const stepsRef = useRef();
  let history = useHistory();

  const { t } = useTranslation("tutorialAssignTask", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        element: "#menuCustomerInteraction", // parent menu
        intro: (
          <TutorialElement
            icon="click"
            text={"Bildirilen Sorunları izlemek için tıklayın."}
          />
        ),
        position: "right",
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "#menuIssues", // child menu
        intro: (
          <TutorialElement icon="click">
            {t(
              "Açık ve Kapalı tüm sorunları Liste halinde izlemek için tıklayın."
            )}
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          var element = document.querySelector("#menuCustomerInteraction");
          if (element.childElementCount > 1 == false)
            $("#menuCustomerInteraction")[0].firstElementChild.click();
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: null,
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/tutorial/sorunlar_list.png"
                }
                width="740"
                height="256"
              ></img>
            </div>
            <br />
            Kapatmak istediğiniz Soruna ait <b>Sorun Detay</b> sayfasına gitmek
            için listeden soruna çift tıklayın.
          </TutorialElement>
        ),
        comp: "Issues",
        tooltipClass: classes.sorunlarListClass,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: null,
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/tutorial/sorunKapat2.png"
                }
                width="407"
                height="226"
              ></img>
            </div>
            <br />
            Kapatmak istediğiniz Sorun ile ilgili detayları (Görsel,
            Servis/Operasyon Bilgileri, Görev Geçmişi vb.) inceleyin ve{" "}
            <b>Açık</b> düğmesine tıklayın.
          </TutorialElement>
        ),
        comp: "Issues",
        tooltipClass: classes.sorunKapat2Class,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: null,
        intro: (
          <TutorialElement>
            <div>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/sorunKapatDialog1.png"
                }
                width="733"
                height="386"
              ></img>
            </div>
            <br />
            <div style={{ textAlign: "center" }}>
              <b>Sorunu Kapatma Tipi’ni ve Kapatma Tarihi’ni seçin.</b>
              <br />
              İsteğe bağlı <b>Açıklama</b> girin.
              <br />
              Açık Görevlerin durumu için <b>İptal</b> veya <b>Tamamlandı</b>’yı
              seçin ve <b>Sorunu Kapat</b>’a tıklayın.
            </div>
          </TutorialElement>
        ),
        comp: "Issues",
        tooltipClass: classes.sorunKapatDialog1Class,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
    ]);
  }, []);

  return (
    <div>
      {steps &&
        steps.length > stepIndex &&
        steps[stepIndex].comp === "Issues" && <Issues />}

      <ThingsSteps
        code={code}
        steps={steps}
        ref={stepsRef}
        onStepChange={setStepIndex}
        afterComplete={() => {
          window.history.back();
          $("#menuCustomerInteraction")[0].firstElementChild.click();
        }}
      />
    </div>
  );
}
