import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    minHeight: "100%",
    backgroundColor: "white",
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/pageTemplate/Template8_Background.jpg)`,
    backgroundSize: "100% 100%",
  },

  banner: {
    height: "90px",
    width: "100%",
    borderBottom: "solid 1px #DDDDDD",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "white",
    zIndex: 20,
  },

  appLogo: {
    position: "absolute",
    marginTop: 0,
    marginLeft: 10,
    top: "50%",
    transform: "translateY(-50%)",
    maxWidth: 80,
    maxHeight: 80,
    //border: "solid 2px #656DFC",
    //borderRadius: 10,
  },

  bannerAccount: {
    position: "absolute",
    left: 150,
    top: 35,
    color: "#151B26",
    fontSize: 12,
  },

  bannerThing: {
    color: "#151B26",
    fontSize: 22,
    paddingTop: "5px",
    paddingLeft: "30px",
    display: "flex",
    justifyContent: "center",
  },

  customerButton: {
    color: "white",
    backgroundColor: "#B84A96",
    "&:hover": {
      backgroundColor: "#F1BD1A",
    },
    border: "solid 2px white",
    borderRadius: 10,
    width: "80px",
    fontSize: 8,
    float: "right",
    marginRight: "10px",
    marginTop: "27px",
  },

  infoButton: {
    color: "white",
    backgroundColor: "#949398",
    "&:hover": {
      backgroundColor: "#F1BD1A",
    },
    border: "solid 2px white",
    borderRadius: 10,
    width: "45%",
    fontSize: 11,
    float: "right",
  },

  photosDiv: {
    marginLeft: 30,
    marginRight: 30,
    marginTop: 20,
    padding: 4,
    paddingTop: 80,
  },

  photosDivNoMargin: {
    padding: 4,
    paddingTop: 80,
  },

  actionButtons1Div: {
    marginLeft: 30,
    marginRight: 30,
    marginTop: 10,
    padding: 4,
  },

  actionButtons2Div: {
    marginLeft: 30,
    marginRight: 30,
    marginTop: 0,
    padding: 4,
    display: "flex",
    justifyContent: "center",
  },

  midPanel: {
    marginTop: 20,
    marginLeft: 30,
    marginRight: 30,
    textAlign: "center",
  },

  surveyButton: {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#F1BD1A",
    },
    border: "1px solid #DDDDDD",
    color: "#151B26",
    width: "98%",
    maxWidth: 624,
    borderRadius: 10,
    marginRight: 10,
    marginBottom: 10,
    marginLeft: 5,
    fontSize: 11,
    justifyContent: "left",
  },

  registerButton: {
    backgroundColor: "#656DFC",
    "&:hover": {
      backgroundColor: "#F1BD1A",
    },
    color: "white",
    width: 150,
    float: "right",
    borderRadius: 10,
    fontSize: 11,
  },

  campaignsPanel: {
    marginTop: 20,
    marginLeft: 30,
    marginRight: 30,
  },

  campaign: {
    backgroundColor: "#E2E2E2",
    height: 70,
    width: "100%",
    display: "flex",
    marginBottom: 10,
    cursor: "pointer",
    borderRadius: 10,
  },

  campaignLogo: {
    height: "100%",
  },

  campaignTexts: {
    flex: 1,
    textAlign: "center",
    paddingTop: 10,
  },

  campaignContentTexts: {
    color: "#151B26",
    fontSize: 12,
    fontWeight: "bold",
  },

  campaignTitle: {
    color: "#151B26",
    fontSize: 13,
    fontWeight: "bold",
  },

  campaignDate: {
    color: "#151B26",
    fontSize: 11,
    paddingTop: 10,
  },

  bottomPanel: {
    marginTop: 90,
    marginLeft: 30,
    marginRight: 30,
    display: "flex",
  },

  issueButton: {
    backgroundColor: "#8E3B46",
    "&:hover": {
      backgroundColor: "#F1BD1A",
    },
    color: "white",
    borderRadius: 10,
    fontSize: 11,
    width: "100%",
    maxWidth: 300,
    float: "right",
  },

  suggestionButton: {
    backgroundColor: "#656DFC",
    "&:hover": {
      backgroundColor: "#F1BD1A",
    },
    color: "white",
    padding: 0,
    //marginTop: 10,
    width: "100%",
    border: "solid 2px white",
    borderRadius: 10,
    fontSize: 11,
    maxWidth: 624,
  },

  likeButton: {
    backgroundColor: "#5AE0AF",
    "&:hover": {
      backgroundColor: "#F1BD1A",
    },
    color: "white",
    borderRadius: 10,
    fontSize: 11,
    width: "100%",
    maxWidth: 300,
    float: "left",
  },

  bottomPanelIcon: {
    fontSize: 48,
    color: "white",
  },

  linksPanel: {
    marginTop: 20,
    marginLeft: 30,
    marginRight: 30,
  },

  linksTitle: {
    backgroundColor: "#E2E2E2",
    height: 30,
    width: "100%",
    paddingTop: "10px",
    color: "#656DFC",
    fontSize: 13,
    fontWeight: "bold",
    textAlign: "center",
  },

  linkItem: {
    backgroundColor: "#E2E2E2",
    height: 30,
    width: "100%",
    display: "flex",
    paddingTop: "10px",
    paddingLeft: "10px",
    cursor: "pointer",
    color: "#656DFC",
    fontSize: 13,
    marginTop: 20,
  },

  actionButtonIcon: {
    paddingRight: 5,
  },

  documentButton: {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#F1BD1A",
    },
    color: "#151B26",
    border: "1px solid #DDDDDD",
    width: "98%",
    maxWidth: 624,
    borderRadius: 10,
    marginRight: 10,
    marginBottom: 10,
    marginLeft: 5,
    fontSize: 11,
    justifyContent: "left",
  },

  linkButton: {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#F1BD1A",
    },
    color: "#151B26",
    border: "1px solid #DDDDDD",
    width: "98%",
    maxWidth: 624,
    borderRadius: 10,
    marginRight: 10,
    marginBottom: 10,
    marginLeft: 5,
    fontSize: 11,
    justifyContent: "left",
  },

  footer: {
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: "white",
    paddingLeft: "10px",
    paddingBottom: "5px",
    paddingTop: "3px",
    borderTop: "solid 1px #DDDDDD",
    zIndex: 20,
  },

  footerLogo: {
    height: 40,
    paddingBottom: "8px",
  },

  copyright: {
    marginTop: -37,
    paddingBottom: 10,
  },
}));
