import { FormHelperText } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function GenericSelect(props) {
  const {
    value,
    onChange,
    required,
    faLabel,
    valuesList,
    labelProperty,
    helperText,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation("components", { i18n });

  const [items, setItems] = useState(valuesList);

  useEffect(() => {
    setItems(valuesList);
  }, [valuesList]);

  return (
    <FormControl
      variant="outlined"
      className={classes.w100}
      required={required}
      size="small"
    >
      <InputLabel>{faLabel}</InputLabel>
      <Select
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        label={faLabel}
      >
        <MenuItem value={""}>...</MenuItem>
        {items &&
          items.map((item, index) => (
            <MenuItem value={item} key={index}>
              {item[labelProperty]}
            </MenuItem>
          ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
