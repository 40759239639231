import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { SERVICE_OPERATION_CLASS } from "./faApi";

export const ServiceOperationClassService = {
  save: (patrolDef, callback, errorCallback) => {
    let reqBody = {
      ...patrolDef,
    };

    let req = requests.getSimplePostRequest(
      SERVICE_OPERATION_CLASS.save,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(
      SERVICE_OPERATION_CLASS.findById,
      id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (serviceOperationClass, callback, errorCallback) => {
    let reqBody = {
      ...serviceOperationClass,
    };

    let req = requests.getSimplePostRequest(
      SERVICE_OPERATION_CLASS.delete,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  saveStock: (stock, callback, errorCallback) => {
    let reqBody = {
      ...stock,
    };

    let req = requests.getSimplePostRequest(
      SERVICE_OPERATION_CLASS.saveStock,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  deleteStock: (stock, callback, errorCallback) => {
    let reqBody = {
      ...stock,
    };

    let req = requests.getSimplePostRequest(
      SERVICE_OPERATION_CLASS.deleteStock,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  saveProcedure: (procedure, callback, errorCallback) => {
    let reqBody = {
      ...procedure,
    };

    let req = requests.getSimplePostRequest(
      SERVICE_OPERATION_CLASS.saveProcedure,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  deleteProcedure: (procedure, callback, errorCallback) => {
    let reqBody = {
      ...procedure,
    };

    let req = requests.getSimplePostRequest(
      SERVICE_OPERATION_CLASS.deleteProcedure,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  uploadServiceOperationDocument: (
    serviceOperationId,
    document,
    callback,
    errorCallback
  ) => {
    let formData = new FormData();
    formData.append("multipartFile", document.file, document.file.name);

    let req = requests.getSimplePostRequest(
      SERVICE_OPERATION_CLASS.uploadServiceOperationDocument +
        "/" +
        serviceOperationId,
      formData
    );
    req["header"] = { "Content-Type": "multipart/form-data" };

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  downloadServiceOperationThumbnails: (id, callback, errorCallback) => {
    const req = requests.getSimpleGetRequest(
      SERVICE_OPERATION_CLASS.downloadServiceOperationThumbnails,
      {
        id,
      }
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  deleteServiceOperationDocument: (
    serviceOperationId,
    documentId,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(
      SERVICE_OPERATION_CLASS.deleteServiceOperationDocument +
        "?serviceOperationId=" +
        serviceOperationId +
        "&objectId=" +
        documentId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  downloadServiceOperationDocument: (
    serviceOperationId,
    documentId,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(
      SERVICE_OPERATION_CLASS.downloadServiceOperationDocument +
        "?id=" +
        serviceOperationId +
        "&objectId=" +
        documentId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  getHashtagList: (accountId, limit, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      SERVICE_OPERATION_CLASS.getHashtagList,
      {
        accountId,
        limit,
      }
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
