import {
  Collapse,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import PropTypes from "prop-types";
import React, { createRef, useRef, useState } from "react";
import { selectThemePrefer } from "react-base-fa/dist/AppSlice";
import { FaButton, FaInput, FaUnAuthPages } from "react-base-fa/dist/fa";
import useStyles from "react-base-fa/dist/fa/faUnAuthPages/style";
import { requests } from "react-base-fa/dist/services/requestCreater";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RECAPTCHA_CODE } from "services/utils/Constants";
import i18n from "../../i18n";
import { USER_APPLICATION } from "../../services/faApi";

export default function ForgotPassword(props) {
  const { loginPath, appLogo } = props;
  const VERIFICATION_EMAIL = "EMAIL";
  const VERIFICATION_SMS = "SMS";
  const [verificationType, setVerificationType] = useState(VERIFICATION_EMAIL);
  const theme = useSelector(selectThemePrefer);
  let recaptchaRef = useRef();
  const classes = useStyles();
  const { t } = useTranslation("forgotPassword", { i18n });
  const history = useHistory();
  let formRef = createRef();

  const [values, setValues] = useState({
    email: "",
    phone: "",
    hash: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    email: "",
    phone: "",
  });
  const [isFieldsEmpty, setIsFieldsEmpty] = useState({
    phone: true,
    email: true,
  });
  const [isFieldsChecked, setIsFieldsChecked] = useState({
    phone: false,
    email: false,
  });

  const [phone, setPhone] = useState(null);

  const onFocusPhone = () => {
    if (!phone || phone.length == 0) setPhone("+90 (5");
  };

  const onBlurPhone = () => {
    if (phone === "+90 (5**) *** ** **") setPhone("");
  };
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setIsFieldsEmpty({ ...isFieldsEmpty, [prop]: false });
    setIsFieldsChecked({ ...isFieldsEmpty, [prop]: false });
  };
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  async function sendEmail(event) {
    event.preventDefault();

    let req = requests.getSimplePostRequest(
      USER_APPLICATION.forgotPasswordByEmail
    );
    axios
      .post(req.url, null, { params: { email: values.email } })
      .then((response) => {
        setValues({ ...values, ["hash"]: response.data.hash });
        setAlertType("success");
        setAlertMessage(t("BASAIRILI_MESAJ"));
        setShowAlert(true);
        let confLoc = {
          pathname: "/change-password",
          state: {
            changeMailValues: {
              email: values.email,
              hash: response.data.HASH,
              //token: response.data.TOKEN,
            },
          },
        };

        setTimeout(() => {
          history.push(confLoc);
        }, 1000);
      })
      .catch((err) => {
        console.warn(err);

        setValues({ ...values, ["hash"]: "0" });
        setAlertType("error");
        setAlertMessage(t("Eksik veya hatalı giriş yaptınız!"));
        setShowAlert(true);
        let confLoc = {
          pathname: "/forgot-password",
          state: {
            changeMailValues: {
              email: values.email,
              hash: "0",
              //token: response.data.TOKEN,
            },
          },
        };

        setTimeout(() => {
          history.push(confLoc);
        }, 1000);
      });
  }
  async function sendPhone(event) {
    event.preventDefault();
    let myPhoneNumber = values.phone;
    const regEx = new RegExp(/[\s()*]/g);
    myPhoneNumber = myPhoneNumber.replaceAll(regEx, "");
    let req = requests.getSimplePostRequest(
      USER_APPLICATION.forgotPasswordByPhone
    );
    axios
      .post(req.url, null, { params: { phone: myPhoneNumber } })
      .then((response) => {
        setValues({ ...values, ["hash"]: response.data.hash });
        setAlertType("success");
        setAlertMessage(t("BASAIRILI_MESAJ"));
        setShowAlert(true);
        let confLoc = {
          pathname: "/change-password",
          state: {
            changePhoneValues: {
              phone: values.phone,
              hash: response.data.HASH,
              //token: response.data.TOKEN,
            },
          },
        };

        setTimeout(() => {
          history.push(confLoc);
        }, 1000);
      })
      .catch((err) => {
        console.warn(err);

        if (err.response.status === 404) {
          setAlertMessage(t("TELEFON_BULUNAMADI"));
        } else {
          setAlertMessage(t("Eksik veya hatalı giriş yaptınız!"));
        }

        setValues({ ...values, ["hash"]: "0" });
        setAlertType("error");

        setShowAlert(true);
        let confLoc = {
          pathname: "/forgot-password",
          state: {
            changePhoneValues: {
              phone: values.phone,
              hash: "0",
              //token: response.data.TOKEN,
            },
          },
        };

        setTimeout(() => {
          history.push(confLoc);
        }, 1000);
      });
  }
  const CheckEmail = () => {
    setIsFieldsChecked({ ...isFieldsChecked, ["email"]: true });
    setErrorMessages({ ...errorMessages, ["email"]: "" });
  };
  const CheckPhone = () => {
    setIsFieldsChecked({ ...isFieldsChecked, ["phone"]: true });
    setErrorMessages({ ...errorMessages, ["phone"]: "" });
  };

  return (
    <FaUnAuthPages>
      <img
        src={appLogo}
        height="78"
        alt="logo"
        className={classes.appLogo}
        style={{ objectFit: "contain" }}
      />
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        theme={theme}
        badge="bottomright"
        sitekey={RECAPTCHA_CODE}
      />
      <Typography component="h1" variant="h5">
        {t("SIFREMI_UNUTTUM")}
      </Typography>
      <form
        ref={formRef}
        className={classes.form}
        validate="true"
        onSubmit={
          verificationType === VERIFICATION_EMAIL ? sendEmail : sendPhone
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RadioGroup
              row
              aria-label="position"
              value={verificationType}
              onChange={(event) => {
                setVerificationType(event.target.value);
              }}
            >
              <FormControlLabel
                value={VERIFICATION_EMAIL}
                control={<Radio color="primary" />}
                label="E-Posta"
              />
              <FormControlLabel
                value={VERIFICATION_SMS}
                control={<Radio color="primary" />}
                label="Cep Telefonu"
              />
            </RadioGroup>
            {verificationType === VERIFICATION_EMAIL && (
              <Grid item xs={12}>
                <FaInput
                  faType="email"
                  required
                  label={t("KULLANICI_E_POSTA")}
                  autoComplete="email"
                  onChange={handleChange("email")}
                  onBlur={CheckEmail}
                  value={values.email}
                  helperText={errorMessages.email}
                  faError={errorMessages.email}
                />
              </Grid>
            )}
            {verificationType === VERIFICATION_SMS && (
              <Grid item xs={12}>
                <FaInput
                  faType="phone"
                  required
                  label={t("KULLANICI_TELEFON")}
                  autoComplete="phone"
                  onChange={(event) => {
                    setPhone(event.target.allValues.formattedValue);
                    setValues({
                      ...values,
                      ["phone"]: event.target.allValues.formattedValue,
                    });
                    setIsFieldsEmpty({ ...isFieldsEmpty, ["phone"]: false });
                    setIsFieldsChecked({ ...isFieldsEmpty, ["phone"]: false });
                  }}
                  faMask="+## (###) ### ## ##"
                  faMaskChar="*"
                  onFocus={onFocusPhone}
                  onBlur={onBlurPhone}
                  value={phone}
                  helperText="Örnek : +99 (999) 999 99 99"
                  faError={errorMessages.phone}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Collapse in={showAlert}>
          <Alert
            variant="outlined"
            severity={alertType}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {alertMessage}
          </Alert>
        </Collapse>

        <FaButton
          async
          type="submit"
          formRef={formRef}
          label={t("GONDER")}
          variant="contained"
          style={{ marginTop: "10px", marginBottom: "15px" }}
          className={classes.submit}
        />
        <Grid container justify="center">
          <Grid item>
            <Link
              onClick={() => history.push(loginPath)}
              className={classes.link}
              variant="body2"
            >
              {t("GIRIS_YAP")}
            </Link>
          </Grid>
        </Grid>
      </form>
    </FaUnAuthPages>
  );
}

ForgotPassword.propTypes = {
  loginPath: PropTypes.string,
  backGroundImage: PropTypes.any,
  appLogo: PropTypes.any,
};
