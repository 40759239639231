import UserDetail from "components/detail/UserDetail";
import React from "react";
import { FaAutoComplete } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { ACCOUNT } from "../../services/faApi";

export default function UserAndGroupAutoComplete(props) {
  const { value, accountId, onChange, required, label } = props;

  const { t } = useTranslation("components", { i18n });

  return (
    <FaAutoComplete
      faType="async"
      value={value}
      getOptionSelected={(option, value) => {
        let result = false;
        if (value && value.user && option.user)
          result = option.user.id === value.user.id;
        else if (value && value.userGroup && option.userGroup)
          result = option.userGroup.id === value.userGroup.id;
        else result = false;
      }}
      sort="fullname"
      faFilterProp="fullname"
      getOptionLabel={(option) => {
        if (option.user) return option.user.fullname;
        else if (option.userGroup) return option.userGroup.name;
        else return "";
      }}
      requestUrl={ACCOUNT.findAccountUsersAndGroups + "?accountId=" + accountId}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      label={label == null ? t("USER") : label}
      required={required}
      renderOption={(option) => (
        <UserDetail
          value={option.user ? option.user : option.userGroup}
          type={option.user ? "USER" : "USER_GROUP"}
        />
      )}
    />
  );
}
