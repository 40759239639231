import React from "react";



export default function ForbiddenPage() {  

    return (
        <div>
            Forbidden Pages
        </div>
    );
}


