import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { SURVEY } from "./faApi";

export const SurveyService = {
  save: (survey, callback, errorCallback) => {
    let reqBody = {
      ...survey,
    };
    let req = requests.getSimplePostRequest(SURVEY.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  delete: (survey, callback, errorCallback) => {
    let reqBody = {
      ...survey,
    };

    let req = requests.getSimplePostRequest(SURVEY.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(SURVEY.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  active: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(SURVEY.active + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  passive: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(SURVEY.passive + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  findTargets: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      SURVEY.findTargets + "?surveyId=" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  deleteTargets: (targets, callback, errorCallback) => {
    let reqUrl = "?";
    for (let i = 0; i < targets.length; i++) {
      reqUrl += "id=" + targets[i];
      if (i !== targets.length - 1) {
        reqUrl += "&";
      }
    }

    let reqBody = {
      ...targets,
    };
    let req = requests.getSimplePostRequest(
      SURVEY.deleteTargets + reqUrl,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
  saveTargets: (targets, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(SURVEY.saveTargets, targets);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  savePage: (page, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(SURVEY.savePage, page);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  deletePage: (page, callback, errorCallback) => {
    let reqBody = {
      ...page,
    };

    let req = requests.getSimplePostRequest(SURVEY.deletePage, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
  findPages: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      SURVEY.findPages + "?surveyId=" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  pageMoveUp: (surveyId, id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      SURVEY.pageMoveUp + "?surveyId=" + surveyId + "&id=" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  pageMoveDown: (surveyId, id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      SURVEY.pageMoveDown + "?surveyId=" + surveyId + "&id=" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  saveQuestion: (question, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(SURVEY.saveQuestion, question);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  deleteQuestion: (question, callback, errorCallback) => {
    let reqBody = {
      ...question,
    };

    let req = requests.getSimplePostRequest(SURVEY.deleteQuestion, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
  findQuestions: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      SURVEY.findQuestions + "?surveyId=" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  questionMoveUp: (surveyId, id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      SURVEY.questionMoveUp + "?surveyId=" + surveyId + "&id=" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  questionMoveDown: (surveyId, id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      SURVEY.questionMoveDown + "?surveyId=" + surveyId + "&id=" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  analysis: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(SURVEY.analysis + "/" + id);

    FaAxios(req)
      .then((response) => {
        console.log("response", response);
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getSurvey: (id, accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(SURVEY.getSurvey, {
      id,
      accountId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  answerFindById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(SURVEY.answerFindById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getNext: (accountId, surveyId, id, sort, isAsc, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(SURVEY.getNext, {
      accountId,
      surveyId,
      id,
      sort,
      isAsc,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getPrevious: (
    accountId,
    surveyId,
    id,
    sort,
    isAsc,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(SURVEY.getPrevious, {
      accountId,
      surveyId,
      id,
      sort,
      isAsc,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  addQuestionToLibrary: (survey, callback, errorCallback) => {
    let reqBody = {
      ...survey,
    };

    let req = requests.getSimplePostRequest(
      SURVEY.addQuestionToLibrary,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  libraryQuestions: (searchForm, callback, errorCallback) => {
    let reqBody = {
      ...searchForm,
    };

    let req = requests.getSimplePostRequest(SURVEY.libraryQuestions, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  saveAllQuestions: (list, callback, errorCallback) => {
    let reqBody = {
      ...list,
    };

    let req = requests.getSimplePostRequest(SURVEY.saveAllQuestions, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  saveQuestionCategory: (category, callback, errorCallback) => {
    let reqBody = {
      ...category,
    };

    let req = requests.getSimplePostRequest(
      SURVEY.saveQuestionCategory,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  copy: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(SURVEY.copy + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getDailyCounts: (surveyId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      SURVEY.getDailyCounts + "/" + surveyId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  surveyAllAnswers: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(SURVEY.surveyAllAnswers + "/" + id);

    FaAxios(req)
      .then((response) => {
        console.log("response", response);
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
