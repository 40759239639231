import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function ChannelSelect(props) {
  const { value, onChange, required, sms, email, push } = props;
  const classes = useStyles();
  const { t } = useTranslation("channel", { i18n });

  return (
    <FormControl
      variant="outlined"
      className={classes.w100}
      size="small"
      required={required}
    >
      <InputLabel>{t("CHANNEL")}</InputLabel>
      <Select
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        label={t("CHANNEL")}
      >
        <MenuItem value={null}>...</MenuItem>
        {((!sms && !email && !push) || sms) && (
          <MenuItem value={"SMS"}>{t("SMS")}</MenuItem>
        )}
        {((!sms && !email && !push) || email) && (
          <MenuItem value={"EMAIL"}>{t("EMAIL")}</MenuItem>
        )}
        {((!sms && !email && !push) || push) && (
          <MenuItem value={"PUSH"}>{t("PUSH")}</MenuItem>
        )}
      </Select>
    </FormControl>
  );
}
