import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function PatrolExecutionStatusSelect(props) {
  const { value, onChange, required } = props;
  const classes = useStyles();
  const { t } = useTranslation("components", { i18n });

  return (
    <FormControl variant="outlined" className={classes.w100} size="small">
      <InputLabel>{t("PATROL_EXECUTION_TYPE")}</InputLabel>
      <Select
        // classes={{
        //   outlined: classes.padding10,
        // }}
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        required={required}
        label={t("PATROL_EXECUTION_TYPE")}
      >
        <MenuItem value={null}>...</MenuItem>
        <MenuItem value={"PATROL_EXECUTION_TYPE_ACTIVE"}>
          {t("PATROL_EXECUTION_TYPE_ACTIVE")}
        </MenuItem>
        <MenuItem value={"PATROL_EXECUTION_TYPE_COMPLETED"}>
          {t("PATROL_EXECUTION_TYPE_COMPLETED")}
        </MenuItem>
        <MenuItem value={"PATROL_EXECUTION_TYPE_ALL_MISSED"}>
          {t("PATROL_EXECUTION_TYPE_ALL_MISSED")}
        </MenuItem>
        <MenuItem value={"PATROL_EXECUTION_TYPE_SOME_MISSED"}>
          {t("PATROL_EXECUTION_TYPE_SOME_MISSED")}
        </MenuItem>
      </Select>
    </FormControl>
  );
}
