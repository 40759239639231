import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { SUGGESTIONCLASS } from "./faApi";

export const SuggestionClassService = {
  save: (suggestionClass, callback, errorCallback) => {
    let reqBody = {
      ...suggestionClass,
    };

    let req = requests.getSimplePostRequest(SUGGESTIONCLASS.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (suggestionClass, callback, errorCallback) => {
    let reqBody = {
      ...suggestionClass,
    };

    let req = requests.getSimplePostRequest(SUGGESTIONCLASS.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findByThingClass: (thingClassId, accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      SUGGESTIONCLASS.findByThingClass +
        "?accountId=" +
        accountId +
        "&thingClassId=" +
        thingClassId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  enable: (suggestionClassDisableId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(SUGGESTIONCLASS.enable, {
      suggestionClassDisableId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  disable: (suggestionClassId, thingClassId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(SUGGESTIONCLASS.disable, {
      suggestionClassId,
      thingClassId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(SUGGESTIONCLASS.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
