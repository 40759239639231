import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
    ThingDetailImage: {
        width: "100%",
        objectFit: "scale-down"
    },
    ThingDetailLabelHeader: {
        //textAlign: "right"
    }

}));