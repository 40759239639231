import {
  faChevronDown,
  faChevronUp,
  faEdit,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { Add } from "@material-ui/icons";
import ServiceOperationClassAutoComplete from "components/autocomplete/ServiceOperationClassAutoComplete";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import ThingClassAutoComplete from "components/autocomplete/ThingClassAutoComplete";
import ThingDetail from "components/detail/ThingDetail";
import ThingSelector from "components/selector/ThingSelector";
import ThingsDataTable from "components/thingsDataTable";
import ControlItemEdit from "features/controlItem/ControlItemEdit";
import React, { useRef, useState } from "react";
import { FaSearch } from "react-base-fa";
import { FaDialog } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { PatrolDefThingService } from "services/PatrolDefThingService";
import { ControlItemService } from "services/controlItem/controlItemService";
import { isTNF } from "services/utils";
import i18n from "../../i18n";
import { PATROL_DEF_THING } from "../../services/faApi";
import { useStyles } from "./style";

export default function PatrolDefThings(props) {
  const { patrolDef, onChange } = props;
  const faDialog = useDialog();
  const classes = useStyles();
  let history = useHistory();

  const { t } = useTranslation(["patrolDef", "thingClass"], { i18n });
  const tableRef = useRef();

  const accountId = localStorage.accountId;
  const [loading, setLoading] = useState(false);
  const [isControlItemDialogOpen, setIsControlItemDialogOpen] = useState(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedServiceOperationClass, setSelectedServiceOperationClass] =
    useState(null);
  const [selectedPatrolDefThing, setSelectedPatrolDefThing] = useState({
    things: [],
    serviceOperationClass: null,
  });
  const [selectedControlItem, setSelectedControlItem] = useState(null);

  const [things, setThings] = useState([]);

  const refresh = () => {
    tableRef.current.refreshTable();
  };

  const deletePatrolDefThing = () => {
    faDialog({
      description: t("Kaydı silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        PatrolDefThingService.delete(
          selectedPatrolDefThing,
          deleteOnSuccess,
          deleteOnError
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const deleteOnSuccess = (data) => {
    refresh();
    onChange();
    setIsDialogOpen(false);
  };

  const deleteOnError = (data) => {};

  const moveUp = (row) => {
    PatrolDefThingService.moveUp(
      patrolDef.id,
      row.id,
      row.id,
      deleteOnSuccess,
      deleteOnError
    );
  };

  const moveDown = (row) => {
    PatrolDefThingService.moveDown(
      patrolDef.id,
      row.id,
      row.id,
      deleteOnSuccess,
      deleteOnError
    );
  };

  const renderMoveButton = (row, up) => {
    return (
      <IconButton
        onClick={() => (up === true ? moveUp(row) : moveDown(row))}
        size="small"
        color="primary"
        aria-label={up === true ? t("MOVE_UP") : t("MOVE_DOWN")}
        disabled={patrolDef.status === "ACTIVE"}
      >
        <FontAwesomeIcon icon={up === true ? faChevronUp : faChevronDown} />
      </IconButton>
    );
  };

  const deleteControlItem = (data) => {
    faDialog({
      description: t("Kontrol sorusunu silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        ControlItemService.delete(
          data,
          (data) => {
            refresh();
          },
          (error) => {
            console.log("error", error);
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const renderControlItems = (row) => {
    if (row.controlItemList && row.controlItemList.length > 0) {
      return (
        <table>
          {row.controlItemList.map((item, index) => {
            return (
              <tr
                onDoubleClick={() => {
                  if (patrolDef.status !== "ACTIVE") {
                    setSelectedControlItem(item);
                    setIsControlItemDialogOpen(true);
                  }
                }}
              >
                <td>{item.orderNo}</td>
                <td>{item.question}</td>
                <td>{t(item.itemType, { ns: "thingClass" })}</td>
                <td>{t(item.documentAttachmentType, { ns: "thingClass" })}</td>
                <td>
                  <IconButton
                    onClick={() => deleteControlItem(item)}
                    size="small"
                    color="primary"
                    aria-label={t("SIL")}
                    disabled={patrolDef.status === "ACTIVE"}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </IconButton>
                </td>
              </tr>
            );
          })}
          <tr>
            <td colSpan={5}>
              <IconButton
                onClick={() => {
                  setSelectedControlItem(null);
                  setSelectedPatrolDefThing(row);
                  setIsControlItemDialogOpen(true);
                }}
                size="small"
                color="primary"
                aria-label={t("Ekle")}
                title="Kontrol Sorusu Ekle"
                disabled={patrolDef.status === "ACTIVE"}
              >
                <FontAwesomeIcon icon={faPlus} />
              </IconButton>
            </td>
          </tr>
        </table>
      );
    } else {
      return (
        <>
          <IconButton
            onClick={() => {
              setSelectedControlItem(null);
              setSelectedPatrolDefThing(row);
              setIsControlItemDialogOpen(true);
            }}
            size="small"
            color="primary"
            aria-label={t("Ekle")}
            title="Kontrol Sorusu Ekle"
          >
            <FontAwesomeIcon icon={faPlus} />
          </IconButton>
        </>
      );
    }
  };

  const columns = [
    { field: "orderNo", label: t("ORDER_NO"), align: "center", width: "5%" },
    {
      field: "thing",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      width: "30%",
      valueFormatter: (val) => {
        return <ThingDetail value={val} />;
      },
    },
    {
      field: "serviceOperationClass",
      label: t("Bakım Görevi"),
      align: "center",
      width: "20%",
      valueFormatter: (val) => {
        return val?.className;
      },
    },
    {
      field: "id",
      label: t("Kontrol Soruları"),
      align: "center",
      width: "35%",
      valueFormatter: (value, row) => {
        return renderControlItems(row);
      },
    },
    {
      field: "id",
      label: t("OPERATION"),
      align: "center",
      width: "15%",
      valueFormatter: (value, row) => {
        return (
          <>
            <IconButton
              onClick={() => onEditButton(row)}
              size="small"
              color="primary"
              aria-label={t("SIL")}
              disabled={patrolDef.status === "ACTIVE"}
            >
              <FontAwesomeIcon icon={faEdit} />
            </IconButton>
            {renderMoveButton(row, true)}
            {renderMoveButton(row, false)}
          </>
        );
      },
    },
  ];

  const addThings = () => {
    let tmp = things.map((item, index) => {
      if (index === 0)
        return {
          ...selectedPatrolDefThing,
          patrolDef: patrolDef,
          thing: item,
          serviceOperationClass: selectedPatrolDefThing.serviceOperationClass,
        };
      else
        return {
          patrolDef: patrolDef,
          thing: item,
          serviceOperationClass: selectedPatrolDefThing.serviceOperationClass,
        };
    });
    PatrolDefThingService.saveAll(tmp, saveOnSuccess, saveOnError);
  };

  const saveOnSuccess = (data) => {
    refresh();
    setIsDialogOpen(false);
    onChange();
  };

  const saveOnError = (data) => {};

  const onEditButton = (row) => {
    if (patrolDef.status !== "ACTIVE") {
      setSelectedPatrolDefThing(row);
      setThings([row.thing]);
      setIsDialogOpen(true);
    }
  };

  const renderDialog = () => {
    return (
      <FaDialog
        loading={loading}
        showSaveButton
        faOpen={isDialogOpen}
        title={t("EDIT_THING")}
        faOnSubmit={addThings}
        faHandleClose={() => {
          setIsDialogOpen(false);
        }}
      >
        <Grid item container spacing={1}>
          <Grid item xs={12}>
            <ThingSelector
              value={things}
              accountId={accountId}
              multiple={true}
              onChange={(data) => {
                setThings(data);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ServiceOperationClassAutoComplete
              accountId={accountId}
              value={selectedPatrolDefThing?.serviceOperationClass}
              onChange={(data) => {
                setSelectedPatrolDefThing({
                  ...selectedPatrolDefThing,
                  serviceOperationClass: data,
                });
              }}
              label="Bakım görevi eklemek için seçiniz"
            />
          </Grid>
        </Grid>
        {selectedPatrolDefThing?.id ? (
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button onClick={deletePatrolDefThing} color="secondary">
              {t("Sil")}
            </Button>
          </Grid>
        ) : null}
      </FaDialog>
    );
  };

  const thingAddButtonClicked = () => {
    setSelectedPatrolDefThing({
      things: [],
      serviceOperationClass: null,
    });
    setThings([]);
    setIsDialogOpen(true);
  };

  const newSearchForm = () => {
    return {
      patrolDefId: patrolDef.id,
      thingId: null,
      thing: null,
      thingClassId: null,
      thingClass: null,
    };
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const controlItemOnChange = () => {
    tableRef.current.refreshTable();
    setIsControlItemDialogOpen(false);
  };

  return (
    <>
      {renderDialog()}
      <ControlItemEdit
        onChange={controlItemOnChange}
        open={isControlItemDialogOpen}
        patrolDefThing={selectedPatrolDefThing}
        value={selectedControlItem}
        onClose={() => {
          setIsControlItemDialogOpen(false);
        }}
      />
      <Grid item lg={12} xs={12}>
        <Card>
          <CardHeader
            action={
              <IconButton
                aria-label="settings"
                onClick={thingAddButtonClicked}
                disabled={patrolDef.status === "ACTIVE"}
              >
                <Add />
              </IconButton>
            }
            title={t("PATROL_THINGS")}
            data-fa-section="PATROL_THINGS"
          />

          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
            style={{ marginTop: 10 }}
          >
            <Grid item xs={6}>
              <div>{searchInfo}</div>
            </Grid>
            <Grid item xs={6}>
              <FaSearch
                onSearch={searchSubmit}
                onClear={searchClear}
                onSummarySearch={searchSummary}
                setSearchInfo={setSearchInfo}
              >
                <ThingAutoComplete
                  value={searchForm.thing}
                  accountId={accountId}
                  onChange={(data) => {
                    setSearchForm({
                      ...searchForm,
                      thing: data,
                      thingId: data?.id,
                    });
                  }}
                />

                <ThingClassAutoComplete
                  accountId={accountId}
                  value={searchForm.thingClass}
                  onChange={(data) => {
                    setSearchForm({
                      ...searchForm,
                      thingClass: data,
                      thingClassId: data?.id,
                    });
                  }}
                />
              </FaSearch>
            </Grid>
          </Grid>

          <ThingsDataTable
            searchUrl={PATROL_DEF_THING.search + "?patrolDefId=" + patrolDef.id}
            searchForm={searchForm}
            columns={columns}
            ref={tableRef}
            showHeaderText={false}
            queryParams={{
              page: 0,
              size: 50,
              sort: "orderNo",
              isAsc: true,
            }}
          />
        </Card>
      </Grid>
    </>
  );
}
