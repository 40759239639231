import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardHeader, Grid, IconButton } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import AddIcon from "@material-ui/icons/Add";
import CustomerListAutoComplete from "components/autocomplete/CustomerListAutoComplete";
import ThingsDataTable from "components/thingsDataTable";
import React, { useRef, useState } from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { CustomerListDetailService } from "services/CustomerListDetailService";
import { CustomerService } from "services/CustomerService";
import { CUSTOMER } from "services/faApi";
import { gridStyles } from "styles/ThingsGridStyle";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function CustomerLists(props) {
  const { customer } = props;
  const classes = useStyles();
  const tableRef = useRef();
  const gridClasses = gridStyles();
  const accountId = localStorage.accountId;
  const faDialog = useDialog();
  const dispatch = useDispatch();
  const { t } = useTranslation("customer", { i18n });
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [customerList, setCustomerList] = useState();

  const refresh = () => {
    tableRef.current.refreshTable();
  };
  const removeFromList = (customerId, customerListId) => {
    faDialog({
      description: t(t("Kaydı silmek istediğinize emin misiniz?")),
      type: "confirm",
    })
      .then((confirmation) => {
        CustomerService.removeFromList(
          customerId,
          customerListId,

          (data) => {
            showAlert(t("Başarıyla silindi"), "success");
            refresh();
          },
          (error) => {
            showAlert(t("Kayıt silinemedi, bağlı kayıtlar mevcut."), "error");
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const columns = [
    {
      field: "name",
      label: t("NAME"),
      align: "center",
      width: "90%",
    },
    {
      field: "id",
      label: t("OPERATION"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        return (
          <IconButton
            onClick={() => removeFromList(customer?.id, row?.id)}
            size="small"
            color="primary"
            aria-label={t("SIL")}
          >
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        );
      },
    },
  ];

  const saveList = () => {
    CustomerListDetailService.save(
      { customer: { id: customer.id }, customerList: { id: customerList.id } },
      (data) => {
        setIsEditDialogOpen(false);
        showAlert(t("Başarıyla eklendi"), "success");
        refresh();
      },
      (error) => {
        showAlert(t("Müşteri kayıtlı."), "error");
      }
    );
  };

  const editDialogRender = () => {
    return (
      <>
        <FaDialog
          title={t("Listeye Ekle")}
          faOpen={isEditDialogOpen}
          faOnSubmit={saveList}
          showSaveButton
          faHandleClose={(data) => {
            setIsEditDialogOpen(false);
          }}
        >
          <Grid item container alignItems="center" justifyContent="center">
            <Grid item xs={12}>
              <CustomerListAutoComplete
                value={customerList}
                accountId={accountId}
                onChange={(data) => {
                  setCustomerList(data);
                }}
              />
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  return (
    <>
      {editDialogRender()}
      <Card style={{ minHeight: 100 }} className="fai-customerInteractions">
        <CardHeader
          title={t("Bulunduğu Listeler")}
          data-fa-section="Bulunduğu Listeler"
          action={
            <IconButton
              aria-label="settings"
              onClick={() => {
                setCustomerList(null);
                setIsEditDialogOpen(true);
              }}
            >
              <AddIcon />
            </IconButton>
          }
        />
        {customer && customer.id > 0 && (
          <ThingsDataTable
            dataUrl={CUSTOMER.findLists + "?id=" + customer.id}
            queryParams={{
              page: 0,
              size: 10,
              sort: "name",
              isAsc: false,
            }}
            columns={columns}
            ref={tableRef}
            showDeleteButton={false}
            showHeaderText={false}
            columnClassName={gridClasses.GenericColumn}
          />
        )}
      </Card>
    </>
  );
}
