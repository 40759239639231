import ThingDetail from "features/thingDetail";
import Things from "features/things";
import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { ThingService } from "services/thing/thingService";
import i18n from "../../i18n";
import { useStyles } from "./QuickStartTutorialStyle";
import ThingsSteps from "./ThingsSteps";
import TutorialElement from "./TutorialElement";

export default function ShareFileStandardTutorial() {
  const { code } = useParams();
  const classes = useStyles();

  const stepsRef = useRef();
  const myThingsRef = useRef(null);
  const thingDetailRef = useRef(null);
  let history = useHistory();

  const { t } = useTranslation("tutorialShareWebLink", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);
  const [thingId, setThingId] = useState(null);

  useEffect(() => {
    ThingService.getSystemThing(
      localStorage.accountId,
      (data) => {
        setThingId(data?.id);
      },
      (error) => {}
    );
  }, []);

  useEffect(() => {
    if (thingId)
      setSteps([
        {
          element: "#menuThings",
          intro: (
            <TutorialElement icon="click">
              <b>{t("Etkileşim Noktalarım")}</b> {t(" menüsüne tıklayın")}.
            </TutorialElement>
          ),
        },
        {
          element: "#menuThingList",
          intro: (
            <TutorialElement icon="click">
              <b>{t("Etkileşim Noktalarını ")}</b>{" "}
              {t("görüntülemek için Listeye tıklayın.")}
            </TutorialElement>
          ),
          onBeforeChange: (index) => {
            var element = document.querySelector("#menuThings");
            if (element?.childElementCount > 1 == false)
              $("#menuThings")[0].firstElementChild.click();
            stepsRef.current.updateStepElement(index);
          },
        },
        {
          intro: (
            <TutorialElement icon="info">
              {"Web bağlantısı eklemek istediğiniz"}{" "}
              <b>{t("Etkileşim Noktasına")}</b> {"çift tıklayın. "}
            </TutorialElement>
          ),
          comp: "Things",
          onBeforeChange: (index) => {
            stepsRef.current.updateStepElement(index);
          },
        },
        {
          intro: (
            <TutorialElement icon="info">
              {t("Etkileşim noktasına ait tüm bilgiler burada yer almaktadır.")}
            </TutorialElement>
          ),
          comp: "ThingDetail",
          onBeforeChange: (index) => {
            stepsRef.current.updateStepElement(index);
          },
        },
        {
          element: "[data-fa-button='FILES_ADD']",
          intro: (
            <TutorialElement header="Menu" icon="click">
              {t("Dosya eklemek için tıklayın.")}
            </TutorialElement>
          ),
          comp: "ThingDetail",
          onBeforeChange: (index) => {
            stepsRef.current.updateStepElement(index);
          },
        },
        {
          element: "[data-fa-section='FILES_EDIT_DIALOG']",
          intro: (
            <TutorialElement icon="info">
              {t("Tanım ve Açıklama bilgilerini girin.")} <br />
              {t("Etkileşim için ")} <b>{t("Herkes'i")}</b> {t(" seçin.")}{" "}
              <br />
              {t(" Belgeyi yükleyin ve ")} <b>{t("Kaydet'e")}</b> {t(" basın.")}
            </TutorialElement>
          ),
          comp: "ThingDetail",
          onBeforeChange: (index) => {
            $("[data-fa-button='FILES_ADD']").click();
            stepsRef.current.updateStepElement(index);
          },
        },
        {
          element: "[data-fa-section='FILES_CARD']",
          intro: (
            <TutorialElement icon="info">
              {t("Dosya paylaşımına hazırsınız.")} <br />
            </TutorialElement>
          ),
          comp: "ThingDetail",
          onBeforeChange: (index) => {
            $("[aria-label='close']")[0].click();
            stepsRef.current.updateStepElement(index);
          },
        },
      ]);
  }, [thingId]);

  return (
    <div>
      {steps &&
        steps.length > stepIndex &&
        steps[stepIndex].comp === "Things" && <Things />}
      {steps &&
        steps.length > stepIndex &&
        steps[stepIndex].comp === "ThingDetail" &&
        thingId && <ThingDetail ref={thingDetailRef} thingId={thingId} />}

      <ThingsSteps
        code={code}
        steps={steps}
        ref={stepsRef}
        onStepChange={setStepIndex}
        afterComplete={() => {
          window.history.back();
          $("#menuThings")[0].firstElementChild.click();
        }}
      />
    </div>
  );
}
