export const CHART_COLORS = [
  "#11AECF",
  "#3CBB78",
  "#FCBA28",
  "#EF3133",
  "#2D56A6",
  "#1576BB",
  "#A1419A",
  "#4B3E9A",
  "#173978",
  "#1E91CA",
  "#38B64A",
  "#6CBD47",
  "#9ECC38",
  "#F5ED32",
  "#FCD228",
  "#F67B2E",
  "#FF5254",
  "#EB2364",
  "#CB1D8C",
  "#723D97",
];
