import { Grid, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import LoadingBar from "components/loadingBar";
import ThingsDataTable from "components/thingsDataTable";
import i18n from "i18n";
import { default as React, Suspense, useRef, useState } from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { BENEFICIARY_PORTAL } from "services/faApi";
import { getDateTimeWFormat } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";

export default function BenefficiaryMessages() {
  const accountId = localStorage.beneficiaryAccountId;
  const { t } = useTranslation("beneficiaryPortal", { i18n });
  const labelClasses = labelStyles();
  const [isMessageDetailOpen, setIsMessageDetailOpen] = useState(false);
  const tableRef = useRef();
  const [beneficiaryMessage, setBeneficiaryMessage] = useState(null);
  const newSearchForm = () => {
    return {
      accountId: accountId,
    };
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const columns = [
    {
      field: "createDate",
      label: t("Gönderim Tarihi"),
      align: "center",
      width: "25%",
      valueFormatter: (val) => getDateTimeWFormat(val),
    },
    {
      field: "subject",
      label: t("Konu"),
      align: "center",
      width: "25%",
    },
    {
      field: "text",
      label: t("İçerik"),
      align: "center",
      width: "25%",
    },

    {
      field: "to",
      label: t("Alıcı"),
      align: "center",
      width: "25%",
    },
  ];

  const onDoubleClick = (row) => {
    setBeneficiaryMessage(row);
    setIsMessageDetailOpen(true);
  };
  const renderDetailDialog = () => {
    return (
      <FaDialog
        title={"Mesaj Detay"}
        faOpen={isMessageDetailOpen}
        faHandleClose={() => {
          setIsMessageDetailOpen(false);
        }}
      >
        {beneficiaryMessage && (
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={0}
          >
            <Grid item xs={12}>
              <Grid item container spacing={1}>
                <Grid item xs={6} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("Gönderim Tarihi")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {getDateTimeWFormat(beneficiaryMessage.createDate)}
                  </Typography>
                </Grid>

                <Grid item xs={6} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("Alıcı")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {beneficiaryMessage.to}
                  </Typography>
                </Grid>

                <Grid item xs={6} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("Konu")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {beneficiaryMessage.subject}
                  </Typography>
                </Grid>

                <Grid item xs={6} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("Gönderim Yolu")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {beneficiaryMessage.flag.toLowerCase()}
                  </Typography>
                </Grid>

                <Grid item xs={12} className={labelClasses.LastRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("İçerik")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {beneficiaryMessage.text}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </FaDialog>
    );
  };
  return (
    <Suspense fallback={<LoadingBar />}>
      {renderDetailDialog()}

      <Card>
        <CardHeader title={t("Mesajlarım")} data-fa-section="MESSAGES" />
        <ThingsDataTable
          searchUrl={BENEFICIARY_PORTAL.findEmailSMS}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
        />
      </Card>
    </Suspense>
  );
}
