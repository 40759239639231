import { THINGS_ENVIRONMENT } from "environments/Environments";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";
import { APP } from "services/utils/Constants";
import LoginT2C from "./LoginT2C";
import LoginTF from "./LoginTF";

export default function Login(props) {
  const {
    appLogo,
    requestUrl,
    align,
    backGroundImage,
    signUpPath,
    fgPasswordPath,
    usernameLogin,
    userPhoneNumberLogin,
  } = props;

  const app = localStorage.app;

  const history = useHistory();

  const renderTF = () => {
    return (
      <LoginTF
        appLogo={THINGS_ENVIRONMENT.appLogo}
        requestUrl={requestUrl}
        align={align}
        backGroundImage={backGroundImage}
        signUpPath={signUpPath}
        fgPasswordPath={fgPasswordPath}
        usernameLogin={usernameLogin}
        userPhoneNumberLogin={userPhoneNumberLogin}
      />
    );
  };

  const renderT2C = () => {
    history.push("/login-t2c");
    return <></>;
    if (false)
      return (
        <LoginT2C
          appLogo={THINGS_ENVIRONMENT.appLogoT2CDark}
          requestUrl={requestUrl}
          align={align}
          backGroundImage={backGroundImage}
          signUpPath={signUpPath}
          fgPasswordPath={fgPasswordPath}
          usernameLogin={usernameLogin}
          userPhoneNumberLogin={userPhoneNumberLogin}
        />
      );
  };

  if (app === APP.T2C) return renderT2C();
  else return renderTF();
}

Login.propTypes = {
  appLogo: PropTypes.any,
  align: PropTypes.string,
  requestUrl: PropTypes.string,
  backGroundImage: PropTypes.any,
  usernameLogin: PropTypes.bool,
  signUpPath: PropTypes.string,
  fgPasswordPath: PropTypes.string,
};
