import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { CAMPAIGN, DOCUMENT } from "./faApi";

export const CampaignService = {
  save: (campaign, callback, errorCallback) => {
    let reqBody = {
      ...campaign,
    };

    let req = requests.getSimplePostRequest(CAMPAIGN.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(CAMPAIGN.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  deleteCampaign: (campaign, callback, errorCallback) => {
    let reqBody = {
      ...campaign,
    };
    let req = requests.getSimplePostRequest(CAMPAIGN.deleteCampaign, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  changeStatus: (id, status, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      CAMPAIGN.changeStatus + "/" + id + "?status=" + status
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  setDefaultImage: (campaignId, objectId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      CAMPAIGN.setDefaultImage +
        "?campaignId=" +
        campaignId +
        "&objectId=" +
        encodeURI(objectId)
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  findCalendars: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      CAMPAIGN.findCalendars + "?campaignId=" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  saveCalendar: (calendar, callback, errorCallback) => {
    let reqBody = {
      ...calendar,
    };
    let req = requests.getSimplePostRequest(CAMPAIGN.saveCalendar, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findUsers: (campaignId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      CAMPAIGN.findUsers + "?campaignId=" + campaignId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  deleteCalendar: (calendar, callback, errorCallback) => {
    let reqBody = {
      ...calendar,
    };
    let req = requests.getSimplePostRequest(CAMPAIGN.deleteCalendar, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  saveUser: (user, callback, errorCallback) => {
    let reqBody = {
      ...user,
    };
    let req = requests.getSimplePostRequest(CAMPAIGN.saveUser, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  deleteUser: (user, callback, errorCallback) => {
    let reqBody = {
      ...user,
    };
    let req = requests.getSimplePostRequest(CAMPAIGN.deleteUser, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  saveTargets: (targets, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(CAMPAIGN.saveTargets, targets);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  deleteTargets: (targets, callback, errorCallback) => {
    let reqUrl = "?";
    for (let i = 0; i < targets.length; i++) {
      reqUrl += "id=" + targets[i];
      if (i !== targets.length - 1) {
        reqUrl += "&";
      }
    }

    let reqBody = {
      ...targets,
    };
    let req = requests.getSimplePostRequest(
      CAMPAIGN.deleteTargets + reqUrl,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },

  findTargets: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      CAMPAIGN.findTargets + "?campaignId=" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findDocuments: (campaignId, accountId, callback, errorCallback) => {
    let reqParams = {
      campaignId: campaignId,
      accountId: accountId,
      page: 0,
      size: 10,
      isAsc: false,
      sort: "name",
    };

    let req = requests.getSimpleGetRequest(DOCUMENT.findDocuments, reqParams);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  uploadDocument: (
    campaignId,
    accountId,
    name,
    description,
    document,
    callback,
    errorCallback
  ) => {
    let formData = new FormData();
    formData.append("multipartFile", document.file, document.file.name);

    let req = requests.getSimplePostRequest(
      DOCUMENT.uploadDocument +
        "?campaignId=" +
        campaignId +
        "&accountId=" +
        accountId +
        "&name=" +
        name +
        "&description=" +
        description,
      formData
    );
    req["header"] = { "Content-Type": "multipart/form-data" };

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  downloadDocument: (
    campaignId,
    accountId,
    documentId,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(
      DOCUMENT.downloadDocument +
        "?campaignId=" +
        campaignId +
        "&accountId=" +
        accountId +
        "&documentId=" +
        documentId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  deleteDocument: (
    campaignId,
    accountId,
    documentId,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimplePostRequest(
      DOCUMENT.deleteDocument +
        "?campaignId=" +
        campaignId +
        "&accountId=" +
        accountId +
        "&documentId=" +
        documentId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findPages: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      CAMPAIGN.findPages + "?campaignId=" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findQuestions: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      CAMPAIGN.findQuestions + "?campaignId=" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  saveQuestion: (question, callback, errorCallback) => {
    let reqBody = {
      ...question,
    };
    let req = requests.getSimplePostRequest(CAMPAIGN.saveQuestion, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  deleteQuestion: (question, callback, errorCallback) => {
    let reqBody = {
      ...question,
    };
    let req = requests.getSimplePostRequest(CAMPAIGN.deleteQuestion, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  getCampaign: (id, accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(CAMPAIGN.getCampaign, {
      id,
      accountId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getCampaignNumbers: (id, accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(CAMPAIGN.getCampaignNumbers, {
      id,
      accountId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  customerFindById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(CAMPAIGN.customerFindById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  changeCustomerStatus: (
    id,
    status,
    orderNo,
    awardId,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimplePostRequest(
      CAMPAIGN.changeCustomerStatus +
        "/" +
        id +
        "?status=" +
        status +
        "&orderNo=" +
        orderNo +
        "&campaignAwardId=" +
        awardId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  changeCustomerStatusSimple: (id, status, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      CAMPAIGN.changeCustomerStatusSimple + "/" + id + "?status=" + status
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  getNext: (
    accountId,
    campaignId,
    id,
    sort,
    isAsc,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(CAMPAIGN.getNext, {
      accountId,
      campaignId,
      id,
      sort,
      isAsc,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getPrevious: (
    accountId,
    campaignId,
    id,
    sort,
    isAsc,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(CAMPAIGN.getPrevious, {
      accountId,
      campaignId,
      id,
      sort,
      isAsc,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  downloadCustomerAnswer: (accountId, id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      CAMPAIGN.downloadCustomerAnswer + "?accountId=" + accountId + "&id=" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  downloadCustomerAnswerShopping: (accountId, id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      CAMPAIGN.downloadCustomerAnswerShopping +
        "?accountId=" +
        accountId +
        "&id=" +
        id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findAwards: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      CAMPAIGN.findAwards + "?campaignId=" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  saveAward: (award, callback, errorCallback) => {
    let reqBody = {
      ...award,
    };

    let req = requests.getSimplePostRequest(CAMPAIGN.saveAward, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  deleteAward: (award, callback, errorCallback) => {
    let reqBody = {
      ...award,
    };
    let req = requests.getSimplePostRequest(CAMPAIGN.deleteAward, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  uploadImage: (campaignId, document, callback, errorCallback) => {
    let formData = new FormData();
    formData.append("multipartFile", document, document.name);

    let req = requests.getSimplePostRequest(
      DOCUMENT.uploadCampaignImage + "/" + campaignId,
      formData
    );
    req["header"] = { "Content-Type": "multipart/form-data" };

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  deleteImage: (accountId, campaignId, documentId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      DOCUMENT.deleteCampaignImage +
        "?accountId=" +
        accountId +
        "&campaignId=" +
        campaignId +
        "&documentId=" +
        encodeURI(documentId)
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  getCampaignImage: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      DOCUMENT.getCampaignImage + "/" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  findCheckLists: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      CAMPAIGN.findCheckLists + "?campaignId=" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  deleteCheckList: (award, callback, errorCallback) => {
    let reqBody = {
      ...award,
    };
    let req = requests.getSimplePostRequest(CAMPAIGN.deleteCheckList, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  saveCheckList: (award, callback, errorCallback) => {
    let reqBody = {
      ...award,
    };

    let req = requests.getSimplePostRequest(CAMPAIGN.saveCheckList, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getCustomerCheckList: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      CAMPAIGN.getCustomerCheckList + "?id=" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  fillCheckList: (form, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(CAMPAIGN.fillCheckList, form);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  evaluate: (form, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(CAMPAIGN.evaluate, form);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getCustomerLogList: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      CAMPAIGN.getCustomerLogList + "?id=" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  exportCustomers: (searchForm, paging, callback, errorCallback) => {
    let s = {
      ...searchForm,
      paging: {
        ...paging,
      },
    };
    var req = requests.getSimplePostRequest(CAMPAIGN.exportCustomers, s);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  downloadCustomerImage: (accountId, objectId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      CAMPAIGN.downloadCustomerImage +
        "?accountId=" +
        accountId +
        "&objectId=" +
        encodeURI(objectId)
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  savePage: (page, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(CAMPAIGN.savePage, page);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  deletePage: (page, callback, errorCallback) => {
    let reqBody = {
      ...page,
    };

    let req = requests.getSimplePostRequest(CAMPAIGN.deletePage, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },

  questionMoveUp: (campaignId, id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      CAMPAIGN.questionMoveUp + "?campaignId=" + campaignId + "&id=" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  questionMoveDown: (campaignId, id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      CAMPAIGN.questionMoveDown + "?campaignId=" + campaignId + "&id=" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  changeJoinStatus: (campaignCustomerId, value, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      CAMPAIGN.changeJoinStatus +
        "?campaignCustomerId=" +
        campaignCustomerId +
        "&value=" +
        value
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
