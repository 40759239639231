import AccountDetailTutorial from "features/tutorial/pageTutorials/AccountDetailTutorial";
import AccountInteractionTutorial from "features/tutorial/pageTutorials/AccountInteractionTutorial";
import AccountNotificationTutorial from "features/tutorial/pageTutorials/AccountNotificationTutorial";
import AccountUserInvitationTutorial from "features/tutorial/pageTutorials/AccountUserInvitationTutorial";
import AccountUserTutorial from "features/tutorial/pageTutorials/AccountUserTutorial";
import CampaignDetailTutorial from "features/tutorial/pageTutorials/CampaignDetailTutorial";
import CouponDetailTutorial from "features/tutorial/pageTutorials/CouponDetailTutorial";
import CustomerInteractionDashboardTutorial from "features/tutorial/pageTutorials/CustomerInteractionDashboardTutorial";
import CustomerInteractionIndoorTutorial from "features/tutorial/pageTutorials/CustomerInteractionIndoorTutorial";
import CustomerInteractionsAll from "features/tutorial/pageTutorials/CustomerInteractionsAll";
import ExportDataTutorial from "features/tutorial/pageTutorials/ExportDataTutorial";
import HomepageTutorial from "features/tutorial/pageTutorials/HomepageTutorial";
import IssueDetailTutorial from "features/tutorial/pageTutorials/IssueDetailTutorial";
import CustomerInteractionsIssues from "features/tutorial/pageTutorials/IssuesTutorial";
import LikeDetailTutorial from "features/tutorial/pageTutorials/LikeDetailTutorial";
import CustomerInteractionsLikes from "features/tutorial/pageTutorials/LikeTutorial";
import LocationDetailTutorial from "features/tutorial/pageTutorials/LocationDetailTutorial";
import LocationTutorial from "features/tutorial/pageTutorials/LocationTutorial";
import MyThingFreeTutorial from "features/tutorial/pageTutorials/MyThingFreeTutorial";
import MyThingsBeginnerTutorial from "features/tutorial/pageTutorials/MyThingsBeginnerTutorial";
import ProfileTutorial from "features/tutorial/pageTutorials/ProfileTutorial";
import StorageStatTutorial from "features/tutorial/pageTutorials/StorageStatTutorial";
import SubscriptionsTutorial from "features/tutorial/pageTutorials/SubscriptionsTutorial";
import SuggestionDetailTutorial from "features/tutorial/pageTutorials/SuggestionDetailTutorial";
import CustomerInteractionsSuggestions from "features/tutorial/pageTutorials/SuggestionTutorial";
import ThingClassDetailTutorial from "features/tutorial/pageTutorials/ThingClassDetailTutorial";
import ThingClassTutorial from "features/tutorial/pageTutorials/ThingClassTutorial";
import ThingDetailTutorial from "features/tutorial/pageTutorials/ThingDetailTutorial";

// Database'de Help tablosunda 'custom' kolonu bu listedeki name'e karsilik geliyor.
// Detay sayfası tutoriallari listesidir.
export const tutorialRoutes = [
  {
    name: "homepage_tutorial",
    component: HomepageTutorial,
  },
  {
    name: "my_thing_free_tutorial",
    component: MyThingFreeTutorial,
  },
  {
    name: "my_things_beginner_tutorial",
    component: MyThingsBeginnerTutorial,
  },
  {
    name: "customer_interactions_all",
    component: CustomerInteractionsAll,
  },
  {
    name: "issues",
    component: CustomerInteractionsIssues,
  },
  {
    name: "issue_detail",
    component: IssueDetailTutorial,
  },
  {
    name: "likes",
    component: CustomerInteractionsLikes,
  },
  {
    name: "like_detail",
    component: LikeDetailTutorial,
  },
  {
    name: "suggestions",
    component: CustomerInteractionsSuggestions,
  },
  {
    name: "suggestion_detail",
    component: SuggestionDetailTutorial,
  },
  /****************************************** */
  {
    name: "account_detail",
    component: AccountDetailTutorial,
  },
  {
    name: "account_interaction",
    component: AccountInteractionTutorial,
  },
  {
    name: "account_notification",
    component: AccountNotificationTutorial,
  },
  {
    name: "account_users",
    component: AccountUserTutorial,
  },
  {
    name: "profile",
    component: ProfileTutorial,
  },
  {
    name: "storage_stat",
    component: StorageStatTutorial,
  },
  {
    name: "export_data",
    component: ExportDataTutorial,
  },
  {
    name: "thing_class_detail",
    component: ThingClassDetailTutorial,
  },
  {
    name: "thing_detail",
    component: ThingDetailTutorial,
  },
  {
    name: "coupon_detail",
    component: CouponDetailTutorial,
  },
  {
    name: "campaign_detail",
    component: CampaignDetailTutorial,
  },
  {
    name: "customer_interaction_dashboard",
    component: CustomerInteractionDashboardTutorial,
  },
  {
    name: "customer_interaction_indoor",
    component: CustomerInteractionIndoorTutorial,
  },
  {
    name: "account_user_invitation",
    component: AccountUserInvitationTutorial,
  },
  {
    name: "subscriptions",
    component: SubscriptionsTutorial,
  },
  {
    name: "thingClass",
    component: ThingClassTutorial,
  },
  {
    name: "location",
    component: LocationTutorial,
  },
  {
    name: "location_detail",
    component: LocationDetailTutorial,
  },
];
