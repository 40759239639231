import { IconButton } from "@material-ui/core";
import ThingsHelpIcon from "components/icons/ThingsHelpIcon";
import ThingsSteps from "features/tutorial/ThingsSteps";
import TutorialElement from "features/tutorial/TutorialElement";
import $ from "jquery";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function AccountPreferencesHelp(props) {
  const { value } = props;
  const { t } = useTranslation("help", { i18n });

  const classes = useStyles();
  const stepsRef = useRef();
  const [open, setOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            <b>Hesap Seçeneklerinin</b> güncellendiği alandır.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='ACCOUNT_PREFERENCES_BUTTON']",
        intro: (
          <TutorialElement icon="click">
            Hesap Seçeneklerini <b>güncellemek</b> için tıklayın.
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        intro: (
          <TutorialElement>
            <p>
              <ul>
                <li>
                  <b>Yetki Modeli </b>belirleyin.
                </li>
                <li>
                  <b>Etkileşim Noktası Tipi Veri Kaynakları </b>alanından
                  standart veri kaynaklarından birini (UniClass, Taşınır Mal)
                  seçin veya Thing ile devam edin.
                </li>
                <li>
                  <b>Servis/Operasyon boş kontrol listesi gönderebilme </b>
                  alanından Evet veya Hayır seçeneklerinden birini seçin.
                </li>
                <li>
                  <b>Servis/Operasyon Malzeme seçim türü </b>alanından
                  Servis/Operasyon görevinde kullanılan sarf malzemelerinin
                  girilmesi için Barkod veya Liste seçeneklerinden birini seçin.
                </li>
                <li>
                  <b>Etkileşim noktası silmeye izin ver </b> alanından Evet veya
                  Hayır seçeneklerinden birini seçin.
                </li>
                <li>
                  <b>Bildirim silmeye izin ver </b>alanından Evet veya Hayır
                  seçeneklerinden birini seçin.
                </li>
                <li>
                  <b>Varsayılan etkileşim noktası tipi </b>alanından varsayılan
                  tipi değiştirmek için tıklayın ve güncelleyin.
                </li>
                <li>
                  <b>Varsayılan İlişki Tipi </b>alanından{" "}
                  <b>
                    Bağlı, Etkileşim Noktası, İçerir, İçerilir, Sahibi, Parçası
                  </b>
                  seçeneklerinden birini seçin veya boş bırakın.
                </li>
                <li>
                  <b>Biricik Etkileşim Noktası Tanımları </b> alanından{" "}
                  <b>Etkin Değil, Zorla ve Uyar</b> seçeneklerinden birini seçin
                  veya boş bırakın.
                </li>
                <li>
                  <b>Müşteri İletişim İzni El İle Verilmesi</b> alanından{" "}
                  <b>
                    İzin Verme, İzin ver ve Müşterinin yazılı beyanının
                    yüklenmesi ile
                  </b>{" "}
                  seçeneklerinden birini seçin veya boş bırakın.
                </li>
                <li>
                  <b>Varlık Tanımlayıcı </b>alanından{" "}
                  <b>Sınıf İsmi, Varlık Kodu, Hashtag ve Tanım </b>
                  seçeneklerinden birini veya birkaçını seçin.
                </li>
              </ul>
              <b>Kaydet</b>’e basın.
            </p>
          </TutorialElement>
        ),
        tooltipClass: classes.accPrefHelpClass,
        onBeforeChange: (index) => {
          $("[data-fa-button='ACCOUNT_PREFERENCES_BUTTON']").click();
          stepsRef.current.updateStepElement(index);
        },
      },
    ]);
  }, []);

  return (
    <>
      {open && (
        <ThingsSteps
          code={value}
          steps={steps}
          ref={stepsRef}
          onStepChange={setStepIndex}
          afterComplete={() => {
            setOpen(true);
            var el = $("[aria-label='close']");
            if (el != null && el[0] != null) el[0].click();
          }}
        />
      )}
      <IconButton
        aria-label="help"
        onClick={() => setOpen(true)}
        title={t("HELP")}
      >
        <ThingsHelpIcon />
      </IconButton>
    </>
  );
}
AccountPreferencesHelp.propTypes = {
  value: PropTypes.string,
};
