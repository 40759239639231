import { Card, CardContent, CardHeader, Grid, Paper } from "@material-ui/core";
import { ResponsiveCalendar } from "@nivo/calendar";
import { default as React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CouponService } from "services/CouponService";
import { getDateWFormatEn } from "services/utils";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function CouponUsageHeatMap(props) {
  const { coupon } = props;
  const { t } = useTranslation("coupon", { i18n });
  const classes = useStyles();
  const accountId = localStorage.accountId;
  const [dailyCounts, setDailyCounts] = useState(null);

  useEffect(() => {
    CouponService.getDailyCounts(
      coupon.id,
      (data) => {
        console.log(data);
        setDailyCounts(data);
      },
      (error) => {}
    );
  }, []);

  return (
    <Card>
      <CardHeader
        title={t("Kullanım Durumu")}
        data-fa-section="Kullanım Durumu"
      ></CardHeader>
      <CardContent>
        <Grid item container spacing={1}>
          <Grid item lg={12} xs={12}>
            <Grid item>
              <Paper style={{ height: "10rem" }}>
                <div style={{ width: "100%", height: "10rem" }}>
                  {dailyCounts && (
                    <ResponsiveCalendar
                      data={dailyCounts?.list.map((item) => {
                        return {
                          value: item.value,
                          day: getDateWFormatEn(item.date),
                        };
                      })}
                      from={getDateWFormatEn(dailyCounts?.from)}
                      to={getDateWFormatEn(dailyCounts?.to)}
                      emptyColor="#eeeeee"
                      colors={["#61cdbb", "#97e3d5", "#e8c1a0", "#f47560"]}
                      margin={{ top: 0, right: 10, bottom: 0, left: 20 }}
                      yearSpacing={40}
                      monthBorderColor="#ffffff"
                      dayBorderWidth={2}
                      dayBorderColor="#ffffff"
                      legends={[
                        {
                          anchor: "bottom-right",
                          direction: "row",
                          translateY: 36,
                          itemCount: 4,
                          itemWidth: 42,
                          itemHeight: 36,
                          itemsSpacing: 14,
                          itemDirection: "right-to-left",
                        },
                      ]}
                      onClick={(day, event) => {
                        //onClick(day.day);
                      }}
                    />
                  )}
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
