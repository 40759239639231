import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { ACCOUNT } from "./faApi";

export const AccountService = {
  downloadLogo: (accountId, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(ACCOUNT.downloadLogo, accountId);

    FaAxios(req)
      .then((response) => {
        callback(response.data.content);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findUserAccounts: (userInfo, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(ACCOUNT.findUserAccounts, userInfo);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  exportData: (accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      ACCOUNT.exportData + "?accountId=" + accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getLastExportData: (accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      ACCOUNT.getLastExportData + "?accountId=" + accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  downloadExportData: (accountId, logId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      ACCOUNT.downloadExportData + "?accountId=" + accountId + "&logId=" + logId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  deleteExportData: (accountId, logId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      ACCOUNT.deleteExportData + "?accountId=" + accountId + "&logId=" + logId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(ACCOUNT.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (form, callback, errorCallback) => {
    let reqBody = {
      ...form,
    };

    let req = requests.getSimplePostRequest(ACCOUNT.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getSubscription: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(ACCOUNT.getSubscription + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  update: (form, callback, errorCallback) => {
    let reqBody = {
      ...form,
    };

    let req = requests.getSimplePostRequest(ACCOUNT.update, reqBody);

    FaAxios(req)
      .then((response) => {
        console.log("step1");
        callback(response);
      })
      .catch((error) => {
        console.log("step2", error);
        errorCallback(error?.response);
      });
    return () => {
      //setLoading(false);
    };
  },

  updateSocialMedia: (form, accountId, callback, errorCallback) => {
    let reqBody = {
      ...form,
    };

    let req = requests.getSimplePostRequest(
      ACCOUNT.updateSocialMedia + "/" + accountId,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        errorCallback(error?.response);
      });
    return () => {
      //setLoading(false);
    };
  },

  getExportDataSummary: (accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      ACCOUNT.getExportDataSummary + "/" + accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response);
      });
    return () => {
      //setLoading(false);
    };
  },
};
