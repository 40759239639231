import i18n from "i18n";
import React, { useEffect } from "react";
import useStyles from "react-base-fa/dist/fa/faUnAuthPages/style";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ReportService } from "services/report/reportService";

export default function PublicDocumentDownload(props) {
  const { value, publicThing, onClose } = props;
  const classes = useStyles();
  const { t } = useTranslation("report", { i18n });
  const history = useHistory();

  useEffect(() => {
    if (value != null) {
      downloadDocument();
    } else {
    }
  }, [value]);

  const downloadDocument = () => {
    ReportService.downloadDocument(
      publicThing.id,
      value.object,
      downloadDocumentOnSuccess,
      downloadDocuemntOnError
    );
  };

  const downloadDocumentOnSuccess = (data) => {
    const link = document.createElement("a");
    link.href = "data:application/octet-stream;base64," + data.content; //url;
    link.setAttribute("download", data.fileName);
    document.body.appendChild(link);
    link.click();
  };

  const downloadDocuemntOnError = (data) => {};

  const renderDocumentDialog = () => {
    return <></>;
  };

  return <>{renderDocumentDialog()}</>;
}
