import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { MESSAGE } from "./faApi";

export const MessagesService = {
  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(MESSAGE.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  markRead: (accountId, idList, callback, errorCallback) => {
    let reqBody = {
      accountId,
      idList,
    };

    let req = requests.getSimplePostRequest(MESSAGE.markRead, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  markUnread: (accountId, idList, callback, errorCallback) => {
    let reqBody = {
      accountId,
      idList,
    };

    let req = requests.getSimplePostRequest(MESSAGE.markUnread, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  markAllRead: (accountId, callback, errorCallback) => {
    let reqBody = {
      accountId,
    };

    let req = requests.getSimplePostRequest(MESSAGE.markAllRead, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
