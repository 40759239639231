import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { SCHEDULE_PLAN } from "./faApi";

export const SchedulePlanService = {
  save: (schedulePlan, callback, errorCallback) => {
    let reqBody = {
      ...schedulePlan,
    };

    let req = requests.getSimplePostRequest(SCHEDULE_PLAN.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (schedulePlan, callback, errorCallback) => {
    let reqBody = {
      ...schedulePlan,
    };

    let req = requests.getSimplePostRequest(SCHEDULE_PLAN.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findSchedulePlan: (accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      SCHEDULE_PLAN.findSchedulePlan + "?accountId=" + accountId + "&query="
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
