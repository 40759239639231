import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { QUEUE, QUEUE_TARGET, QUEUE_USER } from "./faApi";

export const QueueService = {
  save: (queue, callback, errorCallback) => {
    let reqBody = {
      ...queue,
    };

    let req = requests.getSimplePostRequest(QUEUE.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
  delete: (queue, callback, errorCallback) => {
    let reqBody = {
      ...queue,
    };

    let req = requests.getSimplePostRequest(QUEUE.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(QUEUE.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  active: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(QUEUE.active + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  passive: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(QUEUE.passive + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findTargets: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      QUEUE_TARGET.findTargets + "?queueId=" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  saveAllTargets: (targets, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      QUEUE_TARGET.saveAllTargets,
      targets
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  deleteTargets: (targets, callback, errorCallback) => {
    let reqUrl = "?";
    for (let i = 0; i < targets.length; i++) {
      reqUrl += "id=" + targets[i];
      if (i !== targets.length - 1) {
        reqUrl += "&";
      }
    }

    let reqBody = {
      ...targets,
    };
    let req = requests.getSimplePostRequest(
      QUEUE_TARGET.deleteTargets + reqUrl,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },

  findUsers: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      QUEUE_USER.findUsers + "?queueId=" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  saveUser: (queueUser, callback, errorCallback) => {
    let reqBody = {
      ...queueUser,
    };

    let req = requests.getSimplePostRequest(QUEUE_USER.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },

  deleteUser: (queueUser, callback, errorCallback) => {
    let reqBody = {
      ...queueUser,
    };

    let req = requests.getSimplePostRequest(QUEUE_USER.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findNextUser: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      QUEUE_USER.findNextUser + "?queueId=" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  moveUp: (accountId, rowId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      QUEUE.moveUp + "?accountId=" + accountId + "&id=" + rowId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  moveDown: (accountId, rowId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      QUEUE.moveDown + "?accountId=" + accountId + "&id=" + rowId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  changeOrder: (accountId, rowId, newOrder, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      QUEUE.changeOrder +
        "?accountId=" +
        accountId +
        "&rowId=" +
        rowId +
        "&newOrder=" +
        newOrder
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
