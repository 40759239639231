import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { SLA_CONTRACT_THING } from "./faApi";

export const SlaContractThingService = {
  saveAll: (thingList, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      SLA_CONTRACT_THING.saveAll,
      thingList
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (obj, callback, errorCallback) => {
    let reqBody = {
      ...obj,
    };

    let req = requests.getSimplePostRequest(SLA_CONTRACT_THING.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  findTargets: (id, callback, errorCallback) => {
    let searchForm = {
      paging: { page: 0, size: 10000, isAsc: false, sort: "createDate" },
      slaContractId: id,
      thingId: null,
      thingClassId: null,
    };
    let req = requests.getSimplePostRequest(
      SLA_CONTRACT_THING.search + "?slaContractId=" + id,
      searchForm
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  deleteTargets: (targets, callback, errorCallback) => {
    let reqUrl = "?";
    for (let i = 0; i < targets.length; i++) {
      reqUrl += "id=" + targets[i];
      if (i !== targets.length - 1) {
        reqUrl += "&";
      }
    }

    let reqBody = {
      ...targets,
    };
    let req = requests.getSimplePostRequest(
      SLA_CONTRACT_THING.deleteTargets + reqUrl,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
};
