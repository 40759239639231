import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import i18n from "../../../i18n";
import ThingsSteps from "../ThingsSteps";
import TutorialElement from "../TutorialElement";

export default function CouponDetailTutorial() {
  const { code } = useParams();

  const stepsRef = useRef();

  const { t } = useTranslation("tutorialCouponDetail", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement
            header="Merhaba!"
            icon="tutorial"
            text={t("Kupon detayı kılavuzuna hoş geldiniz.")}
          />
        ),
      },
      {
        intro: (
          <TutorialElement icon="info">
            <p>
              Kupon üzerinde değişiklik yapabilmeniz için kuponun durumu{" "}
              <b>pasif</b> olmalıdır
            </p>
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='COUPON_PRODUCTS_EDIT']",
        intro: (
          <TutorialElement icon="info">
            <div>
              <p>
                {t(
                  "Kuponun geçerli olduğu ürün ve etkileşim noktalarını belirlemek için tıklayın."
                )}
              </p>
            </div>
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-section='COUPON_PRODUCT_DIALOG']",
        intro: (
          <TutorialElement icon="click">
            {t("Etkileşim Noktası seçin ve Kaydet’e basın.")}
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          $("[data-fa-button='COUPON_PRODUCTS_EDIT']")[0].click();
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "[data-fa-button='COUPON_SCOPE_EDIT']",
        intro: (
          <TutorialElement icon="click">
            {t("Müşteri ve Müşteri Listesi eklemek için tıklayın.")}
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          $("[aria-label='close']")[0].click();
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "[data-fa-section='COUPON_SCOPE_DIALOG']",
        intro: (
          <TutorialElement icon="click">
            {t("Müşteri ve Müşteri Listesi seçin ve Kaydet’e basın.")}
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          $("[data-fa-button='COUPON_SCOPE_EDIT']")[0].click();
        },
      },
      {
        element: "[data-fa-button='ASSIGN_COUPONS']",
        intro: (
          <TutorialElement icon="click">
            {t("Seçtiğiniz Müşterilere Kupon tanımlamak için tıklayın.")}
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          $("[aria-label='close']")[0].click();
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "[data-fa-button='SEND_COUPONS']",
        intro: (
          <TutorialElement icon="click">
            {t("Kupon Gönder’e tıklayın.")}
          </TutorialElement>
        ),
      },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={"coupon_detail"}
        steps={steps}
        ref={stepsRef}
        onStepChange={setStepIndex}
      />
    </div>
  );
}
