import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import i18n from "i18n";
import PropTypes from "prop-types";
import { default as React } from "react";
import { useTranslation } from "react-i18next";
import { labelStyles } from "styles/ThingsLabelStyle";

export default function OptionsTable(props) {
  const { question, questionAnalysis } = props;

  const labelClasses = labelStyles();
  const { t } = useTranslation("survey", { i18n });

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Seçenek</TableCell>
            <TableCell>%</TableCell>
            <TableCell>#</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {questionAnalysis.detailList.map((item, index) => (
            <TableRow key={index}>
              <TableCell className={labelClasses.LabelValue}>
                {item.surveyQuestionOptionId
                  ? question.options.find(
                      (x) => x.id === item.surveyQuestionOptionId
                    )?.multiLangName["tr"]
                  : "Boş"}
              </TableCell>
              <TableCell className={labelClasses.LabelValue}>
                {"%" + item.percentage}
              </TableCell>
              <TableCell className={labelClasses.LabelValue}>
                {item.count}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={2}>Toplam</TableCell>
            <TableCell>
              {questionAnalysis.detailList.reduce((accumulator, item) => {
                return accumulator + item.count;
              }, 0)}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

OptionsTable.propTypes = {
  question: PropTypes.any,
  questionAnalysis: PropTypes.any,
};
