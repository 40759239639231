import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import { THINGS_ENVIRONMENT } from "environments/Environments";

export const FingerPrintService = {
  getVisitorId: async (callback, errorCallback) => {
    if (!!!THINGS_ENVIRONMENT.fingerprint) {
      callback(null);
      return;
    }

    if (localStorage.getItem("visitorId")) {
      callback(localStorage.getItem("visitorId"));
    } else {
      const fpPromise = FingerprintJS.load({
        apiKey: "NjwVDcm4A5cvw3zQHoX6",
        region: "eu",
        endpoint: "https://metrics.thngs.app",
      });

      try {
        const fp = await fpPromise;
        const result = await fp.get({ extendedResult: true });
        if (result && result.visitorId) {
          localStorage.setItem("visitorId", result.visitorId);
          if (
            result.ipLocation &&
            result.ipLocation.latitude &&
            result.ipLocation.longitude
          ) {
            localStorage.setItem(
              "location2",
              '{"lat":' +
                result.ipLocation.latitude +
                ',"lng":' +
                result.ipLocation.longitude +
                "}"
            );
          }
          callback(result.visitorId);
        } else {
          errorCallback(null);
        }
      } catch (error) {
        console.error("FPJS error:", error);
        switch (error.message) {
          case FingerprintJS.ERROR_GENERAL_SERVER_FAILURE:
            console.log("Unknown server error. Request id:", error.requestId);
            break;
          case FingerprintJS.ERROR_CLIENT_TIMEOUT:
            console.log("Identification time limit of 10 seconds is exceeded");
            break;
          default:
            console.log("Other error");
        }

        errorCallback(error);
      }
    }
  },
};
