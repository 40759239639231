import { IconButton } from "@material-ui/core";
import ThingsHelpIcon from "components/icons/ThingsHelpIcon";
import ThingsSteps from "features/tutorial/ThingsSteps";
import TutorialElement from "features/tutorial/TutorialElement";
import $ from "jquery";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

export default function ThingsDocumentsHelp(props) {
  const { value } = props;
  const { t } = useTranslation("help", { i18n });

  const stepsRef = useRef();
  const [open, setOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            Etkileşim Noktanız ile ilgili paylaşmak istediğiniz{" "}
            <b>İçerik, Bilgi ve Belgelerin</b> yüklendiği alandır.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='FILES_ADD']",
        intro: (
          <TutorialElement icon="click">
            <b>Dosya </b> eklemek için tıklayın.
          </TutorialElement>
        ),
      },

      {
        element: "[data-fa-section='FILES_EDIT_DIALOG']",
        intro: (
          <TutorialElement icon="info">
            <b>Tanım</b> ve <b>Açıklama</b> bilgilerini girin.
            <br /> <br />
            <b>Erişim Türü</b> olarak “Herkes”, “Gizli” ve “Sadece Görevliler”
            seçeneklerinden birini seçin.
            <br /> <br />
            Belgeyi yükleyin ve <b>Kaydet</b>’e basın.
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          $("[data-fa-button='FILES_ADD']")[0].click();
          stepsRef.current.updateStepElement(index);
        },
      },
    ]);
  }, []);

  return (
    <>
      {open && (
        <ThingsSteps
          code={value}
          steps={steps}
          ref={stepsRef}
          onStepChange={setStepIndex}
          afterComplete={() => {
            setOpen(true);
            var el = $("[aria-label='close']");
            if (el != null && el[0] != null) el[0].click();
          }}
        />
      )}
      <IconButton
        aria-label="help"
        onClick={() => setOpen(true)}
        title={t("HELP")}
      >
        <ThingsHelpIcon />
      </IconButton>
    </>
  );
}

ThingsDocumentsHelp.propTypes = {
  value: PropTypes.string,
};
