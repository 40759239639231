import { Avatar, Grid, Typography } from "@material-ui/core";
import Adsense from "components/adsense/Adsense";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import CustomerInteractionOperationButtonGroup from "components/buttonGroup/CustomerInteractionOperationButtonGroup";
import DateButtonGroup from "components/buttonGroup/DateButtonGroup";
import DateRangeSelector from "components/dateRangeSelector";
import CustomerIneractionOperationSelect from "components/select/CustomerIneractionOperationSelect";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaSearch } from "react-base-fa/dist/fa";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { calculateDate, getDateTimeWFormat, isTNF } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { CUSTOMER_INTERACTION } from "../../services/faApi";

export default function CustomerInteractions() {
  const tableRef = useRef();
  const { t } = useTranslation(["customerInteraction", "components"], { i18n });
  let history = useHistory();
  const accountId = localStorage.accountId;
  const labelClasses = labelStyles();

  const columns = [
    {
      field: "date",
      label: t("DATE"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        return getDateTimeWFormat(value);
      },
    },
    {
      field: "identifier",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      width: "30%",
      valueFormatter: (value, row) => {
        if (row.thumbnail != null)
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                alt="thing"
                src={`data:image/png;base64, ${row.thumbnail}`}
                style={{
                  width: "48px",
                  height: "48px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              />{" "}
              {value}
            </div>
          );
        else
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="square"
                style={{
                  width: "48px",
                  height: "48px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              >
                {" "}
                {value.charAt(0)}
              </Avatar>{" "}
              {value}
            </div>
          );
      },
    },
    {
      field: "operation",
      label: t("OPERATION"),
      align: "center",
      width: "20%",
      valueFormatter: (value, row) => {
        let returnValue = t("CUSTOMER_INTERACTION_OPERATION." + value, {
          ns: "components",
        });
        if (value === "SOCIAL_MEDIA") {
          returnValue = returnValue + " - " + row?.socialMediaType;
        }
        return returnValue;
      },
    },
    {
      field: "detail",
      label: t("İşlem Detay"),
      align: "center",
      width: "10%",
    },
    {
      field: "adrCity",
      label: t("Şehir"),
      align: "center",
      width: "10%",
    },
  ];

  const newSearchForm = () => {
    let result = {
      accountId: accountId,
      thing: null,
      thingId: null,
      operation: null,
      operationList: null,
      operationDateValue: null,
      operationDateStart: null,
      operationDateEnd: null,
      limit: 20, //chartLimit
    };

    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    if (row.operation === "ISSUE_REPORT") {
      const tmpTo = {
        pathname: `issues/detail/${row.operationId}`,
        breadCrumbKey: `/issues/detail/:id`,
      };
      history.push(tmpTo);
    } else if (row.operation === "LIKE") {
      const tmpTo = {
        pathname: `like/detail/${row.operationId}`,
        breadCrumbKey: `/like/detail/:id`,
      };
      history.push(tmpTo);
    } else if (row.operation === "SUGGESTION") {
      const tmpTo = {
        pathname: `suggestions/detail/${row.operationId}`,
        breadCrumbKey: `/suggestions/detail/:id`,
      };

      history.push(tmpTo);
    } else if (row.operation === "USER_CONTENT") {
      const tmpTo = {
        pathname: `userContent/detail/${row.operationId}`,
        breadCrumbKey: `/userContent/detail/:id`,
      };
      history.push(tmpTo);
    } else if (row.operation === "SURVEY") {
      navigateToThing(row.thingId);
    } else if (row.operation === "CAMPAIGN") {
      navigateToThing(row.thingId);
    } else if (row.operation === "CUSTOMER") {
      navigateToThing(row.thingId);
    } else if (row.operation === "SCAN_QR") {
      navigateToAccessLog(row.operationId);
    } else if (row.operation === "PUBLIC_INFO") {
      navigateToThing(row.thingId);
    } else if (row.operation === "DOWNLOAD_DOCUMENT") {
      navigateToThing(row.thingId);
    } else if (row.operation === "DOWNLOAD_IMAGE") {
      navigateToThing(row.thingId);
    } else if (row.operation === "THING_LINK") {
      navigateToThing(row.thingId);
    } else if (row.operation === "SOCIAL_MEDIA") {
      navigateToThing(row.thingId);
    }
  };

  const navigateToThing = (thingId) => {
    const tmpTo = {
      pathname: `thingDetail/${thingId}`,
      breadCrumbKey: `/thingDetail/:id`,
    };
    history.push(tmpTo);
  };

  const navigateToAccessLog = (id) => {
    const tmpTo = {
      pathname: `accessLog/detail/${id}`,
      breadCrumbKey: `/accessLog/detail/:id`,
    };
    history.push(tmpTo);
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid item container spacing={1} style={{ marginTop: 5 }}>
          <Grid item xs={2}></Grid>
          <Grid item xs={4}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <ThingAutoComplete
                data-fa-input="FILTER_THING"
                accountId={accountId}
                value={searchForm.thing}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "thing",
                    data,
                    "thingId",
                    data?.id
                  );
                }}
              />

              <CustomerIneractionOperationSelect
                value={searchForm.operation}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    operation: data,
                  });
                }}
              />

              <DateRangeSelector
                label={t("DATE")}
                value={{
                  id: searchForm.operationDateId,
                  startDate: searchForm.operationDateStart,
                  endDate: searchForm.operationDateEnd,
                }}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    operationDateStart: data.startDate,
                    operationDateEnd: data.endDate,
                    operationDateId: data.id,
                  });
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>

        <Grid
          item
          container
          spacing={1}
          style={{ marginBottom: 5, overflowY: "auto" }}
        >
          <Grid item lg={2} xs={12} id="dateSpeedFilter">
            <Grid item xs={12} className={labelClasses.LabelLastRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("DATE")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <DateButtonGroup
                  data-fa-section="DATE_BUTTON_GROUP"
                  value={searchForm.operationDateValue}
                  onChange={(data) => {
                    const temp = {
                      ...searchForm,
                      operationDateValue: data,
                      operationDateStart: calculateDate(data),
                    };

                    setSearchForm(temp);
                    tableRef.current.search(temp);
                  }}
                  style={{ height: 60 }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={10} id="operationSpeedFilter">
            <Grid item xs={12} className={labelClasses.LabelLastRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("OPERATION")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomerInteractionOperationButtonGroup
                data-fa-section="CUSTOMER_INTEREACTION_OPERATION_BUTTON_GROUP"
                value={searchForm.operation}
                onChange={(data) => {
                  const temp = { ...searchForm, operation: data };
                  setSearchForm(temp);
                  tableRef.current.search(temp);
                }}
                style={{ height: 60 }}
              />
            </Grid>
          </Grid>
        </Grid>

        <ThingsDataTable
          searchUrl={CUSTOMER_INTERACTION.search}
          exportUrl={CUSTOMER_INTERACTION.export}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
          queryParams={{
            page: 0,
            size: 10,
            isAsc: false,
            sort: "date",
            accountId: localStorage.accountId,
          }}
        />

        <Adsense value="RklmAlt" />
      </Suspense>
    </>
  );
}
