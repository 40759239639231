import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { COUPON } from "./faApi";

export const CouponService = {
  save: (coupon, callback, errorCallback) => {
    let reqBody = {
      ...coupon,
    };

    let req = requests.getSimplePostRequest(COUPON.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(COUPON.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (coupon, callback, errorCallback) => {
    let reqBody = {
      ...coupon,
    };
    let req = requests.getSimplePostRequest(COUPON.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  changeStatus: (id, status, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      COUPON.changeStatus + "/" + id + "?status=" + status
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  active: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(COUPON.active + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  passive: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(COUPON.passive + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  saveTargets: (targets, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(COUPON.saveTargets, targets);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  deleteTargets: (targets, callback, errorCallback) => {
    let reqUrl = "?";
    for (let i = 0; i < targets.length; i++) {
      reqUrl += "id=" + targets[i];
      if (i !== targets.length - 1) {
        reqUrl += "&";
      }
    }

    let reqBody = {
      ...targets,
    };
    let req = requests.getSimplePostRequest(
      COUPON.deleteTargets + reqUrl,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },

  findTargets: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      COUPON.findTargets + "?couponId=" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  saveProducts: (products, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(COUPON.saveProducts, products);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  deleteProducts: (products, callback, errorCallback) => {
    let reqUrl = "?";
    for (let i = 0; i < products.length; i++) {
      reqUrl += "id=" + products[i];
      if (i !== products.length - 1) {
        reqUrl += "&";
      }
    }

    let reqBody = {
      ...products,
    };
    let req = requests.getSimplePostRequest(
      COUPON.deleteProducts + reqUrl,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },

  findProducts: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      COUPON.findProducts + "?couponId=" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  createCoupons: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(COUPON.createCoupons + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  sendCoupons: (id, channel, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      COUPON.sendCoupons + "/" + id + "?messageChannel=" + channel
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  checkCouponCode: (couponCode, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      COUPON.checkCouponCode + "/" + couponCode
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  sendValidationCode: (couponId, channel, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      COUPON.sendValidationCode + "/" + couponId + "?channel=" + channel
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  checkValidationCode: (couponId, code, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      COUPON.checkValidationCode + "/" + couponId + "?valCode=" + code
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  stat: (couponId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(COUPON.stat + "/" + couponId);
    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getDailyCounts: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(COUPON.getDailyCounts + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
