import { Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { FaAutoComplete } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { THING } from "../../services/faApi";
import { ThingDesc, isTNF } from "../../services/utils";

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function ThingAutoComplete(props) {
  const { value, accountId, onChange, required, multiple, ...other } = props;

  const { t } = useTranslation("components", { i18n });
  const classes = useStyles();

  return (
    <FaAutoComplete
      faType="async"
      value={value}
      getOptionSelected={(option, value) => {
        return option.id === value.id;
      }}
      sort="description"
      faFilterProp="description"
      getOptionLabel={(option) => ThingDesc(option)}
      requestUrl={THING.findDescription + "?accountId=" + accountId}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      label={isTNF(t("THING"), t("THING_T2C"))}
      required={required}
      multiple={multiple}
      // classes={{
      //   option: classes.option,
      // }}
      renderOption={(option) => (
        <React.Fragment>
          {option.thumbnail != null && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                alt="thing"
                src={`data:image/png;base64, ${option.thumbnail}`}
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              />{" "}
              {ThingDesc(option)}
            </div>
          )}
          {option.thumbnail == null && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="square"
                style={{ width: "20px", height: "20px", marginRight: "10px" }}
              >
                {" "}
                {option.identifier.charAt(0)}
              </Avatar>
            </div>
          )}
          <span
            style={{
              fontSize: "14px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
            title={ThingDesc(option)}
          >
            {ThingDesc(option)}
          </span>
          {/* {ThingDesc(option)} */}
        </React.Fragment>
      )}
      {...other}
    />
  );
}
