import { green } from '@material-ui/core/colors';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React, { useRef, useState } from "react";
import { FaButton } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function ThingPhotosCarousel(props) {
  const { images, uploadImages, deleteImage, setDefaultImage } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const { t } = useTranslation("thingDetail", { i18n });
  const fileInputRef = useRef();

  const handleChange = (event) => {
    event.preventDefault();
    if (event?.target?.files?.length > 0) {
      uploadImages(event.target.files);
    }
  };

  const createCarouselItemImage = (item, index) => (
    <div key={index} className={classes.image}>
      <img src={`data:image/png;base64, ${item.content}`} />
      {item.defaultContent === true && (<CheckCircleIcon style={{ color: green[500], zIndex: 1 }} className={classes.defaultImage} />)}
      {item.defaultContent !== true && (
        <div className={classes.defaultImage}>
          <FaButton
            label={t("Varsayılan Yap")}
            size="small"
            onClick={() => {
              setDefaultImage(item.object);
            }}
          />
        </div>
      )}
    </div>
  );

  const baseChildren = <div>{images.map(createCarouselItemImage)}</div>;

  return <Carousel showArrows={true}>{baseChildren.props.children}</Carousel>;
}
