import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { SLA_CONTRACT } from "./faApi";

export const SlaContractService = {
  save: (location, callback, errorCallback) => {
    let reqBody = {
      ...location,
    };

    let req = requests.getSimplePostRequest(SLA_CONTRACT.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (sla, callback, errorCallback) => {
    let reqBody = {
      ...sla,
    };

    let req = requests.getSimplePostRequest(SLA_CONTRACT.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(SLA_CONTRACT.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  monitor: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(SLA_CONTRACT.monitor, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findContractsByThing: (accountId, thingId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      SLA_CONTRACT.findContractsByThing +
        "?accountId=" +
        accountId +
        "&thingId=" +
        thingId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findContractsByThingTask: (accountId, thingId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      SLA_CONTRACT.findContractsByThingTask +
        "?accountId=" +
        accountId +
        "&thingId=" +
        thingId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
