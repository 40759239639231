import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { PATROL_DEF } from "./faApi";

export const PatrolDefService = {
  save: (patrolDef, callback, errorCallback) => {
    let reqBody = {
      ...patrolDef,
    };

    let req = requests.getSimplePostRequest(PATROL_DEF.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(PATROL_DEF.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  activation: (id, startNow, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      PATROL_DEF.activation + "/" + id + "?startNow=" + startNow
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  passivation: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(PATROL_DEF.passivation + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findNextDate: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(PATROL_DEF.findNextDate + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getCounts: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(PATROL_DEF.getCounts + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
