import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { JOURNAL_TYPE } from "./faApi";

export const JournalTypeService = {
  save: (journalType, callback, errorCallback) => {
    let reqBody = {
      ...journalType,
    };

    let req = requests.getSimplePostRequest(JOURNAL_TYPE.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (journalType, callback, errorCallback) => {
    let reqBody = {
      ...journalType,
    };

    let req = requests.getSimplePostRequest(JOURNAL_TYPE.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findByName: (accountId, name, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      JOURNAL_TYPE.findByName +
        "?accountId=" +
        accountId +
        "&name=" +
        encodeURI(name)
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
