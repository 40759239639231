import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Link,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useEffect, useState } from "react";
import { FaDatePicker, FaFileUpload, FaInput } from "react-base-fa/dist/fa";
import { ThingService } from "services/thing/thingService";
import { useStyles } from "./style";

export default function DynamicFieldsEdit(props) {
  const { details, data, selectedDetails, onValuesChange } = props;
  const classes = useStyles();
  const [values, setValues] = useState([]);

  const findDataByDynamicFormId = (dynamicFormId) => {
    var result = data.find((obj) => {
      return obj.dynamicFormId === dynamicFormId;
    });
    if (!result) {
      result = {
        dynamicFormId: dynamicFormId,
        dataString: "",
        dataNumeric: "",
        dataDate: null,
        dataBool: false,
      };
    }
    return result;
  };

  useEffect(() => {
    let detailData = [];
    selectedDetails.map((detail) => {
      let fieldData = findDataByDynamicFormId(detail.id);
      detailData.push(fieldData);
    });
    setValues(detailData);
  }, [details, data, selectedDetails]);

  const handleChangeData = (index, value, type) => {
    let tempValues = [...values];
    tempValues[index] = { ...tempValues[index], [type]: value };
    setValues(tempValues);
    onValuesChange(tempValues);
  };

  const downloadDocument = (fileId) => {
    ThingService.downloadDocument(
      props.objectId,
      fileId,
      downloadDocumentOnSuccess,
      downloadDocuemntOnError
    );
  };

  const downloadDocumentOnSuccess = (data) => {
    //const url = window.URL.createObjectURL(new Blob([data.content]));
    const link = document.createElement("a");
    link.href = "data:application/octet-stream;base64," + data.content; //url;
    link.setAttribute("download", data.fileName);
    document.body.appendChild(link);
    link.click();
  };

  const downloadDocuemntOnError = (data) => {};

  const renderField = (field, index) => {
    if (field.dataType === "STRING") {
      return (
        <FaInput
          required={field.notEmpty}
          id={field.label}
          value={values[index]?.dataString ? values[index].dataString : ""}
          onChange={(event) => {
            handleChangeData(index, event.target.value, "dataString");
          }}
          name={field.label}
          placeholder={field.hint}
          label={field.label}
        />
      );
    } else if (field.dataType === "NUMERIC") {
      return (
        <FaInput
          required={field.notEmpty}
          id={field.label}
          faType={"number"}
          value={
            values[index]?.dataNumeric || values[index]?.dataNumeric === 0
              ? values[index].dataNumeric
              : ""
          }
          onChange={(event) => {
            handleChangeData(index, event.target.value, "dataNumeric");
          }}
          name={field.label}
          min={field.min}
          max={field.max}
          placeholder={field.hint}
          label={field.label}
        />
      );
    } else if (field.dataType === "MONEY") {
      return (
        <FaInput
          required={field.notEmpty}
          id={field.label}
          faType={"number"}
          awnum="decimal-6"
          value={
            values[index]?.dataNumeric || values[index]?.dataNumeric === 0
              ? values[index].dataNumeric
              : ""
          }
          onChange={(event) => {
            handleChangeData(index, event.target.value, "dataNumeric");
          }}
          name={field.label}
          min={field.min}
          max={field.max}
          placeholder={field.hint}
          label={field.label}
        />
      );
    } else if (field.dataType === "DATE" || field.dataType === "DATE_TIME") {
      let minDate = null;
      let maxDate = null;
      if (field.future === true && field.past === true) {
      } else if (field.future === true) {
        minDate = new Date();
      } else if (field.past === true) {
        maxDate = new Date();
      }

      return (
        <FaDatePicker
          faType={field.dataType === "DATE_TIME" ? "dateTime" : "date"}
          required={field.notEmpty}
          value={values[index]?.dataDate ? values[index].dataDate : null}
          faOnChange={(val) => {
            handleChangeData(index, val, "dataDate");
          }}
          minDate={minDate == null ? undefined : minDate}
          maxDate={maxDate == null ? undefined : maxDate}
          placeholder={field.hint}
          label={field.label}
        />
      );
    } else if (field.dataType === "TIME") {
      return (
        <FaDatePicker
          faType="time"
          required={field.notEmpty}
          value={values[index]?.dataDate ? values[index].dataDate : null}
          faOnChange={(val) => {
            handleChangeData(index, val, "dataDate");
          }}
          placeholder={field.hint}
          label={field.label}
        />
      );
    } else if (field.dataType === "TEXT") {
      return (
        <FaInput
          required={field.notEmpty}
          id={field.label}
          multiline
          minRows={4}
          value={values[index]?.dataString ? values[index].dataString : ""}
          onChange={(event) => {
            handleChangeData(index, event.target.value, "dataString");
          }}
          name={field.label}
          placeholder={field.hint}
          label={field.label}
        />
      );
    } else if (field.dataType === "BOOLEAN") {
      return (
        <FormControlLabel
          control={
            <Checkbox
              checked={!!values[index]?.dataBool}
              onChange={(event) => {
                handleChangeData(index, event.target.checked, "dataBool");
              }}
              name="dataBool"
              color="primary"
              label={field.label}
            />
          }
          label={field.label}
        />
      );
    } else if (field.dataType === "YES_NO") {
      return (
        <FormControl component="fieldset">
          <FormLabel component="legend">{field.label}</FormLabel>
          <RadioGroup
            row
            aria-label="position"
            value={"" + values[index]?.dataBool}
            label={field.label}
            onChange={(event) => {
              handleChangeData(
                index,
                event.target.value === "true",
                "dataBool"
              );
            }}
          >
            <FormControlLabel
              value={"true"}
              control={<Radio color="primary" />}
              label={field.yesLabel}
            />
            <FormControlLabel
              value={"false"}
              control={<Radio color="primary" />}
              label={field.noLabel}
            />
          </RadioGroup>
        </FormControl>
      );
    } else if (
      field.dataType === "ENUMS" &&
      (field.enumsMultiple == null || field.enumsMultiple === false)
    ) {
      return (
        <FormControl
          required={field.notEmpty}
          variant="outlined"
          className={classes.w100}
          size="small"
        >
          <InputLabel id="demo-simple-select-required-label">
            {field.label}
          </InputLabel>
          <Select
            id="form-select"
            value={values[index]?.dataString ? values[index].dataString : ""}
            onChange={(event) => {
              handleChangeData(index, event.target.value, "dataString");
            }}
            label={field.label}
            labelId="demo-simple-select-required-label"
          >
            {field.options.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else if (field.dataType === "ENUMS" && field.enumsMultiple === true) {
      return (
        <FormControl
          required={field.notEmpty}
          variant="outlined"
          className={classes.w100}
          size="small"
        >
          <InputLabel id="demo-simple-select-required-label">
            {field.label}
          </InputLabel>
          <Select
            id="form-select"
            value={
              values[index]?.dataString
                ? values[index].dataString.split(";")
                : []
            }
            multiple
            onChange={(event) => {
              let newValue = event.target.value.join(";");
              let tempValues = [...values];
              tempValues[index] = {
                ...tempValues[index],
                dataString: newValue,
              };
              setValues(tempValues);
              onValuesChange(tempValues);
            }}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip key={value} label={value} className={classes.chip} />
                ))}
              </div>
            )}
            label={field.label}
            labelId="demo-simple-select-required-label"
          >
            {field.options.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else if (field.dataType === "JSON") {
      return (
        <FaInput
          required={field.notEmpty}
          id={field.label}
          multiline
          minRows={4}
          value={values[index]?.dataString ? values[index].dataString : ""}
          onChange={(event) => {
            handleChangeData(index, event.target.value, "dataString");
          }}
          name={field.label}
          placeholder={field.hint}
          label={field.label}
        />
      );
    } else if (field.dataType === "FILE") {
      if (values[index]?.dataFile) {
        return (
          <Grid item container spacing={0}>
            <Grid item xs={2}>
              <Typography
                style={{ paddingLeft: 10, color: "rgba(0, 0, 0, 0.54)" }}
              >
                {field.label}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Link
                style={{ textDecoration: "underline" }}
                onClick={() => {
                  downloadDocument(values[index]?.dataFile);
                }}
              >
                {values[index].dataString}
              </Link>
            </Grid>
            <Grid item xs={2}>
              <Button
                size="small"
                startIcon={<FontAwesomeIcon icon={faTrash} />}
                onClick={() => {
                  let tempValues = [...values];
                  tempValues[index] = {
                    ...tempValues[index],
                    dataString: null,
                    dataFile: null,
                  };
                  setValues(tempValues);
                  onValuesChange(tempValues);
                }}
                title="Sil"
              ></Button>
            </Grid>
          </Grid>
        );
      } else {
        return (
          <>
            <Typography
              style={{ paddingLeft: 10, color: "rgba(0, 0, 0, 0.54)" }}
            >
              {field.label}
            </Typography>
            <FaFileUpload
              maxFileSize={
                field.maxFileSize == null
                  ? 20000000
                  : field.maxFileSize * 1000000
              }
              acceptedFiles={
                field.fileTypes == null ? [""] : field.fileTypes.split(",")
              }
              onChange={(data) => {
                if (data != null && data.length > 0) {
                  let tempValues = [...values];
                  tempValues[index] = {
                    ...tempValues[index],
                    dataString: data[0].file.name,
                    dataFile: data[0],
                  };
                  setValues(tempValues);
                  onValuesChange(tempValues);
                } else handleChangeData(index, null, "dataFile");
              }}
              filesLimit={1}
              dropZoneBoxProps={{ style: { minHeight: "50px" } }}
            />
          </>
        );
      }
    } else if (field.dataType === "LINK") {
      return (
        <FaInput
          required={field.notEmpty}
          id={field.label}
          value={values[index]?.dataString ? values[index].dataString : ""}
          onChange={(event) => {
            handleChangeData(index, event.target.value, "dataString");
          }}
          name={field.label}
          placeholder={field.hint}
          label={field.label}
        />
      );
    }
  };

  const renderDetails = selectedDetails.map((item, index) => (
    <Grid key={index} item xs={12}>
      {renderField(item, index)}
    </Grid>
  ));

  return <>{renderDetails}</>;
}
