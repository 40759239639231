import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { PATROL } from "./faApi";

export const PatrolService = {
  findDetailsByPatrol: (patrolId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      PATROL.findDetailsByPatrol + "?patrolId=" + patrolId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data.list);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
