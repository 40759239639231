import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
} from "@material-ui/core";
import QueueAutoComplete from "components/autocomplete/QueueAutoComplete";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import UserAndGroupAutoComplete from "components/autocomplete/UserAndGroupAutoComplete";
import SlaContractLevelRadio from "components/radio/SlaContractLevelRadio";
import ThingsSwitch from "components/ui/ThingsSwitch";
import React, { forwardRef, useEffect, useState } from "react";
import { FaDatePicker, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getRole } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SlaContractService } from "services/SlaContractService";
import { TaskService } from "services/TaskService";
import { GetTaskEndDate } from "services/utils";
import { IsFeedbackInfoExists } from "services/utils/IssueUtils";
import { ROLES } from "services/utils/Roles";
import { checkRole } from "services/utils/SecurityUtils";
import i18n from "../../i18n";

const TaskEdit = forwardRef((props, ref) => {
  const {
    onChange,
    onClose,
    value,
    open,
    accPreferences,
    issue,
    reassign,
    parentId,
  } = props;

  const accountId = localStorage.accountId;

  const role = useSelector(getRole);
  const isSLA = checkRole([ROLES.SLA], role);

  const { t } = useTranslation("issue", { i18n });
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [loading, setLoading] = useState(false);
  const [openFeedbackAssignDialog, setOpenFeedbackAssignDialog] =
    useState(false);
  const [isQueue, setIsQueue] = useState(false);

  const [slaContracts, setSlaContracts] = useState(null);
  const [selectedQueue, setSelectedQueue] = useState();

  useEffect(() => {
    if (issue && issue.account && issue.thing) {
      SlaContractService.findContractsByThingTask(
        issue.account.id,
        issue.thing.id,
        (data) => {
          setSlaContracts(data);
        },
        (error) => {
          setSlaContracts(null);
        }
      );
    }
  }, [issue]);

  const newTask = () => {
    return {
      taskDate: GetTaskEndDate(),
      description: "",
      user: null,
      userGroup: null,
      issue: null,
      thing: null,
      startSla: false,
      slaContractLevel: null,
    };
  };
  const [taskDTO, setTaskDTO] = useState(newTask());

  useEffect(() => {
    if (open) {
      setTaskDTO(value == null ? newTask() : { ...value });
    }
  }, [open]);

  const handleChange = (prop) => (event) => {
    setTaskDTO({ ...taskDTO, [prop]: event.target.value });
  };

  const handleChangeV = (prop, value) => {
    setTaskDTO({ ...taskDTO, [prop]: value });
  };

  const saveSuccess = (data) => {
    showAlert(!taskDTO.id ? t("TASK_SAVED") : t("TASK_EDITED"), "success");
    setTaskDTO(newTask());
    setLoading(false);
    onChange(data);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const saveTask = () => {
    if (accPreferences && accPreferences.feedbackTaskAssign === "ACCEPT")
      saveTaskRequest(true);
    else if (
      (accPreferences && accPreferences.feedbackTaskAssign === "NEVER") ||
      (issue && !IsFeedbackInfoExists(issue))
    )
      saveTaskRequest(false);
    else {
      setOpenFeedbackAssignDialog(true);
    }
  };

  const saveTaskRequest = (feedback) => {
    setLoading(true);
    const tastSaveData = {
      ...taskDTO,
      account: { id: accountId },
      feedback: feedback,
      isQueue: isQueue,
      queueId: selectedQueue?.id ? selectedQueue?.id : null,
    };

    if (issue) {
      tastSaveData.issue = { id: issue?.id, account: { id: accountId } };
      tastSaveData.thing = { id: issue?.thing?.id, account: { id: accountId } };
    }

    if (reassign === true) {
      TaskService.reassign(
        { ...tastSaveData, id: parentId },
        isQueue,
        selectedQueue?.id,
        saveSuccess,
        saveError
      );
    } else {
      TaskService.save(tastSaveData, saveSuccess, saveError);
    }
  };

  const newFormRender = () => {
    return (
      <>
        <FaDialog
          title={t("GOREV_OLUSTUR")}
          faOpen={open}
          faOnSubmit={saveTask}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            onClose();
            setTaskDTO(newTask());
          }}
          faMaxWidth="md"
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            {issue == null && (
              <Grid item xs={12}>
                <ThingAutoComplete
                  accountId={accountId}
                  value={taskDTO.thing}
                  onChange={(data) => {
                    handleChangeV("thing", data);
                  }}
                  required={true}
                />
              </Grid>
            )}
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isQueue}
                    onChange={() => {
                      setIsQueue(!isQueue);
                    }}
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label={t("Kuyruktan Ata")}
              />
            </Grid>
            {isQueue && (
              <Grid item xs={12}>
                <QueueAutoComplete
                  accountId={accountId}
                  onChange={(data) => {
                    setSelectedQueue(data);
                  }}
                  issueId={issue?.id}
                />
                <FormHelperText>
                  Kuyruk seçimi yapmazsanız ilgili varsayılan kuyruk sistem
                  tarafından seçilecektir.
                </FormHelperText>
              </Grid>
            )}
            {!isQueue && (
              <Grid item xs={12}>
                <UserAndGroupAutoComplete
                  value={{ user: taskDTO.user, userGroup: taskDTO.userGroup }}
                  accountId={accountId}
                  onChange={(data) => {
                    setTaskDTO({
                      ...taskDTO,
                      user: data ? (data.user ? data.user : null) : null,
                      userGroup: data
                        ? data.userGroup
                          ? data.userGroup
                          : null
                        : null,
                    });
                  }}
                  label={t("USER")}
                  required={!isQueue}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <FaInput
                faType="description"
                value={taskDTO.description}
                label={t("TASK_DESCRIPTION")}
                onChange={handleChange("description")}
              />
            </Grid>
            <Grid item xs={12}>
              <FaDatePicker
                faType="dateTime"
                label={t("DEADLINE")}
                style={{ minHeight: 65 }}
                value={taskDTO.taskDate}
                faOnChange={(val) => {
                  handleChangeV("taskDate", val);
                }}
              />
            </Grid>
            {isSLA && slaContracts && slaContracts.length > 0 && (
              <Grid item xs={12}>
                <ThingsSwitch
                  label={t("SLA Başlat")}
                  value={taskDTO.startSla}
                  onChange={(data) => {
                    if (data) {
                      setTaskDTO({
                        ...taskDTO,
                        startSla: data,
                        slaContractLevel: null,
                      });
                    } else {
                      setTaskDTO({ ...taskDTO, startSla: data });
                    }
                  }}
                />
              </Grid>
            )}
            {isSLA &&
              slaContracts &&
              slaContracts.length > 0 &&
              taskDTO.startSla && (
                <>
                  <Grid item xs={12}>
                    {slaContracts.length === 1 ? slaContracts[0].name : ""}
                  </Grid>
                  <Grid item xs={12}>
                    <SlaContractLevelRadio
                      value={taskDTO.slaContractLevel}
                      thingId={issue?.thing?.id}
                      task={true}
                      onChange={(data) => {
                        handleChangeV("slaContractLevel", data);
                      }}
                    />
                  </Grid>
                </>
              )}
          </Grid>
        </FaDialog>
      </>
    );
  };

  const feedbackAssignDialog = () => {
    return (
      <Dialog
        open={openFeedbackAssignDialog}
        onClose={() => {
          setOpenFeedbackAssignDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Geribildirim gönderilsin mi?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Bildirim sahibine görev ataması yapıldıktan sonra geribildirim
            gönderilecektir.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenFeedbackAssignDialog(false);
              saveTaskRequest(false);
            }}
            color="primary"
          >
            Gönderme
          </Button>
          <Button
            onClick={() => {
              setOpenFeedbackAssignDialog(false);
              saveTaskRequest(true);
            }}
            color="primary"
            autoFocus
          >
            Gönder
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {newFormRender()}
      {feedbackAssignDialog()}
    </>
  );
});

export default TaskEdit;
