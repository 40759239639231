import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { CUSTOMER_INTERACTION } from "./faApi";

export const CustomerInteractionService = {
  getDailyCounts: (searchForm, callback, errorCallback) => {
    let reqBody = {
      ...searchForm,
    };

    let req = requests.getSimplePostRequest(
      CUSTOMER_INTERACTION.getDailyCounts,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  search: (searchForm, paging, callback, errorCallback) => {
    let s = {
      ...searchForm,
      paging: {
        ...paging,
      },
    };
    var req = requests.getSimplePostRequest(CUSTOMER_INTERACTION.search, s);

    FaAxios(req)
      .then((response) => {
        callback(response.data.list);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getDayTimeHeatmap: (searchForm, callback, errorCallback) => {
    let reqBody = {
      ...searchForm,
    };

    let req = requests.getSimplePostRequest(
      CUSTOMER_INTERACTION.getDayTimeHeatmap,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
