import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function QueueDispatchMethodSelect(props) {
  const { value, onChange, required } = props;
  const classes = useStyles();
  const { t } = useTranslation("components", { i18n });

  return (
    <FormControl
      variant="outlined"
      className={classes.w100}
      size="small"
      required={required}
    >
      <InputLabel>{t("QUEUE_DISPATCH_METHOD")}</InputLabel>
      <Select
        // classes={{
        //   outlined: classes.padding10,
        // }}
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        required={required}
        label={t("QUEUE_DISPATCH_METHOD")}
      >
        <MenuItem value={null}>...</MenuItem>
        <MenuItem value={"ROUND_ROBIN"}>
          {t("QUEUE_DISPATCH_METHOD_ROUND_ROBIN")}
        </MenuItem>
        <MenuItem value={"LEAST_CONNECTION"}>
          {t("QUEUE_DISPATCH_METHOD_LEAST_CONNECTION")}
        </MenuItem>
      </Select>
    </FormControl>
  );
}
