import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { USER_SUBSCRIPTION } from "./faApi";

export const UserSubscriptionService = {
  subscribe: (userSubscription, callback, errorCallback) => {
    let reqBody = {
      ...userSubscription,
    };

    let req = requests.getSimplePostRequest(
      USER_SUBSCRIPTION.subscribe,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        console.log("response => ", response);
        callback(response.data);
      })
      .catch((error) => {
        console.log("response => ", error);

        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findSubscriptionList: (thingId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      USER_SUBSCRIPTION.findSubscriptionList,
      { thingId }
    );
    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
  findIfSubscribed: (userId, thingId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(USER_SUBSCRIPTION.findIfSubscribed, {
      userId,
      thingId,
    });
    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
};
