import { Grid } from "@material-ui/core";
import Adsense from "components/adsense/Adsense";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaInput, FaSearch } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { EMAIL_SMS } from "../../services/faApi";

export default function Messages() {
  const tableRef = useRef();
  const { t } = useTranslation("messsage", { i18n });
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const accountId = localStorage.accountId;

  const [loading, setLoading] = useState(false);

  const columns = [
    {
      field: "createDate",
      label: t("Tarih"),
      align: "center",
      width: "10%",
      type: "dateTime",
    },
    {
      field: "to",
      label: t("Kime"),
      align: "center",
      width: "10%",
    },
    {
      field: "flag",
      label: t("Tür"),
      align: "center",
      width: "10%",
    },
    {
      field: "subject",
      label: t("Konu"),
      align: "center",
      width: "18%",
    },
    {
      field: "text",
      label: t("İçerik"),
      align: "center",
      width: "36%",
    },
    {
      field: "nonSended",
      label: t("Durum"),
      align: "center",
      width: "8%",
      valueFormatter: (val) => (val === true ? "Gönderilmedi" : "Gönderildi"),
    },
    {
      field: "errorMessage",
      label: t("Hata Mesajı"),
      align: "center",
      width: "8%",
    },
  ];

  const newSearchForm = () => {
    let result = {
      to: null,
      subject: null,
      text: null,
      accountId: accountId,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };
  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={2}></Grid>
          <Grid item xs={4}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <FaInput
                label={t("Kime")}
                value={searchForm.to}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "to",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("Konu")}
                value={searchForm.subject}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "subject",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("İçerik")}
                value={searchForm.text}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "text",
                    event.target.value
                  );
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
        <div>
          <Adsense value="RklmUst" />
        </div>
        <ThingsDataTable
          searchUrl={EMAIL_SMS.search + "?accountId=" + localStorage.accountId}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
        />
      </Suspense>
    </>
  );
}
