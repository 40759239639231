import { faEnvelope, faMap, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { ArrowLeft, ArrowRight, List, Save } from "@material-ui/icons";
import BeneficiaryNote from "components/beneficiaryNote";
import ThingDetail from "components/detail/ThingDetail";
import PropTypes from "prop-types";
import { default as React, useEffect, useRef, useState } from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CampaignService } from "services/CampaignService";
import { getDateTimeWFormat, isTNF } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import SurveyGPSLocation from "../survey/SurveyGPSLocation";
import CampaignCustomerButtons from "./CampaignCustomerButtons";
import CampaignCustomerCheckList from "./CampaignCustomerCheckList";
import CampaignCustomerLog from "./CampaignCustomerLog";
import CampaignSurveyQuestionRender from "./CampaignSurveyQuestionRender";
import { useStyles } from "./style";

export default function CampaignCustomerEvaluate(props) {
  const {
    id,
    campaignId,
    isOpen,
    onClose,
    onPrevious,
    onNext,
    currentItem,
    totalItems,
  } = props;

  const labelClasses = labelStyles();

  const { t } = useTranslation("campaign", { i18n });
  const dispatch = useDispatch();
  const classes = useStyles();
  const dialogRef = useRef();
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  const accountId = localStorage.accountId;
  const [campaign, setCampaign] = useState(null);
  const [campaignAnswers, setCampaignAnswers] = useState();
  const [activePage, setActivePage] = useState(1);
  const [evaluation, setEvaluation] = useState({});

  const [isOpenCampaignCustomerLog, setIsOpenCampaignCustomerLog] =
    useState(false);
  const [isOpenBeneficiaryNote, setIsOpenBeneficiaryNote] = useState(false);

  useEffect(() => {
    if (
      campaignId &&
      isOpen &&
      (campaign == null || campaign.id !== campaignId)
    ) {
      CampaignService.getCampaign(
        campaignId,
        accountId,
        (data) => {
          setCampaign(data);
        },
        (error) => {}
      );
    }
  }, [isOpen]);

  useEffect(() => {
    if (id) refresh();
  }, [id]);

  const refresh = () => {
    CampaignService.customerFindById(
      id,
      (data) => {
        setCampaignAnswers(data);

        const temp = {
          list: data.answers.map((item) => {
            return {
              approve: item.approve,
              answerId: item.id,
              questionId: item.campaignQuestion.id,
            };
          }),
          status: data.status,
          campaignCustomerId: data?.id,
          checkList: null,
        };
        setEvaluation(temp);
        setActivePage(1);
      },
      (error) => {}
    );
  };

  const findAnswer = (question) => {
    return campaignAnswers?.answers.find(
      (i) => i.campaignQuestion.id === question.id
    );
  };

  const findEvaluation = (question) => {
    if (evaluation?.list)
      return evaluation?.list.find((i) => i.questionId === question.id);
  };

  const changeApprove = (question, value) => {
    setEvaluation((prev) => {
      let tmp = { ...prev };
      tmp.list.forEach((element) => {
        if (element.questionId === question.id) {
          element.approve = value;
        }
      });

      return tmp;
    });
  };

  const submitForm = () => {
    CampaignService.evaluate(
      evaluation,
      (data) => {
        onClose();
      },
      (error) => {}
    );
  };

  const renderPage = () => {
    let selectedPage = campaign.pageList.filter(
      (item) => item.orderNo === activePage
    );
    if (selectedPage && selectedPage.length > 0) selectedPage = selectedPage[0];
    else selectedPage = null;

    if (!selectedPage) return;

    let pageQuestions = campaign.questionList.filter(
      (item) => item.campaignPage.id === selectedPage.id
    );

    return (
      <Card style={{ minWidth: "100%", minHeight: 200, marginTop: 10 }}>
        <CardHeader title={t("CAMPAIGN_QUESTIONS")} />
        <CardContent>
          <Grid item container spacing={1}>
            <Grid item xs={10}></Grid>
            <Grid item xs={1}>
              Onay
            </Grid>
            <Grid item xs={1}>
              Ret
            </Grid>
            {pageQuestions.map((item, index) => (
              <>
                <Grid item xs={10}>
                  <CampaignSurveyQuestionRender
                    key={index}
                    question={item}
                    onChangeAnswer={() => {}}
                    answer={findAnswer(item)}
                    editable={false}
                    readOnly={true}
                    accountId={accountId}
                    currentLang={"tr"}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Checkbox
                    checked={findEvaluation(item)?.approve === "YES"}
                    onChange={() => {
                      changeApprove(item, "YES");
                    }}
                    color="primary"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Checkbox
                    checked={findEvaluation(item)?.approve === "NO"}
                    onChange={() => {
                      changeApprove(item, "NO");
                    }}
                    color="secondary"
                  />
                </Grid>
              </>
            ))}
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const renderGPSLocation = () => {
    return (
      <SurveyGPSLocation
        ref={dialogRef}
        answer={campaignAnswers}
      ></SurveyGPSLocation>
    );
  };

  const renderHeader = () => {
    return (
      <>
        {renderGPSLocation()}
        <Card
        //className={classes.minCardHeight}
        >
          <CardHeader />
          <CardContent>
            <Grid item container spacing={1}>
              <Grid item xs={12}>
                <Grid item container spacing={1}>
                  <Grid item xs={4} className={labelClasses.LabelLastRow}>
                    <Typography className={labelClasses.LabelHeader}>
                      {isTNF(t("THING"), t("THING_T2C"))}
                    </Typography>
                    <ThingDetail value={campaignAnswers?.thing} />
                  </Grid>

                  <Grid item xs={2} className={labelClasses.LabelLastRow}>
                    <Typography className={labelClasses.LabelHeader}>
                      {t("COMPLETION_TIME")}
                    </Typography>
                    <Typography className={labelClasses.LabelValue}>
                      {`${campaignAnswers?.completionTime} saniye`}
                    </Typography>
                  </Grid>

                  <Grid item xs={2} className={labelClasses.LabelLastRow}>
                    <Typography className={labelClasses.LabelHeader}>
                      {t("REGISTER_DATE")}
                    </Typography>
                    <Typography className={labelClasses.LabelValue}>
                      {getDateTimeWFormat(campaignAnswers?.createDate)}
                    </Typography>
                  </Grid>

                  <Grid item xs={2} className={labelClasses.LabelLastRow}>
                    <Typography className={labelClasses.LabelHeader}>
                      {t("GPS_LOCATION")}
                    </Typography>
                    <IconButton
                      aria-label="settings"
                      onClick={() => {
                        dialogRef.current.openDialog();
                      }}
                    >
                      <FontAwesomeIcon icon={faMap} />
                    </IconButton>
                  </Grid>
                  <Grid item xs={2} className={labelClasses.LabelLastRow}>
                    <Typography className={labelClasses.LabelHeader}>
                      {t("CUSTOMER")}
                    </Typography>
                    <IconButton
                      aria-label="settings"
                      onClick={() => {
                        let customerId = campaignAnswers?.customer?.id;
                        const tmpTo = {
                          pathname: `/customer/detail/${customerId}`,
                          breadCrumbKey: `/customer/detail/:id`,
                        };
                        history.push(tmpTo);
                      }}
                    >
                      <FontAwesomeIcon icon={faUser} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  };

  const renderButtons = () => {
    return (
      <div style={{ marginBottom: 5 }}>
        <Button
          color="primary"
          variant="contained"
          startIcon={<ArrowLeft />}
          onClick={onPrevious}
          disabled={currentItem === 0}
        >
          {t("Önceki [" + currentItem + "]")}
        </Button>

        <Button
          color="primary"
          variant="contained"
          endIcon={<ArrowRight />}
          style={{ float: "right" }}
          onClick={onNext}
          disabled={currentItem === totalItems - 1}
        >
          {t("Sonraki [" + (totalItems - currentItem - 1) + "]")}
        </Button>

        <Button
          color="primary"
          variant="contained"
          endIcon={<List />}
          style={{ float: "right", marginRight: 10 }}
          onClick={() => {
            setIsOpenCampaignCustomerLog(true);
          }}
        >
          {t("İşlem Geçmişi")}
        </Button>

        <Button
          color="primary"
          variant="contained"
          endIcon={<FontAwesomeIcon icon={faEnvelope} />}
          style={{ float: "right", marginRight: 10 }}
          onClick={() => {
            setIsOpenBeneficiaryNote(true);
          }}
        >
          {t("Mesajlaşma Geçmişi")}
        </Button>
      </div>
    );
  };

  const renderStepContent = () => {
    if (
      campaign &&
      campaign.pageList &&
      activePage <= campaign.pageList.length
    ) {
      return renderPage();
    } else if (
      campaign &&
      campaign.pageList &&
      activePage === campaign.pageList.length + 1
    ) {
      return renderEvaluationForm();
    }
  };

  const onStatusChange = (data) => {
    setEvaluation((prev) => {
      return { ...prev, status: data };
    });
  };

  const onCheckListChange = (data) => {
    setEvaluation((prev) => {
      return { ...prev, checkList: data };
    });
  };

  const renderEvaluationForm = () => {
    return (
      <Grid item container spacing={1}>
        <Grid item xs={12}>
          <CampaignCustomerButtons
            campaignCustomerId={campaignAnswers?.id}
            status={evaluation?.status}
            onChange={onStatusChange}
            autoSave={false}
          />
        </Grid>
        <Grid item xs={12}>
          <CampaignCustomerCheckList
            campaignCustomerId={campaignAnswers?.id}
            status={campaignAnswers?.status}
            onChange={onCheckListChange}
            autoSave={false}
          />
        </Grid>
      </Grid>
    );
  };

  const renderNavigationButtons = () => {
    return (
      <Grid item container spacing={1}>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <Button
            color="primary"
            variant="contained"
            startIcon={<ArrowLeft />}
            onClick={() => {
              setActivePage((prev) => prev - 1);
            }}
            disabled={activePage === 1}
            style={{ float: "right" }}
          >
            {t("Geri")}
          </Button>
        </Grid>
        {campaign &&
          campaign.pageList &&
          activePage < campaign.pageList.length + 1 && (
            <Grid item xs={2}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<ArrowRight />}
                onClick={() => {
                  setActivePage((prev) => prev + 1);
                }}
              >
                {t("İleri")}
              </Button>
            </Grid>
          )}
        {campaign &&
          campaign.pageList &&
          activePage === campaign.pageList.length + 1 && (
            <Grid item xs={2}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<Save />}
                onClick={() => {
                  submitForm();
                }}
              >
                {t("Kaydet")}
              </Button>
            </Grid>
          )}
        <Grid item xs={4}></Grid>
      </Grid>
    );
  };

  const renderDialogCampaignCustomerLog = () => {
    return (
      isOpenCampaignCustomerLog && (
        <FaDialog
          //title={t("Geçmiş")}
          faOpen={isOpenCampaignCustomerLog}
          loading={loading}
          faHandleClose={() => {
            setIsOpenCampaignCustomerLog(false);
          }}
          faMaxWidth="lg"
        >
          <CampaignCustomerLog id={campaignAnswers?.id} />
        </FaDialog>
      )
    );
  };

  const renderDialogBeneficiaryNote = () => {
    return (
      isOpenBeneficiaryNote && (
        <FaDialog
          //title={t("Geçmiş")}
          faOpen={isOpenBeneficiaryNote}
          loading={loading}
          faHandleClose={() => {
            setIsOpenBeneficiaryNote(false);
          }}
          faMaxWidth="lg"
        >
          <BeneficiaryNote
            campaignCustomer={campaignAnswers}
            title={t("Mesajlaşma Geçmişi")}
            sms={
              campaignAnswers && campaignAnswers.customer.phone ? true : false
            }
            email={
              campaignAnswers && campaignAnswers.customer.email ? true : false
            }
            push={false}
          />
        </FaDialog>
      )
    );
  };

  return (
    <FaDialog
      title={t("CAMPAIGN_ANSWER_EVALUATE")}
      faOpen={isOpen}
      loading={loading}
      faHandleClose={() => {
        onClose();
      }}
      faMaxWidth="lg"
    >
      <div>
        {renderButtons()}
        {renderHeader()}
        {renderStepContent()}
        {renderNavigationButtons()}
        {renderDialogCampaignCustomerLog()}
        {renderDialogBeneficiaryNote()}
      </div>
    </FaDialog>
  );
}

CampaignCustomerEvaluate.propTypes = {
  id: PropTypes.any,
  campaignId: PropTypes.any,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  currentItem: PropTypes.any,
  totalItems: PropTypes.any,
};
