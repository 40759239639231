import { Button, Grid } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ThingsSwitch from "components/ui/ThingsSwitch";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaDatePicker, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { SurveyService } from "services/SurveyService";
import { GetFieldByLanguage } from "services/utils";
import VerificationTypeSelect from "../../components/select/VerificationTypeSelect";
import i18n from "../../i18n";
import { useStyles } from "./style";

const SurveyEdit = forwardRef((props, ref) => {
  const { onSave, survey, currentLang } = props;
  const { t } = useTranslation("survey", { i18n });
  const accountId = localStorage.accountId;
  let history = useHistory();
  const faDialog = useDialog();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const newSurvey = () => {
    return {
      user: { id: null },
      account: { id: accountId },
      name: null,
      description: null,
      surveyFormat: "SINGLE_PAGE",
      status: "PASSIVE",
      languages: ["TR"],
      userVerificationType: null,
      multiLangName: {
        TR: null,
        EN: null,
      },
      multiLangDescription: {
        TR: null,
        EN: null,
      },
      registerStartDate: null,
      registerEndDate: null,
      fingerprint: false,
    };
  };
  const [surveyDTO, setSurveyDTO] = useState(newSurvey());

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsDialogOpen(true);
    },
  }));

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    if (survey) {
      setSurveyDTO(survey == null ? newSurvey() : { ...survey });
    }
  }, [survey]);

  const saveSurvey = () => {
    setLoading(true);
    console.log(surveyDTO);
    SurveyService.save(surveyDTO, saveOnSuccess, saveOnError);
  };

  const saveOnSuccess = (data) => {
    if (!surveyDTO.id) {
      SurveyService.savePage(
        {
          survey: { id: data.id },
          multiLangName: { tr: "Yeni Sayfa" },
          description: null,
        },
        () => {},
        () => {}
      );
    }

    setIsDialogOpen(false);
    setLoading(false);
    showAlert(!surveyDTO.id ? t("SAVED") : t("EDITED"), "success");
    onSave(data);
  };

  const saveOnError = (error) => {
    showAlert(error, "error");
    setLoading(false);
  };

  const handleChange = (prop) => (event) => {
    setSurveyDTO({ ...surveyDTO, [prop]: event.target.value });
  };

  const handleChangeV = (prop, val) => {
    setSurveyDTO({ ...surveyDTO, [prop]: val });
  };
  const onDelete = (data) => {
    faDialog({
      description: t("Kuralı silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        SurveyService.delete(
          surveyDTO,
          (data) => {
            showAlert(t("Kural Silindi"), "success");
            const tmpTo = {
              pathname: `/survey`,
              breadCrumbKey: `/survey`,
              search: "?page=0&size=10&isAsc=false&sort=name",
            };
            history.push(tmpTo);
          },
          (error) => {
            showAlert(t("Kayıt silinemedi, bağlı kayıtlar mevcut."), "error");
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };
  return (
    <FaDialog
      title={t("EDIT_SURVEY")}
      faOpen={isDialogOpen}
      faOnSubmit={saveSurvey}
      loading={loading}
      showSaveButton
      faHandleClose={() => {
        setIsDialogOpen(false);
        setSurveyDTO(survey ? survey : newSurvey());
      }}
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item xs={12}>
          <FaInput
            label={t("NAME")}
            required
            value={surveyDTO.multiLangName?.[currentLang] || ""}
            onChange={(event) => {
              setSurveyDTO({
                ...surveyDTO,
                multiLangName: {
                  ...surveyDTO.multiLangName,
                  [currentLang]: event.target.value,
                },
              });
            }}
            helperText={
              surveyDTO.multiLangName?.[currentLang] === undefined
                ? GetFieldByLanguage(surveyDTO.multiLangName, currentLang)
                : ""
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FaInput
            label={t("DESCRIPTION")}
            faType="description"
            inputProps={{ maxLength: 255 }}
            onChange={(event) => {
              setSurveyDTO({
                ...surveyDTO,
                multiLangDescription: {
                  ...surveyDTO.multiLangDescription,
                  [currentLang]: event.target.value,
                },
              });
            }}
            value={surveyDTO.multiLangDescription?.[currentLang] || ""}
            helperText={
              surveyDTO.multiLangDescription?.[currentLang] === undefined
                ? GetFieldByLanguage(
                    surveyDTO.multiLangDescription,
                    currentLang
                  )
                : ""
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 10 }}>
        <VerificationTypeSelect
          value={surveyDTO.userVerificationType || ""}
          label="Kullanıcı Doğrulama Modeli"
          onChange={(data) => {
            handleChangeV("userVerificationType", data);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FaDatePicker
          label={t("REGISTER_START_DATE")}
          faType="dateTime"
          value={surveyDTO.registerStartDate}
          faOnChange={(val) => {
            handleChangeV("registerStartDate", val);
          }}
          data-fa-input="REGISTER_START_DATE"
        />
      </Grid>
      <Grid item xs={12}>
        <FaDatePicker
          label={t("REGISTER_END_DATE")}
          faType="dateTime"
          name="registerEndDate"
          value={surveyDTO.registerEndDate}
          faOnChange={(val) => {
            handleChangeV("registerEndDate", val);
          }}
          data-fa-input="REGISTER_END_DATE"
        />
      </Grid>
      <Grid item xs={12}>
        <ThingsSwitch
          label={t("FINGERPRINT")}
          value={surveyDTO?.fingerPrint}
          onChange={(val) => {
            handleChangeV("fingerPrint", val);
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <FormControl variant="outlined" size="small">
          <FormControlLabel
            control={
              <Checkbox
                checked={surveyDTO?.languages?.includes("TR")}
                onChange={(event) => {
                  if (event.target.checked) {
                    let temp = surveyDTO.languages;
                    temp.push("TR");
                    setSurveyDTO({ ...surveyDTO, languages: temp });
                  } else {
                    let temp = surveyDTO.languages;
                    var index = temp.indexOf("TR");

                    if (index !== -1) {
                      temp.splice(index, 1);
                    }
                    setSurveyDTO({ ...surveyDTO, languages: temp });
                  }
                }}
                color="primary"
              />
            }
            label={t("TURKISH")}
          />
        </FormControl>
        <FormControl variant="outlined" size="small">
          <FormControlLabel
            control={
              <Checkbox
                checked={surveyDTO?.languages?.includes("EN")}
                onChange={(event) => {
                  if (event.target.checked) {
                    let temp = surveyDTO.languages;
                    temp.push("EN");
                    setSurveyDTO({ ...surveyDTO, languages: temp });
                  } else {
                    let temp = surveyDTO.languages;
                    var index = temp.indexOf("EN");

                    if (index !== -1) {
                      temp.splice(index, 1);
                    }
                    setSurveyDTO({ ...surveyDTO, languages: temp });
                  }
                }}
                name="recurring"
                color="primary"
              />
            }
            label={t("ENGLISH")}
          />
        </FormControl>
      </Grid>
      {surveyDTO.id && (
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button onClick={onDelete} color="secondary">
            {t("Sil")}
          </Button>
        </Grid>
      )}
    </FaDialog>
  );
});

export default SurveyEdit;
