import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { ORGANIZATION_USER } from "./faApi";

export const OrganizationUserService = {
  save: (organizationUser, callback, errorCallback) => {
    let reqBody = {
      ...organizationUser,
    };

    let req = requests.getSimplePostRequest(ORGANIZATION_USER.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findByOrganization: (organizationId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      ORGANIZATION_USER.findByOrganization,
      { organizationId }
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findUsersByOrganization: (organizationId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      ORGANIZATION_USER.findUsersByOrganization,
      { organizationId }
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findManagersByOrganization: (organizationId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      ORGANIZATION_USER.findManagersByOrganization,
      { organizationId }
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (organizationUser, callback, errorCallback) => {
    let reqBody = {
      ...organizationUser,
    };

    let req = requests.getSimplePostRequest(ORGANIZATION_USER.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
