import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import { FaButton } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { PeriodicReportService } from "../../services/PeriodicReportService";
import DetailCard from "./DetailCard";
import PeriodicReportEdit from "./PeriodicReportEdit";
import PeriodicReportLog from "./PeriodicReportLog";
import PeriodicReportRecurrence from "./PeriodicReportRecurrence";
import PeriodicReportUsers from "./PeriodicReportUsers";
import { useStyles } from "./style";
export default function PeriodicReportDetail() {
  const classes = useStyles();
  const labelClasses = labelStyles();
  const { t } = useTranslation("periodicReport", { i18n });
  const { id } = useParams();
  const accountId = localStorage.accountId;
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const dialogRef = useRef();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [loading, setLoading] = useState(false);
  const [periodicReport, setPeriodicReport] = useState({});

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    setLoading(true);
    PeriodicReportService.findById(
      id,
      findDetailsOnSuccess,
      findDetailsOnError
    );
  };

  const findDetailsOnSuccess = (data) => {
    setPeriodicReport({
      ...data,
    });
    setLoading(false);
  };

  const findDetailsOnError = (error) => {
    showAlert(error, "error");
    setLoading(false);
  };

  const onSave = () => {
    refresh();
  };

  const editDialogRender = () => {
    return (
      <PeriodicReportEdit
        periodicReport={periodicReport}
        ref={dialogRef}
        onSave={onSave}
      ></PeriodicReportEdit>
    );
  };
  const openEditDialog = () => {
    dialogRef.current.openDialog();
  };

  const changeStatusActive = () => {
    faDialog({
      description: t(
        "Periyodik raporu aktifleştirmek istediğinize emin misiniz?"
      ),
      type: "confirm",
    })
      .then((confirmation) => {
        PeriodicReportService.active(
          id,
          changeStatusOnSuccess,
          changeStatusOnError
        );
      })
      .catch((err) => console.log("error:", err));
  };
  const changeStatusPassive = () => {
    faDialog({
      description: t(
        "Periyodik raporu pasifleştirmek istediğinize emin misiniz?"
      ),
      type: "confirm",
    })
      .then((confirmation) => {
        PeriodicReportService.passive(
          id,
          changeStatusOnSuccess,
          changeStatusOnError
        );
      })
      .catch((err) => console.log("error:", err));
  };
  const changeStatusOnSuccess = (data) => {
    setPeriodicReport({
      ...data,
    });
    setLoading(false);
  };

  const changeStatusOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {editDialogRender()}
        <Grid container spacing={1}>
          <Grid item lg={6}>
            <Grid item container spacing={1}>
              <Grid item lg={12}>
                <Card className={classes.minCardHeight}>
                  <CardHeader
                    title={t("PERIODIC_REPORT_INFO")}
                    data-fa-section="PERIODIC_REPORT_INFO"
                    action={
                      <IconButton
                        disabled={periodicReport.status !== "PASSIVE"}
                        aria-label="settings"
                        onClick={() => openEditDialog()}
                      >
                        <EditIcon />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <Grid item container spacing={1}>
                      <Grid item xs={12}>
                        <Grid item container spacing={1}>
                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("NAME")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {periodicReport.name}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("DESCRIPTION")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {periodicReport.description}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            className={labelClasses.LabelLastRow}
                          >
                            <Typography className={labelClasses.LabelHeader}>
                              {t("STATUS")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {t(periodicReport.status)}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className={labelClasses.LabelLastRow}
                          >
                            {periodicReport.status === "PASSIVE" && (
                              <FaButton
                                style={{ width: "20%" }}
                                variant="contained"
                                size="small"
                                faClick={() => {
                                  changeStatusActive();
                                }}
                                data-fa-button="ACTIVATE"
                              >
                                {t("ACTIVATE")}
                              </FaButton>
                            )}
                            {periodicReport.status === "ACTIVE" && (
                              <FaButton
                                style={{ width: "20%" }}
                                variant="contained"
                                size="small"
                                faClick={() => {
                                  changeStatusPassive();
                                }}
                                data-fa-button="PASSIVATE"
                              >
                                {t("PASSIVATE")}
                              </FaButton>
                            )}
                            {/* <FaButton
                              style={{ width: "20%" }}
                              variant="contained"
                              size="small"
                              faClick={() => {
                                PeriodicReportService.generate(
                                  id,
                                  () => {},
                                  () => {}
                                );
                              }}
                              data-fa-button="PASSIVATE"
                            >
                              {t("Generate")}
                            </FaButton> */}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={6}>
            {periodicReport && periodicReport.id > 0 && (
              <PeriodicReportRecurrence
                periodicReport={periodicReport}
                setPeriodicReport={setPeriodicReport}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            {periodicReport && periodicReport.id > 0 && (
              <PeriodicReportUsers periodicReport={periodicReport} />
            )}
          </Grid>

          <Grid item lg={12}>
            {periodicReport && periodicReport.id > 0 && (
              <DetailCard periodicReport={periodicReport} />
            )}
          </Grid>

          <Grid item lg={12}>
            {periodicReport && periodicReport.id > 0 && (
              <PeriodicReportLog
                periodicReport={periodicReport}
              ></PeriodicReportLog>
            )}
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
