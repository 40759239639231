import {
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IssueService } from "services/issue/issueService";
import { IssueDesc, smartTimeDiff } from "services/utils";
import i18n from "../../i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  paper: {
    width: "100%",
    height: 230,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

export default function OpenIssues(props) {
  const { t } = useTranslation(["issue", "issueCloseType"], { i18n });
  const { thing, setIssues, accPreferences, defaultIssue } = props;

  const [activeIssues, setActiveIssues] = useState([]);

  useEffect(() => {
    if (thing) {
      IssueService.findByActiveIssues(thing.thid, thing.id, findByActiveIssuesCallback);
    }
  }, [thing]);

  const findByActiveIssuesCallback = (list) => {
    let feedbackDefault = false;
    if (accPreferences && accPreferences.feedbackTaskAssign === "ACCEPT")
      feedbackDefault = true;
    else if (accPreferences && accPreferences.feedbackTaskAssign === "NEVER")
      feedbackDefault = false;

    let tmpArr = list.map((value) => ({
      ...value,
      closed: true,
      feedback: feedbackDefault,
    }));

    setActiveIssues(tmpArr);
    let arr = [];
    for (let i = 0; i < tmpArr.length; i++) {
      arr.push(i);
    }
    setLeft(arr);

    if (defaultIssue?.id) {
      const myIssue = list.filter((item) => item.id === defaultIssue?.id)[0];
      const defaultIssueIndex = list.indexOf(myIssue);
      setChecked([...checked, defaultIssueIndex]);
    }
  };

  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);

  useEffect(() => {
    if (checked.length > 0) {
      const selectedIssues = [];
      [...checked].forEach((item) => {
        if (activeIssues[item]) {
          selectedIssues.push(activeIssues[item]);
        }
      });
      setIssues(selectedIssues);
    }
  }, [checked, activeIssues]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const closeIssue = (index) => {
    let tmpArry = [...activeIssues];
    tmpArry[index].closed = !tmpArry[index].closed;
    setActiveIssues(tmpArry);
  };

  const feedbackIssue = (index) => {
    let tmpArry = [...activeIssues];
    tmpArry[index].feedback = !tmpArry[index].feedback;
    setActiveIssues(tmpArry);
  };

  const customList = (items) => (
    <Paper className={classes.paper}>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            {/* <TableRow>
              <TableCell colSpan={5}>Ele Alınan Bildirimler</TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell></TableCell>
              <TableCell>{t("BILDIRIM_TARIH")}</TableCell>
              <TableCell>{t("ISSUE")}</TableCell>
              <TableCell>{t("SORUN_KAPAT")}</TableCell>
              <TableCell>{t("GERIBILDIRIM")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.length === 0 && (
              <TableRow>
                <TableCell colSpan={5}>
                  Etkileşim noktasıyla ilgili bildirim bulunmamaktadır.
                </TableCell>
              </TableRow>
            )}
            {items.map((value) => {
              const issue = activeIssues[value];
              const labelId = `transfer-list-item-${value}-label`;
              const itemSelected = checked.indexOf(value) !== -1;

              return (
                <TableRow key={value}>
                  <TableCell>
                    <Checkbox
                      checked={itemSelected}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                      onClick={handleToggle(value)}
                    />
                  </TableCell>
                  <TableCell>{smartTimeDiff(issue.notificationDate)}</TableCell>
                  <TableCell>{IssueDesc(issue)}</TableCell>
                  <TableCell>
                    {itemSelected === true && (
                      <Switch
                        checked={issue.closed}
                        onChange={() => {
                          closeIssue(value);
                        }}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    )}
                  </TableCell>

                  <TableCell>
                    {itemSelected === true && (
                      <Switch
                        checked={issue.feedback}
                        disabled={!issue.closed}
                        onChange={() => {
                          feedbackIssue(value);
                        }}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={12}>
        {customList(left)}
      </Grid>
    </Grid>
  );
}
