import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: -50,
  },
  text: {
    fontSize: 16,
  },
  icon: {
    width: 64,
    height: 64,
  },
}));
