import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { SYNC } from "./faApi";

export const SyncService = {
  ldapUserSync: (callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(SYNC.ldapUserSync);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  ldapOrganizationSync: (callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(SYNC.ldapOrganizationSync);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  startThingThumbnails: (callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(SYNC.startThingThumbnails);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  startLocationDetailThumbnails: (callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(SYNC.startLocationDetailThumbnails);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
