import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import { FaButton } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { CustomerMessageService } from "services/CustomerMessageService";
import { getDateTimeWFormat } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import CustomerMessageDocuments from "./CustomerMessageDocuments";
import CustomerMessageEdit from "./CustomerMessageEdit";
import CustomerMessageSendDetail from "./CustomerMessageSendDetail";
import CustomerMessageTarget from "./CustomerMessageTarget";

export default function CustomerMessageDetail() {
  const labelClasses = labelStyles();
  const { t } = useTranslation("customerMessage", { i18n });
  const { id } = useParams();
  const accountId = localStorage.accountId;
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const dialogRef = useRef();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  //const [editButtonClicked, setEditButtonClicked] = useState(true);

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    setLoading(true);
    CustomerMessageService.findById(id, findByIdOnSuccess, findByIdOnError);
  };

  const findByIdOnSuccess = (data) => {
    setMessage({
      ...data,
    });
    setLoading(false);
  };

  const findByIdOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };

  const onSave = () => {
    refresh();
  };

  const editDialogRender = () => {
    return (
      <CustomerMessageEdit
        message={message}
        ref={dialogRef}
        onSave={onSave}
      ></CustomerMessageEdit>
    );
  };
  const openEditDialog = () => {
    dialogRef.current.openDialog();
  };

  const changeStatusActive = () => {
    faDialog({
      description: t("Mesajı aktifleştirmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        CustomerMessageService.changeStatusActive(
          id,
          changeStatusActiveOnSuccess,
          changeStatusActiveOnError
        );
      })
      .catch((err) => console.log("error:", err));
  };
  const changeStatusActiveOnSuccess = (data) => {
    setMessage({
      ...data,
    });
    setLoading(false);
  };

  const changeStatusActiveOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };

  const changeStatusDraft = () => {
    faDialog({
      description: t(
        "Mesajı taslak haline dönüştürmek istediğinize emin misiniz?"
      ),
      type: "confirm",
    })
      .then((confirmation) => {
        CustomerMessageService.changeStatusDraft(
          id,
          changeStatusDraftOnSuccess,
          changeStatusDraftOnError
        );
      })
      .catch((err) => console.log("error:", err));
  };
  const changeStatusDraftOnSuccess = (data) => {
    setMessage({
      ...data,
    });
    setLoading(false);
  };

  const changeStatusDraftOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };

  const sendNow = () => {
    faDialog({
      description: t("Mesajı göndermek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        CustomerMessageService.sendNow(id, sendNowOnSuccess, sendNowOnError);
      })
      .catch((err) => console.log("error:", err));
  };
  const sendNowOnSuccess = () => {
    refresh();
  };

  const sendNowOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };
  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {editDialogRender()}
        <Grid container spacing={1}>
          <Grid item lg={6} xs={12}>
            <Grid item container spacing={1}>
              <Grid item lg={12}>
                <Card>
                  <CardHeader
                    title={t("CUSTOMER_MESSAGE_INFO")}
                    data-fa-section="CUSTOMER_MESSAGE_INFO"
                    action={
                      <IconButton
                        disabled={message.status !== "DRAFT"}
                        aria-label="settings"
                        onClick={() => openEditDialog()}
                      >
                        <EditIcon />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <Grid item container spacing={1}>
                      <Grid item xs={12}>
                        <Grid item container spacing={1}>
                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("NAME")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {message.name}
                            </Typography>
                          </Grid>
                          {message.messageTitle ? (
                            <Grid
                              item
                              xs={12}
                              className={labelClasses.LabelRow}
                            >
                              <Typography className={labelClasses.LabelHeader}>
                                {t("MESSAGE_TITLE")}
                              </Typography>
                              <Typography className={labelClasses.LabelValue}>
                                {message.messageTitle}
                              </Typography>
                            </Grid>
                          ) : null}

                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("MESSAGE_TEMPLATE")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {message.messageTemplate}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("PLANNED_SEND_DATE")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {getDateTimeWFormat(message.plannedSendDate)}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("DESCRIPTION")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {message.description}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("CHANNEL")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {t(message.channel)}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            className={labelClasses.LabelLastRow}
                          >
                            <Typography className={labelClasses.LabelHeader}>
                              {t("STATUS")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {t(message.status)}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className={labelClasses.LabelLastRow}
                          >
                            {message.status === "DRAFT" ? (
                              <FaButton
                                style={{ width: "25%" }}
                                variant="contained"
                                size="small"
                                faClick={() => {
                                  changeStatusActive();
                                }}
                                //startIcon={<FontAwesomeIcon icon={faUserEdit} />}
                                data-fa-button="ACTIVATE"
                              >
                                {t("ACTIVATE")}
                              </FaButton>
                            ) : (
                              <>
                                {" "}
                                {message.status === "ACTIVE" ? (
                                  <>
                                    <FaButton
                                      style={{ width: "25%", marginLeft: 10 }}
                                      variant="contained"
                                      size="small"
                                      color="secondary"
                                      faClick={() => {
                                        changeStatusDraft();
                                      }}
                                      //startIcon={<Refresh />}
                                      data-fa-button="CONVERT_DRAFT"
                                    >
                                      {t("CONVERT_DRAFT")}
                                    </FaButton>

                                    <FaButton
                                      style={{ width: "25%", marginLeft: 10 }}
                                      variant="contained"
                                      size="small"
                                      faClick={() => {
                                        sendNow();
                                      }}
                                      //startIcon={<Cancel />}
                                      data-fa-button="SEND_NOW"
                                    >
                                      {t("SEND_NOW")}
                                    </FaButton>
                                  </>
                                ) : null}
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={6} xs={12}>
            <Grid item container spacing={1}>
              <Grid item xs={12}>
                {message && message.id > 0 && (
                  <CustomerMessageTarget
                    message={message}
                  ></CustomerMessageTarget>
                )}
              </Grid>

              <Grid item xs={12}>
                {message && message.id > 0 && message.channel === "EMAIL" && (
                  <CustomerMessageDocuments
                    message={message}
                  ></CustomerMessageDocuments>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={12} xs={12}>
            {message && message.id > 0 && (
              <CustomerMessageSendDetail
                message={message}
              ></CustomerMessageSendDetail>
            )}
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
