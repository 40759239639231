import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { ORGANIZATION } from "./faApi";

export const OrganizationService = {
  save: (organization, callback, errorCallback) => {
    let reqBody = {
      ...organization,
    };

    let req = requests.getSimplePostRequest(ORGANIZATION.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findOrganization: (accountId, name, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(ORGANIZATION.findOrganization, {
      accountId,
      name,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (organization, callback, errorCallback) => {
    let reqBody = {
      ...organization,
    };

    let req = requests.getSimplePostRequest(ORGANIZATION.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
