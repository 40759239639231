import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  CardHeader,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ThingsDataTable from "components/thingsDataTable";
import { default as React, Suspense, useRef, useState } from "react";
import { FaDialog, FaFileUpload, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { DocumentService } from "services/DocumentService";
import { SERVICE_OPERATION_CLASS } from "services/faApi";
import { ServiceOperationClassService } from "services/ServiceOperationClassService";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";

export default function ServiceOperationProcedure(props) {
  const { serviceOperation } = props;

  const accountId = localStorage.accountId;

  const { t } = useTranslation("serviceOperationClass", { i18n });
  const { id } = useParams();
  const dispatch = useDispatch();
  const labelClasses = labelStyles();
  const faDialog = useDialog();

  const [loading, setLoading] = useState(false);
  const dialogRef = useRef();
  const tableRef = useRef();
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const refresh = () => {
    tableRef.current.refreshTable();
  };

  const newServiceOperationProcedure = () => {
    return {
      serviceOperationClass: { id: serviceOperation.id },
      name: null,
      order: null,
    };
  };

  const [serviceOperationProcedure, setServiceOperationProcedure] = useState(
    newServiceOperationProcedure()
  );

  const [files, setFiles] = useState(); // yeni dosya yüklerken
  const [documents, setDocuments] = useState(); // yüklü dosyaları çekmek için istek atıldığında dönen dosya

  const newSearchForm = () => {
    return {
      serviceOperationClassId: serviceOperation.id,
    };
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const columns = [
    {
      field: "orderNo",
      label: t("ORDER"),
      align: "center",
      width: "5%",
    },
    {
      field: "name",
      label: t("NAME"),
      align: "center",
      width: "25%",
    },
    {
      field: "documents",
      label: t("PHOTOS"),
      align: "center",
      width: "70%",
      valueFormatter: (val, row) => {
        return val.map((item) => item.fileName + "  ");
      },
    },
  ];

  const save = () => {
    ServiceOperationClassService.saveProcedure(
      serviceOperationProcedure,
      (data) => {
        if (files && files.length > 0) saveDocument(data.id);
        else {
          saveOnSuccess();
        }
      },
      (error) => console.log(error)
    );
  };

  const saveDocument = (id) => {
    DocumentService.uploadServiceOperationProcedureDocument(
      id,
      accountId,
      "", // document name
      "", // document description
      files[0],
      saveOnSuccess,
      () => {}
    );
  };

  const saveOnSuccess = () => {
    setIsEditFormOpen(false);
    setLoading(false);
    tableRef.current.refreshTable();
    setServiceOperationProcedure(newServiceOperationProcedure());
    setFiles([]);
  };

  const downloadDocument = (procedure, item) => {
    DocumentService.downloadServiceOperationProcedureDocument(
      procedure.id,
      accountId,
      item.id,
      downloadDocumentOnSuccess,
      (error) => console.log(error)
    );
  };

  const downloadDocumentOnSuccess = (data) => {
    const link = document.createElement("a");
    link.href = "data:application/octet-stream;base64," + data.content; //url;
    link.setAttribute("download", data.fileName);
    document.body.appendChild(link);
    link.click();
  };

  const deleteDocument = (item, index) => {
    DocumentService.deleteServiceOperationProcedureDocument(
      serviceOperationProcedure.id,
      accountId,
      item.id,
      (data) => {
        let list = serviceOperationProcedure.documents;
        list.splice(index, 1);
        setServiceOperationProcedure({
          ...serviceOperationProcedure,
          documents: list,
        });
      },
      (error) => console.log(error)
    );
  };

  const onDoubleClick = (row) => {
    setServiceOperationProcedure(row);
    setIsEditFormOpen(true);
  };

  const onDelete = (data) => {
    faDialog({
      description: t("İşlemi silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        ServiceOperationClassService.deleteProcedure(
          serviceOperationProcedure,
          (data) => {
            showAlert(t("İşlem silindi"), "success");
            setIsEditFormOpen(false);
            refresh();
            setServiceOperationProcedure(newServiceOperationProcedure());
          },
          (error) => {
            showAlert(t("Kayıt silinemedi, bağlı kayıtlar mevcut."), "error");
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const renderDialog = () => {
    return (
      <FaDialog
        title={"İşlem Adımı Tanımla"}
        faOpen={isEditFormOpen}
        faOnSubmit={save}
        loading={loading}
        showSaveButton
        faHandleClose={() => {
          setIsEditFormOpen(false);
          setServiceOperationProcedure(newServiceOperationProcedure());
          setFiles([]);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <FaInput
              faType="description"
              label={t("NAME")}
              value={serviceOperationProcedure?.name}
              onChange={(event) =>
                setServiceOperationProcedure({
                  ...serviceOperationProcedure,
                  name: event.target.value,
                })
              }
            />
          </Grid>
          {serviceOperationProcedure.documents &&
            serviceOperationProcedure.documents.length > 0 && (
              <Grid item xs={12}>
                {serviceOperationProcedure.documents.map((item, index) => (
                  <Grid
                    container
                    row
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Link
                      onClick={() => {
                        downloadDocument(serviceOperationProcedure, item);
                      }}
                      title={t("İndir")}
                      className={labelClasses.SimpleLink}
                    >
                      <Typography className={labelClasses.LabelValue}>
                        {item.fileName}{" "}
                      </Typography>
                    </Link>
                    <IconButton
                      style={{
                        marginLeft: 10,
                        marginRight: 15,
                        fontSize: 16,
                      }}
                      onClick={() => deleteDocument(item, index)}
                      color="primary"
                      aria-label={t("SIL")}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </IconButton>
                  </Grid>
                ))}
              </Grid>
            )}
          {(!documents || documents.length === 0) && (
            <Grid item xs={12}>
              <FaFileUpload
                maxFileSize={20000000}
                onChange={(data) => {
                  if (data != null && data.length > 0) {
                    setFiles(data);
                  } else setFiles(null);
                }}
                initialFiles={files}
                filesLimit={1}
              />
            </Grid>
          )}
          {serviceOperationProcedure.id && (
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button onClick={onDelete} color="secondary">
                {t("Sil")}
              </Button>
            </Grid>
          )}
        </Grid>
      </FaDialog>
    );
  };
  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {renderDialog()}
        <Card>
          <CardHeader
            action={
              <IconButton
                aria-label="settings"
                onClick={() => {
                  setDocuments([]);
                  setIsEditFormOpen(true);
                }}
              >
                <EditIcon />
              </IconButton>
            }
            title={t("PROCEDURE")}
          ></CardHeader>
          <ThingsDataTable
            searchUrl={
              SERVICE_OPERATION_CLASS.searchProcedure +
              "?accountId=" +
              accountId
            }
            searchForm={searchForm}
            columns={columns}
            ref={tableRef}
            showHeaderText={false}
            faOnDoubleClick={onDoubleClick}
            queryParams={{
              page: 0,
              size: 10,
              sort: "orderNo",
              isAsc: true,
            }}
          />
        </Card>
      </Suspense>
    </>
  );
}
