import {
  faCubes,
  faFolder,
  faGlobe,
  faHashtag,
  faMap,
  faSitemap,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Chip, Paper } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { Cancel } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import LocationAutoComplete from "components/autocomplete/LocationAutoComplete";
import OrganizationAutoComplete from "components/autocomplete/OrganizationAutoComplete";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import ThingClassAutoComplete from "components/autocomplete/ThingClassAutoComplete";
import UserAndGroupAutoComplete from "components/autocomplete/UserAndGroupAutoComplete";
import UserDetail from "components/detail/UserDetail";
import ThingHashtag from "components/hashtag/ThingHashtag";
import { default as React, Suspense, useEffect, useState } from "react";
import { FaButton, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { RecurrenceService } from "services/RecurrenceService";
import { ThingDesc, getDateTimeWFormat, isTNF } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import RecurrenceDetailHistory from "./RecurrenceDetailHistory";
import RecurrenceDetailRecurrence from "./RecurrenceDetailRecurrence";
import { useStyles } from "./style";

export default function RecurrenceDetail() {
  const classes = useStyles();
  const labelClasses = labelStyles();
  const { t } = useTranslation("recurrence", { i18n });
  const { id } = useParams();
  const accountId = localStorage.accountId;
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recurence, setRecurence] = useState({});
  const [updateVal, setUpdateVal] = useState({});

  const [sectionHashtag, setSectionHashtag] = useState(false);
  const [sectionThingClass, setSectionThingClass] = useState(false);
  const [sectionThing, setSectionThing] = useState(false);
  const [sectionOrganization, setSectionOrganization] = useState(false);
  const [sectionLocation, setSectionLocation] = useState(false);

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    setLoading(true);
    RecurrenceService.findById(id, findByIdOnSuccess, findByIdOnError);
  };

  const findByIdOnSuccess = (data) => {
    let tmp = {
      ...data,
      mtncRecurrenceTargets: data.mtncRecurrenceTargets
        ? data.mtncRecurrenceTargets.map((data) => {
            let dto = {
              id: data.id,
              thingClass: null,
              thing: null,
              hashtag: null,
              label: null,
              key: null,
            };

            if (data.hashtag) {
              dto.hashtag = data.hashtag;
              dto.label = data.hashtag;
              dto.key = "h_" + data.hashtag;
            } else if (data.thingClass) {
              dto.thingClass = data.thingClass;
              dto.label = data.thingClass.className;
              dto.key = "c_" + data.thingClass.id;
            } else if (data.thing) {
              dto.thing = data.thing;
              dto.label = ThingDesc(data.thing);
              dto.key = "t_" + data.id;
            } else if (data.organization) {
              dto.organization = data.organization;
              dto.label = data.organization.name;
              dto.key = "o_" + data.organization.id;
            } else if (data.location) {
              dto.location = data.location;
              dto.label = data.location.name;
              dto.key = "l_" + data.location.id;
            } else if (data.locationDetail) {
              dto.locationDetail = data.locationDetail;
              dto.label = data.locationDetail.name;
              dto.key = "ld_" + data.locationDetail.id;
            }

            return dto;
          })
        : [],
    };

    setRecurence({
      ...tmp,
    });
    setLoading(false);
  };

  const findByIdOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };

  const editButtonClicked = () => {
    setUpdateVal({ ...recurence });
    setIsModalOpen(true);
  };

  const saveRecurrence = () => {
    setLoading(true);
    RecurrenceService.save(
      {
        ...updateVal,
        account: { id: accountId },
      },
      saveSuccess,
      saveError
    );
  };

  const saveSuccess = (data) => {
    showAlert(!recurence.id ? t("SAVED") : t("EDITED"), "success");
    setIsModalOpen(false);
    setRecurence({
      ...data,
    });
    setLoading(false);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const passivation = () => {
    faDialog({
      description: t("Pasifleştirmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        setLoading(true);
        RecurrenceService.passivation(
          recurence.id,
          passivationSuccess,
          passivationError
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const passivationSuccess = (data) => {
    showAlert(t("PASSIVATED"), "success");
    setRecurence({
      ...data,
    });
    setLoading(false);
  };

  const passivationError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const activation = () => {
    faDialog({
      description: t("Aktifleştirmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        setLoading(true);
        RecurrenceService.activation(
          recurence.id,
          activationSuccess,
          activationError
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const activationSuccess = (data) => {
    showAlert(t("ACTIVATED"), "success");
    setRecurence({
      ...data,
    });
    setLoading(false);
  };

  const activationError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const handleChange = (prop, val) => {
    setUpdateVal({ ...updateVal, [prop]: val });
  };

  const handleChipDelete = (index) => () => {
    var temp = [...updateVal.mtncRecurrenceTargets];
    temp.splice(index, 1);
    setUpdateVal({ ...updateVal, mtncRecurrenceTargets: temp });
  };

  const addTarget = (newValue) => {
    setUpdateVal({
      ...updateVal,
      mtncRecurrenceTargets: [...updateVal.mtncRecurrenceTargets, newValue],
    });
  };

  const addTargetHashtag = (value) => {
    let dto = {
      thingClass: null,
      thing: null,
      organization: null,
      location: null,
      locationDetail: null,
      hashtag: value,
      label: value,
      key: "h_" + value,
    };

    addTarget(dto);
  };

  const addTargetThingClass = (value) => {
    let dto = {
      thingClass: { id: value.id },
      thing: null,
      hashtag: null,
      label: value.className,
      key: "c_" + value.id,
    };

    addTarget(dto);
  };

  const addTargetThing = (value) => {
    let dto = {
      thingClass: null,
      thing: { id: value.id },
      hashtag: null,
      label: ThingDesc(value),
      key: "t_" + value.id,
    };

    addTarget(dto);
  };

  const addTargetOrganization = (value) => {
    let dto = {
      organization: { id: value.id },
      label: value.name,
      key: "o_" + value.id,
    };

    addTarget(dto);
  };

  const addTargetLocation = (value) => {
    let dto = {
      location: { id: value.id },
      label: value.name,
      key: "l_" + value.id,
    };

    addTarget(dto);
  };

  const addTargetLocationDetail = (value) => {
    let dto = {
      locationDetail: { id: value.id },
      label: value.name,
      key: "ld_" + value.id,
    };

    addTarget(dto);
  };

  const deleteRecurrence = () => {
    faDialog({
      description: t("Periyodik Görevi silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        RecurrenceService.delete(
          recurence,
          (data) => {
            showAlert(t("Silindi"), "success");
            history.goBack();
          },
          (error) => {
            showAlert(t("Kayıt silinemedi, bağlı kayıtlar mevcut."), "error");
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const renderDialog = () => {
    return (
      <FaDialog
        title={"Periyodik Görev Bilgileri Güncelle"}
        faOpen={isModalOpen}
        faOnSubmit={saveRecurrence}
        showSaveButton
        faHandleClose={() => {
          setIsModalOpen(false);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <FaInput
              required
              value={updateVal.name}
              label={t("PERIODIC_TASK_NAME")}
              onChange={(event) => {
                handleChange("name", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              value={updateVal.description}
              label={t("PERIODIC_TASK_DESCRIPTION")}
              onChange={(event) => {
                handleChange("description", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <UserAndGroupAutoComplete
              value={{ user: updateVal.user, userGroup: updateVal.userGroup }}
              accountId={accountId}
              onChange={(data) => {
                setUpdateVal({
                  ...updateVal,
                  user: data.user,
                  userGroup: data.userGroup,
                });
              }}
              label={t("USER")}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">{t("TARGET_GROUP")}</Typography>

            <Paper component="ul" className={classes.chipRoot}>
              {updateVal?.mtncRecurrenceTargets?.map((data, index) => {
                let icon;

                if (data.thingClass) {
                  icon = <FontAwesomeIcon icon={faSitemap} />;
                } else if (data.thing) {
                  icon = <FontAwesomeIcon icon={faCubes} />;
                } else if (data.hashtag) {
                  icon = <FontAwesomeIcon icon={faHashtag} />;
                } else if (data.organization) {
                  icon = <FontAwesomeIcon icon={faFolder} />;
                } else if (data.location) {
                  icon = <FontAwesomeIcon icon={faGlobe} />;
                } else if (data.locationDetail) {
                  icon = <FontAwesomeIcon icon={faMap} />;
                }

                return (
                  <li key={data.key}>
                    <Chip
                      icon={icon}
                      label={data.label}
                      onDelete={handleChipDelete(index)}
                      className={classes.chip}
                    />
                  </li>
                );
              })}
            </Paper>
            <div className={classes.buttonPanel}>
              <FaButton
                faClick={() => {
                  setSectionHashtag(true);
                }}
                fullWidth={false}
                startIcon={<AddIcon />}
              >
                {t("ADD_HASHTAG")}
              </FaButton>
              <FaButton
                faClick={() => {
                  setSectionThingClass(true);
                }}
                fullWidth={false}
                startIcon={<AddIcon />}
              >
                {isTNF(t("ADD_THINGCLASS"), t("ADD_THINGCLASS_T2C"))}
              </FaButton>
              <FaButton
                faClick={() => {
                  setSectionThing(true);
                }}
                fullWidth={false}
                startIcon={<AddIcon />}
              >
                {isTNF(t("ADD_THING"), t("ADD_THING_T2C"))}
              </FaButton>
              <FaButton
                faClick={() => {
                  setSectionOrganization(true);
                }}
                fullWidth={false}
                startIcon={<AddIcon />}
              >
                {t("ADD_ORGANIZATION")}
              </FaButton>
              <FaButton
                faClick={() => {
                  setSectionLocation(true);
                }}
                fullWidth={false}
                startIcon={<AddIcon />}
              >
                {t("ADD_LOCATION")}
              </FaButton>
            </div>
            {sectionHashtag ? (
              <div className={classes.hboxBlock}>
                <ThingHashtag
                  accountId={accountId}
                  onChange={(data) => {
                    addTargetHashtag(data);
                    setSectionHashtag(false);
                  }}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<Cancel />}
                  className={classes.cancelButton}
                  onClick={() => {
                    setSectionHashtag(false);
                  }}
                >
                  {t("CANCEL")}
                </Button>
              </div>
            ) : null}

            {sectionThingClass ? (
              <div className={classes.hboxBlock}>
                <ThingClassAutoComplete
                  accountId={accountId}
                  onChange={(data) => {
                    addTargetThingClass(data);
                    setSectionThingClass(false);
                  }}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<Cancel />}
                  className={classes.cancelButton}
                  onClick={() => {
                    setSectionThingClass(false);
                  }}
                >
                  {t("CANCEL")}
                </Button>
              </div>
            ) : null}

            {sectionThing ? (
              <div className={classes.hboxBlock}>
                <ThingAutoComplete
                  accountId={accountId}
                  onChange={(data) => {
                    addTargetThing(data);
                    setSectionThing(false);
                  }}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<Cancel />}
                  className={classes.cancelButton}
                  onClick={() => {
                    setSectionThing(false);
                  }}
                >
                  {t("CANCEL")}
                </Button>
              </div>
            ) : null}
            {sectionOrganization ? (
              <div className={classes.hboxBlock}>
                <OrganizationAutoComplete
                  accountId={accountId}
                  onChange={(data) => {
                    addTargetOrganization(data);
                    setSectionOrganization(false);
                  }}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<Cancel />}
                  className={classes.cancelButton}
                  onClick={() => {
                    setSectionOrganization(false);
                  }}
                >
                  {t("CANCEL")}
                </Button>
              </div>
            ) : null}

            {sectionLocation ? (
              <div className={classes.hboxBlock}>
                <LocationAutoComplete
                  accountId={accountId}
                  onChange={(data) => {
                    addTargetLocation(data);
                    setSectionLocation(false);
                  }}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<Cancel />}
                  className={classes.cancelButton}
                  onClick={() => {
                    setSectionLocation(false);
                  }}
                >
                  {t("CANCEL")}
                </Button>
              </div>
            ) : null}
          </Grid>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button onClick={deleteRecurrence} color="secondary">
              {t("Sil")}
            </Button>
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  const renderTargets = () => {
    return (
      <ul className={classes.chipRoot}>
        {recurence?.mtncRecurrenceTargets?.map((data, index) => {
          let icon;
          let label;

          if (data.thingClass) {
            icon = <FontAwesomeIcon icon={faSitemap} />;
            label = data.thingClass.className;
          } else if (data.thing) {
            icon = <FontAwesomeIcon icon={faCubes} />;
            label = ThingDesc(data.thing);
          } else if (data.hashtag) {
            icon = <FontAwesomeIcon icon={faHashtag} />;
            label = data.hashtag;
          } else if (data.organization) {
            icon = <FontAwesomeIcon icon={faFolder} />;
            label = data.organization.name;
          } else if (data.location) {
            icon = <FontAwesomeIcon icon={faGlobe} />;
            label = data.location.name;
          } else if (data.locationDetail) {
            icon = <FontAwesomeIcon icon={faMap} />;
            label = data.locationDetail.name;
          }

          return (
            <li key={data.key}>
              <Chip icon={icon} label={label} className={classes.chip} />
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {renderDialog()}

        <Grid container spacing={1}>
          <Grid item lg={6} xs={12}>
            <Grid item container spacing={1}>
              <Grid item lg={12}>
                <Card>
                  <CardHeader
                    action={
                      <IconButton
                        aria-label="settings"
                        onClick={editButtonClicked}
                        disabled={recurence.status === "ACTIVE"}
                      >
                        <EditIcon />
                      </IconButton>
                    }
                    title={t("PERIODIC_TASK_DETAIL")}
                    data-fa-section="PERIODIC_TASK_DETAIL"
                  />
                  <CardContent>
                    <Grid item container spacing={0}>
                      <Grid item xs={12}>
                        <Grid
                          item
                          container
                          spacing={1}
                          style={{ marginLeft: 10, marginRight: 10 }}
                        >
                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("PERIODIC_TASK_NAME")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {recurence.name}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("PERIODIC_TASK_DESCRIPTION")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {recurence.description}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("USER")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {recurence?.user && (
                                <UserDetail
                                  value={recurence.user}
                                  type="USER"
                                />
                              )}
                              {recurence?.userGroup && (
                                <UserDetail
                                  value={recurence.userGroup}
                                  type="USER_GROUP"
                                />
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("TARGET_GROUP")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {renderTargets()}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("PREVIOUS_DATE")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {getDateTimeWFormat(recurence.previousDate)}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("NEXT_DATE")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {getDateTimeWFormat(recurence.nextDate)}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("STATUS")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {t("" + recurence.status)}
                            </Typography>
                          </Grid>

                          <Grid item xs={4}>
                            {recurence.status === "PASSIVE" && (
                              <FaButton
                                style={{ width: "40%" }}
                                variant="outlined"
                                size="small"
                                color="primary"
                                faClick={() => activation()}
                                data-fa-button="ACTIVATE"
                              >
                                {t("ACTIVATE")}
                              </FaButton>
                            )}
                            {recurence.status === "ACTIVE" && (
                              <FaButton
                                style={{ width: "40%" }}
                                variant="outlined"
                                size="small"
                                color="primary"
                                faClick={() => passivation()}
                                data-fa-button="DEACTIVATE"
                              >
                                {t("DEACTIVATE")}
                              </FaButton>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={6} xs={12}>
            <Grid item container spacing={1}>
              <Grid item xs={12}>
                {recurence && recurence.id > 0 && (
                  <RecurrenceDetailRecurrence
                    patrolDef={recurence}
                    setPatrolDef={setRecurence}
                  />
                )}
              </Grid>

              {/* <Grid item xs={12}>	
                {recurence && recurence.id > 0 && (	
                  <RecurrenceUsers recurrence={recurence} />	
                )}	
              </Grid> */}

              <Grid item xs={12}>
                {recurence && recurence.id > 0 && (
                  <RecurrenceDetailHistory patrolDef={recurence} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
