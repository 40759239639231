import { Grid } from "@material-ui/core";
import { Save } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { FaButton } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import EmailEditor from "react-email-editor";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { MessageTemplateService } from "services/MessageTemplateService";
import i18n from "../../i18n";

export default function MessageTemplateEmailEditor() {
  const emailEditorRef = useRef(null);
  const { t } = useTranslation(["messageTemplate", "components"], { i18n });

  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [messageTemplate, setMessageTemplate] = useState(null);
  let history = useHistory();

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    setLoading(true);
    MessageTemplateService.findById(
      id,
      (data) => {
        setMessageTemplate(data);
      },
      (error) => {
        showAlert(error.message, "error");
      }
    );
  };

  const saveForm = () => {
    // emailEditorRef.current.editor.exportJson((data) => {
    //   const { design, html } = data;
    //   console.log("exportHtml", html);
    // });

    emailEditorRef.current.editor.saveDesign((jsonData) => {
      emailEditorRef.current.editor.exportHtml((htmlData) => {
        const { design, html } = htmlData;

        const tempMessageTemplate = {
          ...messageTemplate,
          emailTemplate: html,
          emailTemplateJson: JSON.stringify(jsonData),
        };

        MessageTemplateService.save(
          tempMessageTemplate,
          saveSuccess,
          saveError
        );
      });
    });
  };

  const saveSuccess = (data) => {
    showAlert(!messageTemplate.id ? t("SAVED") : t("EDITED"), "success");
    setLoading(false);

    const tmpTo = {
      pathname: `/messageTemplateDetail/${messageTemplate.id}`,
      breadCrumbKey: `/messageTemplateDetail/:id`,
    };
    history.push(tmpTo);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const onLoad = () => {
    // if (messageTemplate && messageTemplate.emailTemplateJson) {
    //   console.log("emailTemplateJson hazır");
    // }
    // if (
    //   messageTemplate &&
    //   messageTemplate.emailTemplateJson &&
    //   emailEditorRef &&
    //   emailEditorRef.current &&
    //   emailEditorRef.current.editor
    // ) {
    //   console.log(
    //     "messageTemplate.emailTemplateJson",
    //     messageTemplate.emailTemplateJson
    //   );
    // }
    // setLoading(false);
  };

  const onReady = () => {
    // editor is ready
    console.log("onReady");

    emailEditorRef.current.editor.loadDesign(
      JSON.parse(messageTemplate.emailTemplateJson)
    );

    setLoading(false);
  };

  return (
    <div>
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item xs={1}>
          <FaButton
            variant="contained"
            disabled={loading}
            color="primary"
            size="medium"
            faClick={() => {
              saveForm();
            }}
            startIcon={<Save />}
          >
            {t("Kaydet")}
          </FaButton>
        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={6}></Grid>
      </Grid>

      {messageTemplate && (
        <EmailEditor ref={emailEditorRef} onLoad={onLoad} onReady={onReady} />
      )}
    </div>
  );
}
