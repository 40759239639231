import { faMagic } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Adsense from "components/adsense/Adsense";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import {
  FaButton,
  FaDialog,
  FaFileUpload,
  FaInput,
} from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { QRTEMPLATE } from "../../services/faApi";
import { QRTemplateService } from "../../services/qrTemplate/qrTemplateService";
import AccountPreferencesLabel from "./AccountPreferencesLabel";
import LabelDesigner from "./LabelDesigner";

export default function QRTemplate() {
  const tableRef = useRef();
  const faDialog = useDialog();
  const { t } = useTranslation("printQr", { i18n });
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const accountId = localStorage.accountId;
  const [files, setFiles] = useState([]);

  const columns = [
    {
      field: "name",
      label: t("LABEL_TEMPLATE"),
      align: "center",
      width: "30%",
      valueFormatter: (value, row) => {
        if (row.thumbnail != null)
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                alt=""
                src={`data:image/png;base64, ${row.thumbnail}`}
                style={{ width: "96px", marginRight: "10px" }}
              />{" "}
              {value}
            </div>
          );
        else
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="square"
                style={{ width: "96px", marginRight: "10px" }}
              >
                {" "}
                {value.charAt(0)}
              </Avatar>{" "}
              {value}
            </div>
          );
      },
    },
    {
      field: "description",
      label: t("DESCRIPTION"),
      align: "center",
      width: "30%",
    },
    {
      field: "createDate",
      label: t("CREATE_DATE"),
      align: "center",
      width: "20%",
      type: "smartDate",
    },
    {
      field: "lastChangeDate",
      label: t("LAST_CHANGE_DATE"),
      align: "center",
      width: "20%",
      type: "smartDate",
    },
  ];

  const [loading, setLoading] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isLabelDesignerOpen, setIsLabelDesignerOpen] = useState(false);

  const [isAccPreferencesOpen, setIsAccPreferencesOpen] = useState(false);

  const newQrTemplate = () => {
    return {
      id: 0,
      name: "",
      description: "",
      templatePath: "",
      width: 0,
      height: 0,
      userWidth: 0,
      userHeight: 0,
      qrX: 0,
      qrY: 0,
      qrWidth: 0,
      qrHeight: 0,
    };
  };

  const [qrTemplate, setQRTemplate] = useState(newQrTemplate());

  const handleChange = (prop) => (event) => {
    setQRTemplate({ ...qrTemplate, [prop]: event.target.value });
  };

  const saveSuccess = (data) => {
    showAlert(!qrTemplate.id ? t("SAVED") : t("EDITED"), "success");
    setIsEditFormOpen(false);
    setQRTemplate({
      id: 0,
      name: "",
      description: "",
      templatePath: "",
      width: 0,
      height: 0,
      userWidth: 0,
      userHeight: 0,
      qrX: 0,
      qrY: 0,
      qrWidth: 0,
      qrHeight: 0,
    });
    tableRef.current.refreshTable();
    setLoading(false);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const saveQRTemplate = () => {
    setLoading(true);
    QRTemplateService.save(
      {
        ...qrTemplate,
        account: { id: accountId },
      },
      files[0],
      saveSuccess,
      saveError
    );
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    setQRTemplate({ ...row });
    if (row.items) {
      setIsLabelDesignerOpen(true);
    } else {
      setIsEditFormOpen(true);
    }
  };

  const newQRTemplateRegisterRender = () => {
    return (
      <>
        <FaDialog
          title={"Etiket Şablonu Tanımlama"}
          faOpen={isEditFormOpen}
          faOnSubmit={saveQRTemplate}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            setIsEditFormOpen(data);
            setQRTemplate({
              id: 0,
              name: "",
              description: "",
              templatePath: "",
              width: 0,
              height: 0,
              userWidth: 0,
              userHeight: 0,
              qrX: 0,
              qrY: 0,
              qrWidth: 0,
              qrHeight: 0,
            });
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <FaInput
                maxLength={100}
                label={t("NAME")}
                onChange={handleChange("name")}
                value={qrTemplate.name || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                maxLength={100}
                label={t("DESCRIPTION")}
                onChange={handleChange("description")}
                value={qrTemplate.description || ""}
              />
            </Grid>
            <Grid item xs={6}>
              <FaInput
                label={t("LABEL_WIDTH")}
                faType="number"
                required
                onChange={handleChange("width")}
                value={qrTemplate.width}
              />
            </Grid>
            <Grid item xs={6}>
              <FaInput
                label={t("LABEL_HEIGHT")}
                faType="number"
                required
                onChange={handleChange("height")}
                value={qrTemplate.height}
              />
            </Grid>
            <Grid item xs={6}>
              <FaInput
                label={t("QR_CODE_X")}
                faType="number"
                required
                onChange={handleChange("qrX")}
                value={qrTemplate.qrX}
              />
            </Grid>
            <Grid item xs={6}>
              <FaInput
                label={t("QR_CODE_Y")}
                faType="number"
                required
                onChange={handleChange("qrY")}
                value={qrTemplate.qrY}
              />
            </Grid>
            <Grid item xs={6}>
              <FaInput
                label={t("QR_CODE_WIDTH")}
                faType="number"
                required
                onChange={handleChange("qrWidth")}
                value={qrTemplate.qrWidth}
              />
            </Grid>
            <Grid item xs={6}>
              <FaInput
                label={t("QR_CODE_HEIGHT")}
                faType="number"
                required
                onChange={handleChange("qrHeight")}
                value={qrTemplate.qrHeight}
              />
            </Grid>
            <Grid item xs={12}>
              <FaFileUpload
                maxFileSize={20000000}
                dropzoneText={() => {
                  return t("UPLOAD_PHOTO");
                }}
                onChange={setFiles}
                initialFiles={files}
                filesLimit={1}
              />
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  const saveLabelDesign = (templateData, imageFile, items) => {
    setLoading(true);
    QRTemplateService.save(
      {
        ...templateData,
        account: { id: accountId },
      },
      imageFile,
      saveSuccess,
      saveError
    );
    setIsLabelDesignerOpen(false);
  };

  const deleteLabel = (data) => {
    setLoading(true);
    faDialog({
      description: t("Kaydı silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        QRTemplateService.delete(
          qrTemplate,
          (data) => {
            setIsLabelDesignerOpen(false);
            setIsEditFormOpen(false);
            tableRef.current.refreshTable();
            setLoading(false);
          },
          (error) => {}
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const labelDesignRender = () => {
    return (
      isLabelDesignerOpen && (
        <LabelDesigner
          isOpen={isLabelDesignerOpen}
          onSave={saveLabelDesign}
          onClose={() => {
            setIsLabelDesignerOpen(false);
          }}
          onSwitch={() => {
            setIsLabelDesignerOpen(false);
            setIsEditFormOpen(true);
          }}
          onDelete={deleteLabel}
          value={qrTemplate}
        />
      )
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {newQRTemplateRegisterRender()}
        {labelDesignRender()}
        <div>
          <Adsense value="RklmUst" />
        </div>
        <ThingsDataTable
          dataUrl={QRTEMPLATE.findQRTemplate + "?accountId=" + accountId}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
          queryParams={{
            page: 0,
            size: 10,
            sort: "id",
            isAsc: false,
          }}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
          className={"thingsDataTableButtons"}
        >
          <Grid item xs={2}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                setQRTemplate(newQrTemplate());
                setIsLabelDesignerOpen(true);
              }}
              startIcon={<AddIcon />}
              data-fa-button="ADD"
            >
              {t("ADD")}
            </FaButton>
          </Grid>
          <Grid item xs={2}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                setQRTemplate(newQrTemplate());
                setIsEditFormOpen(true);
              }}
              startIcon={<FontAwesomeIcon icon={faMagic} />}
              data-fa-button="CREATE_CUSTOM_LABEL"
            >
              {t("CREATE_CUSTOM_LABEL")}
            </FaButton>
            {/* <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                setIsAccPreferencesOpen(true);
              }}
              startIcon={<Settings />}
            >
              {t("Etiket Ayarları")}
            </FaButton> */}
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={6}></Grid>
        </Grid>
        <AccountPreferencesLabel
          isOpen={isAccPreferencesOpen}
          onClose={() => {
            setIsAccPreferencesOpen(false);
          }}
        />
      </Suspense>
    </>
  );
}
