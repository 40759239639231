import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Link,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import GenericCarousel from "components/ui/GenericCarousel";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FaFileUpload } from "react-base-fa/dist/fa";
import { CampaignService } from "services/CampaignService";
import { CAMPAIGN } from "services/faApi";
import { ReportService } from "services/report/reportService";
import { GetFieldByLanguage } from "services/utils";
import { BASE_URL } from "serviceWorker";

export default function SurveyQuestionImage(props) {
  const { question, answer, onChange, readOnly, accountId, currentLang } =
    props;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const downloadDocument = (id) => {
    CampaignService.downloadCustomerAnswer(
      accountId,
      id,
      downloadDocumentOnSuccess,
      downloadDocuemntOnError
    );
  };

  const downloadDocumentOnSuccess = (data) => {
    const link = document.createElement("a");
    link.href = "data:application/octet-stream;base64," + data.content; //url;
    link.setAttribute("download", data.fileName);
    document.body.appendChild(link);
    link.click();
  };

  const downloadDocuemntOnError = (data) => {};

  const uploadTempFileOnSuccess = (data) => {
    if (data) onChange(question, data, "tempFileId");
  };

  return (
    <Grid item container alignItems="center" spacing={1}>
      {readOnly && (
        <GenericCarousel
          open={isDialogOpen}
          onClose={() => {
            setIsDialogOpen(false);
          }}
          label={"Görseller"}
          objectIdList={[answer?.dataFile]}
        />
      )}
      <Grid item xs={12}>
        <FormControl component="fieldset" required={question.notEmpty}>
          <Grid container item row xs={12}>
            <Grid item xs={12}>
              <FormLabel component="legend">
                {question.orderNo}.{" "}
                {GetFieldByLanguage(question.multiLangName, currentLang)}
              </FormLabel>
            </Grid>
            <Grid item xs={12}>
              <FormHelperText>
                {GetFieldByLanguage(question.multiLangHint, currentLang)}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 10 }}></Grid>
            {answer?.multipartFile && (
              <Grid item xs={12}>
                <Link
                  style={{ textDecoration: "underline" }}
                  onClick={() => {
                    //downloadDocument(values[index]?.dataFile);
                  }}
                >
                  {answer.multipartFile?.file?.name}
                </Link>
              </Grid>
            )}
            {answer?.multipartFile && (
              <Grid item xs={2}>
                <Button
                  size="small"
                  startIcon={<FontAwesomeIcon icon={faTrash} />}
                  onClick={() => {
                    onChange(
                      question,
                      null,
                      "multipartFile",
                      null,
                      "tempFileId"
                    );
                  }}
                  title="Sil"
                ></Button>
              </Grid>
            )}
            {readOnly && answer?.dataFile && (
              <Grid item xs={12}>
                <Link
                  style={{ textDecoration: "underline" }}
                  onClick={() => {
                    downloadDocument(answer.id);
                  }}
                >
                  İndir
                </Link>

                <Link
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => {
                    //downloadDocument(shoppingItem.id);
                    setIsDialogOpen(true);
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      alt="belge"
                      src={
                        BASE_URL +
                        CAMPAIGN.getCustomerImageHd +
                        "?accountId=" +
                        accountId +
                        "&objectId=" +
                        encodeURI(answer.dataFile)
                      }
                      style={{
                        maxWidth: "500px",
                        maxHeight: "500px",
                        marginRight: "10px",
                      }}
                    />
                  </div>
                </Link>
              </Grid>
            )}
            {!readOnly && !answer?.multipartFile && (
              <Grid item xs={12}>
                <FaFileUpload
                  maxFileSize={
                    question.maxFileSize == null
                      ? 20000000
                      : question.maxFileSize * 1000000
                  }
                  acceptedFiles={["image/*"]}
                  onChange={(data) => {
                    if (data != null && data.length > 0) {
                      let temp = data[0];
                      onChange(question, temp, "multipartFile");
                      ReportService.uploadTempFile(
                        accountId,
                        temp,
                        uploadTempFileOnSuccess,
                        (error) => {}
                      );
                    } else
                      onChange(
                        question,
                        null,
                        "multipartFile",
                        null,
                        "tempFileId"
                      );
                  }}
                  filesLimit={1}
                  dropZoneBoxProps={{
                    style: { minHeight: "50px", maxWidth: "500px" },
                  }}
                />
              </Grid>
            )}
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
  );
}

SurveyQuestionImage.propTypes = {
  question: PropTypes.any,
  answer: PropTypes.any,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};
