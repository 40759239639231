import { CardHeader } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Calendar from "features/calendar";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";

export default function HomepageCalendar(props) {
  const { size } = props;
  const { t } = useTranslation("components", { i18n });

  return (
    <Grid item lg={size} xs={12}>
      <Card>
        <CardHeader title={t("HOMEPAGE_ITEM.CALENDAR")} />
        <CardContent>
          <Grid container spacing={1}>
            <Calendar />
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
