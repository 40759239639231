import {makeStyles} from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  activeColor: {
    color: "#28a745",
  },
  passiveColor: {
    color: "#dc3545",
  },
  boldFont: {
    fontWeight: "bold",
  },

  card: {
    minWidth: 275,
    marginBottom: 12,
  },
  card_bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  card_title: {
    fontSize: 14,
  },
  card_pos: {
    marginBottom: 12,
  },
  card_media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
}));