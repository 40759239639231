import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { PATROL_DEF_THING } from "./faApi";

export const PatrolDefThingService = {
  save: (patrolDef, callback, errorCallback) => {
    let reqBody = {
      ...patrolDef,
    };

    let req = requests.getSimplePostRequest(PATROL_DEF_THING.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  saveAll: (thingList, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      PATROL_DEF_THING.saveAll,
      thingList
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (obj, callback, errorCallback) => {
    let reqBody = {
      ...obj,
    };

    let req = requests.getSimplePostRequest(PATROL_DEF_THING.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  moveUp: (patrolDefId, id, controlItemId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(PATROL_DEF_THING.moveUp, {
      patrolDefId,
      id,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  moveDown: (patrolDefId, id, controlItemId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(PATROL_DEF_THING.moveDown, {
      patrolDefId,
      id,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
