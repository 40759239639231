import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { LEGAL, LEGAL_TEXT } from "./faApi";

export const LegalTextService = {
  getLegalTextByType: (type, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      LEGAL_TEXT.getLegalTextByType + "?type=" + type
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  getLegal: (type, accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      LEGAL.getLegal + "?type=" + type + "&accountId=" + accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  save: (legalText, callback, errorCallback) => {
    let reqBody = {
      ...legalText,
    };

    let req = requests.getSimplePostRequest(LEGAL_TEXT.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(LEGAL_TEXT.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
  delete: (legalText, callback, errorCallback) => {
    let reqBody = {
      ...legalText,
    };

    let req = requests.getSimplePostRequest(LEGAL_TEXT.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
};
