import PropTypes from "prop-types";
import React from "react";

const imageLinks = {
  ios: process.env.PUBLIC_URL + "/assets/store/AppStore.svg",
  android: process.env.PUBLIC_URL + "/assets/store/GooglePlayStore.svg",
};

class MobileStoreButton extends React.Component {
  static propTypes = {
    store: PropTypes.oneOf(["ios", "android"]).isRequired,
    url: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
    linkStyles: PropTypes.object,
    linkProps: PropTypes.object,
  };
  static defaultProps = {
    height: 45,
    width: 153,
  };

  render() {
    const { store, url, height, width, linkStyles, linkProps, ...props } =
      this.props;

    const defaultLinkStyles = {
      background: `url(${imageLinks[store]}) no-repeat`,
      backgroundSize: "contain",
      display: "inline-block",
      overflow: "hidden",
      textDecoration: "none",
      height: "100%",
      width: "100%",
      padding: "5px",
      ...linkStyles,
    };

    return (
      <div style={{ height, width, display: "inline-block" }} {...props}>
        <a
          style={defaultLinkStyles}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          {...linkProps}
        >
          &nbsp;
        </a>
      </div>
    );
  }
}

export default MobileStoreButton;
