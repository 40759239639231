import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CampaignCustomerExportOrderSelect from "components/select/CampaignCustomerExportOrderSelect";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CampaignService } from "services/CampaignService";
import i18n from "../../i18n";

export default function CampaignCustomersExport(props) {
  const { campaign, open, onClose } = props;
  const { t } = useTranslation("campaign", { i18n });
  const mapRef = useRef();

  const [exportOrder, setExportOrder] = useState("REGISTER_DATE");

  const exportList = () => {
    const paging = { page: 0, size: 10000, isAsc: false, sort: "createDate" };
    const searchForm = {
      campaignId: campaign.id,
      status: "VERIFIED",
      exportOrder: exportOrder,
    };
    CampaignService.exportCustomers(
      searchForm,
      paging,
      exportCustomersOnSuccess,
      (error) => {}
    );
  };

  const exportCustomersOnSuccess = (data) => {
    const link = document.createElement("a");
    link.href = "data:application/octet-stream;base64," + data.content;
    link.setAttribute("download", data.fileName);
    document.body.appendChild(link);
    link.click();
  };

  return (
    open && (
      <Dialog
        open={open}
        onClose={() => {
          onClose();
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t("Kampanya Katılımcıları Dışarı Al")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid item container row>
              <Grid item xs={12}>
                <CampaignCustomerExportOrderSelect
                  value={exportOrder || ""}
                  required
                  onChange={(data) => {
                    setExportOrder(data);
                  }}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onClose();
            }}
            color="primary"
          >
            Kapat
          </Button>
          <Button
            onClick={() => {
              exportList();
            }}
            color="primary"
          >
            Liste Oluştur
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
}

CampaignCustomersExport.propTypes = {
  searchForm: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
