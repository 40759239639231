import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import LoadingBar from "components/loadingBar";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import i18n from "i18n";
import PropTypes from "prop-types";
import { default as React, Suspense, useEffect, useState } from "react";
import WordCloud from "react-d3-cloud";
import { useTranslation } from "react-i18next";
import { labelStyles } from "styles/ThingsLabelStyle";
import AnswerDistribution from "./AnswerDistribution";

export default function SurveyAnalysisText(props) {
  const { question, questionAnalysis } = props;

  const labelClasses = labelStyles();
  const { t } = useTranslation("survey", { i18n });
  const [words, setWords] = useState([]);

  console.log("questionAnalysis?.detailList", questionAnalysis?.detailList);

  const data = [
    { text: "Hey", value: 1 },
    { text: "lol", value: 200 },
    { text: "first impression", value: 80 },
    { text: "very cool", value: 100 },
    { text: "duck", value: 10 },
  ];

  useEffect(() => {
    let list = [];
    if (questionAnalysis && questionAnalysis.detailList) {
      questionAnalysis.detailList.forEach((item) => {
        list.push({ text: item.dataString, value: item.count });
        console.log(item.dataString, ": ", item.count);

        console.log("log: ", Math.log2(item.count) * 10);
      });
      setWords(list);
    }
  }, [questionAnalysis]);
  const schemeCategory10ScaleOrdinal = scaleOrdinal(schemeCategory10);

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid container spacing={1}>
          <Grid item lg={12}>
            <Grid item container spacing={1}>
              <Grid item lg={12}>
                <Card>
                  <CardHeader
                    title={
                      question.orderNo +
                      ". " +
                      (question?.multiLangName !== null
                        ? question?.multiLangName["tr"]
                        : "")
                    }
                  />
                  <CardContent>
                    <Grid item container spacing={1}>
                      <Grid item xs={12}>
                        <AnswerDistribution
                          questionAnalysis={questionAnalysis}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        {words && (
                          <WordCloud
                            data={words}
                            height={400}
                            font="Times New Roman"
                            fontWeight="bold"
                            fontSize={(word) => Math.log2(word.value) * 5 + 20}
                            spiral="archimedean"
                            rotate={(word) =>
                              word.value % 2 === 0
                                ? Math.random() * 45
                                : Math.random() * -45
                            }
                            random={Math.random}
                            fill={(d, i) => schemeCategory10ScaleOrdinal(i)}
                            // onWordClick={(event, d) => {
                            //   console.log(`onWordClick: ${d.text}`);
                            // }}
                            // onWordMouseOver={(event, d) => {
                            //   console.log(`onWordMouseOver: ${d.text}`);
                            // }}
                            // onWordMouseOut={(event, d) => {
                            //   console.log(`onWordMouseOut: ${d.text}`);
                            // }}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Seçenek</TableCell>
                                <TableCell>%</TableCell>
                                <TableCell>#</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {questionAnalysis.detailList.map(
                                (item, index) => (
                                  <TableRow key={index}>
                                    <TableCell
                                      className={labelClasses.LabelValue}
                                    >
                                      {item.dataString ?? "Boş"}
                                    </TableCell>
                                    <TableCell
                                      className={labelClasses.LabelValue}
                                    >
                                      {"%" + item.percentage}
                                    </TableCell>
                                    <TableCell
                                      className={labelClasses.LabelValue}
                                    >
                                      {item.count}
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                            <TableFooter>
                              <TableRow>
                                <TableCell colSpan={2}>Toplam</TableCell>
                                <TableCell>
                                  {questionAnalysis.detailList.reduce(
                                    (accumulator, item) => {
                                      return accumulator + item.count;
                                    },
                                    0
                                  )}
                                </TableCell>
                              </TableRow>
                            </TableFooter>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}

SurveyAnalysisText.propTypes = {
  question: PropTypes.any,
  questionAnalysis: PropTypes.any,
};
