import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { PERIODIC_REPORT } from "./faApi";

export const PeriodicReportService = {
  save: (pReport, callback, errorCallback) => {
    let reqBody = {
      ...pReport,
    };

    let req = requests.getSimplePostRequest(PERIODIC_REPORT.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  delete: (pReport, callback, errorCallback) => {
    let reqBody = {
      ...pReport,
    };

    let req = requests.getSimplePostRequest(PERIODIC_REPORT.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
  findById: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(PERIODIC_REPORT.findById + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  findDetails: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      PERIODIC_REPORT.findDetails + "?periodicReportId=" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  deleteDetail: (detail, callback, errorCallback) => {
    let reqBody = {
      ...detail,
    };

    let req = requests.getSimplePostRequest(
      PERIODIC_REPORT.deleteDetail,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
  findLogs: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      PERIODIC_REPORT.findLogs +
        "?id=" +
        id +
        "&page=0&size=10&sort=id&isAsc=true"
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  active: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(PERIODIC_REPORT.active + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  passive: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(PERIODIC_REPORT.passive + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  generate: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(PERIODIC_REPORT.generate + "/" + id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  saveDetail: (detail, callback, errorCallback) => {
    let reqBody = {
      ...detail,
    };

    let req = requests.getSimplePostRequest(
      PERIODIC_REPORT.saveDetail,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
