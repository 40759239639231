import { Avatar } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ThingsDataTable from "components/thingsDataTable";
import i18n from "i18n";
import { default as React, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BENEFICIARY_PORTAL } from "services/faApi";
import { getDateTimeWFormat } from "services/utils";

export default function MyInteractions(props) {
  const { campaign } = props;
  const accountId = localStorage.beneficiaryAccountId;
  const { t } = useTranslation(["beneficiaryPortal", "report", "components"], {
    i18n,
  });

  const tableRef = useRef();

  const newSearchForm = () => {
    return {
      accountId: accountId,
    };
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const columns = [
    {
      field: "date",
      label: t("Tarih"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        return getDateTimeWFormat(value);
      },
    },
    {
      field: "identifier",
      label: t("Ürün ve Hizmet"),
      align: "center",
      width: "40%",
      valueFormatter: (value, row) => {
        if (row.thumbnail != null)
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                alt="thing"
                src={`data:image/png;base64, ${row.thumbnail}`}
                style={{
                  width: "48px",
                  height: "48px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              />{" "}
              {value}
            </div>
          );
        else
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="square"
                style={{
                  width: "48px",
                  height: "48px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              >
                {" "}
                {value.charAt(0)}
              </Avatar>{" "}
              {value}
            </div>
          );
      },
    },
    {
      field: "operation",
      label: t("İşlem"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        return t("CUSTOMER_INTERACTION_OPERATION." + value, {
          ns: "components",
        });
      },
    },
  ];

  return (
    <Card>
      <CardHeader
        title={t("Geçmiş Etkileşimlerim")}
        data-fa-section="INTERACTIONS"
      />
      <ThingsDataTable
        searchUrl={BENEFICIARY_PORTAL.interactions}
        searchForm={searchForm}
        queryParams={{
          page: 0,
          size: 10,
          isAsc: false,
          sort: "date",
        }}
        columns={columns}
        ref={tableRef}
        showHeaderText={false}
      />
    </Card>
  );
}
