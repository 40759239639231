import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { default as React, useState } from "react";
import { FaDatePicker, FaDialog } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";

export default function MenuDate(props) {
  const { value, onChange, start, end } = props;
  const { t } = useTranslation("statistic", { i18n });
  const [anchorDateEl, setAnchorDateEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [dateRangeType, setDateRangeType] = useState(value);
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);

  const [modalStatSettings, setModalStatSettings] = useState({
    start: null,
    end: null,
  });

  const handleDateClick = (event) => {
    setAnchorDateEl(event.currentTarget);
  };

  const handleDateClose = () => {
    setAnchorDateEl(null);
  };

  const changeDateRangeType = (newDateRangeType) => {
    let start = null;
    var today = new Date();

    if (newDateRangeType === 1) {
      start = new Date(today);
      start.setDate(start.getDate() - 7);
    } else if (newDateRangeType === 2) {
      start = new Date(today);
      start.setDate(start.getDate() - 14);
    } else if (newDateRangeType === 3) {
      start = new Date(today.setMonth(today.getMonth() - 1));
    } else if (newDateRangeType === 4) {
      start = new Date(today.setMonth(today.getMonth() - 2));
    } else if (newDateRangeType === 5) {
      start = new Date(today.setMonth(today.getMonth() - 6));
    } else if (newDateRangeType === 6) {
      start = new Date(today.setMonth(today.getMonth() - 12));
    } else if (newDateRangeType === 7) {
      setIsModalOpen(true);
      setModalStatSettings({
        start: startDate,
        end: endDate,
      });
    }

    if (newDateRangeType !== 7) {
      setStartDate(start);
      setEndDate(null);
      setDateRangeType(newDateRangeType);
      onChange({ start, end: null, dateRangeType: newDateRangeType });
    }
    setAnchorDateEl(null);
  };

  const saveCustom = () => {
    setStartDate(modalStatSettings.start);
    setEndDate(modalStatSettings.end);
    setDateRangeType(7);
    onChange({
      start: modalStatSettings.start,
      end: modalStatSettings.end,
      dateRangeType: 7,
    });

    setIsModalOpen(false);
  };

  const handleChangeV = (prop, val) => {
    setModalStatSettings({ ...modalStatSettings, [prop]: val });
  };

  const renderDialog = () => {
    return (
      <FaDialog
        title={"Ayarlar"}
        faOpen={isModalOpen}
        faOnSubmit={saveCustom}
        showSaveButton
        faHandleClose={() => {
          setIsModalOpen(false);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <FaDatePicker
              faType="date"
              label={t("Başlangıç Tarihi")}
              value={modalStatSettings.start}
              faOnChange={(val) => {
                handleChangeV("start", val);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FaDatePicker
              faType="date"
              label={t("Bitiş Tarihi")}
              value={modalStatSettings.end}
              faOnChange={(val) => {
                handleChangeV("end", val);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={modalStatSettings.cumulative}
                  onChange={(event) => {
                    handleChangeV("cumulative", event.target.checked);
                  }}
                  name="cumulative"
                  color="primary"
                />
              }
              label={t("Kümülatif")}
            />
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  const renderMenu = () => {
    return (
      <>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleDateClick}
          startIcon={
            <FontAwesomeIcon icon={faCalendar} style={{ color: "#6F7782" }} />
          }
          data-fa-button="DATE_RANGE_BUTTON"
        >
          {t("DATE_RANGE_" + dateRangeType)}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorDateEl}
          keepMounted
          open={Boolean(anchorDateEl)}
          onClose={handleDateClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            onClick={() => {
              changeDateRangeType(1);
            }}
          >
            {t("Son 1 Hafta")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              changeDateRangeType(2);
            }}
          >
            {t("Son 2 Hafta")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              changeDateRangeType(3);
            }}
          >
            {t("Son 1 Ay")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              changeDateRangeType(4);
            }}
          >
            {t("Son 2 Ay")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              changeDateRangeType(5);
            }}
          >
            {t("Son 6 Ay")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              changeDateRangeType(6);
            }}
          >
            {t("Son 1 Yıl")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              changeDateRangeType(7);
            }}
          >
            {t("Özel")}
          </MenuItem>
        </Menu>
      </>
    );
  };

  return (
    <>
      {renderDialog()}
      {renderMenu()}
    </>
  );
}
