import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import timeGridPlugin from "@fullcalendar/timegrid"; // a plugin!
import Card from "@material-ui/core/Card";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
// import CampaignTargetEdit from "./CampaignTargetEdit";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import { CampaignService } from "services/CampaignService";
import { getDateTimeWFormat } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import CampaignCalendarEdit from "./CampaignCalendarEdit";
import { useStyles } from "./style";

export default function CampaignCalendar(props) {
  const { campaign } = props;
  const accountId = localStorage.accountId;
  const { t } = useTranslation("campaign", { i18n });
  const { id } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef();
  const dialogRef = useRef();
  const labelClasses = labelStyles();

  const [loading, setLoading] = useState(false);
  const [loadingCount, setLoadingCount] = useState(0);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [calendars, setCalendars] = useState([]);
  const [calendars22, setCalendars22] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    refresh();
  }, [campaign]);

  const refresh = () => {
    CampaignService.findCalendars(
      campaign.id,
      (data) => {
        let list = [];

        data.map((element) => {
          list.push(element);
        });

        list.push({
          name: "Başlangıç",
          eventDate: campaign.registerStartDate,
        });
        list.push({
          name: "Bitiş",
          eventDate: campaign.registerEndDate,
        });
        list.sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate));

        setCalendars([...list]);
      },
      (error) => {}
    );
  };

  const onDelete = (row) => {
    CampaignService.deleteCalendar(
      row,
      (data) => {
        refresh();
      },
      errorCallback
    );
  };

  const errorCallback = (error) => {
    showAlert(error, "error");
    setLoading(false);
  };
  const onDoubleClick = (row) => {
    if (campaign.status === "DRAFT") {
      setSelectedEvent(row);
      openEditDialog();
    }
  };
  const openEditDialog = (date) => {
    dialogRef.current.openDialog(date);
  };
  const editDialogRender = () => {
    return (
      <CampaignCalendarEdit
        campaign={campaign}
        ref={dialogRef}
        onSave={refresh}
        calendar={selectedEvent}
        onDelete={onDelete}
      ></CampaignCalendarEdit>
    );
  };

  const handleEventClick = (arg) => {
    const campaignCalendar = arg.event.extendedProps.campaignCalendar;

    if (campaignCalendar) {
      if (campaign.status === "DRAFT") {
        setSelectedEvent(campaignCalendar);
        openEditDialog();
      }
    }
  };

  function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  }

  const dateClick = (param) => {
    openEditDialog(param.date);
  };

  const calendarEvents2 = (arg, successCallback, failureCallback) => {
    // bind with an arrow function
    CampaignService.findCalendars(
      id,
      (data) => {
        let list = data.map((element) => {
          return {
            title: element.name,
            date: element.eventDate,
            extendedProps: {
              campaignCalendar: element,
            },
          };
        });
        list.push({
          title: "Başlangıç",
          date: campaign.registerStartDate,
          backgroundColor: "#38B64A",
        });
        list.push({
          title: "Bitiş",
          date: campaign.registerEndDate,
          backgroundColor: "#EF3133",
        });

        successCallback(list);
      },
      findCalendarOnError
    );
  };

  const handleCalendarListChange = (list) => {
    setCalendars(list);
  };

  const findCalendarOnError = (error) => {
    showAlert(error, "error");
    setLoading(false);
  };

  const esLocale = {
    code: "tr",
    week: {
      dow: 1, // Monday is the first day of the week.
      doy: 7, // The week that contains Jan 1st is the first week of the year.
    },
    buttonText: {
      prev: "geri",
      next: "ileri",
      today: "bugün",
      month: "Ay",
      week: "Hafta",
      day: "Gün",
      list: "Ajanda",
    },
    weekText: "Hf",
    allDayText: "Tüm gün",
    moreLinkText: "daha fazla",
    noEventsText: "Gösterilecek etkinlik yok",
  };

  const renderTable = () => (
    <TableContainer style={{ padding: 20 }}>
      <Table aria-label="simple table">
        <TableBody>
          {calendars &&
            calendars.length > 0 &&
            calendars.map((item, index) => (
              <TableRow key={index}>
                <TableCell align="center">
                  {getDateTimeWFormat(item.eventDate)}
                </TableCell>
                <TableCell align="center">{item.name}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {editDialogRender()}
        <Card>
          <CardHeader
            title={t("CAMPAIGN_CALENDAR")}
            data-fa-section="CAMPAIGN_CALENDAR"
            action={
              <IconButton
                disabled={campaign.status !== "DRAFT"}
                aria-label="settings"
                onClick={() => openEditDialog()}
              >
                <AddIcon />
              </IconButton>
            }
          />
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
            initialView="dayGridMonth"
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            eventClick={handleEventClick}
            eventContent={renderEventContent}
            events={calendarEvents2}
            height="auto"
            dayMaxEvents={5}
            locale="tr"
            locales={[esLocale]}
            eventDisplay="block"
            initialDate={campaign?.registerStartDate}
            dateClick={dateClick}
          />
          {renderTable()}
        </Card>
      </Suspense>
    </>
  );
}
