import { CardHeader } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "../thingDetail/style";
import UserContentPhotosCarousel from "./UserContentPhotosCarousel";

export default function UserContentPhotos(props) {
  const { images, setImages, userContentId } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("userContent", { i18n });
  const [selectedHdImageIndex, setSelectedHdImageIndex] = useState(0);

  const [isCarouselOpen, setCarouselOpen] = useState(false);

  const openCarousel = (index) => {
    setSelectedHdImageIndex(index);
    setCarouselOpen(true);
  };

  const closeCarousel = () => {
    setCarouselOpen(false);
  };

  return (
    <>
      <Dialog
        open={isCarouselOpen}
        aria-labelledby="form-dialog-title"
        onClose={closeCarousel}
      >
        <DialogContent>
          <UserContentPhotosCarousel
            userContentId={userContentId}
            setImages={setImages}
            images={images}
            selectedItem={selectedHdImageIndex}
          />
        </DialogContent>
      </Dialog>

      <Card
        style={{ minHeight: 250, overflowY: "auto" }}
        className="fai-photos"
      >
        <CardHeader title={t("PHOTOS")} data-fa-section="PHOTOS" />
        <CardContent>
          <Grid item container spacing={1}>
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            ></div>

            {images.map((item, index) => (
              <div key={index} className={classes.thingImageContainer}>
                <img
                  src={`data:image/png;base64, ${item.content}`}
                  className={classes.thingImage}
                  alt={item.object}
                  onClick={() => {
                    openCarousel(index);
                  }}
                />
              </div>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
