import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { LOCATION_DETAIL } from "./faApi";

export const LocationDetailService = {
  save: (locationDetail, callback, errorCallback) => {
    let reqBody = {
      ...locationDetail,
    };

    let req = requests.getSimplePostRequest(LOCATION_DETAIL.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  uploadDocument: (locationId, name, document, callback, errorCallback) => {
    let formData = new FormData();
    formData.append("multipartFile", document.file, document.file.name);
    formData.append("name", name);

    let req = requests.getSimplePostRequest(
      LOCATION_DETAIL.uploadDocument + "/" + locationId,
      formData
    );
    req["header"] = { "Content-Type": "multipart/form-data" };

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  update: (locationDetailId, name, document, callback, errorCallback) => {
    let formData = new FormData();
    formData.append("multipartFile", document.file, document.file.name);
    formData.append("name", name);

    let req = requests.getSimplePostRequest(
      LOCATION_DETAIL.update + "/" + locationDetailId,
      formData
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findById: (id, callback, errorCallback) => {
    let req = requests.getObjectByIdRequest(LOCATION_DETAIL.findById, id);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  upload: (id, image, callback, errorCallback) => {
    let formData = new FormData();
    formData.append("multipartFile", image, image.name);
    let req = requests.getSimplePostRequest(
      LOCATION_DETAIL.uploadFile + "/" + id,
      formData
    );
    req["header"] = { "Content-Type": "multipart/form-data" };

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  deleteFile: (id, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      LOCATION_DETAIL.deleteFile + "/" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findByLocation: (locationId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      LOCATION_DETAIL.findByLocation + "?locationId=" + locationId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  downloadDocument: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      LOCATION_DETAIL.downloadDocument + "/" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  delete: (locationDetail, callback, errorCallback) => {
    let reqBody = {
      ...locationDetail,
    };

    let req = requests.getSimplePostRequest(LOCATION_DETAIL.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  createCopy: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      LOCATION_DETAIL.createCopy + "/" + id
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
