import IssueTaskCard from "features/issueDetail/IssueTaskCard";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getRole } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AccPreferencesService } from "services/accPreferences/accPreferencesService";
import { TaskService } from "services/TaskService";
import { ROLES } from "services/utils/Roles";
import { checkRole } from "services/utils/SecurityUtils";
import { buttonStyles } from "styles/ThingsButtonStyle";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";

export default function TodoDetail() {
  const labelClasses = labelStyles();
  const { t } = useTranslation(["issue", "components"], { i18n });
  const { id } = useParams();
  const accountId = localStorage.accountId;
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const dialogRef = useRef();
  const buttonClasses = buttonStyles();

  const userRole = useSelector(getRole);
  const isSLA = checkRole([ROLES.SLA], userRole);

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [loading, setLoading] = useState(false);
  const [todo, setTodo] = useState({});
  const [issuePaused, setIssuePaused] = useState(null);
  const [accPreferences, setAccPreferences] = useState(null);

  useEffect(() => {
    if (accountId)
      AccPreferencesService.findByAccount(accountId, (data) => {
        setAccPreferences(data);
      });
  }, [accountId]);

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    setLoading(true);
    TaskService.findById(id, findByIdOnSuccess, findByIdOnError);
  };

  const findByIdOnSuccess = (data) => {
    setTodo({
      ...data,
    });
    setIssuePaused(data.issues[0].paused);
    setLoading(false);
  };

  const findByIdOnError = (error) => {
    showAlert(error?.message, "error");
    setLoading(false);
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <IssueTaskCard
          issue={todo.issue}
          firstTask={todo}
          taskOnChange={refresh}
          accPreferences={accPreferences}
        />
      </Suspense>
    </>
  );
}
