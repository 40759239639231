import React from "react";
import { Handle, Position } from "react-flow-renderer";
import { useStyles } from "./flowNodeStyles";

export function CircularNode({ data }) {
  const classes = useStyles();

  return (
    <div className={classes.circularNode}>
      {data?.targetHandleIds &&
        data.targetHandleIds.map((id, index) => (
          <Handle
            type="target"
            position={Position.Top}
            id={id + "_" + data.id}
            style={{ left: 65 + 20 * index }}
          />
        ))}
      <label>{data.label}</label>
      <Handle type="source" position={Position.Bottom} id="a" />
    </div>
  );
}

export function UserNode({ data }) {
  const classes = useStyles();

  return (
    <div className={classes.userNode}>
      {data.targetHandleIds.map((id, index) => (
        <Handle
          type="target"
          position={Position.Top}
          id={id + "_" + data.id}
          style={{ left: 65 + 20 * index }}
        />
      ))}
      <label>{data.label}</label>
      <Handle type="source" position={Position.Bottom} id="a" />
    </div>
  );
}
