import Issues from "features/issues";
import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import i18n from "../../i18n";
import { useStyles } from "./style";
import ThingsSteps from "./ThingsSteps";
import TutorialElement from "./TutorialElement";

export default function AssignTaskTutorial() {
  const { code } = useParams();
  const classes = useStyles();

  const stepsRef = useRef();
  let history = useHistory();

  const { t } = useTranslation("tutorialAssignTask", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        element: "#menuCustomerInteraction", // parent menu
        intro: (
          <TutorialElement
            icon="click"
            text={"Bildirilen Sorunları izlemek için tıklayın."}
          />
        ),
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "#menuIssues", // child menu
        intro: (
          <TutorialElement icon="click">
            {t(
              "Açık ve Kapalı tüm sorunları Liste halinde izlemek için tıklayın."
            )}
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          var element = document.querySelector("#menuCustomerInteraction");
          if (element.childElementCount > 1 == false)
            $("#menuCustomerInteraction")[0].firstElementChild.click();
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: null,
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/tutorial/sorunlar_list.png"
                }
                width="740"
                height="256"
              ></img>
            </div>
            <br />
            {"Görevlendirme yapmak istediğiniz Açık bir soruna çift tıklayın."}
          </TutorialElement>
        ),
        comp: "Issues",
        tooltipClass: classes.sorunlarListClass,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: null,
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/gorevlendir_butonu.png"
                }
                width="543"
                height="453"
              ></img>
            </div>
            <br />
            {"Görevlendirme yapmak için tıklayın."}
          </TutorialElement>
        ),
        tooltipClass: classes.gorevlendirButonuClass,
        comp: "Issues",
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: null,
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/gorev_olustur_dialog.png"
                }
                width="649"
                height="360"
              ></img>
            </div>
            <br />
            {
              "Görev atayacağınız Kullanıcı’yı seçin, açıklama girin, Termin Tarihi’ni seçin ve Kaydet’e tıklayın."
            }
          </TutorialElement>
        ),
        tooltipClass: classes.gorevOlusturDialogClass,
        comp: "Issues",
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: null,
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/gorev_sorumlusu.png"
                }
                width="434"
                height="413"
              ></img>
            </div>
            <br />
            {"Görev ataması tamamlanmıştır."}
          </TutorialElement>
        ),
        comp: "Issues",
        // tooltipClass: classes.gorevSorumlusuClass,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
    ]);
  }, []);

  return (
    <div>
      {steps &&
        steps.length > stepIndex &&
        steps[stepIndex].comp === "Issues" && <Issues />}

      <ThingsSteps
        code={code}
        steps={steps}
        ref={stepsRef}
        onStepChange={setStepIndex}
        afterComplete={() => {
          window.history.back();
          $("#menuCustomerInteraction")[0].firstElementChild.click();
        }}
      />
    </div>
  );
}
