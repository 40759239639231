import PropTypes from "prop-types";
import React from "react";
import { useStyles } from "../LandingTemplate10Style";
export default function LandingHeader(props) {
  const { publicThing, miniAppLogo } = props;
  const classes = useStyles();

  return (
    <div className={classes.banner}>
      <div className={classes.bannerLeft}>
        {publicThing.logo && (
          <img
            src={"data:image/jpeg;base64," + publicThing.logo}
            alt="logo"
            className={classes.appLogo}
          />
        )}

        {!publicThing.logo && (
          <img src={miniAppLogo} alt="logo" className={classes.appLogo} />
        )}
      </div>
      <div className={classes.bannerTitle}>{publicThing.accountName}</div>
      <div className={classes.bannerRight}>
        <img
          src={process.env.PUBLIC_URL + "/assets/things_no_logo.png"}
          alt="logo"
          className={classes.headerLogo}
        />
      </div>
    </div>
  );
}

LandingHeader.propTypes = {
  loginPath: PropTypes.string,
  onLinkClick: PropTypes.any,
  appLogo: PropTypes.any,
  miniAppLogo: PropTypes.any,
};
