import {
  CardHeader,
  FormControlLabel,
  Switch,
  withStyles,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import { green, grey } from "@material-ui/core/colors";
import ThingsDataTable from "components/thingsDataTable";
import React, { useRef } from "react";
import { getDateTimeWFormat } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { POLICY_LOG } from "services/faApi";
import { IssueDesc } from "services/utils";
import { gridStyles } from "styles/ThingsGridStyle";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function PolicyLog(props) {
  const { policy } = props;
  const classes = useStyles();
  const tableRef = useRef();
  const gridClasses = gridStyles();
  let history = useHistory();

  const { t } = useTranslation("policy", { i18n });

  const GreenSwitch = withStyles({
    switchBase: {
      color: grey[300],
      "&$checked": {
        color: green[500],
      },
      "&$checked + $track": {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const columns = [
    {
      field: "createDate",
      label: t("Tarih"),
      align: "center",
      width: "25%",
      valueFormatter: (value) => {
        return getDateTimeWFormat(value);
      },
    },
  ];

  if (
    policy &&
    (policy.targetAction === "NEW_ISSUE" ||
      policy.targetAction === "DISPATCH_ORGANIZATION")
  ) {
    columns.push({
      field: "issue",
      label: t("ISSUE_STATUS"),
      align: "center",
      width: "25%",
      valueFormatter: (value, row) => {
        if (row.issue.status === "OPEN") {
          return <FormControlLabel control={<GreenSwitch checked={true} />} />;
        } else {
          return <FormControlLabel control={<Switch checked={false} />} />;
        }
      },
    });

    columns.push({
      field: "issue",
      label: t("ISSUE"),
      align: "center",
      width: "50%",
      valueFormatter: (val, row) => IssueDesc(row.issue),
    });
  }

  const onDoubleClick = (row) => {
    if (policy.targetAction === "NEW_ISSUE") {
      const tmpTo = {
        pathname: `/issues/detail/${row.issue.id}`,
        breadCrumbKey: `/issues/detail/:id`,
      };
      history.push(tmpTo);
    }
  };

  return (
    <>
      <Card style={{ minHeight: 100 }} className="fai-thingLinks">
        <CardHeader title={t("POLICY_LOG")} data-fa-section="POLICY_LOG" />
        {policy && policy.id > 0 && (
          <ThingsDataTable
            dataUrl={POLICY_LOG.findLogs + "?id=" + policy.id}
            queryParams={{
              page: 0,
              size: 10,
              sort: "createDate",
              isAsc: false,
            }}
            columns={columns}
            ref={tableRef}
            showDeleteButton={false}
            showHeaderText={false}
            headerText={t("POLICY_LOG")}
            showHeader={false}
            columnClassName={gridClasses.GenericColumn}
            faOnDoubleClick={onDoubleClick}
          />
        )}
      </Card>
    </>
  );
}
