import React from "react";
import { getRole } from "react-base-fa/dist/services/sessionSlice";
import { useSelector } from "react-redux";
import { ROLES } from "services/utils/Roles";
import { checkRole } from "services/utils/SecurityUtils";
import { useStyles } from "./style";

export default function Adsense(props) {
  const { value } = props;
  const classes = useStyles();

  const role = useSelector(getRole);
  const isFree = checkRole([ROLES.FREE], role);

  const getAdvertXY = () => {
    let adType = null;
    let xy = { x: 0, y: 0 };

    if (
      value === "RklmAlt" || // alta konan ve üstündeki bileşene göre ortalanmış gösterilen default reklam
      value === "RklmTabloAlt" || // tablo altlarına konulanlar
      value === "RklmDetayAlt" // detay ekran altlarına konulanlar
    ) {
      adType = "Leaderboard";
    } else if (
      value === "RklmAltMin" // ek padding olması istenmeyen alt reklam
    ) {
      adType = "Leaderboard";
    } else if (value === "RklmUst") {
      adType = "Large_Leaderboard";
    } else if (value === "RklmSag") {
      adType = "Wide_Skyscraper";
    } else if (value === "RklmKare") {
      adType = "Square";
    }

    if (adType === "Small_Square") {
      xy = { x: 200, y: 200 };
    } else if (adType === "Square") {
      xy = { x: 250, y: 250 };
    } else if (adType === "Triple_Widescreen") {
      xy = { x: 50, y: 360 };
    } else if (adType === "Vertical_Rectangle") {
      xy = { x: 240, y: 400 };
    } else if (adType === "Medium_Rectangle") {
      xy = { x: 300, y: 250 };
    } else if (adType === "Netboard") {
      xy = { x: 580, y: 400 };
    } else if (adType === "Large_Rectangle") {
      xy = { x: 336, y: 280 };
    } else if (adType === "Wide_Skyscraper") {
      xy = { x: 160, y: 600 };
    } else if (adType === "Skyscraper") {
      xy = { x: 120, y: 600 };
    } else if (adType === "Portrait") {
      xy = { x: 300, y: 1050 };
    } else if (adType === "Half_Page") {
      xy = { x: 300, y: 600 };
    } else if (adType === "Leaderboard") {
      xy = { x: 728, y: 90 };
    } else if (adType === "Large_Leaderboard") {
      xy = { x: 970, y: 90 };
    } else if (adType === "Billboard") {
      xy = { x: 970, y: 250 };
    } else if (adType === "Top_Banner") {
      xy = { x: 930, y: 180 };
    } else if (adType === "Panaroma") {
      xy = { x: 980, y: 120 };
    } else {
      xy = { x: 0, y: 0 };
    }

    return xy;
  };

  const getClassName = () => {
    if (
      value === "RklmAlt" ||
      value === "RklmTabloAlt" ||
      value === "RklmDetayAlt"
    )
      return classes.adDivAlt;
    else return classes.adDiv;
  };

  const renderAd = () => {
    if (isFree) {
      const xy = getAdvertXY();

      if (xy.x > 0 && xy.y > 0) {
        return (
          <div className={getClassName()}>
            <a href="https://filosoft.fortearge.com" target="_blank">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/a/f_" +
                  xy.x +
                  "x" +
                  xy.y +
                  ".png"
                }
                className={classes.adImage}
                alt="reklam"
              ></img>
            </a>
          </div>
        );
      } else return <></>;
    } else {
      return <></>;
    }
  };

  return renderAd();
}
