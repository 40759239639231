import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { useStyles } from "../style";
import ThingsSteps from "../ThingsSteps";
import TutorialElement from "../TutorialElement";

export default function CustomerInteractionsLikes() {
  const stepsRef = useRef();

  const { t } = useTranslation("tutorialCustomerInteractionsAll", { i18n });
  const classes = useStyles();

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            Müşteri <b>Beğeni</b> ve <b>Sosyal Medya Beğenilerinin</b> toplu
            liste olarak gösterildiği Menü/Sayfadır.
            <p>
              Bu menüde bildirilen Beğeni ve Sosyal Medya Beğenilerini;
              <ul>
                <li>
                  <b>Tarih</b>'e göre görebilirsiniz.
                </li>

                <li>
                  <b>Etkileşim noktasına </b> göre filtreleyebilirsiniz.
                </li>
                <li>
                  <b>Etkileşim noktasına </b> göre listeleyebilirsiniz.
                </li>
                <li>
                  <b>Excel </b>dosyası olarak dışarı aktarabilirsiniz.
                </li>
                <li>
                  <b>Beğeni</b> detay bilgilerine erişebilirsiniz.
                </li>
              </ul>
            </p>
          </TutorialElement>
        ),
      },
      {
        element: "#dateSpeedFilter",
        intro: (
          <TutorialElement icon="click">
            Bildirilen sorunları Tarih’e göre listelemek için <b>“Bugün”</b>,
            <b>“Son 7 Gün”</b> ve <b>“Son 1 Ay”</b> seçeneklerinden birine
            tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-column='likeDate']",
        intro: (
          <TutorialElement icon="click">
            Bildirilen sorunların tümünü <b>ilk</b> veya <b>son</b> bildirim
            tarihine göre listelemek için tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-column='thing']",
        intro: (
          <TutorialElement icon="click">
            Bildirilen sorunların tümünü <b>Etkileşim Noktalarına</b> göre
            listelemek için tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-column='accessLogRecords']",
        intro: (
          <TutorialElement icon="click">
            Beğenilerin tümünü <b>Sosyal Medya</b>’ya göre listelemek için
            tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: ".appSearchBar",
        intro: (
          <TutorialElement icon="click">
            Hızlı arama için Ara’ya ve detaylı arama için Filtre’ye tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: ".MuiButton-iconSizeSmall",
        intro: (
          <TutorialElement icon="click">
            Tarih gösterimini ayarlamak için tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: null,
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/issues_date_settings.png"
                }
                width="700"
                height="261"
              ></img>
            </div>
            <br />
            Görünüm’e tıklayın.
          </TutorialElement>
        ),
        tooltipClass: classes.issueDateSettings,
      },
      {
        element: null,
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/issues_date_settings.png"
                }
                width="700"
                height="261"
              ></img>
            </div>
            <br />
            <b>Açık Tarih</b> veya <b>Akıllı Tarih</b>’ten birini seçin ve{" "}
            <b>Uygula</b> butonuna basın.
            <br />
            <br />
            <b>ÖNEMLİ NOT</b> :<br />
            <b>Açık Tarih</b>, etkileşim noktası ile ilgili her bir işlemin
            gerçekleştiği tarih ve saat bilgilerini içerir.
            <b>Akıllı Tarih</b> ise işlemin gerçekleşmesi sonrasında geçen
            süreyi içerir.
          </TutorialElement>
        ),
        tooltipClass: classes.issueDateSettings,
      },
      {
        element: "div.MuiTablePagination-selectRoot", // sayfa basina kayit sayisi
        intro: (
          <TutorialElement icon="click">
            Sayfa başına Sorun Sayısını ayarlamak için tıklayın ve 10, 25, 50 ve
            100 seçeneklerinden birini seçin.
          </TutorialElement>
        ),
        position: "top",
      },
      {
        element: ".MuiTablePagination-actions",
        intro: (
          <TutorialElement icon="click">
            Sayfalar arasında geçiş yapmak için {"“<”"} ve {"“>”"} seçeneklerine
            tıklayın.
          </TutorialElement>
        ),
        position: "top",
      },
      {
        element: "[title='Excel']",
        intro: (
          <TutorialElement icon="click">
            Listeyi <b>Dışarı Aktarmak</b> için <b>Excel</b> ikonuna tıklayın.
          </TutorialElement>
        ),
        position: "top",
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/begeni_list_element.png"
                }
                width="721"
                height="256"
              ></img>
            </div>
            <br />
            Detay bilgilerine erişmek istediğiniz <b>Beğeni</b>’nin üzerine{" "}
            <b>çift</b> tıklayın.
          </TutorialElement>
        ),
        tooltipClass: classes.issueListElement,
      },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={"likes"} // veri tabanındaki kodla aynı olması önemli
        steps={steps}
        ref={stepsRef}
        onStepChange={setStepIndex}
        // otherOptions={{ scrollToElement: false }}
      />
    </div>
  );
}
