import { Avatar, Grid, IconButton } from "@material-ui/core";
import { ExpandLess, ExpandMore, List } from "@material-ui/icons";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaButton, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { THINGCLASS } from "services/faApi";
import { isTNF } from "services/utils";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { useStyles } from "./style";
import ThingList from "./ThingList";
import "./things.css";

export default function ThingsByClass() {
  const { t } = useTranslation(["thingClass", "errorMessages"], { i18n });
  let history = useHistory();
  const tableRef = useRef();

  const accountId = localStorage.accountId;
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const newSearchForm = () => {
    let result = {
      genericClassName: null,
      className: null,
      domain: null,
      accountId: accountId,
      systemType: null,
      domain: null,
      minThingCount: 1,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const [selectedRows, setSelectedRows] = useState([]);

  const detailSelected = (index) => {
    if (!selectedRows.includes(index)) {
      setSelectedRows([...selectedRows, index]);
    }
  };

  const detailUnSelected = (index) => {
    const newArr = selectedRows.filter((element) => element !== index);
    setSelectedRows([...newArr]);
  };

  const columns = [
    {
      field: "id",
      label: t("Detay"),
      align: "center",
      width: "5%",
      valueFormatter: (value, row, index) => {
        if (selectedRows.includes(index)) {
          return (
            <IconButton
              onClick={() => {
                detailUnSelected(index);
              }}
              size="small"
              color="primary"
              aria-label={t("Kapat")}
            >
              <ExpandLess />
            </IconButton>
          );
        } else {
          return (
            <IconButton
              onClick={() => {
                detailSelected(index);
              }}
              size="small"
              color="primary"
              aria-label={t("Aç")}
            >
              <ExpandMore />
            </IconButton>
          );
        }
      },
    },
    {
      field: "className",
      label: isTNF(
        t("GENERIC_CLASSNAME", { ns: "thingClass" }),
        t("GENERIC_CLASSNAME_T2C", { ns: "thingClass" })
      ),
      align: "center",
      width: "30%",
      valueFormatter: (value, row) => {
        if (row.thumbnail != null)
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={`data:image/png;base64, ${row.thumbnail}`}
                style={{ width: "48px", marginRight: "10px" }}
                alt="thumbnail"
              />{" "}
              {value}
            </div>
          );
        else
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="square"
                style={{ width: "48px", marginRight: "10px" }}
              >
                {" "}
                {value.charAt(0)}
              </Avatar>{" "}
              {value}
            </div>
          );
      },
    },
    {
      field: "count",
      label: t("Varlık Sayısı"),
      align: "center",
      width: "10%",
    },
    {
      field: "parent",
      label: t("PARENT"),
      align: "center",
      width: "27%",
      inner: "className",
      valueFormatter: (parent) => {
        if (parent) {
          return parent.className;
        }
      },
    },
    { field: "domain", label: t("DOMAIN"), align: "center", width: "28%" },
    {
      field: "id",
      label: t("ACCOUNT"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        return row.account ? row.account.fullname : t("SYSTEM");
      },
    },
  ];

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `thingClassDetail/${row.id}`,
      breadCrumbKey: `/thingClassDetail/:id`,
    };
    history.push(tmpTo);
  };

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const rowDetailRender = (row, rowIndex) => {
    return <ThingList thingClassId={row.id} />;
  };

  const onListByThing = () => {
    const tmpTo = {
      pathname: `things`,
      breadCrumbKey: `/things`,
      search: "?page=0&size=10&isAsc=false&sort=createDate",
    };
    history.push(tmpTo);
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <ThingsDataTable
          searchUrl={THINGCLASS.search + "?accountId=" + localStorage.accountId}
          exportUrl={THINGCLASS.export + "?accountId=" + localStorage.accountId}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
          queryParams={{
            page: 0,
            size: 10,
            isAsc: false,
            sort: "count",
            accountId: localStorage.accountId,
          }}
          faDetailRows={selectedRows}
          faDetailRender={rowDetailRender}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={2} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={onListByThing}
              startIcon={<List />}
              data-fa-button="LIST"
              style={{ marginLeft: 70 }}
            >
              {t("THINGS_LIST")}
            </FaButton>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <FaInput
                label={t("GENERIC_CLASSNAME")}
                value={searchForm.className}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "className",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("DOMAIN")}
                value={searchForm.domain}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "domain",
                    event.target.value
                  );
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
