import { SvgIcon } from "@material-ui/core";
import { default as React } from "react";

export default function SurveyIcon(props) {
  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <rect x="44.873" y="21.973" width="37.37" height="6.228" />
      <path d="M19.96,34.429h18.685V15.745H19.96V34.429z M26.188,21.973h6.228v6.228h-6.228V21.973z" />
      <rect x="44.873" y="46.886" width="37.37" height="6.228" />
      <rect x="44.873" y="71.799" width="37.37" height="6.228" />
      <path d="M19.96,84.255h18.685V65.571H19.96V84.255z M26.188,71.799h6.228v6.228h-6.228V71.799z" />
      <polygon points="26.188,60.632 40.846,45.974 36.443,41.57 26.188,51.825 22.161,47.798 17.758,52.202 	" />
    </SvgIcon>
  );
}
