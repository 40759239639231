import React from "react";
import { FaAutoComplete } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { ISSUECLASS } from "../../services/faApi";

export default function IssueClassAutoComplete(props) {
  const { value, accountId, onChange, required, thingClassId, label } = props;

  const { t } = useTranslation("components", { i18n });

  return (
    <FaAutoComplete
      faType="async"
      value={value}
      getOptionSelected={(option, value) => {
        return option.id === value.id;
      }}
      sort="className"
      faFilterProp="className"
      getOptionLabel={(option) => (option.className ? option.className : "")}
      requestUrl={
        thingClassId
          ? ISSUECLASS.findByThingClass +
            "?accountId=" +
            accountId +
            "&thingClassId=" +
            thingClassId
          : ISSUECLASS.findClassName + "?accountId=" + accountId
      }
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      label={label ?? t("ISSUE_CLASS")}
      required={required}
    />
  );
}
