import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CampaignSurveyQuestionRender from "features/campaign/CampaignSurveyQuestionRender";
import i18n from "i18n";
import PropTypes from "prop-types";
import React, { createRef, useEffect, useState } from "react";
import { selectThemePrefer } from "react-base-fa/dist/AppSlice";
import { FaButton } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
// import useStyles from "react-base-fa/dist/fa/faUnAuthPages/style";
import { getCurrentLang } from "react-base-fa/dist/services/utils";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import "react-puzzle-confirm/react-puzzle-confirm.css";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FingerPrintService } from "services/FingerPrintService";
import { GpsLocationService } from "../../../services/location/GpsLocationService";
import { ReportService } from "../../../services/report/reportService";
import LandingHeader from "../landing/header/LandingHeader";
import { useStyles } from "../ReportIssueStyle";

export default function RegisterCampaign(props) {
  const { appLogo, miniAppLogo } = props;
  const { id, thingId, accountId } = useParams();
  const classes = useStyles();
  const { t } = useTranslation("report", { i18n });
  const history = useHistory();
  const formRef = createRef();

  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [gpsError, setGpsError] = useState(false);
  const lang = getCurrentLang();
  const location = useLocation();
  const [publicThing, setPublicThing] = useState(location?.state?.publicThing);

  const [isShowNextButton, setIsShowNextButton] = useState(false);

  const [visitorId, setVisitorId] = useState(null);
  const [blockReport, setBlockReport] = useState(false);

  const [campaign, setCampaign] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [selectedLang, setSelectedLang] = useState("tr");
  const [anchor, setAnchor] = useState(null);

  useEffect(() => {
    if (location.state && location.state.publicThing) {
      GpsLocationService.getLocation(getLocationOnSuccess, getLocationOnError);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    FingerPrintService.getVisitorId(visitorIdOnSuccess, (error) => {
      visitorIdOnSuccess(null);
    });
  }, []);

  const visitorIdOnSuccess = (dataVisitorId) => {
    setVisitorId(dataVisitorId);
    setCampaignCustomer((prev) => {
      return { ...prev, visitorId: dataVisitorId };
    });
    ReportService.checkVisitor(
      publicThing.accountId,
      dataVisitorId,
      "CUSTOMER",
      publicThing.id,
      (data) => {
        if (data === false) {
          setBlockReport(true);
        }
      },
      (error) => {}
    );
  };

  useEffect(() => {
    ReportService.getCampaign(
      id,
      thingId,
      accountId,
      getCampaignOnSuccess,
      getCampaignOnError
    );
  }, []);

  const getCampaignOnSuccess = (data) => {
    setCampaign(data);

    let answers = [];
    data.questionList.forEach((element) => {
      answers.push({
        campaignQuestion: { id: element.id },
        dataString: "",
        dataNumeric: null,
        dataDate: null,
        dataBool: null,
        dataFile: null,
        comment: "",
      });
    });

    setCampaignCustomer((prev) => {
      return { ...prev, answers };
    });
  };

  const getCampaignOnError = (error) => {
    history.goBack();
    showAlert(t("QUOTA_FULL"), "error"); // TODO
  };

  const getLocationOnSuccess = (data) => {
    setCampaignCustomer((prev) => {
      return { ...prev, gpsLocation: JSON.stringify(data) };
    });
  };

  const getLocationOnError = (data) => {
    setGpsError(data);
  };

  const [campaignCustomer, setCampaignCustomer] = useState({
    account: { id: accountId },
    thing: { id: thingId },
    campaign: { id: id },
    gpsLocation: null,
    answers: [],
    startDate: new Date(),
  });

  const [reported, setReported] = useState(false);

  const theme = useSelector(selectThemePrefer);
  const dispatch = useDispatch();

  const submitCampaign = () => {
    setLoading(true);

    let completionTime =
      (new Date().getTime() - campaignCustomer.startDate.getTime()) / 1000;
    const data = { ...campaignCustomer, completionTime: completionTime };

    if (campaign.userVerificationType) {
      let tmpTo = {
        pathname: `/report/customer/tid=${publicThing.tid}`,
        state: {
          publicThing: {
            ...publicThing,
          },
          campaignCustomer: data,
          userVerificationType: campaign.userVerificationType,
        },
      };
      history.push(tmpTo);
    } else {
      setSending(true);
      ReportService.registerCampaign(
        data,
        submitCampaignOnSuccess,
        submitCampaignOnError
      );
    }
  };

  const submitCampaignOnSuccess = (data) => {
    setLoading(false);
    setReported(true);
    setSending(false);

    let tmpTo = {
      pathname: `/report/registerCampaignResult/1`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const submitCampaignOnError = (error) => {
    setLoading(false);
    showAlert(t(error?.data) ?? t("SUBMIT_ERROR_MSG"), "error");
    history.goBack();
  };

  const onChangeAnswer = (question, value, field, value2, field2) => {
    setCampaignCustomer((prev) => {
      let tempAnswers = [...prev.answers];
      tempAnswers[question.orderNo - 1] = {
        ...tempAnswers[question.orderNo - 1],
        [field]: value,
      };

      if (field2) {
        tempAnswers[question.orderNo - 1] = {
          ...tempAnswers[question.orderNo - 1],
          [field2]: value2,
        };
      }

      return { ...prev, answers: tempAnswers };
    });
  };

  const findAnswer = (item) => {
    const result = campaignCustomer.answers.filter(
      (i) => i.campaignQuestion.id === item.id
    )[0];

    return result;
  };

  const getCampaignPageContent = () => {
    let selectedPage = campaign.pageList.filter(
      (item) => item.orderNo === activePage
    );
    if (selectedPage && selectedPage.length > 0) selectedPage = selectedPage[0];
    else selectedPage = null;

    if (!selectedPage) return;

    let pageQuestions = campaign.questionList.filter(
      (item) => item.campaignPage.id === selectedPage.id
    );

    return (
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
        style={{ paddingBottom: 50 }}
      >
        <Grid item xs={12}>
          <h5>{selectedPage.multiLangName?.[selectedLang]}</h5>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ fontSize: "20px" }}>
            {selectedPage.multiLangDescription?.[selectedLang]}
          </Typography>
        </Grid>
        {pageQuestions &&
          pageQuestions.map((item, index) => {
            return (
              <Grid item xs={12}>
                <CampaignSurveyQuestionRender
                  key={index}
                  question={item}
                  onChangeAnswer={onChangeAnswer}
                  answer={findAnswer(item)}
                  editable={true}
                  readOnly={false}
                  accountId={accountId}
                  currentLang={selectedLang}
                />
              </Grid>
            );
          })}
      </Grid>
    );
  };

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const renderReported = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        component={Paper}
        elevation={6}
        style={{
          height: "100vh",
          width: "100vw",
          margin: "10px",
          paddingLeft: "5px",
          paddingRight: "5px",
        }}
      >
        <div>
          <Grid
            item
            container
            spacing={3}
            lg={8}
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              lg={10}
              xs={12}
              style={{ textAlign: "center", marginBottom: 92 }}
            >
              <LandingHeader
                publicThing={publicThing}
                miniAppLogo={miniAppLogo}
              />
            </Grid>
          </Grid>
        </div>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <div style={{ textAlign: "center" }}>
              <CheckCircleOutlineIcon
                style={{ fontSize: "64px", color: green[500] }}
              />
              <Typography style={{ fontSize: "20px" }}>
                Kampanya katılımınız başarıyla gerçekleştirilmiştir, lütfen daha
                sonra tekrar deneyin.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={5}>
            <FaButton
              faType="button"
              size="large"
              label={t("Geri")}
              onClick={() => {
                history.goBack();
              }}
              style={{ height: "60px" }}
            ></FaButton>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (activePage === campaign.pageList.length) {
      submitCampaign();
    } else if (activePage !== campaign.pageList.length) {
      nextPage();
    }
  };

  const nextPage = () => {
    setActivePage((prev) => prev + 1);
  };

  const previousPage = () => {
    if (activePage === 1) navigateMainPage();
    else setActivePage((prev) => prev - 1);
  };

  const navigateMainPage = () => {
    let tmpTo = {
      pathname: `/q/${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };
  const handleClick = (event) => {
    setAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setAnchor(null);
  };
  const onMenuItemClick = (event, index) => {
    setAnchor(null);
    setSelectedLang(index);
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ minHeight: "100vh", width: "100vw" }}
    >
      <>
        {blockReport && renderReported()}
        {!blockReport && (
          <form id="campaign-form" ref={formRef} validate="true">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              component={Paper}
              elevation={6}
              style={{
                minHeight: "100vh",
                width: "100vw",
                margin: "10px",
                paddingLeft: "5px",
                paddingRight: "5px",
              }}
            >
              <div>
                <Grid
                  item
                  container
                  spacing={3}
                  lg={8}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid
                    item
                    lg={10}
                    xs={12}
                    style={{ textAlign: "center", marginBottom: 92 }}
                  >
                    <LandingHeader
                      publicThing={publicThing}
                      miniAppLogo={miniAppLogo}
                    />
                  </Grid>
                </Grid>
                <div>
                  <Typography variant="h5">{campaign?.name}</Typography>
                </div>
                <div>
                  {campaign && campaign.thumbnail && (
                    <img
                      src={"data:image/jpeg;base64," + campaign.thumbnail}
                      alt="CampaignImage"
                      style={{ maxHeight: 200 }}
                    />
                  )}
                </div>
              </div>
              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Grid item xs={12}></Grid>
                {!sending && (
                  <>
                    {campaign &&
                      campaign.languages &&
                      campaign.languages.length > 0 && (
                        <Grid item xs={12}>
                          <IconButton
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                            size="small"
                            style={{
                              color: "#532363",
                              marginRight: "10px",
                              fontSize: "22px",
                            }}
                            title={selectedLang.toUpperCase()}
                          >
                            <Typography style={{ marginRight: 5 }}>
                              Dil:{" "}
                            </Typography>
                            <ReactCountryFlag
                              countryCode={
                                selectedLang === "en"
                                  ? "US"
                                  : selectedLang.toUpperCase()
                              }
                              svg
                            />
                          </IconButton>
                          <Menu
                            id="simple-menu"
                            anchorEl={anchor}
                            keepMounted
                            open={Boolean(anchor)}
                            onClose={handleClose}
                          >
                            {campaign &&
                              campaign.languages &&
                              campaign.languages.map((lang) => (
                                <MenuItem
                                  onClick={(event) =>
                                    onMenuItemClick(event, lang.toLowerCase())
                                  }
                                >
                                  <ReactCountryFlag
                                    countryCode={
                                      lang === "EN" ? "US" : lang.toUpperCase()
                                    }
                                    svg
                                  />
                                </MenuItem>
                              ))}
                          </Menu>
                        </Grid>
                      )}
                    <Grid item xs={12}>
                      {campaign &&
                        campaign.pageList &&
                        campaign.questionList &&
                        getCampaignPageContent()}
                    </Grid>
                  </>
                )}
                {sending && (
                  <Grid item xs={12}>
                    <div style={{ textAlign: "center" }}>
                      <Typography style={{ fontSize: "20px" }}>
                        Kaydınız gönderiliyor, lütfen bekleyiniz...
                      </Typography>
                      <LinearProgress />
                    </div>
                  </Grid>
                )}

                {!sending && campaign && campaign.pageList && (
                  <>
                    {!sending && campaign && campaign.pageList && (
                      <div className={classes.footer}>
                        <div className={classes.actionButtons2Div}>
                          {activePage >= 1 && (
                            <div style={{ flex: 1 }}>
                              <Button
                                className={classes.backButton}
                                onClick={previousPage}
                              >
                                {t("Geri")}
                              </Button>
                            </div>
                          )}

                          {activePage !== campaign.pageList.length && (
                            <div style={{ flex: 1 }}>
                              <Button
                                className={classes.nextButton}
                                onClick={onSubmit}
                              >
                                {t("İleri")}
                              </Button>
                            </div>
                          )}
                          {activePage === campaign.pageList.length && (
                            <div style={{ flex: 1 }}>
                              <Button
                                className={classes.nextButton}
                                onClick={onSubmit}
                              >
                                {campaign.userVerificationType
                                  ? t("Devam")
                                  : t("Katıl")}
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </Grid>
            </Box>
          </form>
        )}
      </>
    </Box>
  );
}

RegisterCampaign.propTypes = {
  loginPath: PropTypes.string,
  appLogo: PropTypes.any,
};
