import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { RESERVATION } from "./faApi";

export const ReservationService = {
  save: (reservation, callback, errorCallback) => {
    let reqBody = {
      ...reservation,
    };

    let req = requests.getSimplePostRequest(RESERVATION.save, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  delete: (reservation, callback, errorCallback) => {
    let reqBody = {
      ...reservation,
    };

    let req = requests.getSimplePostRequest(RESERVATION.delete, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
  changeStatus: (reservationId, value, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      RESERVATION.changeStatus +
        "?reservationId=" +
        reservationId +
        "&value=" +
        value
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },

  passive: (reservationId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      RESERVATION.passive + "?reservationId=" + reservationId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },

  active: (reservationId, callback, errorCallback) => {
    let req = requests.getSimplePostRequest(
      RESERVATION.active + "?reservationId=" + reservationId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return () => {
      //setLoading(false);
    };
  },
};
