import {
  faCubes,
  faFolder,
  faGlobe,
  faHashtag,
  faMap,
  faSitemap,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Tab,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Cancel";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import LocationAutoComplete from "components/autocomplete/LocationAutoComplete";
import OrganizationAutoComplete from "components/autocomplete/OrganizationAutoComplete";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import ThingClassAutoComplete from "components/autocomplete/ThingClassAutoComplete";
import UserAndGroupAutoComplete from "components/autocomplete/UserAndGroupAutoComplete";
import UserDetail from "components/detail/UserDetail";
import ThingHashtag from "components/hashtag/ThingHashtag";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import {
  FaButton,
  FaDatePicker,
  FaDialog,
  FaInput,
  FaSearch,
} from "react-base-fa/dist/fa";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RecurrenceDesc, ThingDesc, isTNF } from "services/utils";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { RECURRENCE } from "../../services/faApi";
import DynamicRecurrenceEdit from "./DynamicRecurrenceEdit";
import { useStyles } from "./style";

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function Recurrence() {
  const { t } = useTranslation("recurrence", { i18n });
  const classes = useStyles();
  const accountId = localStorage.accountId;
  const userInfo = useSelector(getUserInfo);
  let history = useHistory();
  const dialogRef = useRef();
  const tableRef = useRef();
  // States
  const [loading, setLoading] = useState(false);

  const [sectionHashtag, setSectionHashtag] = useState(false);
  const [sectionThingClass, setSectionThingClass] = useState(false);
  const [sectionThing, setSectionThing] = useState(false);
  const [sectionOrganization, setSectionOrganization] = useState(false);
  const [sectionLocation, setSectionLocation] = useState(false);

  const [newTaskOpen, setNewTaskOpen] = useState(false);

  const newRecurrence = () => {
    return {
      id: 0,
      name: "",
      description: "",
      account: {
        id: accountId,
      },
      user: {
        id: userInfo.id,
      },
      status: "PASSIVE",
      dateType: "LITERAL",
      userGroup: null,
      frequency: "hour",
      hourlyInterval: 8,

      dailyMod: "INTERVAL", //WEEKDAY
      dailyInterval: 1,

      weeklyInterval: 1,
      weeklyDays: [false, false, false, false, false, false, false],

      monthlyDay: 1,
      monthlyInterval: 1,

      yearlyInterval: 1,
      yearlyDay: 1,
      yearlyMonth: 0,

      startTime: new Date(2021, 1, 1, 12, 0, 0),
      startDate: new Date(),
      endDate: null,

      mtncRecurrenceTargets: [],
    };
  };

  const [recurence, setRecurence] = useState(newRecurrence());

  const handleTabChange = (event, newValue) => {
    setRecurence({ ...recurence, frequency: newValue });
  };

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `/recurrence/detail/${row.id}`,
      breadCrumbKey: `/recurrence/detail/:id`,
    };
    history.push(tmpTo);
  };

  const addTarget = (newValue) => {
    setRecurence({
      ...recurence,
      mtncRecurrenceTargets: [...recurence.mtncRecurrenceTargets, newValue],
    });
  };

  const addTargetHashtag = (value) => {
    let dto = {
      thingClass: null,
      thing: null,
      organization: null,
      location: null,
      locationDetail: null,
      hashtag: value,
      label: value,
      key: "h_" + value,
    };

    addTarget(dto);
  };

  const addTargetThingClass = (value) => {
    let dto = {
      thingClass: { id: value.id },
      thing: null,
      hashtag: null,
      label: value.className,
      key: "c_" + value.id,
    };

    addTarget(dto);
  };

  const addTargetThing = (value) => {
    let dto = {
      thingClass: null,
      thing: { id: value.id },
      hashtag: null,
      label: ThingDesc(value),
      key: "t_" + value.id,
    };

    addTarget(dto);
  };

  const addTargetOrganization = (value) => {
    let dto = {
      organization: { id: value.id },
      label: value.name,
      key: "o_" + value.id,
    };

    addTarget(dto);
  };

  const addTargetLocation = (value) => {
    let dto = {
      location: { id: value.id },
      label: value.name,
      key: "l_" + value.id,
    };

    addTarget(dto);
  };

  const addTargetLocationDetail = (value) => {
    let dto = {
      locationDetail: { id: value.id },
      label: value.name,
      key: "ld_" + value.id,
    };

    addTarget(dto);
  };

  const onRowEdit = (row) => {
    setNewTaskOpen(true);

    let tmp = {
      ...row,
      mtncRecurrenceTargets: row.mtncRecurrenceTargets
        ? row.mtncRecurrenceTargets.map((data) => {
            let dto = {
              id: data.id,
              thingClass: null,
              thing: null,
              hashtag: null,
              label: null,
              key: null,
            };

            if (data.hashtag) {
              dto.hashtag = data.hashtag;
              dto.label = data.hashtag;
              dto.key = "h_" + data.hashtag;
            } else if (data.thingClass) {
              dto.thingClass = data.thingClass;
              dto.label = data.thingClass.className;
              dto.key = "c_" + data.thingClass.id;
            } else if (data.thing) {
              dto.thing = data.thing;
              dto.label = ThingDesc(data.thing);
              dto.key = "t_" + data.id;
            } else if (data.organization) {
              dto.organization = data.organization;
              dto.label = data.organization.name;
              dto.key = "o_" + data.organization.id;
            } else if (data.location) {
              dto.location = data.location;
              dto.label = data.location.name;
              dto.key = "l_" + data.location.id;
            } else if (data.locationDetail) {
              dto.locationDetail = data.locationDetail;
              dto.label = data.locationDetail.name;
              dto.key = "ld_" + data.locationDetail.id;
            }

            return dto;
          })
        : [],
    };

    setRecurence(tmp);
  };

  const columns = [
    {
      field: "name",
      label: t("PERIODIC_TASK_NAME"),
      align: "center",
      width: "10%",
    },
    {
      field: "user",
      label: t("USER"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        return row.user ? (
          <UserDetail value={row.user} type="USER" />
        ) : (
          <UserDetail value={row.userGroup} type="USER_GROUP" />
        );
      },
    },
    {
      field: "previousDate",
      label: t("PREVIOUS_DATE"),
      align: "center",
      width: "10%",
      type: "dateTime",
    },
    {
      field: "nextDate",
      label: t("NEXT_DATE"),
      align: "center",
      width: "10%",
      type: "dateTime",
    },
    {
      field: "frequency",
      label: "Tekrarlanma",
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        return RecurrenceDesc(row, t);
      },
    },
    {
      field: "mtncRecurrenceTargets",
      label: t("TARGET_GROUP"),
      align: "center",
      width: "40%",
      valueFormatter: (value) => {
        return (
          <ul className={classes.chipRoot}>
            {value?.map((data, index) => {
              let icon;
              let label;

              if (data.thingClass) {
                icon = <FontAwesomeIcon icon={faSitemap} />;
                label = data.thingClass.className;
              } else if (data.thing) {
                icon = <FontAwesomeIcon icon={faCubes} />;
                label = ThingDesc(data.thing);
              } else if (data.hashtag) {
                icon = <FontAwesomeIcon icon={faHashtag} />;
                label = data.hashtag;
              } else if (data.organization) {
                icon = <FontAwesomeIcon icon={faFolder} />;
              } else if (data.location) {
                icon = <FontAwesomeIcon icon={faGlobe} />;
              } else if (data.locationDetail) {
                icon = <FontAwesomeIcon icon={faMap} />;
              }

              return (
                <li key={data.key}>
                  <Chip icon={icon} label={label} className={classes.chip} />
                </li>
              );
            })}
          </ul>
        );
      },
    },
    {
      field: "status",
      label: t("STATUS"),
      align: "center",
      width: "10%",
      valueFormatter: (status) => {
        return t("" + status);
      },
    },
  ];

  const saveTask = () => {
    const recurrenceReqObject = {
      ...recurence,
      hourlyInterval:
        recurence.frequency === "hour" ? recurence.hourlyInterval : null,
      dailyMod: recurence.frequency === "day" ? recurence.dailyMod : null,
      dailyInterval:
        recurence.frequency === "day" ? recurence.dailyInterval : null,

      weeklyInterval:
        recurence.frequency === "week" ? recurence.weeklyInterval : null,
      weeklyDays:
        recurence.frequency === "week"
          ? recurence.weeklyDays
          : [false, false, false, false, false, false, false],

      monthlyDay: recurence.frequency === "month" ? recurence.monthlyDay : null,
      monthlyInterval:
        recurence.frequency === "month" ? recurence.monthlyInterval : null,

      yearlyInterval:
        recurence.frequency === "year" ? recurence.yearlyInterval : null,
      yearlyDay: recurence.frequency === "year" ? recurence.yearlyDay : null,
      yearlyMonth:
        recurence.frequency === "year" ? recurence.yearlyMonth : null,
    };

    const reqrecurence = requests.getSimplePostRequest(RECURRENCE.save, {
      ...recurrenceReqObject,
    });
    FaAxios(reqrecurence)
      .then(() => {
        tableRef.current.refreshTable();
        setNewTaskOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (prop, val) => {
    setRecurence({ ...recurence, [prop]: val });
  };

  const handleChangeWeeklyDays = (index, checked) => {
    var wd = recurence.weeklyDays;
    wd[index] = checked;

    setRecurence({ ...recurence, weeklyDays: wd });
  };

  const handleChipDelete = (index) => () => {
    var temp = [...recurence.mtncRecurrenceTargets];
    temp.splice(index, 1);
    setRecurence({ ...recurence, mtncRecurrenceTargets: temp });
  };

  const newSearchForm = () => {
    let result = {
      user: null,
      userId: null,
      description: null,
      accountId: accountId,
    };

    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm({
      user: null,
      userId: null,
      description: null,
      accountId: accountId,
    });
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const renderNewTaskDialog = () => {
    return (
      <FaDialog
        title={t("NEW_PERIODIC_TASK")}
        faOpen={newTaskOpen}
        faOnSubmit={() => saveTask()}
        loading={loading}
        faHandleClose={() => setNewTaskOpen(false)}
        showSaveButton
        faMaxWidth="lg"
      >
        <Grid item container spacing={1}>
          <Grid item lg={6} xs={12}>
            <FaInput
              required
              value={recurence.name}
              label={t("PERIODIC_TASK_NAME")}
              onChange={(event) => {
                handleChange("name", event.target.value);
              }}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <FaInput
              value={recurence.description}
              label={t("PERIODIC_TASK_DESCRIPTION")}
              onChange={(event) => {
                handleChange("description", event.target.value);
              }}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <UserAndGroupAutoComplete
              value={{ user: recurence.user, userGroup: recurence.userGroup }}
              accountId={accountId}
              onChange={(data) => {
                setRecurence({
                  ...recurence,
                  user: data.user,
                  userGroup: data.userGroup,
                });
              }}
              label={t("USER")}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">{t("TARGET_GROUP")}</Typography>

            <Paper component="ul" className={classes.chipRoot}>
              {recurence.mtncRecurrenceTargets.map((data, index) => {
                let icon;

                if (data.thingClass) {
                  icon = <FontAwesomeIcon icon={faSitemap} />;
                } else if (data.thing) {
                  icon = <FontAwesomeIcon icon={faCubes} />;
                } else if (data.hashtag) {
                  icon = <FontAwesomeIcon icon={faHashtag} />;
                } else if (data.organization) {
                  icon = <FontAwesomeIcon icon={faFolder} />;
                } else if (data.location) {
                  icon = <FontAwesomeIcon icon={faGlobe} />;
                } else if (data.locationDetail) {
                  icon = <FontAwesomeIcon icon={faMap} />;
                }

                return (
                  <li key={data.key}>
                    <Chip
                      icon={icon}
                      label={data.label}
                      onDelete={handleChipDelete(index)}
                      className={classes.chip}
                    />
                  </li>
                );
              })}
            </Paper>
            <div className={classes.buttonPanel}>
              <FaButton
                faClick={() => {
                  setSectionHashtag(true);
                }}
                fullWidth={false}
                startIcon={<AddIcon />}
              >
                {t("ADD_HASHTAG")}
              </FaButton>
              <FaButton
                faClick={() => {
                  setSectionThingClass(true);
                }}
                fullWidth={false}
                startIcon={<AddIcon />}
              >
                {isTNF(t("ADD_THINGCLASS"), t("ADD_THINGCLASS_T2C"))}
              </FaButton>
              <FaButton
                faClick={() => {
                  setSectionThing(true);
                }}
                fullWidth={false}
                startIcon={<AddIcon />}
              >
                {isTNF(t("ADD_THING"), t("ADD_THING_T2C"))}
              </FaButton>
              <FaButton
                faClick={() => {
                  setSectionOrganization(true);
                }}
                fullWidth={false}
                startIcon={<AddIcon />}
              >
                {t("ADD_ORGANIZATION")}
              </FaButton>
              <FaButton
                faClick={() => {
                  setSectionLocation(true);
                }}
                fullWidth={false}
                startIcon={<AddIcon />}
              >
                {t("ADD_LOCATION")}
              </FaButton>
            </div>
            {sectionHashtag ? (
              <div className={classes.hboxBlock}>
                <ThingHashtag
                  accountId={accountId}
                  onChange={(data) => {
                    addTargetHashtag(data);
                    setSectionHashtag(false);
                  }}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<CancelIcon />}
                  className={classes.cancelButton}
                  onClick={() => {
                    setSectionHashtag(false);
                  }}
                >
                  {t("CANCEL")}
                </Button>
              </div>
            ) : null}

            {sectionThingClass ? (
              <div className={classes.hboxBlock}>
                <ThingClassAutoComplete
                  accountId={accountId}
                  onChange={(data) => {
                    addTargetThingClass(data);
                    setSectionThingClass(false);
                  }}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<CancelIcon />}
                  className={classes.cancelButton}
                  onClick={() => {
                    setSectionThingClass(false);
                  }}
                >
                  {t("CANCEL")}
                </Button>
              </div>
            ) : null}

            {sectionThing ? (
              <div className={classes.hboxBlock}>
                <ThingAutoComplete
                  accountId={accountId}
                  onChange={(data) => {
                    addTargetThing(data);
                    setSectionThing(false);
                  }}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<CancelIcon />}
                  className={classes.cancelButton}
                  onClick={() => {
                    setSectionThing(false);
                  }}
                >
                  {t("CANCEL")}
                </Button>
              </div>
            ) : null}
            {sectionOrganization ? (
              <div className={classes.hboxBlock}>
                <OrganizationAutoComplete
                  accountId={accountId}
                  onChange={(data) => {
                    addTargetOrganization(data);
                    setSectionOrganization(false);
                  }}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<CancelIcon />}
                  className={classes.cancelButton}
                  onClick={() => {
                    setSectionOrganization(false);
                  }}
                >
                  {t("CANCEL")}
                </Button>
              </div>
            ) : null}

            {sectionLocation ? (
              <div className={classes.hboxBlock}>
                <LocationAutoComplete
                  accountId={accountId}
                  onChange={(data) => {
                    addTargetLocation(data);
                    setSectionLocation(false);
                  }}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<CancelIcon />}
                  className={classes.cancelButton}
                  onClick={() => {
                    setSectionLocation(false);
                  }}
                >
                  {t("CANCEL")}
                </Button>
              </div>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">{t("RECURRENCE_PERIOD")}</Typography>

            <TabContext value={recurence.frequency}>
              <Box display="flex" color="transparent" flexDirection="column">
                <TabList
                  value={recurence.frequency}
                  onChange={handleTabChange}
                  scrollButtons="auto"
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="icon label tabs example"
                >
                  <Tab label="Saatlik" value="hour" />
                  <Tab label="Günlük" value="day" />
                  <Tab label="Haftalık" value="week" />
                  <Tab label="Aylık" value="month" />
                  <Tab label="Yıllık" value="year" />
                </TabList>
                <Divider />
              </Box>
              <TabPanel value="hour">
                <div className={classes.hboxBlock}>
                  Her
                  <FaInput
                    faType={"number"}
                    value={recurence.hourlyInterval}
                    style={{ width: 90, paddingLeft: 10, paddingRight: 10 }}
                    onChange={(event) => {
                      handleChange("hourlyInterval", event.target.value);
                    }}
                  />
                  saatte bir
                </div>
              </TabPanel>
              <TabPanel value="day">
                <FormControl component="fieldset">
                  <RadioGroup
                    value={recurence.dailyMod}
                    onChange={(event) => {
                      handleChange("dailyMod", event.target.value);
                    }}
                  >
                    <div className={classes.hboxBlock}>
                      <FormControlLabel
                        value="INTERVAL"
                        control={<Radio />}
                        label=" "
                      />
                      Her
                      <FaInput
                        faType={"number"}
                        value={recurence.dailyInterval}
                        style={{ width: 90, paddingLeft: 10, paddingRight: 10 }}
                        onChange={(event) => {
                          handleChange("dailyInterval", event.target.value);
                        }}
                      />
                      günde bir
                    </div>
                    <div className={classes.hboxBlock}>
                      <FormControlLabel
                        value="WEEKDAY"
                        control={<Radio />}
                        label=" "
                      />
                      Her iş günü
                    </div>
                  </RadioGroup>
                </FormControl>
              </TabPanel>
              <TabPanel value="week">
                <div className={classes.hboxBlock}>
                  Her
                  <FaInput
                    faType={"number"}
                    value={recurence.weeklyInterval}
                    style={{ width: 90, paddingLeft: 10, paddingRight: 10 }}
                    onChange={(event) => {
                      handleChange("weeklyInterval", event.target.value);
                    }}
                  />
                  haftada bir
                </div>
                {days.map((day, index) => (
                  <FormControlLabel
                    key={index}
                    value={index}
                    control={
                      <Checkbox
                        checked={recurence.weeklyDays[index]}
                        onChange={(event) => {
                          handleChangeWeeklyDays(index, event.target.checked);
                        }}
                        name="notEmpty"
                        color="primary"
                      />
                    }
                    label={t(day)}
                  />
                ))}
              </TabPanel>
              <TabPanel value="month">
                <div className={classes.hboxBlock}>
                  Her ayın
                  <FaInput
                    faType={"number"}
                    value={recurence.monthlyDay}
                    style={{ width: 90, paddingLeft: 10, paddingRight: 10 }}
                    onChange={(event) => {
                      handleChange("monthlyDay", event.target.value);
                    }}
                  />
                  .günü ve her
                  <FaInput
                    faType={"number"}
                    value={recurence.monthlyInterval}
                    style={{ width: 90, paddingLeft: 10, paddingRight: 10 }}
                    onChange={(event) => {
                      handleChange("monthlyInterval", event.target.value);
                    }}
                  />
                  ayda bir
                </div>
              </TabPanel>
              <TabPanel value="year">
                <div className={classes.hboxBlock}>
                  Her
                  <FaInput
                    faType={"number"}
                    value={recurence.yearlyInterval}
                    style={{ width: 90, paddingLeft: 10, paddingRight: 10 }}
                    onChange={(event) => {
                      handleChange("yearlyInterval", event.target.value);
                    }}
                  />
                  yılda bir
                  <Select
                    className={classes.timeSelect}
                    value={recurence.yearlyMonth}
                    onChange={(event) => {
                      handleChange("yearlyMonth", event.target.value);
                    }}
                  >
                    {months.map((item, index) => (
                      <MenuItem key={index} value={index}>
                        {t(item)}
                      </MenuItem>
                    ))}
                  </Select>
                  ayının
                  <FaInput
                    faType={"number"}
                    value={recurence.yearlyDay}
                    style={{ width: 90, paddingLeft: 10, paddingRight: 10 }}
                    onChange={(event) => {
                      handleChange("yearlyDay", event.target.value);
                    }}
                  />
                  .günü
                </div>
              </TabPanel>
            </TabContext>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="caption">
              {t("RECURRENCE_INTERVAL")}
            </Typography>

            <Grid item container spacing={1}>
              <Grid item lg={4} xs={12}>
                <FaDatePicker
                  faType="date"
                  required
                  label={t("RECURRENCE_START_DATE")}
                  style={{ minHeight: 65 }}
                  value={recurence.startDate}
                  faOnChange={(val) => {
                    handleChange("startDate", val);
                  }}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <FaDatePicker
                  faType="time"
                  required
                  label={t("START_TIME")}
                  value={recurence.startTime}
                  faOnChange={(val) => {
                    handleChange("startTime", val);
                  }}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <FaDatePicker
                  faType="date"
                  label={t("RECURRENCE_END_DATE")}
                  style={{ minHeight: 65 }}
                  value={recurence.endDate}
                  faOnChange={(val) => {
                    handleChange("endDate", val);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FaDialog>
    );
  };
  const addDynamicRecurrenceRender = () => {
    return (
      <DynamicRecurrenceEdit
        ref={dialogRef}
        onSave={() => {
          tableRef.current.refreshTable();
        }}
      ></DynamicRecurrenceEdit>
    );
  };
  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {renderNewTaskDialog()}
        {addDynamicRecurrenceRender()}
        {
          <ThingsDataTable
            searchUrl={
              RECURRENCE.search + "?accountId=" + localStorage.accountId
            }
            searchForm={searchForm}
            columns={columns}
            ref={tableRef}
            showHeaderText={false}
            faOnDoubleClick={onDoubleClick}
          />
        }

        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={2} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                setRecurence(newRecurrence());
                setNewTaskOpen(true);
              }}
              startIcon={<AddIcon />}
              data-fa-button="ADD"
            >
              {t("ADD_STATIC")}
            </FaButton>
          </Grid>
          <Grid item xs={2} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                setRecurence(newRecurrence());
                dialogRef.current.openDialog();
              }}
              startIcon={<AddIcon />}
              data-fa-button="ADD"
            >
              {t("ADD_DYNAMIC")}
            </FaButton>
          </Grid>
          <Grid item xs={2}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <FaInput
                label={t("DESCRIPTION")}
                value={searchForm.description}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "description",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("DESCRIPTION")}
                value={searchForm.description}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "description",
                    event.target.value
                  );
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
