import { faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, CardHeader, Grid, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Cancel, Check } from "@material-ui/icons";
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SlaDetail from "components/detail/SlaDetail";
import UserDetail from "components/detail/UserDetail";
import IssueTaskHelp from "features/help/IssueTaskHelp";
import TaskEdit from "features/issues/TaskEdit";
import FeatureNotAllowed from "features/subscriptions/FeatureNotAllowed";
import Pause from "features/todo/Pause";
import PauseFinish from "features/todo/PauseFinish";
import React, { useEffect, useState } from "react";
import { FaButton } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import {
  getGroups,
  getOrganizations,
  getRole,
  getUserInfo,
} from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TaskService } from "services/TaskService";
import { smartTimeDiff } from "services/utils";
import { ROLES } from "services/utils/Roles";
import {
  checkRole,
  checkRoleByIssue,
  checkRoleByTask,
  checkRolePromise,
} from "services/utils/SecurityUtils";
import { buttonStyles } from "styles/ThingsButtonStyle";
import { imageStyles } from "styles/ThingsImageStyle";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";

export default function IssueTaskCard(props) {
  const { firstTask, issue, taskOnChange, accPreferences } = props;
  const { t } = useTranslation(["issue", "components"], { i18n });
  const labelClasses = labelStyles();
  const imageClasses = imageStyles();
  const buttonClasses = buttonStyles();

  const faDialog = useDialog();
  const dispatch = useDispatch();

  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [reassign, setReassign] = useState(false);
  const [parentId, setParentId] = useState(null);
  const [isPauseDialogOpen, setIsPauseDialogOpen] = useState(false);
  const [isPauseFinishDialogOpen, setIsPauseFinishDialogOpen] = useState(false);

  const userRole = useSelector(getRole);
  const userGroups = useSelector(getGroups);
  const userOrganizations = useSelector(getOrganizations);
  const userInfo = useSelector(getUserInfo);

  const [subscriptionError, setSubscriptionError] = useState(false);

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const isSLA = checkRole([ROLES.SLA], userRole);

  let isTaskAssignable = checkRoleByIssue(
    //[ROLES.THINGMANAGER, ROLES.POWER_USER, ROLES.ACCOUNTOWNER],
    [ROLES.POWER_USER, ROLES.ACCOUNTOWNER],
    userRole,
    userInfo,
    userGroups,
    userOrganizations,
    issue
  );

  let isTaskPausable = checkRoleByIssue(
    [ROLES.THINGMANAGER, ROLES.POWER_USER, ROLES.ACCOUNTOWNER],
    userRole,
    userInfo,
    userGroups,
    userOrganizations,
    issue
  );

  useEffect(() => {
    setIsEditFormOpen(false);
  }, [issue]);

  const renderPauseDialog = () => {
    return (
      <div>
        <Pause
          open={isPauseDialogOpen}
          onClose={() => {
            setIsPauseDialogOpen(false);
            taskOnChange();
          }}
          value={firstTask}
        />
      </div>
    );
  };

  const renderPauseFinishDialog = () => {
    return (
      <div>
        <PauseFinish
          open={isPauseFinishDialogOpen}
          onClose={() => {
            setIsPauseFinishDialogOpen(false);
            taskOnChange();
          }}
          value={firstTask}
        />
      </div>
    );
  };

  const completeTask = (task) => {
    faDialog({
      description: t(t("Görevi tamamlamak istediğinize emin misiniz?")),
      type: "confirm",
    })
      .then((confirmation) => {
        TaskService.complete(
          {
            ...task,
          },
          completeTaskSuccess,
          completeTaskError
        );
      })
      .catch((err) => console.log("error:", err));
  };
  const completeTaskSuccess = (data) => {
    showAlert(t("Görev başarıyla tamamlanmıştır"), "success");
    taskOnChange();
  };

  const completeTaskError = (error) => {
    showAlert(error, "error");
  };

  const renderTaskDetail = (task) => {
    return (
      <Grid item container spacing={1}>
        <Grid item xs={12}>
          <Grid item container spacing={1}>
            <Grid item xs={12} className={labelClasses.LabelRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("USER")}
              </Typography>
              <Typography className={labelClasses.LabelValue}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    variant="square"
                    className={imageClasses.ThingListImage}
                  >
                    {" "}
                    {task.user && task.user.fullname.charAt(0)}
                    {task.userGroup && task.userGroup.name.charAt(0)}
                  </Avatar>{" "}
                  <Typography className={labelClasses.LabelValue}>
                    {task.user && <UserDetail value={task.user} type="USER" />}
                    {task.userGroup && (
                      <UserDetail value={task.userGroup} type="USER_GROUP" />
                    )}
                  </Typography>
                </div>
              </Typography>
            </Grid>
            <Grid item xs={12} className={labelClasses.LabelRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("ASSIGN_DATE")}
              </Typography>
              <Typography className={labelClasses.LabelValue}>
                {smartTimeDiff(task.createDate)}
              </Typography>
            </Grid>
            <Grid item xs={12} className={labelClasses.LabelRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("DEADLINE")}
              </Typography>
              <Typography className={labelClasses.LabelValue}>
                {smartTimeDiff(task.taskDate)}
              </Typography>
            </Grid>
            <Grid item xs={12} className={labelClasses.LabelRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("TASK_DESCRIPTION")}
              </Typography>
              <Typography className={labelClasses.LabelValue}>
                {task.description}
              </Typography>
            </Grid>
            {isSLA && task?.slaContractLevel && (
              <SlaDetail
                task={task}
                onChange={taskOnChange}
                editable={isTaskAssignable}
              />
            )}
            <Grid item xs={12} className={labelClasses.LabelLastRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("TASK_STATUS")}
              </Typography>
              <Typography className={labelClasses.LabelValue}>
                {t("TASK_STATUS_" + task?.status, { ns: "components" })}
              </Typography>
            </Grid>

            <Grid item xs={12} className={labelClasses.LabelLastRow}>
              {checkRoleByTask(userInfo, userGroups, task) &&
                (task.status === "ACTIVE" || task.status === "MISSED") && (
                  <FaButton
                    style={{ width: "25%", marginRight: 10, marginTop: 2 }}
                    variant="contained"
                    size="small"
                    color="primary"
                    className={buttonClasses.green}
                    faClick={() => {
                      completeTask(task);
                    }}
                    startIcon={<FontAwesomeIcon icon={Check} />}
                    data-fa-button="COMPLETE"
                  >
                    {t("COMPLETE")}
                  </FaButton>
                )}
              {isTaskAssignable &&
                (task.status === "ACTIVE" ||
                  task.status === "MISSED" ||
                  task.status === "REASSIGNED" ||
                  task.status === "RETURNED" ||
                  task.status === "CANCELED" ||
                  task.status === "COMPLETED") && (
                  <FaButton
                    style={{ width: "25%", marginRight: 10, marginTop: 2 }}
                    variant="contained"
                    size="small"
                    color="primary"
                    faClick={() => {
                      setReassign(true);
                      setParentId(task.id);
                      setIsEditFormOpen(true);
                    }}
                    startIcon={<FontAwesomeIcon icon={faUserEdit} />}
                    data-fa-button="REASSIGN"
                  >
                    {t("REASSIGN")}
                  </FaButton>
                )}
              {isTaskAssignable &&
                (task.status === "ACTIVE" ||
                  task.status === "MISSED" ||
                  task.status === "REASSIGNED" ||
                  task.status === "RETURNED") && (
                  <FaButton
                    style={{ width: "25%", marginRight: 10, marginTop: 2 }}
                    variant="contained"
                    size="small"
                    color="secondary"
                    faClick={() => {
                      cancelTask();
                    }}
                    startIcon={<Cancel />}
                    data-fa-button="CANCEL_TASK"
                  >
                    {t("CANCEL_TASK")}
                  </FaButton>
                )}
              {isTaskPausable &&
                isSLA &&
                !issue?.paused &&
                (task.status === "ACTIVE" ||
                  task.status === "MISSED" ||
                  task.status === "REASSIGNED" ||
                  task.status === "RETURNED") && (
                  <FaButton
                    style={{ width: "25%", marginRight: 10, marginTop: 2 }}
                    variant="contained"
                    size="small"
                    color="primary"
                    faClick={() => {
                      setIsPauseDialogOpen(true);
                    }}
                    startIcon={<PauseIcon />}
                    data-fa-button="PAUSE"
                  >
                    {t("PAUSE")}
                  </FaButton>
                )}
              {isTaskPausable && isSLA && issue?.paused && (
                <FaButton
                  style={{ width: "25%", marginLeft: 10, marginTop: 2 }}
                  variant="contained"
                  size="small"
                  color="primary"
                  faClick={() => {
                    setIsPauseFinishDialogOpen(true);
                  }}
                  startIcon={<PlayArrowIcon />}
                  data-fa-button="PAUSE_FINISH"
                >
                  {t("PAUSE_FINISH")}
                </FaButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const cancelTask = () => {
    faDialog({
      description: t("Görevi iptal etkmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        TaskService.cancel(
          firstTask.id,
          (data) => {
            taskOnChange();
          },
          (error) => {
            taskOnChange();
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };

  return (
    <>
      {renderPauseDialog()}
      {renderPauseFinishDialog()}
      {subscriptionError && (
        <FeatureNotAllowed
          open={subscriptionError}
          onClose={() => {
            setSubscriptionError(false);
          }}
        />
      )}
      <Card style={{ minHeight: 250, overflowY: "auto" }}>
        <CardHeader
          title={t("RESPONSIBLE")}
          data-fa-section="RESPONSIBLE"
          action={<IssueTaskHelp value="issueDetail.task" />}
        />
        <CardContent>
          {isEditFormOpen && (
            <TaskEdit
              onChange={taskOnChange}
              open={isEditFormOpen}
              onClose={() => {
                setIsEditFormOpen(false);
              }}
              accPreferences={accPreferences}
              issue={issue}
              reassign={reassign}
              parentId={parentId}
            />
          )}

          {firstTask != null && renderTaskDetail(firstTask)}
          {!firstTask && (
            <Grid item container spacing={1}>
              <Grid item xs={12}>
                <Grid item container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    className={labelClasses.LabelLastRow}
                    style={{ paddingBottom: 10 }}
                  >
                    <Typography className={labelClasses.LabelHeader}>
                      {t("NO_ASSIGNED_TASK")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={labelClasses.LabelLastRow}>
                  {isTaskAssignable && (
                    <FaButton
                      style={{ width: "25%" }}
                      variant="contained"
                      size="small"
                      color="primary"
                      faClick={() => {
                        checkRolePromise([ROLES.TASK_MANAGEMENT], userRole)
                          .then(() => {
                            setReassign(false);
                            setParentId(null);
                            setIsEditFormOpen(true);
                          })
                          .catch(() => {
                            setSubscriptionError(true);
                          });
                      }}
                      startIcon={<FontAwesomeIcon icon={faUserEdit} />}
                      data-fa-button="ASSIGN_TASK"
                    >
                      {t("ATAMA")}
                    </FaButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </>
  );
}
