import Grid from "@material-ui/core/Grid";
import UserAutoComplete from "components/autocomplete/UserAutoComplete";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  FaDatePicker,
  FaDialog,
  FaFileUpload,
  FaInput,
} from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { ThingService } from "../../services/thing/thingService";
import { useStyles } from "./ThingDetailCardStyle";

const ThingOwnerEdit = forwardRef((props, ref) => {
  const { thing, onUpdate } = props;
  const { t } = useTranslation("thingDetail", { i18n });
  const classes = useStyles();

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsThingOwnerFormOpen(true);
    },
  }));

  const [files, setFiles] = useState([]);
  const [thingChange, setThingChange] = useState({});

  useEffect(() => {
    setThingChange({
      thingId: thing.id,
      ownerUser: thing.ownerUser,
      user: thing.user,
      mtncUser: thing.mtncUser,
      date: new Date(),
      description: "",
      document: null,
      account: thing.account,
    });
  }, [thing]);

  const [isThingOwnerFormOpen, setIsThingOwnerFormOpen] = useState(false);

  const updateThingOwner = () => {
    ThingService.changeOwner(thingChange, updateSuccessful, updateFail);
  };

  const updateSuccessful = (newData) => {
    onUpdate(newData);
    setIsThingOwnerFormOpen(false);
  };

  const updateFail = () => {};

  const filesOnChange = (files) => {
    setFiles(files);

    setThingChange({
      ...thingChange,
      document: files != null && files.length > 0 ? files[0] : null,
    });
  };

  return (
    <FaDialog
      title={"Sorumluluk Belirleme"}
      faOpen={isThingOwnerFormOpen}
      faOnSubmit={updateThingOwner}
      showSaveButton
      faHandleClose={(data) => {
        setIsThingOwnerFormOpen(data);
      }}
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={0}
      >
        <Grid item xs={12}>
          <FaInput
            value={thingChange.description}
            label={t("DESCRIPTION")}
            onChange={(event) => {
              setThingChange({
                ...thingChange,
                ["description"]: event.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FaDatePicker
            faType="date"
            label={t("Değişiklik tarihi")}
            style={{ minHeight: 65 }}
            value={thingChange.date}
            faOnChange={(val) => {
              setThingChange({
                ...thingChange,
                ["date"]: val,
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <UserAutoComplete
            label="Sahibi"
            required={true}
            value={thingChange?.ownerUser}
            accountId={thingChange?.account?.id}
            onChange={(data) => {
              setThingChange({
                ...thingChange,
                ["ownerUser"]: data,
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <UserAutoComplete
            label="Kullanıcısı"
            required={true}
            value={thingChange?.user}
            accountId={thingChange?.account?.id}
            onChange={(data) => {
              setThingChange({
                ...thingChange,
                ["user"]: data,
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <UserAutoComplete
            label="Bakım Sorumlusu"
            required={true}
            value={thingChange?.mtncUser}
            accountId={thingChange?.account?.id}
            onChange={(data) => {
              setThingChange({
                ...thingChange,
                ["mtncUser"]: data,
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FaFileUpload
            maxFileSize={20000000}
            onChange={filesOnChange}
            initialFiles={files}
            filesLimit={1}
          />
        </Grid>
      </Grid>
    </FaDialog>
  );
});

export default ThingOwnerEdit;
