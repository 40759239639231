import {
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Slider
} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { GetFieldByLanguage } from "services/utils";
export default function SurveyQuestionSlider(props) {
  const { question, answer, onChange, readOnly, currentLang } = props;

  return (
    <Grid item xs={12}>
      <FormControl
        component="fieldset"
        style={{ width: "100%" }}
        required={question.notEmpty}
      >
        <Grid container item row xs={12}>
          <Grid item xs={12}>
            <FormLabel component="legend">
              {question.orderNo}. {GetFieldByLanguage(question.multiLangName, currentLang)}
            </FormLabel>
          </Grid>
            <Grid item xs={12}>
              <FormHelperText>
              {GetFieldByLanguage(question.multiLangHint, currentLang)}
              </FormHelperText>
            </Grid>
        </Grid>
        <Slider
          required={question.notEmpty}
          value={answer?.dataNumeric}
          onChange={(event, newValue) => {
            onChange(question, newValue, "dataNumeric");
          }}
          valueLabelDisplay="auto"
          min={question.min}
          max={question.max}
          style={{ width: 500 }}
          disabled={readOnly}
        />
      </FormControl>
    </Grid>
  );
}

SurveyQuestionSlider.propTypes = {
  question: PropTypes.any,
  answer: PropTypes.any,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};
