import { IconButton } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ThingsHelpIcon from "components/icons/ThingsHelpIcon";
import ThingsSteps from "features/tutorial/ThingsSteps";
import TutorialElement from "features/tutorial/TutorialElement";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";
export default function IndoorLocationMapHelp(props) {
  const { value } = props;
  const { t } = useTranslation("help", { i18n });

  const stepsRef = useRef();
  const [open, setOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            <b>Konum güncelleme</b> işlemlerinin yapıldığı alandır.
          </TutorialElement>
        ),
      },
      {
        element: ".mapboxgl-marker",
        intro: (
          <TutorialElement icon="click">
            Konum güncellemek için farenizin imlecini harita üzerindeki{" "}
            <LocationOnIcon /> işaretine getirin, sol klik ile tutun ve
            istediğiniz konuma sürükleyip bırakın.
          </TutorialElement>
        ),
      },
    ]);
  }, []);

  return (
    <>
      {open && (
        <ThingsSteps
          code={value}
          steps={steps}
          ref={stepsRef}
          onStepChange={setStepIndex}
          afterComplete={() => {
            setOpen(true);
          }}
        />
      )}
      <IconButton
        aria-label="help"
        onClick={() => setOpen(true)}
        title={t("HELP")}
      >
        <ThingsHelpIcon />
      </IconButton>
    </>
  );
}

IndoorLocationMapHelp.propTypes = {
  value: PropTypes.string,
};
