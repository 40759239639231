import React, { useEffect, useMemo } from "react";
import ReactFlow, {
  Background,
  Controls,
  MarkerType,
  useEdgesState,
  useNodesState,
} from "react-flow-renderer";
import { useTranslation } from "react-i18next";
import { getDateTimeWFormat } from "services/utils";
import i18n from "../../i18n";
import { CircularNode, UserNode } from "./overviewFlowCustomNode";

const OverviewFlow = (props) => {
  const { t } = useTranslation("issueDetail", { i18n });

  const { issue, taskList, issueLogList } = props;

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const nodeTypes = useMemo(
    () => ({ circular: CircularNode, user: UserNode }),
    []
  );

  useEffect(() => {
    let initialPositions = { x: 250, y: 35 };
    let baseList = [];

    if (!!taskList && taskList.length > 0) {
      taskList.map((task, index) => {
        baseList.push({
          user: task?.user,
          date: task?.createDate,
          label: task?.user?.fullname,
        });
      });
    }
    if (!!issueLogList && issueLogList.length > 0) {
      issueLogList.map((log, index) => {
        baseList.push({
          date: log?.createDate,
          label:
            (log?.description != "" ? log?.description : t(log?.logType)) +
            " " +
            getDateTimeWFormat(log?.createDate),
        });
      });
    }

    baseList.sort(function (a, b) {
      return new Date(a?.date) - new Date(b?.date);
    });

    let initialNodes = [];
    let currentId = 1;

    if (!!issue.id) {
      let startNode = {
        data: { label: "Bildirim: " + getDateTimeWFormat(issue?.createDate) },
        date: issue?.createDate,
        id: currentId.toString(),
        type: "circular",
        position: { x: initialPositions.x, y: initialPositions.y - 20 },
      };
      initialNodes.push(startNode);
    }

    let initialEdges = [];
    // her node için bir önceki nodeun target id si setleniyor
    let lastId = "1";
    baseList.map((e, index) => {
      let id = e?.user?.id ?? ++currentId;

      let existingId = initialNodes.filter((t) => t.id == id);

      if (existingId.length > 0) {
        existingId[0].data.targetHandleIds.push(lastId);
      } else {
        let newObject = {
          data: {
            label: e?.label,
            targetHandleIds: [lastId],
            id: id.toString(),
          },
          date: e?.date,
          id: id.toString(),
          type: e?.user ? "user" : "circular",
          position: {
            x:
              index % 2 == 0
                ? initialPositions.x + 40
                : initialPositions.x - 40,
            y: initialPositions.y + 80 * (index + 1),
          },
        };
        initialNodes[initialNodes.length] = newObject;
      }

      initialEdges.push({
        id: index.toString(),
        target: id.toString(),
        sourceHandle: lastId + "_" + id.toString(),
        source: lastId.toString(),
        markerEnd: {
          type: MarkerType.Arrow,
        },

        label: e?.user ? getDateTimeWFormat(e.date) : null,
      });
      lastId = id;
    });

    setNodes(initialNodes);
    setEdges(initialEdges);
  }, [taskList, issueLogList]);

  return (
    <div style={{ width: "100%", height: 400 }}>
      {nodes && nodes.length > 0 && (
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          nodeTypes={nodeTypes}
        >
          <Controls />
          <Background color="#aaa" gap={16} />
        </ReactFlow>
      )}
    </div>
  );
};

export default OverviewFlow;
