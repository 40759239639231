import { faMap, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import ThingDetail from "components/detail/ThingDetail";
import CampaignSurveyQuestionRender from "features/campaign/CampaignSurveyQuestionRender";
import PropTypes from "prop-types";
import { default as React, useEffect, useRef, useState } from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SurveyService } from "services/SurveyService";
import { getDateTimeWFormat, isTNF } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import SurveyGPSLocation from "./SurveyGPSLocation";
import { useStyles } from "./style";

export default function SurveyAnswerDetail(props) {
  const {
    id,
    surveyId,
    isOpen,
    onClose,
    onPrevious,
    onNext,
    currentItem,
    totalItems,
  } = props;

  const labelClasses = labelStyles();

  const { t } = useTranslation("survey", { i18n });
  const dispatch = useDispatch();
  const classes = useStyles();
  const dialogRef = useRef();
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  const accountId = localStorage.accountId;
  const userInfo = useSelector(getUserInfo);

  const [survey, setSurvey] = useState(null);
  const [surveyAnswer, setSurveyAnswer] = useState(null);
  const defaultLang = userInfo.country.iso;

  useEffect(() => {
    if (surveyId && isOpen && (survey == null || survey.id !== surveyId)) {
      SurveyService.getSurvey(
        surveyId,
        accountId,
        (data) => {
          setSurvey(data);
        },
        (error) => {}
      );
    }
  }, [isOpen]);

  useEffect(() => {
    if (id) SurveyService.answerFindById(id, setSurveyAnswer, (error) => {});
  }, [id]);

  const renderPage = (page) => {
    return (
      <Card style={{ minWidth: "100%", minHeight: 200, marginTop: 10 }}>
        <CardHeader title={page.name} />
        <CardContent>
          {survey.questionList.map(
            (item, index) =>
              item.surveyPage.id === page.id && (
                <div>
                  <CampaignSurveyQuestionRender
                    key={index}
                    question={item}
                    onChangeAnswer={() => {}}
                    answer={surveyAnswer.details.find(
                      (i) => i.surveyQuestion.id === item.id
                    )}
                    editable={survey.status === "PASSIVE"}
                    readOnly={true}
                    accountId={accountId}
                    currentLang={defaultLang.toLowerCase()}
                  />
                </div>
              )
          )}
        </CardContent>
      </Card>
    );
  };

  const renderGPSLocation = () => {
    return (
      <SurveyGPSLocation
        ref={dialogRef}
        answer={surveyAnswer}
      ></SurveyGPSLocation>
    );
  };

  const renderHeader = () => {
    return (
      <>
        {renderGPSLocation()}
        <Card
        //className={classes.minCardHeight}
        >
          <CardHeader />
          <CardContent>
            <Grid item container spacing={1}>
              <Grid item xs={12}>
                <Grid item container spacing={1}>
                  <Grid item xs={6} className={labelClasses.LabelRow}>
                    <Typography className={labelClasses.LabelHeader}>
                      {isTNF(t("THING"), t("THING_T2C"))}
                    </Typography>
                    <ThingDetail value={surveyAnswer.thing} />
                  </Grid>

                  <Grid item xs={3} className={labelClasses.LabelRow}>
                    <Typography className={labelClasses.LabelHeader}>
                      {t("GPSLOCATION")}
                    </Typography>
                    {/* <Typography className={labelClasses.LabelValue}>
                    {t(surveyAnswer.gpsLocation)}
                  </Typography> */}
                    <IconButton
                      aria-label="settings"
                      onClick={() => {
                        dialogRef.current.openDialog();
                      }}
                    >
                      <FontAwesomeIcon icon={faMap} />
                    </IconButton>
                  </Grid>

                  <Grid item xs={3} className={labelClasses.LabelRow}>
                    <Typography className={labelClasses.LabelHeader}>
                      {t("CUSTOMER")}
                    </Typography>
                    <IconButton
                      aria-label="settings"
                      onClick={() => {
                        let customerId = surveyAnswer?.customer?.id;
                        if (customerId) {
                          const tmpTo = {
                            pathname: `/customer/detail/${customerId}`,
                            breadCrumbKey: `/customer/detail/:id`,
                          };
                          history.push(tmpTo);
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faUser} />
                    </IconButton>
                  </Grid>

                  <Grid item xs={6} className={labelClasses.LabelRow}>
                    <Typography className={labelClasses.LabelHeader}>
                      {t("ANSWER_DATE")}
                    </Typography>
                    <Typography className={labelClasses.LabelValue}>
                      {getDateTimeWFormat(surveyAnswer.answerDate)}
                    </Typography>
                  </Grid>

                  <Grid item xs={6} className={labelClasses.LabelRow}>
                    <Typography className={labelClasses.LabelHeader}>
                      {t("COMPLETION_TIME")}
                    </Typography>
                    <Typography className={labelClasses.LabelValue}>
                      {`${surveyAnswer.completionTime} saniye`}
                    </Typography>
                  </Grid>

                  <Grid item xs={6} className={labelClasses.LabelRow}>
                    <Typography className={labelClasses.LabelHeader}>
                      {t("VISITOR_ID")}
                    </Typography>
                    <Typography className={labelClasses.LabelValue}>
                      {surveyAnswer.clientInfo?.visitorId}
                    </Typography>
                  </Grid>

                  <Grid item xs={6} className={labelClasses.LabelRow}>
                    <Typography className={labelClasses.LabelHeader}>
                      {t("IP_ADDRESS")}
                    </Typography>
                    <Typography className={labelClasses.LabelValue}>
                      {surveyAnswer.clientInfo?.ipAddress}
                    </Typography>
                  </Grid>

                  <Grid item xs={6} className={labelClasses.LabelRow}>
                    <Typography className={labelClasses.LabelHeader}>
                      {t("BROWSER")}
                    </Typography>
                    <Typography className={labelClasses.LabelValue}>
                      {surveyAnswer.clientInfo?.browser}
                    </Typography>
                  </Grid>

                  <Grid item xs={6} className={labelClasses.LabelRow}>
                    <Typography className={labelClasses.LabelHeader}>
                      {t("BROWSER_TYPE")}
                    </Typography>
                    <Typography className={labelClasses.LabelValue}>
                      {surveyAnswer.clientInfo?.browserType}
                    </Typography>
                  </Grid>

                  <Grid item xs={6} className={labelClasses.LabelRow}>
                    <Typography className={labelClasses.LabelHeader}>
                      {t("BROWSER_MAJOR_VERSION")}
                    </Typography>
                    <Typography className={labelClasses.LabelValue}>
                      {surveyAnswer.clientInfo?.browserMajorVersion}
                    </Typography>
                  </Grid>

                  <Grid item xs={6} className={labelClasses.LabelRow}>
                    <Typography className={labelClasses.LabelHeader}>
                      {t("DEVICE_TYPE")}
                    </Typography>
                    <Typography className={labelClasses.LabelValue}>
                      {surveyAnswer.clientInfo?.deviceType}
                    </Typography>
                  </Grid>

                  <Grid item xs={6} className={labelClasses.LabelLastRow}>
                    <Typography className={labelClasses.LabelHeader}>
                      {t("PLATFORM")}
                    </Typography>
                    <Typography className={labelClasses.LabelValue}>
                      {surveyAnswer.clientInfo?.platform}
                    </Typography>
                  </Grid>

                  <Grid item xs={6} className={labelClasses.LabelLastRow}>
                    <Typography className={labelClasses.LabelHeader}>
                      {t("PLATFORM_VERSION")}
                    </Typography>
                    <Typography className={labelClasses.LabelValue}>
                      {surveyAnswer.clientInfo?.platformVersion}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  };

  const renderButtons = () => {
    return (
      <div style={{ marginBottom: 5 }}>
        <Button
          color="primary"
          variant="contained"
          startIcon={<ArrowLeft />}
          onClick={onPrevious}
          disabled={currentItem === 0}
        >
          {t("Önceki [" + currentItem + "]")}
        </Button>

        <Button
          color="primary"
          variant="contained"
          endIcon={<ArrowRight />}
          style={{ float: "right" }}
          onClick={onNext}
          disabled={currentItem === totalItems - 1}
        >
          {t("Sonraki [" + (totalItems - currentItem - 1) + "]")}
        </Button>
      </div>
    );
  };

  return (
    <FaDialog
      title={t("SURVEY_ANSWER_DETAIL")}
      faOpen={isOpen}
      loading={loading}
      faHandleClose={() => {
        onClose();
        setSurveyAnswer(null);
      }}
      faMaxWidth="lg"
    >
      {survey && surveyAnswer && survey.pageList && (
        <div>
          {renderButtons()}
          {renderHeader()}
          {survey.pageList.map((page) => {
            return renderPage(page);
          })}
        </div>
      )}
    </FaDialog>
  );
}

SurveyAnswerDetail.propTypes = {
  id: PropTypes.any,
  surveyId: PropTypes.any,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  currentItem: PropTypes.any,
  totalItems: PropTypes.any,
};
