import {
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  TextField,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import { default as React, useEffect, useState } from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function ThingDescriptor(props) {
  const { value, onChange } = props;

  const { t } = useTranslation("accountDetails", { i18n });
  const dispatch = useDispatch();
  const classes = useStyles();

  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  const [items, setItems] = useState([]);
  const [editValues, setEditValues] = useState([]);

  const newLabelButtons = () => [
    {
      name: "className",
      isShown: true,
    },
    {
      name: "thingCode",
      isShown: true,
    },
    {
      name: "hashtag",
      isShown: true,
    },
    {
      name: "description",
      isShown: true,
    },
  ];

  const [labelButtons, setLabelButtons] = useState(newLabelButtons());

  useEffect(() => {
    let tmp = [];
    let tempButtons = newLabelButtons();
    if (value) {
      let splitedItems = value.split(" ");
      splitedItems.map((item, index) => {
        if (item !== "") {
          tmp.push({
            key: index,
            label: item,
          });

          for (const element of tempButtons) {
            if (element.name === item) {
              element.isShown = false;
              break;
            }
          }
        }
      });
    }
    setLabelButtons([...tempButtons]);
    setItems([...tmp]);
    setEditValues([...tmp]);
  }, [value]);

  const addTarget = (newValue) => {
    let dto = {
      label: newValue,
      key: editValues.length,
    };
    setEditValues([...editValues, dto]);

    let tempButtons = labelButtons;

    for (const element of tempButtons) {
      if (element.name === newValue) {
        element.isShown = false;
        break;
      }
    }
    setLabelButtons([...tempButtons]);
  };

  const handleChipDelete = (chipToDelete) => () => {
    setEditValues((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
    let tempButtons = labelButtons;

    for (const element of tempButtons) {
      if (element.name === chipToDelete.label) {
        element.isShown = true;
        break;
      }
    }
    setLabelButtons([...tempButtons]);
  };

  const saveThingDescriptor = () => {
    let strFormat = "";

    if (editValues.length === 0) {
      onChange("");
    } else {
      editValues.map((x) => {
        strFormat = strFormat + x.label + " ";
      });
      onChange(strFormat);
    }
    setIsEditFormOpen(false);
  };

  const renderEditDialog = () => {
    return (
      <FaDialog
        title={t("Varlık Tanımı Belirle")}
        faOpen={isEditFormOpen}
        faHandleClose={(data) => {
          setIsEditFormOpen(false);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
          style={{ minHeight: 200 }}
        >
          <Grid item xs={12}>
            <Paper elevation={0} component="ul" className={classes.chipRoot}>
              {editValues?.map((data, index) => {
                return (
                  <li key={data.key}>
                    <Chip
                      label={t(data.label)}
                      onDelete={handleChipDelete(data, index)}
                      className={classes.chip}
                      variant="outlined"
                    />
                  </li>
                );
              })}
            </Paper>
            {labelButtons.map(
              (buttonItem) =>
                buttonItem.isShown && (
                  <div>
                    <Button
                      onClick={() => {
                        addTarget(buttonItem.name);
                      }}
                      startIcon={<Add />}
                    >
                      {t(buttonItem.name)}
                    </Button>
                  </div>
                )
            )}
          </Grid>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button onClick={() => saveThingDescriptor()} color="secondary">
              Tamam
            </Button>
          </Grid>
        </Grid>
      </FaDialog>
    );
  };
  const getValue = () => {
    let text = " ";
    if (items && items.length > 0)
      items.map((data, index) => {
        text += t(data.label);
        if (index !== items.length - 1) text += ", ";
        return text;
      });
    return text;
  };

  return (
    <>
      {renderEditDialog()}
      <Grid container alignItems="center">
        <Grid item xs={11}>
          {items && getValue() && (
            <TextField
              id="outlined-full-width"
              label="Varlık Tanımlayıcı"
              fullWidth
              margin="normal"
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              value={items ? getValue() : undefined}
            />
          )}
        </Grid>
        <Grid item xs={1} style={{ textAlign: "center" }}>
          <IconButton
            aria-label="settings"
            onClick={() => {
              setIsEditFormOpen(true);
            }}
          >
            <EditIcon />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
}
