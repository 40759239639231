import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Switch,
  Typography,
} from "@material-ui/core";
import { green, grey } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import ThingsEditIcon from "components/icons/ThingsEditIcon";
import { useStyles } from "features/accountDetails/AccountDetailsCardStyle";
import InteractionPageOptionsHelp from "features/help/InteractionPageOptionsHelp";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { getRole } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AccountPreferencesService } from "services/AccountPreferencesService";
import { ROLES } from "services/utils/Roles";
import { checkRole } from "services/utils/SecurityUtils";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";

const InteractionPageOptions = forwardRef((props, ref) => {
  const { account, onSave } = props;
  const { t } = useTranslation(["accountDetails", "components"], {
    i18n,
  });
  const classes = useStyles();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const accountId = localStorage.accountId;
  const [accountDetails, setAccountDetails] = useState();

  const [editValue, setEditValues] = useState();

  const role = useSelector(getRole);
  const labelClasses = labelStyles();

  const surveyRole = checkRole([ROLES.SURVEY], role);
  const campaignRole = checkRole([ROLES.CAMPAIGN], role);
  const userContentRole = checkRole([ROLES.USER_CONTENT], role);
  const customerRole = checkRole([ROLES.CUSTOMER], role);

  useImperativeHandle(ref, () => ({
    openInteractionPageEdit() {
      setIsEditOpen(true);
    },
  }));

  const GreenSwitch = withStyles({
    switchBase: {
      color: grey[300],
      "&$checked": {
        color: green[500],
      },
      "&$checked + $track": {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

  useEffect(() => {
    if (account) {
      setAccountDetails(account);
      setEditValues(account);
    }
  }, [account]);

  const editButtonClicked = () => {
    setIsEditOpen(true);
  };

  const handleChange = (event) => {
    setEditValues({
      ...editValue,
      [event.target.name]: event.target.checked,
    });
  };

  const saveForm = (data) => {
    AccountPreferencesService.saveInteractionPagePreferences(
      {
        ...editValue,
        account: { id: accountId },
      },
      () => {
        setIsEditOpen(false);
        onSave();
      },
      () => {}
    );
  };

  const renderDialog = () => {
    return (
      isEditOpen && (
        <FaDialog
          title={t("THING_INTERACTION_PAGE_OPTIONS")}
          id="THING_INTERACTION_PAGE_OPTIONS_SELECTION"
          faOpen={isEditOpen}
          faOnSubmit={() => {
            saveForm();
          }}
          faHandleClose={(data) => {
            setIsEditOpen(false);
            setEditValues(account);
          }}
          showSaveButton
        >
          <Grid
            container
            spacing={1}
            data-fa-section="THING_INTERACTION_PAGE_OPTIONS_SELECTION"
          >
            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel
                  label={t("IP_LIKE")}
                  labelPlacement="end"
                  control={
                    <GreenSwitch
                      checked={editValue.ipLike}
                      onChange={handleChange}
                      name="ipLike"
                    />
                  }
                />
                <FormControlLabel
                  label={t("IP_SUGGESTION")}
                  labelPlacement="end"
                  control={
                    <GreenSwitch
                      checked={editValue.ipSuggestion}
                      onChange={handleChange}
                      name="ipSuggestion"
                    />
                  }
                />

                {/* <FormControlLabel
                  label={t("IP_INFO")}
                  labelPlacement="end"
                  control={
                    <GreenSwitch
                      checked={editValue.ipDetailInfo}
                      onChange={handleChange}
                      name="ipDetailInfo"
                    />
                  }
                /> */}

                <FormControlLabel
                  label={t("IP_IMAGE")}
                  labelPlacement="end"
                  control={
                    <GreenSwitch
                      checked={editValue.ipImage}
                      onChange={handleChange}
                      name="ipImage"
                    />
                  }
                />
                <FormControlLabel
                  label={t("IP_DOCUMENT")}
                  labelPlacement="end"
                  control={
                    <GreenSwitch
                      checked={editValue.ipDocument}
                      onChange={handleChange}
                      name="ipDocument"
                    />
                  }
                />
                <FormControlLabel
                  label={t("IP_LINK")}
                  labelPlacement="end"
                  control={
                    <GreenSwitch
                      checked={editValue.ipLink}
                      onChange={handleChange}
                      name="ipLink"
                    />
                  }
                />
                <FormControlLabel
                  label={t("IP_PUBLIC_DESCRIPTION")}
                  labelPlacement="end"
                  control={
                    <GreenSwitch
                      checked={editValue.ipThingPublicDescription}
                      onChange={handleChange}
                      name="ipThingPublicDescription"
                    />
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel
                  label={t("IP_ISSUE")}
                  labelPlacement="end"
                  control={
                    <GreenSwitch
                      checked={editValue.ipIssue}
                      onChange={handleChange}
                      name="ipIssue"
                    />
                  }
                />
                <FormControlLabel
                  label={t("IP_ANNOUNCEMENT")}
                  labelPlacement="end"
                  control={
                    <GreenSwitch
                      checked={editValue.ipAnnouncement}
                      onChange={handleChange}
                      name="ipAnnouncement"
                    />
                  }
                />
                <FormControlLabel
                  label={t("IP_SURVEY")}
                  labelPlacement="end"
                  control={
                    <GreenSwitch
                      checked={editValue.ipSurvey}
                      disabled={!surveyRole}
                      onChange={handleChange}
                      name="ipSurvey"
                    />
                  }
                />
                <FormControlLabel
                  label={t("IP_CAMPAIGN")}
                  labelPlacement="end"
                  control={
                    <GreenSwitch
                      checked={editValue.ipCampaign}
                      disabled={!campaignRole}
                      onChange={handleChange}
                      name="ipCampaign"
                    />
                  }
                />
                <FormControlLabel
                  label={t("IP_USER_CONTENT")}
                  labelPlacement="end"
                  control={
                    <GreenSwitch
                      checked={editValue.ipUserContent}
                      disabled={!userContentRole}
                      onChange={handleChange}
                      name="ipUserContent"
                    />
                  }
                />
                <FormControlLabel
                  label={t("IP_CUSTOMER")}
                  labelPlacement="end"
                  control={
                    <GreenSwitch
                      checked={editValue.ipCustomer}
                      disabled={!customerRole}
                      onChange={handleChange}
                      name="ipCustomer"
                    />
                  }
                />
              </FormGroup>
            </Grid>
          </Grid>
        </FaDialog>
      )
    );
  };

  return (
    <>
      {renderDialog()}

      <Card
        className="fai-interactionPageOptions"
        data-fa-section="THING_INTERACTION_PAGE_OPTIONS"
      >
        <CardHeader
          action={
            <>
              <InteractionPageOptionsHelp value="accountInteraction.interactionPageOptions" />
              <IconButton
                data-fa-button="THING_INTERACTION_PAGE_OPTIONS_EDIT"
                aria-label="settings"
                onClick={editButtonClicked}
              >
                <ThingsEditIcon />
              </IconButton>
            </>
          }
          title={t("THING_INTERACTION_PAGE_OPTIONS")}
        />
        <CardContent>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <Grid item container spacing={1}>
                <Grid
                  container
                  xs={6}
                  data-fa-section="THING_INTERACTION_PAGE_LIKE_SELECTION"
                >
                  <Grid
                    item
                    xs={10}
                    className={labelClasses.LabelRow}
                    style={{ height: 28 }}
                  >
                    <Typography className={labelClasses.LabelHeader}>
                      {t("IP_LIKE")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={labelClasses.LabelRow}
                    style={{ height: 28 }}
                  >
                    <Typography className={labelClasses.LabelValue}>
                      {accountDetails?.ipLike ? <CheckIcon /> : null}
                    </Typography>
                  </Grid>
                </Grid>
                {/* --- */}
                <Grid
                  container
                  xs={6}
                  data-fa-section="THING_INTERACTION_PAGE_SUGGESTION_SELECTION"
                >
                  <Grid
                    item
                    xs={10}
                    className={labelClasses.LabelRow}
                    style={{ height: 28 }}
                  >
                    <Typography className={labelClasses.LabelHeader}>
                      {t("IP_SUGGESTION")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={labelClasses.LabelRow}
                    style={{ height: 28 }}
                  >
                    <Typography className={labelClasses.LabelValue}>
                      {accountDetails?.ipSuggestion ? <CheckIcon /> : null}
                    </Typography>
                  </Grid>
                </Grid>
                {/* --- */}
                <Grid
                  container
                  xs={6}
                  data-fa-section="THING_INTERACTION_PAGE_ISSUE_SELECTION"
                >
                  <Grid
                    item
                    xs={10}
                    className={labelClasses.LabelRow}
                    style={{ height: 28 }}
                  >
                    <Typography className={labelClasses.LabelHeader}>
                      {t("IP_ISSUE")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={labelClasses.LabelRow}
                    style={{ height: 28 }}
                  >
                    <Typography className={labelClasses.LabelValue}>
                      {accountDetails?.ipIssue ? <CheckIcon /> : null}
                    </Typography>
                  </Grid>
                </Grid>
                {/* --- */}
                {/* <Grid
                  container
                  xs={12}
                  data-fa-section="THING_INTERACTION_PAGE_INFO_SELECTION"
                >
                  <Grid item xs={10} className={labelClasses.LabelRow}>
                    <Typography className={labelClasses.LabelHeader}>
                      {t("IP_INFO")}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} className={labelClasses.LabelRow}>
                    <Typography className={labelClasses.LabelValue}>
                      {accountDetails?.ipDetailInfo ? <CheckIcon /> : null}
                    </Typography>
                  </Grid>
                </Grid> */}
                {/* --- */}
                <Grid
                  container
                  xs={6}
                  data-fa-section="THING_INTERACTION_PAGE_CUSTOMER_SELECTION"
                >
                  <Grid
                    item
                    xs={10}
                    className={labelClasses.LabelRow}
                    style={{ height: 28 }}
                  >
                    <Typography className={labelClasses.LabelHeader}>
                      {t("IP_CUSTOMER")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={labelClasses.LabelRow}
                    style={{ height: 28 }}
                  >
                    <Typography className={labelClasses.LabelValue}>
                      {accountDetails?.ipCustomer ? <CheckIcon /> : null}
                    </Typography>
                  </Grid>
                </Grid>
                {/* --- */}
                <Grid
                  container
                  xs={6}
                  data-fa-section="THING_INTERACTION_PAGE_SURVEY_SELECTION"
                >
                  <Grid
                    item
                    xs={10}
                    className={labelClasses.LabelRow}
                    style={{ height: 28 }}
                  >
                    <Typography className={labelClasses.LabelHeader}>
                      {t("IP_SURVEY")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={labelClasses.LabelRow}
                    style={{ height: 28 }}
                  >
                    <Typography className={labelClasses.LabelValue}>
                      {accountDetails?.ipSurvey ? <CheckIcon /> : null}
                    </Typography>
                  </Grid>
                </Grid>
                {/* --- */}
                <Grid
                  container
                  xs={6}
                  data-fa-section="THING_INTERACTION_PAGE_CAMPAIGN_SELECTION"
                >
                  <Grid
                    item
                    xs={10}
                    className={labelClasses.LabelRow}
                    style={{ height: 28 }}
                  >
                    <Typography className={labelClasses.LabelHeader}>
                      {t("IP_CAMPAIGN")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={labelClasses.LabelRow}
                    style={{ height: 28 }}
                  >
                    <Typography className={labelClasses.LabelValue}>
                      {accountDetails?.ipCampaign ? <CheckIcon /> : null}
                    </Typography>
                  </Grid>
                </Grid>
                {/* --- */}
                <Grid
                  container
                  xs={6}
                  data-fa-section="THING_INTERACTION_PAGE_IMAGE_SELECTION"
                >
                  <Grid
                    item
                    xs={10}
                    className={labelClasses.LabelRow}
                    style={{ height: 28 }}
                  >
                    <Typography className={labelClasses.LabelHeader}>
                      {t("IP_IMAGE")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={labelClasses.LabelRow}
                    style={{ height: 28 }}
                  >
                    <Typography className={labelClasses.LabelValue}>
                      {accountDetails?.ipImage ? <CheckIcon /> : null}
                    </Typography>
                  </Grid>
                </Grid>

                {/* --- */}
                <Grid
                  container
                  xs={6}
                  data-fa-section="THING_INTERACTION_PAGE_DOCUMENT_SELECTION"
                >
                  <Grid
                    item
                    xs={10}
                    className={labelClasses.LabelRow}
                    style={{ height: 28 }}
                  >
                    <Typography className={labelClasses.LabelHeader}>
                      {t("IP_DOCUMENT")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={labelClasses.LabelRow}
                    style={{ height: 28 }}
                  >
                    <Typography className={labelClasses.LabelValue}>
                      {accountDetails?.ipDocument ? <CheckIcon /> : null}
                    </Typography>
                  </Grid>
                </Grid>
                {/* --- */}
                <Grid
                  container
                  xs={6}
                  data-fa-section="THING_INTERACTION_PAGE_LINK_SELECTION"
                >
                  <Grid
                    item
                    xs={10}
                    className={labelClasses.LabelRow}
                    style={{ height: 28 }}
                  >
                    <Typography className={labelClasses.LabelHeader}>
                      {t("IP_LINK")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={labelClasses.LabelRow}
                    style={{ height: 28 }}
                  >
                    <Typography className={labelClasses.LabelValue}>
                      {accountDetails?.ipLink ? <CheckIcon /> : null}
                    </Typography>
                  </Grid>
                </Grid>
                {/* --- */}
                <Grid
                  container
                  xs={6}
                  data-fa-section="THING_INTERACTION_PAGE_ANNOUNCEMENT_SELECTION"
                >
                  <Grid
                    item
                    xs={10}
                    className={labelClasses.LabelRow}
                    style={{ height: 28 }}
                  >
                    <Typography className={labelClasses.LabelHeader}>
                      {t("IP_ANNOUNCEMENT")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={labelClasses.LabelRow}
                    style={{ height: 28 }}
                  >
                    <Typography className={labelClasses.LabelValue}>
                      {accountDetails?.ipAnnouncement ? <CheckIcon /> : null}
                    </Typography>
                  </Grid>
                </Grid>
                {/* --- */}
                <Grid
                  container
                  xs={6}
                  data-fa-section="THING_INTERACTION_PAGE_USER_CONTENT_SELECTION"
                >
                  <Grid
                    item
                    xs={10}
                    className={labelClasses.LabelLastRow}
                    style={{ height: 28 }}
                  >
                    <Typography className={labelClasses.LabelHeader}>
                      {t("IP_USER_CONTENT")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={labelClasses.LabelLastRow}
                    style={{ height: 28 }}
                  >
                    <Typography className={labelClasses.LabelValue}>
                      {accountDetails?.ipUserContent ? <CheckIcon /> : null}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  xs={6}
                  data-fa-section="THING_INTERACTION_PAGE_PUBLIC_DESCRIPTION_SELECTION"
                >
                  {/* --- */}
                  <Grid
                    item
                    xs={10}
                    className={labelClasses.LabelLastRow}
                    style={{ height: 28 }}
                  >
                    <Typography className={labelClasses.LabelHeader}>
                      {t("IP_PUBLIC_DESCRIPTION")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={labelClasses.LabelLastRow}
                    style={{ height: 28 }}
                  >
                    <Typography className={labelClasses.LabelValue}>
                      {accountDetails?.ipThingPublicDescription ? (
                        <CheckIcon />
                      ) : null}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
});
export default InteractionPageOptions;
