import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { UserTutorialService } from "services/UserTutorialService";
import AssignTaskTutorial from "./AssignTaskTutorial";
import ChangePasswordTutorial from "./ChangePasswordTutorial";
import FollowInteractionDashboardTutorial from "./FollowInteractionDashboardTutorial";
import FollowInteractionsTutorial from "./FollowInteractionsTutorial";
import FollowIssuesTutorial from "./FollowIssues";
import FollowLikesTutorial from "./FollowLikes";
import FollowSuggestionsTutorial from "./FollowSuggestions";
import GeneralPageFeatures from "./GeneralPageFeatures";
import IssueClose_1_Tutorial from "./IssueClose_1_Tutorial";
import IssueClose_2_Tutorial from "./IssueClose_2_Tutorial";
import IssueClose_3_Tutorial from "./IssueClose_3_Tutorial";
import QuickStartBeginnerTutorial from "./QuickStartBeginnerTutorial";
import QuickStartHighPackageTutorial from "./QuickStartHighPackageTutorial";
import QuickStartTutorial from "./QuickStartTutorial";
import ShareFileBeginnerTutorial from "./ShareFileBeginnerTutorial";
import ShareFileFreeTutorial from "./ShareFileFreeTutorial";
import ShareFileStandardTutorial from "./ShareFileStandardTutorial";
import ShareImageBeginnerTutorial from "./ShareImageBeginnerTutorial";
import ShareImageFreeTutorial from "./ShareImageFreeTutorial";
import ShareImageStandardTutorial from "./ShareImageStandardTutorial";
import ShareSocialMediaTutorial from "./ShareSocialMediaTutorial";
import ShareWebLinkBeginnerTutorial from "./ShareWebLinkBeginnerTutorial";
import ShareWebLinkFreeTutorial from "./ShareWebLinkFreeTutorial";
import ShareWebLinkStandardTutorial from "./ShareWebLinkStandardTutorial";

export default function TutorialViewer() {
  const { code } = useParams();

  useEffect(() => {
    UserTutorialService.start(
      code,
      (data) => {},
      (error) => {}
    );
  }, []);

  const getTutorial = () => {
    // Nasil Yapilir listesindeki tutoriallar. Detay sayfası tutorillarini eklemeye gerek yok.
    if (code === "quick_start") return <QuickStartTutorial />;
    else if (code === "quick_start_beginner")
      return <QuickStartBeginnerTutorial />;
    else if (code === "quick_start_high_package")
      return <QuickStartHighPackageTutorial />;
    else if (code === "share_image_free") return <ShareImageFreeTutorial />;
    else if (code === "share_image_beginner")
      return <ShareImageBeginnerTutorial />;
    else if (code === "share_image_standard")
      return <ShareImageStandardTutorial />;
    else if (code === "share_web_link_free")
      return <ShareWebLinkFreeTutorial />;
    else if (code === "share_web_link_beginner")
      return <ShareWebLinkBeginnerTutorial />;
    else if (code === "share_web_link_standard")
      return <ShareWebLinkStandardTutorial />;
    else if (code === "share_file_free") return <ShareFileFreeTutorial />;
    else if (code === "share_file_beginner")
      return <ShareFileBeginnerTutorial />;
    else if (code === "share_file_standard")
      return <ShareFileStandardTutorial />;
    else if (code === "share_social_media") return <ShareSocialMediaTutorial />;
    else if (code === "follow_interactions")
      return <FollowInteractionsTutorial />;
    else if (code === "follow_interaction_dashboard")
      return <FollowInteractionDashboardTutorial />;
    else if (code === "follow_issues") return <FollowIssuesTutorial />;
    else if (code === "follow_likes") return <FollowLikesTutorial />;
    else if (code === "follow_suggestions")
      return <FollowSuggestionsTutorial />;
    else if (code === "assign_task") return <AssignTaskTutorial />;
    else if (code === "issue_close_1") return <IssueClose_1_Tutorial />;
    else if (code === "issue_close_2") return <IssueClose_2_Tutorial />;
    else if (code === "issue_close_3") return <IssueClose_3_Tutorial />;
    else if (code === "change_password") return <ChangePasswordTutorial />;
    else if (code === "general_page_features") return <GeneralPageFeatures />;
    else return <></>;
  };

  return getTutorial();
}
